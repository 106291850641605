import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs-compat/Observable';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { VitrineAdditionalMaterialTypeBase } from 'src/app/concepts/vitrine/enums/vitrine-additional-material-type.enum';
import { ItemFileType } from '../model/item-row-file.model';

export enum FormItemSelectAction {
    SELECT,
    DELETE
}
export interface FormItemSelect {
    id: number;
    rowId?: string;
    action?: FormItemSelectAction;
}
@Injectable({ providedIn: 'root' })
export class FormItemSelectService implements OnDestroy {
    // A subject to track whether the component has been destroyed
    private readonly destroyed: Subject<void> = new Subject();

    // A behavior subject to track the currently selected item
    public selectedItem: BehaviorSubject<FormItemSelect> = new BehaviorSubject<FormItemSelect>(null);

    // A behavior subject to track all selected items
    private selectedItems: BehaviorSubject<FormItemSelect[]> = new BehaviorSubject<FormItemSelect[]>([]);

    // A behavior subject to track the initial set of items
    public initialItems: BehaviorSubject<FormItemSelect[]> = new BehaviorSubject<FormItemSelect[]>([]);

    // A behavior subject to track the remaining set of items
    public remainingItems: BehaviorSubject<FormItemSelect[]> = new BehaviorSubject<FormItemSelect[]>([]);

    // An observable that emits the currently selected item, filtering out null values
    public readonly selectedItem$: Observable<FormItemSelect> = this.selectedItem.asObservable().pipe(
        filter((item) => !!item),
        takeUntil(this.destroyed)
    );
    public readonly selectedItemDup$: Observable<FormItemSelect> = this.selectedItem.asObservable().pipe(takeUntil(this.destroyed));

    // An observable that emits the initial set of items
    public readonly initialItems$: Observable<FormItemSelect[]> = this.initialItems.asObservable();

    // An observable that emits the remaining set of items, sharing the replay for performance and filtering out null values
    public readonly remainingItems$: Observable<FormItemSelect[]> = this.remainingItems
        .asObservable()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }), takeUntil(this.destroyed));

    // An observable that emits all selected items, filtering out null and empty arrays
    public readonly selectedItems$: Observable<FormItemSelect[]> = this.selectedItems.asObservable().pipe(
        filter((items) => !!items && !!items.length),
        takeUntil(this.destroyed)
    );

    // An observable that emits the length of the remaining items
    public remainingItemsLength$: Observable<number> = this.remainingItems$.pipe(
        map((x) => x.length),
        takeUntil(this.destroyed)
    );

    constructor() {
        this.updateSelectedItemsHandler();
        this.updateRemainingItemsHandler();
    }

    /**
     * Sets the currently selected item
     * @param item The item to set as selected
     */
    public setSelectedItem = (item: FormItemSelect): void => {
        if (item) {
            this.selectedItem.next(item);
        }
    };

    /**
     * Sets the selected items
     * @param items The items to set as selected
     */
    public setSelectedItems = (items: FormItemSelect[]): void => {
        this.selectedItems.next(items);
    };

    /**
     * Sets the initial items and updates remaining and selected items
     * @param items The items to set as initial
     */
    public setInitialItems = (items: FormItemSelect[]): void => {
        this.initialItems.next(items);
        this.remainingItems.next(items);
        this.selectedItems.next([]);
        this.selectedItem.next(null);
    };

    /**
     * Resets all items to initial values
     */
    public reset = (): void => {
        this.selectedItem.next(null);
        this.selectedItems.next([]);
        this.initialItems.next([]);
        this.remainingItems.next([]);
    };

    /**
     * Gets the file type from the given ID
     * @param {number} id: the ID of the file type
     * @returns {ItemFileType|null}: an object representing the file type if found, null otherwise
     */
    public getFileTypeById = (id: number, list?: FormItemSelect[]): ItemFileType | null => {
        const items = list || this.initialItems.value;
        const index = items.findIndex((x) => x.id === id);
        if (index > -1) {
            return <ItemFileType>{
                typeId: items[index].id,
                label: VitrineAdditionalMaterialTypeBase[items[index].id]
            };
        }

        return null;
    };

    /**
     * Updates the selected items with the new item, or adds the item if it doesn't exist
     * @param item The item to update or add
     */
    private updateSelectedItems = (item: FormItemSelect): void => {
        const items = this.selectedItems.value;
        const index = items.findIndex((x) => x.id === item.id);
        if (index > -1) {
            const updatedItem: FormItemSelect = {
                ...items[index],
                ...item
            };
            items[index] = updatedItem;
            this.setSelectedItems(items);
        } else {
            this.setSelectedItems([...this.selectedItems.value, item]);
        }
    };
    /**
     *Removes an item from the selectedItems array based on its id.
     *@param item - The item to be removed from the selectedItems array.
     */

    private removeItemFromSelectedItems = (item: FormItemSelect) => {
        const items = [...this.selectedItems.value];
        const index = items.findIndex((x) => x.id === item.id);
        if (index > -1) {
            items.splice(index, 1);
            this.setSelectedItems(items);
        }
    };

    /**
     * Sets the remainingItems array to be the difference between the initialItems array and the selectedItems array.
     * @param selectedItems - The selected items array.
     * @param initialItems - The initial items array.
     */
    private setRemainingItems = (selectedItems: FormItemSelect[]): void => {
        const updatedRemainingItems = [...this.initialItems.value].filter(
            (selectedItem: FormItemSelect) => selectedItems.filter((item: FormItemSelect) => item.id === selectedItem.id).length === 0
        );
        this.remainingItems.next(updatedRemainingItems);
    };

    /**
     * Subscribes to changes in the selected item and updates the selected items, remaining items, or removes an item from selected items.
     * @returns void
     */
    private updateSelectedItemsHandler = (): void => {
        this.selectedItem
            .pipe(
                filter((item: FormItemSelect) => !!item),
                map((item: FormItemSelect) => {
                    if (item.action === FormItemSelectAction.DELETE) {
                        this.removeItemFromSelectedItems(item);
                    } else if (item.action === FormItemSelectAction.SELECT) {
                        this.updateSelectedItems(item);
                    }
                }),
                takeUntil(this.destroyed)
            )
            .subscribe();
    };

    /**
     * Subscribes to the selectedItems observable to update the remaining items
     * @returns {void} This function does not return anything
     */
    private updateRemainingItemsHandler = (): void => {
        this.selectedItems
            .pipe(
                filter((items: FormItemSelect[]) => !!items && !!items.length),
                map((items: FormItemSelect[]) => this.setRemainingItems(items)),
                takeUntil(this.destroyed)
            )
            .subscribe();
    };

    /**
     * Cleans up the component and unsubscribes from any observables
     * @returns {void} This function does not return anything
     */
    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
