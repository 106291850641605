import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Address } from 'src/app/concepts/location/models/location.model';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { validateDate } from 'src/app/shared/validators/dates.validator';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-blank.validator';
import { Translatable } from '../../../shared/model/translatable.model';
import { IAddress } from '../../location/models/location.model';
import { PxUser } from './../../account/model/project-x-user';
import { Translation } from 'src/app/shared/model/translatable.model';
import { RegexpValidator } from '@app/shared/validators/regexp.validator';
import { WikiArtsDataValidation } from '@app/shared/services/wikiArtsData.validation';
import { OrganisationStatus } from '../enums/organization-status.enum';
import { NotificationContent, NotificationContentBuilder } from '@app/concepts/notifications/model/notifications.model';
import { Globals } from '@app/_configs/globals';

/**
 * Represents an organization.
 * @interface
 */
export interface IOrganization {
    /** The ID of the organization. */
    id?: number;
    /** The email of the organization. */
    email: string;
    /** The neq of the organization. */
    neq: string;
    /** The date of foundation of the organization. */
    dateFoundation: string;
    /** The logo of the organization. */
    logo: string;
    /** The phone number of the organization. */
    phone: string;
    /** The phone post number of the organization. */
    phonePostNumber: string;
    /** The ID of the address of the organization. */
    addressId: number;
    /** The ID of the status of the organization. */
    statusId: number;
    /** An array of translations for the organization. */
    trans: Translation[];
    /** An array of types for the organization. */
    types: Array<OrganizationTypes>;
    /** The website of the organization. */
    website: string;
    /** The address of the organization. */
    address?: IAddress;
    /** Indicates if the organization is a network head. */
    isNetworkHead: number;
    /** An array of networks for the organization. */
    networks: number[];
    /** Indicates if tour access is available for the organization. */
    tourAccess: boolean;
    /** The artsdata ID of the organization. */
    artsdataId?: string;
    /** The wikidata ID of the organization. */
    wikidataId?: string;
}

/**
 * Represents the networks of an organization.
 * @interface
 */
export interface OrganizationNetworks {
    /** The ID of the organization. */
    organizationId: number;
    /** An array of number representing the ids of networks. */
    networks: number[];
}

/**
 * Represents the types of an organization.
 * @interface
 */
export interface IOrganizationTypes {
    /** The ID of the organization. */
    organizationId: number;
    /** An array of types for the organization. */
    types: Array<OrganizationTypes>;
}

/**
 * Represents a created organization.
 * @interface
 */
export interface ICreatedOrganization {
    /** Indicates if the organization was created. */
    isCreated: boolean;
    /** The created organization. */
    organization: IOrganization;
}
/**
 * Represents a social media account.
 * @extends Translatable
 */
export class SocialMediaAccount extends Translatable {
    /**
     * Creates a new instance of the SocialMediaAccount class.
     * @param {Record<string, any>} data - The data to initialize the instance with.
     */
    constructor(data: Record<string, any>) {
        super();
        /** @property {Translation[]} trans - An array of translations for the social media account. */
        this.trans = data[ 'trans' ];
    }
}

/**
 * Represents a network.
 * @extends Translatable
 */
export class Network extends Translatable {
    id: number;
    logo?: string;
    /**
     * Creates a new instance of the Network class.
     * @param {Record<string, any>} data - The data to initialize the instance with.
     */
    constructor(data: Record<string, any>) {
        super();
        /** @property {number} id - The ID of the network. */
        this.id = data[ 'id' ];
        /** @property {Translation[]} trans - An array of translations for the network. */
        this.trans = data[ 'trans' ];
        /** @property {string} [logo] - The logo of the network. */
        this.logo = data[ 'logo' ];
    }
}
/**
 * Represents an organization.
 * @extends Translatable
 * @implements IOrganization
 */
export class Organization extends Translatable implements IOrganization {
    /** @property {number} [id] - The ID of the organization. */
    id?: number;
    /** @property {string} email - The email of the organization. */
    email: string;
    /** @property {string} neq - The neq of the organization. */
    neq: string;
    /** @property {string} dateFoundation - The date of foundation of the organization. */
    dateFoundation: string;
    /** @property {string} logo - The logo of the organization. */
    logo: string;
    /** @property {string} phone - The phone number of the organization. */
    phone: string;
    /** @property {string} phonePostNumber - The phone post number of the organization. */
    phonePostNumber: string;
    /** @property {number} addressId - The ID of the address of the organization. */
    addressId: number;
    /** @property {OrganisationStatus} statusId - The ID of the status of the organization. */
    statusId: OrganisationStatus;
    /** @property {boolean} tourAccess - Indicates if tour access is available for the organization. */
    tourAccess: boolean;
    /** @property {Translation[]} trans - An array of translations for the organization. */
    trans: Translation[];
    /** @property {Array<number>} types - An array of types for the organization. */
    types: Array<OrganizationTypes>;
    /** @property {Array<IMember>} members - An array of members for the organization. */
    members: Array<IMember>;
    /** @property {string} website - The website of the organization. */
    website: string;
    /** @property {Address} [address] - The address of the organization. */
    address?: Address;
    /** @property {SocialMediaAccount[]} [socialMediaAccounts] - An array of social media accounts for the organization. */
    socialMediaAccounts?: SocialMediaAccount[];
    /** @property {number} isNetworkHead - Indicates if the organization is a network head. */
    isNetworkHead: number;
    /** @property {number[]} networks - An array of networks for the organization. */
    networks: number[];
    /** @property {string} [artsdataId] - The artsdata ID of the organization. */
    artsdataId?: string;
    /** @property {string} [wikidataId] - The wikidata ID of the organization. */
    wikidataId?: string;
    /** @property {FormGroup} coordForm - The form group for the organization's coordinates. */
    private coordForm: FormGroup;
    /** @property {FormGroup} typeOrgaForm - The form group for the organization's types. */
    private typeOrgaForm: FormGroup;
    /** @property {FormGroup} profileForm - The form group for the organization's profile. */
    private profileForm: FormGroup;
    constructor(datas: Record<string, any>) {
        super();
        this.id = datas[ 'id' ];
        this.email = datas[ 'email' ];
        this.neq = datas[ 'neq' ];
        this.dateFoundation = datas[ 'dateFoundation' ];
        this.logo = datas[ 'logo' ];
        this.phone = datas[ 'phone' ];
        this.phonePostNumber = datas[ 'phonePostNumber' ];
        this.addressId = datas[ 'addressId' ];
        this.statusId = datas[ 'statusId' ];
        this.trans = datas[ 'trans' ];
        this.types = datas[ 'types' ] || [];
        this.members = datas[ 'members' ] || [];
        this.address = new Address(datas[ 'address' ] || {});
        this.socialMediaAccounts = datas[ 'socialMediaAccounts' ] || [];
        this.website = datas[ 'website' ] || '';
        this.isNetworkHead = datas[ 'isNetworkHead' ];
        this.networks = datas[ 'networks' ];
        this.artsdataId = datas[ 'artsdataId' ];
        this.wikidataId = datas[ 'wikidataId' ];
        this.tourAccess = datas[ 'tourAccess' ] != null ? !!datas[ 'tourAccess' ] : null;
    }

    /**
     * Converts the organization to a JSON object.
     * @returns {Record<string, unknown>} The JSON object representing the organization.
     */
    toJSON() {
        return {
            ...this,
            tourAccess: this.tourAccess != null ? +this.tourAccess : null
        };
    }

    /**
     * Converts the organization to a list item.
     * @param {string} lang - The language to use for translation.
     * @returns {ListItem} The list item representing the organization.
     */
    public toListItem(lang: string): ListItem {
        const listItem: ListItem = {
            itemTitle: this.getTranslatedProperty(lang, 'name'),
            itemSubtitle: '',
            itemImgSrc: this.logo,
            itemUrl: '',
            itemInfo2: this.phone,
            itemInfo3: this.address ? this.address.city : '',
            itemInfo4: '',
            itemStatus: this.statusId,
            banners: []
        };
        if (this.address) {
            listItem.itemInfo4 = this.address.getTranslatedState(lang);
            listItem.itemInfo4 = listItem.itemInfo4 === '' ? this.address.otherState : ''; //fallback get otherState
        }

        return listItem;
    }

    /**
     * Gets the coordinates form group for the organization.
     * @param {FormBuilder} fb - The form builder to use for creating the form group.
     * @param {string} lang - The language to use for translation.
     * @returns {FormGroup} The coordinates form group for the organization.
     */
    getCoordFormGroup(lang: string, shouldValidate?: boolean, globals?: Globals): FormGroup {
        /**
         * Returns an array of ValidatorFns based on a provided value
         * and a flag to determine if validation should occur.
         * @param {string} value - The string value used to create a regular expression.
         * @param {boolean} shouldValidate - Flag to determine if validation should occur.
         * @returns {ValidatorFn[]} - An array of ValidatorFns
         */
        function getRegExpValidator(value: string, shouldValidate: boolean): ValidatorFn[] {
            const regExp = WikiArtsDataValidation.getRegExp(value);
            if (!regExp || !shouldValidate) {
                return [];
            }
            return [ RegexpValidator.validate(regExp, 'gm') ];
        }
        if (!this.coordForm) {
            this.coordForm = new FormGroup({
                name: new FormControl(this.getTranslatedProperty(lang, 'name'), [ Validators.required, noWhitespaceValidator ]),
                address1: new FormControl(this.address.address1, [ Validators.required, noWhitespaceValidator ]),
                city: new FormControl(this.address.city, [ Validators.required, noWhitespaceValidator ]),
                zipcode: new FormControl(this.address.zipCode, [ Validators.required, noWhitespaceValidator ]),
                province: new FormControl(this.address.otherState, [ Validators.required, noWhitespaceValidator ]),
                country: new FormControl(this.address.countryId, [ Validators.required ]),
                phone: new FormControl(this.phone, [ Validators.required, noWhitespaceValidator ]),
                phonePostNumber: new FormControl(this.phonePostNumber),
                email: new FormControl(this.email, [ Validators.required, noWhitespaceValidator, globals ? Validators.pattern(globals.emailRegex) : Validators.email ]),
                website: new FormControl(this.website),
                artsdataId: new FormControl(this.artsdataId, getRegExpValidator(WikiArtsDataValidation.artsdata, shouldValidate)),
                wikidataId: new FormControl(this.wikidataId, getRegExpValidator(WikiArtsDataValidation.wikidata, shouldValidate))
            });
        }
        return this.coordForm;
    }

    public coordFormValueChangedListener = (): void => {
        this.coordForm.valueChanges.subscribe((values) => {
            const someAreRequired = Object.keys(this.coordForm.controls).some((controlKey) => {
                const control = this.coordForm.get(controlKey);
                return this.hasRequiredField(control) && !control.value;
            });
            this.coordForm.setErrors(someAreRequired ? { required: true } : null);
        });
    }

    private hasRequiredField = (abstractControl: AbstractControl): boolean => {
        if (!abstractControl.validator) {
            return false;
        }

        const validator = abstractControl.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
    };

    /**
     * Gets the type form group for the organization.
     * @param {FormBuilder} fb - The form builder to use for creating the form group.
     * @returns {FormGroup} The type form group for the organization.
     */
    getTypeFormGroup(fb: FormBuilder): FormGroup {
        if (!this.typeOrgaForm) {
            this.typeOrgaForm = fb.group({
                isProducteur: [ this.types.indexOf(OrganizationTypes.IS_PRODUCTEUR) > -1 ],
                isDiffuseur: [ this.types.indexOf(OrganizationTypes.IS_DIFFUSEUR) > -1 ],
                isSalle: [ this.types.indexOf(OrganizationTypes.IS_SALLE) > -1 ],
                isGovernmental: [ this.types.indexOf(OrganizationTypes.IS_GOVERNMENTAL) > -1 ],
                isProvider: [ this.types.indexOf(OrganizationTypes.IS_PROVIDER) > -1 ],
                isMedia: [ this.types.indexOf(OrganizationTypes.IS_MEDIA) > -1 ],
                isOther: [ this.types.indexOf(OrganizationTypes.IS_OTHER) > -1 ],
                tourAccess: this.tourAccess
            });
            this.typeOrgaForm.setValidators(this.validateTypes);
        }
        return this.typeOrgaForm;
    }

    /**
     * Gets the profile form group for the organization.
     * @param {FormBuilder} fb - The form builder to use for creating the form group.
     * @param {string} lang - The language to use for translation.
     * @returns {FormGroup} The profile form group for the organization.
     */
    getProfileFormGroup(fb: FormBuilder, lang: string): FormGroup {
        if (!this.profileForm) {
            const dateFoundation = this.dateFoundation ? new Date(this.dateFoundation) : null;
            this.profileForm = fb.group({
                logo: [ this.logo, [ Validators.required ] ],
                dateFoundation: [ dateFoundation, [ validateDate, Validators.required ] ],
                neq: [ this.neq ],
                socialMediaAccounts: fb.array([])
            });
        }

        return this.profileForm;
    }

    /**
     * Validates the types of a form group.
     * @param {FormGroup} group - The form group to validate.
     * @returns {ValidationErrors | null} The validation errors or null if the form group is valid.
     */
    validateTypes(group: FormGroup): ValidationErrors | null {
        const hasOnCbChecked = Object.values(group.value).some((value) => value);
        return hasOnCbChecked ? null : { notValid: true };
    }

    getNotificationContent(baseContent: NotificationContent, language: string): NotificationContent {
        return new NotificationContentBuilder(baseContent)
            .setType(this.getTranslatedProperty(language, 'NOTIFICATIONS.ORGANIZATION'))
            .setName(this.getTranslatedProperty(language, 'name'))
            .setImage(this.logo)
            .build();
    }
}

/**
 * Represents a member translation.
 * @interface
 */
export interface IMemberTrans {
    /** The title of the member. */
    title: string;
    /** The ID of the language. */
    langId: string;
}
/**
 * Represents a member.
 * @interface
 */
export interface IMember {
    /** The ID of the member. */
    id: number;
    /** The ID of the role of the member. */
    roleId: number;
    /** The email of the member. */
    email?: string;
    /** The organization of the member. */
    organization?: Organization;
    /** The ID of the organization of the member. */
    organizationId?: number;
    /** An array of translations for the member. */
    trans?: IMemberTrans[];
    /** The ID of the user associated with the member. */
    userId?: number;
    /** The user associated with the member. */
    user?: PxUser;
}

/**
 * Represents an invitation.
 */
export class Invitation {
    /** The ID of the invitation. */
    id: number;
    /** The ID of the organization associated with the invitation. */
    organizationId: number;
    /** The ID of the role associated with the invitation. */
    roleId: number;
    /** The email associated with the invitation. */
    email: string;
    /** The date and time when the invitation was created. */
    createdAt?: Date;
    /** The date and time when the invitation was last updated. */
    updatedAt?: Date;

    /**
     * Creates a new instance of the Invitation class.
     * @param {any} data - The data to initialize the instance with.
     */
    constructor(data: any) {
        this.id = data[ 'id' ];
        this.organizationId = data[ 'organizationId' ];
        this.roleId = data[ 'roleId' ];
        this.email = data[ 'email' ];
        this.createdAt = data[ 'createdAt' ];
        this.updatedAt = data[ 'updatedAt' ];
    }
}
