import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, tap, take } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    private navigating = false;
    private cache: Record<string, Subject<HttpResponse<any>>>;
    constructor(private readonly router: Router) {
        this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.navigating = true;
            this.cache = {};
        });

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => (this.navigating = false));
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method === 'GET' && this.navigating) {
            if (!this.cache[req.urlWithParams]) {
                this.cache[req.urlWithParams] = new Subject<HttpResponse<any>>();

                return next.handle(req).pipe(
                    tap((event) => {
                        if (event instanceof HttpResponse && this.cache[req.urlWithParams]) {
                            this.cache[req.urlWithParams].next(event);
                        }
                    })
                );
            }

            return this.cache[req.urlWithParams].asObservable().pipe(take(1));
        }
        return next.handle(req);
    }
}
