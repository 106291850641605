<div class="sidenav-container">
  <app-section-title title="{{ 'MES-RENCONTRES' | translate }}">
  </app-section-title>

  <section class="sidenav-container__content">
    <div nz-row
         class="sidenav-container__list">
      <nz-list [nzBordered]>
        <!-- LOADING SPINNER-->
        <div *ngIf="isLoading"
             class="loader">
          <i nz-icon
             nzType="loading"
             nzTheme="outline"
             aria-hidden="true"></i>
        </div>

        <!-- LIST -->
        <nz-list-item *ngFor="let meeting of meetingList">
          <div nz-col
               nzSpan="6">
            <div>
              <a [routerLink]="('/pro-meeting/' + meeting.id) | localize">
                <div class="sidebar-image-container">
                  <img *ngIf="meeting.image"
                       class="w-100"
                       style="object-fit: cover; width: 69px;height: 69px;"
                       src="{{ meeting.image }}" alt="" />
                  <img *ngIf="!meeting.image"
                       class="w-100"
                       src="{{ imgFallback }}" alt="" />
                </div>
              </a>
            </div>
          </div>

          <div class="myMeeting-info">
            <div nz-col
                 nzSpan="14"
                 style="display:contents !important;">
              <div style="display:flex; flex-direction: column">
                <div style="display: contents;">
                  <a class="sidenav-container__content__links"
                    [routerLink]="('/pro-meeting/' + meeting.id) | localize">
                    {{ meeting.getTranslatedProperty(this.translate.currentLang, 'name') }}
                  </a>
                  <sub class="sidenav-container__content__sub">
                    {{ meeting.place || '-' }} -
                    <ng-container *ngIf="meeting.dateTo; else dateFrom">
                      {{ meeting.dateFrom| date : 'mediumDate' : 'GMT' }} au
                      {{ meeting.dateTo | date : 'mediumDate' : 'GMT' }}
                    </ng-container>
                    <ng-template #dateFrom>
                      {{ meeting.dateFrom| date : 'mediumDate' : 'GMT' }}
                    </ng-template>
                  </sub>
                </div>
              </div>
            </div>
            <div nz-col
                 nzSpan="4"></div>
          </div>
        </nz-list-item>
      </nz-list>
    </div>
  </section>
</div>
