import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { Tour } from './tour.model';

export class NetworkItem {
  organization: Organization;
  tours: Tour[];
  constructor(data: unknown) {
    this.tours = (data['tours'] as unknown[]).map(v => new Tour(v));
    this.organization = data['organization']
      ? new Organization(data['organization'])
      : null;
  }
}
