import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ItemFileType, ItemRowFile } from '../../model/item-row-file.model';
import { StringUtils } from '../../utils/string-utils';
import { FormItemSelect, FormItemSelectService, FormItemSelectAction } from '../../services/form.row.select.service';
import { filter, shareReplay, share } from 'rxjs/operators';
import {
    getVitrineAdditionalMaterialTypeBaseFormSelectItemList,
    getVitrineAdditionalMaterialTypeBaseList
} from 'src/app/concepts/vitrine/enums/vitrine-additional-material-type.enum';
export interface FileTypeFromMaterialListPayload {
    list: ItemRowFile[];
    fileTypesLength: number;
    options: {
        param: string;
        action: string;
    };
}
@Injectable({ providedIn: 'root' })
export class ItemUploadRepeaterService implements OnDestroy {
    remainingFileTypes: BehaviorSubject<ItemFileType[]> = new BehaviorSubject<ItemFileType[]>([]);
    private itemsSubject: BehaviorSubject<ItemRowFile[]> = new BehaviorSubject<ItemRowFile[]>([]);
    public readonly items$: Observable<ItemRowFile[]> = this.itemsSubject.asObservable().pipe(shareReplay(1));
    private initialTypes: BehaviorSubject<ItemFileType[]> = new BehaviorSubject<ItemFileType[]>([]);
    public readonly initialTypes$: Observable<ItemFileType[]> = this.initialTypes.asObservable();
    /** List of uploaded files, ready to be submitted */
    private uploadedFiles: BehaviorSubject<ItemRowFile[]> = new BehaviorSubject<ItemRowFile[]>(null);
    public uploadedFiles$: Observable<ItemRowFile[]> = this.uploadedFiles.asObservable().pipe(
        filter((item) => !!item),
        share()
    );
    private shouldDisableButtonFromUploadFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public readonly shouldDisableButtonFromUploadFiles$: Observable<boolean> = this.shouldDisableButtonFromUploadFiles.asObservable();
    private addButtonDisableState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public addButtonDisableState$: Observable<boolean> = this.addButtonDisableState.asObservable().pipe(shareReplay(1));
    private lastSelectedTypeId: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
    private destroyed: Subject<void> = new Subject<void>();
    private regExpFileExtract = /.+(\/.+)$/;
    constructor(private formItemSelectService: FormItemSelectService) {}

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    /**
     * Assign initial file types to selectable items
     * Only file types that are not already associated to an uploaded file
     * @param types
     */
    public setInitialTypes = (types: ItemFileType[]): void => this.initialTypes.next(types);

    /**
     * Assign initial file types to selectable items
     * Only file types that are not already associated to an uploaded file
     * @param types
     */
    public setRemainingTypes = (types: ItemFileType[]): void => this.remainingFileTypes.next(types);

    /**
     * Assign the param to the lastSelectedTypeId BehaviorSubject
     * Used by select element in ItemUploadRepeaterComponent
     * @param typeId
     */
    public setLastSelectedTypeId = (typeId: number): void => {
        this.lastSelectedTypeId.next(typeId);
    };

    /**
     * Generates new row and adds it to the list
     * @param {string} param: name of the file type
     * @param {string} action: url for the upload
     * @returns {Observable<boolean>} comsumed by the add row button to set its disabled state
     */
    public addRow = ({ param, action }: Partial<ItemRowFile>): Observable<boolean> => {
        const emptyRowItem: ItemRowFile = this.generateNewItem({
            param,
            action,
            desc: `Fichier ${this.itemsSubject.value.length + 1}`,
            hasUpload: false
        });
        this.addItem(emptyRowItem); // then, add the new empty row to selected the items list
        return of(true);
    };

    /**
     * Removes row from the list and removes the uploaded file
     * @param {ItemRowFile} itemToRemove
     * @returns Observable<{ isDisabled: boolean; items: ItemRowFile[] }> new add row button disable state + updated list of items
     */
    public removeRow = (itemToRemove: ItemRowFile): Observable<{ isDisabled: boolean; items: ItemRowFile[] }> => {
        let isDisabled = false;
        if (!itemToRemove) {
            return;
        }
        // 2 possibilities:
        // [x] 1. lastEmittedItems contains more than one item: just remove the item from the list
        // [x] 2. lastEmittedItems contains one or zero item: reset the file type list
        // [x] 2.1 lastEmittedItems contains zero item: also reset the items list
        const lastEmittedValues: ItemRowFile[] = [...this.itemsSubject.value];
        // Remove from uploaded file ready to be submitted
        this.removeUploadedFile(itemToRemove);
        const remainingItemsAfterRemoval = lastEmittedValues.filter((x: ItemRowFile) => x.id !== itemToRemove.id);
        if (remainingItemsAfterRemoval.length <= 1) {
            if (this.uploadedFiles.value.length) {
                const uploadedFilesTypeIdList = this.uploadedFiles.value.map((x: ItemRowFile) => +x.desc);
                const remainingFileTypes = getVitrineAdditionalMaterialTypeBaseFormSelectItemList().filter(
                    (x: FormItemSelect) => !uploadedFilesTypeIdList.includes(x.id)
                );
                this.setInitialFileTypeItems(remainingFileTypes);
            } else {
                this.setInitialFileTypeItems(getVitrineAdditionalMaterialTypeBaseFormSelectItemList());
            }
            /**
             * ? SOLUTION FOR BUG M548: https://logient.atlassian.net/browse/RIDEAUM-548
             * * Situation when a row with an uploaded file is being removed
             * * BUT the item list still contains an empty row (no uploaded file yet)
             * * SOLUTION: if there's no uploaded files but still an row (empty) in the
             * * item list, the button must be disabled to force the use to select
             * * a file type and to upload a file
             * */
            isDisabled = !this.uploadedFiles.value.length;
            if (!remainingItemsAfterRemoval.length) {
                this.setLastSelectedTypeId(undefined);
                this.setItems([]);
                return of({ isDisabled: false, items: [] });
            }
        }
        /**
         * * if the list contains multiple items, make sure to disable
         * * the button when the number of uploaded files is different from
         * * the number of actual items in the list (one item is empty)
         */
        isDisabled = this.uploadedFiles.value.length !== remainingItemsAfterRemoval.length;
        this.setItems([...remainingItemsAfterRemoval]);
        return of({ isDisabled, items: [...remainingItemsAfterRemoval] });
    };

    /**
     * Sets the initial file type items for the form item select service.
     * @param {ItemFileType[]} types An optional array of ItemFileType objects.
     * @returns {void} No value is returned from this function.
     */
    public setInitialFileTypeItems = (list?: FormItemSelect[]): void => {
        this.formItemSelectService.setInitialItems(list || this.remainingFileTypes.value.map((item): FormItemSelect => ({ id: item.typeId })));
    };

    /**
     * Add a new item to a list of existing items.
     * @param {ItemRowFile} item The new item to add to the subject.
     * @returns {void} No value is returned from this function.
     */
    public addItem = (item: ItemRowFile): void => {
        this.setItems([...this.itemsSubject.value, item]);
    };

    /**
     * Sets the items subject with a new list of items.
     * @param {ItemRowFile[]} items An optional array of existing items.
     * @returns {void} No value is returned from this function.
     */
    public setItems = (items: ItemRowFile[]): void => {
        this.itemsSubject.next([...items]);
    };

    /**
     * Resets the items subject, initial types, and last selected type ID.
     * @returns {void} No value is returned from this function.
     */
    public reset = ({ resetInitialTypes }: { resetInitialTypes: boolean } = { resetInitialTypes: true }): void => {
        this.setItems([]);
        this.setButtonDisabledState(true);
        if (resetInitialTypes) {
            this.setInitialTypes([]);
        }
        this.setLastSelectedTypeId(undefined);
    };

    /**
     * Sets the uploaded files subject with a new array of files.
     * @param {ItemRowFile[]} files The new array of files to set in the uploaded files subject.
     * @returns {void} No value is returned from this function.
     */
    public setUploadedFiles = (files: ItemRowFile[]): void => {
        this.uploadedFiles.next([...files]);
    };

    /**
     * Sets a single uploaded file in the uploaded files subject and selects the last selected type ID.
     * @param {string} file The name of the file to add to the uploaded files subject.
     * @returns {void} No value is returned from this function.
     */
    public setUploadedFile = ({ item, itemId }: { item: ItemRowFile; itemId: string }): void => {
        const uploadedFiles = [...this.uploadedFiles.value];
        const index = uploadedFiles.findIndex((items) => items.file === item.file);
        if (index === -1) {
            this.uploadedFiles.next([...this.uploadedFiles.value, item]);
            // next, select lastSelectedTypeId as the current selected row
            this.formItemSelectService.setSelectedItem(<FormItemSelect>{
                id: this.lastSelectedTypeId.value,
                action: FormItemSelectAction.SELECT
            });
        }
    };

    /**
     * Removes a single uploaded file from the uploaded files subject.
     * @param {ItemRowFile} item The item to remove from the uploaded files subject.
     * @returns {void} No value is returned from this function.
     */
    private removeUploadedFile = ({ file }: ItemRowFile): void => {
        if (!file) {
            return;
        }
        const id = file
            .split(this.regExpFileExtract)
            .filter((x) => !!x.length)
            .join('')
            .split('-')[0]
            .replace(/\//, '');

        const uploadedFiles = [...this.uploadedFiles.value];
        const index = uploadedFiles.findIndex((uploadedItem) => uploadedItem.file.includes(id));
        if (index > -1) {
            uploadedFiles.splice(index, 1);
            this.uploadedFiles.next([...uploadedFiles]);
        }
    };

    /**
     * Sets the initial file type options for the uploaded files subject based on the provided additional material list.
     * If the additional material list is not provided or empty, all available file types are included in the initial options.
     * If the additional material list is provided, the initial options only include the file types that are not already in the list.
     * @param {ItemRowFile[]} additionalMaterialList An array of ItemRowFile objects representing the additional materials uploaded by the user.
     * @returns {void} No value is returned from this function.
     */
    public setFileTypesFromMaterialList = ({ list = [], fileTypesLength, options = undefined }: FileTypeFromMaterialListPayload): void => {
        const { param, action } = options;
        // Complete list of file types to filter
        const vitrineAdditionalMaterialType: ItemFileType[] = getVitrineAdditionalMaterialTypeBaseList();
        // Emit the value to ItemUploadRepeaterComponent
        this.setInitialTypes(vitrineAdditionalMaterialType); // = full list of file types
        if (!list.length) {
            this.setUploadedFiles(list); // = []
            const newItem: ItemRowFile = this.generateNewItem({ desc: `Fichier 1`, action, param, fileTypes: this.initialTypes.value, hasUpload: false });
            this.setItems([newItem]);
            this.setRemainingTypes(vitrineAdditionalMaterialType); // = full list of file types
            return;
        }
        // when there's a list of uploaded files
        this.setUploadedFiles(list);
        this.setItemsFromMaterialList(list, { param, action });

        // Make sure that the initial types available for the selection don't contain the file types
        // already in the list
        const unavailableMaterialTypeList: number[] = list.map((item: ItemRowFile): number => Number(item.desc)).filter((type: number) => !isNaN(type));
        // Only keep the types not in the list in materielSupList
        const updatedVitrineAdditionalMaterialType: ItemFileType[] = vitrineAdditionalMaterialType.filter(
            (item: ItemFileType) => !unavailableMaterialTypeList.includes(item.typeId)
        );
        this.setRemainingTypes(updatedVitrineAdditionalMaterialType);
        this.setAddButtonDisabledStateFromList(list.length, fileTypesLength);
    };

    public setButtonDisabledState = (newState: boolean): void => {
        this.addButtonDisableState.next(newState);
    };

    public setDisableButtonFromUploadFiles = (newState: boolean): void => {
        this.shouldDisableButtonFromUploadFiles.next(newState);
    };

    /**
     * Generates a new item of type ItemRowFile with the provided properties.
     * @param {Object} props An object containing the properties of the new item.
     * @param {ItemFileType[]} props.fileTypes An array of item file types.
     * @param {string} props.desc A description for the new item.
     * @param {string} props.action An action for the new item.
     * @param {string} props.param A parameter for the new item.
     * @returns {ItemRowFile} A new item of type ItemRowFile.
     */
    private generateNewItem = ({ fileTypes, desc, action, param, file, hasUpload }: ItemRowFile): ItemRowFile => ({
        id: StringUtils.generateUUID(),
        desc,
        file: file || '',
        action,
        param: param || 'vitrineImages',
        fileTypes,
        hasUpload
    });

    private setAddButtonDisabledStateFromList = (listLength: number, totalItemsLength: number): void => {
        /**
         * * The button must NOT be disabled WHEN
         * 1. the list of items is empty
         * 2. there's still items that can be added
         */
        if (!listLength) {
            this.setButtonDisabledState(false);
        } else if (listLength < totalItemsLength) {
            this.setButtonDisabledState(false);
            return;
        }

        this.setButtonDisabledState(true);
    };

    private setItemsFromMaterialList = (list: ItemRowFile[], { param, action }: { param: string; action: string }): void => {
        const updatedList = list
            .map(
                (x: ItemRowFile): ItemRowFile => {
                    const file = x.file;
                    /**
                     * ? RegExp Rule
                     ** the file is formatted as "https://storageaccuat.blob.core.windows.net/uploads%2FvitrineImages/6304163923667463-202005121319.pdf"
                     ** We need to extract a substring from a file name
                     ** 1. regex pattern matches the last forward slash '/' and any characters that come before it in the file path.
                     ** 2. 'filter' function to remove any empty strings.
                     ** 3. 'join' function to create a String from Array member
                     ** 4. split again, this time using a hyphen '-' as the delimiter and extracts the 2nd member of the Array, the file name
                     */
                    const fileName = file
                        .split(this.regExpFileExtract)
                        .filter((x) => !!x.length)
                        .join('')
                        .split('-')[1];
                    const list = getVitrineAdditionalMaterialTypeBaseFormSelectItemList();
                    const fileType = this.formItemSelectService.getFileTypeById(+x.desc, list);
                    if (!fileType) {
                        return;
                    }

                    return this.generateNewItem({
                        id: x.id || StringUtils.generateUUID(),
                        desc: fileName,
                        param: param || 'vitrineImages',
                        action,
                        file: x.file,
                        fileTypes: [fileType],
                        hasUpload: !!x.file
                    });
                }
            )
            .filter((item) => !!item);
        this.setItems(updatedList);
    };
}
