import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { NzModalService } from 'ng-zorro-antd';
import { concat, forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { Globals } from '../../../../_configs/globals';
import { AccountService } from '../../../account/services/account.service';
import { AuthService } from '../../../account/services/auth/auth.service';
import { OrganizationService } from '../../../organization/services/organization.service';
import { ProductTypes } from '../../../product/enums/product-types-enum';
import { Product } from '../../../product/model/product.model';
import { ProductService } from '../../../product/services/product.service';
import { Meeting } from '../../model/meeting.model';
import { ParticipantMeeting } from '../../model/participant.model';
import { MeetingService } from '../../services/meeting.service';

@Component({
    selector: 'app-meeting-inscription',
    templateUrl: './meeting-inscription.component.html',
    styleUrls: ['./meeting-inscription.component.scss']
})
export class MeetingInscriptionComponent implements OnInit, OnDestroy {
    isReady = false;
    currentLang = this.translate.currentLang;
    firstTime = true;
    isLoading = false;
    showCreditCards = false;
    hasOrganisation = false;

    meetingId: number;
    meeting: Meeting;
    participants: ParticipantMeeting[] = [];
    teamMembers: any[] = [];
    products: Product[] = [];

    private currentOrganisationId: number;

    facture = {
        subtotal: null,
        taxTps: null,
        taxTvq: null,
        total: null
    };

    formatter = new Intl.NumberFormat('fr-CA', {
        style: 'currency',
        currency: 'CAD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2
    });

    private subscription: Subscription;

    constructor(
        private router: Router,
        private global: Globals,
        private route: ActivatedRoute,
        private authService: AuthService,
        private translate: TranslateService,
        private modalService: NzModalService,
        private accountService: AccountService,
        private meetingService: MeetingService,
        private productService: ProductService,
        private localizeRouter: LocalizeRouterService,
        private notification: RideauNotificationService,
        private organizationService: OrganizationService,
        private breadcrumbService: BreadcrumbService,
        private notificationService: RideauNotificationService
    ) {}

    ngOnInit(): void {
        this.meetingId = this.route.snapshot.params['rencontreId'];
        this.isLoading = true;
        this.initStuff();
        this.subscription = this.accountService.currentOrganizationChange.subscribe(() => this.initStuff());
    }

    private initStuff(): void {
        this.currentOrganisationId = this.accountService.getCurrentCtxOrganizationId();
        forkJoin(this.getMeeting(), this.getParticipants(), this.getTeam())
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                if (this.currentOrganisationId === this.global.NO_ORGA) {
                    this.hasOrganisation = false;
                } else {
                    this.hasOrganisation = true;
                }
            });
    }

    private getParticipants(): Observable<any> {
        const IS_PAID = false;
        return this.meetingService.getAllMeetingParticipantsForCurrentOrg(this.meetingId, IS_PAID).do((participants) => {
            this.participants = participants;
            this.updateFullPrice();
        });
    }

    private getMeeting(): Observable<any> {
        return this.meetingService.getMeetingById(this.meetingId).do((data) => {
            this.meeting = data;

            // Set Custom Breadcrumb item list
            const breadcrumbItems: BreadcrumbItem[] = [];
            const meetingName = this.meeting.getTranslatedProperty(this.currentLang, 'name');
            const meetingUrl = '/pro-meeting/' + this.meetingId;
            breadcrumbItems.push(new BreadcrumbItem({ title: meetingName, url: meetingUrl }));
            breadcrumbItems.push(new BreadcrumbItem({ title: 'PARTICIPER' }));

            this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);

            this.meeting.toSingleMeeting(this.currentLang);
            this.isReady = true;
        });
    }

    private getTeam(): Observable<any> {
        // Si utilisateur sans organisation, on ne fais pas le call
        if (this.currentOrganisationId !== this.global.NO_ORGA) {
            return this.organizationService.getOrganizationTeam(this.currentOrganisationId).do((res) => (this.teamMembers = res['members']));
        } else {
            return of({});
        }
    }

    /**
     * Renvoie les membres de l'organisation, moins ceux qui sont déjà
     * dans la liste des inscriptions.
     */
    getFilteredTeam(): any[] {
        return this.teamMembers.filter((member) => {
            const found = this.participants.find((part) => part.member.id === member.id);
            return found === undefined;
        });
    }

    public addMemberToMeeting(memberId: any): void {
        this.isLoading = true;

        const user: any = {
            organizationId: this.currentOrganisationId,
            meetingId: this.meetingId
        };

        if (memberId === null) {
            // utilisateur sans orga
            user.userId = this.authService.User.id;
        } else {
            // utilisateur membre d'une orga
            user.memberId = memberId;
        }

        this.meetingService.addParticipant(user).subscribe((res) => {
            if (res['participant'] && res['participant']['memberId'] && res['participant']['memberId'] !== memberId) {
                this.notificationService.error(this.translate.instant('ERRORS.DEJA-AJOUTE'));
            } else if (this.hasOrganisation) {
                this.notificationService.success(this.translate.instant('AJOUTE'));
            }

            this.getParticipants()
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe();
        });
    }

    public removeParticipant(id: number): void {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            // DELETE SERVICE CALL
            nzOnOk: () => {
                this.isLoading = true;
                this.meetingService
                    .removeParticipant(this.meetingId, id)
                    .flatMap(() => this.getParticipants())
                    .pipe(finalize(() => (this.isLoading = false)))
                    .subscribe();
            }
        });
    }

    addProduct(product: Product, participant: ParticipantMeeting): void {
        this.doAddProduct(product, participant).subscribe();
    }

    private doAddProduct(product: Product, participant: ParticipantMeeting): Observable<any> {
        const tarifId = product.tariffs.length > 0 ? product.tariffs[0].id : null;
        const gratuityId = product.gratuities && product.gratuities.length > 0 ? product.gratuities[0].id : null;

        return this.meetingService.addProductToParticipant(this.meeting.id, participant.id, product.id, tarifId, gratuityId).do((res) => {
            if (res.isCreated) {
                participant.products.push(product);
                this.updateFullPrice();
            } else {
                this.notification.error(this.translate.instant('ERRORS.PRODUIT-DEJA-PAYE'));
            }
        });
    }

    /**
     * Si le produit à supprimer est gratuit ou à prix simple, on fait juste l'appel service.
     * Si il est à tarifs multiples, c'est plus compliqué.
     * @param product
     * @param participant
     */
    removeProduct(product: Product, participant: ParticipantMeeting): void {
        if (product.tariffs && product.tariffs.length > 0) {
            this.complicatedRemoveProduct(product, participant);
        } else {
            this.doRemoveProduct(product, participant).subscribe();
        }
    }

    private doRemoveProduct(product: Product, participant: ParticipantMeeting): Observable<any> {
        return this.meetingService.removeProductFromParticipant(this.meeting.id, participant.id, product.id).do(() => {
            participant.products = participant.products.filter((prod) => prod.id !== product.id);
            this.updateFullPrice();
        });
    }

    /**
     * Dans le cas de la supression d'un produit à tarif multiple, afin d'éviter la triche, on
     * va retirer ce produit à tous les participants qui l'ont, puis le ré-affecter un par un pour
     * que
     * @param product
     * @param participant
     */
    private complicatedRemoveProduct(product: Product, participant: ParticipantMeeting): void {
        this.isLoading = true;

        // on fait la liste de tous les participants qui ont ce produit
        const impactedParticipants = this.participants.filter((participant) => {
            const found = participant.products.find((prod) => prod.id === product.id);
            return found !== undefined;
        });
        // On supprime le produit pour tous ces participants
        const arrayOfSuppressionObs = impactedParticipants.map((part) => this.doRemoveProduct(product, part));

        forkJoin(arrayOfSuppressionObs)
            .flatMap(() => {
                // Pour chaque participant impacté, ont doit faire la requete GetProducts, trouver le produit
                // avec le bon tarif, et l'ajouter avant de passer au participant suivant.
                const arrayOfAdditionObs = impactedParticipants
                    // Bien sûr, on ne veut rajouter le produit à celui-ci, on vien de cliquer pour lui supprimer.
                    .filter((part) => part.id !== participant.id)
                    .map((part) => this.getProductAndAddIt(product, part));

                if (arrayOfAdditionObs.length === 0) {
                    this.isLoading = false;
                }

                return concat(...arrayOfAdditionObs);
            })
            .subscribe(() => {
                this.isLoading = false;
            });
    }

    private getProductAndAddIt(product: Product, participant: ParticipantMeeting): Observable<any> {
        return this.getRightProductForParticipant(product, participant).flatMap((theRightproduct: Product) => this.doAddProduct(theRightproduct, participant));
    }

    /**
     * Récupère les produits et trouve celui désiré pour le participant.
     */
    private getRightProductForParticipant(product: Product, participant: ParticipantMeeting): Observable<Product> {
        const filters = [
            {
                field: 'meetingId',
                value: participant.meetingId
            },
            {
                field: 'productTypeId',
                value: ProductTypes.INSCRIPTION
            },
            {
                field: 'organizationId',
                value: this.currentOrganisationId
            },
            {
                field: 'cheaperPrice',
                value: 1
            },
            {
                field: 'userId',
                value: participant.user.id
            }
        ];
        return this.productService.getProducts(filters).map((products) => products.find((prod) => prod.id === product.id));
    }

    /**
     * Mise à jour des totaux et taxes
     */
    public updateFullPrice(): void {
        // Reducer pour faire la somme de tous les sous-toutaux des participants
        this.facture.subtotal = this.participants.reduce((participantsSum, participant) => {
            // Reducer pour faire la somme des produits d'un participant
            const participantSubTotal = participant.products
                .filter((product) => !product.isPaid)
                .reduce((productsSum, prod) => {
                    if (prod.gratuities && prod.gratuities.length > 0) {
                        productsSum += Number(prod.gratuities[0].price);
                    } else if (prod.tariffs && prod.tariffs.length > 0) {
                        productsSum += Number(prod.tariffs[0].price);
                    } else if (prod.singlePrice) {
                        productsSum += Number(prod.singlePrice);
                    }
                    return productsSum;
                }, 0);
            return participantsSum + participantSubTotal;
        }, 0);

        const taxTps = this.participants.reduce((participantsTpsSum, participant) => {
            // Reducer pour faire la somme des produits d'un participant
            const participantSubTotal = participant.products
                .filter((product) => !product.isPaid && product.taxable)
                .reduce((productsSum, prod) => {
                    if (prod.gratuities && prod.gratuities.length > 0) {
                        productsSum += Number(prod.gratuities[0].price);
                    } else if (prod.tariffs && prod.tariffs.length > 0) {
                        productsSum += Number(prod.tariffs[0].price);
                    } else if (prod.singlePrice) {
                        productsSum += Number(prod.singlePrice);
                    }
                    return productsSum;
                }, 0);

            let participantTaxTps = participantSubTotal * this.global.taxes.TPS;

            return participantsTpsSum + participantTaxTps;
        }, 0);

        const taxTvq = this.participants.reduce((participantsTvqSum, participant) => {
            // Reducer pour faire la somme des produits d'un participant
            const participantSubTotal = participant.products
                .filter((product) => !product.isPaid && product.taxable)
                .reduce((productsSum, prod) => {
                    if (prod.gratuities && prod.gratuities.length > 0) {
                        productsSum += Number(prod.gratuities[0].price);
                    } else if (prod.tariffs && prod.tariffs.length > 0) {
                        productsSum += Number(prod.tariffs[0].price);
                    } else if (prod.singlePrice) {
                        productsSum += Number(prod.singlePrice);
                    }
                    return productsSum;
                }, 0);

            let participantTaxTvq = +participantSubTotal * +this.global.taxes.TVQ;

            return participantsTvqSum + participantTaxTvq;
        }, 0);

        this.facture.taxTps = taxTps.toFixed(2);
        this.facture.taxTvq = taxTvq.toFixed(2);
        this.facture.total = Number(this.facture.subtotal + taxTps + taxTvq).toFixed(2);
    }

    /**
     * ouvre le dropdown de paiement par carte
     */
    public payNow(): void {
        this.showCreditCards = !this.showCreditCards;

        setTimeout(() => {
            scrollTo(2000, 1000);
        }, 100);
    }

    /**
     * Payement des inscriptions
     * @param paymentInformations
     */
    public checkout(paymentInformations): void {
        const paiementInfos: any = {
            paymentMethodId: Number(paymentInformations.selectedCard),
            cardCvv: paymentInformations.cvvNumber,
            frontAmount: Number(this.facture.total),
            meetingId: this.meetingId,
            participants: this.participants.map((participant) => participant.id)
        };

        if (this.currentOrganisationId !== this.global.NO_ORGA) {
            paiementInfos.organizationId = this.currentOrganisationId;
        }

        this.isLoading = true;

        this.meetingService.payForInscription(paiementInfos).subscribe(
            (res) => {
                this.showCreditCards = false;
                this.notificationService.success(this.translate.instant('PAIEMENT-ACCEPTE'));

                // naviguer vers la facture
                if (this.hasOrganisation) {
                    const route: any[] = this.localizeRouter.translateRoute(['/organization', this.currentOrganisationId, 'bill', res['transactionId']]) as any[];
                    this.router.navigate(route);
                } else {
                    // naviguer vers la rencontre
                    this.router.navigate(['../../', this.meetingId], {
                        relativeTo: this.route
                    });
                }
            },
            (err) => {
                this.isLoading = false;
                if (err.status === 500) {
                    this.notificationService.error(this.translate.instant('ERRORS.PAIEMENT-ERROR'));
                }
                if (err.status === 409) {
                    // Si 409, on laisse le error-handler général faire son travail
                    throw err;
                } else if (err.status === 402) {
                    this.notificationService.error(this.translate.instant('ERRORS.CREDIT-CARD-DECLINED'));
                }
                if (err.error.stack.includes('GatewayError: Unexpected Gateway Response: 509 - Security Code/CVV2/CVC must be 3 digits')) {
                    this.notificationService.error(this.translate.instant('ERRORS.PAIEMENT-ERROR'));
                } else if (err.error.stack.includes('No products to pay for the listed participants')) {
                    this.notificationService.error(this.translate.instant('ERRORS.SELECT-PRODUCT'));
                }
            }
        );
    }

    // appelle le service qui vide la liste, puis retourne sur la fiche Rencontre
    cancel(): void {
        if (this.hasOrganisation) {
            this.isLoading = true;
            this.meetingService
                .cancelInscription(this.meetingId)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe(() => {
                    this.router.navigate(['../../', this.meetingId], {
                        relativeTo: this.route
                    });
                });
        } else {
            this.router.navigate(['../../', this.meetingId], {
                relativeTo: this.route
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
