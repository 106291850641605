<div>
  <div class="info-header">
    {{ 'MEETING-PRODUCTS-INFOS' | translate }}
  </div>

  <nz-divider></nz-divider>

  <!-- LIST -->

  <div *ngFor="let product of products"
       class="product-wrapper">
    <div class="product-line">
      <div class="product-title">
        {{ product.getTranslatedProperty(lang, 'name')}}
      </div>
      <div>
        {{ 'PRODUCT-TYPE.' + product.productTypeId | translate }}
      </div>
      <div class="price">
        {{getPrice(product)}}
      </div>
    </div>
    <ng-container *ngIf="!product.isFree && !product.singlePrice && product.tariffs?.length">
      <div class="tarif-line"
           *ngFor="let tarif of product.tariffs">
        <div class="tarif-title">
          {{ getTarfiTypeLabel(tarif)}}
        </div>
        <div>
          {{ getVolumeTypeLabel(tarif) }}
        </div>
        <div class="price">
          {{ formatter.format(tarif.price) }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
