import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getVitrineTypesList } from '../../../../../vitrine/enums/vitrine-type.enum';
import { ActivityVitrine, Vitrine } from '../../../../../vitrine/model/vitrine.model';
import { Activity } from '../../../../model/activity.model';

@Component({
    selector: 'app-activity-vitrines-form',
    templateUrl: './activity-vitrines-form.component.html',
    styleUrls: ['./activity-vitrines-form.component.scss']
})
export class ActivityVitrinesFormComponent implements OnInit {
    @Input() activity: Activity;
    @Input() selectedVitrine: ActivityVitrine;
    @Input() meetingVitrinesList: Vitrine[];

    @Output() cancel = new EventEmitter<any>();
    @Output() saveChanges = new EventEmitter<ActivityVitrine>();

    vitrineForm: FormGroup;
    vitrineTypes = getVitrineTypesList();
    lang = this.translate.currentLang;

    constructor(private fb: FormBuilder, public translate: TranslateService) {}

    ngOnInit(): void {
        // this.fb = new FormGroup();
        this.initVitrineForm();
        // console.log(this.selectedVitrine)
    }

    initVitrineForm(): void {
        this.vitrineForm = this.fb.group({
            vitrineId: new FormControl(!this.selectedVitrine ? undefined : this.selectedVitrine.vitrineId.toString(), [Validators.required]),
            vitrineTypeId: new FormControl(!this.selectedVitrine ? undefined : this.selectedVitrine.vitrineTypeId.toString(), [Validators.required]),
            duration: new FormControl(!this.selectedVitrine ? undefined : this.selectedVitrine.duration, [Validators.required])
        });
    }

    saveVitrine(): void {
        if (this.vitrineForm.valid) {
            const theVitrine: ActivityVitrine = new ActivityVitrine({
                id: this.selectedVitrine ? this.selectedVitrine.id : undefined,
                duration: parseInt(this.vitrineForm.get('duration').value),
                vitrineId: parseInt(this.vitrineForm.get('vitrineId').value),
                vitrineTypeId: parseInt(this.vitrineForm.get('vitrineTypeId').value)
            });

            // console.log(this.selectedVitrine)
            this.saveChanges.emit(theVitrine);
        } else {
            // form is not valid
            // console.log('form is not valid')
        }
    }

    dismiss(): void {
        this.cancel.emit(this.selectedVitrine ? this.selectedVitrine.id : null);
    }
}
