import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-read-only-action",
  templateUrl: "./read-only-action.component.html",
  styleUrls: ["./read-only-action.component.scss"],
})
export class ReadOnlyActionComponent implements OnInit {
  private _details: string;

  @Input() isUrl: boolean;
  @Input() title: string;
  @Input() set details(detail: string) {
    this._details = detail ? detail.replace(/<[^>]*>/g, "") : "";
  }

  get details() {
    return this._details;
  }

  @Input() showDetailsColumn: boolean = true;
  @Output() EditClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  onEditClick() {
    this.EditClicked.emit(true);
  }
}
