export enum OrganizationTypes {
    VACANT = 0,
    IS_PRODUCTEUR = 1,
    IS_DIFFUSEUR = 2,
    IS_SALLE = 3,
    IS_GOVERNMENTAL = 4,
    IS_PROVIDER = 5,
    IS_MEDIA = 6,
    IS_OTHER = 7,
}
