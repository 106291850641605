<div class="item-row">
  <ng-container *ngIf="!hideThumbnails">
      <div class="item-row__col item-row__col--avatar item-row__avatar">
        <span class="item-row__avatar-container">
          <img class="img-responsive item-row__avatar-src"
               src="{{ item.itemImgSrc }}"
               alt="">
        </span>
      </div>
  </ng-container>

  <div class="item-row__infos">
    <div class="item-row__col__row-title">
      <div class="d-sm-flex ">
        <p class="item-row__text item-row__text--title">
          <ng-container *ngIf="item.itemUrl; else noUrl">
            <a class="item-row__text-url"
              [routerLink]="item.itemUrl | localize">{{ item.itemTitle }}</a>
          </ng-container>
          <ng-template #noUrl>
              {{ item.itemTitle }}
          </ng-template>
        </p>
      </div>
      <p class="item-row__text item-row__text--subtitle">{{ item.itemSubtitle }}</p>
    </div>
    <div class="item-row__col__row-desc">
      <p class="item-row__text ">{{ item.itemInfo1 | translate | shortenText }}</p>
      <p class="item-row__text">{{ item.itemInfo2 | translate}}</p>
    </div>
    <div class="item-row__col"
          style="margin-right:10px;">
      <div *ngIf="enableEditing" >
      <input name="participantType"
             class="btn-rect"
             [(ngModel)]="item.itemInfo3"
             (change)="updateParticipantType()"/>
      </div>
    </div>
    <div *ngIf="enableEditing || enableRemoving"
         class="item-row__col__row-options">
      <div class="d-flex">
        <button type="button"
                style="margin-right:10px"
                class="btn-square"
                *ngIf="enableEditing"
                [disabled]="isEditInscriptions"
                (click)="modifyInscriptions()"><i class="icon-pencil" aria-hidden="true"></i></button>
        <button type="button"
                style="margin-right:10px"
                class="btn-square"
                *ngIf="enableRemoving"
                (click)="removeItemForm()"><i class="icon-trash" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
</div>
<!-- INSCRIPTION LIST -->
<div *ngIf="isEditInscriptions" class="form-inscription">
  <div class="form-inscription__head">
    {{ 'INSCRIPTIONS' | translate}}
  </div>

    <div *ngFor="let product of products">
      <div class="item-row">
        <div class="item-row__infos">
          <div class="item-row__col__row-title">
            <p class="item-row__text item-row__text--subtitle">{{ product.itemTitle }}</p>
          </div>
        </div>
        <button type="button"
                *appPermission="5"
                class="btn-square"
                style="margin:10px"
                (click)="deleteProduct(product.itemId)"><i class="icon-trash" aria-hidden="true"></i></button>

      </div>
    </div>
  <div class="form-filter__footer text-md-right p-3">
    <button type="button"
            class="btn-rect px-3 d-inline-block mr-md-3"
            (click)="cancelEditProducts()"
            style="padding-top:5px">{{ 'ANNULER' | translate }}</button>
    <button class="btn d-inline-block"
            [disabled]="productsToDelete.length == 0"
            (click)="submitDeletedProducts()"
            style="padding: 1px 16px 1px 17px">{{ 'ENREGISTER' | translate }}</button>
  </div>

</div>

