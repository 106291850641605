import { Component, OnInit, Input } from '@angular/core';
import { Globals } from 'src/app/_configs/globals';
import { Tag } from 'src/app/concepts/tags/model/tags.model';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { SelectedFilter } from 'src/app/shared/model/list-item.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-etiquettes-mini',
    templateUrl: './etiquettes-mini.component.html',
    styleUrls: ['./etiquettes-mini.component.scss']
})
export class EtiquettesMiniComponent implements OnInit {
    @Input() tags: Tag[];
    defaultImg: string;
    currentLang: string;

    constructor(private router: Router, private globals: Globals, private translate: TranslateService, private localizeRouter: LocalizeRouterService) {}

    ngOnInit(): void {
        this.defaultImg = this.globals.imgFallback;
        this.currentLang = this.translate.currentLang;
    }

    redirectToShowList(id: number) {
        const tagsFilter: SelectedFilter[] = [{ field: 'tagIds', value: id }];
        sessionStorage.setItem('showAdvFilters', JSON.stringify(tagsFilter));

        const singleEtiquetteUrl = this.localizeRouter.translateRoute('/offres-de-spectacles').toString();

        this.router.navigate([singleEtiquetteUrl]);
    }
}
