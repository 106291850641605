import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToursService } from '../../services/tours.service';
import { Tour } from '../../model/tour.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { flatMap } from 'rxjs/operators';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { TourShow } from '../../model/tour-show.model';
import { LocalizeRouterService } from 'localize-router';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { NetworkService } from 'src/app/concepts/organization/services/network.service';

@Component({
  selector: 'app-edit-tour-settings',
  templateUrl: './edit-tour-settings.component.html',
  styles: [
    `
      [nzType='loading'] {
        font-size: 40px;
      }
      .footer {
        min-height: 120px;
        background: #ecebe7;
        padding-top: 40px;
      }
      .container {
        padding-bottom: 20px;
      }
      .h2 {
        margin-bottom: 20px;
      }
    `,
  ],
})
export class EditTourSettingsComponent implements OnInit {
  form: FormGroup;
  tour: Tour;
  sourceTours: Tour[] = [];
  maxDateSelectedOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
  ];
  tourShows: TourShow[];

  constructor(
    readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly toursService: ToursService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly formBuilder: FormBuilder,
    private readonly accountService: AccountService,
    private readonly router: Router,
    private readonly localizeRouter: LocalizeRouterService,
    private readonly networkService: NetworkService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
        sourceTourId: null,
        maxDateSelected: [3, Validators.required]
      },
      { validators: [dateRangeValidator] }
    );

    if (this.activatedRoute.snapshot.params.tourId) {
      this.loadData().subscribe(this.update.bind(this));
    } else {
      this.breadcrumbService.addBreadcrumbCascade(
        [
          new BreadcrumbItem({
            title: this.translateService.instant('TOURNEE-SANS-TITRE'),
          }),
          new BreadcrumbItem({
            title: this.translateService.instant('MODIFIER'),
          }),
        ],
        true
      );

      if (this.activatedRoute.snapshot.params.sourceTourId) {
        this.form.get('sourceTourId').setValue(Number(this.activatedRoute.snapshot.params.sourceTourId));
      }
    }

    this.networkService
      .getNetworksTours(this.accountService.getCurrentCtxOrganizationId())
      .subscribe(networks => {
        const network = networks.find(network => network.organization.id === this.accountService.getCurrentCtxOrganizationId());
        this.sourceTours = network ? network.tours : [];
      });
  }

  onSubmit() {
    const tour = new Tour({
      ...this.tour,
      ...this.form.value,
      isAvailable: this.form.value.isAvailable != null ? !this.form.value.isAvailable : true,
      organizationId: this.accountService.getCurrentCtxOrganizationId(),
      isPaid: true,
      trans: [
        ...(this.tour ? this.tour.trans : []),
        {
          langId: this.translateService.currentLang,
          name: this.form.value.name,
        },
      ],
    });

    if (this.tour) {
      return this.toursService
        .updateTour(tour)
        .pipe(flatMap(() => this.loadData()))
        .subscribe(this.update.bind(this));
    } else {
      return this.toursService.createTour(tour).subscribe((data) => {
        this.router.navigate([
          this.localizeRouter.translateRoute(`/tour/${data.tour.id}/edit/settings`),
        ]);
      });
    }
  }

  private loadData() {
    return zip(
      this.toursService.getTourById(
        +this.activatedRoute.snapshot.params.tourId
      ),
      this.toursService.getSubmittedTourShows([
        { field: 'tourId', value: +this.activatedRoute.snapshot.params.tourId },
        {
          field: 'organizationId',
          value: this.accountService.getCurrentCtxOrganizationId(),
        },
      ])
    ).pipe(take(1));
  }

  private update([tour, tourShows]: [Tour, TourShow[]]): void {
    this.breadcrumbService.addBreadcrumbCascade(
      [
        new BreadcrumbItem({
          title: tour.getTranslatedProperty(
            this.translateService.currentLang,
            'name'
          ),
          url: `/tour/${tour.id}/edit`,
        }),
        new BreadcrumbItem({
          title: this.translateService.instant('MODIFIER'),
        }),
      ],
      true
    );
    this.tour = tour;
    this.tourShows = tourShows;
    this.form.addControl('isAvailable', new FormControl());
    this.form.setValue({
      name: tour.getTranslatedProperty(this.translateService.currentLang, 'name'),
      startDate: tour.startDate,
      endDate: tour.endDate,
      sourceTourId: tour.sourceTourId,
      maxDateSelected: tour.maxDateSelected,
      isAvailable: !tour.isAvailable
    });
  }
}

function dateRangeValidator(control: AbstractControl) {
  const startDate: Date = control.value.startDate;
  const endDate: Date = control.value.endDate;

  if (startDate && endDate) {
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);

    if (endDate.getTime() < startDate.getTime()) {
      return { dateRange: true };
    }
  }

  return null;
}
