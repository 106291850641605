import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'floatNumber'
})
export class FloatNumberPipe implements PipeTransform {
    /**
     *Transforms a number value into a string representation.
     * @param {number} value - The number value to transform.
     * @param {[string | RegExp, 'comma' | 'space']} [regexp = /./s, replacementChar] - The regular expression or string pattern to replace.
     * @returns {string} The transformed string representation of the number value.
     */
    transform<T>(value: number | string, [replacementChar, regexp = /\./s]: ['comma' | 'space' | 'dot', string | RegExp]): T {
        if (!value) {
            return;
        }
        if (typeof value === 'string') {
            return (Number(value.replace(regexp, '.')) as unknown) as T;
        }
        let replacement: string;
        switch (replacementChar) {
            case 'comma':
                replacement = ',';
                break;
            case 'space':
                replacement = ' ';
                break;
            case 'dot':
                replacement = '.';
                break;
            default:
                replacement = this.getFirstSpecialCharacter(value);
        }
        if (!Number.isInteger(value)) {
            return (value.toString().replace(regexp, replacement) as unknown) as T;
        }
        return (value.toString() as unknown) as T;
    }

    private getFirstSpecialCharacter = <T>(value: T): string => {
        const r = new RegExp(/\D/, 's');
        const m = r.exec(value.toString());
        if (m && m.length) {
            return m[0];
        }
    };
}
