import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { minutesToTime } from 'src/app/shared/utils/time-utils';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-blank.validator';
import { Organization, Network } from '../../organization/model/organization.model';
import { ShowAudiences } from '../enums/show-audiences.enum';
import { Promotion } from '../../promotion/model/promotion.model';
import { Tag } from '../../tags/model/tags.model';
import { Observable, of } from 'rxjs';
import { OneOrManyValidator } from '@app/shared/validators/oneOrMany.validator';
import { ShowStatus } from '../enums/show-status.enum';
import { OneOrManyValidatorArrayValues } from '@app/shared/validators/oneOrManyArrayValues.validator';
import { ItemRowFile } from '@app/shared/model/item-row-file.model';
import { OneOrManyFilesValidator } from '@app/shared/validators/oneOrManyFiles.validator';
import { richTextMaxLength } from '@app/shared/components/rich-text/rich-text-max-length';
import { NotificationContent, NotificationContentBuilder } from '@app/concepts/notifications/model/notifications.model';
import { Globals } from '@app/_configs/globals';
export interface IShow {
    id: number;
    statusId: ShowStatus;
    image: string;
    urlPressPhoto1: string;
    lblPressPhoto1: string;
    urlPressPhoto2: string;
    lblPressPhoto2: string;
    urlPromoPhoto1: string;
    lblPromoPhoto1: string;
    urlPromoPhoto2: string;
    lblPromoPhoto2: string;
    // les durations sont exprimées en secondes
    durationTotal: number;
    durationBreak: number;
    durationAssembly: number;
    durationDisassembly: number;
    urlTechSpecification: string;
    capacity: number;
    personsOnRoad: number;
    hasViolence: number;
    isAvailable: boolean;
    isTrainingAvailable: boolean;
    // Les mesures sont exprimées en milimetres
    trayWidth: number;
    trayHeight: number;
    trayDepth: number;
    trayMaxCost: number;
    trayMinCost: number;
    isActive: number;
    isPaid: number;
    organizationId: number;
    urlAudio: string;
    urlVideo: string;
    personsOnStage: number;
    hasBreak: number;
    hasSensibilisation: boolean;
    // Representant section
    representative: string;
    repEmail: string;
    repPhone: string;

    updatedAt: any;

    producer: Organization;

    isFavorite: boolean;
    isMarketed: boolean;
    itemUrl?: string;
    itemUrlExt?: boolean;
}

export interface IShowContact {
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    email: string;
    phonePostNumber?: string;
}

export interface IContactsResponse {
    showId: number;
    contacts: Array<IShowContact>
}

export interface IShowProducer {
    langId: string;
    name: string;
}

export type StringProperties<T> = { [K in keyof T]: T[K] extends string ? K : T[K] extends string | undefined ? K : never }[keyof T];

export class ShowProducer extends Translatable implements IShowProducer {
    langId: string;
    name: string;
    constructor(datas: Record<string, any>) {
        super();
        this.trans = datas['trans'];
        this.langId = datas['langId'];
        this.name = datas['name'];
    }
}
export class Show extends Translatable implements IShow {
    id: number;
    statusId: ShowStatus;
    image: string;
    urlPressPhoto1: string;
    lblPressPhoto1: string;
    urlPressPhoto2: string;
    lblPressPhoto2: string;
    urlPromoPhoto1: string;
    lblPromoPhoto1: string;
    urlPromoPhoto2: string;
    lblPromoPhoto2: string;
    urlAudio: string;
    urlVideo: string;
    artistUrl?: string;
    personsOnStage: number;
    hasBreak: number;
    hasSensibilisation: boolean;
    languages: string[];
    // les durations sont exprimées en secondes
    durationTotal: number;
    durationBreak: number;
    durationAssembly: number;
    durationDisassembly: number;
    urlTechSpecification: string;
    capacity: number;
    personsOnRoad: number;
    hasViolence: number;
    hasNudity: number;
    isAvailable: boolean;
    isTrainingAvailable: boolean;
    // Les mesures sont exprimées en milimetres
    trayWidth: number;
    trayHeight: number;
    trayDepth: number;
    trayMaxCost: number;
    trayMinCost: number;
    isActive: number;
    isPaid: number;
    organizationId: number;
    producerId: number;
    // proprieté récuperées séparément
    artists?: any[];
    contacts?: ShowContact[];
    audiences?: number[];
    classifications?: ShowAudiences[];
    organization?: Organization;
    favorite?: ShowFavorites;
    // Representant section
    representative: string;
    repEmail: string;
    repPhone: string;

    updatedAt: any;
    createdAt: Date;
    isPromoted: number;
    promotions: Promotion[];

    tags: Tag[];
    isFavorite: boolean;
    producer: Organization;

    isMarketed: boolean;
    itemUrl?: string;
    itemUrlExt?: boolean;

    private identityForm: FormGroup;
    private classificationForm: FormGroup;
    private cachetForm: FormGroup;
    private additionalMaterialForm: FormGroup;

    constructor(datas: Record<string, any>) {
        super();
        this.id = datas['id'];
        this.statusId = datas['statusId'];
        this.image = datas['image'];
        this.urlPressPhoto1 = datas['urlPressPhoto1'];
        this.lblPressPhoto1 = datas['lblPressPhoto1'];
        this.urlPressPhoto2 = datas['urlPressPhoto2'];
        this.lblPressPhoto2 = datas['lblPressPhoto2'];
        this.urlPromoPhoto1 = datas['urlPromoPhoto1'];
        this.lblPromoPhoto1 = datas['lblPromoPhoto1'];
        this.urlPromoPhoto2 = datas['urlPromoPhoto2'];
        this.lblPromoPhoto2 = datas['lblPromoPhoto2'];
        this.durationTotal = datas['durationTotal'];
        this.durationBreak = datas['durationBreak'];
        this.durationAssembly = datas['durationAssembly'];
        this.durationDisassembly = datas['durationDisassembly'];
        this.urlTechSpecification = datas['urlTechSpecification'];
        this.capacity = datas['capacity'];
        this.personsOnRoad = datas['personsOnRoad'];
        this.hasViolence = datas['hasViolence'];
        this.hasNudity = datas['hasNudity'];
        this.trayWidth = datas['trayWidth'];
        this.trayHeight = datas['trayHeight'];
        this.trayDepth = datas['trayDepth'];
        this.trayMaxCost = datas['trayMaxCost'];
        this.trayMinCost = datas['trayMinCost'];
        this.isActive = datas['isActive'];
        this.isPaid = datas['isPaid'];
        this.organizationId = datas['organizationId'];
        this.trans = datas['trans'];
        this.classifications = datas['classifications'];
        this.artists = datas['artists'];
        this.contacts = datas['contacts'];
        this.audiences = datas['audiences'];
        this.urlAudio = datas['urlAudio'];
        this.urlVideo = datas['urlVideo'];
        this.artistUrl = datas['artistUrl'];
        this.isMarketed = datas['isMarketed'];
        this.personsOnStage = datas['personsOnStage'];
        this.hasBreak = datas['hasBreak'];
        this.isAvailable = datas['isAvailable'] != null ? !!datas['isAvailable'] : undefined;
        this.hasSensibilisation = datas['hasSensibilisation'] != null ? !!datas['hasSensibilisation'] : undefined;
        this.isTrainingAvailable = datas['isTrainingAvailable'] != null ? !!datas['isTrainingAvailable'] : undefined;
        this.languages = datas['languages'];
        this.representative = datas['representative'];
        this.repEmail = datas['repEmail'];
        this.repPhone = datas['repPhone'];
        this.updatedAt = datas['updatedAt'];
        this.organization = datas['organization'] ? new Organization(datas['organization']) : undefined;
        this.favorite = datas['favorite'] ? new ShowFavorites(datas['favorite']) : undefined;
        this.isPromoted = datas['isPromoted'];
        this.promotions = datas['promotions'];
        this.tags = datas['tags'] ? datas['tags'].map((el) => new Tag(el)) : undefined;
        this.producerId = datas['producerId'];
        this.producer = datas['producer'] ? new Organization(datas['producer']) : null;
        this.createdAt = datas['createdAt'] ? datas['createdAt'] : null;
        this.itemUrl = datas['itemUrl'] ? datas['itemUrl'] : null;
        this.itemUrlExt = datas['itemUrlExt'] ? datas['itemUrlExt'] : false;
    }

    toJSON() {
        return {
            ...this,
            isAvailable: this.isAvailable != null ? +this.isAvailable : undefined,
            hasSensibilisation: this.hasSensibilisation != null ? +this.hasSensibilisation : undefined,
            isTrainingAvailable: this.isTrainingAvailable != null ? +this.isTrainingAvailable : undefined
        };
    }

    toListItem(lang: string): ListItem {
        const item = new ListItem();

        item.itemId = this.id;
        item.itemTitle = this.getTranslatedProperty(lang, 'title');
        item.itemSubtitle = '';
        item.itemImgSrc = this.image;
        item.itemUrl = this.itemUrl;
        item.itemUrlExt = this.itemUrlExt;
        item.itemInfo1 = this.organization ? this.organization.getTranslatedProperty(lang, 'name') : '';
        item.itemStatus = this.statusId;
        //item.itemInfo4 = this.isPromoted && this.isPromoted === 1 ? '1' : '';

        if (this.isPromoted && this.isPromoted === 1) {
            item.banners.push({
                iconName: 'star-icon.svg',
                iconBackgroundColor: '#FE544D'
            });
        }

        item.organizationId = this.organizationId;

        return item;
    }

    getIdentificationFormGroup(lang: string, globals?: Globals): FormGroup {
        if (!this.identityForm) {
            this.identityForm = new FormGroup({
                title: new FormControl(this.getTranslatedProperty(lang, 'title'), [Validators.required, noWhitespaceValidator]),
                artists: new FormControl(this.artists.map((artist) => artist.name), [Validators.required]),
                organizationId: new FormControl(this.organizationId, [Validators.required]),
                producerId: new FormControl(this.producerId),
                image: new FormControl(this.image, [Validators.required]),
                isTrainingAvailable: new FormControl(this.isTrainingAvailable),
                hasSensibilisation: new FormControl(this.hasSensibilisation),
                isAvailable: new FormControl(this.isAvailable),
                languages: new FormControl(this.languages, [Validators.required]),
                artistUrl: new FormControl(this.artistUrl),
                representative: new FormControl(this.representative, [Validators.required]),
                repEmail: new FormControl(this.repEmail, [Validators.required, globals ? Validators.pattern(globals.emailRegex) : Validators.email]),
                repPhone: new FormControl(this.repPhone, [Validators.required]),
                shortDescription:
                    new FormControl(this.getTranslatedProperty(lang, 'shortDescription'), [Validators.required, richTextMaxLength(globals ? globals.MAXDESCRIPTIONLENGHT400 : 400)]),
                longDescription:
                    new FormControl(this.getTranslatedProperty(lang, 'longDescription'), [Validators.required, richTextMaxLength(globals ? globals.MAXDESCRIPTIONLENGHT1800 : 1800)]),
                coProducer: new FormControl(this.getTranslatedProperty(lang, 'coProducer'), [Validators.maxLength(100)])

            });
        }
        return this.identityForm;
    }

    getClassificationFormGroup(): FormGroup {
        if (!this.classificationForm) {
            this.classificationForm = new FormGroup({
                discipline: new FormControl(undefined),
                disciplines: new FormArray([], {
                    validators: [OneOrManyValidatorArrayValues.validate('cascade')]
                }),
                audiences: new FormGroup(
                    {
                        isAllPublic: new FormControl(this.audiences.indexOf(ShowAudiences.GRAND_PUBLIC) > -1),
                        isFamily: new FormControl(this.audiences.indexOf(ShowAudiences.FAMILLES) > -1),
                        isBabies: new FormControl(this.audiences.indexOf(ShowAudiences.BEBES) > -1),
                        isInfants: new FormControl(this.audiences.indexOf(ShowAudiences.TOUT_PETITS) > -1),
                        isSmallKids: new FormControl(this.audiences.indexOf(ShowAudiences.PETIT_ENFANTS) > -1),
                        isKids: new FormControl(this.audiences.indexOf(ShowAudiences.ENFANTS) > -1),
                        isYoung: new FormControl(this.audiences.indexOf(ShowAudiences.JEUNES) > -1),
                        isAdults: new FormControl(this.audiences.indexOf(ShowAudiences.ADULTES) > -1),
                        isElders: new FormControl(this.audiences.indexOf(ShowAudiences.AINES) > -1)
                    },
                    {
                        validators: [OneOrManyValidator.validate()]
                    }
                ),
                hasViolence: new FormControl(this.hasViolence === 1),
                hasNudity: new FormControl(this.hasNudity === 1)
            });
        }

        return this.classificationForm;
    }

    getCachetFormGroup(fb: FormBuilder): FormGroup {
        if (!this.cachetForm) {
            const durTot = minutesToTime(this.durationTotal);
            const durAs = minutesToTime(this.durationAssembly);
            const durDis = minutesToTime(this.durationDisassembly);
            this.cachetForm = fb.group({
                coutPlateauMin: [this.trayMinCost || 0],
                coutPlateauMax: [this.trayMaxCost || 0],
                showDurationHour: [durTot.h],
                showDurationMinutes: [durTot.m],
                showHasEntracte: [this.hasBreak === 1],
                showEntracteDuration: [this.durationBreak || 0],
                showMountingDurationHour: [durAs.h],
                showMountingDurationMinutes: [durAs.m],
                showDemountingDurationHour: [durDis.h],
                showDemountingDurationMinutes: [durDis.m],
                plateauWidth: [this.trayWidth || 0],
                plateauDepth: [this.trayDepth || 0],
                plateauHeight: [this.trayHeight || 0],
                jaugeMax: [this.capacity || 0],
                urlTechSpecification: [this.urlTechSpecification || ''],
                personOnRoad: [this.personsOnRoad || 0],
                personsOnStage: [this.personsOnStage || 0]
            });
        }
        return this.cachetForm;
    }

    getAdditionalMaterialFormGroup(endpoint: string): FormGroup {
        if (!this.additionalMaterialForm) {
            this.additionalMaterialForm = new FormGroup(
                {
                    promoPhoto1: new FormControl({
                        file: this.urlPromoPhoto1,
                        desc: this.lblPromoPhoto1,
                        action: `${endpoint}upload/show/promo1`,
                        param: 'showpromo1'
                    }),
                    promoPhoto2: new FormControl({
                        file: this.urlPromoPhoto2,
                        desc: this.lblPromoPhoto2,
                        action: `${endpoint}upload/show/promo2`,
                        param: 'showpromo2'
                    }),
                    pressPhoto1: new FormControl({
                        file: this.urlPressPhoto1,
                        desc: this.lblPressPhoto1,
                        action: `${endpoint}upload/show/press1`,
                        param: 'showpress1'
                    }),
                    pressPhoto2: new FormControl({
                        file: this.urlPressPhoto2,
                        desc: this.lblPressPhoto2,
                        action: `${endpoint}upload/show/press2`,
                        param: 'showpress2'
                    }),
                    urlVideo: new FormControl(this.urlVideo),
                    urlAudio: new FormControl(this.urlAudio)
                },
                {
                    validators: [
                        OneOrManyFilesValidator.validate<ItemRowFile>({
                            fields: ['file', 'desc'],
                            formControlNameList: ['promoPhoto1', 'promoPhoto2', 'pressPhoto1', 'pressPhoto2']
                        })
                    ]
                }
            );
        }
        return this.additionalMaterialForm;
    }

    requiredPropAreNotEmpty(currentLang: string): Observable<boolean> {
        return of(
            !!this.id &&
            !!this.getTranslatedProperty(currentLang, 'title') &&
            !!this.image &&
            !!this.getTranslatedProperty(currentLang, 'shortDescription') &&
            !!this.getTranslatedProperty(currentLang, 'longDescription') &&
            !!this.artists.length &&
            !!this.representative &&
            !!this.repEmail &&
            !!this.repPhone &&
            !!this.classifications.length &&
            !!this.audiences.length &&
            !!(this.urlPressPhoto1 || this.urlPressPhoto2 || this.urlPromoPhoto1 || this.urlPromoPhoto2)
        );
    }

    get isSubmitted() {
        return this.statusId === ShowStatus.SOUMIS;
    }

    get isApproved() {
        return this.statusId === ShowStatus.APPROUVE;
    }

    get isNotSubmitted() {
        return this.statusId === ShowStatus.NON_SOUMIS;
    }

    get isRejected() {
        return this.statusId === ShowStatus.REJETE;
    }

    get isExpired() {
        return this.statusId === ShowStatus.EXPIRE;
    }

    getNotificationContent(baseContent: NotificationContent, language: string): NotificationContent {
        return new NotificationContentBuilder(baseContent)
            .setType(this.getTranslatedProperty(language, 'NOTIFICATIONS.VENUE'))
            .setName(this.getTranslatedProperty(language, 'name'))
            .setImage(this.image)
    }
}
interface FormGroupControlValue {
    pressPhoto1: ItemRowFile;
    pressPhoto2: ItemRowFile;
    promoPhoto1: ItemRowFile;
    promoPhoto2: ItemRowFile;
    urlVideo: string;
    urlAudio: string;
}
export interface ShowAudiencesFormGroupValues {
    isAllPublic?: boolean;
    isFamily?: boolean;
    isBabies?: boolean;
    isInfants?: boolean;
    isSmallKids?: boolean;
    isKids?: boolean;
    isYoung?: boolean;
    isAdults?: boolean;
    isElders?: boolean;
}
export class ShowContact implements IShowContact {
    id: number;
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    email: string;
    phonePostNumber?: string;
    constructor(datas: Record<string, any>) {
        this.id = datas['id'];
        this.firstName = datas['firstName'] || '';
        this.lastName = datas['lastName'] || '';
        this.title = datas['title'] || '';
        this.phone = datas['phone'] || '';
        this.phonePostNumber = datas['phonePostNumber'] || '';
        this.email = datas['email'] || '';
    }
}
export class ShowFavorites {
    userId?: number;
    organizationId?: number;
    networkIds?: number[];
    userFavorite?: number;
    organizationFavorite?: number;
    networkFavorite?: number;

    organization?: Organization;
    network?: Network;

    constructor(datas?: any) {
        this.userId = datas['userId'] ? datas['userId'] : 0;
        this.organizationId = datas['organizationId'] ? datas['organizationId'] : 0;
        this.networkIds = datas['networkIds'] ? datas['networkIds'] : 0;
        this.userFavorite = datas['userFavorite'] ? datas['userFavorite'] : 0;
        this.organizationFavorite = datas['organizationFavorite'] ? datas['organizationFavorite'] : 0;
        this.networkFavorite = datas['networkFavorite'] ? datas['networkFavorite'] : 0;
        this.organization = datas['organization'] ? new Organization(datas['organization']) : null;
        this.network = datas['network'] ? new Network(datas['network']) : null;
    }
}
