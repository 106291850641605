import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product } from '../../concepts/product/model/product.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductTypes } from '../../concepts/product/enums/product-types-enum';
import { SelectedFilter } from '../../shared/model/list-item.model';
import { ProductService } from '../../concepts/product/services/product.service';
import { CreditCardService } from '../../concepts/payment/services/credit-card.service';
import { Transaction } from '../../concepts/payment/models/transaction.model';
import { AccountService } from '../../concepts/account/services/account.service';
import { AuthService } from '../../concepts/account/services/auth/auth.service';
import { ShoppingCartService } from '../../concepts/account/services/shopping-cart.service';
import { LocalizeRouterService } from 'localize-router';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Program } from '../../concepts/program/model/program.model';
import { ProgramStatus } from '../../concepts/program/model/program-status.model';
import { ReportsService } from 'src/app/concepts/reports/reports.service';
import { Calendar } from 'src/app/concepts/program/model/calendar.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Organization } from '../../concepts/organization/model/organization.model';
import { ProgramRouteData } from 'src/app/concepts/program/program-route-resolvers';
import { environment } from 'src/environments/environment';
import { ResolverListnerService } from 'src/app/shared/services/resolver-listner.service';
import { SessionStorageService } from '@app/shared/services/storage.service';
import { ShowStatus } from '@app/concepts/show/enums/show-status.enum';

@Component({
    selector: 'app-calendar-page',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarPageComponent implements OnInit, OnDestroy {
    public readonly programStatus = ProgramStatus;

    public calendarTransactions: Transaction[] = [];
    public date = new Date();
    public displayLock: boolean;
    public isCalendarsFilterReady: boolean;
    public isListView: boolean;
    public isResolving = false;
    public listData: Program[] = [];
    public myCalendars: Calendar[];
    public organization: Organization;
    public routeData: ProgramRouteData;
    public tourId: number;

    private calendarProduct: Product;
    private destroyed: Subject<void> = new Subject();
    private filterForOrganization = (program: Program): boolean => {
        return this.accountService.getCurrentCtxOrganizationId() === program.organizationId;
    };
    private getCurrentContextOrganization = (): void => {
        this.orgId = this.accountService.getCurrentCtxOrganizationId();
        if (this.orgId !== -1) {
            const currentContextOrganization: Organization = this.sessionStorage.getItem(this.sessionStorageOrganizationKey);
            if (currentContextOrganization && currentContextOrganization.id === this.orgId) {
                this.organization = currentContextOrganization;
                return;
            }
            this.organizationService
                .getOrganization(this.orgId)
                .pipe(takeUntil(this.destroyed))
                .subscribe((organization: Organization) => {
                    this.organization = organization;
                    this.sessionStorage.setItem(this.sessionStorageOrganizationKey, organization);
                });
            this.accountService.checkUserType(this.orgId);
        }
    };
    private getDataForList = (): void => {
        // If the current view is not a list view, exit the function
        if (!this.isListView) {
            return;
        }
        // If 'programs' does not exist in routeData or if it exists but is an empty array, set listData to an empty array and exit the function
        const hasProgramsList = 'programs' in this.routeData && !!this.routeData.programs.length;
        const hasTourProgramsList = 'tourPrograms' in this.routeData && !!this.routeData.tourPrograms.length;
        if (!hasProgramsList && !hasTourProgramsList) {
            return;
        }

        // If 'programs' exists in routeData and is not an empty array, proceed with the following operations
        const programs = hasProgramsList ? [...this.routeData.programs] : hasTourProgramsList ? [...this.routeData.tourPrograms] : [];
        if (!programs.length) {
            return;
        }
        this.listData = programs
            // Filter out programs that do not have a date
            .filter((prog: Program) => !!prog.date)
            // Map each program to a new Program object. If the date property of the program is a string, convert it to a Date object
            .map(
                (prog: Program) =>
                    new Program({
                        ...prog,
                        date: typeof prog.date === 'string' ? new Date(prog.date) : prog.date
                    })
            )
            // Keep programs that have the same month and year as the selected date
            .filter((prog: Program) => prog.date.getMonth() === this.date.getMonth() && prog.date.getFullYear() === this.date.getFullYear())
            // Filter programs that only belong to the organization with which the user is connected
            .filter((program: Program) => this.filterForOrganization(program));
    };

    private orgId: number;
    private sessionStorageOrganizationKey = 'sp.currentContextOrganization';
    showStatusObject = ShowStatus;

    constructor(
        private productService: ProductService,
        private creditCardService: CreditCardService,
        private accountService: AccountService,
        private authService: AuthService,
        public translateService: TranslateService,
        private shoppingCartService: ShoppingCartService,
        private localizeRouter: LocalizeRouterService,
        private reportService: ReportsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private readonly organizationService: OrganizationService,
        private sessionStorage: SessionStorageService,
        private resolverListenerService: ResolverListnerService
    ) {}

    public addDate(): void {
        this.routeData.tourAdmin
            ? this.router.navigate([this.localizeRouter.translateRoute(`/programmations/tour/${this.routeData.tour.id}/add`)])
            : this.router.navigate([this.localizeRouter.translateRoute(`/programmations/add`)]);
    }

    public exportCalendarExcel(): void {
        this.reportService.generateCalendarXlsx(this.routeData.tour);
    }

    public exportCalendarIcs(): void {
        this.reportService.generateCalendar(this.tourId);
    }

    public formatHour(hour: string): string {
        const hourDate = new Date(hour);

        return new Date(hourDate.getTime() - hourDate.getTimezoneOffset() * 60000).toISOString().split('T')[1].substring(0, 5);
    }

    public getCalendarProduct(): void {
        const filters: SelectedFilter[] = [
            {
                field: 'productTypeId',
                value: ProductTypes.PROGRAM
            }
        ];
        this.productService
            .getProducts(filters)
            .pipe(takeUntil(this.destroyed))
            .subscribe((products: Product[]) => {
                products = products.filter((product) => product.isAlwaysValid === 1 || product.isNotExpired()).map((product) => product.setFinalPrice());
                this.calendarProduct = products.reduce((minPrice, product) => (parseInt(product.singlePrice) < parseInt(minPrice.singlePrice) ? product : minPrice), products[0]);
            });
    }

    public getTransactions(): void {
        let transactions: any = [];
        if (this.orgId !== -1) {
            transactions = this.creditCardService.getTransactionsProductTypeHistory(this.orgId, ProductTypes.PROGRAM);
        } else {
            transactions = this.creditCardService.getUserTransactionsProductTypeHistory(parseInt(this.authService.User.id), ProductTypes.PROGRAM);
        }

        return transactions.subscribe((bills) => {
            this.calendarTransactions = bills.filter(
                (bill) => bill.transactionProducts && bill.statusId == 10 && new Date(bill.createdAt) > new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            );
            this.displayLock = this.calendarTransactions.length ? false : true;
        });
    }

    public manageCalendars(): void {
        this.router.navigate(['./calendriers'], { relativeTo: this.activatedRoute });
    }

    public ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    public ngOnInit(): void {
        this.resolverListenerService.isResolvingObservable$.pipe(takeUntil(this.destroyed)).subscribe((isResolving) => {
            this.isResolving = isResolving;
        });
        (this.activatedRoute.data as Observable<ProgramRouteData>).pipe(takeUntil(this.destroyed)).subscribe((routeData) => {
            this.routeData = { ...routeData, ...this.activatedRoute.snapshot.queryParams } as ProgramRouteData;
            this.myCalendars = routeData.calendars;
            this.isCalendarsFilterReady = true;
            this.initializeDefaultDate();
            this.getDataForList();
        });

        this.getCalendarProduct();
        if (new Date() > new Date(environment.CALENDAR_FREE_UNTIL)) {
            this.getTransactions();
        } else {
            this.displayLock = false;
        }

        this.getCurrentContextOrganization();
        this.tourId = this.activatedRoute.snapshot.params['tourId'];
    }

    public onMonthSelect(date: Date): void {
        this.date = date;
        this.isListView && this.getDataForList();
    }

    public onViewChange(isListView: boolean): void {
        this.isListView = isListView;
        this.getDataForList();
    }

    public onYearSelect(date: Date): void {
        this.date = date;
        this.isListView && this.getDataForList();
    }

    public getOrganizationName(program: Program): string {
        // refactor this code with typescript optional chaining
        if ('show' in program && program.show && program.show.organization) {
            return program.show.organization.getTranslatedProperty(this.translateService.currentLang, 'name');
        } else if ('organization' in program && program.organization) {
            return program.organization.getTranslatedProperty(this.translateService.currentLang, 'name');
        }
        return '';
    }

    public payNow(): void {
        //Add to cart then redirect to Shopping Cart
        this.addToCart()
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                const url = this.localizeRouter.translateRoute(`/shopping-cart`);
                this.router.navigate([url]);
            });
    }

    private addToCart(): Observable<any> {
        const cartProduct: any = {
            productTypeId: ProductTypes.PROGRAM,
            productId: this.calendarProduct.id,
            userId: parseInt(this.authService.User.id),
            quantity: 1,
            itemId: 1
        };

        if (this.orgId !== -1) {
            cartProduct.organizationId = this.orgId;
        }

        return this.shoppingCartService.addToCart(cartProduct);
    }

    private initializeDefaultDate(): void {
        if (this.routeData.date) {
            this.date = new Date(+this.routeData.date);
            return;
        }
        if (this.routeData.tour) {
            this.date = new Date(this.routeData.tour.startDate);
        }
    }
    public isShowExpired = (program: Program): boolean => {
        if (!program.show) {
            return false;
        }
        return program.show.statusId === this.showStatusObject.EXPIRE;
    };
}
