import { ItemCardBanner } from "./item-card-banner.model";

export class ListItem {
  itemId?: number;
  itemTitle: string;
  itemSubtitle: string;
  itemImgSrc: string;
  itemDate?: string;
  itemCategory?: string;
  itemUrl?: string;
  itemUrlExt?: boolean;
  itemInfo1?: string;
  itemInfo2?: string;
  itemInfo3?: string;
  itemInfo4?: string;
  itemInfo5?: string;
  itemStatus?: number;
  itemPrice?: string;
  isEmpty?: boolean = false;
  itemList?: ListItem[] = [];
  itemRatings?: any;
  linkedShowExpired?: boolean;
  organizationId?: number;
  itemIsToKeep?: boolean;
  isPublication?: number;
  organizationName?: string;

  banners?: ItemCardBanner[] = [];

  constructor(data?: Record<string, any>) {
    if (data && data['isEmpty']) {
      this.isEmpty = data['isEmpty'];
      this.banners = [];
    }
  }
}

export class Filter {
  filterField: string;
  filterName: string;
  filterValues: FilterValue[];
}

export class FilterValue {
  filterValueName: string;
  filterValue: any;
  selected?: boolean;
  filterTotal?: number;
}

export class SelectedFilter {
  field: string;
  value: any;
}

export class Sort {
  sortName: string;
  sortValue: any;
}

export class Pagination {
  // Index du premier élément retourné dans la liste de tous les éléments
  offset: number;
  // nombre max d'éléments à retourner
  limit: number;
  // nombre total d'éléments avant pagination.
  total?: number;

  constructor(offset?: number, limit?: number, total?: number) {
    this.offset = offset;
    this.limit = limit;
    this.total = total;
  }
  getPage(): number {
    return Math.ceil(this.offset / this.limit) + 1;
  }

  getTotal(): number {
    return isNaN(this.total) ? 100 : this.total;
  }
}
