import { Routes } from '@angular/router';
import { MyCalendarsComponent } from './components/my-calendars/my-calendars.component';
import { ProgramSingleComponent } from './components/program-single/program-single.component';
import { CalendarPageComponent } from 'src/app/pages/calendar/calendar.component';
import { SiteConcepts } from 'src/app/shared/enums/site-concepts.enum';
import { ProgramRouteResolvers, TourProgramRouteResolvers, TourShowProgramRouteResolvers } from './program-route-resolvers';
import { ProgramAdminComponent } from './components/program-admin/program-admin.component';

export const ProgrammationRoutes: Routes = [
    {
        path: 'tour/:tourId/tour-show/:tourShowId',
        component: CalendarPageComponent,
        resolve: TourShowProgramRouteResolvers,
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'tour/:tourId',
        component: CalendarPageComponent,
        resolve: TourProgramRouteResolvers,
        runGuardsAndResolvers: 'always'
    },
    {
        path: '',
        component: CalendarPageComponent,
        resolve: ProgramRouteResolvers,
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'calendriers',
        component: MyCalendarsComponent,
        data: {
            breadcrumb: [SiteConcepts.PROGRAM, SiteConcepts.SINGLE],
            appPermission: 1,
            organizationId: 1
        }
    },
    {
        path: 'add',
        component: ProgramSingleComponent,
        data: {
            breadcrumb: [SiteConcepts.PROGRAM, SiteConcepts.SINGLE]
        }
    },
    {
        path: 'tour/:tourId/add',
        component: ProgramAdminComponent,
        data: {
            breadcrumb: [SiteConcepts.PROGRAM, SiteConcepts.SINGLE]
        }
    },
    {
        path: ':progId',
        component: ProgramSingleComponent,
        data: {
            breadcrumb: [SiteConcepts.PROGRAM, SiteConcepts.SINGLE]
        }
    }
];
