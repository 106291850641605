import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import { RowTypes } from '../../../../shared/enums/row-types.enum';
import { Filter, FilterValue, ListItem, Pagination, SelectedFilter } from '../../../../shared/model/list-item.model';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { AccountService } from '../../../account/services/account.service';
import { ActivityCategories } from '../../enums/activity-categories.enum';
import { Activity } from '../../model/activity.model';
import { ActivityService } from '../../services/activity.service';

@Component({
    selector: 'app-activity-list',
    templateUrl: './activity-list.component.html',
    styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnInit {
    @Input() meetingId: number;
    @Input() enableFilters: boolean;
    @Input() enableEditing: boolean;
    @Input() enabledDefaultfilters: boolean;
    @Output() activityListSize: EventEmitter<any> = new EventEmitter<any>();
    @Output() activityListLength: EventEmitter<any> = new EventEmitter<any>();

    isLoading = false;
    searchText: string;
    currentLang = this.translate.currentLang;

    activities: Activity[];
    activityListItems: ListItem[] = [];

    // Configs
    private selectedFilters: SelectedFilter[] = [];
    filterList: Filter[] = [];
    pagination: Pagination = new Pagination();
    listType: RowTypes = RowTypes.ACTIVITY;

    defaultFilters: SelectedFilter[] = [
        {
            field: 'isAvailable',
            value: ''
        }
    ];

    constructor(
        private activityService: ActivityService,
        private translate: TranslateService,
        private modalService: NzModalService,
        private accoutService: AccountService,
        private notification: RideauNotificationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.initFilters();
        this.initPagination();
        this.defaultFiltersCheck();
        this.getActivities();
    }

    getActivities(): void {
        this.isLoading = true;

        const allFilters = [...this.defaultFilters, ...this.selectedFilters];

        this.activityService.getActivitiesForMeeting(this.meetingId, allFilters, this.pagination, this.searchText).subscribe((data: Activity[]) => {
            this.activities = data;
            this.activityListItems = this.getActivityListItems(data);
            this.activityListSize.emit(this.pagination.total);
            this.isLoading = false;
        });
    }

    getActivityListItems(activityList: Activity[]): ListItem[] {
        const activityRoute = this.translate.instant('ROUTES.activity');
        return activityList.map((activity) => activity.toListItem(this.currentLang, activityRoute));
    }

    initFilters(): void {
        this.selectedFilters = [];
        this.filterList = [];
        this.getActivitiesFilters();
    }

    getActivitiesFilters(): void {
        const categoriesFilter = new Filter();
        categoriesFilter.filterField = 'categoryId';
        categoriesFilter.filterName = this.translate.instant('ACTIVITIES');
        categoriesFilter.filterValues = Object.keys(ActivityCategories)
            .filter((val) => isNaN(Number(val)))
            .map((key) => {
                return {
                    filterValueName: this.translate.instant('ACTIVITY-CATEGORIES.' + key),
                    filterValue: ActivityCategories[key]
                } as FilterValue;
            });

        categoriesFilter.filterValues.unshift({
            filterValueName: this.translate.instant('ALL-CATEGORIES'),
            filterValue: ''
        } as FilterValue);

        this.filterList.push(categoriesFilter);
    }

    initPagination(): void {
        this.pagination.limit = 12;
        this.pagination.offset = 0;
        this.pagination.total = 0;
    }

    onFilterChange(event: SelectedFilter): void {
        // console.log("Filter Changed", event);
        this.selectedFilters = [event];
        this.getActivities();
    }

    onPageChange(event): void {
        // console.log("Pagination Changed", event);
        this.pagination.offset = event.offset;
        this.getActivities();
    }

    modifyActivity(activityId): void {
        // console.log("Navigate to modify url");
        const activity = this.translate.instant('ROUTES.activity');
        const modify = this.translate.instant('ROUTES.modify');
        const editRoute = `/${this.currentLang}/${activity}/${this.meetingId}/${activityId}/${modify}`;

        this.router.navigate([editRoute]);
    }

    onSearchChange(event: string): void {
        this.searchText = event;
        this.getActivities();
    }

    removeActivity(activityId): void {
        const orgaId = this.accoutService.getCurrentCtxOrganizationId();
        this.activityService.removeActivity(activityId, orgaId).subscribe(() => {
            // Notify an reload activity list
            this.notification.success(this.translate.instant('FORM.ACTIVITY-SUPPRIME'));
            this.getActivities();
        });
    }

    defaultFiltersCheck(): void {
        if (this.enabledDefaultfilters) {
            this.defaultFilters[0].value = 1;
        }
    }
}
