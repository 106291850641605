import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { isBlankValidator } from 'src/app/shared/validators/not-blank.validator';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { SocialMediaAccount } from '../../../model/organization.model';
import { AbstractOrganizationForm } from '../organization-abstract-form/organization-abstract-form.component';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { Globals } from '../../../../../_configs/globals';
import { ImgUploadService, ImgUploadState, ImgUploadStatus } from '@app/shared/components/img-upload/img-upload.service';
import { filter } from 'rxjs/operators';
@Component({
    selector: 'app-organization-profile-form',
    templateUrl: './organization-profile-form.component.html',
    styleUrls: ['./organization-profile-form.component.scss']
})
export class OrganizationProfileFormComponent extends AbstractOrganizationForm implements OnInit {
    controlSocialsArray: FormArray;
    socialsArray: any;
    lang: string;
    formGroup: FormGroup;

    public uploadAction: string;
    public imageChangedEvent: any = '';
    public croppedImage: any = '';

    //logo
    logoUrl: string;
    loading = false;

    descriptionModules;
    isBlankDescValidator = isBlankValidator;

    descriptionModel = '';

    constructor(
        private global: Globals,
        private fb: FormBuilder,
        private translate: TranslateService,
        private notification: RideauNotificationService,
        public accountService: AccountService,
        protected globals: Globals,
        private imgUploadService: ImgUploadService
    ) {
        super(accountService, globals);
    }

    ngOnInit(): void {
        registerLocaleData(fr);
        this.lang = this.translate.currentLang;
        this.uploadAction = this.globals.api.ENDPOINT + 'upload/orgImage';
        this.initForm();
        this.imgCurrentStatusHandler();
    }

    protected initForm(): void {
        this.formGroup = this.organization.getProfileFormGroup(this.fb, this.translate.currentLang);
        this.descriptionModel = this.organization.getTranslatedProperty(this.translate.currentLang, 'description');
        this.descriptionModules = this.global.InputRichTextModules;
        //Temp push socialsArray value into social Array
        this.controlSocialsArray = <FormArray>this.formGroup.get('socialMediaAccounts');
        this.organization.socialMediaAccounts.forEach((x) => {
            this.controlSocialsArray.push(this.patchValues(x.getTranslatedProperty(this.lang, 'url')));
        });
    }

    patchValues(url) {
        return this.fb.group({
            url: [url]
        });
    }
    addField(e?: MouseEvent): void {
        if (e) {
            e.preventDefault();
        }
        this.controlSocialsArray.push(this.patchValues(''));
    }
    removeField(index, e: MouseEvent): void {
        e.preventDefault();
        this.controlSocialsArray.controls.splice(index, 1); //remove targetted FormControl from FormArray
    }

    getFormControl(name: string): AbstractControl {
        return this.formGroup.controls[name];
    }

    doSubmit(param?: any) {
        // reafect values for orga, emit for orga emit for submit
        this.organizationValue.setTranslatedProperty(this.translate.currentLang, 'description', this.descriptionModel);
        this.organizationValue.logo = this.formGroup.value.logo;
        this.organizationValue.dateFoundation = this.formGroup.value.dateFoundation;
        this.organizationValue.neq = this.formGroup.value.neq || '';

        //Re-affecter les socialMedias
        this.organizationValue.socialMediaAccounts = this.controlSocialsArray.controls.map((control: FormGroup) => {
            const social: SocialMediaAccount = new SocialMediaAccount({});
            social.setTranslatedProperty(this.lang, 'url', control.value['url']);
            return social;
        });

        super.doSubmit(param);

        this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
    }

    limitDescriptionLength($event) {
        if ($event.editor.getLength() > this.global.MAXDESCRIPTIONLENGHT) {
            $event.editor.deleteText(this.global.MAXDESCRIPTIONLENGHT, $event.editor.getLength());
        }
    }

    // Upload Callback
    onUploadFileCallback($event) {
        this.formGroup.patchValue({
            logo: $event
        });
    }

    private imgCurrentStatusHandler = () => {
        this.imgUploadService.currentStatus$.pipe(filter((x) => x.status !== ImgUploadStatus.INIT)).subscribe((state: ImgUploadState) => {
            switch (state.status) {
                case ImgUploadStatus.UPLOADING:
                    this.loading = true;
                    this.formGroup.get('logo').setErrors({ required: true });
                    break;
                case ImgUploadStatus.DELETED:
                    this.loading = false;
                    this.formGroup.patchValue({ logo: null });
                    break;
                case ImgUploadStatus.SUCCESS:
                case ImgUploadStatus.ERROR:
                    this.loading = false;
                    this.formGroup.get('logo').setErrors(null);
                    break;
            }
            this.formGroup.get('logo').updateValueAndValidity();
        });
    };
}
