<div class="etiquettesLarge">
  <div class="etiquettesLarge_Item" *ngFor="let tag of tagsList">
    <div class="etiquettesLarge_ItemInfo">
      <img [src]="tag.image ? tag.image : defaultImg" alt="">
      <div class="etiquettesLarge_ItemInfoText">
        <p class="etiquettesLarge_ItemInfoTextTitle">
          <a [routerLink]="('/admin/etiquettes/' + tag.id) | localize" title="">{{ tag.getTranslatedProperty(currentLang, 'keyword') }}</a>
        </p>
        <p class="etiquettesLarge_ItemInfoTextSubtitle">
          {{tag.countShows + ' '}}
          {{tag.countShows == 1 ? ("SHOW-OFFER" | translate) : ("SHOWS-OFFERS" | translate) }}
        </p>
      </div>
    </div>
    <button class="etiquettesLarge_ItemDeleteBtn" (click)="deleteTag( tag.id )">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path fill-rule="nonzero" d="M15 7a1 1 0 0 1 1.01.991l.09 10a1 1 0 0 1-1 1.009H5.9a1 1 0 0 1-1-1.009l.09-10a1 1 0 0 1 2 .018L6.91 17h7.18l-.08-8.991A1 1 0 0 1 15 7zM9 7a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm3 0a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm1-6a1 1 0 0 1 1 1v2h3a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2h3V2a1 1 0 0 1 1-1h5zm-1 2H9v1h3V3z"/>
      </svg>
    </button>
  </div>
</div>


