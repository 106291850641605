<app-page-title title="{{ 'PANIER-DACHAT' | translate }}">
</app-page-title>


<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-9 cart">
      <ng-container *ngIf="isReady">
        <ng-container *ngIf="cartItems.length > 0; else noItems">

          <div *ngIf="isLoading"
               class="loader">
            <i nz-icon
               nzType="loading"
               nzTheme="outline"
               aria-hidden="true"></i>
          </div>
          <div class="cart__info-box mb-3">
            <div class="row align-items-center">
              <div class="col-md-8">
                <span>{{ 'PANIER.VOUS-AVEZ' | translate }}</span>
                {{cartItems.length}}
                {{cartItems.length > 1 ? ('PANIER.ITEMS' | translate) : ('PANIER.ITEM' | translate) }}
                {{'PANIER.DANS-VOTRE-PANIER-POUR-UN-TOTAL' |translate}}
                <strong>{{formatter.format(billDetails.total)}}</strong>
              </div>
              <div class="col-md-4 text-right">
                <button class="btn"
                        type="button"
                        *appPermission="6"
                        (click)="payCart()"
                        style="padding-top:7px">{{'PANIER.PAYER' | translate}}
                </button>
              </div>
            </div>
          </div>

          <!-- Choix carte -->
          <div class="form-filter form-filter--right mb-3"
               *ngIf="showCreditCards">
            <app-select-credit-card [processing]="isLoading"
                                    (onPay)="checkout($event)"></app-select-credit-card>
          </div>

          <div class="cart-items mb-4">
            <div class="item-row item-row--light p-3"
                 *ngFor="let item of cartItems">
              <!--TITLE-->
              <div class="item-row__col">
                <p class="item-row__text item-row__text--title d-inline-block">
                  {{item.template.getTranslatedProperty(lang,'name')}}</p>
                <p class="item-row__text item-row__text--subtitle">
                  {{ item.item.getTranslatedProperty(lang,'title') }}</p>
              </div>
              <!-- Indicateur produit expiré -->
              <!-- <div *ngIf="item.template.isExpired()"
                   class="item-row__col">
                <i class="icon-warning mr-1"></i>
                <span>{{'EXPIRED-PRODUCT' | translate}}</span>
              </div> -->

              <!-- QUANTITY -->
              <div *ngIf="item.quantity > 1"
                   class="item-row__col mr-4">
                <div class="item-row__text item-row__text--title text-right">
                  {{ item.quantity + ' x ' + formatter.format(item.template.price)}}
                </div>
              </div>
              <!--PRICE-->
              <div class="item-row__col mr-4">
                <div class="item-row__text item-row__text--title text-right">
                  {{formatter.format(item.totalPrice)}}
                </div>
              </div>
              <!--REMOVE ACTION-->
              <div class="item-row__col item-row__col--auto text-right">
                <button type="button"
                        class="btn-square"
                        (click)="removeFromCart(item.id)"><i class="icon-x"></i></button>
              </div>
            </div>
          </div>
          <div class="row cart-total text-md-right">
            <div class="col-md-12 mb-1">
              <span class="mr-4">{{ 'PANIER.SOUS-TOTAL' | translate}}</span>
              <span>{{formatter.format(billDetails.subtotal)}}</span>
            </div>
            <div class="col-md-12 mb-1">
              <span class="mr-4">{{ 'PANIER.TPS' | translate}}</span>
              <span>{{formatter.format(billDetails.gst)}}</span>
            </div>
            <div class="col-md-12 mb-1">
              <span class="mr-4">{{ 'PANIER.TVQ' | translate}}</span>
              <span>{{formatter.format(billDetails.qst)}}</span>
            </div>
            <div class="col-md-12 mb-3">
              <span class="mr-4"><strong>{{ 'PANIER.TOTAL' | translate}}</strong></span>
              <span>{{formatter.format(billDetails.total)}}</span>
            </div>
          </div>
        </ng-container>
        <ng-template #noItems>
          {{'PANIER.PAS-DITEMS' | translate}}
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
