import { ActivityService } from './../../../activities/services/activity.service';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { MeetingService } from '../../services/meeting.service';
import { Meeting } from '../../model/meeting.model';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { Observable, Observer } from 'rxjs';
import { UploadFile } from 'ng-zorro-antd';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { Globals } from '../../../../_configs/globals';
import { ReportsService } from 'src/app/concepts/reports/reports.service';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { ObjectUtils } from '@app/shared/utils/object.utils';

@Component({
    selector: 'app-meeting-modify',
    templateUrl: './meeting-modify.component.html',
    styleUrls: [ './meeting-modify.component.scss' ]
})
export class MeetingModifyComponent implements OnInit {
    public isReady = false;
    public loading = false;
    public lang = this.translate.currentLang;
    public openedTab: string;

    public logoUrl: string;
    public uploadAction: string;

    public meetingId: number;
    public meeting: Meeting;

    reloadStandList: EventEmitter<any> = new EventEmitter<any>();

    isScenePro = false;
    isMeetingAdmin = false;

    constructor(
        public translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private globals: Globals,
        private meetingService: MeetingService,
        private activityService: ActivityService,
        private notification: RideauNotificationService,
        private breadcrumbService: BreadcrumbService,
        private localiseService: LocalizeRouterService,
        private accountService: AccountService,
        private reportService: ReportsService
    ) { }

    ngOnInit(): void {
        this.meetingId = this.route.snapshot.params[ 'rencontreId' ];
        this.handleRouteEvents();
        this.getMeeting();
        this.uploadAction = this.globals.api.ENDPOINT + this.globals.endpoints.upload.standImg;
        this.isScenePro = this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID;
        this.accountService.currentOrganizationChange.subscribe(() => {
            this.isScenePro = this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID;
        });
    }

    private getMeeting(): void {
        this.meetingService.getMeetingById(this.meetingId).subscribe((data) => {
            // console.log(data);
            this.meeting = new Meeting(data);

            this.isMeetingAdmin = this.accountService.getCurrentCtxOrganizationId() === this.meeting.organizationId;

            // Set Custom Breadcrumb
            const breadcrumbItems: BreadcrumbItem[] = [];
            const meetingName = this.meeting.getTranslatedProperty(this.lang, 'name');
            const meetingUrl = '/pro-meeting/' + this.meetingId;
            breadcrumbItems.push(
                new BreadcrumbItem({
                    title: meetingName,
                    url: meetingUrl,
                    isEditing: true
                })
            );

            this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);

            this.isReady = true;
        });
    }

    /**
     * L'onglet courrant est determiné par le parametre :page de l'URL. Comme celui-ci
     * est traduit, il y a un travail de reverse-traduction à faire pour trouver l'onglet
     * correspondant.
     */
    private handleRouteEvents(): void {
        const tabs = [ 'general', 'activities', 'stands', 'showcases', 'participants', 'products', 'jury' ];

        this.route.params.subscribe((params) => {
            //default value
            this.openedTab = tabs[ 0 ];
            if (params.page) {
                // traduire tous les onglets jusqu'à trouver celui passé en URL
                for (const tab of tabs) {
                    const locTab = this.localiseService.translateRoute(tab);
                    if (params.page === locTab) {
                        this.openedTab = tab;
                        break;
                    }
                }
            } else {
                //redirect to coordonees si pas de parametre.
                const transTab = this.localiseService.translateRoute(this.openedTab);
                this.router.navigate([ `./${transTab}` ], { relativeTo: this.route });
            }
        });
    }

    createActivity(): void {
        this.activityService.createActivityAndRedirectToForm(this.meeting.id, this.lang);
    }

    saveMeeting(): void {
        ObjectUtils.cleanUpNullValues(this.meeting);
        this.meetingService.modifyMeeting(this.meeting).subscribe((data) => {
            this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
        });
    }

    /*Meeting Stand Image*/
    removeImage(): void {
        this.logoUrl = '';
        this.meeting.standImageUrl = '';
    }

    beforeUpload = (file: File) => {
        return new Observable((observer: Observer<boolean>) => {
            const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPGorPNG) {
                this.notification.error(this.translate.instant('FORM.FORMAT-NON-SUPPORTE'));
                observer.complete();
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.notification.error(this.translate.instant('FORM.FICHIER-TROP-VOLUMINEUX'));
                observer.complete();
                return;
            }
            this.checkImageDimension(file).then((dimensionRes) => {
                if (!dimensionRes) {
                    this.notification.error(this.translate.instant('ERRORS.LARGEUR-HAUTEUR-1000-MAX'));
                    observer.complete();
                    return;
                }
                observer.next(isJPGorPNG && isLt2M && dimensionRes);
                observer.complete();
            });
        });
    };

    handleImageChange(info: { file: UploadFile }): void {
        switch (info.file.status) {
            case 'uploading':
                this.loading = true;
                break;
            case 'done':
                // Get this url from response
                this.getBase64(info.file.originFileObj, (img: string) => {
                    this.logoUrl = img;
                    this.loading = false;
                });

                const urlImageResponse = info.file.response.completeUrl;

                this.meeting.standImageUrl = urlImageResponse;

                this.saveMeeting();

                // console.log(this.meeting.standImageUrl)
                break;
            case 'error':
                this.notification.error(this.translate.instant('FORM.ERREUR-RESEAU'));
                this.loading = true;
                break;
        }
    }

    private getBase64(img: File, callback: (img: string) => void): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result.toString()));
        reader.readAsDataURL(img);
    }

    private checkImageDimension(file: File): Promise<boolean> {
        return new Promise((resolve) => {
            const img = new Image(); // create image
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                resolve(height <= this.globals.images.maxHeight && width <= this.globals.images.maxWidth);
            };
        });
    }

    exportVitrine(): void {
        this.reportService.generateVitrineReport(this.meeting);
    }

    exportParticipants(): void {
        this.reportService.generateParticipantReport(this.meeting);
    }
}
