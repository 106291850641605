<ng-container *ngIf="exportOptions.length">
    <button class="btn btn-export btn--edit" nz-dropdown
        nzType="default"
        [nzDropdownMenu]="exportMenu"
        (nzVisibleChange)="isRotate = !isRotate;">
        {{ 'EXPORTER' | translate }}
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" [ngClass]="isRotate ? '_svgRotate' : ''">
            <path fill-rule="nonzero" fill="white" d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z"/>
          </svg>
    </button>

    <nz-dropdown-menu #exportMenu="nzDropdownMenu">
        <ul nz-menu>
            <li *ngFor="let option of exportOptions" nz-menu-item (click)="exportContent(option.id)">
                    {{ option.label }}
                    <i nz-icon aria-hidden="true" nzType="{{buttonType}}" nzTheme="outline" style="padding-right:4px"></i>
            </li>
        </ul>
    </nz-dropdown-menu>

</ng-container>
