import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-row-item-action',
  templateUrl: './row-item-action.component.html',
  styleUrls: ['./row-item-action.component.scss']
})
export class RowItemActionComponent implements OnInit {
  @Input() imageUrl: string
  @Input() title: string;
  @Input() subtitle: string;

  @Input() curveTop = false;
  @Input() curveBottom = false

  @HostBinding('class.curveTop')
  get isCurveTop() {
    return this.curveTop;
  }

  @HostBinding('class.curveBottom')
  get isCurveBottom(){
    return this.curveBottom;
  }

  constructor() { }

  ngOnInit() {

  }

}
