import { Component, Input, ContentChild, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from '../../model/list-item.model';
import { RowTypes } from '../../enums/row-types.enum';
import { Observable } from 'rxjs';
import { take, finalize } from 'rxjs/operators';

let unique = 0;

interface RowSelectable {
  getTranslatedProperty(currentLang: string, property: string): string;
  toListItem(currentLang: string): ListItem;
}

@Component({
  selector: 'app-row-multiple-select',
  templateUrl: './row-multiple-select.component.html',
  styleUrls: ['./row-multiple-select.component.scss'],
})
export class RowMultipleSelectComponent<T, U> implements ControlValueAccessor {
  private onChange: (value: U[]) => void;
  private onTouched: () => void;
  @Input() rowType: RowTypes;
  @Input() options: Array<T & RowSelectable>;
  @Input() selectedOptions: Array<T>;
  @Input() labelProperty: string;
  @Input() valueProperty = 'id';
  @Input() valueSelectedOptionProperty = 'organization';
  @Input() valueSelectedOptionAddProperty = 'hasSubmitedPrograms';
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() onAdd: (option: U) => Observable<void>;
  @Input() onRemove: (option: U) => Observable<void>;
  @ContentChild(TemplateRef, { static: false }) optionTemplate: TemplateRef<unknown>;
  id = `RowSelectComponent-${unique++}`;
  selected: U;
  value: U[];
  private pending: U[] = [];
  constructor(
    readonly translateService: TranslateService,
    private readonly ngControl: NgControl
  ) {
    this.ngControl.valueAccessor = this;
  }
  onSelected(): void {
    const selected = this.selected;
    this.pending.push(selected);
    this.onAdd(selected).pipe(
      finalize(() => {
        this.pending.splice(this.pending.indexOf(selected), 1);
      }),
      take(1)
    ).subscribe();
    this.value.push(selected);
    this.onChange(this.value.slice(0));
    this.onTouched();
    setTimeout(() => this.selected = null);
  }
  onRemoved(selected: U): void {
    this.pending.push(selected);
    this.onRemove(selected).pipe(
      finalize(() => {
        this.pending.splice(this.pending.indexOf(selected), 1);
      }),
      take(1)
    ).subscribe(() => {
      this.value.splice(this.value.indexOf(selected), 1);
      this.onChange(this.value.slice(0));
      this.onTouched();
    });
  }
  selectedItemRow(selected: U): ListItem {
    return (
      this.options &&
      this.options
        .find((option) => option[this.valueProperty] === selected)
        .toListItem(this.translateService.currentLang)
    );
  }
  selectedOption(selected: U): T {
    return (
      this.options &&
      this.options.find((option) => option[this.valueProperty] === selected)
    );
  }
  templateContext(selected: U) {
    const option = this.options &&
      this.options.find((option) => option[this.valueProperty] === selected);

    if(this.selectedOptions && option){
      const selectOption = this.selectedOptions.find((item) => item[this.valueSelectedOptionProperty] ? item[this.valueSelectedOptionProperty][this.valueProperty] === option[this.valueProperty] : null);

      if(selectOption){
        option[this.valueSelectedOptionAddProperty] = selectOption[this.valueSelectedOptionAddProperty]
      }
    }

    return {
      $implicit: option,
      remove: this.onRemoved.bind(this, selected),
      loading: this.isLoading(selected)
    }
  }
  isLoading(selected: U) {
    return this.pending.indexOf(selected) > -1;
  }
  isSelected(option: T) {
    return !!this.value.find(v => v === option[this.valueProperty]);
  }
  writeValue(propertyValue: U[]): void {
    this.value = propertyValue || [];
  }
  registerOnChange(fn: (value: U[]) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
