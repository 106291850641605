import { CreditCardService } from './../../../payment/services/credit-card.service';
import { CreditCard } from './../../../payment/models/credit-card.model';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PxUser } from '../../model/project-x-user';
import { AuthService } from '../../services/auth/auth.service';
import { Transaction } from 'src/app/concepts/payment/models/transaction.model';
import { NzModalService } from 'ng-zorro-antd';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';

@Component({
  selector: 'app-account-billing',
  templateUrl: './account-billing.component.html',
  styleUrls: ['./account-billing.component.scss'],
})
export class AccountBillingComponent implements OnInit {
  @Input() public user: PxUser;
  @Input() public displayCreditCardSection = true;
  public creditCard: CreditCard;
  public creditCards: Array<any>;
  public isReady = false;

  public isSavingCard = false;
  public hasCreditCard = false;
  public showCreditCardForm = false;
  public billingHistory: Transaction[];

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private modalService: NzModalService,
    private creditCardService: CreditCardService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    if (!this.user) {
      this.user = this.authService.User;
    }

    this.getCards();
    this.getHistory();
  }

  getHistory(): void {
    this.creditCardService
      .getUserHistory(Number(this.user.id))
      .subscribe((data) => {
        this.billingHistory = data.filter(
          (transaction) => transaction.organizationId == null
        );
      });
  }

  getCards(): void {
    this.creditCardService
      .getUserCards(Number(this.user.id))
      .subscribe((data) => {
        this.creditCards = data;
        this.isReady = true;
      });
  }

  saveCreditCard(formGroup): void {
    this.isSavingCard = true;
    formGroup.userId = this.user.id;
    this.creditCardService.saveCard(formGroup).subscribe(
      () => {
        this.notification.success(this.translate.instant('FORM.CARTE-AJOUTEE'));
        this.isSavingCard = false;
        this.showCreditCardForm = false;
        this.getCards();
      },
      (err) => {
        console.log(err);
        this.notification.error(
          this.translate.instant('ERRORS.UPLOADING-CARD')
        );
        this.isSavingCard = false;
      }
    );
  }

  removeCard(cardId): void {
    const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
    const acceptDelete = this.translate.instant('OUI');
    const rejectDelete = this.translate.instant('NON');
    this.modalService.confirm({
      nzContent: deleteMessages,
      nzOkText: this.translate.instant(acceptDelete),
      nzCancelText: this.translate.instant(rejectDelete),
      nzClosable: true,
      nzMaskClosable: true,
      nzOnOk: () => {
        this.creditCardService.removeCard(cardId, -1).subscribe(() => {
          this.getCards();
          console.log('carte supprimée');
        });
      },
    });
  }
}
