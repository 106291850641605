
    <div nz-col nzMd="6">
      <div class="list-filters">
        <div class="list-filters__box">
          <div nz-menu>
            <a nz-menu-item class="list-filters__item" [routerLink]="'../settings' | localize" routerLinkActive="active">
              {{ 'PARAMETRES-TOURNEE' | translate }}
            </a>
            <a nz-menu-item class="list-filters__item"
              [routerLink]="'../admins' | localize"
              routerLinkActive="active"
              *ngIf="routeData.tour">
              {{ 'ADMINISTRATEURS' | translate }}
            </a>
            <a nz-menu-item class="list-filters__item"
              [routerLink]="'../diffuseurs' | localize"
              routerLinkActive="active"
              *ngIf="routeData.tour">
              {{ 'DIFFUSEURS' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  