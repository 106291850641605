<form id="profile-form"
      class="mb-4"
      nz-form
      [formGroup]="formGroup"
      (ngSubmit)="doSubmit()">
  <div class="row">
    <div class="col-md-12">
      <label class="d-block mb-3">{{ 'CONFIGURATIONS-DE-SALLE' | translate }}</label>
    </div>
  </div>
  <!-- input repeater work in progress -->
  <div formArrayName="configurations">
    <div class="row mb-2">
      <div class="col-md-4">
        <label>{{ 'FORM.TYPE' | translate }}</label>
      </div>
      <div class="col-md-3">
        <label>{{ 'FORM.CAPACITE' | translate }}</label>
      </div>
    </div>
    <nz-form-item *ngFor="let configuration of controlVenueConfigurationsArray.controls; let i = index">
      <div class="row align-items-center"
           [formGroupName]="i">
        <div class="col-md-4">

          <nz-select formControlName="configurationTypeId" >
            <nz-option *ngFor="let type of venueConfiguationTypes"
                       [nzValue]="type.value"
                       [nzLabel]="type.label"></nz-option>
          </nz-select>
        </div>
        <div class="col-md-3">
          <input nz-input
              type="number"
              min="0"
              max="999999"
              placeholder=""
              formControlName="capacity">
        </div>
        <div class="col-md-1">
          <i nz-icon
          aria-hidden="true"
             type="minus-circle-o"
             class="dynamic-delete-button"
             *ngIf="!formGroup.disabled"
             (click)="removeField(i,$event)"></i>
        </div>
      </div>
    </nz-form-item>
  </div>
  <nz-form-item>
    <nz-form-control>
      <button class="btn-rect"
              [disabled]="formGroup.disabled"
              style="padding: 0px 11px; font-size: 14px;"
              (click)="addField($event)">{{'AJOUTER-CONFIGURATION' | translate }}</button>
    </nz-form-control>
  </nz-form-item>
  <nz-divider></nz-divider>
  <div class="row">
    <div class="col-md-5">
      <nz-form-item>
        <nz-form-control>
          <label>{{ 'FORM.DEVIS-TECHNIQUE' | translate }}
            <div class="d-flex ">
              <input placeholder=""
                     formControlName="urlTechSpecification"
                     placeholder="https://www.exemple.com">
              <a class="ml-4 mt-2 quotation-link" target="_blank" [href]="formGroup.value.urlTechSpecification">
                <i nz-icon
                aria-hidden="true" type="export" theme="outline"></i>
              </a>
            </div>
          </label>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <ng-container *ngIf="venue.statusId === 1 || venue.statusId === 4">
    <button *appPermission="1;objectOrganizationId :venue.organizationId"
            nz-button
            [nzLoading]="isLoading"
            class="btn btn--default">
      {{'ENREGISTER-CONTINUER' | translate}}
    </button>
    <p *appPermission="1;objectOrganizationId :venue.organizationId"
       class="d-inline-block ml-2">{{ 'OU' | translate }}
      <a *ngIf="!isLoading"
         (click)="doSubmit('simple')">{{'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
      <span *ngIf="isLoading">{{'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
    </p>
  </ng-container>
  <ng-container *ngIf="(venue.statusId === 3 || venue.statusId === 2) &&!formGroup.disabled">
    <button *appPermission="1;objectOrganizationId :venue.organizationId;"
            nz-button
            [nzLoading]="isLoading"
            class="btn btn--default">
      {{'ENREGISTER' | translate}}
    </button>
  </ng-container>
</form>
