<ng-container *ngIf="isReady">
  <form nz-form
        [formGroup]="formGroup"
        (ngSubmit)="doSubmit()">
    <div nz-row
         nzGutter="16">

      <div nz-col
           nzMd="16">
        <nz-form-item>
          <nz-form-control>
            <label for="editionName">{{ 'FORM.NOM-EDITION' | translate}}</label>
            <input id="editionName"
                   formControlName="name">
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label for="description">{{ 'FORM.DESCRIPTION' | translate}}</label>
            <div class="quill-editor-edit d-inline-block w-100" [class.ql-disabled]="formGroup.disabled" [tabindex]="formGroup.disabled ? null : 0">
                <quill-editor  id="description"
                               class="w-100"
                               [(ngModel)]="descriptionModel"
                               [disabled]="formGroup.disabled"
                               [sanitize]="true"
                               [modules]="descriptionModules"
                               (onContentChanged)="limitDescriptionLength($event)"
                               [placeholder]="'FORM.DESCRIPTION-PLACEHOLDER' | translate"
                               [ngModelOptions]="{standalone: true}">
                 </quill-editor>
              </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col
           nzMd="4">

           <app-img-upload
              [currentImage]="meeting.image"
              [uploadAction]="uploadAction"
              [maxCropperWidth]="imageConfig.maxWidth"
              [maxCropperHeight]="imageConfig.maxHeight"
              [maxFileWeigth]="2000"
              uploadActionName="meetingmain"
              [isDisabled]="formGroup.disabled"
              (onUploadFile)="onUploadFileCallback($event)"
              (onFileRemoved)="onFileRemovedCallback($event)"
            ></app-img-upload>

      </div>
    </div>
    <div nz-row
         nzGutter="16">
      <div nz-col
           nzMd="12">

        <nz-form-item>
          <nz-form-control>
            <label for="organizer"
                   class="repertory-filter__label d-block">{{'FORM.ORGANISATION-PROPRIETAIRE' | translate}}</label>
            <nz-select name="organizationId"
                       id="organizer"
                       nzShowSearch
                       nzAllowClear
                       style="width: 100%"
                       formControlName="organizationId">
              <nz-option></nz-option>
              <nz-option *ngFor="let org of organizationList"
                         [nzLabel]="org.getTranslatedProperty(currentLang,'name')"
                         [nzValue]="org.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label for="place">{{ 'FORM.VILLE' | translate}}</label>
            <input id="place"
                   formControlName="place">
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label for="website">{{ 'FORM.SITE-WEB' | translate}}</label>
            <input id="website"
                   formControlName="website">
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control class="d-inline-block">
            <label>{{ 'FORM.DATE' | translate}}</label>
            <div>
              <nz-radio-group class="input-radioBtn"
                              formControlName="isDatePeriod">
                <label nz-radio
                       class="input-radioBtn__radio"
                       [nzValue]="false">{{'FORM.ONE-DAY' | translate}}</label>
                <label nz-radio
                       class="input-radioBtn__radio"
                       [nzValue]="true">{{'FORM.MANY-DAYS' | translate}}</label>
              </nz-radio-group>
            </div>
          </nz-form-control>

          <nz-form-control>
            <div class="row">
              <div class="col">
                <nz-date-picker id="dateFrom"
                                nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                nzPlaceHolder="yyyy/MM/dd"
                                formControlName="dateFrom"></nz-date-picker>
              </div>
              <ng-container *ngIf="(this.formGroup.value.isDatePeriod)">
                <div class="col-1 p-0 text-center"><span>{{'FORM.TO' | translate}}</span></div>
                <div class="col">
                  <nz-date-picker id="dateTo"
                                  nzAllowClear="true"
                                  nzFormat="yyyy/MM/dd"
                                  nzPlaceHolder="yyyy/MM/dd"
                                  formControlName="dateTo"></nz-date-picker>
                </div>
              </ng-container>
            </div>


          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label>{{ 'FORM.INSCRIPTION-PERIOD' | translate}}</label>
            <div class="row">
              <div class="col">
                <nz-date-picker id="subscriptionDateFrom"
                                nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                nzPlaceHolder="yyyy/MM/dd"
                                formControlName="subscriptionDateFrom"></nz-date-picker>
              </div>
              <ng-container>
                <div class="col-1 p-0 text-center"><span>{{'FORM.TO' | translate}}</span></div>
                <div class="col">
                  <nz-date-picker id="subscriptionDateTo"
                                  nzAllowClear="true"
                                  nzFormat="yyyy/MM/dd"
                                  nzPlaceHolder="yyyy/MM/dd"
                                  formControlName="subscriptionDateTo"></nz-date-picker>
                </div>
              </ng-container>
            </div>


          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label>{{ 'FORM.PROPOSER-VITRINE-PERIOD' | translate}}</label>
            <div class="row">
              <div class="col">
                <nz-date-picker id="proposalVitrineDateFrom"
                                nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                nzPlaceHolder="yyyy/MM/dd"
                                formControlName="proposalVitrineDateFrom"></nz-date-picker>
              </div>
              <ng-container>
                <div class="col-1 p-0 text-center"><span>{{'FORM.TO' | translate}}</span></div>
                <div class="col">
                  <nz-date-picker id="proposalVitrineDateTo"
                                  nzAllowClear="true"
                                  nzFormat="yyyy/MM/dd"
                                  nzPlaceHolder="yyyy/MM/dd"
                                  formControlName="proposalVitrineDateTo"></nz-date-picker>
                </div>
              </ng-container>
            </div>


          </nz-form-control>
        </nz-form-item>


        <nz-form-item>
          <label nz-checkbox
                 formControlName="isActivityOptional">{{'FORM.ACTIVITY-CHOICE' | translate}}</label>
          <p class="font-small ml-4">{{'FORM.ACTIVITY-CHOICE-DESC' | translate}}.</p>
          <nz-form-control>
            <div class="row pl-4">
              <div class="col">
                <nz-date-picker id="activityDateFrom"
                                nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                nzPlaceHolder="yyyy/MM/dd"
                                formControlName="activityDateFrom"
                                [nzDisabled]="!formGroup.value.isActivityOptional"></nz-date-picker>
              </div>
              <div class="col-sm-1 p-0 text-center">
                <span>{{'FORM.TO' | translate}}</span>
              </div>
              <div class="col">
                <nz-date-picker id="activityDateTo"
                                nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                nzPlaceHolder="yyyy/MM/dd"
                                formControlName="activityDateTo"
                                [nzDisabled]="!formGroup.value.isActivityOptional"></nz-date-picker>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label nz-checkbox
                 formControlName="isStandOptional">{{'FORM.STAND-CHOICE' | translate}}</label>
          <p class="font-smaller ml-4">{{'FORM.STAND-CHOICE-DESC' | translate}}.</p>
          <nz-form-control>
            <div class="row pl-4">
              <div class="col">
                <nz-date-picker id="standDateFrom"
                                nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                nzPlaceHolder="yyyy/MM/dd"
                                formControlName="standDateFrom"
                                [nzDisabled]="!formGroup.value.isStandOptional"></nz-date-picker>
              </div>
              <div class="col-sm-1 p-0 text-center">
                <span>{{'FORM.TO' | translate}}</span>
              </div>
              <div class="col">
                <nz-date-picker id="standDateTo"
                                nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                nzPlaceHolder="yyyy/MM/dd"
                                formControlName="standDateTo"
                                [nzDisabled]="!formGroup.value.isStandOptional"></nz-date-picker>
              </div>
            </div>

          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label class="mb-2 py-2"
                   formControlName="isPublished"
                   nz-checkbox>
              <b>{{ 'FORM.PUBLISH-MEETING' | translate }}</b>
            </label>
          </nz-form-control>
        </nz-form-item>

      </div>

      <div nz-col
           nzMd="4">

      </div>
    </div>

    <!-- Bouton simple si orga approuvée -->
    <button nz-button [disabled]="formGroup.invalid"
            class="btn btn--default">
      {{'ENREGISTER' | translate}}
    </button>
  </form>

</ng-container>
