import { MembershipConfig } from './../model/membership-config.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/_configs/globals';
import { MainService } from './../../../shared/services/main.services';

@Injectable({
    providedIn: 'root'
})
export class MembershipService extends MainService {
    constructor(protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    getMembershipConfiguration(networkId: number): Observable<MembershipConfig> {
        const endpoint = this.uri + this.globals.endpoints.membership.getMemberShipEndpoint(networkId);
        return this.httpClient.get(endpoint).map((res) => new MembershipConfig(res));
    }

    updateMembershipConfiguration(config: MembershipConfig): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.membership.main;
        return this.httpClient.put(endpoint, config);
    }
}
