import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from '../../model/list-item.model';

@Component({
    selector: 'app-paginated-list',
    templateUrl: 'paginated-list.component.html',
    styleUrls: ['paginated-list.component.scss']
})
export class PaginatedListComponent {
    @Input() isLoading: boolean;
    @Input() usePagination: boolean;
    @Input() pagination: Pagination;
    @Output() pageChange = new EventEmitter<Pagination>();

    public onPageChange(page: number) {
        const paginate = new Pagination();
        paginate.limit = this.pagination.limit;
        paginate.offset = paginate.limit * (page - 1);
        this.pageChange.emit(paginate);
    }
}
