<!--Team & admin Tab-->
<div class="row">
  <div class="col-md-8">
    <h2>{{ 'SALLES' | translate}}</h2>
  </div>
  <div class="col-md-4 text-md-right">
    <button class="btn"
            type="button"
            *appPermission="1"
            (click)="newVenue()"
            style="padding: 1px 20px 1px 13px">
      <i class="icon-plus icon-for-btn"></i>
      {{'AJOUTER-SALLE' | translate}}
    </button>
  </div>
</div>
<ng-container *ngIf="venues.length else noVenues">
  <div class="item-row"
       *ngFor="let venue of venues">
    <div class="item-row__col item-row__col--avatar item-row__avatar">
      <span class="item-row__avatar-container">
        <img class="item-row__avatar-src"
             src="{{ venue.photo }}"
             alt="">
      </span>
    </div>
    <div class="item-row__col">
      <div class="item-row__text item-row__text--title d-inline-block">
        {{venue.getTranslatedProperty(lang,'name')}}
      </div>
    </div>
    <div class="item-row__col item-row__col--roles">
      <div class="d-flex">
        <button *appPermission="1;"
                type="button"
                style="margin-right:10px; padding-top:6px"
                class="btn-rect"
                (click)="modifyVenueRoute(organizationId, venue.id)">{{'FORM.MODIFIER' | translate}} <i
             class="icon-edit"></i></button>
        <button *appPermission="2;"
                type="button"
                class="btn-square"
                (click)="removeVenue(venue.id)"><i class="icon-trash"></i></button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noVenues>
  {{'AUCUNE-SALLE-LIEE' | translate}}
</ng-template>
