<form id="organisation-step-1" class="mb-4" nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()" *ngIf="formGroup">
    <div nz-row nzGutter="16">
      <div nz-col nzMd="18">
        <app-select 
          *ngIf="formGroup.contains('organizationId') && getSceneProAdmin() == SCENE_PRO_ID" 
          formControlName="organizationId" 
          labelProperty="name" 
          [options]="organizations"
          [validateStatus]="(formGroup.get('organizationId').value === SCENE_PRO_ID && formGroup.get('organizationId').touched) ? 'error' : ''"
          [isRequired]="formGroup.get('organizationId').value === SCENE_PRO_ID && formGroup.get('organizationId').touched">
          {{ 'FORM.ORGANISATION-PROPRIETAIRE' | translate }} *
        </app-select>

        <app-text-input formControlName="title" requiredTranslateKey="CHAMP-REQUIS">
          {{ 'FORM.TITRE' | translate }} *
        </app-text-input>

        <nz-form-item>
          <nz-form-control>
            <label for="artists"> {{ 'FORM.ARTISTES' | translate }} *</label>
            <nz-select nzMode="tags" id="artists" [nzTokenSeparators]="[',']" style="width: 100%;"
              formControlName="artists" [nzPlaceHolder]="'FORM.ARTISTES' | translate">
              <nz-option *ngFor="let artist of approvedArtists" [nzLabel]="artist" [nzValue]="artist"></nz-option>
            </nz-select>
            <nz-form-explain class="has-error" *ngIf="formGroup.get('artists').errors && formGroup.get('artists').touched">
              {{ "CHAMP-REQUIS" | translate }}
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <app-select formControlName="producerId" labelProperty="name" [options]="organizations">
          {{ 'FORM.PRODUCTEUR-OFFICIEL' | translate }}
        </app-select>

        <app-text-input formControlName="coProducer">
          {{ 'FORM.CO-PRODUCTEUR' | translate }}
        </app-text-input>

      </div>

      <div nz-col nzMd="6">
        <label class="d-inline-block mb-1" for="show-image">
          {{ 'FORM.IMAGE-VEDETTE' | translate }} *
        </label>
        <app-img-upload
        [currentImage]="show.image"
        [imageTitle]="'FORM.IMAGE-VEDETTE' | translate"
        [maxCropperWidth]="imageConfig.maxWidth"
        [maxCropperHeight]="imageConfig.maxHeight"
        [uploadAction]="uploadAction"
        uploadActionName="showmain"
        [maxFileWeigth]="2000"
        formControlName="image"
        requiredTranslateKey="CHAMP-REQUIS"
        (onUploadFile)='onUploadFileCallback($event)'
        ></app-img-upload>
      </div>
    </div>

    <div nz-row nzGutter="16">
      <div nz-col>
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="isTrainingAvailable">
              <strong>{{ "FORM.SPECTACLE-FORMATION-REDUITE" | translate }}</strong>
            </label>
          </nz-form-control>
        </nz-form-item>
        <app-rich-text formControlName="shortDescription" [modules]="global.InputRichTextModules" [countMaxLength]="maxDescriptionLength400"
          [disable]="isSceneProAdmin ? false : show.statusId === showStatus.APPROUVE"
          requiredTranslateKey="CHAMP-REQUIS">
          {{ "FORM.DESCRIPTION-COURTE" | translate }} *
          <p class="text-smaller mb-2">{{ "FORM.DESCRIPTION-COURTE-DETAILS" | translate }}</p>
        </app-rich-text>

        <app-rich-text formControlName="longDescription" [modules]="global.InputRichTextModules" [countMaxLength]="maxDescriptionLength1800"
          requiredTranslateKey="CHAMP-REQUIS">
          {{ "FORM.DESCRIPTION-LONGUE" | translate }} *
        </app-rich-text>


        <app-text-input formControlName="artistUrl">
          {{ "FORM.URL-ARTIST-SHOW" | translate }}
        </app-text-input>

        <app-text-input formControlName="representative" requiredTranslateKey="CHAMP-REQUIS">
          {{ "FORM.REPRESENTANT-NOM" | translate }} *
        </app-text-input>

        <div nz-row nzGutter="16">
          <div nz-col [nzMd]="12">

            <app-text-input formControlName="repEmail" type="email" requiredTranslateKey="CHAMP-REQUIS">
              {{ "FORM.REPRESENTANT-MAIL" | translate }} *
            </app-text-input>

          </div>
          <div nz-col [nzMd]="12">

            <app-text-input formControlName="repPhone" requiredTranslateKey="CHAMP-REQUIS">
              {{ "FORM.REPRESENTANT-PHONE" | translate }} *
            </app-text-input>

          </div>
        </div>

        <nz-form-item>
          <nz-form-control >
            <div class="w-50">
              <label for="langues"> {{ "FORM.LANGUES-DU-SPECTACLE" | translate }} *</label>
              <nz-select [nzMaxTagCount]="3" style="width: 100%" id="langues" nzMode="multiple"
                [nzPlaceHolder]="'CHOISIR-UNE-LANGUE' | translate" formControlName="languages" requiredTranslateKey="CHAMP-REQUIS">
                <nz-option *ngFor="let language of getSiteLanguageOptions()" [nzLabel]="language.name | translate"
                  [nzValue]="language.id"></nz-option>
              </nz-select>
              <nz-form-explain class="has-error" *ngIf="formGroup.get('languages').errors && formGroup.get('languages').touched">
                {{ "CHAMP-REQUIS" | translate }}
              </nz-form-explain>
            </div>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label formControlName="hasSensibilisation" nz-checkbox>
              <strong>{{ "FORM.SENSIBILISATION-DISPO" | translate }}</strong>
            </label>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label class="mb-2 py-2" formControlName="isAvailable" nz-checkbox>
              <strong>{{ "FORM.SPECTACLE-DISPO" | translate }}</strong>
              <p class="text-smaller mb-2">
                {{ "FORM.SPECTACLE-DISPO-EXPLICATION" | translate }}
              </p>
            </label>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <ng-container *ngIf="displaySaveForLater()">
      <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid"
      class="btn btn--primary">
        {{ "ENREGISTER-CONTINUER" | translate }}
      </button>
      <p *appPermission="1; objectOrganizationId: show.organizationId" class="d-inline-block ml-2" [ngClass]="{'disabled': isLoading}">
        {{ "OU" | translate }}
        <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{
          "ENREGISTER-TERMINER-PLUS-TARD" | translate
          }}</a>
        <span *ngIf="isLoading">{{
          "ENREGISTER-TERMINER-PLUS-TARD" | translate
          }}</span>
      </p>
    </ng-container>
    <ng-container *ngIf="displaySubmit()">
      <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid"
       class="btn btn--primary">
        {{ "ENREGISTER" | translate }}
      </button>
    </ng-container>
  </form>
