<span *ngIf="isShowExpired" class="tag tag--black">{{ 'STATUS.OFFRE-EXPIREE' | translate }}</span>
<span class="title">{{ program.show ? program.show.getTranslatedProperty(translateService.currentLang, 'title') : program.showCustom }}</span>
<span class="mr-1" *ngIf="program.hour">{{ program.hour | date: 'HH' }}h{{ program.hour | date: 'mm' }}</span>
<span class="venueName">{{ program.venue ? program.venue.getTranslatedProperty(translateService.currentLang, 'name') : program.venueCustom }}</span>
<span class="venueAddress">{{ program.venue && program.venue.address ? program.venue.address.city : '' }}</span>
<span *ngIf="program.organization" class="venueAddress">{{ program.organization | translatable: 'name' }}</span>
<i class="icon-world" aria-hidden="true" *ngIf="routeData.tourAdmin || program.tourId"></i>

<ng-template #actions let-program>
    <div class="c-action-container">
        <p class="c-action__title mb-2" *ngIf="canDisplayStatusName">
            {{ 'STADE-CONFIRMATION' | translate }}:
            <b class="d-block">{{ statusName }}</b>
        </p>
        <button appButton class="c-action-button c-action-button--green" [ngClass]="{ 'c-action-button--disabled': isProgramConfirmed }" (click)="actionProgram('confirm')">
            <i class="icon-checkmark" aria-hidden="true"></i>
            <span>{{ 'CONFIRMER' | translate }}</span>
        </button>
        <button appButton class="c-action-button c-action-button--yellow" [ngClass]="{ 'c-action-button--disabled': isProgramProvisory }" (click)="actionProgram('provisory')">
            <i class="icon-checkmark" aria-hidden="true"></i>
            <span>{{ 'PROVISORY' | translate }}</span>
        </button>
        <button appButton class="c-action-button c-action-button--red" (click)="actionProgram('delete')">
            <i class="icon-x" aria-hidden="true"></i>
            <span>{{ 'SUPPRIMER' | translate }}</span>
        </button>
        <!-- https://logient.atlassian.net/browse/RIDEAU-1799 -->
        <button appButton class="c-action-button c-action-button--white" (click)="navigateToProgramPage()">
            <i class="icon-pencil" aria-hidden="true"></i>
            <span>{{ 'MODIFIER' | translate }}</span>
        </button>
    </div>
</ng-template>
