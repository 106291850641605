<app-page-title title="{{ 'TABLEAU-BORD' | translate }}"
                [shouldBeApproved]="false"
                [isDashboardPage]="true"></app-page-title>

<div class="container">
  <div nz-row
       *ngIf="isReady"
       class="pb-5"
       [nzGutter]="24">
    <div nz-col
         nzMd="16">
      <!-- MAIN COLUMN -->
      <h2>{{'NOTIFICATIONS.NOTIFICATIONS' | translate}}</h2>
      <app-notifications-list [notifications]="notifications"
                    (archiveItem)="archiveNotifications($event)"
                    (archiveAll)="archiveNotifications()"
                  ></app-notifications-list>

      <a  *ngIf="notifications.length" [routerLink]="linkToAllNotification" class="allNotificationsLink">
        {{'NOTIFICATIONS.ALL-NOTIFICATIONS' | translate}}
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path fill-rule="nonzero" d="M11.536 9.95L7.293 5.707a1 1 0 0 1 1.414-1.414l4.95 4.95a1 1 0 0 1 0 1.414l-4.95 4.95a1 1 0 0 1-1.414-1.415l4.243-4.242z"/>
        </svg>
      </a>

      <h2 class="mt-5"
          style="opacity: 0;">{{'RENCONTRES' | translate}}</h2>
    </div>
    <div nz-col
         class="pl-4 rigth-col"
         nzMd="8">
      <!-- RIGHT COLUMN -->
      <app-bills-to-pay></app-bills-to-pay>
      <app-my-publications-little></app-my-publications-little>
      <app-my-shows-little *appPermission="1;organizationType:1"></app-my-shows-little>
      <app-my-meetings-little></app-my-meetings-little>
    </div>
  </div>
</div>
