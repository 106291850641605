import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-export-dropdown',
  templateUrl: './export-dropdown.component.html',
  styleUrls: ['./export-dropdown.component.scss'],
})
export class ExportDropdownComponent {
  @Input() exportOptions: any;
  // pour pouvoir associer un callback au click au lieu d'une navigation
  @Output() onExportSelected = new EventEmitter();

  isRotate = false;

  exportContent($event) {
    this.onExportSelected.emit($event);
  }
}
