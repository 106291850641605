<div class="item-row" [ngClass]="{ 'item-row__disabled': isDisabled }">
    <ng-container *ngIf="!hideThumbnails">
        <ng-container *ngIf="itemRowType == activityRow; else imgSource">
            <div class="item-row__date-container border-right">
                <span class="item-row__date-container__day">{{ day }}</span>
                <span class="item-row__date-container__month text-uppercase">{{ month | translate }}</span>
            </div>
        </ng-container>
        <ng-template #imgSource>
            <ng-container *ngIf="!(itemRowType == productRow)">
                <div class="item-row__col item-row__col--avatar item-row__avatar-src">
                    <span class="item-row__avatar-container">
                        <img class="img-responsive item-row__avatar-src" src="{{ item.itemImgSrc }}" alt="" />
                        <span *ngIf="isDisabled && itemRowType == showRow" class="item-row__expired">{{ 'STATUS.EXPIREE' | translate }}</span>
                        <span *ngIf="isKeep && isAdmin" class="item-row__keeped">{{ 'STATUS.KEEPED' | translate }}</span>
                    </span>
                </div>
            </ng-container>
        </ng-template>
    </ng-container>

    <div class="item-row__infos">
        <div class="item-row__col">
            <div [ngClass]="classListHandler">
                <div class="item-row__col__row item-row__col__row-title">
                    <p class="item-row__text--title">
                        <ng-container *ngIf="item.itemUrl; else noUrl">
                            <ng-container *ngIf="item.itemUrlExt; else regularLink">
                                <a rel="item-row" class="item-row__text-url" (click)="openUrl(item.itemUrl)">{{ item.itemTitle }}</a>
                            </ng-container>
                            <ng-template #regularLink>
                                <a rel="item-row" class="item-row__text-url" [routerLink]="item.itemUrl | localize">{{ item.itemTitle }}</a>
                            </ng-template>
                        </ng-container>
                        <ng-template #noUrl>
                            {{ item.itemTitle }}
                        </ng-template>
                    </p>
                    <p *ngIf="item.itemSubtitle && item.isPublication !== 1" class="item-row__text--subtitle">{{ item.itemSubtitle | shortenText: 80 }}</p>
                    <p *ngIf="item.isPublication === 1" class="item-row__text--subtitle">{{ item.organizationName }}</p>
                </div>
                <div class="item-row__col__row item-row__col__row-desc">
                    <div *ngIf="item.itemCategory" class="item-row__bubble">{{ item.itemCategory | translate }}</div>
                    <div *ngIf="item.itemInfo5" class="item-row__bubble_capacity">{{ item.itemInfo5 | translate }}</div>
                    <p class="item-row__text">{{ item.itemInfo1 | translate | shortenText }}</p>
                    <p *ngIf="itemRowType !== RowTypes.BOTTIN && itemRowType !== RowTypes.DIFFUSEUR" class="item-row__text">{{ item.itemInfo2 | translate }}</p>
                </div>
                <section class="item-row__col__row item-row__col__row-extra d-flex flex-column text-right">
                    <ng-container *ngIf="item.itemInfo3">
                        <p class="item-row__text item-row__text--itemInfo3">{{ item.itemInfo3 | translate }}</p>
                    </ng-container>
                    <ng-container *ngIf="item.itemInfo4">
                        <p class="item-row__text item-row__text--itemInfo4">{{ item.itemInfo4 | translate }}</p>
                    </ng-container>
                </section>
                <div *ngIf="enableEditing || enableRemoving" class="item-row__col__row item-row__col__row-options">
                    <div class="d-flex">
                        <button type="button" style="margin-right: 10px" class="btn-square" *ngIf="enableEditing" (click)="modifyItemForm()">
                            <i class="icon-pencil" aria-hidden="true"></i>
                        </button>

                        <button type="button" style="margin-right: 10px" class="btn-square btn-cross-icon" *ngIf="enableRemoving" (click)="removeItemForm()" [disabled]="loading">
                            <i [ngClass]="removeIcon" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="item.itemPrice" class="item-row__col text-right pr-3">
        <p class="item-row__text">{{ item.itemPrice }}</p>
    </div>
    <div *ngIf="item.itemStatus && !hideStatus" style="padding: 0px 20px">
        <i nz-icon aria-hidden="true" *ngIf="item.itemStatus === 1" [nzType]="'minus-circle'" title="{{ 'STATUS.NON_SOUMIS' | translate }}" style="color: blue"></i>
        <i nz-icon aria-hidden="true" *ngIf="item.itemStatus === 2" [nzType]="'question-circle'" title="{{ 'STATUS.SOUMIS' | translate }}" style="color: orange"></i>
        <i nz-icon aria-hidden="true" *ngIf="item.itemStatus === 3" [nzType]="'check-circle'" title="{{ 'STATUS.APPROUVE' | translate }}" style="color: green"></i>
        <i nz-icon aria-hidden="true" *ngIf="item.itemStatus === 4" [nzType]="'close-circle'" title="{{ 'STATUS.REJETE' | translate }}" style="color: red"></i>
        <i nz-icon aria-hidden="true" *ngIf="item.itemStatus === 5" [nzType]="'clock-circle'" title="{{ 'STATUS.OUTDATED' | translate }}" style="color: grey"></i>
    </div>

    <div *ngIf="itemToRate">
        <div *ngIf="!isAdmin; else adminRating" class="item-row__interests">
            <button class="like" (click)="isliked()" [ngClass]="rateValue == 1 ? 'liked' : ''">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path
                        d="M8.84 3.248c.66-1.695 1.556-2.41 3.218-2.211l.226.04c.97.22 1.63.936 1.934 1.962l.053.206c.21.903.196 1.84-.017 3.012l-.038.201-.031.129.72-.07.444-.036c.917-.051 1.634.058 2.28.397 1.177.617 1.483 1.466 1.325 3.015-.025.247-.055.493-.095.781l-.09.59-.174 1.027c-.06.275-.144.561-.253.867-.07.199-.148.4-.247.634l-.198.455-.386.84c-1.053 2.134-2.71 3.378-4.864 3.51-.415.027-.78-.005-1.344-.104l-.284-.05c-.245-.042-.35-.055-.471-.06l-.34-.018c-.221-.014-.481-.035-.787-.062l-.906-.09-2.09-.231c-.81-.09-1.425-.775-1.425-1.59v-6.363c0-.25.059-.497.171-.721.098-.193.221-.382.373-.578.106-.137.225-.277.366-.428l.125-.13c.066-.068.142-.144.213-.213l.237-.224.27-.28c.348-.366.553-.606.711-.837l.128-.199c.011-.02.036-.069.068-.14l.118-.268.311-.77zM3 9c.513 0 .936.386.993.883L4 10v7c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L2 17v-7c0-.552.448-1 1-1zm8.77-5.985c-.51-.06-.67.012-.945.658l-.118.29-.752 2.004-.327.809c-.13.309-.231.525-.316.667-.269.45-.565.82-1.113 1.397l-.342.347c-.024.021-.139.131-.253.244l-.235.239c-.095.102-.176.197-.244.284l-.07.095-.056.083v5.9l1.724.192.887.088.39.033.467.032.293.015c.17.012.341.036.603.08l.287.052c.42.073.638.092.872.077 1.393-.086 2.442-.873 3.184-2.374l.365-.797.185-.424c.08-.19.144-.355.201-.516.082-.231.143-.438.175-.58l.162-.962.086-.563c.035-.252.062-.471.084-.692l.015-.154c.065-.675.03-.728-.28-.89-.28-.147-.651-.204-1.221-.172l-.26.019-.883.086-.86.077-1.854.13.356-1.589.283-1.155.072-.413c.122-.79.12-1.386-.002-1.907l-.038-.147c-.103-.35-.247-.504-.399-.54z"
                    />
                </svg>
                <span>{{ likes }}</span>
            </button>
            <button class="dislike" (click)="isdisliked()" [ngClass]="rateValue == 2 ? 'disliked' : ''">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path
                        d="M12.647 1.407c2.154.133 3.81 1.376 4.864 3.511l.386.84.198.455c.099.234.177.435.247.634.109.305.192.591.253.867l.174 1.027.09.59c.027.191.05.365.068.532l.027.248c.158 1.55-.148 2.399-1.325 3.015-.646.34-1.363.449-2.28.397l-.444-.035-.72-.07.031.128.038.201c.213 1.173.228 2.11.017 3.013l-.053.205c-.304 1.027-.964 1.743-1.934 1.963l-.226.04c-1.662.199-2.558-.517-3.218-2.211l-.749-1.994-.31-.77-.119-.268-.053-.11-.015-.03c-.181-.303-.392-.565-.839-1.036l-.27-.28-.237-.223-.213-.213-.125-.13c-.14-.152-.26-.291-.366-.428-.152-.196-.275-.385-.373-.579-.112-.223-.171-.47-.171-.72V3.613c0-.816.614-1.501 1.426-1.59l2.089-.232.906-.09c.306-.027.566-.048.787-.062l.34-.017c.12-.005.226-.018.47-.06l.285-.05c.565-.1.929-.131 1.344-.105zm-.997 2.074l-.287.05c-.262.046-.433.07-.603.082l-.588.033c-.164.012-.351.028-.562.047l-.887.087L7 3.972v5.9l.056.083.07.096c.068.087.149.181.244.284l.235.239c.114.112.229.222.253.243l.342.348c.548.577.844.946 1.113 1.396.085.143.185.359.316.668l.327.808.752 2.005.118.29c.275.646.435.718.945.657l.123-.022c.152-.036.296-.191.399-.54l.038-.148c.121-.52.124-1.117.002-1.906l-.072-.413-.283-1.156-.356-1.588 1.853.13.861.076.883.087.26.018c.57.032.94-.024 1.222-.172l.116-.065c.2-.124.219-.246.163-.824l-.015-.155c-.022-.22-.049-.44-.084-.691l-.086-.563-.162-.963c-.032-.141-.093-.348-.175-.579-.057-.162-.122-.327-.2-.516l-.186-.425-.365-.796c-.742-1.501-1.79-2.289-3.184-2.375-.234-.015-.451.004-.872.078zM3 2c.552 0 1 .448 1 1v7l-.007.117c-.057.497-.48.883-.993.883-.552 0-1-.448-1-1V3l.007-.117C2.064 2.386 2.487 2 3 2z"
                    />
                </svg>
                <span>{{ dislikes }}</span>
            </button>
        </div>
        <ng-template #adminRating>
            <div class="item-row__interests_admin">
                <div class="d-inline-flex">
                    <button class="item-row__interests_admin_like" (click)="isliked()" [ngClass]="rateValue == 1 ? 'liked' : ''" [disabled]="isAdmin">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path
                                d="M8.84 3.248c.66-1.695 1.556-2.41 3.218-2.211l.226.04c.97.22 1.63.936 1.934 1.962l.053.206c.21.903.196 1.84-.017 3.012l-.038.201-.031.129.72-.07.444-.036c.917-.051 1.634.058 2.28.397 1.177.617 1.483 1.466 1.325 3.015-.025.247-.055.493-.095.781l-.09.59-.174 1.027c-.06.275-.144.561-.253.867-.07.199-.148.4-.247.634l-.198.455-.386.84c-1.053 2.134-2.71 3.378-4.864 3.51-.415.027-.78-.005-1.344-.104l-.284-.05c-.245-.042-.35-.055-.471-.06l-.34-.018c-.221-.014-.481-.035-.787-.062l-.906-.09-2.09-.231c-.81-.09-1.425-.775-1.425-1.59v-6.363c0-.25.059-.497.171-.721.098-.193.221-.382.373-.578.106-.137.225-.277.366-.428l.125-.13c.066-.068.142-.144.213-.213l.237-.224.27-.28c.348-.366.553-.606.711-.837l.128-.199c.011-.02.036-.069.068-.14l.118-.268.311-.77zM3 9c.513 0 .936.386.993.883L4 10v7c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L2 17v-7c0-.552.448-1 1-1zm8.77-5.985c-.51-.06-.67.012-.945.658l-.118.29-.752 2.004-.327.809c-.13.309-.231.525-.316.667-.269.45-.565.82-1.113 1.397l-.342.347c-.024.021-.139.131-.253.244l-.235.239c-.095.102-.176.197-.244.284l-.07.095-.056.083v5.9l1.724.192.887.088.39.033.467.032.293.015c.17.012.341.036.603.08l.287.052c.42.073.638.092.872.077 1.393-.086 2.442-.873 3.184-2.374l.365-.797.185-.424c.08-.19.144-.355.201-.516.082-.231.143-.438.175-.58l.162-.962.086-.563c.035-.252.062-.471.084-.692l.015-.154c.065-.675.03-.728-.28-.89-.28-.147-.651-.204-1.221-.172l-.26.019-.883.086-.86.077-1.854.13.356-1.589.283-1.155.072-.413c.122-.79.12-1.386-.002-1.907l-.038-.147c-.103-.35-.247-.504-.399-.54z"
                            />
                        </svg>
                        <span>{{ likes }}</span>
                    </button>
                    <button class="item-row__interests_admin_dislike" (click)="isdisliked()" [ngClass]="rateValue == 2 ? 'disliked' : ''" [disabled]="isAdmin">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path
                                d="M12.647 1.407c2.154.133 3.81 1.376 4.864 3.511l.386.84.198.455c.099.234.177.435.247.634.109.305.192.591.253.867l.174 1.027.09.59c.027.191.05.365.068.532l.027.248c.158 1.55-.148 2.399-1.325 3.015-.646.34-1.363.449-2.28.397l-.444-.035-.72-.07.031.128.038.201c.213 1.173.228 2.11.017 3.013l-.053.205c-.304 1.027-.964 1.743-1.934 1.963l-.226.04c-1.662.199-2.558-.517-3.218-2.211l-.749-1.994-.31-.77-.119-.268-.053-.11-.015-.03c-.181-.303-.392-.565-.839-1.036l-.27-.28-.237-.223-.213-.213-.125-.13c-.14-.152-.26-.291-.366-.428-.152-.196-.275-.385-.373-.579-.112-.223-.171-.47-.171-.72V3.613c0-.816.614-1.501 1.426-1.59l2.089-.232.906-.09c.306-.027.566-.048.787-.062l.34-.017c.12-.005.226-.018.47-.06l.285-.05c.565-.1.929-.131 1.344-.105zm-.997 2.074l-.287.05c-.262.046-.433.07-.603.082l-.588.033c-.164.012-.351.028-.562.047l-.887.087L7 3.972v5.9l.056.083.07.096c.068.087.149.181.244.284l.235.239c.114.112.229.222.253.243l.342.348c.548.577.844.946 1.113 1.396.085.143.185.359.316.668l.327.808.752 2.005.118.29c.275.646.435.718.945.657l.123-.022c.152-.036.296-.191.399-.54l.038-.148c.121-.52.124-1.117.002-1.906l-.072-.413-.283-1.156-.356-1.588 1.853.13.861.076.883.087.26.018c.57.032.94-.024 1.222-.172l.116-.065c.2-.124.219-.246.163-.824l-.015-.155c-.022-.22-.049-.44-.084-.691l-.086-.563-.162-.963c-.032-.141-.093-.348-.175-.579-.057-.162-.122-.327-.2-.516l-.186-.425-.365-.796c-.742-1.501-1.79-2.289-3.184-2.375-.234-.015-.451.004-.872.078zM3 2c.552 0 1 .448 1 1v7l-.007.117c-.057.497-.48.883-.993.883-.552 0-1-.448-1-1V3l.007-.117C2.064 2.386 2.487 2 3 2z"
                            />
                        </svg>
                        <span>{{ dislikes }}</span>
                    </button>
                </div>
                <nz-switch
                    *ngIf="item.itemIsToKeep !== undefined && isAdmin"
                    [(ngModel)]="isKeep"
                    [nzDisabled]="isDisabled"
                    (ngModelChange)="isShowKeeped()"
                    ngClass="item-row__interests_admin_switch ml-3"
                    nzSize="small"
                ></nz-switch>
            </div>
        </ng-template>
    </div>
</div>
<div class="overlay" *ngIf="loading">
    <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
</div>
