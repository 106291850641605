import { Injectable, EventEmitter } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.services';
import { HttpClient } from '@angular/common/http';
import { Pagination, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { map } from 'rxjs/operators';
import { Globals } from 'src/app/_configs/globals';
import { Notification } from '../model/notifications.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService extends MainService {
    public updateNotificationCount = new EventEmitter();

    constructor(protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    getNotificationCount(contextOrgId?: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.notifications.count;
        if (contextOrgId > 0) {
            endpoint += '?contextOrgId=' + contextOrgId;
        }
        return this.httpClient.get(endpoint);
    }

    getAllNotifications(filters?: SelectedFilter[], pagination?: Pagination) {
        let endpoint = this.uri + this.globals.endpoints.notifications.all;

        if (filters || pagination) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            const filter = this.formatGetFilters(filters);
            endpoint += filter;
            endpoint += filter && pagination ? '&' : '';
        }

        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) {
                    pagination.total = data[ 'total' ];
                }
                return data[ 'notifications' ].map((notification) => new Notification(notification));
            })
        );
    }

    archiveNotifications(notifId?: number): Observable<any> {
        let endpoint = this.uri;
        if (notifId) {
            endpoint += this.globals.endpoints.notifications.getSingleArchiveEndpoint(notifId);
        } else {
            endpoint += this.globals.endpoints.notifications.allArchives;
        }
        return this.httpClient.put(endpoint, null);
    }

    triggerNotificationCountUpdate(): void {
        this.updateNotificationCount.emit();
    }
}
