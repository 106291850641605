import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from 'localize-router';
import { RouterModule } from '@angular/router';
import { PromotionFormComponent } from './components/promotion-form/promotion-form.component';
import { PromotionListComponent } from './components/promotion-list/promotion-list.component';
import { MyPromotionsComponent } from './components/my-promotions/my-promotions.component';

@NgModule({
  declarations: [
    PromotionFormComponent,
    PromotionListComponent,
    MyPromotionsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    TranslateModule,
    LocalizeRouterModule,
    RouterModule,
    SharedModule,
  ],
  exports: [MyPromotionsComponent],
})
export class PromotionModule {}
