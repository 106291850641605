export function sameDay(a: Date, b: Date) {
    if (!a && !b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    return (
        a.getFullYear() === b.getFullYear() &&
        a.getMonth() === b.getMonth() &&
        a.getDate() === b.getDate()
    );
}

export function sameHour(a: Date, b: Date) {
    if (!a && !b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    return a.getHours() === b.getHours() && a.getMinutes() === b.getMinutes();
}
export class DateRangeChecker {
    private static convertToUTC(date: Date | string): Date {
        if (date instanceof Date) {
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
        } else if (typeof date === 'string') {
            const parsedDate = new Date(date);
            return new Date(Date.UTC(parsedDate.getUTCFullYear(), parsedDate.getUTCMonth(), parsedDate.getUTCDate()));
        }
        throw new Error('Invalid date format');
    }

    private static setToUTCDateWithTime(date: Date, hours: number, minutes: number, seconds: number, milliseconds: number): void {
        date.setUTCHours(hours, minutes, seconds, milliseconds);
    }

    private static checkDateInRange(utcToday: Date, utcDateFrom: Date, utcDateTo: Date): boolean {
        return utcToday >= utcDateFrom && utcToday <= utcDateTo;
    }

    private static checkStartDate(utcToday: Date, utcDateFrom: Date): boolean {
        return utcToday >= utcDateFrom;
    }

    private static checkEndDate(utcToday: Date, utcDateTo: Date): boolean {
        return utcToday <= utcDateTo;
    }

    static checkInRangeDate(pDateFrom: Date | string, pDateTo?: Date | string, defaut?: boolean): boolean {
        let response = defaut ? defaut : false;
        const today = new Date();
        const utcToday = DateRangeChecker.convertToUTC(today);
        DateRangeChecker.setToUTCDateWithTime(utcToday, today.getHours(), today.getMinutes(), 0, 0);

        if (pDateFrom && pDateTo) {
            const dateFrom = DateRangeChecker.convertToUTC(pDateFrom);
            DateRangeChecker.setToUTCDateWithTime(dateFrom, 0, 1, 0, 0);
            const dateTo = DateRangeChecker.convertToUTC(pDateTo);
            DateRangeChecker.setToUTCDateWithTime(dateTo, 23, 59, 59, 999);

            return DateRangeChecker.checkDateInRange(utcToday, dateFrom, dateTo);
        } else if (pDateFrom) {
            const dateFrom = DateRangeChecker.convertToUTC(pDateFrom);
            DateRangeChecker.setToUTCDateWithTime(dateFrom, 0, 1, 0, 0);
            return DateRangeChecker.checkStartDate(utcToday, dateFrom);
        } else if (pDateTo) {
            const dateTo = DateRangeChecker.convertToUTC(pDateTo);
            DateRangeChecker.setToUTCDateWithTime(dateTo, 23, 59, 59, 999);
            return DateRangeChecker.checkEndDate(utcToday, dateTo);
        }

        return response;
    }
}
