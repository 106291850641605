import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { Organization } from '../../model/organization.model';
import { OrganizationService } from '../../services/organization.service';
import { AccountService } from './../../../account/services/account.service';
import { LocationService } from './../../../location/services/location.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-organisations',
    templateUrl: './organization-list.component.html',
    styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {
    userOrganizations: Organization[];

    isLoading: boolean;

    isDisabled = false;

    lang = this.translate.currentLang;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private accountService: AccountService,
        private localizeRouter: LocalizeRouterService,
        private organizationService: OrganizationService
    ) {}

    ngOnInit(): void {
        this.getUserOrganizations();
    }

    getUserOrganizations(): void {
        this.isLoading = true;
        this.userOrganizations = [];
        this.accountService.getUserOrganizations().subscribe((data: Organization[]) => {
            this.userOrganizations = data;
            this.isLoading = false;
        });
    }

    navigateToOrganization(orgId: number): void {
        this.accountService.setCurrentCtxOrganizationId(orgId);
        const transTab: any[] = this.localizeRouter.translateRoute(['/organization/', orgId, 'modify', 'coordonnees']) as any[];
        this.router.navigate([...transTab]);
    }

    async createNewOrganization(): Promise<void> {
        this.isDisabled = true;
        this.organizationService.createNewOrganization().subscribe((result: any) => {
            const organizationId = result.organization.id;
            this.accountService.setCurrentCtxOrganizationId(organizationId);
            const url = this.localizeRouter.translateRoute(`/organization/${organizationId}/modify`);
            this.router.navigate([url]);
        });
    }
}
