import { Translatable } from 'src/app/shared/model/translatable.model';
import { Translation } from 'src/app/shared/model/translatable.model';

export class Calendar extends Translatable {
  id?: number;
  isMain?: string;
  organizationId?: number;
  countPrograms?: number;

  isEditing?: boolean;

  trans: Translation[];

  constructor(data?: Record<string, any>) {
    super();

    this.id = data['id'] ? data['id'] : undefined;
    this.isMain = data['isMain'] ? data['isMain'] : undefined;
    this.organizationId = data['organizationId']
      ? data['organizationId']
      : undefined;
    this.countPrograms = data['countPrograms'];

    this.trans = data['trans'] ? data['trans'] : [];
  }
}
