<h2 class="mb-4">{{'FACTURATION' | translate}}</h2>
<div class="row">
  <div class="col-md-12">
    <p style="border:1px rgb(193, 193, 193) solid; padding:20px;">
      <strong>{{'FACTURATION-TEXTE1' | translate}}</strong>
      <!-- {{'FACTURATION-TEXTE2' | translate}}.
      <button type="button"
              class="btn-square ml-2"
              (click)="hasCreditCard =!hasCreditCard"><i class="fa fa-eye"></i></button> -->
    </p>

    <ng-container *ngIf="displayCreditCardSection">
      <div class="row mb-4 align-items-center">
        <div class="col-md-6">
          <h2 class="mb-0">{{ creditCards ? ('CARTES-ENREGISTREES' | translate) :   ('AUCUNE-CARTE' | translate)   }}</h2>
        </div>
        <div class="col-md-6 text-right">
          <button type="button" class="btn" [disabled]="showCreditCardForm"
                  (click)="showCreditCardForm  = !showCreditCardForm;">{{'AJOUTER' | translate }}</button>
        </div>
      </div>

      <div *ngIf="isSavingCard"
           class="loader">
        <i nz-icon
           nzType="loading"
         nzTheme="outline"
         aria-hidden="true"></i>
      </div>

      <app-new-credit-card *ngIf="showCreditCardForm"
                           class="d-block mb-4"
                           creditCardFormType="organization"
                           [isSavingCard]="isSavingCard"
                           (onConfirmCreditCard)="saveCreditCard($event)"
                           (onHideForm)="showCreditCardForm = false"></app-new-credit-card>

      <ng-container *ngIf="isReady && creditCards else noCreditCard">
        <div class="item-row item-row--light p-3"
             *ngFor="let creditCard of creditCards">
          <div class="item-row__col">
            <p class="item-row__text">************{{creditCard.ccNumber}}</p>
          </div>
          <!--REMOVE ACTION-->
          <div class="item-row__col item-row__col--auto text-right">
            <button type="button"
                    class="btn-square"
                    (click)="removeCard(creditCard.id)"><i class="icon-x"></i></button>
          </div>
        </div>
      </ng-container>
      <ng-template #noCreditCard>
        <div class="item-row">
          <div class="item-row__col">
            <p class="item-row__text">{{ 'AUCUNE-CARTE-SAUVEGARDEE' | translate }}</p>
          </div>
        </div>
      </ng-template>

      <nz-divider></nz-divider>
    </ng-container>

  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <h2>{{'HISTORIQUE-DE-PAIEMENT' | translate }}</h2>
    <p *ngIf="billingHistory && billingHistory.length > 0">Les confirmations sont envoyées à {{user.email}}
    </p>
    <nz-divider></nz-divider>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container *ngFor="let bill of billingHistory">
      <app-item-row-billing [bill]="bill">
      </app-item-row-billing>
      <nz-divider></nz-divider>
    </ng-container>
  </div>
</div>
