

/**
 *
 * @param string
 * @param maxChar
 * @returns a shorter string if it is longer then the maxChar
 */
export function stringShortener(string: string, maxChar: number): string{
  if(!(string.length > maxChar)){
    return string;
  }

  return string.slice(0, maxChar) + '..';
}
