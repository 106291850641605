<ng-container *ngIf="isReady">
    <app-page-title title="{{ 'ETIQUETTES' | translate }}" 
                    buttonText="{{ 'CREER-TAG' | translate }}"
                    (buttonClicked)="createNewEtiquette()">
    </app-page-title>

    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col col-sm-8 col-lg-6 mb-5">
                <app-etiquettes-large [tagsList]="etiquettes" (onDeleteTags)="deleteEtiquette($event)"></app-etiquettes-large>
            </div>
        </div>
    </div>
</ng-container>