import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

export class OneOrManyValidator extends Validators {
    static validate(formControlNameList?: string[]): ValidatorFn {
        return (formGroup: FormGroup) => {
            let controlList: FormControl[] = [];
            if (!formControlNameList || (formControlNameList && !formControlNameList.length)) {
                controlList = Object.keys(formGroup.controls).map((k: string) => formGroup.get(k) as FormControl);
            } else {
                controlList = formControlNameList.map((name: string) => formGroup.get(name) as FormControl);
            }
            if (!controlList || (controlList && !controlList.length)) {
                return null;
            }
            const values = controlList.map((control: FormControl) => control.value as boolean);
            if (values.every((value: boolean) => !value)) {
                return {
                    hasOneOrManyRequiredError: true
                };
            }
            return null;
        };
    }
}
