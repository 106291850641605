import { ListItem } from 'src/app/shared/model/list-item.model';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { Translation } from 'src/app/shared/model/translatable.model';

export class Tag extends Translatable {
  id?: number;
  image?: string;
  showsId?: number[];
  countShows?: number;
  isAlwaysValid?: number;
  validFrom?: string;
  validTo?: string;
  trans: Translation[];

  constructor(data?: Record<string, any>) {
    super();

    this.id = data['id'] ? data['id'] : undefined;
    this.image = data['image'] ? data['image'] : undefined;
    this.showsId = data['showsId'] ? data['showsId'] : [];
    this.trans = data['trans'] ? data['trans'] : [];
    this.countShows = data['countShows'] ? data['countShows'] : 0;
    this.isAlwaysValid = data.isAlwaysValid;
    this.validFrom = data.validFrom;
    this.validTo = data.validTo;
  }

  toListItem(lang: string): ListItem {
    const item = new ListItem();
    item.itemId = this.id;
    item.itemTitle = this.getTranslatedProperty(lang, 'keyword');
    item.itemSubtitle = '_';
    item.itemImgSrc = this.image ? this.image : '';
    return item;
  }
}

export interface ITagsTrans {
  langId: string;
  keyword: string;
}

export interface ITagUpdate {
  id?: number;
  image?: string;
  showsId?: number[];
  trans?: Translation[];
  isAlwaysValid?: number;
  validFrom?: string;
  validTo?: string;
}
