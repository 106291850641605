import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProgramService } from './services/program.services';
import { Observable } from 'rxjs';
import { Calendar } from './model/calendar.model';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CalendarsResolver implements Resolve<Calendar[]> {
  constructor(
    private readonly programService: ProgramService,
    private readonly userOrganizationIdResolver: UserOrganizationIdResolver
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Calendar[]> {
    return this.userOrganizationIdResolver.resolve().pipe(
      switchMap(contextOrganizationId => this.programService.getCalendars(contextOrganizationId))
    );
  }
}
