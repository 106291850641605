import { Pipe, PipeTransform } from '@angular/core';
import { stringShortener } from '../utils/string-shortener';

@Pipe({
  name: 'stringShortener'
})
export class StringShortenerPipe implements PipeTransform {

  transform(value: string, maxChar: number): any {
    return stringShortener(value, maxChar);
  }

}
