import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetSiteLanguageOptions } from 'src/app/shared/enums/app-languages.enum';

@Pipe({
  name: 'getTranlatedLanguages'
})
export class GetTranlatedLanguagesPipe implements PipeTransform {

  constructor(private translateService: TranslateService){}

  transform(langs: string[]): any {

    const languages = GetSiteLanguageOptions();

    return langs.map(lang => {
      const langOption = languages.find(language => language.id === lang);
      return langOption ? this.translateService.instant(langOption.name) : ''

    } ).join(', ')
  }

}
