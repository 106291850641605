import { Component, OnInit } from '@angular/core';
import { ListItem, Pagination } from 'src/app/shared/model/list-item.model';
import { TranslateService } from '@ngx-translate/core';
import { RowTypes } from 'src/app/shared/enums/row-types.enum';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { formatPhoneNumber } from 'src/app/shared/utils/other-utils';
import { LocalizeRouterService } from 'localize-router';
import { AccountService } from '../../../account/services/account.service';
import { PxUser } from '../../../account/model/project-x-user';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit {
  usersItems: ListItem[];
  listType: RowTypes = RowTypes.CONTACT;
  isLoading = true;
  lang = this.translate.currentLang;
  pagination: Pagination = new Pagination();

  searchText: string;

  constructor(
    private notification: RideauNotificationService,
    private translate: TranslateService,
    private accountService: AccountService,
    private localizeRouter: LocalizeRouterService
  ) {}

  ngOnInit(): void {
    this.initPagination();
    this.getUsers();
  }

  getUsers() {
    this.accountService
      .getAllUsers(null, this.pagination, this.searchText)
      .subscribe((users: PxUser[]) => {
        this.usersItems = users.map((user: PxUser) => {
          const item = this.toListItem(user, this.lang);
          item.itemUrl = `info/${user.id}`;
          return item;
        });
        this.isLoading = false;
      });
  }

  toListItem(user: PxUser, lang: string): ListItem {
    const item = new ListItem();
    item.itemTitle = `${user.firstName} ${user.lastName}`;
    item.itemImgSrc = user.avatar || './assets/images/placeholder-membre.svg';
    item.itemUrl = '';
    item.itemInfo1 = user.email || 'NO-EMAIL';
    item.itemInfo2 = formatPhoneNumber(user.phone) || 'NO-PHONE';

    return item;
  }

  initPagination() {
    this.pagination.limit = 12;
    this.pagination.offset = 0;
    this.pagination.total = 0;
  }

  onSearchChange(event: string) {
    this.searchText = event;
    this.getUsers();
  }

  onPageChange(event: Pagination) {
    this.pagination.offset = event.offset;
    this.getUsers();
  }
}
