<!-- STAND CREATE SINGLE -->
<button nz-button
        class="btn btn--default"
        (click)="showStandFormModal()">
  <i nz-icon
     nzType="plus"
     nzTheme="outline"
     aria-hidden="true"></i>
  <span class="ml-2">{{'AJOUTER-STAND' | translate}}</span>
</button>

<!-- STAND CREATE MANY -->
<button nz-button
        class="btn btn--default ml-2"
        (click)="showMultiStandForm()">
  {{'AJOUTER-STAND-MULTI' | translate}}
</button>
