import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertType'
})
export class ConvertTypePipe implements PipeTransform {
    transform<T extends boolean | number | string, U extends boolean | number | string | undefined>(value: T, args: 'number' | 'boolean' | 'string'): T | U {
        switch (args) {
            case 'boolean':
                return !!value as U;
            case 'string':
                return value.toString() as U;
            case 'number':
                if (typeof value === 'boolean') {
                    return (value ? 1 : 0) as U;
                }
                if (typeof value === 'string') {
                    return (value ? 1 : 0) as U;
                }
                break;
            default:
                return value;
        }
        return value;
    }
}
