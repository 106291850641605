import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

/**
 * DO NOT REMOVE!!
 * Add compatibility from rxjs 5. to 6.
 */
import 'rxjs-compat/add/operator/do';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/operator/mergeMap';





if (environment.IS_PRODUCTION) {
  enableProdMode();
}

initContainer(environment.GTM_ID);

// On doit catcher le code erreur forget password pas le choix, merci azure
function getHashValue(key) {
  const matches = location.hash.match(new RegExp(key + '=([^&]*)'));
  return matches ? matches[1] : null;
}

const value = getHashValue('error_description');

if (value && value.startsWith('AADB2C90118')) {
  window.location.href = environment.AZ_FORGET;
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

function initContainer(id: string) {
  (function (w, d, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const j: any = d.createElement('script');
    const dl: any = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    d.head.prepend(j);

    const fr: any = d.createElement('iframe');
    fr.style.height = '0';
    fr.style.width = '0';
    fr.src = 'https://www.googletagmanager.com/gtm.js?id=' + i;
    const ns: HTMLElement = d.createElement('noscript');
    ns.insertAdjacentHTML(
      'beforeend',
      '<iframe src="' +
        fr.src +
        '" width= "' +
        fr.style.width +
        '" height="' +
        fr.style.height +
        '" style="display:none;visibility:hidden"></iframe>'
    );

    d.body.prepend(ns);
  })(window, document, 'dataLayer', id);
}
