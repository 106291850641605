import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';
import { PaidTourShowsResolver } from './paid-tour-shows.resolver';
import { Store } from './store';

@Injectable({ providedIn: 'root' })
export class PaidTourShowResolver extends Store<TourShow> implements Resolve<TourShow> {
  constructor(
    private readonly paidTourShowsResolver: PaidTourShowsResolver
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TourShow> {
    return this.initialize(this.paidTourShowsResolver.resolve(route).pipe(
      map(tourShows => tourShows.find(tourShow => tourShow.id === +route.paramMap.get('tourShowId')))
    ));
  }

  incrementProgramCount() {
    const programCountNumber = this.state.programCount || 0;
    this.state.programCount = programCountNumber + 1;
    this.state = this.state;
  }

  decrementProgramCount() {
    const programCountNumber = this.state.programCount || 0;
    this.state.programCount = programCountNumber - 1;
    this.state = this.state;
  }
}
