import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToursService } from './services/tours.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tour } from './model/tour.model';

@Injectable({ providedIn: 'root' })
export class TourResolver implements Resolve<Tour> {
  constructor(
    private readonly toursService: ToursService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tour> {
    return this.toursService.getTourById(+route.paramMap.get('tourId'));
  }
}