<div nz-row>
    <div nz-col nzSpan="24">
        <strong>{{ title | translate}}</strong>
        <p>{{ subtitle | translate}}</p>
    </div>
    <ng-container *ngIf="!disabled">
        <div nz-col nzSpan="24">
            <div class="c-upload-box">
                <header class="c-upload-box__header p-3 d-flex align-items-center">
                    <div class="w-50">{{'FORM.TYPE-DE-FICHIER' | translate}}</div>
                    <div class="w-50">{{'FORM.FILE-NAME' | translate | case: 'capitalize' }}</div>
                </header>
                <main class="c-upload-box__list p-3" *ngIf="itemsLength$ | async">
                    <ng-container *ngFor="let item of items$ | async; first as first$">
                        <div [ngClass]="{'mt-3': !first$}">
                            <app-item-row-file (uploaded)="uploadedHandler($event)"
                                (removed)="removedRowHandler($event)" [name]="name" [maxFileSize]="maxFileSize"
                                [shouldDisplayImage]="displayImage" (selected)="selectedTypeIdHandler($event)"
                                [types]="initialItems$ | async" [isDisabled]="disabled" [showCredit]="false"
                                [isVitrine]="isVitrine" [showSelectFileLabel]="true" [(item)]="item"
                                [acceptedFileTypes]="acceptedFileTypes"></app-item-row-file>
                        </div>
                    </ng-container>
                </main>
                <footer class="c-upload-box__footer p-3">
                    <button type="button" appButton class="btn" (click)="addRowHandler()"
                        [disabled]="(isButtonDisabled$ | async) || (shouldDisableButtonFromUploadFiles$ | async)">
                        <i class="icon-plus" aria-hidden="true"></i>
                        <span>{{ btnText | translate }}</span></button>
                </footer>
            </div>
        </div>
    </ng-container>
</div>