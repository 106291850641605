import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-blank.validator';
import { Organization } from '../../organization/model/organization.model';
import { TranslateService } from '@ngx-translate/core';
import { Gratuitie } from './gratuities.model';
import { Translation } from 'src/app/shared/model/translatable.model';

export interface IProduct {
    id?: number;
    productId: number;
    organizationId: number;
    productTypeId: number;
    isAlwaysValid: number;
    validFrom: string;
    validTo: string;
    isQuantityUnlimited: number;
    quantity: number;
    uniqueCode: number;
    adminOrgAccountingNumber: number;
    adminOrgProjectNumber: number;
    orgAccountingNumber: number;
    orgProjectNumber: number;
    isFree: number;
    singlePrice: string;
    taxable: number;
    isStandOptional?: number;
    meetingId?: number;
    meetingName?: string;
    tariffs?: Tarif[];
    trans: Translation[];
    isPaid?: number;
    gratuities: Gratuitie[];
    remainingQuantity?: number;
}

export class Product extends Translatable implements IProduct {
    id?: number;
    productId: number;
    organizationId: number;
    organization?: Organization;
    productTypeId: number;
    isAlwaysValid: number;
    validFrom: string;
    validTo: string;
    isQuantityUnlimited: number;
    quantity: number;
    uniqueCode: number;
    adminOrgAccountingNumber: number;
    adminOrgProjectNumber: number;
    orgAccountingNumber: number;
    orgProjectNumber: number;
    isFree: number;
    singlePrice: string;
    taxable: number;
    isStandOptional?: number;
    meetingId?: number;
    meetingName?: string;
    tariffs?: Tarif[];
    trans: Translation[];
    isPaid?: number;
    gratuities: Gratuitie[];
    remainingQuantity?: number;

    private generalForm: FormGroup;
    private compatibilityForm: FormGroup;
    private tarificationForm: FormGroup;

    constructor(datas: Record<string, any>) {
        super();
        this.id = datas[ 'id' ];
        this.productId = datas[ 'productId' ];
        this.organizationId = datas[ 'organizationId' ];
        this.organization = datas[ 'organization' ];
        this.productTypeId = datas[ 'productTypeId' ];
        this.isAlwaysValid = datas[ 'isAlwaysValid' ];
        this.validFrom = datas[ 'validFrom' ];
        this.validTo = datas[ 'validTo' ];
        this.isQuantityUnlimited = datas[ 'isQuantityUnlimited' ];
        this.quantity = datas[ 'quantity' ];
        this.uniqueCode = datas[ 'uniqueCode' ];
        this.adminOrgAccountingNumber = datas[ 'adminOrgAccountingNumber' ];
        this.adminOrgProjectNumber = datas[ 'adminOrgProjectNumber' ];
        this.orgAccountingNumber = datas[ 'orgAccountingNumber' ];
        this.orgProjectNumber = datas[ 'orgProjectNumber' ];
        this.isFree = datas[ 'isFree' ];
        this.singlePrice = datas[ 'singlePrice' ];
        this.taxable = datas[ 'taxable' ];
        this.isStandOptional = datas[ 'isStandOptional' ];
        this.meetingId = datas[ 'meetingId' ];
        this.meetingName = datas[ 'meetingName' ];
        this.trans = datas[ 'trans' ];
        this.tariffs = datas[ 'tariffs' ];
        this.isPaid = datas[ 'isPaid' ];
        this.remainingQuantity = datas[ 'remainingQuantity' ];
        if (datas[ 'gratuities' ]) {
            this.gratuities = datas[ 'gratuities' ].map((grat) => new Gratuitie(grat));
        }
    }

    toListItem(lang: string, translate: TranslateService): ListItem {
        let itemPrice;
        let itemInfo3;
        let itemInfo4;

        if (this.isFree) {
            itemPrice = translate.instant('GRATUIT');
        } else if (this.singlePrice) {
            itemPrice = this.singlePrice.replace('.', ',') + '$';
        } else {
            itemPrice = translate.instant('NON-SPECIFIE');
        }
        const dateFrom = new Date(this.validFrom);
        const dateTo = new Date(this.validTo);

        if (this.validFrom == null && this.validTo == null) {
            itemInfo3 = '';
            itemInfo4 = '';
        } else {
            itemInfo3 =
                dateFrom.getDate() +
                '/' +
                (dateFrom.getMonth() + 1) +
                '/' +
                dateFrom.getFullYear();
            itemInfo4 =
                dateTo.getDate() +
                '/' +
                (dateTo.getMonth() + 1) +
                '/' +
                dateTo.getFullYear();
        }

        return {
            itemId: this.id,
            itemTitle: this.getTranslatedProperty(lang, 'name'),
            itemSubtitle: this.organization
                ? new Organization(this.organization).getTranslatedProperty(
                    lang,
                    'name'
                )
                : '',
            itemUrl: '',
            itemInfo1: this.getTranslatedProperty(lang, 'descPublic'),
            itemInfo2: translate.instant('PRODUCT-TYPE.' + this.productTypeId),
            itemInfo3: itemInfo3,
            itemInfo4: itemInfo4,
            itemPrice: itemPrice,
        } as ListItem;
    }

    /*FormGroups*/
    getGeneralFormGroup(fb: FormBuilder, lang: string): FormGroup {
        if (!this.generalForm) {
            this.generalForm = fb.group({
                name: [
                    this.getTranslatedProperty(lang, 'name'),
                    [ Validators.required, noWhitespaceValidator ],
                ],
                organizationId: [ this.organizationId ],
                descInternal: [ this.getTranslatedProperty(lang, 'descInternal') ],
                descPublic: [ this.getTranslatedProperty(lang, 'descPublic') ],
                productTypeId: [ this.productTypeId ],
                isAlwaysValid: [ this.isAlwaysValid ],
                productDateRange: [ [ this.validFrom, this.validTo ] ],
                validFrom: [ this.validFrom ],
                validTo: [ this.validTo ],
                isQuantityUnlimited: [ this.isQuantityUnlimited ],
                quantity: [ this.quantity ],
                isStandOptional: [ !!this.isStandOptional ],
                meetingId: [ this.meetingId ],
                remainingQuantity: [ this.remainingQuantity ]
            });
        }
        return this.generalForm;
    }

    getCompatibilityFormGroup(fb: FormBuilder, lang: string): FormGroup {
        if (!this.compatibilityForm) {
            this.compatibilityForm = fb.group({
                adminOrgAccountingNumber: [ this.adminOrgAccountingNumber ],
                adminOrgProjectNumber: [ this.adminOrgProjectNumber ],
                uniqueCode: [ this.uniqueCode ],
                orgAccountingNumber: [ this.orgAccountingNumber ],
                orgProjectNumber: [ this.orgProjectNumber ],
                taxable: [ this.taxable ],
                productTypeId: [ this.productTypeId ]
            });
        }
        return this.compatibilityForm;
    }

    getTarificationFormGroup(fb: FormBuilder, lang: string): FormGroup {
        if (!this.tarificationForm) {
            this.tarificationForm = fb.group({
                isFree: [ this.isFree ],
                singlePrice: [ this.singlePrice ],
            });
        }
        return this.tarificationForm;
    }

    setFinalPrice(): Product {
        if (this.isFree || (this.singlePrice === undefined || this.singlePrice && this.singlePrice.length === 0)) {
            this.singlePrice = '0';
        } else if (this.gratuities && this.gratuities.length) {
            this.singlePrice = this.gratuities[ 0 ].price
                ? this.gratuities[ 0 ].price.toString()
                : '0';
        }
        return this;
    }

    isNotExpired(): boolean {
        const today = new Date();

        // Si dateFrom et DateTo sont définis, on vérifie que today est entre les deux.
        if (this.validFrom && this.validTo) {
            return (
                today >= new Date(this.validFrom) && today <= new Date(this.validTo)
            );
        }
        // Si seul this.validFrom est défini, on vérifie que today est après.
        else if (this.validFrom && !this.validTo) {
            return today >= new Date(this.validFrom);
        }
        // Si seul this.validTo est défini, on vérifie que today est avant.
        else if (!this.validFrom && this.validTo) {
            return today <= new Date(this.validTo);
        }
    }

    get hasRemainingQuantities(): boolean {
        // has unlimited quantities of product
        const isQuantityUnlimited = this.isQuantityUnlimited !== 0;
        /* has null quantity of product
        * /!\ This condition will be removed
        */
        const hasNullQuantity = this.remainingQuantity === null;
        // has remaining quantity of product
        const hasRemainingQuantity = this.remainingQuantity > 0;
        return isQuantityUnlimited || (!isQuantityUnlimited && (hasNullQuantity || hasRemainingQuantity));
    }
}

export interface ICreatedProduct {
    isCreated: boolean;
    product: IProduct;
}

export class Tarif {
    id: any;
    participantTypeId: number;
    minInscriptions: number;
    price: number;
    toDelete?: number;

    // constructor(data: any) {
    //   this.id = data['id'];
    //   this.participantTypeId = data['participantTypeId'];
    //   this.minInscriptions = data['minInscriptions'];
    //   this.price = data['price'];
    // }
}
