import { Globals } from 'src/app/_configs/globals';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { TarifTypes, VolumeTypes } from 'src/app/concepts/product/enums/tarifs-enum';
import { Product } from 'src/app/concepts/product/model/product.model';
import { ProductService } from 'src/app/concepts/product/services/product.service';
import { Meeting } from '../../../model/meeting.model';
import { AccountService } from './../../../../account/services/account.service';
import { ProductTypes } from './../../../../product/enums/product-types-enum';
import { ParticipantMeeting } from './../../../model/participant.model';

@Component({
    selector: 'app-inscription-row',
    templateUrl: './inscription-row.component.html',
    styleUrls: [ './inscription-row.component.scss' ]
})
export class InscriptionRowComponent implements OnInit {
    @Input()
    participant: ParticipantMeeting;

    @Input()
    meeting: Meeting;

    products: Product[];

    @Output()
    removeProduct: EventEmitter<Product> = new EventEmitter<Product>();

    @Output()
    addProduct: EventEmitter<Product> = new EventEmitter<Product>();

    @Output()
    removeParticipant: EventEmitter<number> = new EventEmitter<number>();

    lang = this.translate.currentLang;
    currentOrganisationId: number;
    loadingProducts = false;

    totalPrice: any;
    formatter = new Intl.NumberFormat('fr-CA', {
        style: 'currency',
        currency: 'CAD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2
    });

    constructor(private global: Globals, private translate: TranslateService, private accountService: AccountService, private productService: ProductService) { }

    ngOnInit(): void {
        // Remove products that are already payed (Ce cas ne devrait pas arriver mais on ne sait jamais)
        this.participant.products = this.participant.products.filter((product) => !product.isPaid).map((product) => new Product(product));
        this.currentOrganisationId = this.accountService.getCurrentCtxOrganizationId();
    }

    /**
     * Récupère les produits et meilleurs tarifs pour le participant. Cette methode est appelée à
     * chaque fois que l'on clique sur "Ajouter un produit" pour mettre à jour la liste.
     */
    getProductsForMeeting(): void {
        this.loadingProducts = true;

        const filters = [
            {
                field: 'meetingId',
                value: this.participant.meetingId
            },
            {
                field: 'productTypeId',
                value: ProductTypes.INSCRIPTION
            },
            {
                field: 'cheaperPrice',
                value: 1
            },
            {
                field: 'userId',
                value: this.participant.user.id
            },
            {
                field: 'checkValidDateProduct',
                value: 1
            }
        ];

        if (this.currentOrganisationId !== this.global.NO_ORGA) {
            filters.push({
                field: 'organizationId',
                value: this.currentOrganisationId
            });
        }

        this.productService
            .getProducts(filters)
            .pipe(finalize(() => (this.loadingProducts = false)))
            .subscribe((products: Product[]) => {
                // Virer de la liste des produits ceux déjà ajoutés par le participant
                this.products = products
                    .filter((prod) => {
                        const found = this.participant.products.find((partProd) => partProd.id === prod.id);
                        return !found;
                    })
                    .filter((x: Product) => {
                        return x.hasRemainingQuantities;
                    });
            });
    }

    /**
     * revoie une description du prix du produit selon qu'il est fixe, gratuit ou à tarfis multiples.
     * @param product
     */
    public getDescriptionForProduct(product: Product): string {
        if (product.isFree) {
            return this.translate.instant('GRATUIT');
        } else if (product.gratuities && product.gratuities.length > 0) {
            return this.translate.instant('SPECIAL-GRATUITIE');
        } else if (product.tariffs && product.tariffs.length > 0) {
            // on prend le premier tariff car il ne peux y'en avoir qu'un par produit par participant
            const tarif = product.tariffs[ 0 ];
            let description = '';

            switch (tarif.participantTypeId) {
                case TarifTypes.ANY_TYPE:
                    description += this.translate.instant('PRIX-REGULIER');
                    break;
                case TarifTypes.PRODUCER:
                    description += this.translate.instant('TARIF_TYPES.PRODUCER');
                    break;
                case TarifTypes.DIFFUSER:
                    description += this.translate.instant('TARIF_TYPES.DIFFUSER');
                    break;
                case TarifTypes.ROOM_MANAGER:
                    description += this.translate.instant('TARIF_TYPES.ROOM_MANAGER');
                    break;
                case TarifTypes.ORGA_MEMBER:
                    description += this.translate.instant('TARIF_TYPES.ORGA_MEMBER') + ' ' + this.meeting.organizationName;
                    break;
                case TarifTypes.GOVERNMENT_INSTITUTION:
                    description += this.translate.instant('TARIF_TYPES.GOVERNMENT_INSTITUTION');
                    break;
                case TarifTypes.PROVIDER:
                    description += this.translate.instant('TARIF_TYPES.PROVIDER');
                    break;
                case TarifTypes.OTHER:
                    description += this.translate.instant('TARIF_TYPES.OTHER');
                    break;
            }

            description += ' - ';

            switch (tarif.minInscriptions) {
                case VolumeTypes.TWO:
                    description += this.translate.instant('VOLUME_TYPES.TWO');
                    break;
                case VolumeTypes.THREE:
                    description += this.translate.instant('VOLUME_TYPES.THREE');
                    break;
                case VolumeTypes.FOUR:
                    description += this.translate.instant('VOLUME_TYPES.FOUR');
                    break;
                case VolumeTypes.ALL:
                    description = description.substring(0, description.length - 3);
                    break;
            }

            return description;
        } else if (product.singlePrice) {
            return this.translate.instant('PRIX-REGULIER');
        }
    }

    /**
     * revoie le prix du produit formaté, suivant qu'il est gratui, fixe, ou à tarfis multiples.
     * @param product
     */
    public getPriceForProduct(product: Product): string {
        if (product.isFree) {
            return this.formatter.format(0);
        } else if (product.gratuities && product.gratuities.length) {
            return this.formatter.format(Number(product.gratuities[ 0 ].price));
        } else if (product.singlePrice) {
            return this.formatter.format(Number(product.singlePrice));
        } else if (product.tariffs && product.tariffs.length) {
            return this.formatter.format(Number(product.tariffs[ 0 ].price));
        }
    }
}
