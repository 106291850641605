import { Injectable } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Tag } from '../model/tags.model';
import { LocalizeRouterService } from 'localize-router';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from 'src/app/shared/model/list-item.model';
import { map } from 'rxjs/operators';
import { Globals } from 'src/app/_configs/globals';
import { ITagUpdate } from '../model/tags.model';

@Injectable({
    providedIn: 'root'
})
export class TagsService extends MainService {
    constructor(
        protected httpClient: HttpClient,
        private localizeRouter: LocalizeRouterService,
        private translate: TranslateService,
        protected globals: Globals,
        private router: Router
    ) {
        super(httpClient, globals);
    }

    getAllEtiquettes(): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.etiquettes.all;
        return this.httpClient.get(endpoint);
    }

    getAllEtiquettesWithShows(): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.etiquettes.allWithShows;
        return this.httpClient.get(endpoint);
    }

    getTagById(id: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.etiquettes.singleTag(id);
        return this.httpClient.get(endpoint);
    }

    getShowsByTagId(id: number, pagination?: Pagination): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.etiquettes.showTag(id);

        if (pagination) {
            endpoint += '?' + this.formatPagination(pagination);
        }

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) {
                    pagination.total = data['total'];
                }
                return data['shows'];
            })
        );
    }

    addShowToTag(tagId: number, showId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.etiquettes.addShows;
        const tag = {
            tagId: tagId,
            showsId: [showId]
        };
        return this.httpClient.post(endpoint, tag);
    }

    updateTagById(tag: ITagUpdate): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.etiquettes.create;
        return this.httpClient.put(endpoint, tag);
    }

    deleteShowsByTagId(tagId: number, showId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.etiquettes.deleteShowTag(tagId, showId);
        return this.httpClient.delete(endpoint);
    }

    createEtiquetteAndRedirectToSingle(currentLang) {
        const endpoint = this.uri + this.globals.endpoints.etiquettes.create;
        // let body = new Tag();
        const tag = new Tag({});
        tag.setTranslatedProperty(this.translate.currentLang, 'keyword', this.translate.instant('NOUVELLE-ETIQUETTE'));

        this.httpClient.post(endpoint, tag).subscribe((res) => {
            const url = this.localizeRouter.translateRoute(`/admin/etiquettes/${res['tag']['id']}/`);
            this.router.navigate([url]);
        });
    }

    deleteTag(id: number): Observable<any> {
        // console.log(id)
        const endpoint = this.uri + this.globals.endpoints.etiquettes.singleTag(id);
        return this.httpClient.delete(endpoint);
    }
}
