import { Venue } from 'src/app/concepts/venue/model/venue.model';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { LocalizeRouterService } from 'localize-router';
import { NzModalService } from 'ng-zorro-antd';
import { VenueService } from 'src/app/concepts/venue/services/venue.service';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';

@Component({
  selector: 'app-organization-venues',
  templateUrl: './organization-venues.component.html',
  styleUrls: ['./organization-venues.component.scss'],
})
export class OrganizationVenuesComponent implements OnInit {
  @Input()
  public organizationId: number;
  public venues: any = [];
  public lang = this.translate.currentLang;

  constructor(
    private router: Router,
    private venueService: VenueService,
    private translate: TranslateService,
    private modalService: NzModalService,
    private notification: RideauNotificationService,
    private accountService: AccountService,
    private localize: LocalizeRouterService
  ) {}

  ngOnInit(): void {
    this.getVenues();
  }

  getVenues(): void {
    this.venueService
      .getVenues(this.organizationId)
      .subscribe((venues) => (this.venues = venues));
  }

  removeVenue(venueId) {
    const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
    const acceptDelete = this.translate.instant('OUI');
    const rejectDelete = this.translate.instant('NON');
    this.modalService.confirm({
      nzContent: deleteMessages,
      nzOkText: this.translate.instant(acceptDelete),
      nzCancelText: this.translate.instant(rejectDelete),
      nzClosable: true,
      nzMaskClosable: true,
      nzOnOk: () =>
        this.venueService
          .removeVenue(venueId, this.organizationId)
          .subscribe(() => {
            this.notification.success(
              this.translate.instant('FORM.SALLE-SUPPRIMEE')
            );
            this.getVenues();
          }),
    });
  }

  modifyVenueRoute(organizationId, venueId) {
    const url = this.localize.translateRoute(
      `/venue/${organizationId}/${venueId}/modify`
    );
    this.router.navigate([url]);
  }

  async newVenue(): Promise<void> {
    this.organizationId = this.accountService.getCurrentCtxOrganizationId();
    this.venueService.createBlankVenueAndRedirectToForm(this.organizationId);
  }
}
