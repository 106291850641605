import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { NzModalService } from 'ng-zorro-antd';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { countInvalidControls } from 'src/app/shared/utils/other-utils';
import { isBlankValidator } from 'src/app/shared/validators/not-blank.validator';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { Show } from '../../model/show.model';
import { ShowService } from '../../services/show.service';
import { AccountService } from './../../../account/services/account.service';
import { ShoppingCartService } from './../../../account/services/shopping-cart.service';
import { ProductTypes } from './../../../product/enums/product-types-enum';
import { ShowStatus } from './../../enums/show-status.enum';
import { SHOW_TABS_IDS, ShowTab, ShowTabsService } from '../../services/show-tabs.service';
@Component({
    selector: 'app-show-modify',
    templateUrl: './show-modify.component.html',
    styleUrls: [ './show-modify.component.scss' ]
})
export class ShowModifyComponent implements OnInit, OnDestroy {
    public allFormsAreValid$: Observable<boolean>;
    private _show: Show;
    get show() {
        return this._show;
    }

    set show(show: Show) {
        this._show = show;
        this.allFormsAreValid$ = this._show.requiredPropAreNotEmpty(this.lang);
    }

    // flags
    public displayErrors = false;
    public isLoading: boolean;
    public isDeleteLoading: boolean;
    public isReady = false;
    public isInCart: boolean;

    public showUrl: string;
    public cartProduct: any;

    public prevValues: any;
    public newValues: any;

    /*FORM STEPS*/
    public readonly current$: Observable<string> = this.showTabs.currentTabId$;
    public readonly showTabIdEnum = SHOW_TABS_IDS;
    public readonly tabs$: Observable<ShowTab[]> = this.showTabs.list$;
    public totalErrorCount$: Observable<number> = this.tabs$.pipe(map((tabs: ShowTab[]) => tabs.reduce((acc, value) => acc + value.errorCount, 0)));
    lang: string;
    public successMsg: string;
    constructor(
        private showService: ShowService,
        private route: ActivatedRoute,
        private router: Router,
        private localizeRouter: LocalizeRouterService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private notification: RideauNotificationService,
        private shoppingCartService: ShoppingCartService,
        private authService: AuthService,
        public breadcrumbService: BreadcrumbService,
        private accountService: AccountService,
        private modalService: NzModalService,
        private showTabs: ShowTabsService
    ) { }

    ngOnInit(): void {
        // listen to route changes
        this.lang = this.translate.currentLang;
        this.handleRouteEvents();
        this.showService.getShow(this.route.snapshot.params.showId).subscribe((show) => {
            this.show = new Show(show);
            const breadcrumbItems: BreadcrumbItem[] = [];
            const showName = this.show.getTranslatedProperty(this.lang, 'title');
            this.showUrl = '/shows-offers/' + this.show.organizationId + '/' + this.show.id;
            breadcrumbItems.push(
                new BreadcrumbItem({
                    title: showName,
                    url: this.showUrl,
                    isEditing: true
                })
            );

            this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);

            this.initChangedInfos();

            forkJoin([ this.getShowAudiences() ]).subscribe(() => {
                this.updateErrorCounts();
                this.isReady = true;
            });
        });

        // Vérifier si l'offre de spectacle est dans le panier d'achat
        this.shoppingCartService
            .isItemInCart(Number(this.route.snapshot.params.showId), ProductTypes.PUBLICATION_DOFFRE)
            .subscribe((res) => (this.isInCart = res));
    }

    initChangedInfos() {
        this.prevValues = this.getInfoToCheck();
    }
    getInfoToCheck() {
        return {
            longDescr: this.show.getTranslatedProperty(this.lang, 'longDescription'),
            techSpec: this.show.urlTechSpecification,
            urlVideo: this.show.urlVideo,
            urlAudio: this.show.urlAudio,
            urlPressPhoto1: this.show.urlPressPhoto1,
            urlPressPhoto2: this.show.urlPressPhoto2,
            urlPromoPhoto1: this.show.urlPromoPhoto1,
            urlPromoPhoto2: this.show.urlPromoPhoto2
        };
    }

    checkChangedInfos() {
        this.newValues = this.getInfoToCheck();
        return JSON.stringify(this.newValues) != JSON.stringify(this.prevValues);
    }

    ngOnDestroy(): void {
        if (!this.show) {
            return;
        }
        if (this.show.statusId === ShowStatus.APPROUVE && this.checkChangedInfos()) {
            this.sendNotificationEmail().subscribe();
        }
    }

    sendNotificationEmail() {
        return this.showService.updateShowInfo(this.show.id, this.show.organizationId, this.prevValues, this.newValues);
    }

    getShowAudiences(): Observable<any> {
        return this.showService.getAudiences(this.show.id).do((data) => (this.show[ 'audiences' ] = data[ 'audiences' ]));
    }

    getShowClassif(): Observable<any> {
        return this.showService.getClassifications(this.show.id).do((data) => (this.show.classifications = data));
    }
    /**
     * L'onglet courrant est determiné par le parametre :page de l'URL. Comme celui-ci
     * est traduit, il y a un travail de revers traduction à faire pour trouver l'onglet
     * correspondant.
     */
    private async handleRouteEvents() {
        this.route.params.subscribe(async (params) => {
            if (params.page) {
                // traduire tous les onglets jusqu'à trouver celui passé en URL
                const currentTabId = this.showTabs.getCurrentTabId(params.page);
                this.showTabs.setCurrentTabId(currentTabId);
            } else {
                this.showTabs.setCurrentTabId();
                //redirect to identification si pas de parametre.
                const currentTabId = this.showTabs.getCurrentTabId();
                this.router.navigate([ `./${this.localizeRouter.translateRoute(currentTabId)}` ], { relativeTo: this.route });
            }
        });
    }

    private gotToNextTab() {
        //redirect to next tab.
        const nextTabId = this.showTabs.getNextTabId();
        this.router.navigate([ `../${this.localizeRouter.translateRoute(nextTabId)}` ], { relativeTo: this.route });
    }

    saveShow(event: any) {
        this.isLoading = true;

        forkJoin([ this.saveShowObject(), this.saveShowAudiences(), this.saveShowArists(), this.saveShowClassif() ])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((res) => {
                this.successMsg = this.translate.instant('FORM.SAUVEGARDE');
                this.notification.success(this.successMsg);
                this.updateErrorCounts();

                // Si on est PAS sur le dernier onglet et qu'on a pas d'event.
                if (this.show.statusId !== ShowStatus.SOUMIS && event === undefined) {
                    this.gotToNextTab();
                }
            });
    }

    saveShowObject(): Observable<any> {
        return this.showService.modifyShow(this.show);
    }

    saveShowAudiences(): Observable<any> {
        return this.showService.modifyShowAudience(this.show.id, this.show.organizationId, this.show.audiences);
    }

    saveShowArists(): Observable<any> {
        return this.showService.modifyShowArtists(
            this.show.id,
            this.show.organizationId,
            this.show.artists.map((art) => art.name)
        );
    }

    saveShowClassif(): Observable<any> {
        return this.showService.modifyClassification(this.show.id, this.show.organizationId, this.show.classifications);
    }

    toggleDisplayErrors() {
        this.displayErrors = !this.displayErrors;
        if (this.displayErrors) {
            this.updateErrorCounts();
        }
    }

    //Cart
    addToCart() {
        this.cartProduct = {
            productTypeId: ProductTypes.PUBLICATION_DOFFRE,
            // productId: 1, // TODO  : changer ça
            organizationId: this.show.organizationId,
            userId: parseInt(this.authService.User.id),
            quantity: 1,
            itemId: this.show.id
        };
        return this.shoppingCartService.addToCart(this.cartProduct).do((data) => {
            console.log('Product Cart Saved: ', data);
            this.notification.success(this.translate.instant('FORM.AJOUTE-AU-PANIER'));
        });
    }

    payLater() {
        //Simply call add to cart
        this.addToCart().subscribe(() => (this.isInCart = true));
    }

    payNow() {
        //Add to cart then redirect to Shopping Cart
        this.addToCart().subscribe(() => {
            const url = this.localizeRouter.translateRoute(`/shopping-cart`);
            this.router.navigate([ url ]);
        });
    }

    private updateErrorCounts() {
        // identification
        const identityForm = this.show.getIdentificationFormGroup(this.lang);
        let identityErrorCount = countInvalidControls(identityForm);
        // Custom error count for show descriptions box (not part of the form group)
        const shortDescInput = this.show.getTranslatedProperty(this.translate.currentLang, 'shortDescription');
        if (isBlankValidator(shortDescInput)) identityErrorCount++;
        const longDescInput = this.show.getTranslatedProperty(this.translate.currentLang, 'longDescription');
        if (isBlankValidator(longDescInput)) identityErrorCount++;
        this.showTabs.setTabErrorCount('IDENTIFICATION', identityErrorCount);

        // classification
        const classifCount = this.show.classifications.length === 0 ? 1 : 0;
        const audienceCount = this.show.audiences.length === 0 ? 1 : 0;
        this.showTabs.setTabErrorCount('CLASSIFICATION', classifCount + audienceCount);

        // cachet
        const cachetfForm = this.show.getCachetFormGroup(this.fb);
        this.showTabs.setTabErrorCount('CACHET', countInvalidControls(cachetfForm));

        // materiel
        this.showTabs.setTabErrorCount('MATERIEL', this.show.urlPromoPhoto1 && this.show.lblPromoPhoto1 ? 0 : 1);
    }

    submitForApproval() {
        this.changeStatus(ShowStatus.SOUMIS).subscribe(() => {
            this.notification.success(this.translate.instant('MESSAGES.SOUMIS-POUR-APPROBATION'));
        });
    }

    approve() {
        this.changeStatus(ShowStatus.APPROUVE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.APPROUVEE'));
        });
    }

    reject() {
        this.changeStatus(ShowStatus.REJETE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.REJETEE'));
        });
    }

    private changeStatus(status: ShowStatus): Observable<any> {
        const approvalState = {
            showId: this.show.id,
            organizationId: this.show.organizationId,
            statusId: status
        };
        this.isLoading = true;
        return this.showService.modifyShowApproval(approvalState).do(() => {
            this.show.statusId = status;
            this.isLoading = false;
        });
    }

    deleteShow() {
        this.isDeleteLoading = true;
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.showService.deleteShow(this.show.id, this.accountService.getCurrentCtxOrganizationId()).subscribe(() => {
                    this.isDeleteLoading = false;
                    this.notification.success(this.translate.instant('SHOW-SUPPRIMEE'));
                    this.router.navigate([ '/dashboard' ]);
                });
            }
        });
    }
}
