import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { OrganisationStatus } from 'src/app/concepts/organization/enums/organization-status.enum';
import { tagBottinSearch, BottinSearchData } from 'src/app/data-layer';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { LocationService } from '../../concepts/location/services/location.service';
import { RowTypes } from '../../shared/enums/row-types.enum';
import { Globals } from '../../_configs/globals';
import { OrganizationTypes } from './../../concepts/organization/enums/organization-types.enum';
import { Organization } from './../../concepts/organization/model/organization.model';
import { BottinService } from './../../concepts/organization/services/bottin.service';
import { Venue } from './../../concepts/venue/model/venue.model';
import { Filter, FilterValue, Pagination, SelectedFilter, Sort } from './../../shared/model/list-item.model';
import { BottinAdvancedSearchComponent } from './bottin-advanced-search/bottin-advanced-search.component';
import { advancedSelectedFilters } from './bottin-advanced-search';
import { VenueType } from '@app/concepts/venue/enums/venue-type.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-bottin',
    templateUrl: './bottin.component.html',
    styleUrls: [ './bottin.component.scss' ]
})
export class BottinComponent implements OnInit {
    public lang: string;
    public pageTitle: string;

    catalogArray: Array<Organization | Venue> = [];

    itemList: ListItem[] = [];

    leftSideFilters: Filter[] = [];

    sorts: Sort[] = [];

    pagination: Pagination;

    searchText: string;

    isLoading: boolean;
    private bottinSearchData: BottinSearchData = {};

    // Par defaut on affiche que les orgas approuvées.
    defaultFilters: SelectedFilter[] = [
        {
            field: 'statusId',
            value: this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID ? '' : 3
        },
        {
            field: 'withAddress',
            value: 1
        }
    ];
    leftSideSelectedFilter: SelectedFilter = new SelectedFilter();
    advancedSelectedFilters: SelectedFilter[] = [];
    listType: RowTypes = RowTypes.BOTTIN;

    constructor(
        public bottinService: BottinService,
        public Location: LocationService,
        private globals: Globals,
        private accountService: AccountService,
        private translate: TranslateService,
        private httpClient: HttpClient
    ) { }

    ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.pageTitle = this.translate.instant('REPERTOIRE');

        this.initFilters();
        this.initSort();
        this.initPagination();
        if (!sessionStorage.getItem('bottinAdvFilters')) {
            this.getOrganizations();
        }

        this.accountService.currentOrganizationChange.subscribe(() => {
            this.defaultFilters[ 0 ].value = this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID ? '' : 3;
            this.initFilters();
            this.getOrganizations();
        });
    }

    private getOrganizations(tag = false) {
        this.isLoading = true;
        sessionStorage.setItem('bottin_pagination', JSON.stringify(this.pagination));
        const allFilters = [ ...this.defaultFilters, this.leftSideSelectedFilter, ...this.advancedSelectedFilters ];
        this.bottinService.getBottin(allFilters, this.pagination, this.searchText).subscribe((organizationsData: Array<Organization | Venue>) => {
            this.catalogArray = organizationsData;
            // Transformation de la liste d' Organization en list d' Item affichables dans la liste.
            this.itemList = this.catalogArray.map((catalogItem) => {
                const res: ListItem = catalogItem.toListItem(this.lang);

                if (catalogItem instanceof Organization) {
                    res.itemSubtitle = catalogItem.types
                        .reduce((acc, type) => {
                            return acc + this.translate.instant('ORGANIZATION-TYPE_' + type) + ' - ';
                        }, '')
                        .slice(0, -2);
                    res.itemUrl = `/organization/${catalogItem.id}`;
                } else if (catalogItem instanceof Venue) {
                    res.itemSubtitle = this.translate.instant('SALLE-DE-SPECTACLE');
                    res.itemUrl = `/venue/${catalogItem.organizationId}/${catalogItem.id}`;
                }

                if (this.accountService.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID) {
                    res.itemStatus = null;
                }

                return res;
            });

            this.isLoading = false;
        });

        if (tag) {
            tagBottinSearch(this.bottinSearchData, this.httpClient);
        }
    }

    /**
     * Initialise les filtres de gauche et leurs valeurs possibles
     */
    private initFilters() {
        this.leftSideSelectedFilter = new SelectedFilter();

        // Tous les élements
        const allItemsFilter = new Filter();
        allItemsFilter.filterField = '';
        allItemsFilter.filterValues = [
            {
                filterValueName: this.translate.instant('TOUS'),
                selected: true
            } as FilterValue
        ];
        // Type d'organisation
        const orgaTypeFilter = new Filter();
        orgaTypeFilter.filterField = 'organizationTypeId';
        orgaTypeFilter.filterName = this.translate.instant('ORGANIZATION-TYPES');
        orgaTypeFilter.filterValues = Object.values(OrganizationTypes)
            .filter((val: string | OrganizationTypes) => !isNaN(Number(val)))
            .filter((x: number) => x !== OrganizationTypes.VACANT) // don't use 1st value = VACANT
            .map((val: number) => {
                return {
                    filterValue: val,
                    filterValueName: this.translate.instant('ORGANIZATION-TYPE_' + val)
                } as FilterValue;
            });
        // Salles de spectacle
        const venueFilter = new Filter();
        venueFilter.filterField = 'venuesOnly';
        venueFilter.filterName = this.translate.instant('VENUE-TYPES');
        venueFilter.filterValues = [
            {
                filterValueName: this.translate.instant('SALLE-DE-SPECTACLE'),
                filterValue: VenueType.DEFAULT
            } as FilterValue,
            {
                filterValueName: this.translate.instant('RESIDENCY-SHOW'),
                filterValue: VenueType.RESIDENCY
            } as FilterValue
        ];

        this.leftSideFilters = [ allItemsFilter, orgaTypeFilter, venueFilter ];
        this.addRideauFilter();

        const ssFilters = JSON.parse(sessionStorage.getItem('bottinLeftFilters'));
        if (ssFilters && ssFilters.value) {
            this.leftSideSelectedFilter = ssFilters;
            for (const filter of this.leftSideFilters) {
                if (filter.filterField != ssFilters.field) {
                    for (const filterVal of filter.filterValues) {
                        filterVal.selected = false;
                    }
                } else {
                    for (const filterVal of filter.filterValues) {
                        filterVal.selected = filterVal.filterValue === ssFilters.value;
                    }
                }
            }
        }
    }

    addRideauFilter() {
        // Filtres sur le status pour l'utilisateur RIDEAU
        if (this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID) {
            const statusFilter = new Filter();
            statusFilter.filterField = 'statusId';
            statusFilter.filterName = this.translate.instant('SHOW-STATUS');
            statusFilter.filterValues = Object.keys(OrganisationStatus)
                .filter((val) => isNaN(Number(val)))
                .map((key) => {
                    return {
                        filterValueName: this.translate.instant('STATUS.' + key),
                        filterValue: OrganisationStatus[ key ]
                    } as FilterValue;
                });

            this.leftSideFilters.push(statusFilter);
        }
    }

    private initSort() {
        const sortByName = new Sort(),
            sortByCity = new Sort();
        sortByName.sortName = this.translate.instant('NOM');
        sortByName.sortValue = 'name';
        sortByCity.sortName = this.translate.instant('FORM.VILLE');
        sortByCity.sortValue = 'city';

        this.sorts = [ sortByName, sortByCity ];
    }

    private initPagination() {
        this.pagination = new Pagination(0, 12);
        const session = JSON.parse(sessionStorage.getItem('bottin_pagination'));
        if (session) {
            Object.assign(this.pagination, session);
        }
    }

    onFilterChange(event: SelectedFilter) {
        this.leftSideSelectedFilter = event;
        /* si salles de spectacles, virer les filtres avancés Membres,
       si organisation, virer les filtres davancés capacité.
       si "Tous", virer les 2
    */
        if (event.field === '') {
            this.removeAdvancedFilterMembers();
            this.removeAdvancedFilterCapacity();
            this.removeAdvancedFilterResidency();
        } else if (event.field === 'venuesOnly') {
            // venues only
            if (event.value === VenueType.DEFAULT) {
                this.removeAdvancedFilterResidency();
            } else if (event.value === VenueType.RESIDENCY) {
                // venues with residency
                this.removeAdvancedFilterCapacity();
            }
            this.removeAdvancedFilterMembers();
        } else if (event.field === 'organizationTypeId') {
            this.removeAdvancedFilterCapacity();
            this.removeAdvancedFilterResidency();
        }

        this.getOrganizations(true);
        sessionStorage.setItem('bottinLeftFilters', JSON.stringify(event));
        sessionStorage.setItem('bottinAdvFilters', JSON.stringify(this.advancedSelectedFilters));
    }

    removeAdvancedFilterMembers() {
        this.advancedSelectedFilters = this.advancedSelectedFilters.filter((filter) => !advancedSelectedFilters.members.includes(filter.field));
    }
    removeAdvancedFilterCapacity() {
        this.advancedSelectedFilters = this.advancedSelectedFilters.filter((filter) => !advancedSelectedFilters.defaultVenue.includes(filter.field));
    }
    removeAdvancedFilterResidency() {
        this.advancedSelectedFilters = this.advancedSelectedFilters.filter((filter) => !advancedSelectedFilters.residency.includes(filter.field));
    }

    onAdvancedFilterChange(event: Parameters<BottinAdvancedSearchComponent[ 'filtersChange' ][ 'emit' ]>[ 0 ]) {
        // On remet à la page 1
        this.pagination.offset = 0;
        this.advancedSelectedFilters = event.filters;
        this.bottinSearchData = event.dataLayer;
        this.getOrganizations(true);
    }

    onSearchChange(event: string) {
        this.searchText = event;
        this.bottinSearchData.terme = event;
        this.getOrganizations(true);
    }

    onPageChange(event: Pagination) {
        this.pagination.offset = event.offset;
        this.getOrganizations();
    }
}
