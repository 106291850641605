<app-page-title title="{{ 'CALENDARS' | translate }}">
    <a (click)="collapseCreateSection = true" class="btn btn-primary btn--larger">
        <i nz-icon nzType="plus" nzTheme="outline" style="padding-right: 4px" aria-hidden="true"></i>
        {{ 'CALENDRIER-CREER' | translate }}
    </a>
</app-page-title>

<div class="container">
    <div class="row justify-content-center">
        <div class="col col-sm-8 col-lg-6 mb-5">
            <div *ngIf="isReady" class="calendarList">
                <ng-container *ngIf="collapseCreateSection">
                    <div id="calendar-new" class="calendarList__item--edit mb-5">
                        <!-- TITLE -->
                        <div class="title">
                            <label for="calId" class="repertory-filter__label mb-2 d-block">{{ 'TITRE' | translate }} *</label>
                            <input nz-input placeholder="Titre du calendrier" [(ngModel)]="newCalTitleInput" />
                        </div>

                        <!-- CLOSE -->
                        <div class="actions d-flex justify-content-end u-gap(xs)">
                            <button nz-button nzType="secondary" (click)="collapseCreateSection = !collapseCreateSection">
                                {{ 'ANNULER' | translate }}
                            </button>

                            <button type="button" nz-button nzType="primary" [disabled]="newCalTitleInput == ''" (click)="createCalendar()">{{ 'ENREGISTER' | translate }}</button>
                        </div>
                    </div>
                </ng-container>

                <div class="calendarList" *ngFor="let calendar of myCalendars; let index = index">
                    <!-- ITEM ROW -->
                    <div class="calendarList__item">
                        <div class="calendarList__item--row">
                            <div class="calendarList__item__info">
                                <p class="calendarList__item__info-title">
                                    {{ calendar.getTranslatedProperty(currentLang, 'title') }}
                                </p>
                                <p class="calendarList__item__info-subtitle">
                                    {{ calendar.countPrograms == 1 ? calendar.countPrograms + ' date' : calendar.countPrograms + ' dates' }}
                                </p>
                            </div>
                            <div *ngIf="!calendar.isMain" class="calendarList__item__actions">
                                <button type="button" style="margin-right: 10px" class="btn-square" (click)="editCalendar(index)">
                                    <i class="icon-pencil" aria-hidden="true"></i>
                                </button>
                                <button type="button" style="margin-right: 10px" class="btn-square" (click)="deleteCalendar(calendar.id)">
                                    <i class="icon-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- EDIT ITEM SECTION -->
                    <ng-container *ngIf="calendar.isEditing">
                        <div id="calendar-{{ calendar.id }}" class="calendarList__item--edit my-3">
                            <!-- TITLE -->
                            <div class="title">
                                <label for="calId" class="repertory-filter__label mb-2 d-block">{{ 'TITRE' | translate }} *</label>
                                <input nz-input placeholder="Titre du calendrier" [(ngModel)]="calendar['title']" />
                            </div>

                            <!-- CLOSE -->
                            <div class="actions d-flex justify-content-end">
                                <button type="button" style="margin-right: 10px" class="btn-rect" (click)="calendar.isEditing = false">{{ 'ANNULER' | translate }}</button>

                                <button type="button" style="margin-right: 10px" class="btn btn--primary" (click)="updateCalendar(calendar)">{{ 'ENREGISTER' | translate }}</button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
