import { FormBuilder, FormGroup } from '@angular/forms';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { Show } from '../../show/model/show.model';
import { TourEquipage } from '../enums/tour-equipage.enum';
import { Tour } from './tour.model';
import { ICreatedResponse } from '@app/shared/model/response.model';

export interface TourShowInterface {
    organizationId?: number;
    organizationHeadId?: number;
    showId?: number;
    ratings?: any;
    tourId?: number;
    hasNoQCArtists?: number;
    hasEmergingArtists?: number;
    equipage?: Array<number>;
    extraRequirements?: string;
    personsOnStage?: number;
    singleRooms?: number;
    doubleRooms?: number;
    showsFee?: number;
    showsFeeDiscount1?: number;
    showsFeeDiscount2?: number;
    showsFeeDiscount3?: number;
    showsFeeDiscount4?: number;
    shows3Fee?: number;
    shows4To6Fee?: number;
    shows7To9Fee?: number;
    shows10PlusFee?: number;
    revenuePercentage?: number;
    broadcastExpenses?: number;
    isHelpNeeded?: number;
    isAvailableAllTime?: number;
    dateFromBlock1?: Date;
    dateToBlock1?: Date;
    dateFromBlock2?: Date;
    dateToBlock2?: Date;
    mediaPassword?: string;
    mediaUrl?: string;
}

export class TourShow extends Translatable implements TourShowInterface {
    id?: number;
    ratings?: any;
    organizationId: number;
    organizationHeadId: number;
    showId: number;
    tourId: number;
    hasNoQCArtists?: number;
    hasEmergingArtists?: number;
    equipage?: number[];
    extraRequirements?: string;
    personsOnStage?: number;
    singleRooms?: number;
    doubleRooms?: number;
    showsFee?: number;
    showsFeeDiscount1?: number;
    showsFeeDiscount2?: number;
    showsFeeDiscount3?: number;
    showsFeeDiscount4?: number;
    shows3Fee?: number;
    shows4To6Fee?: number;
    shows7To9Fee?: number;
    shows10PlusFee?: number;
    revenuePercentage?: number;
    broadcastExpenses?: number;
    isHelpNeeded?: number;
    isAvailableAllTime?: number;
    dateFromBlock1?: Date;
    dateToBlock1?: Date;
    dateFromBlock2?: Date;
    dateToBlock2?: Date;
    show?: Show;
    statusId?: number;
    programCount?: number;
    createdAt: Date;
    mediaPassword?: string;
    mediaUrl?: string;

    private saisonReguliereForm: FormGroup;

    constructor(datas: Record<string, any>) {
        super();
        if (datas !== {}) {
            this.id = datas['id'];
            this.ratings = datas['ratings'];
            this.organizationId = datas['organizationId'];
            this.organizationHeadId = datas['organizationHeadId'];
            this.showId = datas['showId'];
            this.tourId = datas['tourId'];
            this.hasNoQCArtists = datas['hasNoQCArtists'];
            this.hasEmergingArtists = datas['hasEmergingArtists'];
            this.personsOnStage = datas['personsOnStage'];
            this.extraRequirements = datas['extraRequirements'];
            this.mediaPassword = datas['mediaPassword'];
            this.mediaUrl = datas['mediaUrl'];
            this.singleRooms = datas['singleRooms'];
            this.doubleRooms = datas['doubleRooms'];
            this.showsFee = datas['showsFee'];
            this.showsFeeDiscount1 = datas['showsFeeDiscount1'];
            this.showsFeeDiscount2 = datas['showsFeeDiscount2'];
            this.showsFeeDiscount3 = datas['showsFeeDiscount3'];
            this.showsFeeDiscount4 = datas['showsFeeDiscount4'];
            this.shows3Fee = datas['shows3Fee'];
            this.shows4To6Fee = datas['shows4To6Fee'];
            this.shows7To9Fee = datas['shows7To9Fee'];
            this.shows10PlusFee = datas['shows10PlusFee'];
            this.revenuePercentage = datas['revenuePercentage'];
            this.broadcastExpenses = datas['broadcastExpenses'];
            this.isHelpNeeded = datas['isHelpNeeded'];
            this.isAvailableAllTime = datas['isAvailableAllTime'];
            this.dateFromBlock1 = datas['dateFromBlock1'];
            this.dateToBlock1 = datas['dateToBlock1'];
            this.dateFromBlock2 = datas['dateFromBlock2'];
            this.dateToBlock2 = datas['dateToBlock2'];
            this.equipage = datas['equipage'] ? datas['equipage'] : [];
            this.show = datas['show'] ? new Show(datas['show']) : null;
            this.statusId = datas['statusId'] ? datas['statusId'] : 2;
            this.programCount = datas['programCount'];
            this.createdAt = datas['createdAt'] ? datas['createdAt'] : null;
        }
    }

    getSaisonReguliereFormGroup(fb: FormBuilder): FormGroup {
        if (!this.saisonReguliereForm) {
            this.saisonReguliereForm = fb.group({
                showId: [this.showId],
                hasNoQCArtists: [!!this.hasNoQCArtists],
                hasEmergingArtists: [!!this.hasEmergingArtists],
                extraRequirements: [this.extraRequirements],
                singleRooms: [this.singleRooms],
                doubleRooms: [this.doubleRooms],
                isAvailableAllTime: [!!this.isAvailableAllTime],

                toursRoseqDateRangeBloc1: [[this.dateFromBlock1, this.dateToBlock1]],
                toursRoseqDateRangeBloc2: [[this.dateFromBlock2, this.dateToBlock2]],

                dateFromBlock1: [this.dateFromBlock1],
                dateToBlock1: [this.dateToBlock1],
                dateFromBlock2: [this.dateFromBlock2],
                dateToBlock2: [this.dateToBlock2],
                directeur: [this.equipage.indexOf(TourEquipage.DIRECTEUR_TOURNEE) > -1],
                sonorisateur: [this.equipage.indexOf(TourEquipage.SONORISATEUR_ARTISTE) > -1],
                eclaragist: [this.equipage.indexOf(TourEquipage.ECLAIRAGISTE_ARTISTE) > -1],
                equipmentSonore: [this.equipage.indexOf(TourEquipage.EQUIPEMENT_SONORE) > -1],
                equipmentEclarage: [this.equipage.indexOf(TourEquipage.EQUIPEMENT_ECLAIRAGE) > -1],
                premierePartie: [this.equipage.indexOf(TourEquipage.PREMIERE_PARTIE) > -1]
            });
        }
        return this.saisonReguliereForm;
    }

    toJSON(): unknown {
        return {
            ...this,
            saisonReguliereForm: null
        };
    }

    isDateIsAvailableForTour(date: Date, tour: Tour) {
        if (this.isAvailableAllTime) {
            return this.dateIsInBetween(date, tour.startDate, tour.endDate);
        } else if (this.tourShowDateBlockAreDefined()) {
            return (
                this.dateIsInBetween(date, tour.startDate, tour.endDate) &&
                (this.dateIsInBetween(date, this.dateFromBlock1, this.dateToBlock1) || this.dateIsInBetween(date, this.dateFromBlock2, this.dateToBlock2))
            );
        }
        return false;
    }

    isFirstDateAvailable(date: Date) {
        return date.getTime() === new Date(this.dateFromBlock1).getTime();
    }

    private dateIsInBetween(date: Date, startingDate: Date, endingDate: Date): boolean {
        return this.getDate(date) >= this.getDate(startingDate) && this.getDate(date) <= this.getDate(endingDate);
    }

    private getDate = function (date: any): Date {
        const _date = new Date(date);
        return new Date(Date.UTC(_date.getFullYear(), _date.getMonth(), _date.getDate()));
    };

    private tourShowDateBlockAreDefined(): boolean {
        return (this.dateFromBlock1 != undefined && this.dateToBlock1 != undefined) || (this.dateFromBlock2 != undefined && this.dateToBlock2 != undefined);
    }
}

export interface ITourShowResponse {
    id?: number;
    organizationId?: number;
    organizationHeadId?: number;
    showId?: number;
    tourId?: number;
    hasNoQCArtists?: number;
    hasEmergingArtists?: number;
    extraRequirements?: string;
    singleRooms?: number;
    doubleRooms?: number;
    showsFee?: number;
    shows3Fee?: number;
    shows4To6Fee?: number;
    shows7To9Fee?: number;
    shows10PlusFee?: number;
    showsFeeDiscount1?: number;
    showsFeeDiscount2?: number;
    showsFeeDiscount3?: number;
    showsFeeDiscount4?: number;
    revenuePercentage?: number;
    broadcastExpenses?: number;
    isHelpNeeded?: number;
    isAvailableAllTime?: number;
    dateFromBlock1?: Date;
    dateToBlock1?: Date;
    dateFromBlock2?: Date;
    dateToBlock2?: Date;
    isActive?: number;
    equipage?: number[];
    statusId?: number;
    personsOnStage?: number;
}

export interface ITourShowCreatedResponse extends ICreatedResponse {
    tourShow: ITourShowResponse;
    isEquipageCreated: boolean;
}
