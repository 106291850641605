<div class="container">
  <div nz-row nzGutter="16">

       <app-edit-tour-nav></app-edit-tour-nav>


    <div nz-col nzMd="12">
      <h2>{{ 'ADMINISTRATEURS' | translate }}</h2>
      <div class="AdminTour_searchBar">
        <app-dynamic-search-bar (valueChanged)='onSearch($event)' [timeToEmit]='100' #dynamicBar >
          <div result *ngIf="!loading; else loadingTemplate" >
            <div class="AdminTour_searchedUser" *ngIf="searchedUser.length > 0; else empty" >
              <app-row-item-action *ngFor="let user of searchedUser" [imageUrl]='user.avatar' [title]='user.firstName + " " + user.lastName' [subtitle]='userCardSubtitle' >
                <div action class="action add" >
                  <button type="button"
                  class="btn-square add"
                  [disabled]="loading"
                  (click)="addAdmin(user.id, dynamicBar)">
                  <i class="icon-plus" aria-hidden="true"></i>
                </button>
                </div>
              </app-row-item-action>
            </div>
            <ng-template #empty>
              <nz-empty class="AdminTour_searchedUser" ></nz-empty>
            </ng-template>
          </div>


          <ng-template #loadingTemplate>
            <p> loading.... </p>
          </ng-template>




        </app-dynamic-search-bar>
      </div>



      <div class="AdminTour_currentAdmin" >
        <div class="AdminTour_currentAdmin_singleAdmin" *ngFor="let user of adminUser; let first = first; let last = last">
          <app-row-item-action   [curveTop]='first' [curveBottom]='last' [imageUrl]='user.avatar' [title]='user.firstName + " " + user.lastName' [subtitle]='userCardSubtitle' >
            <div action *ngIf="currentUser.id !== user.id" class="action remove" >
              <button type="button"
                  class="btn-square trash"
                  [disabled]="loading"
                  (click)="removeAdmin(user.id)">
                  <i class="icon-trash" aria-hidden="true"></i>
                </button>
            </div>
          </app-row-item-action>
        </div>

      </div>

    </div>
  </div>
</div>
