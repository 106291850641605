import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickOutsideElement]'
})
export class ClickOutsideElementDirective {

  @Output() onClickOutside: EventEmitter<any> = new EventEmitter();

  constructor(private hostElement: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onCLick(event){

    if(!this.hostElement.nativeElement.contains(event.target)){
      this.onClickOutside.emit(event);
    }
  }
}
