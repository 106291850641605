
<div class="ShowAccordionBlock d-block" (click)='navigate()'>

  <div class="ShowAccordionBlock__image d-inline-block" >
    <img height="72" width="72" [src]='tourShow.show.image' alt="">
  </div>

  <div class="ShowAccordionBlock__textBlock d-inline-block ">
    <div class="ShowAccordionBlock_textBlock-container d-flex justify-content-between">

        <div class="d-flex flex-column">
          <p class="ShowAccordionBlock__textBlock-title-main"> {{ showTitle  }} </p>
          <p class="ShowAccordionBlock__textBlock-title-artist" > {{ tourShow.show | extractArtistListPipeable }} </p>
        </div>

        <p class="ShowAccordionBlock__textBlock-date d-inline-block"> {{ "DEPOSE-LE" | translate }}  {{ tourShow.createdAt | date }} </p>

    </div>
  </div>

</div>
