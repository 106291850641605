import { Component, Input, OnInit } from '@angular/core';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';
import { Tour } from 'src/app/concepts/tours/model/tour.model';

@Component({
  selector: 'app-organization-banner',
  templateUrl: './organization-banner.component.html',
  styleUrls: ['./organization-banner.component.scss']
})
export class OrganizationBannerComponent implements OnInit {

  @Input() tour: Tour;
  @Input() tourShow: TourShow;

  constructor() { }

  ngOnInit() {
  }

}
