<div nz-row>
    <div nz-col
         nzMd="16">
      <h2>{{ 'SHOW-STEP.CONTACTS' | translate}}</h2>
    </div>
    <div class="text-md-right"
         nz-col
         nzMd="8"
         *ngIf="!formGroup.disabled">
      <button type="button"
              *appPermission="1;objectOrganizationId :show.organizationId"
              class="btn"
              [disabled]="collapseNewContactForm"
              (click)="showNewContactForm()"
              style="padding: 2px 21px 1px 13px">
        <i class="icon-plus icon-for-btn"></i>
        {{'AJOUTER-CONTACT' | translate}}
      </button>
    </div>
  </div>
  <!-- CREATE MEMBER FORM -->
  <div class="form-filter"
       *ngIf="collapseNewContactForm">
    <app-contact-form [contactForm]="showContactForm"
                      (addContact)="addNewContact()"
                      (cancel)="collapseNewContactForm = !collapseNewContactForm"></app-contact-form>
  </div>

  <nz-divider></nz-divider>

  <form id="organisation-step-4"
        class="mb-4">
    <ng-container *ngFor="let showContact of showContacts">
      <app-item-row-contact [contact]="showContact"
                            [showForm]="showHideModifyForm(showContact.id)"
                            [isDisabled]="formGroup.disabled"
                            (hideOtherModifyForms)="onHideOtherModifyForms($event)"
                            (modifyContact)="onModifyShowContact($event)"
                            (removeContact)="onRemoveShowContact($event)"></app-item-row-contact>
    </ng-container>

  </form>
