import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Translatable, Translation } from 'src/app/shared/model/translatable.model';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-blank.validator';
import { ListItem } from '../../../shared/model/list-item.model';
import { IAddress } from '../../location/models/location.model';
import { Organization } from '../../organization/model/organization.model';
import { VenueConfigurationType } from '../enums/venue-configuration-type.enum';
import { RegexpValidator } from 'src/app/shared/validators/regexp.validator';
import { WikiArtsDataValidation } from '../../../shared/services/wikiArtsData.validation';
import { BooleanUtils } from '@app/shared/utils/boolean-utils';
import { OneOrManyValidator } from '@app/shared/validators/oneOrMany.validator';
import { FloatNumberPipe } from '@app/shared/pipes/float-number.pipe';
import { VenueStatus } from '../enums/venue-status.enum';
import { Globals } from '@app/_configs/globals';

export enum ResidencyType {
    ALL = 0,
    CREATION = 1,
    TECHNICAL = 2,
    DRY = 3
}
export enum ResidencyAvailability {
    FALL = 1,
    SUMMER = 2,
    SPRING = 3,
    WINTER = 4
}

export interface IVenue {
    photo: string;
    id: number;
    addressId: number;
    phone: string;
    phonePostNumber: string;
    email: string;
    website: string;
    urlTechSpecification: string;
    statusId: number;
    organizationId: number;
    artsdataId?: string;
    wikidataId?: string;
}

export class Venue extends Translatable implements IVenue {
    photo: string;
    id: number;
    addressId: number;
    phone: string;
    phonePostNumber: string;
    email: string;
    website: string;
    urlTechSpecification: string;
    statusId: VenueStatus;
    organizationId: number;
    // proprieté récuperées séparément
    organization?: Organization;
    trans: Translation[];
    address?: IAddress;
    configurations?: VenueConfiguration[];
    //  residency data
    isResidency?: number = 0;
    isTypeCreation?: number;
    isTypeTechnical?: number;
    isTypeDried?: number;
    isDisponibilityFall?: number;
    isDisponibilitySummer?: number;
    isDisponibilitySpring?: number;
    isDisponibilityWinter?: number;
    isDurationDay?: number;
    isDurationWeek?: number;
    isDurationMonth?: number;
    hasReceptionStaff?: number;
    hasAssemblyStaff?: number;
    hasTechnicalDirector?: number;
    hasSoundEngineer?: number;
    hasLightingDesigner?: number;
    hasStageTechnician?: number;
    hasRigger?: number;
    totalStageWidth?: number;
    totalSceneDepth?: number;
    clearHeightLamps?: number;
    widthPlayground?: number;
    playgroundDepth?: number;
    hasAttachmentPoints?: number;
    hasDanceFloor?: number;
    hasVideoProjector?: number;
    hasSoundEquipment?: number;
    hasLightEquipment?: number;
    hasInternet?: number;
    hasTelepresenceEquipment?: number;
    hasLodge?: number;
    hasStorageSpace?: number;
    hasKitchen?: number;
    hasLaundryRoom?: number;
    hasHostingPartnership?: number;
    hasLandingStage?: number;
    // internal properties
    artsdataId?: string;
    wikidataId?: string;
    private coordForm: FormGroup;
    private technicalInfoForm: FormGroup;
    private residencyForm: FormGroup;
    private profileForm: FormGroup;
    private floatNumberPipe: FloatNumberPipe = new FloatNumberPipe();
    constructor(datas: Record<string, any>) {
        super();
        this.photo = datas[ 'photo' ];
        this.id = datas[ 'id' ];
        this.addressId = datas[ 'addressId' ];
        this.phone = datas[ 'phone' ];
        this.phonePostNumber = datas[ 'phonePostNumber' ];
        this.email = datas[ 'email' ];
        this.website = datas[ 'website' ];
        this.urlTechSpecification = datas[ 'urlTechSpecification' ];
        this.statusId = datas[ 'statusId' ];
        this.organizationId = datas[ 'organizationId' ];
        this.artsdataId = datas[ 'artsdataId' ];
        this.wikidataId = datas[ 'wikidataId' ];
        this.trans = datas[ 'trans' ];
        this.organization = datas[ 'organization' ] ? new Organization(datas[ 'organization' ]) : undefined;
        this.address = datas[ 'address' ];
        this.configurations = datas[ 'configurations' ] || [];
        this.isResidency = datas[ 'isResidency' ];
        this.isTypeCreation = datas[ 'isTypeCreation' ];
        this.isTypeTechnical = datas[ 'isTypeTechnical' ];
        this.isTypeDried = datas[ 'isTypeDried' ];
        this.isDisponibilityFall = datas[ 'isDisponibilityFall' ];
        this.isDisponibilitySummer = datas[ 'isDisponibilitySummer' ];
        this.isDisponibilitySpring = datas[ 'isDisponibilitySpring' ];
        this.isDisponibilityWinter = datas[ 'isDisponibilityWinter' ];
        this.isDurationDay = datas[ 'isDurationDay' ];
        this.isDurationWeek = datas[ 'isDurationWeek' ];
        this.isDurationMonth = datas[ 'isDurationMonth' ];
        this.hasReceptionStaff = datas[ 'hasReceptionStaff' ];
        this.hasAssemblyStaff = datas[ 'hasAssemblyStaff' ];
        this.hasTechnicalDirector = datas[ 'hasTechnicalDirector' ];
        this.hasSoundEngineer = datas[ 'hasSoundEngineer' ];
        this.hasLightingDesigner = datas[ 'hasLightingDesigner' ];
        this.hasStageTechnician = datas[ 'hasStageTechnician' ];
        this.hasRigger = datas[ 'hasRigger' ];
        this.totalStageWidth = datas[ 'totalStageWidth' ];
        this.totalSceneDepth = datas[ 'totalSceneDepth' ];
        this.clearHeightLamps = datas[ 'clearHeightLamps' ];
        this.widthPlayground = datas[ 'widthPlayground' ];
        this.playgroundDepth = datas[ 'playgroundDepth' ];
        this.hasAttachmentPoints = datas[ 'hasAttachmentPoints' ];
        this.hasDanceFloor = datas[ 'hasDanceFloor' ];
        this.hasVideoProjector = datas[ 'hasVideoProjector' ];
        this.hasSoundEquipment = datas[ 'hasSoundEquipment' ];
        this.hasLightEquipment = datas[ 'hasLightEquipment' ];
        this.hasInternet = datas[ 'hasInternet' ];
        this.hasTelepresenceEquipment = datas[ 'hasTelepresenceEquipment' ];
        this.hasLodge = datas[ 'hasLodge' ];
        this.hasStorageSpace = datas[ 'hasStorageSpace' ];
        this.hasKitchen = datas[ 'hasKitchen' ];
        this.hasLaundryRoom = datas[ 'hasLaundryRoom' ];
        this.hasHostingPartnership = datas[ 'hasHostingPartnership' ];
        this.hasLandingStage = datas[ 'hasLandingStage' ];
    }

    public get isNotSubmitted(): boolean {
        return this.statusId === VenueStatus.NON_SOUMIS;
    }
    public get isSubmitted(): boolean {
        return this.statusId === VenueStatus.SOUMIS;
    }
    public get isRejected(): boolean {
        return this.statusId === VenueStatus.REJETE;
    }
    public get isApproved(): boolean {
        return this.statusId === VenueStatus.APPROUVE;
    }

    public toListItem(lang: string): ListItem {
        return {
            itemTitle: this.getTranslatedProperty(lang, 'name'),
            itemSubtitle: this.organization ? this.organization.getTranslatedProperty(lang, 'name') : '',
            itemImgSrc: this.photo,
            itemUrl: '',
            // itemInfo1: this.email,
            itemInfo2: this.phone,
            itemInfo3: this.address ? this.address.city : '',
            itemInfo4: this.address ? this.address.otherState : '',
            itemStatus: this.statusId
        } as ListItem;
    }

    getCoordFormGroup(fb: FormBuilder, lang: string, shouldValidate?: boolean, globals?: Globals): FormGroup {
        /**
         * Returns an array of ValidatorFns based on a provided value
         * and a flag to determine if validation should occur.
         * @param {string} value - The string value used to create a regular expression.
         * @param {boolean} shouldValidate - Flag to determine if validation should occur.
         * @returns {ValidatorFn[]} - An array of ValidatorFns
         */
        function getRegExpValidator(value: string, shouldValidate: boolean): ValidatorFn[] {
            const regExp = WikiArtsDataValidation.getRegExp(value);
            if (!regExp || !shouldValidate) {
                return [];
            }
            return [ RegexpValidator.validate(regExp, 'gm') ];
        }
        if (!this.coordForm) {
            this.coordForm = fb.group({
                name: new FormControl(this.getTranslatedProperty(lang, 'name'), [ Validators.required, noWhitespaceValidator ]),
                address1: new FormControl(this.address.address1, [ Validators.required, noWhitespaceValidator ]),
                city: new FormControl(this.address.city, [ Validators.required, noWhitespaceValidator ]),
                zipcode: new FormControl(this.address.zipCode, [ Validators.required, noWhitespaceValidator ]),
                province: new FormControl(this.address.otherState, [ Validators.required, noWhitespaceValidator ]),
                country: new FormControl(this.address.countryId, [ Validators.required ]),
                phone: new FormControl(this.phone, [ Validators.required, noWhitespaceValidator ]),
                phonePostNumber: new FormControl(this.phonePostNumber),
                email: new FormControl(this.email, [ globals ? Validators.pattern(globals.emailRegex) : Validators.email, Validators.required ]),
                website: new FormControl(this.website),
                artsdataId: new FormControl(this.artsdataId, getRegExpValidator(WikiArtsDataValidation.artsdata, shouldValidate)),
                wikidataId: new FormControl(this.wikidataId, getRegExpValidator(WikiArtsDataValidation.wikidata, shouldValidate))
            });
        }
        this.coordForm.valueChanges.subscribe((values) => {
            const someAreRequired = Object.keys(this.coordForm.controls).some((controlKey) => {
                const control = this.coordForm.get(controlKey);
                return this.hasRequiredField(control) && !control.value;
            });
            this.coordForm.setErrors(someAreRequired ? { required: true } : null);
        });
        return this.coordForm;
    }

    private hasRequiredField = (abstractControl: AbstractControl): boolean => {
        if (!abstractControl.validator) {
            return false;
        }

        const validator = abstractControl.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
    };

    getTechnicalInfoForm(fb: FormBuilder, lang: string): FormGroup {
        if (!this.technicalInfoForm) {
            this.technicalInfoForm = fb.group({
                configurations: fb.array([]),
                urlTechSpecification: String
            });
        }
        return this.technicalInfoForm;
    }

    getProfileFormGroup(fb: FormBuilder, lang: string): FormGroup {
        if (!this.profileForm) {
            this.profileForm = fb.group({
                description: [ this.getTranslatedProperty(lang, 'description'), [ Validators.required, noWhitespaceValidator ] ],
                photo: [ this.photo, [ Validators.required ] ]
            });
        }

        return this.profileForm;
    }

    getResidencyFormGroup(): FormGroup {
        const regExp = new RegExp(/\./, 's');
        if (!this.residencyForm) {
            this.residencyForm = new FormGroup({
                isResidency: new FormControl(BooleanUtils.convertFromNumber(this.isResidency)),
                type: new FormGroup(
                    {
                        isTypeCreation: new FormControl(BooleanUtils.convertFromNumber(this.isTypeCreation)),
                        isTypeTechnical: new FormControl(BooleanUtils.convertFromNumber(this.isTypeTechnical)),
                        isTypeDried: new FormControl(BooleanUtils.convertFromNumber(this.isTypeDried))
                    },
                    {
                        validators: [ OneOrManyValidator.validate([ 'isTypeCreation', 'isTypeTechnical', 'isTypeDried' ]) ]
                    }
                ),
                availability: new FormGroup(
                    {
                        isDisponibilityFall: new FormControl(BooleanUtils.convertFromNumber(this.isDisponibilityFall)),
                        isDisponibilitySummer: new FormControl(BooleanUtils.convertFromNumber(this.isDisponibilitySummer)),
                        isDisponibilitySpring: new FormControl(BooleanUtils.convertFromNumber(this.isDisponibilitySpring)),
                        isDisponibilityWinter: new FormControl(BooleanUtils.convertFromNumber(this.isDisponibilityWinter))
                    },
                    {
                        validators: [ OneOrManyValidator.validate([ 'isDisponibilityFall', 'isDisponibilitySummer', 'isDisponibilitySpring', 'isDisponibilityWinter' ]) ]
                    }
                ),
                duration: new FormGroup(
                    {
                        isDurationDay: new FormControl(BooleanUtils.convertFromNumber(this.isDurationDay)),
                        isDurationWeek: new FormControl(BooleanUtils.convertFromNumber(this.isDurationWeek)),
                        isDurationMonth: new FormControl(BooleanUtils.convertFromNumber(this.isDurationMonth))
                    },
                    {
                        validators: [ OneOrManyValidator.validate([ 'isDurationDay', 'isDurationWeek', 'isDurationMonth' ]) ]
                    }
                ),
                hasReceptionStaff: new FormControl(BooleanUtils.convertFromNumber(this.hasReceptionStaff)),
                hasAssemblyStaff: new FormControl(BooleanUtils.convertFromNumber(this.hasAssemblyStaff)),
                hasTechnicalDirector: new FormControl(BooleanUtils.convertFromNumber(this.hasTechnicalDirector)),
                hasSoundEngineer: new FormControl(BooleanUtils.convertFromNumber(this.hasSoundEngineer)),
                hasLightingDesigner: new FormControl(BooleanUtils.convertFromNumber(this.hasLightingDesigner)),
                hasStageTechnician: new FormControl(BooleanUtils.convertFromNumber(this.hasStageTechnician)),
                hasRigger: new FormControl(BooleanUtils.convertFromNumber(this.hasRigger)),
                // stage dimensions
                totalStageWidth: new FormControl(
                    this.floatNumberPipe.transform<string>(this.totalStageWidth, [ 'comma', regExp ]),
                    [ Validators.required ]
                ),
                totalSceneDepth: new FormControl(
                    this.floatNumberPipe.transform<string>(this.totalSceneDepth, [ 'comma', regExp ]),
                    [ Validators.required ]
                ),
                clearHeightLamps: new FormControl(
                    this.floatNumberPipe.transform<string>(this.clearHeightLamps, [ 'comma', regExp ])
                ),
                widthPlayground: new FormControl(
                    this.floatNumberPipe.transform<string>(this.widthPlayground, [ 'comma', regExp ])
                ),
                playgroundDepth: new FormControl(
                    this.floatNumberPipe.transform<string>(this.playgroundDepth, [ 'comma', regExp ])
                ),
                // technical equipment
                hasAttachmentPoints: new FormControl(BooleanUtils.convertFromNumber(this.hasAttachmentPoints)),
                hasDanceFloor: new FormControl(BooleanUtils.convertFromNumber(this.hasDanceFloor)),
                hasVideoProjector: new FormControl(BooleanUtils.convertFromNumber(this.hasVideoProjector)),
                hasSoundEquipment: new FormControl(BooleanUtils.convertFromNumber(this.hasSoundEquipment)),
                hasLightEquipment: new FormControl(BooleanUtils.convertFromNumber(this.hasLightEquipment)),
                hasInternet: new FormControl(BooleanUtils.convertFromNumber(this.hasInternet)),
                hasTelepresenceEquipment: new FormControl(BooleanUtils.convertFromNumber(this.hasTelepresenceEquipment)),
                hasLodge: new FormControl(BooleanUtils.convertFromNumber(this.hasLodge)),
                hasStorageSpace: new FormControl(BooleanUtils.convertFromNumber(this.hasStorageSpace)),
                hasKitchen: new FormControl(BooleanUtils.convertFromNumber(this.hasKitchen)),
                hasLaundryRoom: new FormControl(BooleanUtils.convertFromNumber(this.hasLaundryRoom)),
                hasHostingPartnership: new FormControl(BooleanUtils.convertFromNumber(this.hasHostingPartnership)),
                hasLandingStage: new FormControl(BooleanUtils.convertFromNumber(this.hasLandingStage))
            });
        }
        return this.residencyForm;
    }
}
export class VenueTrans {
    langId: string;
    name: string;
    description: string;
}

export interface ICreatedVenue {
    isCreated: boolean;
    venue: IVenue;
}

export class VenueConfiguration {
    constructor(public name: string, public type: VenueConfigurationType, public capacity: number) { }
}
