import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordInputComponent),
            multi: true
        }
    ]
})
export class PasswordInputComponent implements ControlValueAccessor {
    @Input() label = 'MOT-DE-PASSE';
    @Input() labelColor = '#FFF';
    @Input() showForgotPassword = false;
    isPasswordVisible = false;
    value = '';
    get labelStyle() {
        return this.labelColor === '#FFF' ? 'white' : 'currentColor';
    }
    onChange: (event: any) => void;
    onTouched: (event: any) => void;

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }
}
