import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { Observable, of } from 'rxjs';
import { switchMap, flatMap } from 'rxjs/operators';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';
import { ProductTypes } from 'src/app/concepts/product/enums/product-types-enum';
import { Product } from 'src/app/concepts/product/model/product.model';
import { ProductService } from 'src/app/concepts/product/services/product.service';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';
import { BreadcrumbItem } from 'src/app/shared/model/breadcrumb.model';
import { ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.services';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Globals } from 'src/app/_configs/globals';
import { Promotion } from '../../model/promotion.model';
import { PromotionService } from '../../services/promotion.service';
import { ShowService } from '../../../show/services/show.service';
import { Show } from '../../../show/model/show.model';
import { ShowStatus } from '../../../show/enums/show-status.enum';
import { RowTypes } from '../../../../shared/enums/row-types.enum';

@Component({
    selector: 'app-promotion-form',
    templateUrl: './promotion-form.component.html',
    styleUrls: [ './promotion-form.component.scss' ],
})
export class PromotionFormComponent implements OnInit {
    private promotionId: number;
    promotion: Promotion;

    private promotionProduct: Product;

    public formGroup: FormGroup;
    public lang = this.translate.currentLang;
    public isCreation = false;
    public isLoading = false;
    public organizationId: number;
    public showId: number;
    public isReady = false;
    public isScenePro = false;
    shows: Show[];
    selectedShow: Show;
    public itemList: ListItem[] = [];
    itemRow: RowTypes = RowTypes.SHOW;
    loading = false;
    price: string;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private global: Globals,
        private route: ActivatedRoute,
        private authService: AuthService,
        private translate: TranslateService,
        private productService: ProductService,
        private accountService: AccountService,
        private breadcrumbService: BreadcrumbService,
        private localizeRouter: LocalizeRouterService,
        private promotionService: PromotionService,
        private notification: RideauNotificationService,
        private shoppingCartService: ShoppingCartService,
        private showService: ShowService
    ) {
        this.formGroup = new FormGroup({});
    }

    ngOnInit(): void {
        this.promotion = new Promotion({});
        this.isScenePro =
            this.accountService.getCurrentCtxOrganizationId() ===
            this.global.SCENE_PRO_ORGID;
        this.isLoading = false;
        this.initPromotion();
        this.getPromotionProduct();
    }

    initPromotion() {
        const routeToNew = this.translate.instant('ROUTES.new-promotion');
        //Si on est en creation, on crée une promotion vide
        if (this.route.snapshot.routeConfig.path.includes(routeToNew)) {
            this.isCreation = true;
            this.organizationId = this.route.snapshot.params[ 'organizationId' ];
            this.getShows().subscribe((res) => {
                this.isReady = true;
                this.showId = this.route.snapshot.params[ 'showId' ];
                this.setSelectedShow();
                this.promotion = new Promotion({
                    statusId: ObjectStatus.NON_SOUMIS,
                    showId: this.selectedShow ? this.selectedShow.id : '',
                });
                this.promotion.show = this.selectedShow;
                const breadcrumbItems: BreadcrumbItem[] = [];
                const promoName = this.translate.instant('NOUVELLE-PROMOTION');
                breadcrumbItems.push(
                    new BreadcrumbItem({ title: promoName, url: null, isEditing: true })
                );
                this.initForm();
                this.updateApercu();
                this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
            });
        } else {
            //Si on est en modifiaction, on va chercher la promotion
            this.promotionId = this.route.snapshot.params[ 'promotionId' ];
            this.getPromotion();
        }
    }

    initForm() {
        this.formGroup = this.promotion.getPromotionFormGroup(this.fb, this.lang);
        this.formGroup.get('selectedShow').valueChanges.subscribe((val) => {
            if (val != this.selectedShow) {
                this.selectedShow = val;
                this.updateApercu();
            }
        });
    }
    setSelectedShow() {
        this.selectedShow = this.shows.find((show) => show.id == this.showId);
    }
    getPromotion() {
        this.promotionService
            .getPromotionById(this.promotionId)
            .subscribe((res) => {
                this.promotion = res;
                this.organizationId = this.promotion.organizationId;
                this.showId = this.promotion.showId;
                this.getShows().subscribe(() => {
                    this.setSelectedShow();
                    this.promotion.show = this.selectedShow;
                    this.initForm();
                    this.updateApercu();
                    this.isReady = true;
                    const breadcrumbItems: BreadcrumbItem[] = [];
                    const promoName = this.promotion.getTitle(this.lang);
                    breadcrumbItems.push(
                        new BreadcrumbItem({ title: promoName, url: null, isEditing: true })
                    );
                    this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
                });
            });
    }

    getPromotionProduct() {
        const filters: SelectedFilter[] = [
            {
                field: 'productTypeId',
                value: ProductTypes.PROMOTION,
            },
        ];
        this.productService
            .getProducts(filters)
            .subscribe((products: Product[]) => {
                products = products
                    .filter(
                        (product) => product.isAlwaysValid == 1 || product.isNotExpired()
                    )
                    .map((product) => product.setFinalPrice());
                this.promotionProduct = products.reduce(
                    (minPrice, product) =>
                        parseInt(product.singlePrice) < parseInt(minPrice.singlePrice)
                            ? product
                            : minPrice,
                    products[ 0 ]
                );
                this.showPrice();
            });
    }

    doSubmit() {
        this.isLoading = true;
        this.promotion.showId = this.formGroup.value.selectedShow.id;
        if (this.formGroup.value.dateFrom) {
            const date = new Date(this.formGroup.value.dateFrom);
            date.setHours(0, 0, 0, 0);
            this.formGroup.patchValue({
                dateFrom: date
            });
        }
        this.promotion.dateFrom = this.formGroup.value.dateFrom;
        this.promotion.monthsNumber = Number(this.formGroup.value.monthsNumber);
        this.promotion.organizationId = this.formGroup.value.selectedShow.organizationId;

        of(this.isCreation)
            .pipe(
                switchMap((isCreation) => {
                    if (isCreation) {
                        return this.promotionService.createPromotion(this.promotion);
                    }
                    return this.promotionService.modifiyPromotion(this.promotion);
                }),
                flatMap((promotion) =>
                    this.addToCart(this.isCreation ? promotion.id : this.promotion.id)
                )
            )
            .subscribe(
                (res) => {
                    this.notification.success(
                        this.translate.instant('FORM.AJOUTE-AU-PANIER')
                    );
                    const url = this.localizeRouter.translateRoute(`/shopping-cart`);
                    this.router.navigate([ url ]);
                },
                (err) => {
                    this.isLoading = false;
                    if (
                        err.status === 409 &&
                        err.error &&
                        err.error.name === 'ResourceAlreadyExists'
                    ) {
                        this.notification.error(
                            this.translate.instant('ERRORS.ResourceAlreadyExists')
                        );
                    } else {
                        this.notification.error(
                            this.translate.instant('ERRORS.SERVER-ERROR')
                        );
                    }
                }
            );
    }

    private addToCart(promotionId: number): Observable<any> {
        const cartProduct = {
            productTypeId: ProductTypes.PROMOTION,
            productId: this.promotionProduct.id,
            organizationId: this.accountService.getCurrentCtxOrganizationId(),
            userId: parseInt(this.authService.User.id),
            quantity: this.promotion.monthsNumber,
            itemId: promotionId,
        };
        return this.shoppingCartService.addToCart(cartProduct);
    }

    dismiss() {
        this.router.navigate([ '../' ], { relativeTo: this.route });
    }

    approve() {
        this.changeStatus(ObjectStatus.APPROUVE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.APPROUVEE'));
        });
    }

    reject() {
        this.changeStatus(ObjectStatus.REJETE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.REJETEE'));
        });
    }

    private changeStatus(status: ObjectStatus): Observable<any> {
        this.isLoading = true;
        return this.promotionService
            .modifyPromotionApproval(status, this.promotionId)
            .do(() => {
                this.promotion.statusId = status;
                this.isLoading = false;
            });
    }

    isDateLessThanTwoDays(): boolean {
        if (this.formGroup.value.dateFrom) {
            const selectedDate = new Date(this.formGroup.value.dateFrom);
            const twoDaysFromNow = new Date(Date.now());
            twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
            if (selectedDate <= twoDaysFromNow) return true;
        }
        return false;
    }

    /*le prix qui correspon à la duration choisie*/
    showPrice(): string {
        if (this.promotionProduct) {
            const price = Number(this.promotionProduct.singlePrice);
            const nbMonths: number = this.formGroup.value.monthsNumber;
            const total = (nbMonths * price).toFixed(2);
            return `${total} $`;
        }
    }

    updateApercu() {
        if (!this.selectedShow) return;
        this.promotion.show = this.selectedShow;
        this.itemList = [];
        const item = this.selectedShow.toListItem(this.lang);
        item.itemSubtitle = this.showService.extractArtistList(this.selectedShow);
        //promoted
        item.itemInfo4 = '1';
        const str = this.translate.instant('ROUTES.shows-offers');
        item.itemUrl = `/${str}/${this.selectedShow.organizationId}/${this.selectedShow.id}`;

        this.itemList.push(
            item,
            new ListItem({ isEmpty: true }),
            new ListItem({ isEmpty: true }),
            new ListItem({ isEmpty: true })
        );
    }

    private getShows(): Observable<Show[]> {
        const showFilters = [
            {
                field: 'statusId',
                value: ShowStatus.APPROUVE,
            },
            {
                field: 'isPaid',
                value: 1,
            },
            {
                field: 'organizationId',
                value: this.organizationId
                    ? this.organizationId
                    : this.accountService.getCurrentCtxOrganizationId(),
            },
            {
                field: 'withOrg',
                value: 1,
            },
            {
                field: 'isAvailable',
                value: 1,
            },
        ];

        return this.showService.getShows(showFilters).do((res) => {
            this.shows = res;
        });
    }
}
