import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/_configs/globals';
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from '../../services/tags.service';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { ActivatedRoute } from '@angular/router';
import { Tag, ITagsTrans, ITagUpdate } from '../../model/tags.model';
import { Show } from 'src/app/concepts/show/model/show.model';
import { Pagination, ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { ShowService } from 'src/app/concepts/show/services/show.service';
import { AccountService } from 'src/app/concepts/account/services/account.service';

@Component({
    selector: 'app-tags-single',
    templateUrl: './tags-single.component.html',
    styleUrls: ['./tags-single.component.scss']
})
export class TagsSingleComponent implements OnInit {
    isReady = false;
    loading = false;

    currentLang = '';
    pageTitle = '';
    searchText = '';
    contextOrgId: number;
    isAlwaysValid;
    validityInterval: [Date, Date] | [];

    tag: Tag;
    tagId: number;
    tagShows: ListItem[] = [];
    addedShows: ListItem[] = [];
    pagination: Pagination = new Pagination();

    showsSearch: Show[];
    completeUrl: any;
    logoUrl: string;

    uploadAction: string;

    // Get Only approved shows
    defaultFilters: SelectedFilter[] = [
        {
            field: 'statusId',
            value: 3
        }
    ];
    public readonly imageConfig = this.globals.images;

    constructor(
        private globals: Globals,
        private showService: ShowService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private accountService: AccountService,
        private etiquettesService: TagsService,
        private notification: RideauNotificationService
    ) {}

    ngOnInit(): void {
        this.uploadAction = this.globals.api.ENDPOINT + 'upload/show/main';
        this.tagId = parseInt(this.route.snapshot.params['tagId']);
        this.currentLang = this.translate.currentLang;
        this.contextOrgId = this.accountService.getCurrentCtxOrganizationId();

        this.initPagination();

        this.getTag();
        this.getTagShows();
        this.getAllShowsForSearch();
    }

    initPagination() {
        this.pagination.limit = 10;
        this.pagination.offset = 0;
        this.pagination.total = 0;
    }

    // UPDATE
    updateTag() {
        const newTag: ITagUpdate = {
            id: this.tagId,
            image: this.completeUrl,
            //showsId: [...this.tagShows, ...this.addedShows].map(el => el.itemId),
            trans: [
                {
                    langId: this.currentLang,
                    keyword: this.pageTitle
                }
            ],
            isAlwaysValid: Number(this.isAlwaysValid)
        };
        if (!this.isAlwaysValid && this.validityInterval) {
            const [validFrom, validTo] = this.validityInterval;
            newTag.validFrom = (validFrom || new Date()).toJSON();
            newTag.validTo = (validTo || new Date()).toJSON();
        }

        this.etiquettesService.updateTagById(newTag).subscribe((data) => {
            if (data) {
                // Notify an reload activity list
                this.notification.success(this.translate.instant('ETIQUETTE-MAJ'));
                this.getTag();
            }
        });
    }

    // TAGS
    getTag() {
        this.etiquettesService.getTagById(this.tagId).subscribe((data) => {
            this.tag = new Tag(data);
            this.pageTitle = this.tag.getTranslatedProperty(this.currentLang, 'keyword');
            this.logoUrl = this.tag.image;

            this.isAlwaysValid = !!this.tag.isAlwaysValid;
            if (this.tag.validFrom && this.tag.validTo) {
                this.validityInterval = [new Date(this.tag.validFrom), new Date(this.tag.validTo)];
            } else {
                this.validityInterval = [];
            }

            this.isReady = true;
        });
    }

    onPageChange(event: Pagination) {
        console.log(event);
        this.pagination.offset = event.offset;
        this.getTagShows();
    }

    getTagShows() {
        this.etiquettesService.getShowsByTagId(this.tagId, this.pagination).subscribe((data) => {
            this.tagShows = data.map((item) => {
                const show = new Show(item);
                const tagShowItem = show.toListItem(this.currentLang);
                tagShowItem.itemSubtitle = this.showService.extractArtistList(show);
                const str = this.translate.instant('ROUTES.shows-offers');
                tagShowItem.itemUrl = `/${str}/${this.contextOrgId}/${show.id}`;
                return tagShowItem;
            });
        });
    }

    deleteTagShow($event) {
        this.etiquettesService.deleteShowsByTagId(this.tagId, $event).subscribe((data) => {
            if (data) {
                // Notify an reload activity list
                this.notification.success(this.translate.instant('ETIQUETTE-SHOW-DELETE'));
                this.getTagShows();
            }
        });
    }

    // SEARCH
    onSearchChange(search) {
        // console.log(search)
        this.searchText = search ? search : '';
        this.getAllShowsForSearch();
    }

    onSearchSelect(selectedId) {
        // console.log(selected)
        if (selectedId) {
            this.etiquettesService.addShowToTag(this.tagId, selectedId).subscribe((data) => {
                if (data) {
                    // Notify an reload activity list
                    this.notification.success(this.translate.instant('ETIQUETTE-SHOW-MAJ'));
                    this.searchText = '';
                    this.initPagination();
                    this.getTagShows();
                }
            });
        }
    }

    getAllShowsForSearch() {
        // console.log($event)
        this.showService.getShows(this.defaultFilters, null, null, this.searchText).subscribe((data) => {
            this.showsSearch = data;
        });
    }

    // IMAGE
    onUploadFileCallback($event) {
        // console.log($event);
        this.completeUrl = $event;
    }
}
