import { Translatable } from 'src/app/shared/model/translatable.model';
import { Organization } from '../../organization/model/organization.model';
import { ListItem } from './../../../shared/model/list-item.model';
import { Jury } from './jury.model';
import { Translation } from 'src/app/shared/model/translatable.model';

export class Meeting extends Translatable {
  id: number;
  statusId?: number;
  title?: string;
  subtitle?: string;
  image?: string;
  description?: string;
  organizationId: number;
  organization?: Organization;
  organizationName?: string;
  place?: string;
  website?: string;
  dateFrom?: Date;
  dateTo?: Date;
  juries?: any;
  hasVitrines: number;
  hasProducts: number;
  isPublished?: number;
  isDatePeriod?: number;
  isActivityOptional?: number;
  activityDateFrom?: string;
  activityDateTo?: string;
  isStandOptional?: number;
  standDateFrom?: string;
  standDateTo?: string;
  standImageUrl?: string;
  subscriptionDateFrom?: Date;
  subscriptionDateTo?: Date;
  proposalVitrineDateFrom?: Date;
  proposalVitrineDateTo?: Date;
  isActive?: number;
  trans: Translation[];

  constructor(datas: Record<string, any>) {
    super();
    this.id = datas['id'];
    this.statusId = datas['statusId'];
    this.title = datas['title'];
    this.subtitle = datas['subtitle'];
    this.image = datas['image'];
    this.description = datas['description'];
    this.organizationId = datas['organizationId'];
    this.organization = datas['organization']
      ? new Organization(datas['organization'])
      : null;
    this.organizationName = datas['organizationName'];
    this.place = datas['place'];
    this.website = datas['website'];
    this.juries = datas['juries']
      ? datas['juries'].map((jury) => new Jury(jury))
      : [];
    this.hasVitrines = datas['hasVitrines'];
    this.hasProducts = datas['hasProducts'];
    this.isPublished = datas['isPublished'] ? datas['isPublished'] : 0;
    this.isDatePeriod = datas['isDatePeriod'] ? datas['isDatePeriod'] : null;
    this.dateFrom = datas['dateFrom'] ? new Date(datas['dateFrom']) : null;
    this.dateTo = datas['dateTo'] ? new Date(datas['dateTo']) : null;
    this.isActivityOptional = datas['isActivityOptional'];
    this.activityDateFrom = datas['activityDateFrom'];
    this.activityDateTo = datas['activityDateTo'];
    this.isStandOptional = datas['isStandOptional'];
    this.standDateFrom = datas['standDateFrom'];
    this.standDateTo = datas['standDateTo'];
    this.standImageUrl = datas['standImageUrl'];
    this.proposalVitrineDateFrom = datas['proposalVitrineDateFrom']
      ? new Date(datas['proposalVitrineDateFrom'])
      : null;
    this.proposalVitrineDateTo = datas['proposalVitrineDateTo']
      ? new Date(datas['proposalVitrineDateTo'])
      : null;
    this.subscriptionDateFrom = datas['subscriptionDateFrom']
      ? new Date(datas['subscriptionDateFrom'])
      : null;
    this.subscriptionDateTo = datas['subscriptionDateTo']
      ? new Date(datas['subscriptionDateTo'])
      : null;
    this.isActive = datas['isActive'];
    this.trans = datas['trans'];
  }

  toListItem(lang: string, organizer?: string): ListItem {
    const item = new ListItem();
    // "TODO : Format Date - Ville - Organisé par ROSEQ";
    item.itemUrl = `./${this.id}`;
    item.itemTitle = this.getTranslatedProperty(lang, 'name');
    item.itemImgSrc = this.image ? this.image : null;
    item.itemSubtitle = `${this.convertDate(this.dateFrom, lang)}`;
    if (!!this.isDatePeriod) {
      item.itemSubtitle += ` au ${this.convertDate(this.dateTo, lang)}`;
    }
    if (this.place) {
      item.itemSubtitle += ` - ${this.place}`;
    }
    if (this.organization) {
      item.itemInfo1 = `${
        this.organization
          ? organizer +
            ' ' +
            this.organization.getTranslatedProperty(lang, 'name')
          : ''
      }`;
    }
    return item;
  }

  toSingleMeeting(lang: string): void {
    this.title = this.getTranslatedProperty(lang, 'name');
    this.subtitle = `Du ${this.convertDate(this.dateFrom, lang)}`;
    if (!!this.isDatePeriod) {
      this.subtitle += ` au ${this.convertDate(this.dateTo, lang)}`;
    }
    this.description = this.getTranslatedProperty(lang, 'description');
  }

  convertDate(d: Date, lang: string): string {
    const local = this.getLocal(lang);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return d ? new Intl.DateTimeFormat(local, options).format(d) : '';
  }

  private getLocal(lang: any): string {
    let local = '';
    if (lang === 'fr') {
      local = 'fr-CA';
    } else {
      local = 'en-US';
    }
    return local;
  }

  /**
   * Détermine à partir des dates si le choix d'activités
   * est ouvert.
   */
  isActivityChoiceOpened(): boolean {
    // vrai par défaut
    let isOpened = !!this.isActivityOptional;
    const today = new Date();
    const dateFrom = new Date(this.activityDateFrom);
    const dateTo = new Date(this.activityDateTo);
    dateTo.setHours(23, 59, 59, 999); // Mettre la date à la dernière milliseconde du jour

    if (this.isActivityOptional) {
      // Si dateFrom et DateTo sont définis, on vérifie que today est entre les deux.
      if (this.activityDateFrom && this.activityDateTo) {
        isOpened = today >= dateFrom && today <= dateTo;
      }
      // Si seul dateFrom est défini, on vérifie que today est après.
      else if (this.activityDateFrom && !this.activityDateTo) {
        isOpened = today >= dateFrom;
      }
      // Si seul dateTo est défini, on vérifie que today est avant.
      else if (!this.activityDateFrom && this.activityDateTo) {
        isOpened = today <= dateTo;
      }
    }

    return isOpened;
  }
}
