import { Component, Input } from '@angular/core';

@Component({
    selector: 'form-error',
    template: `
        <nz-form-explain class="has-error">
            {{ value }}
        </nz-form-explain>
    `
})
export class FormErrorComponent {
    @Input() value: string;
}
