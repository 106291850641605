<ng-container *ngIf="isReady">

    <app-page-title title="{{ venue.getTranslatedProperty(lang , 'name') }}"
        buttonText="{{ 'FORM.MODIFIER' | translate }}" [buttonLink]="editVenueLink" [permission]="1"
        [organizationId]="venue.organizationId">
    </app-page-title>

    <div class="container">
        <ng-container *ngIf="minConfigCapacity === maxConfigCapacity; else capacities">
            <h2>{{maxConfigCapacity}} {{'PLACES' | translate}}</h2>
        </ng-container>
        <ng-template #capacities>
            <h2>{{minConfigCapacity}} {{'A-CCENT' | translate}} {{maxConfigCapacity}} {{'PLACES' | translate}}</h2>
        </ng-template>

        <div nz-row nzGutter="24">
            <div nz-col nzSpan="24" nzLg="12">
                <div class="main-picture p-3 mb-3">
                    <img src="{{venue && venue.photo ? venue.photo : '/assets/images/notfound-square.png'}}" alt="">
                </div>
                <p class="fiche-content">
                    <quill-editor [modules]="{toolbar:false}" [readOnly]="true" class="fiche-content"
                        [ngModel]="venue.getTranslatedProperty(lang, 'description') ? venue.getTranslatedProperty(lang, 'description') : ('AUCUNE-DONNEE' | translate )">
                    </quill-editor>
            </div>
            <div nz-col nzSpan="24" nzLg="6">
                <h3 class="c-title c-title--section mb-1">{{'COORDONNEES' | translate}}</h3>
                <div class="fiche-details mb-3">
                    <ng-container *ngIf="venue.address">
                        <ng-container
                            *ngTemplateOutlet="text; context: {$implicit: venue.address.address1 }"></ng-container>
                        <ng-container
                            *ngTemplateOutlet="text; context: {$implicit: venue.address.address2 }"></ng-container>
                        <ng-container
                            *ngTemplateOutlet="text; context: {$implicit: venue.address.city + ', ' + venueState }"></ng-container>
                        <ng-container
                            *ngTemplateOutlet="text; context: {$implicit: 'COUNTRIES.' + venue.address.countryId | translate }"></ng-container>
                        <ng-container
                            *ngTemplateOutlet="text; context: {$implicit: venue.address.zipCode }"></ng-container>
                    </ng-container>
                    <ng-container
                        *ngTemplateOutlet="text; context: {$implicit: (venue.phone || '') + (venue.phonePostNumber ? '[' + venue.phonePostNumber +']' : '') }"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="text; context: {link: {mailto: true, url: venue.email} }"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="text; context: {link: {mailto: false, url: venue.website | externalLink} }"></ng-container>
                    <div class="d-flex align-items-center mt-3 mb-4" *ngIf="venue.wikidataId">
                        <ng-container
                            *ngTemplateOutlet="text; context: {link: {mailto: false, url: (wikidataUrlBase + venue.wikidataId) | externalLink, content: ('VENUE-DATA.PAGE' | translate) +'&nbsp;'+ ('VENUE-DATA.WIKIDATA_TITLE' | translate), tooltip: 'VENUE-DATA.TOOLTIP_LINK' | translate} }"></ng-container>
                    </div>
                </div>
                <nz-divider></nz-divider>
                <ng-container *ngIf="organization">
                    <h3 class="c-title c-title--section mb-1">{{'ORGANIZATION-TYPE_3' | translate}}</h3>
                    <div class="item-row" *ngIf="organization">
                        <div class="logo-diffuseur-wrapper">
                            <img class="logo-diffuseur-wrapper-img"
                                src="{{ organization.logo? organization.logo : '/assets/images/placeholder-organisation.svg'}}"
                                alt="">
                        </div>
                        <div class="item-row__col">
                            <p class="item-row__text item-row__text--title"><a
                                    href="{{organizationLink}}">{{organization.getTranslatedProperty(lang,'name')}}</a>
                            </p>
                            <p class="item-row__text item-row__text--subtitle">{{ organization.address.city }}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div nz-col nzSpan="24" nzLg="6">
                <h3 class="c-title c-title--section mb-1">{{'CONFIGURATIONS-DE-SALLE' | translate}}</h3>
                <div class="fiche-details mb-3">
                    <ng-container *ngFor="let configuration of venue.configurations">
                        <p class="mb-0">{{ getConfigurationLabel(configuration.configurationTypeId) }}
                            ({{configuration.capacity}}
                            {{'PLACES' | translate}})</p>
                    </ng-container>
                </div>
                <ng-container *ngIf="venue.urlTechSpecification">
                    <p class="my-4">
                        <a href="{{ venue.urlTechSpecification }}" class="btn-rect btn--larger py-1" target="_blank">
                            <i nz-icon type="file-pdf" class="mr-2" theme="outline" aria-hidden="true"></i>
                            <span>{{ 'FORM.DEVIS-TECHNIQUE' | translate }}</span>
                        </a>
                    </p>
                </ng-container>
            </div>
            <div nz-col
                 nzSpan="24"
                 nzLg="6">
                <h3 class="c-title c-title--section mb-1">{{'RESIDENCY-OFFER' | translate}}</h3>
                <div class="fiche-details mt-1">
                    <p *ngIf="venue.isResidency">{{'OUI' | translate}}</p>
                    <p *ngIf="!venue.isResidency">{{'NON' | translate}}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- DONNEES-Residence -->
    <div class="residency-block" *ngIf="venue.isResidency">
        <app-venue-single-residency [venue]="venue"></app-venue-single-residency>
    </div>

</ng-container>

<ng-template #text let-content let-link="link">
    <p class="mb-0">
        <ng-container
            *ngTemplateOutlet="!link ? defaultTpl : link.mailto ? linkTpl : urlTpl; context: { $implicit: content, link: link }">
        </ng-container>
        <ng-template #defaultTpl let-content>
            <ng-container *ngIf="content">{{ content }}</ng-container>
        </ng-template>
        <ng-template #linkTpl let-link="link">
            <ng-container *ngIf="link.url">
                <a href="mailto:{{ link.url }}">
                    {{ link.content || link.url }}
                </a>
            </ng-container>
        </ng-template>
        <ng-template #urlTpl let-link="link">
            <ng-container *ngIf="link.url">
                <a class="website label-only label-with-icon" href="{{ link.url }}" target="_blank">
                    <span>
                        {{ link.content || link.url }}
                    </span>
                    <ng-container *ngIf="link.tooltip">
                        <span class="help-icon help-icon--blue help-icon--small" nz-tooltip
                            nzTooltipTitle="{{link.tooltip}}" nzTooltipPlacement="right">
                        </span>
                    </ng-container>
                </a>
            </ng-container>
        </ng-template>
    </p>
</ng-template>
