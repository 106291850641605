export enum AppLanguages {
  ALLEMAND = 'de',
  ANGLAIS = 'en',
  ATIKEMEKW = 'ak',
  CRIE = 'cr',
  ESPAGNOL = 'es',
  FRANCAIS = 'fr',
  HINDI = 'hi',
  INNU = 'in',
  INUKTITUT = 'iu',
  ITALIEN = 'it',
  JAPONAIS = 'ja',
  MANDARIN = 'mn',
  SANS_PAROLES = 'mu',
  PORTUGUAIS = 'pt',
  RUSSE = 'ru',
  AUTRES = 'at',
}

interface AppLanguagesSpecs {
  name: string;
  id: AppLanguages;
}

export function GetSiteLanguageById(langId: string): AppLanguagesSpecs {
  switch (langId) {
    case AppLanguages.ALLEMAND:
      return { name: 'ALLEMAND', id: AppLanguages.ALLEMAND };

    case AppLanguages.FRANCAIS:
      return { name: 'FRANCAIS', id: AppLanguages.FRANCAIS };

    case AppLanguages.ANGLAIS:
      return { name: 'ANGLAIS', id: AppLanguages.ANGLAIS };

    case AppLanguages.ESPAGNOL:
      return { name: 'ESPAGNOL', id: AppLanguages.ESPAGNOL };

    case AppLanguages.PORTUGUAIS:
      return { name: 'PORTUGUAIS', id: AppLanguages.PORTUGUAIS };

    case AppLanguages.AUTRES:
      return { name: 'AUTRES', id: AppLanguages.AUTRES };

    case AppLanguages.ATIKEMEKW:
      return { name: 'ATIKEMEKW', id: AppLanguages.ATIKEMEKW };

    case AppLanguages.CRIE:
      return { name: 'CRIE', id: AppLanguages.CRIE };

    case AppLanguages.HINDI:
      return { name: 'HINDI', id: AppLanguages.HINDI };

    case AppLanguages.INNU:
      return { name: 'INNU', id: AppLanguages.INNU };

    case AppLanguages.INUKTITUT:
      return { name: 'INUKTITUT', id: AppLanguages.INUKTITUT };

    case AppLanguages.ITALIEN:
      return { name: 'ITALIEN', id: AppLanguages.ITALIEN };

    case AppLanguages.JAPONAIS:
      return { name: 'JAPONAIS', id: AppLanguages.JAPONAIS };

    case AppLanguages.MANDARIN:
      return { name: 'MANDARIN', id: AppLanguages.MANDARIN };

    case AppLanguages.SANS_PAROLES:
      return { name: 'SANS_PAROLES', id: AppLanguages.SANS_PAROLES };

    case AppLanguages.RUSSE:
      return { name: 'RUSSE', id: AppLanguages.RUSSE };
  }
}

export function GetSiteLanguageOptions(): AppLanguagesSpecs[] {
  return [
    { name: 'ALLEMAND', id: AppLanguages.ALLEMAND },
    { name: 'ANGLAIS', id: AppLanguages.ANGLAIS },
    { name: 'ATIKEMEKW', id: AppLanguages.ATIKEMEKW },
    { name: 'CRIE', id: AppLanguages.CRIE },
    { name: 'ESPAGNOL', id: AppLanguages.ESPAGNOL },
    { name: 'FRANCAIS', id: AppLanguages.FRANCAIS },
    { name: 'HINDI', id: AppLanguages.HINDI },
    { name: 'INNU', id: AppLanguages.INNU },
    { name: 'INUKTITUT', id: AppLanguages.INUKTITUT },
    { name: 'ITALIEN', id: AppLanguages.ITALIEN },
    { name: 'JAPONAIS', id: AppLanguages.JAPONAIS },
    { name: 'MANDARIN', id: AppLanguages.MANDARIN },
    { name: 'SANS_PAROLES', id: AppLanguages.SANS_PAROLES },
    { name: 'PORTUGUAIS', id: AppLanguages.PORTUGUAIS },
    { name: 'RUSSE', id: AppLanguages.RUSSE },
    { name: 'AUTRES', id: AppLanguages.AUTRES },
  ];
}
