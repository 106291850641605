import { Routes } from '@angular/router';
import { SiteConcepts } from 'src/app/shared/enums/site-concepts.enum';
import { PromotionFormComponent } from './components/promotion-form/promotion-form.component';
import { PromotionListComponent } from './components/promotion-list/promotion-list.component';

export const AdminPromotionRoutes: Routes = [
  {
    path: '',
    component: PromotionListComponent,
    data: {
      breadcrumb: [SiteConcepts.PROMOTION],
    },
  },
  {
    path: ':promotionId/modify',
    component: PromotionFormComponent,
    data: {
      breadcrumb: [SiteConcepts.PROMOTION, SiteConcepts.SINGLE],
    },
  },
];
