<app-page-title title="{{ 'PRODUITS' | translate }}"
                buttonText="{{ 'FORM.NOUVEAU-PRODUIT' | translate}}"
                (buttonClicked)="newProduct()"
                [permission]="1"
                [organizationId]="1">
</app-page-title>


<!--APP LIST-->
<div class="container">
  <app-item-list [itemList]="itemList"
                 [filterList]="leftSideFilters"
                 [sortList]="sorts"
                 [listItemsType]="listType"
                 [pagination]="pagination"
                 [isLoading]="isLoading"
                 [useFilters]="true"
                 [useViewSwitcher]="false"
                 [useSearchBar]="false"
                 [useSorting]="false"
                 [usePagination]="true"
                 elementsLabel="{{'PRODUITS' | translate | lowercase}}"
                 (filterChange)="onFilterChange($event)"
                 (searchChange)="onSearchChange($event)"
                 (sortChange)="onSortChange($event)"
                 (pageChange)="onPageChange($event)">
  </app-item-list>
</div>
