import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Venue } from 'src/app/concepts/venue/model/venue.model';
import { Pagination, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { Organization } from '../model/organization.model';
import { MainService } from './../../../shared/services/main.services';
import { Globals } from './../../../_configs/globals';

@Injectable({
    providedIn: 'root'
})
export class BottinService extends MainService {
    constructor(protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    getBottin(filters?: SelectedFilter[], pagination?: Pagination, searchText?: string): Observable<Array<Organization | Venue>> {
        let endpoint: string = this.uri + this.globals.endpoints.catalog;
        const text = searchText ? searchText.trim() : null;

        if (filters || pagination || text) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination || text ? '&' : '';
        }
        if (text) {
            endpoint += `name=${encodeURIComponent(text)}`;
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) {
                    pagination.total = data['total'];
                }
                return data['catalog'].map((elt) => {
                    if (elt['isVenue']) {
                        return new Venue(elt);
                    } else if (elt['isOrganization']) {
                        return new Organization(elt);
                    }
                    return null;
                });
            })
        );
    }
}
