import { TourResolver } from './tour.resolver';
import { Tour } from './model/tour.model';
import { NetworkResolver } from 'src/app/concepts/organization/network.resolver';
import { Organization } from 'src/app/concepts/organization/model/organization.model';

export interface TourEditRouteData {
  tour: Tour,
  network: Organization[]
}

export const TourEditRouteResolvers = {
  tour: TourResolver,
  network: NetworkResolver
}
