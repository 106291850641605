<div nz-row
     class="mt-3 table-head light-tablehead">
    <div nz-col
         nzMd="12"
         class="table-head__col">
        <strong>
            {{'PROMOTION-OFFRES' | translate}}
        </strong>
    </div>
    <div nz-col
         nzMd="12"
         class="table-head__col text-right"
         id="button-wrapper">
        <button class="btn-rect v-align-center"
                (click)="goToOffers()">
            <i nz-icon
               nzType="plus"
               nzTheme="outline"
               class="pt-1"
               aria-hidden="true"></i>
            {{'NOUVELLE-PROMOTION' | translate}}
        </button>
    </div>
</div>

<div *ngFor="let item of promItemArray">
    <app-item-row-promo [item]="item"
                        [showRenewButton]="true"
                        (renewItem)="goToOffers(item)"
                        (modifyItem)="modifier(item)"
                        (removeItem)="supprimer(item)"></app-item-row-promo>
</div>
