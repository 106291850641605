<div class="item-row mb-3">
  <div class="item-row__col">
    <p class="item-row__text item-row__text--title">Cotisation de membre</p>
    <p class="item-row__text item-row__text--subtitle">RIDEAU</p>
  </div>

  <div class="item-row__col v-align-center text-center">
    <p class="item-row__text v-align-center item-row__text--subtitle">
      {{subscription.sentAt | date : 'mediumDate' : 'GMT'}}</p>
  </div>

  <div class="item-row__col v-align-center text-center v-align-center">
    <p class="item-row__text v-align-center item-row__text--subtitle">
      {{subscription.totalFee}} $ {{'FORM.TAXES-INCLUSES' | translate}}
    </p>
  </div>

  <div class="right-buttons">
    <ng-container *ngIf="!subscription.isPaid">
      <a [routerLink]="[ '/organization', subscription.organizationId ,'bill-preview', subscription.id ] | localize"
         *appPermission="6;objectOrganizationId:subscription.organizationId"
         target="_blank"
         class="btn-rect">{{'AFFICHER' | translate }}</a>
      <button type="button"
              *appPermission="6;objectOrganizationId:subscription.organizationId"
              (click)="showPayment()"
              class="btn ml-2">{{'PAYER' | translate }}</button>
      <button type="button"
              *appPermission="0;objectOrganizationId:subscription.organizationHeadId"
              (click)="markAsPayed()"
              class="btn-rect">{{'MARQUER-PAYEE' | translate }}</button>
    </ng-container>
    <ng-container *ngIf="subscription.isPaid">
      <p class="item-row__text item-row__text--subtitle text-right">
        <i nz-icon
        aria-hidden="true"
           type="check"
           theme="outline"></i>
        <span class="ml-3">{{'PAYEE' | translate }} {{subscription.paidAt | date  : 'mediumDate' : 'GMT'}}</span>
      </p>
    </ng-container>

  </div>
</div>
<div class="form-filter form-filter--right mb-3"
     *ngIf="showCreditCards">
  <app-select-credit-card [processing]="isLoading"
                          (onPay)="checkout($event)"></app-select-credit-card>
</div>
