import { ProductTypes } from './../../../enums/product-types-enum';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { ProductService } from '../../../services/product.service';
import { AbstractProductForm } from '../product-abstract-form/product-abstract-form.component';
import { Tarif } from '../../../model/product.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { Gratuitie } from '../../../model/gratuities.model';

@Component({
    selector: 'app-product-tarification-form',
    templateUrl: './product-tarification-form.component.html',
    styleUrls: [ './product-tarification-form.component.scss' ],
})
export class ProductTarificationFormComponent
    extends AbstractProductForm
    implements OnInit {
    public formGroup: FormGroup;
    public gratuities: Gratuitie[] = [];
    public lang: string;
    public orgaName: string;
    public productTypes = ProductTypes;
    public radioRateValue: string;
    public tariffs: Tarif[] = [];

    constructor(
        private fb: FormBuilder,
        public productService: ProductService,
        protected accountService: AccountService,
        private organisationService: OrganizationService,
        private translate: TranslateService
    ) {
        super(accountService);
    }

    public addGratuitie(event) {
        const gratuitie = new Gratuitie({});
        this.gratuities.push(gratuitie);
        event.stopPropagation();
        event.preventDefault();
    }

    public addTarif(event) {
        const tarif = new Tarif();
        tarif.id = 'new_tarif' + Date.now();
        this.productValue.tariffs.push(tarif);
        this.tariffs = this.productValue.tariffs.filter(
            (tarif) => tarif.toDelete !== 1
        );
        event.stopPropagation();
        event.preventDefault();
    }

    public deleteGratuitie() {
        this.gratuities = this.gratuities.filter((x) => x.toDelete !== 1);
    }

    public doSubmit(param?: any) {
        if (this.radioRateValue === 'freeRate') {
            this.productValue.isFree = 1;
            this.productValue.singlePrice = null;
            this.productValue.tariffs = [];
        } else if (this.radioRateValue === 'fixRate') {
            this.productValue.isFree = 0;
            this.productValue.tariffs = [];
            this.productValue.singlePrice = this.formGroup.value.singlePrice;
        } else if (this.radioRateValue === 'multipleRate') {
            this.productValue.singlePrice = null;
            this.productValue.isFree = 0;
            this.productValue.tariffs.forEach((tarif) => {
                if (isNaN(Number(tarif.id)) && tarif.id.startsWith('new_tarif')) {
                    tarif.id = null;
                }
            });
            // forcer le reload pour que les ids des tarifs soient à jour.
            param = 'reload';
        }

        this.productValue.gratuities = this.gratuities;

        super.doSubmit(param);
    }

    public ngOnInit(): void {
        this.initForm();
        this.getOrganisationProprietaire();
    }

    public removeTarif(tarifId) {
        console.log('removeTarif', tarifId);
        this.tariffs = this.productValue.tariffs.filter((x) => x.toDelete !== 1);
    }

    protected initForm() {
        // init du formulaire
        this.formGroup = this.product.getTarificationFormGroup(this.fb, this.lang);
        if (this.formGroup.value.isFree === 1) {
            this.radioRateValue = 'freeRate';
        } else if (this.formGroup.value.singlePrice) {
            this.radioRateValue = 'fixRate';
        } else {
            this.radioRateValue = 'multipleRate';
            this.tariffs = this.productValue.tariffs.filter((x) => x.toDelete !== 1);
        }

        this.gratuities = this.productValue.gratuities;

        this.formGroup.value.singlePrice = parseFloat(
            this.formGroup.value.singlePrice
        );
    }

    private getOrganisationProprietaire() {
        this.organisationService
            .getOrganization(this.product.organizationId)
            .subscribe((org: Organization) => {
                this.orgaName = org.getTranslatedProperty(
                    this.translate.currentLang,
                    'name'
                );
            });
    }
}
