import { Translatable } from 'src/app/shared/model/translatable.model';

export interface ICartProduct {
  id?: number;
  productId?: number;
  productTypeId: number;
  organizationId: number;
  userId: number;
  quantity: number;
  langId: string;
  template?: Template;
  itemId?: number;
  totalPrice: number; // Qty * singlePrice
}

export interface IProductGratuity {
  id?: number;
  productId?: number;
  userId?: number;
  price?: string;
}

export class CartProduct implements ICartProduct {
  id?: number;
  productId?: number;
  item: CartProductItem;
  productTypeId: number;
  organizationId: number;
  userId: number;
  quantity: number;
  langId: string;
  template?: Template;
  itemId?: number;
  totalPrice: number;

  constructor(datas: Record<string, any>) {
    this.id = datas['id'];
    this.productId = datas['productId'];
    this.productTypeId = datas['productTypeId'];
    this.organizationId = datas['organizationId'];
    this.userId = datas['userId'];
    this.quantity = datas['quantity'];
    this.langId = datas['langId'];
    this.itemId = datas['itemId'];
    this.totalPrice = datas['totalPrice'];
    this.template = new Template(datas['template']);
    this.item = new CartProductItem(datas['item']);
  }
}

export class Template extends Translatable {
  isFree: number;
  singlePrice: number;
  taxable: number;
  validTo: Date;
  validFrom: Date;
  gratuities: IProductGratuity[];
  price: number;

  constructor(datas: Record<string, any>) {
    super();
    this.isFree = datas['isFree'];
    this.singlePrice = datas['singlePrice'];
    this.taxable = datas['taxable'];
    this.trans = datas['trans'];
    this.validTo = datas['validTo'] ? new Date(datas['validTo']) : null;
    this.validFrom = datas['validFrom'] ? new Date(datas['validFrom']) : null;
    this.gratuities = datas['gratuities'] ? datas['gratuities'] : null;
  }

  isExpired(): boolean {
    const today = new Date();

    // Si dateFrom et DateTo sont définis, on vérifie que today est entre les deux.
    if (this.validFrom && this.validTo) {
      return today >= this.validFrom && today <= this.validTo;
    }
    // Si seul this.validFrom est défini, on vérifie que today est après.
    else if (this.validFrom && !this.validTo) {
      return today >= this.validFrom;
    }
    // Si seul this.validTo est défini, on vérifie que today est avant.
    else if (!this.validFrom && this.validTo) {
      return today <= this.validTo;
    }
  }

  setFinalPrice(): void {
    if (this.isFree) {
      this.price = 0;
    } else if (this.gratuities && this.gratuities.length) {
      this.price = this.gratuities[0].price
        ? parseFloat(this.gratuities[0].price)
        : 0;
      this.singlePrice = this.gratuities[0].price
        ? parseFloat(this.gratuities[0].price)
        : 0;
    } else if (this.singlePrice) {
      this.price = this.singlePrice;
    } else {
      this.price = 0;
    }
  }
}

export class CartProductItem extends Translatable {
  constructor(datas: Record<string, any>) {
    super();
    this.trans = datas && datas['trans'];
  }
}
