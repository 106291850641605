import { Routes } from '@angular/router';
import { PaidBillComponent } from 'src/app/shared/components/prints/paid-bill/paid-bill.component';
import { Permission } from 'src/app/shared/enums/roles.enum';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';
import { PermissionGuard } from '../account/services/permission-guard.service';
import { OrganizationModifyComponent } from './components/organization-modify/organization-modify.component';
import { OrganizationSingleComponent } from './components/organization-single/organization-single.component';
import { UnpaidBillComponent } from 'src/app/shared/components/prints/unpaid-bill/unpaid-bill.component';
import { PublicationFormComponent } from '../publication/components/publication-form/publication-form.component';
import { OrganizationTourShowComponent } from './components/organization-tour-show/organization-tour-show.component';

const SCENE_PRO_ID = 1;

export const OrganiszationRoutes: Routes = [
    {
        path: '',
        component: OrganizationSingleComponent
    },
    {
        path: ':organizationId', // this will be the organisation ID or name
        children: [
            {
                path: '',
                component: OrganizationSingleComponent,
                data: {
                    breadcrumb: [SiteConcepts.BOTTIN, SiteConcepts.SINGLE],
                    appPermission: undefined,
                    shouldBeApproved: false
                }
            },
            {
                path: 'modify/promotion/new-publication',
                component: PublicationFormComponent,
                data: {
                    breadcrumb: [SiteConcepts.PUBLICATION, SiteConcepts.SINGLE],
                    appPermission: Permission.CAN_EDIT,
                    shouldBeApproved: true
                }
            },
            {
                path: 'tour/:tourId/show/:showId',
                component: OrganizationTourShowComponent,
                data: {
                    breadcrumb: [SiteConcepts.ACCOUNT, SiteConcepts.SINGLE]
                }
            },
            {
                path: 'modify/:page',
                component: OrganizationModifyComponent,
                data: {
                    breadcrumb: [SiteConcepts.BOTTIN, SiteConcepts.SINGLE],
                    appPermission: Permission.CAN_EDIT,
                    shouldBeApproved: false
                }
            },
            {
                path: 'modify',
                component: OrganizationModifyComponent,
                data: {
                    breadcrumb: [SiteConcepts.BOTTIN, SiteConcepts.SINGLE],
                    appPermission: Permission.CAN_EDIT,
                    shouldBeApproved: false
                }
            },
            {
                path: 'bill/:transactionId',
                component: PaidBillComponent,
                data: {
                    breadcrumb: [SiteConcepts.BOTTIN, SiteConcepts.SINGLE],
                    appPermission: Permission.CAN_EDIT,
                    shouldBeApproved: false
                }
            },
            {
                path: 'bill-preview/:billId',
                component: UnpaidBillComponent,
                data: {
                    breadcrumb: [SiteConcepts.BOTTIN, SiteConcepts.SINGLE],
                    appPermission: Permission.CAN_EDIT,
                    shouldBeApproved: false
                }
            },
            {
                path: 'bill/:transactionId/refund',
                component: PaidBillComponent,
                canActivateChild: [PermissionGuard],
                data: {
                    breadcrumb: [SiteConcepts.BOTTIN, SiteConcepts.SINGLE],
                    appPermission: Permission.CAN_EDIT,
                    organizationId: SCENE_PRO_ID
                }
            }
        ],
        canActivate: [PermissionGuard]
    }
];
