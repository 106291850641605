import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginatedListComponent } from './paginated-list.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, NzPaginationModule, TranslateModule],
    exports: [PaginatedListComponent],
    declarations: [PaginatedListComponent]
})
export class PaginatedListModule {}
