import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Globals } from './../../_configs/globals';

@Directive({
  selector: '[appIfAdmin]',
})
export class IfAdminDirective implements OnInit, OnDestroy {
  private hasView = false;
  private subscription: Subscription;
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly accountService: AccountService,
    private readonly globals: Globals
  ) {}
  ngOnInit(): void {
    this.subscription = this.accountService.currentOrganizationChange.subscribe(
      this.update.bind(this)
    );
    this.update();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  private update(): void {
    const condition =
      this.accountService.getCurrentCtxOrganizationId() ===
      this.globals.SCENE_PRO_ORGID;
    if (condition && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
