import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class RegexpValidator extends Validators {
    static validate(pattern: string, flags?: string): ValidatorFn {
        return (control: AbstractControl): { [ key: string ]: boolean; } | null => {
            if (!control.touched || (control.touched && !control.dirty)) {
                return null;
            }
            const expression = new RegExp(pattern, flags || '');
            const pass = expression.test(control.value);
            if (!pass) return { regex: true };

            return null;
        };
    }
}
