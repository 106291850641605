<div class="item-row mb-3">
    <div class="item-row__col"
         *ngIf="bill.statusId == 10  || bill.statusId == 20;else paymentFail">
        <p class="item-row__text item-row__text--title">No</p>
        <p class="item-row__text item-row__text--subtitle">{{bill.id}}</p>
    </div>
    <ng-template #paymentFail>
        <div class="item-row__col">
            <p class="item-row__text item-row__text--title">{{'PAIEMENT-ECHOUE' | translate}}</p>
        </div>
    </ng-template>

    <div class="item-row__col text-center">
        <p class="item-row__text item-row__text--title">{{bill.createdAt | date : 'mediumDate' : 'GMT'}}</p>
    </div>
    <div class="item-row__col text-center">
        <p class="item-row__text item-row__text--title">{{bill.total}} $</p>
    </div>
    <div class="item-row__col text-right">
        <p class="item-row__text item-row__text--title"
           *ngIf="bill.statusId == 10 || bill.statusId == 20">
            <ng-container *ngIf="bill.organizationId; else elseTemplate">

                <a [routerLink]="[ '/organization', bill.organizationId ,'bill', bill.id, 'refund' ] | localize"
                   *ngIf="isRideau && bill.statusId == 10"
                   target="_blank"
                   class="btn-rect mr-2 v-align-center">{{'REMBOURSER' | translate }}</a>
                <a [routerLink]="[ '/organization', bill.organizationId ,'bill', bill.id ] | localize"
                   target="_blank"
                   class="btn-rect v-align-center">{{'AFFICHER' | translate }}</a>
            </ng-container>
            <ng-template #elseTemplate>
              <a [routerLink]="[ '/admin','users','bill',  bill.userId, bill.id, 'refund' ] | localize"
                 *ngIf="isRideau && bill.statusId == 10"
                 target="_blank"
                 class="btn-rect mr-2 v-align-center">{{'REMBOURSER' | translate }}</a>
              <a [routerLink]="[ '/profile','bill', bill.userId, bill.id ] | localize"
                 target="_blank"
                 class="btn-rect v-align-center">{{'AFFICHER' | translate }}</a>
            </ng-template>

        </p>
    </div>
</div>
