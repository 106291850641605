import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from 'localize-router';
import { NgZorroAntdModule, NzToolTipModule } from 'ng-zorro-antd';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from 'src/app/shared/shared.module';
import { StandFormComponent } from '../stand/components/stand-create/stand-form/stand-form.component';
import { StandRangeFormComponent } from '../stand/components/stand-create/stand-range-form/stand-range-form.component';
import { ActivityListComponent } from './components/activity-list/activity-list.component';
import { ActivityDisponibilityFormComponent } from './components/activity-modify/activity-disponibility-form/activity-disponibility-form.component';
import { ActivityGeneralFormComponent } from './components/activity-modify/activity-general-form/activity-general-form.component';
import { ActivityModifyComponent } from './components/activity-modify/activity-modify.component';
import { ActivityParticipantsFormComponent } from './components/activity-modify/activity-participants-form/activity-participants-form.component';
import { ActivityVitrinesFormComponent } from './components/activity-modify/activity-vitrines/activity-vitrines-form/activity-vitrines-form.component';
import { ActivityVitrinesComponent } from './components/activity-modify/activity-vitrines/activity-vitrines.component';
import { ActivitySingleComponent } from './components/activity-single/activity-single.component';
import { ChooseActivitiesComponent } from './components/choose-activities/choose-activities.component';

@NgModule({
  declarations: [
    ActivityModifyComponent,
    ActivityGeneralFormComponent,
    ActivitySingleComponent,
    ActivityListComponent,
    ChooseActivitiesComponent,
    ActivityParticipantsFormComponent,
    ActivityDisponibilityFormComponent,
    ActivityVitrinesComponent,
    ActivityVitrinesFormComponent,
    ActivityParticipantsFormComponent,
  ],
  entryComponents: [
    ChooseActivitiesComponent,
    StandFormComponent,
    StandRangeFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    LocalizeRouterModule,
    NgZorroAntdModule,
    SharedModule,
    QuillModule,
    NzToolTipModule,
  ],
  exports: [
    ActivityModifyComponent,
    ActivityGeneralFormComponent,
    ActivityDisponibilityFormComponent,
    ActivityParticipantsFormComponent,
    ActivitySingleComponent,
    ActivityListComponent,
  ],
})
export class ActivityModule {}
