<form
  nz-form
  id="activityAccesRestrictions"
  [formGroup]="formGroup"
  (ngSubmit)="doSubmit()"
>
  <div nz-row
       nzGutter="16">
    <div nz-col
         nzMd="4">
      <nz-form-item>
        <nz-form-control>
          <label for="capacity">{{ "FORM.CAPACITE" | translate }}</label>
          <input id="capacity"
                 name="capacity"
                 type="number"
                 formControlName="capacity">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <label for="orgaTypeAcces">
    {{ "FORM.ORGANIZATION-TYPE-ACCESS" | translate }}</label
  >
  <nz-form-item>
    <nz-form-control nzSpan="14">
      <nz-radio-group formControlName="hasOrgaTypeRestrictions" (ngModelChange)="checkDisableOrganizationTypeRestriction($event)">
          <label nz-radio class="input-radioBtn" [nzValue]="false">
            {{ "AVAILABLE-ALL-ORGANIZATION-TYPES" | translate }}
          </label>
          <label nz-radio class="input-radioBtn" [nzValue]="true">
            {{ "AVAILABLE-CERTAIN-ORGANIZATION-TYPES" | translate }}
          </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <div formGroupName="orgaTypeRestrictions" style="margin-left: 2%">
    <nz-form-item nz-row>
      <nz-form-control nzSpan="14">
        <label
          nz-checkbox
          formControlName="hasOrgaId"
          (ngModelChange)="checkDisableOrganizationNetworkRestriction($event)"
        >
          {{ "RESTRICTION-ORGANISATION-SPECIFIQUE" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-control nzSpan="14">
        <nz-select
          nzPlaceHolder="{{ 'SELECT-NETWORK' | translate }}"
          nzShowSearch
          nzAllowClear
          style="width: 100%"
          formControlName="orgaId"
          [nzDisabled]="!formGroup.get('orgaTypeRestrictions.hasOrgaId').value"
        >
          <nz-option
            *ngFor="let network of networkList"
            [nzLabel]="network.getTranslatedProperty(currentLang, 'name')"
            [nzValue]="network.id"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-control nzSpan="14">
        <label nz-checkbox formControlName="allowProducers">
          {{ "ORGANIZATION-TYPE_1" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-control nzSpan="14">
        <label nz-checkbox formControlName="allowDiffusers">
          {{ "ORGANIZATION-TYPE_2" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-romw>
      <nz-form-control nzSpan="14">
        <label nz-checkbox formControlName="allowVenuesOwners">
          {{ "ORGANIZATION-TYPE_3" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-romw>
    <nz-form-control nzSpan="14">
      <label nz-checkbox formControlName="allowGovernmental">
        {{ "ORGANIZATION-TYPE_4" | translate }}
      </label>
    </nz-form-control>
  </nz-form-item>
    <nz-form-item nz-romw>
      <nz-form-control nzSpan="14">
        <label nz-checkbox formControlName="allowProviders">
          {{ "ORGANIZATION-TYPE_5" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-romw>
      <nz-form-control nzSpan="14">
        <label nz-checkbox formControlName="allowMedia">
          {{ "ORGANIZATION-TYPE_6" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-romw>
      <nz-form-control nzSpan="14">
        <label nz-checkbox formControlName="allowOthers">
          {{ "ORGANIZATION-TYPE_7" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-romw>
      <nz-form-control nzSpan="14">
        <label nz-checkbox formControlName="allowIndividuals">
          {{ "INDIVIDUS" | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
  </div>
  <!-- Restriction de l'activité selon les produits de la rencontre -->
  <label> {{ "FORM.MEETING-PRODUCT-ACCESS" | translate }}</label>
  <nz-form-item>
    <nz-form-control nzSpan="14">
      <nz-radio-group formControlName="hasNoProductsRestrictions" (ngModelChange)="checkDisableProductsRestrictions($event)">
        <label nz-radio class="input-radioBtn" [nzValue]="true">
          {{ "AVAILABLE-ALL-PRODUCTS" | translate }}
        </label>
        <label nz-radio class="input-radioBtn" [nzValue]="false">
          {{ "AVAILABLE-CERTAIN-PRODUCTS" | translate }}
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <div style="margin-left: 2%">
    <div *ngIf="areProductsLoading">
      <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
    </div>
    <span *ngFor="let product of meetingProducts; let i = index">
      <nz-form-item nz-row>
        <nz-form-control nzSpan="14">
          <label
            nz-checkbox
            [nzDisabled]="formGroup.get('hasNoProductsRestrictions').value"
            [ngModel]="meetingProductsRestrictions.includes(product.id)"
            (ngModelChange)="addNewProductRestriction($event, product.id)"
            [ngModelOptions]="{ standalone: true }"
          >
            {{ product.getTranslatedProperty(currentLang, "name") ?
               product.getTranslatedProperty(currentLang, "name") :
               ('NO-NAME-PRODUCT') | translate
            }}
          </label>
        </nz-form-control>
      </nz-form-item>
    </span>
  </div>

  <button nz-button [nzLoading]="isLoading" class="btn btn--default mt-3">
    {{ "ENREGISTER" | translate }}
  </button>
</form>
