<form *ngIf="formGroup" nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()" class="mb-4">
    <div nz-row nzGutter="16">
        <div nz-col nzMd="18">
            <nz-form-item>
                <nz-form-control>
                    <nz-radio-group [(ngModel)]="radioRateValue" [ngModelOptions]="{ standalone: true }">
                        <label for="" nz-radio nzValue="freeRate" class="d-block mb-2" formControlName="isFree">{{ 'FORM.GRATUIT' | translate }}</label>
                        <label nz-radio nzValue="fixRate" class="mb-3">
                            <span class="mr-2">{{ 'FORM.TARIF-FIXE-DE' | translate }}</span>
                            <input type="number" class="w-50" *ngIf="radioRateValue == 'fixRate'" formControlName="singlePrice" />
                            $
                        </label>
                        <label
                            for=""
                            nz-radio
                            *ngIf="product.productTypeId === productTypes.INSCRIPTION || product.productTypeId === productTypes.PROGRAM"
                            nzValue="multipleRate"
                            class="d-block mb-2"
                        >
                            {{ 'FORM.PLUSIEURS-TARIFS' | translate }}
                        </label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row class="mb-4" *ngIf="radioRateValue == 'multipleRate' && (product.productTypeId === productTypes.INSCRIPTION || product.productTypeId === productTypes.PROGRAM)">
        <label for="">{{ 'FORM.TARFIS' | translate }}</label>
        <nz-divider></nz-divider>

        <div *ngIf="!orgaName" class="loader">
            <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
        </div>

        <ng-container *ngIf="orgaName">
            <app-tarif-row *ngFor="let tarif of tariffs" [tarif]="tarif" [orgaName]="orgaName" (deleteTarif)="removeTarif($event)"></app-tarif-row>
        </ng-container>

        <button (click)="addTarif($event)" class="btn btn--default">{{ 'AJOUTER-TARIF' | translate }}</button>
    </div>

    <div nz-row class="mb-4">
        <h3>{{ 'GRATUITIES' | translate }}</h3>
        <nz-divider></nz-divider>

        <app-gratuitie-row *ngFor="let gratuitie of gratuities" [gratuitie]="gratuitie" (deleteGratuitie)="deleteGratuitie()"></app-gratuitie-row>

        <button (click)="addGratuitie($event)" class="btn btn--default">{{ 'AJOUTER-GRATUITIE' | translate }}</button>
    </div>

    <button class="btn btn--default" style="padding-top: 7px">{{ 'ENREGISTER' | translate }}</button>
</form>
