import { TranslateService } from '@ngx-translate/core';
import { Classification } from '../model/classification.model';

export function buildTreesClassifications(
  classifications: Classification[],
  classificationsListCascade: any[],
  translate: TranslateService
) {
  const langId = translate.currentLang;

  for (const classification of classifications) {
    if (classification.level == 1) {
      const obj: any = getBranchClassification(
        classification,
        classifications,
        langId
      );
      classificationsListCascade.push(obj);
    }
  }
}

function getBranchClassification(classification: Classification, classifications: Classification[], langId: string) {
  //Obtenir la traduction
  const translation = classification.trans as any;
  const trans = translation.find((x) => x.langId == langId) as any;

  const classif: any = {
    value: classification.id.toString(),
    label: classification.getTranslatedProperty(langId, 'description'),
  };

  //Suppression de l'item ajouté
  classifications = classifications.filter((x) => x.id != classification.id);

  //Obtenir ses enfants
  const nextChildren: any = classifications.filter((x) => x.parentId == classification.id);

  if (nextChildren && nextChildren.length) {
    classif.children = [];
    for (const child of nextChildren) {
      const childRow: any = getBranchClassification(child, classifications, langId);
      // if last
      classif.children.push(childRow);
    }
  } else {
    classif.isLeaf = true;
  }

  return classif;
}
