import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { SessionStorageService } from '@app/shared/services/storage.service';

@Injectable({ providedIn: 'root' })
export class UserOrganizationResolver implements Resolve<Organization> {
    private readonly sessionStorageOrganizationKey = 'sp.currentContextOrganizationId';
    constructor(
        private sessionStorage: SessionStorageService,
        private readonly organizationService: OrganizationService,
        private readonly userOrganizationIdResolver: UserOrganizationIdResolver
    ) {}

    resolve(): Observable<Organization> {
        return this.userOrganizationIdResolver.resolve().pipe(switchMap((contextOrganizationId) => this.getCurrentContextOrganization(contextOrganizationId)));
    }

    private getCurrentContextOrganization = (contextOrganizationId: number): Observable<Organization | null> => {
        if (contextOrganizationId === -1) {
            return of(null);
        }
        const currentContextOrganization: Organization = this.sessionStorage.getItem(this.sessionStorageOrganizationKey);
        if (currentContextOrganization && currentContextOrganization.id === contextOrganizationId) {
            return of(currentContextOrganization);
        }
        return this.organizationService.getOrganization(contextOrganizationId).pipe(
            tap((organization: Organization) => {
                this.sessionStorage.setItem(this.sessionStorageOrganizationKey, organization);
            })
        );
    };
}
