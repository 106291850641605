
<div >
  <div
    class="TourAccordion w-100 "
    AddClassOnClick
    [classToAdd]="'open'"
    [targetClass]="'.TourAccordion__arrow-container'"
    (click)='onClick()'
  >
    <img class="TourAccordion__logo d-inline-block" [src]='tourShows.tour.organization.logo' alt="">

    <div class="TourAccordion__arrow-container">
      <i class="TourAccordion__arrow-icon d-inline-block icon-chevron-down" ></i>
    </div>

    <h4 class="TourAccordion__name d-inline-block" > {{ tourName }} </h4>
    <div class="TourAccordion__trash-container" (click)='delete($event)' >
       <i class="TourAccordion__trash-icon icon-trash  d-inline-block"  alt=""></i>
    </div>
  </div>

  <div  class="TourAccordion__list" [class.TourAccordion__list-open]='isOpen'>
    <div class="TourAccordion__list-content" *ngFor="let tourShow of tourShows.tourShows">
      <app-organisation-show-accordion-block [tourShow]='tourShow' [tourId]='tourShows.tour.id' ></app-organisation-show-accordion-block>
    </div>
  </div>

</div>

<ng-template #modalText>

  <div class="ModalText">
    <h3 class="ModalText__title pl-4"> {{ "SUPPRIMER-DEPOT" | translate}} {{ tourShows.tourShows.length }} {{ "DEPOT-FROM" | translate }} « {{tourName}} » </h3>
    <p class="pl-4">{{"DELETE-DEPOT-INFORMATION" | translate}}</p>
  </div>

</ng-template>
