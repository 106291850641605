import { Component, Input, OnInit } from '@angular/core';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';

@Component({
  selector: 'app-show-modalities',
  templateUrl: './show-modalities.component.html',
  styleUrls: ['./show-modalities.component.scss']
})
export class ShowModalitiesComponent implements OnInit {

  @Input() tourShow?: TourShow;

  constructor() { }

  ngOnInit() {
  }

}
