import {ListItem} from './../../model/list-item.model';
import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {Globals} from '../../../_configs/globals';
import {RowTypes} from '../../enums/row-types.enum';
import {TourShowInterest} from 'src/app/concepts/tours/model/tour-show-interest.model';
import {TourInterest} from 'src/app/concepts/tours/enums/tour-interet.enum';

import {AccountService} from '../../../concepts/account/services/account.service';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent implements OnInit {
  @Input() item: ListItem;
  @Input() itemRowType: RowTypes;
  @Input() itemToRate = false;
  @Input() isOrgAdmin = true;
  @Input() showKeepSwitch = false;
  @Input() isAdmin?: boolean = false;

  @Output() rateItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateKeepedShow: EventEmitter<any> = new EventEmitter<any>();

  imageNotAvailable = false;
  activityVitrineRow: RowTypes = RowTypes.ACTIVITYVITRINE;
  showRow: RowTypes = RowTypes.SHOW;

  rateValue: number;
  update: boolean;
  likes = 0;
  dislikes = 0;
  isDisabled: boolean;
  isExpired: boolean;
  isKeep = false;

  constructor(
    private globals: Globals,
    private readonly accountService: AccountService
  ) {
  }

  get editable(): boolean {
    const currentOrganizationId = this.accountService.getCurrentCtxOrganizationId();
    return (
      currentOrganizationId === 1 || // Scene Pro
      this.item.organizationId === currentOrganizationId ||
      !(this.item.linkedShowExpired || this.item.itemStatus === 5)
    );
  }

  ngOnInit(): void {
    if (!this.item.itemImgSrc) {
      //image prop fallback
      this.imageNotAvailable = true;
      this.item.itemImgSrc = this.item.isEmpty
        ? './assets/images/empty.png'
        : this.globals.imgFallback;
    }

    if (this.itemToRate && this.item.itemRatings) {
      this.likes = this.item.itemRatings.likes;
      this.dislikes = this.item.itemRatings.dislikes;
      this.rateValue = this.item.itemRatings.interest;
    }
    this.isExpired = this.item.linkedShowExpired || this.item.itemStatus === 5;
    this.isKeep = this.item.itemIsToKeep;
  }

  isFav() {
    if (this.item.banners) {
      return this.item.banners.map(c => c.iconName === 'star-icon.svg').includes(true);
    }
    return null
  }

  isliked() {
    this.update = this.rateValue == TourInterest.LIKE;
    this.rateValue = this.update ? TourInterest.NOEVAL : TourInterest.LIKE;
    this.onRateItem();
  }

  isdisliked() {
    this.update = this.rateValue == TourInterest.DISLIKE;
    this.rateValue = this.update ? TourInterest.NOEVAL : TourInterest.DISLIKE;
    this.onRateItem();
  }

  onRateItem() {
    const rating: TourShowInterest = new TourShowInterest({
      tourShowId: this.item.itemId,
      interest: this.rateValue,
      reason: [],
    });

    this.rateItem.emit(rating);
  }

  isShowKeeped() {
    const currentOrganizationId = this.accountService.getCurrentCtxOrganizationId();
    const showKeeped: number = this.isKeep ? 2 : 1;
    this.updateKeepedShow.emit({
      id: this.item.itemId,
      statusId: showKeeped,
      organizationId: currentOrganizationId,
    });
  }
}
