import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class CanLoginGuard implements CanActivate {
    canActivateFromRoute = ['forgot-password', 'inscription', 'reset-password'];

    constructor(private translateService: TranslateService, private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.User) {
            this.router.navigate([this.translateService.currentLang, 'dashboard']);

            return false;
        } else {
            if (!environment.CAN_DIRECTLY_LOGIN && !this.previousUrlCanAccess()) {
                window.location.href = environment.VITRINE_URL;
                return false;
            }
            return true;
        }
    }

    private previousUrlCanAccess() {
        const isFromWebSite = document.referrer.includes(environment.VITRINE_URL);
        let isFromCanActivateRoute = false;

        for (let index = 0; index < this.canActivateFromRoute.length && !isFromCanActivateRoute; index++) {
            isFromCanActivateRoute = document.referrer.includes(this.canActivateFromRoute[index]);
        }

        return isFromWebSite || isFromCanActivateRoute;
    }
}
