<h2>{{ 'PROMOTION' | translate}}</h2>
<div nz-row>
    <div nz-col
         nzMd="24">
        <app-my-publications></app-my-publications>

    </div>
</div>
<div nz-row
*ngIf="isProducer"
>
  <div nz-col
       nzMd="24">
    <app-my-promotions></app-my-promotions>

  </div>
</div>
