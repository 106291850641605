export class ObjectUtils {
    static cleanUpNullValues<T>(obj: T): void {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                let value = obj[ key ];
                if (Array.isArray(value)) {
                    value.forEach((element: any) => {
                        if (element && typeof element === 'object') {
                            ObjectUtils.cleanUpObject(element);
                        }
                    });
                } else if (typeof value === 'object' && value !== null) {
                    ObjectUtils.cleanUpObject(value);
                } else if (value === null) {
                    obj[ key ] = undefined;
                }
            }
        }
    }

    private static cleanUpObject(obj: any): void {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                let value = obj[ key ];
                if (value === null) {
                    obj[ key ] = undefined;
                } else if (typeof value === 'object' && value !== null) {
                    ObjectUtils.cleanUpObject(value);
                }
            }
        }
    }
}
