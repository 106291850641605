import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShowAudiences } from '../enums/show-audiences.enum';

@Pipe({
  name: 'getTranslatedPublics'
})
export class GetTranslatedPublicsPipe implements PipeTransform {

  constructor(private translateService: TranslateService){}

  transform(publicIds: number[]): string {

    return publicIds.map( id => {
      const audienceEnumKey = Object.keys(ShowAudiences)[Object.values(ShowAudiences).indexOf(id)]
      return audienceEnumKey ? this.translateService.instant(`FORM.${audienceEnumKey}`) : ''
    } ).join(', ')
  }

}
