<ng-container *ngIf="isReady">
  <div class="page-template-head organisationPage">
    <div class="container">
      <div nz-row>
        <div nz-col>
          <div *ngIf="organization.statusId === 2" class="approval-box">
            <p>{{ "ORGANIZATION-APPROVAL-STATE" | translate }}</p>
            <div>
              <button *appPermission="4" (click)="reject()" nz-button [nzLoading]="isLoading"
                class="btn btn--default rejected ">
                {{ "REJETER" | translate }}
              </button>
              <button *appPermission="4" (click)="approve()" nz-button [nzLoading]="isLoading"
                class="btn btn--default approved ml-2">
                {{ "APPROUVER" | translate }}
              </button>

            </div>
          </div>
        </div>
      </div>
      <div nz-row style="display:flex; align-items: center;">
        <div nz-col nzMd="14" class="page-title--title">
          <!-- TITLE BAR -->
          <h1>{{ organization?.getTranslatedProperty(lang, "name") }}</h1>
        </div>
        <div nz-col nzMd="5">
          <!-- STATUS -->
          <span *ngIf="organization.statusId === 2" class="rect">{{
            "EN-ATTENTE-APPROBATION" | translate
            }}</span>
          <span *ngIf="organization.statusId === 4" class="rect">{{
            "STATUS.REJETEE" | translate
            }}</span>
          <span *ngIf="organization.statusId === 1 || organization.statusId === 4" class="ml-5">
            <button *appPermission="2; objectOrganizationId:organization.id;shouldBeApproved:false"
              (click)="deleteOrganization(organization.id)" nz-button [nzLoading]="isLoading" class="btn btn--default">
              {{ "FORM.SUPPRIMER" | translate }}
            </button>
          </span>
        </div>

        <div nz-col nzMd="6" style="text-align: right">
          <p class="mb-1" à *ngIf="organization.statusId === 3">
            <a class="btn btn-default" [routerLink]="['/organization/', organization.id] | localize">
              <i nz-icon nzType="eye" nzTheme="outline" class="mr-2" aria-hidden="true"></i>
              <span>{{ "VOIR-LE-PROFIL-PUBLIC" | translate }}</span>
            </a>
          </p>
          <p class="mb-1" *ngIf="organization.statusId !== 3 && totalErrorCount() > 0">
            <span class="rect">{{ totalErrorCount() }}
              {{
              totalErrorCount() > 1
              ? ("CHAMPS-INCOMPLETS" | translate)
              : ("CHAMP-INCOMPLET" | translate)
              }}
              :
              <a *ngIf="!displayErrors" (click)="toggleDisplayErrors()">
                {{ "AFFICHER" | translate }}</a>
              <a *ngIf="displayErrors" (click)="toggleDisplayErrors()">
                {{ "CACHER" | translate }}</a>
            </span>
          </p>
          <div *ngIf="
              (organization.statusId === 1 || organization.statusId === 4) &&
              totalErrorCount() === 0
            ">
            <button *appPermission="
                3;
                objectOrganizationId: organization.id;
                shouldBeApproved: false
              " (click)="submitForApproval()" nz-button [nzLoading]="isLoading" class="btn btn--default">
              {{ "FORM.SOUMETTRE-POUR-APPROBATION" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container pb-5">
    <div nz-row nzGutter="24">
      <!-- MENU -->
      <div nz-col nzMd="6" class="list-filters mb-4 mb-md-0 px-0">
        <div class="list-filters__box">
          <ul nz-menu>
            <li nz-menu-item class="errorCount list-filters__item" [nzSelected]="openedTab == 'coordonnees'"
              [routerLink]="['../coordonnees'] | localize" routerLinkActive="active">
              {{ "COORDONNEES" | translate }}
              <nz-badge *ngIf="displayErrors" [nzCount]="coordonneesErrorCount"
                [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
            </li>
            <li nz-menu-item class="errorCount list-filters__item" [nzSelected]="openedTab == 'type'"
              [routerLink]="['../type'] | localize" routerLinkActive="active">
              {{ "FORM.TYPE-ORGANISATION" | translate }}
              <nz-badge *ngIf="displayErrors" [nzCount]="typeErrorCount"
                [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
            </li>
            <li nz-menu-item class="errorCount list-filters__item" [nzSelected]="openedTab == 'profile'"
              [routerLink]="['../profile'] | localize" routerLinkActive="active">
              {{ "PROFIL" | translate }}
              <nz-badge *ngIf="displayErrors" [nzCount]="profileErrorCount"
                [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
            </li>
            <!-- Ces onglets ne sont accessibles qu'une fois l'organisation approuvée -->
            <ng-container *ngIf="organization.statusId === 3">
              <li nz-menu-item class="list-filters__item" [nzSelected]="openedTab == 'team'"
                [routerLink]="['../team'] | localize" routerLinkActive="active">
                {{ 'EQUIPE-ADMIN' | translate}}</li>

              <!-- PROMOTION -->
              <li nz-menu-item class="list-filters__item" [nzSelected]="openedTab == 'promotion'"
                [routerLink]="['../promotion'] | localize" routerLinkActive="active">
                {{ 'PROMOTION' | translate}}</li>

              <li nz-menu-item class="list-filters__item" [nzSelected]="openedTab == 'contacts'"
                [routerLink]="['../contacts'] | localize" routerLinkActive="active">
                {{ 'SHOW-STEP.CONTACTS' | translate}}</li>
              <li nz-menu-item *ngIf="broadcaster" class="list-filters__item" [nzSelected]="openedTab == 'broadcaster'"
                [routerLink]="['../broadcaster'] | localize" routerLinkActive="active">
                {{'PARAMETRE-DIFFUSEUR' | translate}}</li>
              <li nz-menu-item *ngIf="organization.types.indexOf(3) > -1" class="list-filters__item"
                [nzSelected]="openedTab == 'venues'" [routerLink]="['../venues'] | localize" routerLinkActive="active">
                {{ 'SALLES' | translate}}</li>
              <li nz-menu-item class="list-filters__item" [nzSelected]="openedTab == 'billing'"
                [routerLink]="['../billing'] | localize" routerLinkActive="active">
                {{ "FACTURATION" | translate }}
              </li>
              <li nz-menu-item *ngIf="organization.isNetworkHead" class="list-filters__item"
                [nzSelected]="openedTab == 'members'" [routerLink]="['../members'] | localize"
                routerLinkActive="active">
                {{ "ORGANISATIONS-MEMBRES" | translate }}
              </li>
              <li nz-menu-item *ngIf="isRideauMember" class="list-filters__item"
                [nzSelected]="openedTab == 'network-infos'" [routerLink]="['../network-infos'] | localize"
                routerLinkActive="active">
                <!-- TODO : Si membre d'un réseau -->
                {{ "NETWORK-INFOS" | translate }}
              </li>
            </ng-container>
            <li *ngIf="isProducteur" nz-menu-item class="list-filters__item" [nzSelected]="openedTab == 'user-depot'"
              [routerLink]="['../user-depot'] | localize" routerLinkActive="active">
              {{ "DEPOT" | translate }}
            </li>
          </ul>
        </div>
      </div>

      <div nz-col nzMd="18">
        <!-- COORDONÉES -->
        <ng-container *ngIf="openedTab == 'coordonnees'">
          <h2 class="mb-4">{{ "COORDONNEES" | translate }}</h2>
          <app-organization-coordinate-form [(organization)]="organization" (submitCallback)="saveCoord($event)"
            [isLoading]="isLoading" [displayErrors]="displayErrors"></app-organization-coordinate-form>
        </ng-container>
        <!-- TYPE D'ORGANISATION -->
        <ng-container *ngIf="openedTab == 'type'">
          <h2>{{ "FORM.TYPE-ORGANISATION" | translate }}*</h2>
          <app-organization-type-form [(organization)]="organization" (submitCallback)="saveTypes($event)"
            [isLoading]="isLoading" [displayErrors]="displayErrors"></app-organization-type-form>
        </ng-container>

        <!-- PROFILE -->
        <ng-container *ngIf="openedTab == 'profile'">
          <!--Profile Tab-->
          <h2>{{ "PROFIL" | translate }}</h2>
          <div nz-row>
            <div nz-col nzMd="24">
              <app-organization-profile-form [(organization)]="organization" (submitCallback)="saveProfile($event)"
                [isLoading]="isLoading" [displayErrors]="displayErrors">
              </app-organization-profile-form>
            </div>
          </div>
        </ng-container>

        <!-- PROMOTION -->
        <ng-container *ngIf="openedTab == 'promotion'">
          <app-organization-promotion></app-organization-promotion>
        </ng-container>

        <!-- ÉQUIPE ET ADMINISTRATION -->
        <ng-container *ngIf="openedTab == 'team'">
          <app-organization-team [organizationId]="organizationId"></app-organization-team>
        </ng-container>

        <!-- CONTACTS -->
        <ng-container *ngIf="openedTab == 'contacts'">
          <app-organization-contacts-form [organizationId]="organizationId"></app-organization-contacts-form>
        </ng-container>

        <!-- PARAMÈTRES DIFFUSEUR -->
        <ng-container *ngIf="openedTab == 'broadcaster'">
          <!--Broadcaster Tab-->
          <h2>{{ "PARAMETRE-DIFFUSEUR" | translate }}</h2>
          <!-- TODO : MAKE A REUSABLE COMPONENT -->
        </ng-container>

        <!-- SALLES -->
        <ng-container *ngIf="openedTab == 'venues'">
          <!--Venues Tab-->
          <app-organization-venues [organizationId]="organizationId"></app-organization-venues>
        </ng-container>

        <!-- ARTISTES -->
        <ng-container *ngIf="openedTab == 'artists'">
          <!--Artists Tab-->
          <h2>{{ "ARTISTES" | translate }}</h2>
        </ng-container>

        <!-- FACTURATION -->
        <ng-container *ngIf="openedTab == 'billing'">
          <!--Billing Tab-->
          <app-organization-billing [(organization)]="organization"></app-organization-billing>
        </ng-container>

        <!-- MEMBRES -->
        <ng-container *ngIf="openedTab == 'members'">
          <!--Billing Tab-->
          <app-organization-members [organization]="organization"></app-organization-members>
        </ng-container>

        <!-- NETWORK-INFOS -->
        <ng-container *ngIf="openedTab == 'network-infos'">
          <h2>{{ "NETWORK-INFOS" | translate }}</h2>
          <app-organization-network-infos [organization]="organization"></app-organization-network-infos>
        </ng-container>


        <ng-container *ngIf="openedTab === 'user-depot'">
          <h2>{{ "DEPOT" | translate }}</h2>
          <app-organisation-depot [organisationId]='organizationId'>
          </app-organisation-depot>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
