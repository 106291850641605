// Taken from: https://devblogs.microsoft.com/premier-developer/add-application-insights-to-an-angular-spa/

import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { environment } from '@env';

@Injectable()
export class MyMonitoringService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    // instrumentationKey: environment.AZURE_AI_KEY || '' // TODO: put key into bitbucket YAML def/env
  };

  constructor() {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
      AppInsights.queue.push(() => {
        AppInsights.context.addTelemetryInitializer(
          (envelope: Microsoft.ApplicationInsights.IEnvelope) => {
            envelope.tags['ai.cloud.role'] = 'frontend';
          }
        );
      });
    }
  }

  logPageView(
    name?: string,
    url?: string,
    properties?: any,
    measurements?: any,
    duration?: number
  ): void {
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  logEvent(name: string, properties?: any, measurements?: any): void {
    AppInsights.trackEvent(name, properties, measurements);
  }

  logError(error: Error): void {
    AppInsights.trackException(error);
  }
}
