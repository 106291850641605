
    <nz-form-item>
      <nz-form-control>
        <label [attr.for]="id" nz-checkbox [(ngModel)]="value" (ngModelChange)="ngModelChange()">
          <ng-content></ng-content>
        </label>
        <div class="description">
          <ng-content select="[description]"></ng-content>
        </div>
      </nz-form-control>
    </nz-form-item>
  