import { Globals } from 'src/app/_configs/globals';
import { AuthService } from './../../../concepts/account/services/auth/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../../concepts/account/services/account.service';
import { CreditCardService } from '../../../concepts/payment/services/credit-card.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-credit-card',
  templateUrl: './select-credit-card.component.html',
  styleUrls: ['./select-credit-card.component.scss'],
})
export class SelectCreditCardComponent implements OnInit {
  @Output()
  onPay: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  processing = false;

  public isReady = false;
  public currentOrgId: number;
  public isUserWithNoOrg = false;
  public creditCards: any[];
  public selectedCard;
  public cvvNumber;

  constructor(
    private global: Globals,
    private authService: AuthService,
    private accountService: AccountService,
    private creditCardService: CreditCardService
  ) {}

  ngOnInit(): void {
    this.getCards();
  }

  getCards(): void {
    this.currentOrgId = this.accountService.getCurrentCtxOrganizationId();
    let obs: Observable<any>;
    // Si user sans organisation
    if (this.currentOrgId === this.global.NO_ORGA) {
      this.isUserWithNoOrg = true;
      obs = this.creditCardService.getUserCards(
        Number(this.authService.User.id)
      );
    } else {
      obs = this.creditCardService.getCards(this.currentOrgId);
    }

    obs.subscribe((data) => {
      this.creditCards = data || [];
      if (this.creditCards.length === 1) {
        this.selectedCard = this.creditCards[0].id;
      }
      this.isReady = !this.isReady;
    });
  }

  sendPaymentInformations(): void {
    const obj = {
      selectedCard: Number(this.selectedCard),
      cvvNumber: this.cvvNumber,
    };

    this.processing = true;

    this.onPay.emit(obj);
  }
}
