<app-page-title title="{{ 'RAPPORTS' | translate }}"></app-page-title>
<div class="container pb-5">
  <div class="mb-4"
      nz-row
      nzGutter="16">

    <!-- Left side -->
    <div nz-col
        nzMd="6">
    </div>

    <!-- Main content -->
    <div nz-col
        nzMd="18">
      <h2 class="mb-4">{{ 'RAPPORTS-FINANCIERS' | translate}}</h2>

      <form nz-form>
        <div nz-row
            nzGutter="16">
          <div nz-col
              nzMd="12">
            <nz-form-item>
              <nz-form-control>
                <label for="name">{{ 'FORM.DATES-RAPPORT' | translate}}</label>
                <nz-range-picker nzAllowClear="true"
                                nzFormat="yyyy/MM/dd"
                                name="reportRange"
                                [(ngModel)]="reportRange">
                </nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <button nz-button
                [nzLoading]="isLoading"
                (click)="doSubmit(FACTURES)"
                class="btn btn--default mr-4">
          {{'GENERER-RAPPORT-FACTURE' | translate}}
        </button>

        <button nz-button
                [nzLoading]="isLoading"
                (click)="doSubmit(PRODUITS)"
                class="btn btn--default">
          {{'GENERER-RAPPORT-PRODUITS' | translate}}
        </button>


      </form>
    </div>
  </div>
</div>
<div class="container pb-5">
  <div class="mb-4"
       nz-row
       nzGutter="16">

    <div nz-col
         nzMd="18">
      <h2 class="mb-4">{{ 'RAPPORTS-MEMBRES' | translate}}</h2>

      <button nz-button
              [nzLoading]="isLoading"
              (click)="doSubmit(MEMBRES)"
              class="btn btn--default">
        {{'GENERER-RAPPORT-MEMBRES' | translate}}
      </button>
    </div>
  </div>
</div>

<div class="container pb-5">
  <div class="mb-4"
       nz-row
       nzGutter="16">

    <div nz-col
         nzMd="18">
      <h2 class="mb-4">{{ 'RAPPORTS-USERS' | translate}}</h2>

      <button nz-button
              [nzLoading]="isLoading"
              (click)="doSubmit(USERS)"
              class="btn btn--default">
        {{'GENERER-RAPPORT-USERS' | translate}}
      </button>
    </div>
  </div>
</div>
