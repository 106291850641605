<div>
    <label for="stand">{{ 'STAND' | translate }}</label>
    <nz-select id="stand" name="stand" nzAllowClear nzPlaceHolder="{{ 'AUCUN-STAND-SELECTIONNE' | translate }}" [(ngModel)]="selectedStand">
        <nz-option *ngFor="let stand of standList" [nzLabel]="stand.label" [nzValue]="stand" [nzDisabled]="stand.isDisabled"></nz-option>
    </nz-select>
</div>

<div *ngIf="meeting.standImageUrl">
    <img [src]="meeting.standImageUrl" alt="Plan des stands" />
</div>
