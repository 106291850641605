import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Tour } from '../../model/tour.model';

@Component({
  selector: 'app-edit-tour',
  templateUrl: './edit-tour.component.html'
})
export class EditTourComponent implements OnInit {

  tour: Tour;

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.tour = this.activatedRoute.snapshot.data.tour
  }

}
