<app-connection-container
  [title]=' "CONNEXION" | translate '
>

  <div class="row justify-content-center mt-4"  >

  <div class="Login__form-section-warning Login__form-section-col col-lg-4 col-sm-12 col-md-8 " >
    <i class="Login__form-section-warning-icon  icon-warning mr-1"></i>
    <p class="Login__form-section-text Login__form-section-text-bold" >
      {{ "RESET_PASSWORD_WARNING" | translate }}
    </p>
    <p class="Login__form-section-text">
      {{ "RESET_PASSWORD_REASON" | translate }}
    </p>

    <div class="Login__form-section-reset-password">
      <app-button-connection
        (click)='resetPasswordClick()'
        buttonType='Secondary'
        [text]=' "RESET_PASSWORD" | translate '
      >
      </app-button-connection>
    </div>

    <form
      nz-form
      [formGroup]="connectionForm"
      (ngSubmit)='login($event)'
      >

     <nz-form-item>
        <nz-form-control>
          <label style="color: white !important" for="email"> {{ "EMAIL_ADRESS" | translate }} </label>
          <input formControlName='email' type="email" name="email">
          <nz-form-explain class="has-error" *ngIf="connectionForm.invalid && connectionForm.get('email').errors &&
            connectionForm.get('email').touched">
            <span
            *ngIf="connectionForm.get('email').errors['required']; else incorrectField">{{ "CHAMP-REQUIS-SIMPLE" | translate }}</span>
            <ng-template #incorrectField>{{'ENTRER-VALEUR'|translate}}</ng-template>
          </nz-form-explain>          
        </nz-form-control>
     </nz-form-item>

     <nz-form-item>
      <app-password-input formControlName='password' [showForgotPassword]='true' ></app-password-input>
     </nz-form-item>


     <div class="Login__action">
      <app-button-connection
      class="w-100"
      buttonType='Primary'
      [text]=' "CONNEXION" | translate '
      [isLoading]='isLoading'
      type='submit'
      [disable]='!connectionForm.valid'
     >

     </app-button-connection>
     </div>


     <div class="Login__no-account-cta d-flex mb-4">
      <p>{{ "NO_ACCOUNT" | translate }}?</p>
      <a class="cta_link ml-2" [routerLink]='["../inscription"]'> {{ "REGISTER" | translate }}</a>
     </div>

    </form>


  </div>


  </div>



</app-connection-container>

