import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { UploadFile } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';
import { ProductTypes } from 'src/app/concepts/product/enums/product-types-enum';
import { Product } from 'src/app/concepts/product/model/product.model';
import { ProductService } from 'src/app/concepts/product/services/product.service';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';
import { BreadcrumbItem } from 'src/app/shared/model/breadcrumb.model';
import { SelectedFilter } from 'src/app/shared/model/list-item.model';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.services';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Globals } from 'src/app/_configs/globals';
import { IUpdatedResponse, Publication } from '../../model/publication.model';
import { PublicationService } from '../../services/publication.service';
import { ImgUploadService, ImgUploadState, ImgUploadStatus } from '@app/shared/components/img-upload/img-upload.service';

@Component({
    selector: 'app-publication-form',
    templateUrl: './publication-form.component.html',
    styleUrls: ['./publication-form.component.scss']
})
export class PublicationFormComponent implements OnInit {
    private publicationId: number;
    publication: Publication;

    private publicationProduct: Product;

    public uploadAction: string;
    public formGroup: FormGroup;
    public lang = this.translate.currentLang;
    public isCreation = false;
    public isLoading = false;
    public organizationId: number;
    public isReady = false;
    public isScenePro = false;

    //Publication image
    imageUrl: string;
    loading = false;
    hasAvatar = false;
    defaultImage = this.global.imgFallback;

    //ngModel
    selectedDuration = '';
    isSelected = false;
    price: string;
    public readonly imageConfig = this.global.images;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private global: Globals,
        private route: ActivatedRoute,
        private authService: AuthService,
        private translate: TranslateService,
        private productService: ProductService,
        private accountService: AccountService,
        private breadcrumbService: BreadcrumbService,
        private localizeRouter: LocalizeRouterService,
        private publicationService: PublicationService,
        private notification: RideauNotificationService,
        private shoppingCartService: ShoppingCartService,
        private imgUploadService: ImgUploadService
    ) {
        this.formGroup = new FormGroup({});
    }

    ngOnInit(): void {
        this.publication = new Publication({});
        this.uploadAction = this.global.api.ENDPOINT + this.global.endpoints.upload.publication;
        this.isScenePro = this.accountService.getCurrentCtxOrganizationId() === this.global.SCENE_PRO_ORGID;
        this.initPublication();
        this.getPublicationProduct();
    }

    initPublication() {
        const routeToNew = this.translate.instant('ROUTES.new-publication');
        //Si on est en creation, on crée une publication vide
        if (this.route.snapshot.routeConfig.path.includes(routeToNew)) {
            this.isCreation = true;
            this.publication = new Publication({
                trans: [
                    {
                        langId: this.lang,
                        title: this.translate.instant('NOUVELLE-PUBLICATION'),
                        description: ''
                    }
                ],
                statusId: ObjectStatus.NON_SOUMIS
            });
            this.organizationId = this.route.snapshot.params['organizationId'];
            this.initForm();
            this.isReady = true;

            const breadcrumbItems: BreadcrumbItem[] = [];
            const publiName = this.translate.instant('NOUVELLE-PUBLICATION');
            breadcrumbItems.push(new BreadcrumbItem({ title: publiName, url: null, isEditing: true }));

            this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
        } else {
            //Si on est en modifiaction, on va chercher la Publication
            this.publicationId = this.route.snapshot.params['publicationId'];
            this.getPublication();
        }
    }

    initForm() {
        this.formGroup = this.publication.getPublicationFormGroup(this.fb, this.lang);
        if (this.publication.image && this.publication.image.length > 0) {
            this.hasAvatar = true;
        }
        this.imgCurrentStatusHandler();
    }

    getPublication() {
        //TODO : get publication by id
        this.publicationService.getPublicationById(this.publicationId).subscribe((res) => {
            this.publication = res;
            this.initForm();
            this.imageUrl = this.formGroup.value.image;
            this.isReady = true;

            const breadcrumbItems: BreadcrumbItem[] = [];
            const publiName = this.publication.getTranslatedProperty(this.lang, 'title');
            breadcrumbItems.push(new BreadcrumbItem({ title: publiName, url: null, isEditing: true }));

            this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
        });
    }

    getPublicationProduct() {
        const filters: SelectedFilter[] = [
            {
                field: 'productTypeId',
                value: ProductTypes.PUBLICATION
            }
        ];
        this.productService.getProducts(filters, undefined, parseInt(this.authService.User.id)).subscribe((products: Product[]) => {
            products = products.filter((product) => product.isAlwaysValid == 1 || product.isNotExpired()).map((product) => product.setFinalPrice());
            this.publicationProduct = products.reduce((minPrice, product) => (parseInt(product.singlePrice) < parseInt(minPrice.singlePrice) ? product : minPrice), products[0]);
            this.showPrice();
        });
    }

    doSubmit() {
        this.isLoading = true;

        this.publication.setTranslatedProperty(this.lang, 'title', this.formGroup.value.title);
        this.publication.setTranslatedProperty(this.lang, 'description', this.formGroup.value.description);
        this.publication.image = this.formGroup.value.image;
        this.publication.website = this.formGroup.value.website;
        if (this.formGroup.value.dateFrom) {
            this.formGroup.patchValue({
                dateFrom: new Date(new Date(this.formGroup.value.dateFrom).setHours(0, 0, 0, 0))
            });
        }
        this.publication.dateFrom = this.formGroup.value.dateFrom;
        this.publication.weeksNumber = Number(this.formGroup.value.weeksNumber);

        if (this.isCreation) {
            this.publication.organizationId = this.organizationId;

            this.publicationService
                .createPublication(this.publication)
                .flatMap((publication: any) => this.addToCart(publication.id))
                .subscribe((res) => {
                    this.notification.success(this.translate.instant('FORM.AJOUTE-AU-PANIER'));
                    const url = this.localizeRouter.translateRoute(`/shopping-cart`);
                    this.router.navigate([url]);
                });
        } else {
            this.publicationService.updatePublication(this.publication).subscribe((response: IUpdatedResponse) => {
                this.notification.success(this.translate.instant('MODIFICATIONS-ENREGISTREES'));
                this.isLoading = false;
                if (this.publication.isPaid !== 1) {
                    this.updateCart();
                    if (response.isAddedToCart) {
                        this.router.navigate([this.localizeRouter.translateRoute(`/shopping-cart`)]);
                    }
                }
            });
        }
    }

    dismiss() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    approve() {
        this.changeStatus(ObjectStatus.APPROUVE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.APPROUVEE'));
        });
    }

    reject() {
        this.changeStatus(ObjectStatus.REJETE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.REJETEE'));
        });
    }

    isDateLessThanTwoDays(): boolean {
        if (this.formGroup.value.dateFrom) {
            const selectedDate = new Date(this.formGroup.value.dateFrom);
            const twoDaysFromNow = new Date(Date.now());
            twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
            if (selectedDate <= twoDaysFromNow) return true;
        }
        return false;
    }

    /*le prix qui correspon à la duration choisie*/
    showPrice(): string {
        if (!this.publicationProduct || !this.formGroup.value.weeksNumber) {
            return;
        }
        const price = Number(this.publicationProduct.singlePrice);
        const nbWeeks: number = this.formGroup.value.weeksNumber;
        return `${(nbWeeks * price).toFixed(2)} $`;
    }

    /*publication image*/
    removeAvatar() {
        this.publication.image = '';
        this.imageUrl = null;
        this.formGroup.patchValue({
            image: ''
        });
        this.hasAvatar = false;
    }

    beforeUpload = (file: File) => {
        return new Observable((observer: Observer<boolean>) => {
            const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPGorPNG) {
                this.notification.error(this.translate.instant('FORM.FORMAT-NON-SUPPORTE'));
                observer.complete();
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 2; //2MB
            if (!isLt2M) {
                this.notification.error(this.translate.instant('FORM.FICHIER-TROP-VOLUMINEUX'));
                observer.complete();
                return;
            }
            this.checkImageDimension(file).then((dimensionRes) => {
                if (!dimensionRes) {
                    this.notification.error(this.translate.instant('ERRORS.LARGEUR-HAUTEUR-1000-MAX'));
                    observer.complete();
                    return;
                }
                observer.next(isJPGorPNG && isLt2M && dimensionRes);
                observer.complete();
            });
        });
    };

    catchImageUrl(imageUrl) {
        this.imageUrl = imageUrl;
        this.formGroup.get('image').setValue(imageUrl);
    }

    handleChange(info: { file: UploadFile }): void {
        switch (info.file.status) {
            case 'uploading':
                this.loading = true;
                break;
            case 'done':
                {
                    // Get this url from response
                    this.getBase64(info.file.originFileObj, (img: string) => {
                        this.loading = false;
                        this.imageUrl = img;
                    });

                    const urlImageResponse = info.file.response.completeUrl;

                    this.formGroup.patchValue({
                        image: urlImageResponse
                    });
                    this.publication.image = urlImageResponse;
                    this.hasAvatar = true;
                }
                break;
            case 'error':
                this.notification.error(this.translate.instant('FORM.ERREUR-RESEAU'));
                this.loading = false;
                break;
        }
    }

    private changeStatus(status: ObjectStatus): Observable<any> {
        this.isLoading = true;
        return this.publicationService.modifyPublicationApproval(status, this.publicationId).do(() => {
            this.publication.statusId = status;
            this.isLoading = false;
        });
    }

    private getBase64(img: File, callback: (img: string) => void): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result.toString()));
        reader.readAsDataURL(img);
    }

    private checkImageDimension(file: File): Promise<boolean> {
        return new Promise((resolve) => {
            const img = new Image(); // create image
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                resolve(height <= this.global.images.maxHeight && width <= this.global.images.maxWidth);
            };
        });
    }

    private addToCart(publicationId: number): Observable<any> {
        const cartProduct = {
            productTypeId: ProductTypes.PUBLICATION,
            productId: this.publicationProduct.id,
            organizationId: this.accountService.getCurrentCtxOrganizationId(),
            userId: parseInt(this.authService.User.id),
            quantity: this.publication.weeksNumber,
            itemId: publicationId
        };
        return this.shoppingCartService.addToCart(cartProduct);
    }

    private updateCart(): void {
        this.shoppingCartService.shoppingCartChanged.emit('added');
    }

    private imgCurrentStatusHandler = () => {
        this.imgUploadService.currentStatus$.pipe(filter((x) => x.status !== ImgUploadStatus.INIT)).subscribe((state: ImgUploadState) => {
            switch (state.status) {
                case ImgUploadStatus.UPLOADING:
                    this.loading = true;
                    this.formGroup.get('image').setErrors({ required: true });
                    break;
                case ImgUploadStatus.DELETED:
                    this.loading = false;
                    this.formGroup.patchValue({ image: null });
                    break;
                case ImgUploadStatus.SUCCESS:
                case ImgUploadStatus.ERROR:
                    this.loading = false;
                    this.formGroup.get('image').setErrors(null);
                    break;
            }
            this.formGroup.get('image').updateValueAndValidity();
        });
    };

    isPublicationPaid() {
        return this.publication.isPaid == 1;
    }
}
