
<div class="RowItemAction_container" >

  <img [src]='imageUrl' class="RowItemAction_image"  alternative='../../../../assets/images/notfound-square.png' />

  <div class="RowItemAction_detailsAction">
    <div class="RowItemAction_detailsAction_details">
      <h3 class="RowItemAction_detailsAction_details_title"> {{title}} </h3>
      <p>{{ subtitle }}</p>
    </div>

    <div class="RowItemAction_detailsAction_action">
      <ng-content select="[action]"></ng-content>
    </div>

  </div>

</div>
