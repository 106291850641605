import { Globals } from 'src/app/_configs/globals';
import { AccountService } from './../../../../account/services/account.service';
import { FormGroup } from '@angular/forms';
import { Show } from './../../../model/show.model';
import { EventEmitter, Input, Output } from '@angular/core';
import { ShowStatus } from '../../../enums/show-status.enum';

export abstract class AbstractShowForm {
    showValue: Show;

    @Output()
    showChange = new EventEmitter<Show>();

    @Output()
    submitCallback = new EventEmitter<any>();

    @Input()
    get show(): Show {
        return this.showValue;
    }

    set show(value) {
        if (this.showValue && value.id !== this.showValue.id) {
            this.showValue = new Show(value);
            this.initForm();
        } else {
            this.showValue = new Show(value);
        }
        this.checkDisable();

        this.showChange.emit(this.showValue);
    }

    @Input()
    displayErrors: boolean;

    @Input()
    public isLoading: boolean;

    public formGroup: FormGroup;
    protected abstract initForm(): void;
    constructor(protected accountService: AccountService, protected globals: Globals) { }

    public get hasFormErrors() {
        return this.formGroup.invalid;
    }

    protected checkDisable() {
        if (this.formGroup && this.show.statusId === ShowStatus.SOUMIS && this.accountService.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID) {
            this.formGroup.disable();
        } else if (this.formGroup) {
            this.formGroup.enable();
            if (this.show.statusId === ShowStatus.APPROUVE && this.accountService.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID) {
                this.disableFieldIfExist('title');
                this.disableFieldIfExist('artists');
                this.disableFieldIfExist('image');
                this.disableFieldIfExist('shortDesc');
                this.disableFieldIfExist('representative');
                this.disableFieldIfExist('repEmail');
                this.disableFieldIfExist('repPhone');
                this.disableFieldIfExist('languages');
            }
        }
    }

    doSubmit<T>(param?: T) {
        this.showChange.emit(this.showValue);
        this.submitCallback.emit(param);
    }

    private disableFieldIfExist(name: string) {
        const field = this.formGroup.get(name);
        if (field) field.disable();
    }
}
