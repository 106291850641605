export interface TourShowInterests {
  interests: Array<TourShowInterest>;
}

export interface TourShowInterest {
  id?: number;
  tourShowId?: number;
  organizationId?: number;
  interest?: number;
  reason?: number[];
}

export class TourShowInterest {
  id?: number;
  tourShowId?: number;
  organizationId?: number;
  interest?: number;
  reason?: number[] = [];

  constructor(data: any) {
    this.id = data['id'];
    this.tourShowId = data['tourShowId'];
    this.organizationId = data['organizationId'];
    this.interest = data['interest'];
    this.reason = data['reason'];
  }
}
