import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { AuthService, StorageKey } from 'src/app/concepts/account/services/auth/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class UnauthorizeErrorInterceptor implements HttpInterceptor {

  private uncheckRouteRequest: string[] = ['login','reset-password-form', 'refresh-token', 'register', 'confirm-email' ]

  private tokenIsRefreshing = false;
  private tokenRefreshingSubject$: Subject<boolean> = new Subject();

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ){}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!this.requestContainsRoutes(req, this.uncheckRouteRequest)){
      return next.handle(req).pipe(
        catchError((error) => {
          if( error instanceof HttpErrorResponse && error.status === 401 ){

            if(!this.tokenIsRefreshing){

              this.changeTokenIsRefreshingState(true);

              return this.authService.refreshToken().pipe(
                switchMap(() => {
                  this.changeTokenIsRefreshingState(false);
                  return next.handle(this.handleRequest(req));
                }),
                catchError( (err) => {
                  if(this.router.url.split('/').findIndex( segment => segment.includes('confirm-email') ) != -1){
                    this.authService.clearUser()
                    throw null
                  } else {
                    this.authService.logout();
                    throw err
                  }
                })
              )

            } else {

              return this.tokenRefreshingSubject$.pipe(
                filter(isRefreshing =>isRefreshing === false),
                take(1),
                switchMap(() => {
                  return next.handle(this.handleRequest(req));
                })
              )
            }
          }
          throw error
        })
      )
    }
    return next.handle(req);
  }

  private requestContainsRoutes(request: HttpRequest<any>, routesPart: string[]){

    let isContaining = false;

    for (let routePartIndex = 0; routePartIndex < routesPart.length && !isContaining; routePartIndex++) {
      isContaining = request.url.includes(routesPart[routePartIndex]);
    }
    return isContaining;
  }

  private handleRequest(request: HttpRequest<any>){

    return request.clone({
      headers:  new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem(StorageKey.RIDEAU_TOKEN)}`,
        'Accept-Language': `${this.translateService.currentLang}`,
      })
    });

  }

  private changeTokenIsRefreshingState(isRefreshing: boolean){
    this.tokenIsRefreshing = isRefreshing;
    this.tokenRefreshingSubject$.next(isRefreshing);
  }
}
