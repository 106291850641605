import { Network } from './../../../concepts/organization/model/organization.model';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SelectedFilter } from 'src/app/shared/model/list-item.model';
import { NetworkService } from 'src/app/concepts/organization/services/network.service';
import { TranslateService } from '@ngx-translate/core';
import { BottinSearchData } from 'src/app/data-layer';
import { ResidencyAvailability, ResidencyType } from '@app/concepts/venue/model/venue.model';
import { VenueType } from '@app/concepts/venue/enums/venue-type.enum';
import { BooleanUtils } from '@app/shared/utils/boolean-utils';
import { FloatNumberPipe } from '@app/shared/pipes/float-number.pipe';
@Component({
    selector: 'app-bottin-advanced-search',
    templateUrl: './bottin-advanced-search.component.html',
    styleUrls: ['./bottin-advanced-search.component.scss']
})
export class BottinAdvancedSearchComponent implements OnInit {
    @Output()
    filtersChange = new EventEmitter<{ filters: SelectedFilter[]; dataLayer: BottinSearchData }>();

    @Input()
    set leftSideSelectedFilter(value: SelectedFilter) {
        if (!this.leftSideSelectedFilterValue || value.field !== this.leftSideSelectedFilterValue.field) {
            this.leftSideSelectedFilterValue = value;
            this.onlyOrgas = false;
            this.onlyVenues = false;
            this.onlyResidency = false;
            if (value.field === 'venuesOnly') {
                if (value.value === VenueType.RESIDENCY) {
                    this.onlyResidency = true;
                } else if (value.value === VenueType.DEFAULT) {
                    this.onlyVenues = true;
                    this.clearResidencyFilter();
                }
                this.clearMemberFilter();
            } else if (value.field === 'organizationTypeId') {
                this.onlyOrgas = true;
                this.clearCapacityFilters();
                this.getNetworksList();
                this.clearResidencyFilter();
            } else if (value.field === '') {
                this.clearMemberFilter();
                this.clearCapacityFilters();
                this.clearResidencyFilter();
            }
        }
        if (!this.leftSideSelectedFilterValue || (value.field == this.leftSideSelectedFilterValue.field && value.field == 'venuesOnly')) {
            this.leftSideSelectedFilterValue = value;
            this.onlyResidency = value.value === 2;
            this.clearMemberFilter();
            this.clearResidencyFilter();
        }
    }
    readonly residencyTypes = Object.values(ResidencyType).filter((x) => typeof x === 'number');
    readonly residencyAvailabilities = Object.values(ResidencyAvailability).filter((x) => typeof x === 'number');
    leftSideSelectedFilterValue: SelectedFilter;

    //List de réseaux
    networkList: Network[] = [];

    // flags
    onlyVenues = false;
    onlyOrgas = false;
    onlyResidency = false;
    //modeles
    state: string;
    residencyType: number[];
    disponibilityPeriod: number[];
    // commas are allowed: the value can also be a string
    totalStageWidth: number | string;
    totalSceneDepth: number | string;
    clearHeightLamps: number | string;
    hasAttachmentPoints: boolean;
    hasDanceFloor: boolean;
    city: string;
    memberName: string;
    capacityMin: number;
    capacityMax: number;

    // subjects
    stateSubject: Subject<string> = new Subject();
    citySubject: Subject<string> = new Subject();
    // residency
    residencyTypeSubject: Subject<number[]> = new Subject();
    disponibilityPeriodSubject: Subject<number[]> = new Subject();
    totalStageWidthSubject: Subject<number> = new Subject();
    clearHeightLampsSubject: Subject<number> = new Subject();
    totalSceneDepthSubject: Subject<number> = new Subject();
    hasAttachmentPointsSubject: Subject<number> = new Subject();
    hasDanceFloorSubject: Subject<number> = new Subject();
    memberNameSubject: Subject<string> = new Subject();
    capacityMinSubject: Subject<number> = new Subject();
    capacityMaxSubject: Subject<number> = new Subject();

    // SelectedFilters
    stateFilter: SelectedFilter = {
        field: 'state',
        value: null
    };
    cityFilter: SelectedFilter = {
        field: 'city',
        value: null
    };
    memberNameFilter: SelectedFilter = {
        field: 'memberName',
        value: null
    };
    residencyTypeFilter: SelectedFilter = {
        field: 'residencyType',
        value: null
    };
    disponibilityPeriodFilter: SelectedFilter = {
        field: 'disponibilityPeriod',
        value: null
    };
    totalStageWidthFilter: SelectedFilter = {
        field: 'totalStageWidth',
        value: null
    };
    totalSceneDepthFilter: SelectedFilter = {
        field: 'totalSceneDepth',
        value: null
    };
    clearHeightLampsFilter: SelectedFilter = {
        field: 'clearHeightLamps',
        value: null
    };
    hasAttachmentPointsFilter: SelectedFilter = {
        field: 'hasAttachmentPoints',
        value: null
    };
    hasDanceFloorFilter: SelectedFilter = {
        field: 'hasDanceFloor',
        value: null
    };
    capacityMinFilter: SelectedFilter = {
        field: 'capacityMin',
        value: null
    };
    capacityMaxFilter: SelectedFilter = {
        field: 'capacityMax',
        value: null
    };
    networkFilter: SelectedFilter = {
        field: 'organizationHeadId',
        value: null
    };

    public lang: string;
    private floatNumberPipe: FloatNumberPipe = new FloatNumberPipe();
    constructor(private networkService: NetworkService, private translate: TranslateService) {}

    ngOnInit(): void {
        this.lang = this.translate.currentLang;

        this.pipeDebounce(this.stateSubject).subscribe((value) => {
            const trimed = value.trim();
            this.stateFilter.value = trimed.length > 0 ? trimed : null;
            this.handleChange();
        });

        this.pipeDebounce(this.citySubject).subscribe((value) => {
            const trimed = value.trim();
            this.cityFilter.value = trimed.length > 0 ? trimed : null;
            this.handleChange();
        });

        this.pipeDebounce(this.memberNameSubject).subscribe((value) => {
            const trimed = value.trim();
            this.memberNameFilter.value = trimed.length > 0 ? trimed : null;
            this.handleChange();
        });

        this.pipeDebounce(this.residencyTypeSubject).subscribe((value) => {
            this.residencyTypeFilter.value = value;
            this.handleChange();
        });

        this.pipeDebounce(this.disponibilityPeriodSubject).subscribe((value) => {
            this.disponibilityPeriodFilter.value = value;
            this.handleChange();
        });

        this.pipeDebounce(this.totalStageWidthSubject)
            .pipe(filter(this.filterWithPattern))
            .subscribe((value) => {
                this.totalStageWidthFilter.value = value;
                this.handleChange();
            });

        this.pipeDebounce(this.totalSceneDepthSubject)
            .pipe(filter(this.filterWithPattern))
            .subscribe((value) => {
                this.totalSceneDepthFilter.value = value;
                this.handleChange();
            });

        this.pipeDebounce(this.clearHeightLampsSubject)
            .pipe(filter(this.filterWithPattern))
            .subscribe((value) => {
                this.clearHeightLampsFilter.value = value;
                this.handleChange();
            });

        this.pipeDebounce(this.hasAttachmentPointsSubject).subscribe((value) => {
            this.hasAttachmentPointsFilter.value = value;
            this.handleChange();
        });

        this.pipeDebounce(this.hasDanceFloorSubject).subscribe((value) => {
            this.hasDanceFloorFilter.value = value;
            this.handleChange();
        });

        this.pipeDebounce(this.capacityMinSubject).subscribe((value) => {
            this.capacityMinFilter.value = value;
            this.handleChange();
        });

        this.pipeDebounce(this.capacityMaxSubject).subscribe((value) => {
            this.capacityMaxFilter.value = value;
            this.handleChange();
        });
        this.getSessionFilters();
    }

    private pipeDebounce<T>(subject: Subject<T>): Observable<T> {
        return subject.pipe(debounceTime(300), distinctUntilChanged());
    }

    handleChange() {
        const filters: SelectedFilter[] = [];
        const dataLayer: BottinSearchData = {};

        if (this.stateFilter.value) {
            filters.push(this.stateFilter);
            dataLayer.province = this.stateFilter.value;
        }
        if (this.cityFilter.value) {
            filters.push(this.cityFilter);
            dataLayer.ville = this.cityFilter.value;
        }
        if (this.memberNameFilter.value) {
            filters.push(this.memberNameFilter);
            dataLayer.membre = this.memberNameFilter.value;
        }
        if (this.capacityMinFilter.value || this.capacityMinFilter.value == 0) {
            filters.push(this.capacityMinFilter);
            dataLayer.capacite = { min: this.capacityMinFilter.value };
        }
        if (this.capacityMaxFilter.value) {
            filters.push(this.capacityMaxFilter);
            dataLayer.capacite = { ...(dataLayer.capacite || {}), max: this.capacityMaxFilter.value };
        }
        if (this.networkFilter.value) {
            filters.push(this.networkFilter);
            if (this.networkList.length) {
                dataLayer.reseau = this.networkList.find((network) => network.id === this.networkFilter.value).getTranslatedProperty('fr', 'name');
            }
        }
        if (this.disponibilityPeriodFilter.value) {
            filters.push(this.disponibilityPeriodFilter);
            dataLayer.disponibilityPeriod = this.disponibilityPeriodFilter.value;
        }
        if (this.residencyTypeFilter.value) {
            filters.push(this.residencyTypeFilter);
            dataLayer.residencyType = this.residencyTypeFilter.value;
        }
        if (this.totalStageWidthFilter.value) {
            filters.push(this.totalStageWidthFilter);
            dataLayer.totalStageWidth = this.totalStageWidthFilter.value;
        }
        if (this.totalSceneDepthFilter.value) {
            filters.push(this.totalSceneDepthFilter);
            dataLayer.totalSceneDepth = this.totalSceneDepthFilter.value;
        }
        if (this.clearHeightLampsFilter.value) {
            filters.push(this.clearHeightLampsFilter);
            dataLayer.clearHeightLamps = this.clearHeightLampsFilter.value;
        }
        if (this.hasAttachmentPointsFilter.value) {
            filters.push(this.hasAttachmentPointsFilter);
            dataLayer.hasAttachmentPoints = this.hasAttachmentPointsFilter.value;
        }
        if (this.hasDanceFloorFilter.value) {
            filters.push(this.hasDanceFloorFilter);
            dataLayer.hasDanceFloor = this.hasDanceFloorFilter.value;
        }

        this.filtersChange.emit({ filters, dataLayer });
        this.setSessionFilters(filters);
    }

    setSessionFilters(filters: SelectedFilter[]): void {
        sessionStorage.setItem('bottinAdvFilters', JSON.stringify(filters));
    }

    getSessionFilters(): void {
        const ssFilters = JSON.parse(sessionStorage.getItem('bottinAdvFilters'));
        if (!ssFilters) return;
        for (const filter of ssFilters) {
            if (filter.field && (filter.value || filter.value == 0))
                switch (filter.field) {
                    case 'state':
                        this.state = filter.value;
                        this.stateFilter.value = filter.value;
                        break;
                    case 'city':
                        this.city = filter.value;
                        this.cityFilter.value = filter.value;
                        break;
                    case 'residencyType':
                        this.residencyType = filter.value;
                        this.residencyTypeFilter.value = filter.value;
                        break;
                    case 'disponibilityPeriod':
                        this.disponibilityPeriod = filter.value;
                        this.disponibilityPeriodFilter.value = filter.value;
                        break;
                    case 'totalStageWidth':
                        this.totalStageWidth = filter.value;
                        this.totalStageWidthFilter.value = filter.value;
                        break;
                    case 'totalSceneDepth':
                        this.totalSceneDepth = filter.value;
                        this.totalSceneDepthFilter.value = filter.value;
                        break;
                    case 'clearHeightLamps':
                        this.clearHeightLamps = filter.value;
                        this.clearHeightLampsFilter.value = filter.value;
                        break;
                    case 'hasAttachmentPoints':
                        this.hasAttachmentPoints = filter.value;
                        this.hasAttachmentPointsFilter.value = filter.value;
                        break;
                    case 'hasDanceFloor':
                        this.hasDanceFloor = filter.value;
                        this.hasDanceFloorFilter.value = filter.value;
                        break;
                    case 'memberName':
                        this.memberName = filter.value;
                        this.memberNameFilter.value = filter.value;
                        break;
                    case 'capacityMin':
                        this.capacityMin = filter.value;
                        this.capacityMinFilter.value = filter.value;
                        break;
                    case 'capacityMax':
                        this.capacityMax = filter.value;
                        this.capacityMaxFilter.value = filter.value;
                        break;
                    case 'organizationHeadId':
                        this.networkFilter.value = filter.value;
                        break;
                }
        }

        this.handleChange();
    }

    clearMemberFilter(): void {
        this.memberNameFilter.value = null;
        this.memberName = null;
    }

    clearResidencyFilter(): void {
        this.residencyTypeFilter.value = null;
        this.residencyType = null;
        this.disponibilityPeriodFilter.value = null;
        this.disponibilityPeriod = null;
        this.totalSceneDepthFilter.value = null;
        this.totalSceneDepth = null;
        this.totalStageWidthFilter.value = null;
        this.totalStageWidth = null;
        this.clearHeightLampsFilter.value = null;
        this.clearHeightLamps = null;
        this.hasAttachmentPointsFilter.value = null;
        this.hasAttachmentPoints = null;
        this.hasDanceFloorFilter.value = null;
        this.hasDanceFloor = null;
    }

    clearCapacityFilters(): void {
        this.capacityMinFilter.value = null;
        this.capacityMaxFilter.value = null;
        this.capacityMin = null;
        this.capacityMax = null;
    }

    getNetworksList(): void {
        this.networkService.getAllNetworks().subscribe((res) => (this.networkList = res));
    }

    public setResidencyType = (newValue: number[]): void => this.residencyTypeSubject.next(newValue);
    public setResidencyAvailabilityPeriod = (newValue: number[]): void => this.disponibilityPeriodSubject.next(newValue);
    // since the values can be strings, we need to convert the values to number before they're sent to the back end
    // ex: '10,5' -> 10.5
    public setResidencyClearHeightLamps = (newValue: number | string): void =>
        this.clearHeightLampsSubject.next(this.transformValueToNumericFormat(newValue) as number);
    public setResidencyTotalSceneDepth = (newValue: number | string): void =>
        this.totalSceneDepthSubject.next(this.transformValueToNumericFormat(newValue) as number);
    public setResidencyTotalStageWidth = (newValue: number | string): void =>
        this.totalStageWidthSubject.next(this.transformValueToNumericFormat(newValue) as number);
    public setResidencyHasAttachmentPoint = (newValue: boolean): void => this.hasAttachmentPointsSubject.next(BooleanUtils.convertToNumber(newValue) as number);
    public setResidencyHasDanceFloor = (newValue: boolean): void => this.hasDanceFloorSubject.next(BooleanUtils.convertToNumber(newValue));
    public getResidencyAvailabilityById = (id: number): string => this.translate.instant(`RESIDENCY-FORM.DISPONIBILITY-FIELD-${id}`);
    public getResidencyTypeById = (id: number): string => this.translate.instant(`RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-${id}`);

    private filterWithPattern = <T>(value: T) => {
        if (!value) {
            return true;
        }

        return /^\d+(\.\d+)?(,\d+)?$/gm.test(value.toString());
    };

    private transformValueToNumericFormat = (value: number | string, regExp: string | RegExp = /,/s): number => {
        return this.floatNumberPipe.transform<number>(value, ['dot', regExp]);
    };
}
