<div nz-row>
  <div nz-col
       nzMd="16">
    <h2 class="mb-1">{{ 'SHOWCASES' | translate}}</h2>
  </div>
  <div class="text-md-right"
       nz-col
       nzMd="8">
    <button type="button"
            class="btn"
            [disabled]="collapseCreateSection"
            style="min-width: 275px; padding: 1px"
            (click)="!collapseCreateSection ? addVitrine() : null">
      <i class="icon-plus icon-for-btn"></i>
      {{'AJOUTER-VITRINE' | translate}}
    </button>
  </div>
</div>

<!--FORMULAIRE D'AJOUT D'UNE VITRINE-->
<ng-container *ngIf="collapseCreateSection">
  <app-activity-vitrines-form [meetingVitrinesList]="meetingVitrineList"
                              (saveChanges)="saveNewVitrine($event)"
                              (cancel)="(this.collapseCreateSection = false)"></app-activity-vitrines-form>
</ng-container>

<!--LISTE DE VITRINES-->
<ng-container *ngIf="activityVitrineList.length; else nothingToShow">
  <ng-container *ngFor="let activityVitrine of activityVitrineList">

    <app-item-row [item]="activityVitrine.listItem"
                  [itemRowType]="itemType"
                  [enableEditing]="true"
                  [enableRemoving]="true"
                  (modifyItem)="toggleVitrineForm($event)"
                  [classList]="['d-grid', 'align-items-center']"
                  (removeItem)="removeItem($event)"></app-item-row>

    <ng-container *ngIf="showForm(activityVitrine.vitrine.id)">
      <app-activity-vitrines-form [selectedVitrine]="activityVitrine.vitrine"
                                  [meetingVitrinesList]="meetingVitrineList"
                                  (saveChanges)="updateActivityVitrine($event)"
                                  (cancel)="toggleHandler()"></app-activity-vitrines-form>
    </ng-container>

  </ng-container>
</ng-container>

<!--FALLBACK SI PAS DE VITRINES-->
<ng-template #nothingToShow>
  <p>{{'AUCUNE-VITRINE-ACTIVITE' | translate }}</p>
</ng-template>
