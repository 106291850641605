import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, catchError, map } from 'rxjs/operators';
import { getVitrineAdditionalMaterialTypeBaseFormSelectItemList } from 'src/app/concepts/vitrine/enums/vitrine-additional-material-type.enum';
import { Globals } from 'src/app/_configs/globals';
import { ItemRowFile } from '../../model/item-row-file.model';
import { FormItemSelectService } from '../../services/form.row.select.service';
import { RideauNotificationService } from '../../services/rideau-notification.service';
import { ItemUploadRepeaterService } from './item-upload-repeater.service';
@Component({
    selector: 'app-item-upload-repeater',
    templateUrl: './item-upload-repeater.component.html',
    styleUrls: ['./item-upload-repeater.component.scss']
})
export class ItemUploadRepeaterComponent implements OnInit {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() btnText: string;
    @Input() action: string;
    @Input() isVitrine?: boolean;
    @Input() items: ItemRowFile[] = [];
    @Input() disabled?: boolean;
    @Input() displayImage?: boolean;
    @Input() shouldDisplayImage?: boolean; // not base of the file type
    @Input() maxFileSize?: number = this.globals.maxImageSize;
    @Input() acceptedFileTypes: string[];
    @Input() name: string;

    public items$: Observable<ItemRowFile[]> = this.itemUploadRepeaterService.items$;
    public itemsLength$: Observable<boolean> = this.itemUploadRepeaterService.items$.pipe(map((items: ItemRowFile[]) => (!items ? false : !!items.length)));
    public initialItems$ = this.formItemSelectService.initialItems$;
    public remainingItems$ = this.formItemSelectService.remainingItems$;
    public readonly selectedItem$ = this.formItemSelectService.selectedItem$;
    public readonly isButtonDisabled$: Observable<boolean> = this.itemUploadRepeaterService.addButtonDisableState$;
    public readonly shouldDisableButtonFromUploadFiles$: Observable<boolean> = this.itemUploadRepeaterService.shouldDisableButtonFromUploadFiles$;
    private destroyed: Subject<void> = new Subject<void>();

    constructor(
        private globals: Globals,
        private notification: RideauNotificationService,
        private translate: TranslateService,
        private formItemSelectService: FormItemSelectService,
        private itemUploadRepeaterService: ItemUploadRepeaterService
    ) {}

    ngOnInit(): void {
        this.itemUploadRepeaterService.setInitialFileTypeItems(this.isVitrine && getVitrineAdditionalMaterialTypeBaseFormSelectItemList());
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
        this.itemUploadRepeaterService.reset({ resetInitialTypes: false });
    }

    public addRowHandler = (): void => {
        this.itemUploadRepeaterService
            .addRow({ param: this.name, action: this.action })
            .pipe(
                takeUntil(this.destroyed),
                catchError((error: string) => {
                    throw error;
                })
            )
            .subscribe(
                (isDisabled: boolean) => this.itemUploadRepeaterService.setButtonDisabledState(isDisabled),
                (error) => {
                    this.notification.error(this.translate.instant(error));
                }
            );
    };

    public selectedTypeIdHandler = (typeId: number): void => {
        this.itemUploadRepeaterService.setLastSelectedTypeId(typeId);
    };

    public removedRowHandler(row: ItemRowFile): void {
        this.itemUploadRepeaterService.removeRow(row).subscribe(
            ({ isDisabled }) => this.itemUploadRepeaterService.setButtonDisabledState(isDisabled),
            (err) => console.error(err)
        );
    }

    public uploadedHandler = ({ fileUploaded, itemId, item }: { fileUploaded: boolean; itemId: string; item: ItemRowFile }): void => {
        this.itemUploadRepeaterService.setButtonDisabledState(fileUploaded);
        this.itemUploadRepeaterService.setUploadedFile({ item, itemId });
    };
}
