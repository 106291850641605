import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

export function minutesToTime(min: number = 0): { h: number; m: number } {
    return {
        h: Math.floor(min / 60),
        m: min % 60
    };
}

export function timeToMinutes({ hours, minutes }: { hours: number, minutes: number }): number {
    function isNullOrUndefined(value: number): boolean {
        return value === undefined || value === null;
    }
    return (isNullOrUndefined(hours) ? 0 : hours) * 60 + (isNullOrUndefined(minutes) ? 0 : minutes);
}

export function formatDate(d: Date): string {
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [ year, month, day ].join('-');
}

export function getTimeFromTimestamp(date: Date): string {
    return new Date(date)
        .toLocaleString('fr-CA', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'America/Montreal'
        })
        .replace(/\s/g, '');
}

export function getTimestampFromDate(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
}

export function ToSameDay(date: Date): Date {
    const res = new Date();
    res.setTime(0);

    res.setHours(date.getHours());
    res.setMinutes(date.getMinutes());
    res.setSeconds(date.getSeconds());

    return res;
}

export function getRemainingDays(dayStart: Date, dayEnd: Date, translate: TranslateService): string {
    const today = new Date();
    if (dayStart < today && today < dayEnd) {
        const difference = dayEnd.getTime() - today.getTime();
        return Math.round(difference / (1000 * 3600 * 24)).toString() + ' ' + translate.instant('JOURS-RESTANT');
    } else if (dayStart > today) {
        const options = { month: 'short', day: 'numeric' } as Intl.DateTimeFormatOptions;
        return (
            translate.instant('FORM.DU') +
            ' ' +
            dayStart.toLocaleDateString(translate.currentLang, options) +
            ' ' +
            translate.instant('FORM.AU') +
            ' ' +
            dayEnd.toLocaleDateString(translate.currentLang, options)
        );
    } else {
        return translate.instant('STATE.TERMINE');
    }
}
export function getDayStart(date: Date) {
    const dayStart = new Date(date);
    dayStart.setHours(0, 0, 0, 0);
    return dayStart;
}
export function getDayEnd(date: Date) {
    const dayEnd = getDayStart(date);
    dayEnd.setDate(dayEnd.getDate() + 1);
    return dayEnd;
}

export function getClosestDay(date: Date) {
    const _date = typeof date === 'string' ? new Date(date) : date;
    const dayStart = getDayStart(date);
    const dayEnd = getDayEnd(date);
    const deltaStart = _date.getTime() - dayStart.getTime();
    const deltaEnd = dayEnd.getTime() - _date.getTime();
    return deltaStart < deltaEnd ? dayStart : dayEnd;
}

/**
 * This function converts a string or a Date object into a Date object.
 * @param {string | Date} hours - The input hours which can be a string or a Date object.
 * @returns {Date | null} - Returns a Date object or null.
 */
export function setHoursToDate(hours: string | Date) {
    if (hours instanceof Date) {
        // If 'hours' is a Date object, convert it to a Date object using moment.js and return.
        return moment(hours).toDate();
    } else if (hours === 'null') {
        // If 'hours' is the string 'null', return null.
        return null;
    } else if (typeof hours === 'string') {
        // If 'hours' is a string like '02:00:55', convert it to a Date object using moment.js and return.
        // The date is set to '1970-01-01' by default.
        return moment(`1970-01-01 ${hours}`, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
    // If 'hours' is not a valid date or string, return null.
    return null;
}
