import { Translatable } from 'src/app/shared/model/translatable.model';

export class Classification extends Translatable {
    id: number;
    parentId: number;
    level: number;
    desc: string;
    constructor(datas: Record<string, any>) {
        super();
        this.id = datas['id'];
        this.parentId = datas['parentId'];
        this.level = datas['level'];
        this.desc = datas['desc'];
        this.trans = datas['trans'];
      }
}
