import { Permission } from 'src/app/shared/enums/roles.enum';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { ShowFavorites } from 'src/app/concepts/show/model/show.model';
import {
  Organization,
  Network,
} from 'src/app/concepts/organization/model/organization.model';
import { TranslateService } from '@ngx-translate/core';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import {AccountService} from "../../../../concepts/account/services/account.service";
import {Globals} from "../../../../_configs/globals";

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  currentLang = '';

  @Input() image: string;
  @Input() background?: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttonText: string;
  @Input() buttonLink: string;
  @Input() buttonIsLoading: string;
  @Input() categories: string[];
  @Input() buttonType: string;
  // inputs pour permissions sur bouton
  @Input() permission?: Permission;
  @Input() user?: PxUser;
  @Input() organizationId: number;
  // @Input() userNetworks: Network[];
  @Input() userNetworkIds: number[];
  @Input() orgaType: OrganizationTypes;
  @Input() shouldBeApproved = true;
  // inputs pour le theming
  @Input() isDashboardPage = false;
  @Input() isShowPage = false;
  @Input() isWithImagePage = false;
  @Input() isMainPage = false;
  @Input() withNoMargin = false;
  @Input() useExport = false;
  @Input() useFavorites = false;
  @Input() favorites: EventEmitter<ShowFavorites>;
  @Input() userNetworks: Network[] = [];
  // pour pouvoir associer un callback au click au lieu d'une navigation
  @Output() buttonClicked = new EventEmitter();
  @Output() onSaveFavorites = new EventEmitter();

  isRotate = false;
  isBtnActive = false;

  userFavorite = false;
  organizationFavorite = false;
  networkFavorite: boolean[] = [false, false];

  networkIds: number[] = [];
  network: Network;
  networks: [];
  organization: Organization;
    nzClickHide = false;
  constructor(
    private translate: TranslateService,
    private accountService: AccountService,
    private globals: Globals
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    if (this.favorites) {
      this.favorites.subscribe((showFavorites) => {
        this.userFavorite = !!showFavorites.userFavorite;
        this.organizationFavorite = !!showFavorites.organizationFavorite;

        for (let j = 0; j < this.userNetworks.length; j++) {
          this.networkFavorite[j] = showFavorites.networkIds.includes(
            this.userNetworks[j].id
          );
        }

        this.network = showFavorites.network ? showFavorites.network : null;
        this.organization = showFavorites.organization
          ? showFavorites.organization
          : null;
      });
    }
  }

    updateFavorites(): void {
        this.isRotate = !this.isRotate;
        this.isBtnActive = !this.isBtnActive
    }

  saveFavorite(): void {
    function checkTrue(value) {
      return value === true;
    }

    this.networkIds = [];

    for (let i = 0; i < this.networkFavorite.length; i++) {
      this.networkFavorite[i]
        ? this.networkIds.push(this.userNetworks[i].id)
        : '';
    }
    const showFavorites = new ShowFavorites({
      userId: this.accountService.getUserId(),
      organization: this.accountService.getCurrentCtxOrganizationId(),
      networkIds: this.networkIds,
      userFavorite: this.userFavorite ? 1 : 0,
      organizationFavorite: this.organization
        ? this.organizationFavorite
          ? 1
          : 0
        : null,
      networkFavorite: this.networkFavorite.some(checkTrue) ? 1 : 0,
    });

    this.onSaveFavorites.emit(showFavorites);
  }
}
