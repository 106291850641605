import { Routes } from '@angular/router';
import { ProductModifyComponent } from './components/product-modify/product-modify.component';
import { ProductComponent } from './components/product.component';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';
import { Permission } from 'src/app/shared/enums/roles.enum';

const SCENE_PRO_ID = 1;

export const ProductRoutes: Routes = [
  {
    path: '',
    component: ProductComponent,
    data: {
      breadcrumb: [SiteConcepts.PRODUIT],
      appPermission: Permission.CAN_EDIT,
      organizationId: SCENE_PRO_ID,
    },
  },
  {
    path: ':productId/modify/:page',
    component: ProductModifyComponent,
    data: {
      breadcrumb: [SiteConcepts.PRODUIT, SiteConcepts.SINGLE],
      appPermission: Permission.CAN_EDIT,
      organizationId: SCENE_PRO_ID,
    },
  },
  {
    path: ':productId/modify',
    component: ProductModifyComponent,
    data: {
      breadcrumb: [SiteConcepts.PRODUIT, SiteConcepts.SINGLE],
      appPermission: Permission.CAN_EDIT,
      organizationId: SCENE_PRO_ID,
    },
  },
];
