import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationTypes } from '@app/concepts/organization/enums/organization-types.enum';
import { Organization } from '@app/concepts/organization/model/organization.model';
import { OrganizationService } from '@app/concepts/organization/services/organization.service';

@Component({
  selector: 'app-organization-promotion',
  templateUrl: './organization-promotion.component.html',
})
export class OrganizationPromotionComponent {
  private orgId: number;
  isProducer:boolean;

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
  ) {}
  ngOnInit(): void {
    this.orgId = this.route.snapshot.params['organizationId'];
    this.organizationService.getOrganization(this.orgId).subscribe(
      (data: Organization) => {
        this.isProducer = data.types.some((type) => type === OrganizationTypes.IS_PRODUCTEUR);
      }
    );
  }
}
