<div class="ConnectionContainer  h-100">

  <div class="ConnectionContainer__container container">

    <!--  LOGO  -->
    <div class="ConnectionContainer__logo-section" >
      <img src="../../../../assets/images/logo-scene-pro.svg" alt="" [routerLink]='[ "/"+translateService.currentLang , "login"]'>
      <img class="ConnectionContainer__logo-section-pattern" src="../../../../assets/images/Bitmap.png" alt="">
    </div>
    <!-- END LOGO  -->

    <!-- FORM SECTION -->
    <div class="ConnectionContainer__form-section ">

      <div class="row justify-content-center">
        <h1 class="ConnectionContainer__title col-lg-4 col-sm-12 col-md-8">
         {{ title }}
        </h1>

      </div>



      <ng-content></ng-content>


    </div>

  </div>
</div>
