import { Pipe, PipeTransform } from '@angular/core';

/**
 * Prends en entrée une url de site web externe à l'application.
 *  - Renvoie une chaine vide si url null.
 *  - Si url contient 'http://', on renvoie tel quel
 *  - Sinon on rajoute 'http://' devant
 *
 */
@Pipe({
  name: 'externalLink',
})
export class ExternalLink implements PipeTransform {
  transform(value: string): string {
    if (value) {
      if (
        value.indexOf('http://') > -1 ||
        value.indexOf('https://') > -1 ||
        value.indexOf('file://') > -1
      ) {
        return value;
      } else {
        return 'https://' + value;
      }
    } else {
      return '';
    }
  }
}
