import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    HostBinding,
    ViewChild,
    TemplateRef,
    ViewContainerRef,
    ElementRef,
    HostListener,
    Output,
    EventEmitter
} from '@angular/core';
import { Program } from 'src/app/concepts/program/model/program.model';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProgramStatus } from 'src/app/concepts/program/model/program-status.model';
import { ProgramRouteData } from 'src/app/concepts/program/program-route-resolvers';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { ShowStatus } from '@app/concepts/show/enums/show-status.enum';

@Component({
    selector: 'app-tour-show-program',
    templateUrl: './tour-show-program.component.html',
    styleUrls: ['./tour-show-program.component.scss']
})
export class TourShowProgramComponent implements OnInit, OnDestroy {
    @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<unknown>;
    @Input() program: Program;
    @Output() handleActionProgram: EventEmitter<{ type: string; program: Program }> = new EventEmitter<{
        type: string;
        program: Program;
    }>();
    public readonly programStatus = ProgramStatus;
    private readonly showStatusObject = ShowStatus;
    routeData: ProgramRouteData;

    @HostBinding('class.confirmed') get confirmed(): boolean {
        return this.isProgramConfirmed;
    }

    @HostBinding('class.provisory') get provisory(): boolean {
        return this.isProgramProvisory;
    }

    @HostBinding('class.expired') get expired(): boolean {
        return this.isShowExpired;
    }

    @HostBinding('class.cancel') get cancel(): boolean {
        return this.isProgramCancelled;
    }

    @HostBinding('class.exploratory') get exploratory(): boolean {
        return this.isProgramExploratory;
    }

    @HostBinding('class.isNotInTour') get isNotInTour(): boolean {
        return !this.isInTour;
    }

    @HostBinding('class.disabled') get isDisabled(): boolean {
        return this.isDisabledForUser;
    }

    @HostListener('click') onClick(): void {
        this.clickedHandler();
    }

    public get canDisplayStatusName(): boolean {
        return this.isProgramConfirmed || this.isProgramCancelled || this.isProgramProvisory;
    }
    // https://logient.atlassian.net/browse/RIDEAU-1799
    public get statusName(): string {
        return this.translateService.instant(`PROGRAMME-STATUS-${this.programStatus[this.program.statusId]}`)
    }

    private get isInTour(): boolean {
        return this.program.tourId && this.routeData.tour.id === this.program.tourId;
    }

    public get isProgramConfirmed(): boolean {
        return this.program.statusId === this.programStatus.Confirmed;
    }

    private get isProgramCancelled(): boolean {
        return this.program.statusId === this.programStatus.Canceled;
    }

    public get isProgramProvisory(): boolean {
        return this.program.statusId === this.programStatus.Provisory;
    }

    private get isProgramExploratory(): boolean {
        return this.program.statusId === ProgramStatus.Exploratory;
    }

    private get isTourAdmin(): boolean {
        return this.routeData.tourAdmin;
    }

    private overlay: OverlayRef;
    private static overlay: OverlayRef;
    private destroyed: Subject<void> = new Subject();
    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly elementRef: ElementRef,
        private readonly overlayService: Overlay,
        readonly translateService: TranslateService
    ) { }

    ngOnInit(): void {
        (this.activatedRoute.data as Observable<ProgramRouteData>).pipe(takeUntil(this.destroyed)).subscribe((routeData) => {
            this.routeData = routeData;
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
        if (TourShowProgramComponent.overlay && TourShowProgramComponent.overlay === this.overlay) {
            this.closeOverlay();
        }
    }

    actionProgram(type: string): void {
        this.handleActionProgram.emit({ type, program: this.program });
        this.closeOverlay();
    }

    closeOverlay(): void {
        TourShowProgramComponent.overlay.detach();
        TourShowProgramComponent.overlay.dispose();
        TourShowProgramComponent.overlay = null;
        this.overlay = null;
    }

    public get isShowExpired(): boolean {
        if (!this.program.show) {
            return false;
        }
        return this.program.show.statusId === this.showStatusObject.EXPIRE;
    }

    private openPortalModal(): void {
        const portal = new TemplatePortal(this.actionsTemplate, this.viewContainerRef, { $implicit: this.program });
        TourShowProgramComponent.overlay = this.overlay = this.overlayService.create({
            scrollStrategy: this.overlayService.scrollStrategies.reposition(),
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top'
                    }
                ])
        });
        TourShowProgramComponent.overlay.attach(portal);
    }

    public navigateToProgramPage(): void {
        this.router.navigate([this.translateService.currentLang, 'programmations', this.program.id]);
    }

    private clickedHandler = (): void => {
        if (this.isDisabledForUser) {
            return;
        }

        if (TourShowProgramComponent.overlay && TourShowProgramComponent.overlay === this.overlay) {
            this.closeOverlay();
            return;
        }

        if (TourShowProgramComponent.overlay) {
            TourShowProgramComponent.overlay.detach();
            TourShowProgramComponent.overlay.dispose();
            TourShowProgramComponent.overlay = null;
        }

        if (this.isShowExpired) {
            return;
        }
        // https://logient.atlassian.net/browse/RIDEAU-1799
        this.openPortalModal();
    };

    private get isDisabledForUser() {
        return (!this.isTourAdmin && this.isProgramExploratory) || !this.isInTour;
    }
}
