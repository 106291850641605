import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NetworkItem } from '../../../concepts/tours/model/network-item.model';
import { Organization } from '../../../concepts/organization/model/organization.model';
import { AccountService } from '../../../concepts/account/services/account.service';
import { Globals } from '@app/_configs/globals';

@Component({
    selector: 'app-network-card',
    templateUrl: './network-card.component.html',
    styleUrls: ['./network-card.component.scss']
})
export class NetworkCardComponent implements OnInit {
    @Input() item: NetworkItem;
    @Input() networks: number[];
    @Input() isNetworkMember: boolean;
    @Input() isSceneProAdmin: boolean;
    public organization: Organization;
    public isNetworkAdmin = false;
    private userOrganizationId: number;

    constructor(private translate: TranslateService, private accountService: AccountService, protected globals: Globals) {}

    ngOnInit(): void {
        this.organization = new Organization(this.item.organization);
        this.userOrganizationId = this.accountService.getCurrentCtxOrganizationId();
        this.isNetworkAdmin = this.userOrganizationId === this.item.organization.id;
    }

    public get organizationName(): string {
        return this.organization.getTranslatedProperty(this.translate.currentLang, 'name');
    }
}
