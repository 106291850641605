import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StandService } from '../../services/stand.service';
import { Stand } from '../../model/stand.model';
import { ListItem } from '../../../../shared/model/list-item.model';
import { StandFormComponent } from '../stand-create/stand-form/stand-form.component';
import { NzModalService } from 'ng-zorro-antd';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { Globals } from '../../../../_configs/globals';
import { LocalizeRouterService } from 'localize-router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-stand-list',
    templateUrl: './stand-list.component.html',
    styleUrls: [ './stand-list.component.scss' ]
})
export class StandListComponent implements OnInit {
    @Input() meetingId: number;
    @Input() isAdminView: boolean;
    @Input() meetingOwnerOrgId: number;
    @Input() reloadStandList: EventEmitter<any> = new EventEmitter<any>();
    @Output() standListSize: EventEmitter<any> = new EventEmitter<any>();

    public standList$: Observable<ListItem[]>;
    searchText: string;
    imgFallback: string;
    lang: string = this.translate.currentLang;
    public formGroup: FormGroup = new FormGroup({
        keyword: new FormControl('')
    });
    private standListItems: BehaviorSubject<ListItem[]> = new BehaviorSubject<ListItem[]>([]);
    private filteredStandListItems: BehaviorSubject<ListItem[]> = new BehaviorSubject<ListItem[]>([]);
    private standList: Stand[];
    constructor(
        private globals: Globals,
        private standService: StandService,
        private translate: TranslateService,
        private localizeRouter: LocalizeRouterService,
        private notification: RideauNotificationService,
        private modalService: NzModalService
    ) {
        this.standList$ = this.filteredStandListItems.asObservable();
    }

    ngOnInit(): void {
        this.getMeetingStands();
        this.updateStandListItems();
        this.imgFallback = this.globals.imgFallback;
        this.reloadStandList.subscribe(() => this.getMeetingStands());
        this.filterByKeyword();
        this.updateStandListCount();
    }

    modifyStand = (standId: number): void => {
        const stand = this.standList.filter((stand) => stand.id == standId).pop();

        this.modalService.create({
            nzContent: StandFormComponent,
            nzWidth: '400px',
            nzComponentParams: {
                theStand: stand,
                meetingId: this.meetingId
            },
            // Le callBack OnOk renvoie l'instance du composant
            nzOnOk: async (res: StandFormComponent) => {
                const isUpdated = await res.updateStand();
                if (isUpdated) {
                    this.getMeetingStands();
                    return isUpdated;
                }
                return false;
            }
        });
    };

    removeStand = (standId: number): void => {
        this.standService.deleteStand(standId).subscribe((data) => {
            this.getMeetingStands();
            this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
        });
    };

    private getMeetingStands = (): void => {
        this.standService
            .getStands(this.meetingId)
            .pipe(
                tap(({ stands }) => (this.standList = stands.map((stand) => new Stand(stand)))),
                map(({ stands }) =>
                    stands.map((stand) => {
                        const updatedStand = new Stand(stand);
                        const item = updatedStand.toListItem(this.lang);
                        if (updatedStand.organizationId && !this.isAdminView) {
                            item.itemUrl = `/organization/${stand.organizationId}`;
                        }
                        return item;
                    })
                ),
                map((stands: ListItem[]) => this.standListItems.next(stands))
            )
            .subscribe();
    };

    private filterByKeyword = (): void => {
        this.formGroup
            .get('keyword')
            .valueChanges.pipe(
                map((keyword: string) => {
                    if (!keyword || (keyword && !keyword.length)) {
                        this.filteredStandListItems.next([ ...this.standListItems.value ]);
                        return;
                    }
                    const newValue = keyword.toLocaleLowerCase();
                    const updatedList = [ ...this.standListItems.value ];
                    const values = updatedList.filter(
                        (item: ListItem) => item.itemInfo1.toLocaleLowerCase().includes(newValue) || item.itemTitle.toLocaleLowerCase().includes(newValue)
                    );
                    this.filteredStandListItems.next(values);
                })
            )
            .subscribe();
    };

    private updateStandListItems = (): void => {
        this.standListItems.subscribe((stands: ListItem[]) => this.filteredStandListItems.next(stands));
    };

    private updateStandListCount = (): void => {
        this.filteredStandListItems
            .pipe(
                filter((items: ListItem[]) => !!items),
                map((items: ListItem[]) => items.length)
            )
            .subscribe((count: number) => this.standListSize.emit(count));
    };
}
