<app-page-title [title]="'PROGRAMMATION' | translate">
    <button appButton (click)="manageCalendars()" class="mr-3" [disabled]="displayLock">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                fill-rule="nonzero"
                d="M5 3.5v2H4v10h12v-10h-1v-2h2a1 1 0 0 1 .993.883L18 4.5v12a1 1 0 0 1-.883.993L17 17.5H3a1 1 0 0 1-.993-.883L2 16.5v-12a1 1 0 0 1 .883-.993L3 3.5h2zm3 8v2H6v-2h2zm3 0v2H9v-2h2zm3 0v2h-2v-2h2zm-6-3v2H6v-2h2zm3 0v2H9v-2h2zm3 0v2h-2v-2h2zm-7-7a1 1 0 0 1 .993.883L8 2.5v4a1 1 0 0 1-1.993.117L6 6.5v-4a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 .993.883L14 2.5v4a1 1 0 0 1-1.993.117L12 6.5v-4a1 1 0 0 1 1-1zm-2 2v2H9v-2h2z"
            />
        </svg>
        {{ 'CALENDRIER-GERER-DATE' | translate }}
    </button>
    <button
        appButton
        *ngIf="organization && organization.tourAccess"
        class="mr-3"
        [routerLink]="['/tour', 'new', 'settings'] | localize"
        [disabled]="displayLock && !(organization && organization.tourAccess)"
    >
        <i class="icon-world" aria-hidden="true"></i>
        {{ 'NOUVELLE-TOURNEE' | translate }}
    </button>
    <button appButton (click)="addDate()" class="blue" [disabled]="displayLock">
        <i class="icon-plus" aria-hidden="true"></i>
        {{ 'CALENDRIER-CREER-DATE' | translate }}
    </button>
</app-page-title>

<div class="container my-5">
    <div class="page-calendar" id="calendar">
        <ng-container *ngIf="routeData.paidTours.length || routeData.archivedPaidTours.length">
            <app-tour-manager [class.joined]="routeData.tourAdmin && routeData.tourShows.length > 0"></app-tour-manager>

            <app-selected-tour-manager *ngIf="!routeData.tourAdmin && routeData.tourShows && routeData.tourShows.length"></app-selected-tour-manager>
        </ng-container>

        <app-tour-access-manager *ngIf="routeData.tourAdmin"></app-tour-access-manager>

        <!-- CALENDAR HEADER -->
        <ng-container *ngIf="!displayLock">
            <!-- Header -->
            <app-program-calendar-header
                [activeDate]="date"
                [tour]="routeData.tour"
                (yearChange)="onYearSelect($event)"
                (monthChange)="onMonthSelect($event)"
                (viewChange)="onViewChange($event)"
                (exportCalendarIcs)="exportCalendarIcs($event)"
                (exportCalendarExcel)="exportCalendarExcel($event)"
            ></app-program-calendar-header>
            <!-- END CALENDAR HEADER -->

            <!-- CALENDAR -->
            <ng-container *ngIf="!isListView; else listViewTemplate">
                <app-program-calendar [date]="date"></app-program-calendar>
            </ng-container>
            <!-- END CALENDAR -->

            <!-- LIST VIEW -->
            <ng-template #listViewTemplate>
                <ng-container *ngIf="listData.length; else emptyTemplate">
                    <div class="row">
                        <div class="col-md-12 page-calendar-list">
                            <!-- LIST -->
                            <ng-container *ngFor="let program of listData">
                                <div class="page-calendar-list__item" [class.page-calendar-list__item--grayscale]="isShowExpired(program)">
                                    <!-- date -->
                                    <div class="page-calendar-list__item__date-container border-right">
                                        <span class="page-calendar-list__item__date-container-chip" *ngIf="program.isMatchUpdated || program.isMatchDeleted"></span>
                                        <span class="page-calendar-list__item__date-container__day">{{ program.date.getDate() }}</span>
                                        <span class="page-calendar-list__item__date-container__month text-uppercase">{{ 'MOIS.' + program.date.getMonth() | translate }}</span>
                                    </div>
                                    <!-- Show img -->
                                    <div class="page-calendar-list__item__col page-calendar-list__item__col--avatar page-calendar-list__item__avatar-src">
                                        <span class="page-calendar-list__item__avatar-container">
                                            <img
                                                alt="avatar"
                                                class="img-responsive page-calendar-list__item__avatar-src"
                                                src="{{ program.show ? program.show.image : '../../../assets/images/placeholder-artiste.svg' }}"
                                            />
                                        </span>
                                    </div>
                                    <!-- infos -->
                                    <div class="page-calendar-list__item__infos">
                                        <div class="page-calendar-list__item__col">
                                            <div class="d-sm-flex">
                                                <div class="page-calendar-list__item__col__row-title">
                                                    <div class="page-calendar-list__item__text--title mb-1">
                                                        <a
                                                            class="page-calendar-list__item__text-url"
                                                            [routerLink]="'/programmations/' + program.id | localize"
                                                            [class.page-calendar-list__item__text-url__canceled]="program.statusId === programStatus.Canceled"
                                                            [class.disabled]="isShowExpired(program)"
                                                        >
                                                            {{
                                                                (program.show ? program.show.getTranslatedProperty(translateService.currentLang, 'title') : program.showCustom)
                                                                    | shortenText: 100
                                                            }}
                                                        </a>
                                                        <div>
                                                            <span class="page-calendar-list__item__status page-calendar-list__item__status--{{ programStatus[program.statusId] }}">
                                                                {{ 'PROGRAMME-STATUS-' + programStatus[program.statusId] | translate }}
                                                            </span>
                                                        </div>

                                                        <div *ngIf="isShowExpired(program)" class="page-calendar-list__item__status tag tag--black">
                                                            <span>{{ 'STATUS.OFFRE-EXPIREE' | translate }}</span>
                                                        </div>
                                                    </div>
                                                    <p
                                                        class="page-calendar-list__item__text--subtitle mb-1"
                                                        [class.page-calendar-list__item__text--subtitle__canceled]="program.statusId === programStatus.Canceled"
                                                    >
                                                        {{ getOrganizationName(program) | shortenText: 80 }}
                                                    </p>
                                                </div>
                                                <div class="page-calendar-list__item__col__row-desc">
                                                    <p class="page-calendar-list__item__text mb-1">
                                                        <span>
                                                            {{ program.hour ? formatHour(program.hour) : '' }}
                                                        </span>
                                                        {{ program.venue ? program.venue.getTranslatedProperty(translateService.currentLang, 'name') : program.venueCustom }}
                                                    </p>
                                                    <p class="page-calendar-list__item__text mb-1">
                                                        {{ program.venue && program.venue.address ? program.venue.address.city : '-' }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>

        <!-- ACCESS POPUP -->
        <ng-container *ngIf="displayLock">
            <div class="page-calendar-lock">
                <div class="page-calendar-lock__wrapper">
                    <div class="page-calendar-lock__box">
                        <h2>{{ 'CALENDRIER-PARTAGE' | translate }}</h2>
                        <p>{{ 'CALENDRIER-NO-ACCESS' | translate }}</p>
                        <button nz-button class="btn btn--default mb-3" (click)="payNow()">
                            {{ 'CALENDRIER-ACCEDER' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <app-overlay *ngIf="isResolving">
            <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        </app-overlay>
    </div>
</div>

<ng-template #emptyTemplate>
    <h3 class="my-5 d-flex align-items-center justify-content-center">{{ 'AUCUNE-INFORMATIONS' | translate }}</h3>
</ng-template>
