import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'src/app/concepts/payment/models/transaction.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Globals } from 'src/app/_configs/globals';

@Component({
  selector: 'app-item-row-billing',
  templateUrl: './item-row-billing.component.html',
})
export class ItemRowBillingComponent implements OnInit {
  @Input() bill: Transaction;
  public itemDownloadUrl: string;

  isRideau = false;

  constructor(
    private global: Globals,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.isRideau =
      this.accountService.getCurrentCtxOrganizationId() ===
      this.global.SCENE_PRO_ORGID;
  }
}
