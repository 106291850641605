<div class="row">
    <div class="col-md-12">
        <div class="row mb-4 align-items-center">
            <div class="col-md-6">
                <h2 class="mb-0">{{ creditCards ? ('CARTES-ENREGISTREES' | translate) : ('AUCUNE-CARTE' | translate) }}</h2>
            </div>
            <div class="col-md-6 text-right">
                <button
                    *appPermission="1; objectOrganizationId: organization.id"
                    class="btn"
                    [disabled]="showCreditCardForm"
                    (click)="showCreditCardForm = !showCreditCardForm"
                    style="padding-top: 7px"
                >
                    <i nz-icon aria-hidden="true" nzType="plus" nzTheme="outline" class="pt-1"></i>
                    {{ 'AJOUTER' | translate }}
                </button>
            </div>
        </div>

        <div *ngIf="isSavingCard" class="loader">
            <i nz-icon aria-hidden="true" nzType="loading" nzTheme="outline"></i>
        </div>

        <app-new-credit-card
            *ngIf="showCreditCardForm"
            class="d-block mb-4"
            creditCardFormType="organization"
            [isSavingCard]="isSavingCard"
            (onConfirmCreditCard)="saveCreditCard($event)"
            (onHideForm)="showCreditCardForm = false"
        ></app-new-credit-card>

        <ng-container *ngIf="!isLoading && creditCards; else noCreditCard">
            <div class="item-row item-row--light p-3" *ngFor="let creditCard of creditCards">
                <div class="item-row__col">
                    <p class="item-row__text">************{{ creditCard.ccNumber }}</p>
                </div>
                <!--REMOVE ACTION-->
                <div class="item-row__col item-row__col--auto text-right">
                    <button type="button" class="btn-square" *appPermission="1; objectOrganizationId: organization.id" (click)="removeCard(creditCard.id)">
                        <i class="icon-x"></i>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-template #noCreditCard>
            <div class="item-row">
                <div class="item-row__col">
                    <p class="item-row__text">{{ 'AUCUNE-CARTE-SAUVEGARDEE' | translate }}</p>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<nz-divider></nz-divider>

<h2>{{ 'FACTURES' | translate }}</h2>
<ng-container>
    <div class="row">
        <div class="col-md-12 text-center">
            <div nz-col class="d-flex align-items-center justify-content-center">
                <nz-radio-group class="fiche-filter" [(ngModel)]="currentStatusType">
                    <label class="fiche-filter__radio" nz-radio-button nzValue="TO-PAY" (click)="toggleCotisationsType('TO-PAY')">
                        {{ 'A-PAYER' | translate }}
                    </label>
                    <label class="fiche-filter__radio" nz-radio-button nzValue="PAYED" (click)="toggleCotisationsType('PAYED')">
                        {{ 'Payées' | translate }}
                    </label>
                </nz-radio-group>
            </div>
        </div>
    </div>
    <nz-divider></nz-divider>
    <div class="row mb-5">
        <div class="col-md-12">
            <ng-container *ngFor="let subscription of filteredMembershipBills">
                <app-item-row-billing-subscription [subscription]="subscription" (paid)="onPaid()"></app-item-row-billing-subscription>
                <nz-divider></nz-divider>
            </ng-container>
        </div>
    </div>
</ng-container>

<div class="row">
    <div class="col-md-12">
        <h2>{{ 'HISTORIQUE-DE-PAIEMENT' | translate }}</h2>
        <p>
            Les confirmations sont envoyées à {{ organization.email }}
            <a [routerLink]="['../coordonnees'] | localize">
                <u>{{ 'MODIFIER-COORDONEES' | translate }}</u>
            </a>
        </p>
        <nz-divider></nz-divider>
    </div>
</div>

<app-paginated-list [isLoading]="isLoading" (pageChange)="onPageChange($event)" [pagination]="pagination" [usePagination]="usePagination">
    <ng-container list *ngFor="let bill of paginatedBillingHistory">
        <app-item-row-billing [bill]="bill"></app-item-row-billing>
        <nz-divider></nz-divider>
    </ng-container>
</app-paginated-list>
