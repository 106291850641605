import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { TourEditRouteData } from 'src/app/concepts/tours/tour-edit-route-resolvers';

@Component({
  selector: 'app-edit-tour-nav',
  template: `
    <div nz-col nzMd="6">
      <div class="list-filters">
        <div class="list-filters__box">
          <div nz-menu>
            <a nz-menu-item class="list-filters__item" [routerLink]="'../settings' | localize" routerLinkActive="active">
              {{ 'PARAMETRES-TOURNEE' | translate }}
            </a>
            <a nz-menu-item class="list-filters__item"
              [routerLink]="'../admins' | localize"
              routerLinkActive="active"
              *ngIf="routeData.tour">
              {{ 'ADMINISTRATEURS' | translate }}
            </a>
            <a nz-menu-item class="list-filters__item"
              [routerLink]="'../diffuseurs' | localize"
              routerLinkActive="active"
              *ngIf="routeData.tour">
              {{ 'DIFFUSEURS' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
    `,
  ],
})
export class EditTourNavComponent implements OnInit, OnDestroy {
  routeData: TourEditRouteData;
  subscription = new Subscription();

  constructor(
    readonly activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.subscription.add((this.activatedRoute.data as Observable<TourEditRouteData>).subscribe(routeData => {
      this.routeData = routeData;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
