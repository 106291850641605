import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {OrganizationService} from 'src/app/concepts/organization/services/organization.service';
import {SelectedFilter} from 'src/app/shared/model/list-item.model';
import {ShowAudiences} from '../../../enums/show-audiences.enum';
import {ShowService} from '../../../services/show.service';
import {buildTreesClassifications} from '../../../util/discipline-cascader.util';
import {Organization} from 'src/app/concepts/organization/model/organization.model';
import {Tag} from '../../../../tags/model/tags.model';
import {TagsService} from '../../../../tags/services/tags.service';
import {ShowOfferSearchData, data_layer_cascade_r, CascadeChild} from 'src/app/data-layer';

@Component({
  selector: 'app-show-advanced-search',
  templateUrl: './show-advanced-search.component.html',
  styleUrls: ['./show-advanced-search.component.scss'],
})
export class ShowAdvancedSearchComponent implements OnInit {
  @Input()
  isTourShow?: boolean;

  @Input() searchSessionStorageSuffix: string = '';

  @Output()
  filtersChange = new EventEmitter<{ filters: SelectedFilter[], dataLayer: ShowOfferSearchData, rechercheSauvegardee: boolean }>();

  classificationsListCascade: CascadeChild[] = [];
  discipline: string[] = [];
  disciplineFilter: SelectedFilter = {
    field: 'classificationId',
    value: null,
  };

  interDisciplinaryFilter: SelectedFilter = {
    field: 'multiClassif',
    value: null,
  };

  producer: number[];
  producerSubject: Subject<any> = new Subject();
  producerFilter: SelectedFilter = {
    field: 'organizationId',
    value: null,
  };

  public tags: Tag[];
  selectedTags: number[];
  tagSubject = new Subject<any>();
  tagFilter: SelectedFilter = {
    field: 'tagIds',
    value: null,
  };

  public: number[];
  publicFilter: SelectedFilter = {
    field: 'audienceIds',
    value: null,
  };
  public showAudiences = ShowAudiences;
  public organizations: Organization[];
  public lang = this.translate.currentLang;

  constructor(
    private cdr: ChangeDetectorRef,
    private showService: ShowService,
    private translate: TranslateService,
    private organizationService: OrganizationService,
    private tagService: TagsService
  ) {
  }

  ngOnInit(): void {
    let rechercheSauvegardee = false;

    if (JSON.parse(sessionStorage.getItem(`recherche_avancee_discipline_${this.searchSessionStorageSuffix}`))) {
      this.discipline = JSON.parse(sessionStorage.getItem(`recherche_avancee_discipline_${this.searchSessionStorageSuffix}`));
      rechercheSauvegardee = true;
    }
    if (JSON.parse(sessionStorage.getItem(`recherche_avancee_public_${this.searchSessionStorageSuffix}`))) {
      this.public = JSON.parse(sessionStorage.getItem(`recherche_avancee_public_${this.searchSessionStorageSuffix}`));
      rechercheSauvegardee = true;
    }
    if (JSON.parse(sessionStorage.getItem(`recherche_avancee_etiquettes_${this.searchSessionStorageSuffix}`))) {
      this.selectedTags = JSON.parse(sessionStorage.getItem(`recherche_avancee_etiquettes_${this.searchSessionStorageSuffix}`));
      this.tagFilter.value = this.selectedTags && this.selectedTags.length > 0 ? this.selectedTags : null;
      rechercheSauvegardee = true;
    }
    if (JSON.parse(sessionStorage.getItem(`recherche_avancee_producteur_${this.searchSessionStorageSuffix}`))) {
      this.producer = JSON.parse(sessionStorage.getItem(`recherche_avancee_producteur_${this.searchSessionStorageSuffix}`));
      this.producerFilter.value = this.producer;
      rechercheSauvegardee = true;
    }

    if (this.isTourShow) this.producerFilter.field = 'showOrganizationId';
    const arrayOfObservables: Observable<any>[] = [];
    arrayOfObservables.push(this.getDisciplineList());
    arrayOfObservables.push(this.getOrganizationList());
    arrayOfObservables.push(this.getTagList());

    this.producerSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.producerFilter.value = value;
        this.handleChange();
      });

    this.tagSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.tagFilter.value = value && value.length > 0 ? value : null;
        this.handleChange();
      });

    forkJoin(arrayOfObservables).subscribe(() => {
      if (rechercheSauvegardee) {
        this.handleChange(true);
      }
    });
  }

  getDisciplineList(): Observable<any> {
    // Création de la cascade à partir de la liste de toutes les disciplines
    return this.showService.getClassificationsList().do((data) => {
      if (data) {
        // Construire la liste des classifications pour le cascader
        buildTreesClassifications(
          data,
          this.classificationsListCascade,
          this.translate
        );
        this.cdr.markForCheck();
      }
    });
  }

  getOrganizationList(): Observable<any> {
    return this.organizationService
      .getProducers()
      .do((data: Organization[]) => {
        this.organizations = data;
      });
  }

  getTagList(): Observable<any> {
    return this.tagService.getAllEtiquettesWithShows().do((data) => {
      if (data && data['tags']) {
        this.tags = [];
        data['tags'].map((tag) => {
          this.tags.push(new Tag(tag));
        });
        this.tags = data.tags.map((tag) => new Tag(tag));
      }
    });
  }

  handleChange(rechercheSauvegardee = false) {
    const filters = [];
    const dataLayer: ShowOfferSearchData = {};

    sessionStorage.removeItem(`recherche_avancee_discipline_${this.searchSessionStorageSuffix}`);
    sessionStorage.removeItem(`recherche_avancee_producteur_${this.searchSessionStorageSuffix}`);
    sessionStorage.removeItem(`recherche_avancee_public_${this.searchSessionStorageSuffix}`);
    sessionStorage.removeItem(`recherche_avancee_etiquettes_${this.searchSessionStorageSuffix}`);

    if (this.discipline && this.discipline.length) {
      this.discipline.length === 1
        ? (this.disciplineFilter.value = this.discipline)
        : (this.disciplineFilter.value = this.discipline[
        this.discipline.length - 1
          ]);
      filters.push(this.disciplineFilter);

      dataLayer.discipline = data_layer_cascade_r(this.discipline[this.discipline.length - 1], {
        value: null,
        label: null,
        children: this.classificationsListCascade
      }, []).map(cascadeChild => cascadeChild.label);
      sessionStorage.setItem(`recherche_avancee_discipline_${this.searchSessionStorageSuffix}`, JSON.stringify(this.discipline));
    }

    if (!!this.interDisciplinaryFilter) {
      filters.push(this.interDisciplinaryFilter);
    }

    if (this.producerFilter.value) {
      filters.push(this.producerFilter);

      dataLayer.producteur = this.organizations.find(organisation => organisation.id === this.producerFilter.value).getTranslatedProperty('fr', 'name');
      sessionStorage.setItem(`recherche_avancee_producteur_${this.searchSessionStorageSuffix}`, JSON.stringify(this.producer));
    }

    if (this.public) {
      this.public = this.public.filter(function (elem, index, self) {
        return index === self.indexOf(elem);}).map(function (val, i) {
            return val === null ? 0 : val;
        });
      this.publicFilter.value = this.public;
      filters.push(this.publicFilter);
      dataLayer.public = ShowAudiences[this.publicFilter.value];
      sessionStorage.setItem(`recherche_avancee_public_${this.searchSessionStorageSuffix}`, JSON.stringify(this.public));
    }

    if (this.tagFilter.value) {
      filters.push(this.tagFilter);

      dataLayer.etiquettes = (this.tagFilter.value as number[]).map(id => this.tags.find(tag => tag.id === id).getTranslatedProperty('fr', 'keyword'));
      sessionStorage.setItem(`recherche_avancee_etiquettes_${this.searchSessionStorageSuffix}`, JSON.stringify(this.selectedTags));
    }

    this.filtersChange.emit({filters, dataLayer, rechercheSauvegardee});
  }

  data_layer_cascade_r(value: string, child: CascadeChild, tokens: CascadeChild[]): CascadeChild[] {
    if (child.value === value) {
      return tokens;
    }
    if (child.children) {
      for (const cchild of child.children) {
        const result = this.data_layer_cascade_r(value, cchild, [...tokens, cchild])
        if (result) {
          return result;
        }
      }
    }
  }
}
