<ng-container *ngIf="isReady">
  <app-page-title title="{{ 'DEPOT-RESEAUX' | translate | uppercase }}"
                  buttonText="{{ 'NEW-DEPOT' | translate }}"
                  (buttonClicked)="createNewTour()"
                  [permission]="CAN_EDIT">
          
  </app-page-title>

  <div class="container">
    <app-item-list
      [itemList]="itemList"
      [pagination]="pagination"
      [listItemsType]="listType"
      [isLoading]="isLoading"
      [useViewSwitcher]="false"
      [useFilters]="false"
      [useSearchBar]="false"
      [useSorting]="false"
      [usePagination]="true"
      elementsLabel="{{ 'DEPOT-RESEAUX' | translate | lowercase }}"
      (pageChange)="onPageChange($event)"
      (modifyItems)="modifyTour($event)"
    >

    </app-item-list>
  </div>
</ng-container>