import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ToursService } from './services/tours.service';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Tour } from './model/tour.model';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';
import { SessionStorageService } from '@app/shared/services/storage.service';
import { StorageData } from './model/storage-data.model';
interface PaidTourStorageData extends StorageData<Tour[]> {
    contextOrganizationId?: number;
}
@Injectable({ providedIn: 'root' })
export class PaidToursResolver implements Resolve<Tour[]> {
    private readonly sessionStoragePaidToursKey = 'sp.paidTours';
    constructor(
        private sessionStorage: SessionStorageService,
        private readonly toursService: ToursService,
        private readonly userOrganizationIdResolver: UserOrganizationIdResolver
    ) {}

    resolve(): Observable<Tour[]> {
        return this.userOrganizationIdResolver.resolve().pipe(switchMap((contextOrganizationId) => this.getPaidTours(contextOrganizationId)));
    }

    private getPaidTours = (contextOrganizationId: number): Observable<Tour[] | null> => {
        if (contextOrganizationId === -1) {
            return of(null);
        }
        const storedData: PaidTourStorageData = this.sessionStorage.getItem(this.sessionStoragePaidToursKey);
        // if (storedData && storedData.contextOrganizationId && storedData.contextOrganizationId === contextOrganizationId) {
        //     return of(storedData.list.map((x) => new Tour(x)));
        // }
        return this.toursService.paidTours(contextOrganizationId).pipe(
            map((tours: Tour[]) => {
                this.sessionStorage.setItem(this.sessionStoragePaidToursKey, { ts: Date.now(), list: tours, contextOrganizationId });
                return tours.map((x) => new Tour(x));
            })
        );
    };
}
