import {
  Directive,
  HostBinding,
  Input,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'button[appButton]',
})
export class ButtonDirective implements OnChanges {
  @HostBinding('attr.type') type = 'button';
  @HostBinding('class.loading') loading = false;
  @HostBinding('disabled') disabledBinding = false;
  @Input() click: any;
  @Input() disabled: boolean;
  @HostListener('click') onClick() {
    if (this.click) {
      const result = this.click();
      if (result instanceof Subscription) {
        this.loading = true;
        this.disabledBinding = this.loading || this.disabled;
        result.add(() => {
          this.loading = false;
          this.disabledBinding = this.loading || this.disabled;
        });
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.disabledBinding = this.loading || this.disabled;
    }
  }
}
