<div nz-row>

  <div nz-col nzSpan="24">
    <div>
      <h2 class="mb-4">{{ 'SHOW-STEP.MARKETING' | translate}}</h2>
    </div>
  </div>

</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <app-alert [class]='marketingState.currentState.prop.class'>
      <i class="status-bulb--small" [ngClass]='marketingState.currentState.prop.icon'></i>
      <span [innerHTML]=" marketingState.currentState.prop.sentence | translate"></span>
      <button appButton [click]='marketingState.currentState.prop.callback'>
        {{ marketingState.currentState.prop.buttonSentence | translate }}
      </button>
    </app-alert>
  </div>
</div>

<div nz-row>
  <div nz-col nzSpan="15" class="Marketing Marketing_detailsContainer">
    <app-read-only-action [title]=' "FORM.TITRE" | translate ' [details]=' show | translatable:"title" '
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]="'ARTISTE-MAIN' | translate"
      [details]="!!show.artists[0] ? show.artists[0].name : 'AUCUNE-INFORMATIONS' | translate"
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"RECHERCHER-PAR-PRODUCTEUR" | translate'
      [details]='show.producer ? (show.producer | translatable:"name") : ""'
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"FORM.CO-PRODUCTEUR" | translate ' [details]=' show | translatable:"coProducer" '
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"PUBLIC-DESCRIPTION" | translate '
      [details]=' show | translatable:"shortDescription" '
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"FORM.ARTISTES" | translate ' [details]=' show | extractArtistListPipeable '
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"FORM.DISCIPLINE-MAIN" | translate' [details]='createShowClassification()'
      (EditClicked)='navigateTo("CLASSIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"FORM.LANGUES" | translate ' [details]=' show.languages | getTranlatedLanguages '
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"FORM.PUBLIC-CIBLE" | translate ' [details]=' show.audiences | getTranslatedPublics '
      (EditClicked)='navigateTo("CLASSIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"FORM.DUREE-DE-SPECTACLE" | translate '
      [details]=' show.durationTotal | minuteToHour ' (EditClicked)='navigateTo("CACHET")'></app-read-only-action>
    <app-read-only-action [title]='"FORM.WITH-INTERMISSION" | translate'
      [details]='show.hasBreak ? ( "OUI" | translate ) : ( "NON" | translate ) '
      (EditClicked)='navigateTo("CACHET")'></app-read-only-action>
    <app-read-only-action [title]='"URL-SHOW" | translate' [details]='show.artistUrl' [isUrl]='true'
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <app-read-only-action [title]='"URL-PROMO" | translate' [details]='show.urlVideo' [isUrl]='true'
      (EditClicked)='navigateTo("MATERIEL")'></app-read-only-action>

  </div>

  <div nz-col nzSpan="8">
    <app-read-only-action [title]='"SHOW-IMAGE" | translate' [showDetailsColumn]='false'
      (EditClicked)='navigateTo("IDENTIFICATION")'></app-read-only-action>
    <div>
      <img [src]='show.image' alt="" class="Marketing_mainImage">
    </div>

  </div>
</div>