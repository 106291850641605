import { NetworkService } from './../../../organization/services/network.service';
import { TourInterest } from './../../../tours/enums/tour-interet.enum';
import { Component, OnInit, Input, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAlbum } from 'ngx-lightbox';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Organization, Network } from 'src/app/concepts/organization/model/organization.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { ShowAudiences, SortedShowAudiences } from '../../enums/show-audiences.enum';
import { Show, ShowFavorites } from '../../model/show.model';
import { ShowService } from '../../services/show.service';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { GetSiteLanguageById } from '../../../../shared/enums/app-languages.enum';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';
import { ToursService } from 'src/app/concepts/tours/services/tours.service';
import { GetEquipageById } from 'src/app/concepts/tours/enums/tour-equipage.enum';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { TourShowInterest } from 'src/app/concepts/tours/model/tour-show-interest.model';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { ITourKeepUpdate, Tour } from 'src/app/concepts/tours/model/tour.model';
import { Classification } from '../../model/classification.model';
import { tagShowOfferView } from 'src/app/data-layer';
import { NzModalService } from 'ng-zorro-antd';
import { LocalizeRouterService } from 'localize-router';
import { HttpClient } from '@angular/common/http';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { Globals } from 'src/app/_configs/globals';
@Component({
    selector: 'app-show-single',
    templateUrl: './show-single.component.html',
    styleUrls: ['./show-single.component.scss']
})
export class ShowSingleComponent implements OnInit {
    public OpenEvaluations = false;
    public artistList = [];
    public coProducerModel: string;
    // contextNetworkId: number;
    public contextNetworkId: number[];
    public contextOrgId = 0;
    public descriptionModel: string;
    public descriptionShort: string;
    @Input() public disableFavorites = false;
    public dislikeReasons = [
        {
            label: this.translate.instant('TOURS.REASON-0'),
            value: 0,
            checked: false
        },
        {
            label: this.translate.instant('TOURS.REASON-1'),
            value: 1,
            checked: false
        },
        {
            label: this.translate.instant('TOURS.REASON-2'),
            value: 2,
            checked: false
        }
    ];
    public dislikes = 0;
    public displayFavorites = true;
    public displayShortDesc = false;
    public editShowLink$: Observable<string>;
    public evaluated: boolean;
    public hasTransformClassifs = false;
    public interestEnum: TourInterest;
    public isAdmin = false;
    public isKeep = false;
    public isReady = false;
    public isRotate = false;
    public isToRate = true;
    public lang: string;
    public languagesList: string;
    public likes = 0;
    public mainProducerLink: string;
    public nudityAndViolence = false;
    public organisation: Organization;
    public organizationLink: string;
    public photoAlbum: IAlbum[] = [];
    public promoteShowlink = '';
    public promotionInFuture: string;
    public publicCible: string[];
    public selfTourShowRating = 0;
    public show: Show;
    public showClassificationCascade: string[][] = [];
    public showClassificationList: Classification[] = [];
    public showFavorites: EventEmitter<ShowFavorites> = new EventEmitter();
    public showId = 0;
    public showTourInterests = [];
    public successMsg: string;
    public switchStyle: boolean;
    public tour: Tour;
    public tourId: number;
    @Input() public tourShow?: TourShow;
    public user: PxUser;
    public userNetworkIds: number[] = [];
    public userNetworks: Network[] = [];

    private editShowLinkSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private subscriptions: Subscription;

    constructor(
        private showService: ShowService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private accountService: AccountService,
        private orgaService: OrganizationService,
        private breadcrumbService: BreadcrumbService,
        private notification: RideauNotificationService,
        private translate: TranslateService,
        private networkService: NetworkService,
        private tourService: ToursService,
        private modalService: NzModalService,
        private router: Router,
        private readonly localizeRouter: LocalizeRouterService,
        private httpClient: HttpClient,
        private globals: Globals
    ) {
        this.editShowLink$ = this.editShowLinkSubject.asObservable();
    }

    public canceldislike() {
        this.selfTourShowRating = TourInterest.NOEVAL;
        this.rateTourShow();
    }

    public createShowCascade(result: string[], classifications: Classification[], classificationId: number) {
        if (classifications) {
            const classification = classifications.filter((x) => x.id == classificationId).pop();
            result.unshift(classification.getTranslatedProperty(this.lang, 'description'));

            // Récursive si le noeud actuel a un parent
            if (classification.parentId) {
                this.createShowCascade(result, classifications, classification.parentId);
            }
            return result;
        }
        return [];
    }

    public deleteTourShow(): void {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.tourService.deleteTourShow(this.tourShow.id, this.accountService.getCurrentCtxOrganizationId()).subscribe(() => {
                    this.notification.success(this.translate.instant('DEPOT-DU-SPECTACLE-SUPPRIMEE'));
                    this.router.navigate([this.localizeRouter.translateRoute(`/tour/${this.tourId}/shows`)]);
                });
            }
        });
    }

    public formatNumbers(numberToFormat: number) {
        if (!numberToFormat) {
            return '';
        }
        if (numberToFormat % 1 === 0) {
            Math.trunc(numberToFormat);
        }

        return numberToFormat
            .toString()
            .split(/(?=(?:\d{3})+(?:\.|$))/g)
            .join(' ')
            .replace('.00', '');
    }

    public formatShowClassification(classificationId: number, classifications: Classification[]) {
        let classificationPath = [];
        const result: any[] = [];
        if (classificationId) {
            classificationPath = this.createShowCascade(result, classifications, classificationId);
        }
        return classificationPath;
    }

    public getAllShowClassif(): Observable<any> {
        return this.showService.getClassificationsList().do((classifications) => (this.showClassificationList = classifications));
    }

    public getContextNetwork(): Observable<any> {
        this.contextOrgId = this.accountService.getCurrentCtxOrganizationId();
        return this.networkService.getNetworksByOrganization(this.contextOrgId).do((data) => {
            this.contextNetworkId = data.networks;
            this.userNetworkIds = data.networks;
        });
    }

    public getEquipageById(id): string {
        return this.translate.instant(GetEquipageById(id));
    }

    public getMyOrganizationTourShowInterest() {
        // Filter Interests List by context Organization Id
        const myInterest = this.showTourInterests.filter((interest) => interest.organizationId == this.contextOrgId);

        // Remove all che
        this.dislikeReasons.forEach((el: any) => (el['checked'] = false));

        if (!myInterest.length) {
            this.switchStyle = null;
            return;
        }
        this.selfTourShowRating = myInterest[0].interest;
        if (myInterest[0].interest == TourInterest.LIKE) {
            this.switchStyle = true;
            return;
        }
        if (myInterest[0].interest == TourInterest.DISLIKE) {
            this.switchStyle = false;
            this.dislikeReasons.forEach((dropdownReason) => {
                dropdownReason.checked = myInterest[0].reason.filter((el) => el == dropdownReason.value).length > 0;
            });
            return;
        }
        this.switchStyle = null;
    }

    public getNetworksInfos() {
        this.networkService.getNetworksByOrganization(this.accountService.getCurrentCtxOrganizationId()).subscribe();
    }

    public getShowArists(): Observable<any> {
        return this.showService.getArtists(this.showId).do((data) => (this.show.artists = data['artists']));
    }

    public getShowAudiences(): Observable<any> {
        return this.showService.getAudiences(this.showId).do((data) => {
            const AudiencesEnumKeys = Object.keys(ShowAudiences).filter((k) => isNaN(Number(k)));
            // Afficher les Audiences dans le même ordre dans le front
            const sortedAudiencesValues = Object.values(SortedShowAudiences).filter((v) => !isNaN(Number(v))) as number[];
            const sortedAudiences = sortedAudiencesValues.filter((audience) => data['audiences'].includes(audience));
            this.show['audiences'] = sortedAudiences;
            // On génère une string à partir des ids et de l'enum des Audiences
            this.publicCible = this.show.audiences.map((elt) => {
                return this.translate.instant('FORM.' + AudiencesEnumKeys[elt - 1]);
            });
        });
    }

    public getShowClassif(): Observable<any> {
        return this.showService.getClassifications(this.showId).do((data) => (this.show.classifications = data));
    }

    public getShowFavorites() {
        // Call for Favorites
        this.showService.getShowFavorites(this.showId, this.contextOrgId, this.contextNetworkId).subscribe((data) => {
            const showFavorites = new ShowFavorites(data);
            showFavorites.organizationId = this.contextOrgId;
            // Trigger Page Title Favorites Update
            this.showFavorites.emit(showFavorites);
        });
    }

    public getShowOrganization(): Observable<any> {
        return this.orgaService.getOrganization(this.show.organizationId).do((data) => (this.organisation = data));
    }

    public getShowSingle(): Observable<any> {
        const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        return this.showService.getShow(this.showId).do((data: Show) => {
            this.show = new Show(data);
            this.promotionInFuture = '';

            if (!this.show.isPromoted && this.show.promotions && this.show.promotions.length > 0) {
                const startDate = new Date(this.show.promotions[0].dateFrom);
                if (startDate > new Date()) {
                    this.promotionInFuture = ' ' + this.translate.instant('FORM.DU').toLowerCase() + ' ' + startDate.toLocaleDateString(this.globals.locales.frFR, options);
                    this.show.isPromoted = 1;
                }
            }
            // Set Breadcrumb
            const showName = this.show.getTranslatedProperty(this.lang, 'title');
            const breadcrumbItem = new BreadcrumbItem();
            breadcrumbItem.set(showName);

            const breadcrumbItems: BreadcrumbItem[] = [breadcrumbItem];

            if (this.tour) {
                breadcrumbItems.push(this.getTourBreadCrumItem(this.tour));
                breadcrumbItems.reverse();
            }

            this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems);

            this.artistList = this.show.artists.map((x) => x.name);

            this.languagesList = this.show.languages.map((langId) => this.translate.instant(GetSiteLanguageById(langId).name)).join(', ');

            this.mainProducerLink = `/organization/${this.show.producerId}`;
            this.organizationLink = `/organization/${this.show.organizationId}`;
            this.editShowLinkSubject.next(`/shows-offers/${this.show.organizationId}/${this.show.id}/modify/IDENTIFICATION`);
            this.promoteShowlink = `/shows-offers/${this.show.organizationId}/${this.show.id}/new-promotion`;
            this.nudityAndViolence = this.show.hasNudity == 1 && this.show.hasViolence == 1;
            this.descriptionModel = this.show.getTranslatedProperty(this.lang, 'longDescription');
            this.descriptionShort = this.show.getTranslatedProperty(this.lang, 'shortDescription');
            this.coProducerModel = this.show.getTranslatedProperty(this.lang, 'coProducer');
            this.photoAlbum = [];

            if (this.show.urlPromoPhoto1) {
                this.photoAlbum.push({
                    src: this.show.urlPromoPhoto1,
                    caption: this.show.lblPromoPhoto1,
                    thumb: this.show.urlPromoPhoto1
                });
            }
            if (this.show.urlPromoPhoto2) {
                this.photoAlbum.push({
                    src: this.show.urlPromoPhoto2,
                    caption: this.show.lblPromoPhoto2,
                    thumb: this.show.urlPromoPhoto2
                });
            }
            if (this.show.urlPressPhoto1) {
                this.photoAlbum.push({
                    src: this.show.urlPressPhoto1,
                    caption: this.show.lblPressPhoto1,
                    thumb: this.show.urlPressPhoto1
                });
            }
            if (this.show.urlPressPhoto2) {
                this.photoAlbum.push({
                    src: this.show.urlPressPhoto2,
                    caption: this.show.lblPressPhoto2,
                    thumb: this.show.urlPressPhoto2
                });
            }
        });
    }

    public getTour() {
        this.tourService.getTourById(this.tourId).subscribe((tour) => {
            this.isAdmin = this.contextOrgId === tour.organizationId;
            this.tour = tour;
        });
    }

    public getTourShowAllInterests() {
        // Get context organization id
        this.contextOrgId = this.accountService.getCurrentCtxOrganizationId();

        this.tourService.getTourShowInterests(this.tourShow.id).subscribe((showInterests) => {
            this.showTourInterests = showInterests.interests;
            // Init Organization Object
            this.showTourInterests.forEach((interest) => {
                interest.organization = new Organization(interest.organization);
            });
            // Update counters
            this.likes = this.showTourInterests.filter((interest: TourShowInterest) => interest.interest == TourInterest.LIKE).length;
            this.dislikes = this.showTourInterests.filter((interest: TourShowInterest) => interest.interest == TourInterest.DISLIKE).length;
            // Verify my own interest
            this.getMyOrganizationTourShowInterest();
        });
    }

    public initUserNetworks() {
        this.networkService.getAllNetworks().subscribe((res) => {
            for (const item of res) {
                for (const userNetworkId of this.userNetworkIds) {
                    if (userNetworkId == item.id) {
                        this.userNetworks.push(item);
                    }
                }
            }
        });
    }

    public isShowKeeped() {
        const showKeeped: number = this.isKeep ? 2 : 1;
        const body: ITourKeepUpdate = {
            id: this.tourShow.id,
            statusId: showKeeped,
            organizationId: this.contextOrgId
        };

        this.tourService.updateTourShow(body).subscribe();
    }

    public async isdisliked() {
        // console.log("disisLiked");
        this.selfTourShowRating = TourInterest.DISLIKE;
        document.getElementById('dislikeBtn').click();
        this.rateTourShow();
    }

    public async isliked() {
        this.selfTourShowRating = this.selfTourShowRating == TourInterest.LIKE ? TourInterest.NOEVAL : TourInterest.LIKE;
        this.rateTourShow();
    }

    public ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.user = this.authService.User;
        this.lang = this.translate.currentLang;
        this.tourId = this.route.snapshot.params['tourId'];
        this.contextOrgId = this.accountService.getCurrentCtxOrganizationId();

        // is Tour Show
        if (this.tourShow) {
            this.showId = this.tourShow.showId;
            this.isKeep = this.tourShow.statusId === 1 ? false : true;
            // Calling getTourShowAllInterests
            this.getTourShowAllInterests();
        } else {
            // Get Context Network Id
            this.showId = this.route.snapshot.params['showId'];
        }

        if (this.tourId) {
            this.getTour();
        }

        this.getShowSingle().subscribe(() => {
            forkJoin([this.getShowAudiences(), this.getShowArists(), this.getShowOrganization(), this.getContextNetwork(), this.getAllShowClassif()]).subscribe(async (data) => {
                // Get Favorites or disable them
                if (!this.disableFavorites) {
                    this.initUserNetworks();
                    this.getShowFavorites();
                } else {
                    this.displayFavorites = false;
                }

                for (const showClassif of this.show.classifications) {
                    const cascade = this.formatShowClassification(showClassif, this.showClassificationList);
                    this.showClassificationCascade.push(cascade);
                }
                this.isReady = true;
                const isDiffuser = await this.isDiffuser();
                tagShowOfferView(
                    {
                        showId: this.show.id,
                        isDiffuser,
                        offre: this.show.getTranslatedProperty('fr', 'title'),
                        disciplines: this.showClassificationCascade,
                        publics: this.show.audiences.map((audience) => ShowAudiences[audience]),
                        representant: this.show.representative
                    },
                    this.httpClient
                );
            });
        });
        this.getShowAudiences();

        const sub = this.accountService.currentOrganizationChange.subscribe(() => {
            // Favorites
            this.getContextNetwork().subscribe((res) => {
                // Get Favorites or disable them
                if (!this.disableFavorites) {
                    this.displayFavorites = true;
                    this.getShowFavorites();
                } else {
                    this.displayFavorites = false;
                }
            });

            // Interest for tourShow
            if (this.tourShow) {
                this.getTourShowAllInterests();
            }
        });
        this.subscriptions = sub;
    }

    public onSaveFavorites($event) {
        // update object
        const showFavorites = new ShowFavorites($event);
        showFavorites.organizationId = this.contextOrgId;
        this.showService.createShowFavorites(this.showId, showFavorites).subscribe((res) => {
            this.successMsg = this.translate.instant('FORM.SAUVEGARDE');
            this.notification.success(this.successMsg);
        });
    }

    public rateTourShow() {
        const tourShowInterest: TourShowInterest = {
            organizationId: this.contextOrgId,
            interest: this.selfTourShowRating,
            reason: []
        };

        if (this.selfTourShowRating == TourInterest.DISLIKE && this.dislikeReasons.filter((obj) => obj['checked'] === true).length) {
            tourShowInterest.reason = this.dislikeReasons.filter((obj) => obj['checked'] === true).map((el: any) => parseInt(el.value));
        }

        console.log(tourShowInterest);

        this.tourService.createTourShowInterest(this.tourShow.id, tourShowInterest).subscribe((res) => {
            this.getTourShowAllInterests();
        });
    }

    private getTourBreadCrumItem(tour: Tour) {
        const breadCrum = new BreadcrumbItem();

        const tourURl = '/tour/' + this.tourId + '/shows';

        breadCrum.set(tour.getTranslatedProperty(this.lang, 'name'), tourURl);
        return breadCrum;
    }

    private async isDiffuser(): Promise<number> {
        const isDiffuser = await this.accountService.doesCurrentOrgaHasType(OrganizationTypes.IS_DIFFUSEUR).toPromise();
        const isNotScenePro = this.accountService.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID;
        return isDiffuser && isNotScenePro ? 1 : 0;
    }
}
