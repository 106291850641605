import { getTimeFromTimestamp } from '../utils/time-utils';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Prends un nombre de minutes et le formate en heures et minutes
 * Usage :
 *   {{ 75 | hourMin }}
 * Affiche :
 *  1h15
 */
@Pipe({
  name: 'timeFromDate',
})
export class TimeFromDatePipe implements PipeTransform {
  transform(value: Date): string {
    return getTimeFromTimestamp(value);
  }
}
