<div class="container pb-5 infoAndSchedule" *ngIf="tourShow">
  <div nz-row
    nzGutter="16">
    <div nz-col nzMd="12">
      <h3 class="mt-5 mb-3 ml-2">{{ 'TOURS.MODALITES-CONDITIONS'| translate }}</h3>
      <hr class="bg-neutral clr-neutral" />
      <div *ngIf="tourShow.showsFee; else discount">
        <div class="listH">
          <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-PAR-REPRESENTATION'| translate }}</p>
          <p class="page-template-text mb-0">{{ tourShow.showsFee ? tourShow.showsFee : '' }} $</p>
        </div>
      </div>
      <ng-template #discount>
        <div *ngIf="tourShow.showsFeeDiscount1 || tourShow.showsFeeDiscount2 || tourShow.showsFeeDiscount3  || tourShow.showsFeeDiscount4; else group">
          <div class="listH">
            <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-1-REPRESENTATION'| translate }}</p>
            <p class="page-template-text mb-0">{{ tourShow.showsFeeDiscount1 }} $</p>
          </div>
          <hr class="bg-neutral clr-neutral" />
          <div class="listH">
            <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-2-REPRESENTATION'| translate }}</p>
            <p class="page-template-text mb-0">{{ tourShow.showsFeeDiscount2 ? tourShow.showsFeeDiscount2 :  '' }} $</p>
          </div>
          <hr class="bg-neutral clr-neutral" />
          <div class="listH">
            <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-3-REPRESENTATION'| translate }}</p>
            <p class="page-template-text mb-0">{{ tourShow.showsFeeDiscount3 ? tourShow.showsFeeDiscount3 :  ''  }} $</p>
          </div>
          <hr class="bg-neutral clr-neutral" />
          <div class="listH">
            <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-4-REPRESENTATION'| translate }}</p>
            <p class="page-template-text mb-0">{{ tourShow.showsFeeDiscount4 ? tourShow.showsFeeDiscount4 :  ''  }} $</p>
          </div>
        </div>
      </ng-template>
       <ng-template #group>
        <div class="listH">
          <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-FIXE-1-3'| translate }}</p>
          <p class="page-template-text mb-0">{{ tourShow.shows3Fee }} $</p>
        </div>
         <hr class="bg-neutral clr-neutral" />
        <div class="listH">
          <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-FIXE-4-6'| translate }}</p>
          <p class="page-template-text mb-0">{{ tourShow.shows4To6Fee ? tourShow.shows4To6Fee :  '' }} $</p>
        </div>
          <hr class="bg-neutral clr-neutral" />
        <div class="listH">
          <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-FIXE-7-9'| translate }}</p>
          <p class="page-template-text mb-0">{{ tourShow.shows7To9Fee ? tourShow.shows7To9Fee :  ''  }} $</p>
        </div>
          <hr class="bg-neutral clr-neutral" />
        <div class="listH">
          <p class="page-template-labels mb-0">{{ 'TOURS.CACHET-FIXE-10+'| translate }}</p>
          <p class="page-template-text mb-0">{{ tourShow.shows10PlusFee ? tourShow.shows10PlusFee :  ''  }} $</p>
        </div>
      </ng-template>
      <hr class="bg-neutral clr-neutral" />
      <div class="listH">
        <p class="page-template-labels mb-0">{{ 'TOURS.POURCENTAGE'| translate }}</p>
        <p class="page-template-text mb-0">{{ tourShow.revenuePercentage ? tourShow.revenuePercentage :  ''  }} %</p>
      </div>
        <hr class="bg-neutral clr-neutral" />
      <div class="listH">
        <p class="page-template-labels mb-0">{{ 'TOURS.DEPENSES-DIFFUSEURS'| translate }}</p>
        <p class="page-template-text mb-0">{{ tourShow.broadcastExpenses ? tourShow.broadcastExpenses :  ''  }} $</p>
      </div>
        <hr class="bg-neutral clr-neutral" />
      <div class="listH">
        <p class="page-template-labels mb-0">{{ 'TOURS.AIDE-TOURNEE'| translate }}</p>
        <p class="page-template-text mb-0">{{ tourShow.isHelpNeeded ? 'Oui' : 'Non' }}</p>
      </div>
    </div>
    <div nz-col nzMd="12">
      <h3 class="mt-5 mb-3 ml-2">{{ 'TOURS.DATES-DISPONIBLES'| translate }}</h3>
      <hr class="bg-neutral clr-neutral" />
      <div class="listH">
        <p class="page-template-labels mb-0">{{ 'TOURS.DISPONIBILITE-TOUT-TEMPS'| translate }}</p>
        <p class="page-template-text mb-0">{{ tourShow.isAvailableAllTime ? 'Oui' : 'Non' }}</p>
      </div>
      <div *ngIf="!tourShow.isAvailableAllTime">
      <hr class="bg-neutral clr-neutral" />
      <div class="listH">
        <p class="page-template-labels mb-0">Bloc 1</p>
        <p class="page-template-text mb-0">{{ tourShow.dateFromBlock1 ? (tourShow.dateFromBlock1 | date:'longDate') :  'NA' }} – {{ tourShow.dateToBlock1 ? (tourShow.dateToBlock1 | date:'longDate') :  'NA'  }}</p>
      </div>
        <hr class="bg-neutral clr-neutral" />
      <div class="listH">
        <p class="page-template-labels mb-0">Bloc 2</p>
        <p class="page-template-text mb-0">{{ tourShow.dateFromBlock2 ? (tourShow.dateFromBlock2 | date:'longDate') :  'NA'  }} – {{ tourShow.dateToBlock2 ? (tourShow.dateToBlock2 | date:'longDate') :  'NA'  }}</p>
      </div>
      </div>
    </div>
  </div>
</div>
