<div class="item-row">
    <div class="participant-infos">
        <!-- Photo -->
        <img class="item-row__avatar-src" src="{{ participant.user.avatar || './assets/images/placeholder-membre.svg' }}" alt="" />
        <!-- Nom et Prénom -->
        <div class="item-row__text item-row__text--title d-inline-block">
            {{ participant.user.firstName + ' ' + participant.user.lastName }}
        </div>
    </div>

    <div class="inscription-infos">
        <div *ngFor="let product of participant.products" class="inscription-item">
            <div class="col_1">
                <div class="product-name">{{ product.getTranslatedProperty(lang, 'name') }}</div>
                <div class="product-desc">{{ getDescriptionForProduct(product) }}</div>
            </div>
            <div class="col_2">
                {{ getPriceForProduct(product) }}
            </div>
            <div class="col_3">
                <button type="button" class="btn-square" (click)="removeProduct.emit(product)"><i class="icon-trash"></i></button>
            </div>
        </div>
        <div class="inscription-item">
            <div class="col_1">
                <ng-container *ngIf="participant.user.avatar; else profilIncomplet">
                    <!-- Sélecteur de produits (chargement des produits à l'ouverture) -->
                    <nz-dropdown [nzTrigger]="'click'">
                        <button class="button" (click)="getProductsForMeeting()" nz-button nz-dropdown>
                            <span>{{ 'AJOUTER-UN-PRODUIT' | translate }}</span>
                            <i nz-icon type="down"></i>
                        </button>
                        <ul nz-menu>
                            <ng-container *ngIf="loadingProducts; else productsTpl">
                                <li nz-menu-item>{{ 'CHARGEMENT' | translate }}...</li>
                            </ng-container>
                            <ng-template #productsTpl>
                                <div class="dropdown">
                                    <ng-container *ngIf="products && products.length; else noProductsTpl">
                                        <li nz-menu-item *ngFor="let product of products" (click)="addProduct.emit(product)">
                                            {{ product.getTranslatedProperty(lang, 'name') + ' - ' + getPriceForProduct(product) }}
                                        </li>
                                    </ng-container>
                                </div>
                                <ng-template #noProductsTpl>
                                    <li nz-menu-item>
                                        {{ 'NO-PRODUCTS' | translate }}
                                    </li>
                                </ng-template>
                            </ng-template>
                        </ul>
                    </nz-dropdown>
                </ng-container>

                <!-- Profil Incomplet -->
                <ng-template #profilIncomplet>
                    <div class="profilIncomplet">
                        <i class="icon-warning mr-1"></i>
                        <span>{{ 'PROFIL-INCOMPLET' | translate }}</span>
                    </div>
                </ng-template>
            </div>
            <div class="col_2"></div>
            <div class="col_3">
                <button type="button" *ngIf="participant.products.length === 0 && currentOrganisationId !== -1" class="btn-square" (click)="removeParticipant.emit(participant.id)">
                    <i class="icon-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>
