<div *ngIf="showBreadcrumb" class="rideauBreadcrumb">

  <div class="container d-flex">
    <!-- Lien vers le Dashboard -->
    <a class="rideauBreadcrumb_dash" routerLink="/"> {{'TABLEAU-BORD' | translate}} </a>

    <!-- Lien vers le concepts principal -->
    <ng-container *ngIf="breadcrumbMainConcept">
      <div class="rideauBreadcrumb_item">
        <a routerLink="{{ breadcrumbMainConcept.url | localize}}" (click)="breadcrumbItems = []"> {{
          breadcrumbMainConcept.title | translate }} </a>
      </div>
    </ng-container>

    <!-- Loop sur les éléments du fil d'Ariane  -->
    <ng-container *ngFor="let item of  breadcrumbItems$ | async">
      <div class="rideauBreadcrumb_item">
        <a *ngIf="item.url; else pageName" [ngClass]="{'editing': item.isEditing}" routerLink="{{ item.url | localize}}"
          (click)="breadcrumbItems = []">
          {{ item.title | translate }}
        </a>
        <ng-template #pageName>
          <span>{{ item.title | translate }}</span>
        </ng-template>
      </div>
    </ng-container>
  </div>

</div>
