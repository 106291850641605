<nz-form-item>
  <label [attr.for]="id">
    <ng-content></ng-content>
  </label>

  <nz-form-control>
    <nz-select
      [attr.id]="id"
      name="selected"
      nzShowSearch
      [nzShowArrow]="false"
      [(ngModel)]="selected"
      (ngModelChange)="onSelected()"
      [nzDisabled]="disabled"
      [nzPlaceHolder]="placeholder"
    >
      <ng-container *ngFor="let option of options">
        <nz-option
          *ngIf="!isSelected(option)"
          [nzLabel]="
            option.getTranslatedProperty(
              translateService.currentLang,
              labelProperty
            )
          "
          [nzValue]="option[valueProperty]"
        >
        </nz-option>
      </ng-container>
    </nz-select>

    <i class="icon-search" aria-hidden="true"></i>

    <ng-content select="[after]"></ng-content>
  </nz-form-control>

  <div class="selected">
    <ng-container *ngIf="optionTemplate; else elseNoTemplateBlock">
      <ng-container *ngFor="let selected of value">
        <ng-container *ngTemplateOutlet="optionTemplate; context: templateContext(selected)"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #elseNoTemplateBlock>
      <app-item-row
        [itemRowType]="rowType"
        [item]="selectedItemRow(selected)"
        [enableRemoving]="!disabled"
        hideStatus="true"
        (removeItem)="onRemoved(selected)"
        *ngFor="let selected of value"
        [confirmPrompt]="false"
        [loading]="isLoading(selected)"
        removeIcon="icon-trash"
      >
      </app-item-row>
    </ng-template>
  </div>
</nz-form-item>
