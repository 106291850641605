<app-page-title title="{{ 'USERS' | translate }}"
                [permission]="1"
                [organizationId]="1">
</app-page-title>
<div class="container">
    <app-item-list [itemList]="usersItems"
                   [pagination]="pagination"
                   [listItemsType]="listType"
                   [isLoading]="isLoading"
                   [useSearchBar]="true"
                   [searchBarPlaceHolder]="'RECHERCHER-UTILISATEUR' | translate"
                   [useSorting]="false"
                   [usePagination]="true"
                   elementsLabel="{{'USERS' | translate | lowercase}}"
                   (searchChange)="onSearchChange($event)"
                   (pageChange)="onPageChange($event)">
  </app-item-list>
</div>
