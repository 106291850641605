import { distinctUntilChanged, filter, map, share, withLatestFrom, take, switchMap, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meeting } from 'src/app/concepts/meeting/model/meeting.model';
import { MeetingService } from 'src/app/concepts/meeting/services/meeting.service';
import { Permission, Role } from 'src/app/shared/enums/roles.enum';
import { ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Globals } from 'src/app/_configs/globals';
import { Organization, OrganizationNetworks } from 'src/app/concepts/organization/model/organization.model';
import { NetworkService } from 'src/app/concepts/organization/services/network.service';
import { NetworkItem } from '../../concepts/tours/model/network-item.model';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { OrganizationManagerService } from '@app/concepts/organization/services/organizations-manager.service';

@Component({
    selector: 'app-pro-meeting',
    templateUrl: './pro-meeting.component.html',
    styleUrls: ['./pro-meeting.component.scss']
})
export class ProMeetingComponent implements OnInit {
    public lang: string;
    public createProMeetingRoute: string;
    public isLoading = false;
    public itemList: ListItem[] = [];
    public SCENE_PRO_ID = this.globals.SCENE_PRO_ORGID;
    public CAN_EDIT = Permission.CAN_EDIT;
    networks$: Observable<NetworkItem[]>;
    currentLang = this.translate.currentLang;
    organizationList = {};
    organization: Organization;
    private defaultFilters: BehaviorSubject<SelectedFilter[]> = new BehaviorSubject<SelectedFilter[]>([]);
    ctxOrganizationId: number;
    public networksByOrganization: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    public networksByOrganization$: Observable<number[]> = this.networksByOrganization.asObservable().pipe(share());
    selectedUserOrganizationId$: Observable<number> = this.organizationManager.selectedUserOrganizationId$;
    public isSceneProAdmin$: Observable<boolean> = this.accountService.hasRole(Role.ADMINISTRATOR).pipe(
        withLatestFrom(this.selectedUserOrganizationId$),
        map(([isAdmin, selectedUserOrganizationId]) => isAdmin && selectedUserOrganizationId === this.globals.SCENE_PRO_ORGID),
        take(1),
        share()
    );
    private destroyed: Subject<void> = new Subject();
    constructor(
        private globals: Globals,
        private meetingService: MeetingService,
        private translate: TranslateService,
        private accountService: AccountService,
        private networkService: NetworkService,
        private organizationManager: OrganizationManagerService
    ) {}

    ngOnInit(): void {
        this.lang = this.translate.currentLang;
        const proMeetingRoute = this.translate.instant('ROUTES.pro-meeting');
        this.createProMeetingRoute = `/${this.lang}/${proMeetingRoute}/create`;
        this.getAllMeetings();
        this.getNetworksTours();
        this.getNetworksByOrganization();
        this.updateFiltersOnOrganizationChanged();
    }
    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    getAllMeetings(): void {
        this.isLoading = true;
        this.meetingService.getAllMeetings([...this.defaultFilters.value], null, null, true).subscribe((res: Meeting[]) => {
            const meetingOrganizer = this.translate.instant('ORGANISE-PAR');
            this.itemList = res.map((elt) => elt.toListItem(this.lang, meetingOrganizer));
            this.isLoading = false;
        });
    }

    createNewMeeting(): void {
        this.meetingService.createMeetingAndRedirectToForm();
    }

    getNetworksTours(): void {
        if (this.accountService.getCurrentCtxOrganizationId() !== this.globals.NO_ORGA) {
            this.networks$ = this.networkService.getNetworksTours(this.accountService.getCurrentCtxOrganizationId());
        }
    }

    public checkIfNetworkMember(id: number): boolean {
        if (!this.networksByOrganization.value) {
            return;
        }
        return this.networksByOrganization.value.includes(id);
    }

    private setDefaultFilters = (newValue: SelectedFilter[]): void => {
        this.defaultFilters.next([...newValue]);
    };

    private getNetworksByOrganization = (): void => {
        this.selectedUserOrganizationId$
            .pipe(
                filter((x) => x !== undefined),
                distinctUntilChanged(),
                tap((id: number) => {
                    this.setDefaultFilters([
                        {
                            field: 'isPublished',
                            value: id === this.globals.SCENE_PRO_ORGID ? '' : 1
                        }
                    ]);
                }),
                filter((id: number) => this.accountService.getCurrentCtxOrganizationId() !== this.globals.NO_ORGA && !this.networksByOrganization.value.length),
                switchMap((id: number) => this.networkService.getNetworksByOrganization(id)),
                map((result: OrganizationNetworks) => this.networksByOrganization.next(result.networks)),
                take(1)
            )
            .subscribe();
    };

    private updateFiltersOnOrganizationChanged = (): void => {
        this.accountService.currentOrganizationChange
            .pipe(
                withLatestFrom(this.selectedUserOrganizationId$),
                filter(([value, id]: [any, number]) => id !== undefined),
                tap(([value, id]) => {
                    this.setDefaultFilters([
                        {
                            ...this.defaultFilters.value[0],
                            value: id === this.globals.SCENE_PRO_ORGID ? '' : 1
                        }
                    ]);
                }),
                switchMap(([value, id]: [any, number]) => this.networkService.getNetworksByOrganization(id)),
                map((result: OrganizationNetworks) => result.networks)
            )
            .subscribe((networks: number[]) => {
                this.networksByOrganization.next(networks);
                this.getAllMeetings();
                this.getNetworksTours();
            });
    };
}
