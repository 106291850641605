import { Component, Input, Output, EventEmitter, Optional } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { UploadFile } from 'ng-zorro-antd';
import { ImageCroppedEvent } from 'ngx-image-cropper-upgraded';
import { RideauNotificationService } from '../../services/rideau-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { StorageKey } from 'src/app/concepts/account/services/auth/auth.service';
import { Globals } from '@app/_configs/globals';
import { ImgUploadService, ImgUploadStatus } from './img-upload.service';

@Component({
    selector: 'app-img-upload',
    templateUrl: './img-upload.component.html',
    styleUrls: ['./img-upload.component.scss']
})
export class ImgUploadComponent implements ControlValueAccessor {
    private onChange: (value: string) => void;
    private onTouched: () => void;
    @Input() disabled: boolean;
    @Input() requiredTranslateKey = 'REQUIRED_ERROR';

    @Input() imageTitle: string;
    @Input() currentImage: string;
    @Input() uploadAction: string;
    @Input() uploadActionName: string;
    @Input() maxFileWeigth: number;
    @Input() isDisabled: boolean;
    @Input() maxCropperWidth = this.global.images.maxWidth;
    @Input() maxCropperHeight = this.global.images.maxHeight;
    @Output() onUploadFile: EventEmitter<string> = new EventEmitter();

    avatarUrl = '';
    croppedImage = '';
    imageChangedEvent = '';

    fileInput: any;

    loading = false;
    isVisible = false;

    constructor(
        private global: Globals,
        public translate: TranslateService,
        private notification: RideauNotificationService,
        private imgUploadService: ImgUploadService,
        @Optional() readonly ngControl: NgControl
    ) {
        ngControl && (ngControl.valueAccessor = this);
    }

    /*Avatar*/
    addNewAvatar() {
        this.avatarUrl = '';
        this.currentImage = '';
        this.fileInput = undefined;
        window.setTimeout(() => {
            document.getElementById('file-input-account').click();
        }, 1);
    }

    deleteAvatar() {
        this.avatarUrl = null;
        this.currentImage = null;
        this.fileInput = undefined;
        this.isVisible = false;

        this.modelChange(null);
    }

    base64toBlob = (base64Data, contentType) => {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = window.atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        //return new Blob(byteArrays, { type: contentType });
        return new File(byteArrays, 'image.png');
    };

    beforeUpload = (file: File) => {
        return new Observable((observer: Observer<boolean>) => {
            const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPGorPNG) {
                this.notification.error(this.translate.instant('FORM.FORMAT-NON-SUPPORTE'));
                observer.complete();
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.notification.error(this.translate.instant('FORM.FICHIER-TROP-VOLUMINEUX'));
                observer.complete();
                return;
            }
            this.checkImageDimension(file).then((dimensionRes) => {
                if (!dimensionRes) {
                    this.notification.error(this.translate.instant('ERRORS.LARGEUR-HAUTEUR-1000-MAX'));
                    observer.complete();
                    return;
                }
                observer.next(isJPGorPNG && isLt2M && dimensionRes);
                observer.complete();
            });
        });
    };

    private getBase64(img: File, callback: (img: string) => void): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result.toString()));
        reader.readAsDataURL(img);
    }

    private checkImageDimension(file: File): Promise<boolean> {
        return new Promise((resolve) => {
            const img = new Image(); // create image
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                resolve(height < this.global.images.maxHeight && width < this.global.images.maxWidth);
            };
        });
    }

    handleChange(info: { file: UploadFile }): void {
        switch (info.file.status) {
            case 'uploading':
                this.loading = true;
                this.imgUploadService.setCurrentStatus({ status: ImgUploadStatus.UPLOADING });
                break;
            case 'done':
                {
                    // Get this url from response
                    this.getBase64(info.file.originFileObj, (img: string) => {
                        this.loading = false;
                        this.avatarUrl = img;
                    });

                    const urlImageResponse = info.file.response.completeUrl;
                    this.onUploadFile.emit(urlImageResponse);
                    this.imgUploadService.setCurrentStatus({ status: ImgUploadStatus.SUCCESS, url: urlImageResponse });

                    this.modelChange(urlImageResponse);
                }
                break;
            case 'error':
                this.notification.error(this.translate.instant('FORM.ERREUR-RESEAU'));
                this.loading = false;
                this.imgUploadService.setCurrentStatus({ status: ImgUploadStatus.ERROR });
                break;
        }
    }

    async imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.avatarUrl = event.base64;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    saveCrop() {
        // console.log("Save Image");
        const [meta, data] = this.croppedImage.split(';');
        const [, contentType] = meta.split(':');
        const [, base64] = data.split(',');

        const formData: FormData = new FormData();
        const blob = this.base64toBlob(base64, contentType);

        formData.append(this.uploadActionName, blob);
        const request = new XMLHttpRequest();
        request.open('POST', this.uploadAction);
        request.setRequestHeader('Authorization', `Bearer ${localStorage.getItem(StorageKey.RIDEAU_TOKEN)}`);
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                const json = JSON.parse(request.responseText);
                this.avatarUrl = json.completeUrl;
                this.imgUploadService.setCurrentStatus({ status: ImgUploadStatus.SUCCESS, url: json.completeUrl });
                this.onUploadFile.emit(json.completeUrl);

                this.modelChange(json.completeUrl);
            }
        };
        request.send(formData);

        this.isVisible = false;
    }

    imageLoaded() {
        this.isVisible = true;
    }

    modelChange(value: string) {
        if (!value) {
            this.imgUploadService.setCurrentStatus({ status: ImgUploadStatus.DELETED });
        }
        if (this.ngControl) {
            this.onChange(value);
            this.onTouched();
        }
    }

    writeValue(value: string): void {
        this.currentImage = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
