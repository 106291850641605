
    <nz-form-item>
      <label [attr.for]="id">
        <ng-content></ng-content>
      </label>
      <nz-form-control>
        <quill-editor
          [attr.id]="id"
          [(ngModel)]="value"
          (ngModelChange)="ngModelChange()"
          [sanitize]="true"
          [modules]="modules"
          [placeholder]="'FORM.DESCRIPTION-PLACEHOLDER' | translate"
          [class.error]="ngControl.touched && ngControl.errors"
          [readOnly]="disable"
        >
        </quill-editor>
        <p
          *ngIf="countMaxLength"
          class="word-count"
          [class.error]="
            ngControl.touched && ngControl.errors && ngControl.errors.maxlength
          "
        >
          {{
            'RICH_TEXT_CHARACTERS'
              | translate
                : { actualLength: actualLength, requiredLength: countMaxLength }
          }}
        </p>
        <p
          *ngIf="
            ngControl.touched && ngControl.errors && ngControl.errors.maxlength
          "
          class="error"
        >
          {{ maxLengthTranslateKey | translate: ngControl.errors.maxlength }}
        </p>
        <p
          *ngIf="
            ngControl.touched && ngControl.errors && ngControl.errors.minlength
          "
          class="error"
        >
          {{ minLengthTranslateKey | translate: ngControl.errors.minlength }}
        </p>
        <p
          *ngIf="
            ngControl.touched && ngControl.errors && ngControl.errors.required
          "
          class="error"
        >
          {{ requiredTranslateKey | translate }}
        </p>
      </nz-form-control>
    </nz-form-item>
  