<ng-container *ngIf="isReady">
  <app-page-title [title]="pageTitle"
      [subtitle]="pageSubTitle"
      image="{{ headOrganization.logo }}"
      [isWithImagePage]="true"
      [useExport]="true">

      <button appButton class="blue mr-3" [routerLink]="['/admin/tour', tourId, 'edit'] | localize" *appIfAdmin>
        {{ 'FORM.MODIFIER' | translate }}
      </button>

      <button appButton *ngIf="isAdmin"  class="blue mr-3" [routerLink]="['/tour', tourId, 'edit', 'owner'] | localize">
        {{ 'FORM.MODIFIER' | translate }}
      </button>
      <div *ngIf="tourAccess">
        <button appButton class="mr-3" [routerLink]="['/tour', tourId, 'new', 'settings'] | localize" *appIfOwnerOf="tour">
          <i nz-icon aria-hidden="true" nzType="global" nzTheme="outline" style="padding-right:4px"></i>
          {{ 'NOUVELLE-TOURNEE' | translate }}
        </button>
      </div>


      <app-export-dropdown #exportDropdown
        [exportOptions]="exportOptions"
        (onExportSelected)="onExportCallback($event)">
      </app-export-dropdown>

  </app-page-title>

  <div class="container">
    <app-item-list
      [isAdmin]="contextOrgId === tour.organizationId"
      [itemList]="itemList"
      [filterList]="leftSideInterestFilters"
      [sortList]="sorts"
      [pagination]="pagination"
      [listItemsType]="listType"
      [isLoading]="isLoading"
      [useFilters]="true"
      [useViewSwitcher]="true"
      [useSearchBar]="true"
      [searchBarPlaceHolder]="'RECHERCHER-SHOWS' | translate"
      [useSorting]="true"
      [usePagination]="true"
      elementsLabel="{{ 'OFFRES-SPECTACLES' | translate | lowercase }}"
      [isToRate]="true"
      (rateItem)="onRateItem($event)"
      (updateKeepedShow)="onUpdateKeepedShow($event)"
      (filterChange)="onFilterChange($event)"
      (searchChange)="onSearchChange($event)"
      (sortChange)="onSortChange($event)"
      (pageChange)="onPageChange($event)"
      searchSessionStorageSuffix="submitted-shows"
    >

      <app-show-advanced-search #advancedSearch advanced-search searchSessionStorageSuffix="submitted-shows"
          [isTourShow]="true"
          (filtersChange)="onAdvancedFilterChange($event)">
      </app-show-advanced-search>

      <ng-container prefix *appIfOwnerOf="tour">
        <app-alert class="mb-3 success" *ngIf="!tour.isDepositOpen && tour.isVoteOpen">
          <i nz-icon nzType="unlock" nzTheme="fill"></i>
          <span [innerHTML]="'ACCES-DEPOT-DIFFUSEUR-OUVERT' | translate"></span>
          <button appButton [click]="closeForVote.bind(this)">{{ 'ACCES-DEPOT-FERMER' | translate }}</button>
        </app-alert>

        <app-alert class="mb-3 danger" *ngIf="itemList.length && !tour.isDepositOpen && !tour.isVoteOpen">
          <i nz-icon nzType="lock" nzTheme="fill"></i>
          <span [innerHTML]="'ACCES-DEPOT-DIFFUSEUR-FERME' | translate"></span>
          <button appButton [click]="openForVote.bind(this)">{{ 'ACCES-DEPOT-OUVRIR' | translate }}</button>
        </app-alert>

        <app-alert prefix class="mb-3 success" *ngIf="!tour.isVoteOpen && tour.isDepositOpen">
          <i nz-icon nzType="unlock" nzTheme="fill"></i>
          <span [innerHTML]="'ACCES-DEPOT-PRODUCTEUR-OUVERT-COURT' | translate"></span>
          <button appButton [click]="closeForDeposit.bind(this)">{{ 'FERMER-DEPOT' | translate }}</button>
        </app-alert>

        <app-alert prefix class="mb-3 danger" *ngIf="!tour.isVoteOpen && !tour.isDepositOpen && !tour.isDepositDone">
          <i nz-icon nzType="lock" nzTheme="fill"></i>
          <span [innerHTML]="'ACCES-DEPOT-PRODUCTEUR-FERME-COURT' | translate"></span>
          <button appButton [click]="openForDeposit.bind(this)">{{ 'OUVRIR-DEPOT' | translate }}</button>
        </app-alert>

      </ng-container>

    </app-item-list>
  </div>
</ng-container>

