import { ListItem } from '../../../shared/model/list-item.model';
import { Meeting } from '../../meeting/model/meeting.model';
import { Organization } from '../../organization/model/organization.model';
export interface IStand {
    id: number;
    meetingId: number;
    label: string;
    organizationTypeId: number;
    organizationId: number;
    isDisabled?: boolean;
    meeting: Meeting;
    organization: Organization;
    createdAt?: Date;
    updatedAt?: Date;
}

export class Stand implements IStand {
    id: number;
    meetingId: number;
    label: string;
    organizationTypeId: number;
    organizationId: number;
    isDisabled?: boolean;
    // Other properties
    meeting: Meeting;
    organization: Organization;

    constructor(data: any) {
        this.id = data[ 'id' ];
        this.meetingId = data[ 'meetingId' ];
        this.label = data[ 'label' ];
        this.organizationTypeId = data[ 'organizationTypeId' ];
        this.organizationId = data[ 'organizationId' ];

        this.meeting = data[ 'meeting' ] ? new Meeting(data[ 'meeting' ]) : null;
        this.organization = data[ 'organization' ] ? new Organization(data[ 'organization' ]) : null;
    }

    toListItem(lang: string): ListItem {
        const item = new ListItem();
        item.itemId = this.id;
        item.itemTitle = this.label;
        item.itemUrl = this.organization ? '' : null;
        item.itemInfo1 = this.organization ? this.organization.getTranslatedProperty(lang, 'name') : this.setDefaultOrg();

        return item;
    }

    toStandRequest() {
        return {
            id: this.id,
            meetingId: this.meetingId,
            label: this.label,
            organizationTypeId: this.organizationTypeId,
            organizationId: this.isDisabled ? null : this.organizationId,
            meetingOrganizationId: this.meeting.organizationId
        } as IStandRequest;
    }

    setDefaultOrg() {
        return this.organizationTypeId ? 'STAND-VACANT-' + this.organizationTypeId : 'STAND-VACANT';
    }
}

export interface IStandRequest {
    meetingId?: number;
    label?: string;
    organizationTypeId?: number;
    organizationId?: number;
    id?: number;
    meetingOrganizationId: number;
}

export interface IStandResponse {
    stands: IStand[];
    total: number;
}
