import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Pagination,
  SelectedFilter,
} from 'src/app/shared/model/list-item.model';
import { Globals } from '../../../../../_configs/globals';
import { AccountService } from './../../../../account/services/account.service';
import { Show } from './../../../model/show.model';
import { ShowService } from './../../../services/show.service';

@Component({
  selector: 'app-my-shows-little',
  templateUrl: './my-shows-little.component.html',
  styleUrls: ['./my-shows-little.component.scss'],
})
export class MyShowsLittleComponent implements OnInit {
  organisationFilter: SelectedFilter = {
    field: 'organizationId',
    value: 0,
  } as SelectedFilter;

  lang: string;
  imgFallback: string;
  activeRoute: string;

  showList: Show[] = [];

  pagination: Pagination = {
    offset: 0,
    limit: 5,
  } as Pagination;

  isLoading: boolean;

  isDisabled = false;

  constructor(
    private translate: TranslateService,
    private globals: Globals,
    private showService: ShowService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.imgFallback = this.globals.imgFallback;
    this.getMyShows();
    this.accountService.currentOrganizationChange.subscribe(() =>
      this.getMyShows()
    );
  }

  getMyShows() {
    this.isLoading = true;
    this.organisationFilter.value = this.accountService.getCurrentCtxOrganizationId();
    // Get my shows with filters & pagination
    this.showService
      .getShows([this.organisationFilter], this.pagination)
      .subscribe((shows: Show[]) => {
        this.showList = shows;
        this.isLoading = false;
      });
  }

  newShow() {
    this.isDisabled = true;
    this.showService.createBlankShowAndRedirectToForm();
  }
}
