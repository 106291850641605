export class WikiArtsDataValidation {
    static readonly wikidata = 'wikidata';
    static readonly artsdata = 'artsdata';
    static readonly wikidataUrlBase = 'www.wikidata.org/wiki/';
    static readonly artsdataUrlBase = 'kg.artsdata.ca/resource/';
    static readonly rawArtsdataIdRegExp = '^K\\d+-\\d+$';
    static readonly rawWikidatIdRegExp = '^Q[1-9]\\d*$';

    static getRegExp = (value: string): string => {
        if (value === WikiArtsDataValidation.wikidata) {
            return WikiArtsDataValidation.rawWikidatIdRegExp;
        }
        if (value === WikiArtsDataValidation.artsdata) {
            return WikiArtsDataValidation.rawArtsdataIdRegExp;
        }
        return null;
    };
}
