<div nz-row
     nzGutter="24">

  <div *ngIf="isLoading"
       class="loader">
    <i nz-icon
    aria-hidden="true"
       nzType="loading"
       nzTheme="outline"></i>
  </div>
  <div nz-col
       nzMd="8">
    <h2 class="mb-4">{{ 'FACTURATION' | translate}}</h2>
  </div>
  <div nz-col
       nzMd="16"
       class="text-right">

    <ng-container *ngIf="nbBillsNotSent > nbBillsNotSendable">
      <button *appPermission="4"
              (click)="sendTheBills()"
              class="btn btn--default">
        {{'FACTURER-LES-N-MEMBRES' | translate:nbItemIndicator }}
      </button>
    </ng-container>
    <ng-container *ngIf="nbBillsNotSent === 0">
      <button *appPermission="4"
              (click)="startNewBillingPeriod()"
              class="btn btn--default ml-3">
        {{'NOUVELLE-FACTURATION' | translate}}
      </button>
    </ng-container>
  </div>
</div>

<section>
  <div nz-row
       nzGutter="24">
    <div nz-col
         nzMd="24">

      <div nz-row
           class="mb-3">
        <!--  SEARCH -->
        <div nz-col
             nzMd="10"
             class="searchbar mb-3 mb-md-0">
          <nz-input-group>
            <input type="text"
                   nz-input
                   [formControl]="searchFormControl"
                   placeholder="{{'RECHERCHER-PAR-NOM' | translate}}">
          </nz-input-group>
        </div>
        <!-- FILTERS -->
        <div nz-col
             nzMd="14"
             class="text-md-right">
          <nz-button-group>
            <button class="btn btn--light btn-group__item"
            [ngClass]="{'active': active === 'ALL'}"
                    (click)="filterList('ALL')">{{'TOUTES'|translate}}</button>
            <button class="btn btn--light btn-group__item"
            [ngClass]="{'active': active === 'DATA'}"
                    (click)="filterList('DATA')">{{"DONNEES-MANQUANTES"|translate}}</button>
            <button class="btn btn--light btn-group__item"
            [ngClass]="{'active': active === 'PAYED'}"
                    (click)="filterList('PAYED')">{{'PAYEES'|translate}}</button>
            <button class="btn btn--light btn-group__item"
            [ngClass]="{'active': active === 'NOT-PAYED'}"
                    (click)="filterList('NOT-PAYED')">{{'NON-PAYEES'|translate}}</button>
          </nz-button-group>
        </div>
      </div>

      <!-- ELEMENTS -->
      <div nz-row
           class="mb-3">

        <div *ngIf="isLoading"
             class="loader">
          <i nz-icon
          aria-hidden="true"
             nzType="loading"
             nzTheme="outline"></i>
        </div>
        <div nz-col>
          <div class="table">
            <ng-container *ngFor="let billing of billingList">
              <app-membership-billing-item [billing]="billing"
                                           (billingUpdated)="setSentBillsIndicators()"></app-membership-billing-item>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
