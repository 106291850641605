import { Routes } from '@angular/router';
import { PaidBillComponent } from '../../shared/components/prints/paid-bill/paid-bill.component';
import { PermissionGuard } from '../account/services/permission-guard.service';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserAccountComponent } from './components/user-account.component';
const SCENE_PRO_ID = 1;

export const AdminUserRoutes: Routes = [
  {
    path: '',
    component: UserListComponent,
  },
  {
    path: '',
    component: UserAccountComponent,
    data: { openedTab: 'profile' },
  },
  {
    path: 'info/:userId',
    component: UserAccountComponent,
    data: { openedTab: 'profile' },
  },
  {
    path: 'password',
    component: UserAccountComponent,
    data: { openedTab: 'password' },
  },
  {
    path: 'organizations/:userId',
    component: UserAccountComponent,
    data: { openedTab: 'organizations' },
  },

  {
    path: 'account',
    component: UserAccountComponent,
    data: { openedTab: 'account' },
  },
  {
    path: 'billing/:userId',
    component: UserAccountComponent,
    data: { openedTab: 'billing' },
  },
  {
    path: 'bill/:userId/:transactionId',
    component: PaidBillComponent,
  },
  {
    path: 'bill/:userId/:transactionId/refund',
    component: PaidBillComponent,
    canActivate: [PermissionGuard],
  },
];
