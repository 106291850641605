<ng-container *ngIf="isReady">
    <div class="page-template-head">
        <div class="container">
            <div nz-row>
                <div nz-col>
                    <div *ngIf="show.statusId === 2" class="approval-box">
                        <p>{{ 'SHOW-APPROVAL-STATE' | translate }}</p>
                        <div>
                            <button *appPermission="4" (click)="reject()" nz-button [nzLoading]="isLoading" class="btn btn--default rejected">
                                {{ 'REJETER' | translate }}
                            </button>
                            <button *appPermission="4" (click)="approve()" nz-button [nzLoading]="isLoading" class="btn btn--default ml-2 approved">
                                {{ 'APPROUVER' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-row nzType="flex" nzJustify="space-between" class="d-md-flex align-items-center">
                <div nz-col nzSpan="16" class="page-title--title">
                    <!-- TITLE BAR -->
                    <h2>{{ show.getTranslatedProperty(lang, 'title') }}</h2>
                </div>
                <div nz-col>
                    <!-- STATUS -->
                    <span *ngIf="show.statusId === 2" class="rect">{{ 'EN-ATTENTE-APPROBATION' | translate }}</span>
                    <span *ngIf="show.statusId === 4" class="rect">{{ 'STATUS.REJETEE' | translate }}</span>
                    <span *ngIf="show.statusId === 5" class="rect">{{ 'STATUS.EXPIRE' | translate }}</span>
                    <span *ngIf="(show.statusId === 1 || show.statusId === 4) && !isInCart" class="mr-5">
                        <button
                            *appPermission="2; objectOrganizationId: show.organizationId; shouldBeApproved: false"
                            (click)="deleteShow()"
                            nz-button
                            [disabled]="isLoading"
                            [nzLoading]="isDeleteLoading"
                            class="btn btn--default"
                        >
                            {{ 'FORM.SUPPRIMER' | translate }}
                        </button>
                    </span>
                </div>
                <div nz-col nzSpan="6" style="text-align: right">
                    <p class="mb-1" à *ngIf="show.statusId === 3">
                        <a class="btn btn-default" (click)="breadcrumbService.resetBreadcrumb()" [routerLink]="showUrl | localize">
                            <i nz-icon nzType="eye" nzTheme="outline" class="mr-2" aria-hidden="true"></i>
                            <span>{{ 'VOIR-LE-PROFIL-PUBLIC' | translate }}</span>
                        </a>
                    </p>
                    <ng-container *ngIf="totalErrorCount$ | async as totalErrorCount">
                        <p class="mb-1" *ngIf="show.statusId !== 3 && totalErrorCount > 0">
                            <span class="error-count-label d-flex align-items-center">
                                <i class="icon-warning clr-red mr-2" aria-hidden="true"></i>
                                <span>
                                    {{ totalErrorCount }}
                                    {{ totalErrorCount > 1 ? ('CHAMPS-INCOMPLETS' | translate) : ('CHAMP-INCOMPLET' | translate) }}
                                    :
                                    <a *ngIf="!displayErrors" (click)="toggleDisplayErrors()">
                                        {{ 'AFFICHER' | translate }}
                                    </a>
                                    <a *ngIf="displayErrors" (click)="toggleDisplayErrors()">
                                        {{ 'CACHER' | translate }}
                                    </a>
                                </span>
                            </span>
                        </p>
                    </ng-container>
                    <!-- On affiche le boutton pour payer si le show est valide, qu'il n'est pas déjà payé ou déjà dans le panier -->
                    <ng-container *ngIf="(totalErrorCount$ | async) === 0 && show.isPaid === 0 && !isInCart">
                        <button nz-button class="btn btn--default" *appPermission="1; objectOrganizationId: show.organizationId" (click)="payNow()">
                            {{ 'PAYER-MAINTENANT' | translate }}
                        </button>
                    </ng-container>

                    <div *ngIf="show.statusId === 4 && (totalErrorCount$ | async) === 0">
                        <button
                            *appPermission="3; objectOrganizationId: show.organizationId; shouldBeApproved: false"
                            (click)="submitForApproval()"
                            nz-button
                            [nzLoading]="isLoading"
                            class="btn btn--default"
                        >
                            {{ 'FORM.SOUMETTRE-POUR-APPROBATION' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="tabs$ | async as tabs">
        <div nz-row nzGutter="24">
            <div nz-col nzMd="6">
                <div class="list-filters">
                    <div class="list-filters__box">
                        <ul nz-menu>
                            <ng-container *ngFor="let tab of tabs">
                                <li
                                    nz-menu-item
                                    class="errorCount list-filters__item"
                                    [nzSelected]="(current$ | async) === tab.id"
                                    [routerLink]="['../' + tab.id] | localize"
                                    routerLinkActive="active"
                                >
                                    {{ 'SHOW-STEP.' + tab.id | translate }}
                                    <nz-badge *ngIf="displayErrors" [nzCount]="tab.errorCount" [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>

            <div nz-col nzMd="18">
                <!-- IDENTIFICATION -->
                <ng-container *ngIf="(current$ | async) === showTabIdEnum.IDENTIFICATION">
                    <app-show-identification-form
                        [(show)]="show"
                        (submitCallback)="saveShow($event)"
                        [isLoading]="isLoading"
                        [displayErrors]="displayErrors"
                    ></app-show-identification-form>
                </ng-container>

                <!-- CLASSIFICATION -->
                <ng-container *ngIf="(current$ | async) === showTabIdEnum.CLASSIFICATION">
                    <app-show-classification-form
                        [(show)]="show"
                        (submitCallback)="saveShow($event)"
                        [isLoading]="isLoading"
                        [displayErrors]="displayErrors"
                    ></app-show-classification-form>
                </ng-container>

                <!-- CACHET, LOGISTIQUE -->
                <ng-container *ngIf="(current$ | async) === showTabIdEnum.CACHET">
                    <app-show-cachet-form [(show)]="show" (submitCallback)="saveShow($event)" [isLoading]="isLoading" [displayErrors]="displayErrors"></app-show-cachet-form>
                </ng-container>

                <!-- CONTACTS -->
                <ng-container *ngIf="(current$ | async) === showTabIdEnum.CONTACTS">
                    <app-show-contacts-form [(show)]="show" (submitCallback)="saveShow($event)" [isLoading]="isLoading" [displayErrors]="displayErrors"></app-show-contacts-form>
                </ng-container>

                <!-- MATERIEL -->
                <ng-container *ngIf="(current$ | async) === showTabIdEnum.MATERIEL">
                    <app-show-materiel-form [(show)]="show" (submitCallback)="saveShow($event)" [isLoading]="isLoading" [displayErrors]="displayErrors"></app-show-materiel-form>
                </ng-container>

                <!-- MARKETING -->
                <ng-container *ngIf="(current$ | async) === showTabIdEnum.MARKETING">
                    <app-marketing-details [show]="show"></app-marketing-details>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
