
export interface Translation {
    langId: string,

    [ property: string ]: string
}

export abstract class Translatable {
    public trans: Translation[];

    public getTranslatedProperty(currentLang = 'fr', property: string): string {
        if (!this.trans) {
            this.trans = [];
        }
        let transObject = this.trans.find((elt: Translation) => elt.langId === currentLang);
        if (!transObject) {
            return '';
        }
        // 🔐 value cannot be null and an empty string must be returned
        return transObject[ property ] || '';
    }

    public setTranslatedProperty(
        currentLang = 'fr',
        property: string,
        value: string
    ): void {
        if (!this.trans) {
            this.trans = [];
        }
        let transObj = this.trans.find((elt) => elt.langId === currentLang);
        if (!transObj) {
            transObj = { langId: currentLang };
            this.trans.push(transObj);
        }
        transObj[ property ] = value;
    }
}
