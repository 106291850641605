import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-search-bar',
  templateUrl: './dynamic-search-bar.component.html',
  styleUrls: ['./dynamic-search-bar.component.scss']
})
export class DynamicSearchBarComponent implements OnInit, OnDestroy {

  @Input() placeholder : string = 'RECHERCHER';

  @Input() timeToEmit: number = 1000;

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  showResult = false

  private searchStringSubject$ : Subject<string> = new Subject();
  private searchStringObservable$: Observable<string> = this.searchStringSubject$.asObservable();
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {

    this.intiSearchStringObservable()
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  onChange(value){
    this.searchStringSubject$.next(value);
  }

  private intiSearchStringObservable(){
    this.subscription = this.searchStringObservable$.pipe(
      distinctUntilChanged(),
      debounceTime(this.timeToEmit),
    ).subscribe( newValueString => {
      this.valueChanged.emit(newValueString);
    })
  }

}
