<div nz-row
     nzGutter="16"
     class="px-3"
     [formGroup]="formGroup">

  <nz-form-item>
    <nz-form-control>
      <label>{{ 'FORM.AJOUTE-STANDS' | translate}} </label>
      <div nz-row>
        <div nz-col [nzSpan]="10">
          <label class="page-template-labels--black">{{'FORM.NUMEROS-DE' | translate}} *</label>
        </div>
        <div nz-col [nzSpan]="12" class="d-flex text-right">
          <input id="numberFrom"
                 type="number"
                 min="0"
                 class="w-50"
                 nzSize="default"
                 formControlName="numberFrom" required>
          <label class="page-template-labels--black mx-3"> à </label>
          <input id="numberTo"
                 type="number"
                 min="1"
                 class="w-50"
                 nzSize="default"
                 formControlName="numberTo" required>
        </div>
      </div>
      <nz-form-explain class="has-error"
                       *ngIf="displayErrors && (formGroup.errors && formGroup.errors['invalidRange'] || formGroup.controls['numberFrom'].errors || formGroup.controls['numberTo'].errors)">
        <span *ngIf="formGroup.errors['invalidRange']" class="mb-1">
          {{'ERRORS.INVALID-RANGE'|translate}}
          <br>
        </span>
        <span *ngIf="formGroup.controls['numberFrom'].errors || formGroup.controls['numberTo'].errors" class="mb-1">
          {{'ENTRER-VALEUR'|translate}}
        </span>
      </nz-form-explain>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="mb-2">
    <nz-form-control>
      <div nz-row>
        <div nz-col [nzSpan]="10">
          <label class="page-template-labels--black">{{'AJOUTER-PREFIXE' | translate}} *</label>
        </div>
        <div nz-col [nzSpan]="14">
          <input id="prefix"
                 nzSize="default"
                 [formControlName]="'prefix'" required>
        </div>
      </div>

      <nz-form-explain class="has-error"
                       *ngIf="displayErrors && formGroup.controls['prefix'].errors">
        {{'ENTRER-VALEUR'|translate}}
      </nz-form-explain>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control>
      <div nz-row>
        <div nz-col [nzSpan]="10">
          <label nz-checkbox class="page-template-labels--black" [formControlName]="'suffixChecked'">
            <span class="ng-checkbox-text">{{'AJOUTER-SUFFIXE' | translate}}</span>
          </label>
        </div>
        <div nz-col [nzSpan]="14" *ngIf="updateSuffix()">
          <input id="suffix"
                 nzSize="default"
                 formControlName="suffix">
        </div>
      </div>
    </nz-form-control>
  </nz-form-item>

</div>
