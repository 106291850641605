import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LightboxModule } from 'ngx-lightbox';
import { QuillModule } from 'ngx-quill';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ShowModule } from 'src/app/concepts/show/show.module';
import { SharedModule } from 'src/app/shared/shared.module';

/*Components*/
import { AddShowOfferFormComponent } from './components/add-tour-show/add-show-offer-form.component';
import { TourShowSingleComponent } from './components/tour-show-single/tour-show-single.component';
import { LocalizeRouterModule } from 'localize-router';
import { SubmitedShowsComponent } from './components/submited-shows/submited-shows.component';
import { EditTourFormComponent } from './components/edit-tour/edit-tour-form.component';
import { ListedTourComponent } from './components/listed-tour/listed-tour.component';
import { EditTourOwnerComponent } from './components/edit-tour/edit-tour-owner.component';
import { EditTourComponent } from './components/edit-tour/edit-tour.component';
import { EditTourSettingsComponent } from './components/edit-tour/edit-tour-settings.component';
import { EditTourAdminsComponent } from './components/edit-tour/edit-tour-admins.component';
import { EditTourDiffuseursComponent } from './components/edit-tour/edit-tour-diffuseurs.component';
import { EditTourNavComponent } from './components/edit-tour/edit-tour-nav.component';
import { ArchivedToursComponent } from './components/archived/archived-tours.component';

@NgModule({
  declarations: [
    AddShowOfferFormComponent,
    TourShowSingleComponent,
    SubmitedShowsComponent,
    EditTourFormComponent,
    ListedTourComponent,
    EditTourOwnerComponent,
    EditTourComponent,
    EditTourSettingsComponent,
    EditTourAdminsComponent,
    EditTourDiffuseursComponent,
    EditTourNavComponent,
    ArchivedToursComponent
  ],
  imports: [
    CommonModule,
    ShowModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    LightboxModule,
    QuillModule,
    SharedModule,
    LocalizeRouterModule,
    SharedModule
  ],
  exports: [AddShowOfferFormComponent, SubmitedShowsComponent],
})
export class ToursModule {}
