/**
 * Enumeration of available participant types for tariffs.
 * @readonly
 * @enum {number}
 * @property {number} ANY_TYPE - Any participant type.
 * @property {number} PRODUCER - Participant type producer.
 * @property {number} DIFFUSER - Participant type diffuser.
 * @property {number} ORGA_MEMBER - Participant type organization member.
 * @property {number} GOVERNMENT_INSTITUTION - Participant type government institution.
 * @property {number} ROOM_MANAGER - Participant type room manager.
 * @property {number} PROVIDER - Participant type provider.
 * @property {number} OTHER - Participant type other.
 */
export enum TarifTypes {
    ANY_TYPE = 0,
    PRODUCER = 1,
    DIFFUSER = 2,
    ORGA_MEMBER = 3,
    GOVERNMENT_INSTITUTION = 4,
    ROOM_MANAGER = 5,
    PROVIDER = 6,
    OTHER = 7
}

export enum VolumeTypes {
    ALL = 0,
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4
}
