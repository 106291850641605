<!-- Spinner de chargement -->
<div *ngIf="isLoading" class="container header">
    <div class="loader">
        <i nz-icon aria-hidden="true" nzType="loading" nzTheme="outline"></i>
    </div>
</div>

<ng-container *ngIf="!isLoading">
    <div class="container-fluid bg-gray header">
        <div class="container">
            <div nz-row>
                <!-- TODO:Infos vitrine -->

                <!-- Aprouver / Refuser -->
                <div class="bordered-header" *appPermission="1;objectOrganizationId:meeting.organizationId">
                    <span>{{'DEPOSE-LE' | translate}} {{vitrine.createdAt | date: defaultDateFormat}} {{'DANS' |
                        translate}}
                        {{vitrine.product.getTranslatedProperty(lang,'name')}}</span>

                    <button class="btn btn--small" (click)="refuse()" nz-button nzType="default" [disabled]="currentVitrineStatus === 3">{{'REFUSER' |
                        translate}}
                    </button>
                    <button class="btn btn--small" (click)="accept()" nz-button nzType="default" [disabled]="currentVitrineStatus === 2">{{'ACCEPTER' |
                        translate}}
                    </button>
                    <ng-container>
                        <div class="icon-trash-container d-flex justify-content-center align-items-center ml-5"
                             (click)='softDeleteVitrine()'>
                            <i class="icon-trash"></i>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>

    <!-- Show single component -->
    <app-show-single [disableFavorites]="true"></app-show-single>

    <!-- Données supplémentaires si currentOrga = meeting orga -->
    <div *ngIf="isMeetingOwner || isJuryMember || isRideau" class="container-fluid bg-gray py-5">
        <div class="container">
            <!-- Matériel suplémentaire RIDEAU -->
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3>
                    {{ 'MATERIEL-SUPPLEMENTAIRE' | translate }}
                </h3>
                <button appButton class="btn btn--large" [disabled]="vitrineMaterialEdition"
                    *ngIf="(isMeetingOwner || isRideau) && !isJuryMember" (click)="openVitrineMaterialEdition()">
                    <i class="icon-pencil" aria-hidden="true"></i>
                    {{'FORM.MODIFIER' | translate}}
                </button>
            </div>
            <hr class="u-opacity(4)">
            <div class="row mb-3">
                <div class="col-md-6 col-xs-12">
                    <label class="u-opacity(4) d-flex py-2">{{'FORM.LIEN' | translate}}&nbsp;1</label>
                    <ng-container *ngIf="isAudio(vitrine.mediaUrl); else videoLink">
                        <app-audio-player [src]="vitrine.mediaUrl"></app-audio-player>
                    </ng-container>
                    <ng-template #videoLink>
                        <app-video-player [src]="vitrine.mediaUrl"
                            [password]="vitrine.mediaPassword"></app-video-player>
                    </ng-template>
                </div>
                <div class="col-md-6 col-xs-12" *ngIf="vitrine.additionalUrl">
                    <label class="u-opacity(4) d-flex py-2">{{'FORM.LIEN-DEMO-2' | translate}}</label>
                    <ng-container *ngIf="isAudio(vitrine.additionalUrl); else videoLink2">
                        <app-audio-player [src]="vitrine.additionalUrl"></app-audio-player>
                    </ng-container>
                    <ng-template #videoLink2>
                        <app-video-player [src]="vitrine.additionalUrl"
                            [password]="vitrine.additionalMediaPassword"></app-video-player>
                    </ng-template>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-md-3 col-xs-12">
                    <hr class="u-opacity(4)">
                    <label class="u-opacity(4) pb-1">{{"DOSSIER-PRESENTATION-SIMPLE" | translate}} :</label>
                    <ul class="p-0">
                        <ng-container *ngFor="let media of fullFileList$ | async">
                            <li class="pb-1 d-flex">
                                <a href="{{media.file}}" class="u-link-primary d-flex u-gap(xxs) align-items-center"
                                    target="_blank"><i aria-hidden="true" class="icon-arrow-down"></i>
                                    <span class="pl-1">{{getAdditionalMaterialType(media)}}</span></a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div class="col-md-3 col-xs-12">
                    <hr class="u-opacity(4)">
                    <label class="u-opacity(4) pb-1">{{'TYPE-DE-JURY-JEUNE-PUBLIC' | translate}}</label>
                    <p>{{(!!vitrine.isYoungAudience ? 'OUI' : 'NON') | translate }}</p>
                </div>
                <div class="col-md-3 col-xs-12">
                    <hr class="u-opacity(4)">
                    <label class="u-opacity(4) pb-1">{{'TOURS.INFORMATION-SPECTACLE-SHORT-PRESENTATION' | translate}}</label>
                    <p>{{(!!vitrine.isExpress ? 'OUI' : 'NON') | translate }}</p>
                </div>
            </div>
        </div>
    </div>

   <!-- FORM RIDEAU pour modification -->
    <ng-container *ngIf="vitrineMaterialEdition">
        <div class="container">
            <app-vitrine-rideau-form [rideauForm]="rideauForm" [materielSupList]="materielSupList">
            </app-vitrine-rideau-form>
            <!-- Boutons -->
            <div class="footer-buttons">
                <a (click)="cancel()">{{ 'ANNULER' | translate }}</a>
                <button nz-button (click)="submitVitrineMaterialEdition()" nzType="primary" class="mr-2">
                    {{ 'ENREGISTER' | translate }}
                </button>
            </div>
        </div>
    </ng-container>
</ng-container>
