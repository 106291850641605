import { AbstractControl, ValidationErrors } from '@angular/forms';

export function noWhitespaceValidator(
  control: AbstractControl
): ValidationErrors | null {
  const isWhitespace = ((control.value as string) || '').trim().length === 0;
  return !isWhitespace ? null : { required: true };
}

export function noBlankArtists(
  control: AbstractControl
): ValidationErrors | null {
  control.value.forEach((element) => {
    if (((element as string) || '').trim().length === 0) {
      return { whitespace: true };
    }
  });
  return null;
}
/*
 * For template-driven form validation (current use is richtext description box)
 */
export function isBlankValidator(input: string): boolean {
  return ((input as string) || '').replace(/<[^>]*>/g, '').trim().length === 0;
}
