
    <nz-form-control [nzValidateStatus]="validateStatus">
      <nz-time-picker
        class="w-100"
        [(ngModel)]="selected"
        (ngModelChange)="ngModelChange()"
        nzFormat="HH:mm"
        [nzDisabled]="disabled"
      >
      </nz-time-picker>
    </nz-form-control>
  