
    <app-page-title [title]="'TOURNEES-ARCHIVEES' | translate"></app-page-title>
    <div *ngIf="(activatedRoute.data | async) as routeData" class="tours">
      <div *ngFor="let tour of routeData.archivedPaidTours" class="tour">
        <div>
          <div class="name">{{ tour.getTranslatedProperty(translateService.currentLang, 'name') }}</div>
          <div class="dates">{{ tour.startDate | date: 'longDate' }} au {{ tour.endDate | date: 'longDate' }}</div>
        </div>
        <button appButton class="btn-square" [routerLink]="['/tour', tour.id, 'edit', 'settings'] | localize">
          <i class="icon-pencil" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  