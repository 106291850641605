import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Permission } from 'src/app/shared/enums/roles.enum';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent {
  @Input() title: string;
  @Input() buttonText: string;
  @Input() buttonLink: string;
  @Input() buttonIsLoading: string;
  // inputs pour permissions sur bouton
  @Input() permission?: Permission;
  @Input() organizationId: number;
  @Input() orgaType: OrganizationTypes;
  @Input() shouldBeApproved = true;
  // pour pouvoir associer un callback au click au lieu d'une navigation
  @Output() buttonClicked = new EventEmitter();
}
