import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Activity } from '../../../model/activity.model';
import {
  Filter,
  ListItem,
  Pagination,
} from 'src/app/shared/model/list-item.model';
import { ActivityService } from '../../../services/activity.service';
import { RowTypes } from 'src/app/shared/enums/row-types.enum';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { ParticipantMeeting } from '../../../../meeting/model/participant.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-participants-form',
  templateUrl: './activity-participants-form.component.html',
  styleUrls: ['./activity-participants-form.component.scss'],
})
export class ActivityParticipantsFormComponent implements OnInit {
  @Input() activity: Activity;

  activityParticipantsItems: ListItem[];
  filterList: Filter;
  pagination: Pagination = new Pagination();
  isLoading = true;
  searchText: string;
  lang: string;
  activityParticipantsSize: EventEmitter<number> = new EventEmitter<number>();

  listType: RowTypes = RowTypes.CONTACT;

  constructor(
    private activityService: ActivityService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.initPagination();
    this.getParticipantsForActivity();
  }

  getParticipantsForActivity(): void {
    this.isLoading = true;
    this.activityService
      .getParticipantsForActivity(
        this.activity.meetingId,
        this.activity.id,
        true,
        this.pagination,
        this.searchText
      )
      .subscribe((participants) => {
        // Participants to listItem
        this.activityParticipantsItems = participants.map(
          (activityParticipation) => {
            const participant: ParticipantMeeting = new ParticipantMeeting(
              activityParticipation
            );

            if (participant.member && participant.member.organization) {
              participant.member.organization = new Organization(
                participant.member.organization
              );
            }

            return participant.toListItem(this.lang);
          }
        );

        this.activityParticipantsSize.emit(this.pagination.total);
        this.isLoading = false;
      });
  }

  onSearchChange(newSearchText: string): void {
    this.searchText = newSearchText;
    this.getParticipantsForActivity();
  }

  onPageChange(newPage: Pagination): void {
    this.pagination.offset = newPage.offset;
    this.getParticipantsForActivity();
  }

  initPagination(): void {
    this.pagination.limit = 12;
    this.pagination.offset = 0;
    this.pagination.total = 0;
  }
}
