import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from '@app/_configs/globals';
import { ShowContact } from '@app/concepts/show/model/show.model';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';

@Component({
    selector: 'app-item-row-contact',
    templateUrl: './item-row-contact.component.html',
    styleUrls: [ './item-row-contact.component.scss' ],
})
export class ItemRowContactComponent implements OnInit {
    @Input() itemLoopOptions: any;
    @Input() contact: any;
    @Input() showForm: boolean;
    @Input() isDisabled = false;

    @Input() rowItemType = String;

    @Output() modifyContact: EventEmitter<ShowContact> = new EventEmitter<ShowContact>();
    @Output() removeContact: EventEmitter<any> = new EventEmitter<any>();
    @Output() hideOtherModifyForms: EventEmitter<any> = new EventEmitter<any>();

    isVisible = false;
    modifyContactForm: FormGroup;

    constructor(
        private modalService: NzModalService,
        private fb: FormBuilder,
        public translate: TranslateService,
        private globals: Globals
    ) { }

    ngOnInit(): void {
        this.modifyContactForm = this.fb.group({
            id: [ this.contact.id ],
            firstName: [ this.contact.firstName, [ Validators.required ] ],
            lastName: [ this.contact.lastName, [ Validators.required ] ],
            title: [ this.contact.title, [ Validators.required ] ],
            phone: [ this.contact.phone, [ Validators.required ] ],
            phonePostNumber: [ this.contact.phonePostNumber ],
            email: [ this.contact.email, [ Validators.pattern(this.globals.emailRegex), Validators.required ] ],
        });
    }

    showModifyForm(): void {
        this.hideOtherModifyForms.emit(this.contact.id);
    }

    closeForm(): void {
        this.hideOtherModifyForms.emit(null);
    }

    modifyContactFromShow(): void {
        this.modifyContact.emit(new ShowContact(this.modifyContactForm.value));
        this.showForm = false; //close Modify Form
    }

    removeContactFromShow(contactId): void {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.removeContact.emit(contactId);
            },
        });
    }
}
