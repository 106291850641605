import { Translatable } from 'src/app/shared/model/translatable.model';

export class CountryState extends Translatable {
    countryId: number;
    id: number;
    constructor(data: any) {
        super();
        this.countryId = data.countryId;
        this.id = data.id;
        this.trans = data.trans;
    }
}
