import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
export class MembershipConfig {
  organizationHeadId: number;
  value1: number;
  description1: string;
  value2: number;
  description2: string;
  value3: number;
  description3: string;
  value4: number;
  description4: string;
  value5: number;
  description5: string;
  value6: number;
  description6: string;
  value7: number;
  description7: string;
  value8: number;
  description8: string;
  value9: number;
  description9: string;
  value10: number;
  description10: string;

  private membershipConfigForm: FormGroup;

  constructor(datas: Record<string, any>) {
    this.organizationHeadId = datas['organizationHeadId'];
    this.value1 = datas['value1'];
    this.description1 = datas['description1'];
    this.value2 = datas['value2'];
    this.description2 = datas['description2'];
    this.value3 = datas['value3'];
    this.description3 = datas['description3'];
    this.value4 = datas['value4'];
    this.description4 = datas['description4'];
    this.value5 = datas['value5'];
    this.description5 = datas['description5'];
    this.value6 = datas['value6'];
    this.description6 = datas['description6'];
    this.value7 = datas['value7'];
    this.description7 = datas['description7'];
    this.value8 = datas['value8'];
    this.description8 = datas['description8'];
    this.value9 = datas['value9'];
    this.description9 = datas['description9'];
    this.value10 = datas['value10'];
    this.description10 = datas['description10'];
  }

  getMembershipConfigForm(fb: FormBuilder): FormGroup {
    if (!this.membershipConfigForm) {
      this.membershipConfigForm = fb.group({
        organizationHeadId: [this.organizationHeadId],
        value1: [this.value1],
        description1: [this.description1],
        value2: [this.value2],
        description2: [this.description2],
        value3: [this.value3],
        description3: [this.description3],
        value4: [this.value4],
        description4: [this.description4],
        value5: [this.value5],
        description5: [this.description5],
        value6: [this.value6],
        description6: [this.description6],
        value7: [this.value7],
        description7: [this.description7],
        value8: [this.value8],
        description8: [this.description8],
        value9: [this.value9],
        description9: [this.description9],
        value10: [this.value10],
        description10: [this.description10],
      });
    }

    return this.membershipConfigForm;
  }
}
