import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// ngx-translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// NG-Zorro : Ant for angular
import { fr_FR, NgZorroAntdModule, NZ_I18N, NZ_NOTIFICATION_CONFIG } from 'ng-zorro-antd';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Modules
import { AccountModule } from './concepts/account/account.module';
import { AuthGuard } from './concepts/account/services/auth/auth-guard.service';
// Guard
import { AuthInterceptor } from './concepts/account/services/auth/auth-interceptor.service';
import { AuthService } from './concepts/account/services/auth/auth.service';
import { PermissionGuard } from './concepts/account/services/permission-guard.service';
import { ActivityModule } from './concepts/activities/activity.module';
import { MeetingModule } from './concepts/meeting/meeting.module';
import { OrganizationModule } from './concepts/organization/organization.module';
import { ShowModule } from './concepts/show/show.module';
import { StandModule } from './concepts/stand/stand.module';
import { VitrineModule } from './concepts/vitrine/vitrine.module';
import { ToursModule } from './concepts/tours/tours.module';
import { MyMonitoringService } from './monitoring/monitoring.service';
import { BottinAdvancedSearchComponent } from './pages/bottin/bottin-advanced-search/bottin-advanced-search.component';
import { BottinComponent } from './pages/bottin/bottin.component';
import { BillsToPayComponent } from './pages/dashboard/bills-to-pay/bills-to-pay.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HelpComponent } from './pages/help/help.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ProMeetingComponent } from './pages/pro-meeting/pro-meeting.component';
import { RegisterComponent } from './pages/register/register.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { ErrorsHandler } from './shared/error-handling/errors-handler';
import { BreadcrumbService } from './shared/services/breadcrumb.services';
import { SharedModule } from './shared/shared.module';
import { Globals } from './_configs/globals';
import { PublicationModule } from './concepts/publication/publication.module';
import { PromotionModule } from './concepts/promotion/promotion.module';
import { TagsModule } from './concepts/tags/tags.module';
import { ImageCropperModule } from 'ngx-image-cropper-upgraded';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { NotificationsModule } from './concepts/notifications/notifications.module';
registerLocaleData(localeFr);

import { TimeagoModule, TimeagoIntl, TimeagoFormatter, TimeagoCustomFormatter } from 'ngx-timeago';
import { LegalComponent } from './pages/legal/legal.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { CalendarPageComponent } from './pages/calendar/calendar.component';
import { TourManagerComponent } from './pages/calendar/tour-manager/tour-manager.component';
import { SelectedTourManagerComponent } from './pages/calendar/selected-tour-manager/selected-tour-manager.component';
import { ProgramModule } from './concepts/program/program.module';
import { TourAccessManagerComponent } from './pages/calendar/tour-access-manager/tour-access-manager.component';
import { CacheInterceptor } from './cache.interceptor';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { initializeFactory } from './app.initializer';
import { EmailConfirmationComponent } from './pages/email-confirmation/email-confirmation.component';
import { UnauthorizeErrorInterceptor } from './shared/interceptors/UnauthorizedError.interceptor';
import { IsNotLogginGuard } from './shared/guards/is-not-loggin.guard';
import { WINDOW_PROVIDERS } from './shared/services/window.provider';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        DashboardComponent,
        HelpComponent,
        LogoutComponent,
        ProMeetingComponent,
        BottinComponent,
        BottinAdvancedSearchComponent,
        ShoppingCartComponent,
        NotificationsComponent,
        BillsToPayComponent,
        LegalComponent,
        PrivacyComponent,
        CalendarPageComponent,
        TourManagerComponent,
        TourAccessManagerComponent,
        SelectedTourManagerComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        EmailConfirmationComponent
    ],
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        ImageCropperModule,
        NgZorroAntdModule,
        HttpClientModule,
        // Concepts Modules
        AccountModule,
        ActivityModule,
        OrganizationModule,
        TagsModule,
        ShowModule,
        MeetingModule,
        StandModule,
        ToursModule,
        VitrineModule,
        PublicationModule,
        PromotionModule,
        ProgramModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [ HttpClient ]
            }
        }),
        NotificationsModule,
        TimeagoModule.forRoot({
            intl: TimeagoIntl,
            formatter: {
                provide: TimeagoFormatter,
                useClass: TimeagoCustomFormatter
            }
        })
    ],
    providers: [
        WINDOW_PROVIDERS,
        Globals,
        PermissionGuard,
        BreadcrumbService,
        AuthService,
        AuthGuard,
        IsNotLogginGuard,
        ErrorsHandler,
        {
            provide: APP_INITIALIZER,
            deps: [ AuthService ],
            useFactory: initializeFactory,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthorizeErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: NZ_I18N, useValue: fr_FR },
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler
        },
        MyMonitoringService,
        {
            provide: NZ_NOTIFICATION_CONFIG,
            useValue: { nzDuration: 8000 }
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR'
        },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
    ],
    schemas: [ NO_ERRORS_SCHEMA ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
