import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Program } from 'src/app/concepts/program/model/program.model';
import { ProgramStatus } from 'src/app/concepts/program/model/program-status.model';
import { Show } from 'src/app/concepts/show/model/show.model';
import { Tour } from 'src/app/concepts/tours/model/tour.model';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from './store';
import { PaidTourProgramsResolver } from './paid-tour-programs.resolver';
import { PaidTourShowResolver } from './paid-tour-show.resolver';
import { PaidTourShowsResolver } from './paid-tour-shows.resolver';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class TourActions extends Store<Program[]> {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly paidTourProgramsResolver: PaidTourProgramsResolver,
        private readonly paidTourShowResolver: PaidTourShowResolver,
        private readonly paidTourShowsResolver: PaidTourShowsResolver
    ) {
        super();
    }

    addDateChoice(diffuseur: Organization, tour: Tour, show: Show, dateInput: Date): Observable<Program> {
        let date = moment(dateInput).format('YYYY-MM-DD');
        return this.httpClient
            .post<{ program: Record<string, any> }>(`${environment.BACKEND}program`, {
                date,
                organizationId: diffuseur.id,
                calendarId: tour.calendarId,
                tourId: tour.id,
                showId: show.id,
                statusId: ProgramStatus.Exploratory
            })
            .pipe(
                map(
                    (data) =>
                        new Program({
                            ...data.program,
                            show,
                            organization: diffuseur
                        })
                ),
                tap((program) => {
                    this.paidTourProgramsResolver.addProgram(program);
                    this.paidTourShowResolver.incrementProgramCount();
                    this.paidTourShowsResolver.incrementProgramCount(program);
                })
            );
    }

    removeDateChoice(diffuseur: Organization, program: Program): Observable<unknown> {
        return this.httpClient.delete(`${environment.BACKEND}program/${program.id}?organizationId=${diffuseur.id}`).pipe(
            tap(() => {
                this.paidTourProgramsResolver.removeProgram(program);
                this.paidTourShowResolver.decrementProgramCount();
                this.paidTourShowsResolver.decrementProgramCount(program);
            })
        );
    }
}
