import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/_configs/globals';
import { environment } from '../../../environments/environment';
import { Pagination, SelectedFilter } from '../model/list-item.model';
import { ObjectUtils } from '../utils/object.utils';

@Injectable({
    providedIn: 'root'
})
export class MainService {
    protected uri: string = environment.BACKEND;
    protected constructor(protected httpClient: HttpClient, protected globals: Globals) { }

    // Transform array of filters to URL ready string
    protected formatGetFilters(filters: Array<SelectedFilter>): string {
        const esc = encodeURIComponent;
        // Transform filters array to url params
        return filters
            .filter((filter) => filter.field && filter.value)
            .map((filter) => `${esc(filter.field)}=${esc(filter.value)}`)
            .join('&');
    }

    protected cleanUpNullValues<T>(values: T): T {
        ObjectUtils.cleanUpNullValues<T>(values);
        return values;
    }

    protected formatPagination(pagination: Pagination): string {
        return `offset=${pagination.offset}&limit=${pagination.limit}`;
    }
}
