<app-connection-container
  [title]=' "RESET_YOUR_PASSWORD" | translate'
>

  <div class="ResetPassword  mt-4 ">

    <div class="row justify-content-center">
      <form
        [formGroup]="resetForm"
        nz-form
        class="ResetPassword__col col-lg-4 col-sm-12 col-md-8"
        (ngSubmit)='sendNewPassword($event)'
        >

        <nz-form-item >
          <app-password-input formControlName='password' label='NEW_PASSWORD' ></app-password-input>
        </nz-form-item>

        <app-password-hint-validator [isValid]='resetForm.controls.password.valid'  sentence='{{ "PASSWORD-REGEX" | translate }}'  ></app-password-hint-validator>

        <nz-form-item >
          <app-password-input formControlName='confirmPassword' label='NEW_PASSWORD_CONFIRMATION' ></app-password-input>
        </nz-form-item>



       <div class="ResetPassword__action">
          <app-button-connection
            class="w-100"
            buttonType='Primary'
            [text]=' "REINITIALISER" | translate '
            type='submit'
            [disable]='!resetForm.valid'
          ></app-button-connection>
       </div>


      </form>
    </div>
    <div class="ResetPassword__connection-link row justify-content-center">
       <a class="cta_link ResetPassword__col col-lg-4 col-sm-12 col-md-8" [routerLink]='["../../login"]'> {{ "BACK_TO_CONNECTION" | translate }}</a>
    </div>


  </div>

</app-connection-container>

