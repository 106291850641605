
  <div id="loadingIndicator" *ngIf="isResolving">
    <app-loading-indication ></app-loading-indication>
  </div>

  <app-main-navigation *ngIf="user"  ></app-main-navigation>

  <div class="app-container position-relative">
    <app-breadcrumb *ngIf="user"></app-breadcrumb>
    <router-outlet> </router-outlet>
  </div>

  <footer *ngIf="user" class="footer">
    <a  [routerLink]="'/legal' | localize">{{ 'LEGAL' | translate }}</a>
    <a  [routerLink]="'/privacy' | localize">{{ 'PRIVACY' | translate }}</a>
  </footer>

  <!-- LEGAL MODAL -->
 <ng-container *ngIf="!hideModal && hasAcceptedLegal != 1 && this.user">

      <div class="legalModal">
        <div class="legalModal-box">
          <div class="legalModal-content">
            <div class="row">
              <div class="col-2">
                <i nz-icon class="mt-2" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 20 20">
                    <path fill="#1702f8" fill-rule="nonzero"
                    d="M10 3.588l-8.122 14.89a1 1 0 1 1-1.756-.957l9-16.5a1 1 0 0 1 1.756 0l9 16.5A1 1 0 0 1 19 19H5a1 1 0 0 1 0-2h12.315L10 3.588zM9 8a1 1 0 1 1 2 0v4a1 1 0 0 1-2 0V8zm1 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                </i>
              </div>
              <div class="col-10">
                <p>Afin de poursuivre, vous devez accepter les termes d'utilisation de Scène Pro:</p>
                <ul>
                  <li><a class="clr-primary" [routerLink]="'/legal' | localize" target="_blank">
                    {{ 'LEGAL-TITLE' | translate }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20">
                      <title>new window</title>
                      <path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z"/>
                      <path d="M19 1h-8l3.29 3.29-5.73 5.73 1.42 1.42 5.73-5.73L19 9V1z"/>
                    </svg>
                  </a></li>
                  <li><a class="clr-primary" [routerLink]="'/privacy' | localize" target="_blank">
                    {{ 'PRIVACY-TITLE' | translate }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20">
                      <title>new window</title>
                      <path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z"/>
                      <path d="M19 1h-8l3.29 3.29-5.73 5.73 1.42 1.42 5.73-5.73L19 9V1z"/>
                    </svg>
                  </a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="legalModal-footer">
            <button type="button"
              style="margin-right:10px; padding-top:6px"
              class="btn btn--larger"
              (click)="acceptLegalTerms()">
              {{'I-AGREE' | translate}}
            </button>
          </div>
        </div>
      </div>

  </ng-container>

