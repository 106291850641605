import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from 'localize-router';
import { NgZorroAntdModule, NzToolTipModule } from 'ng-zorro-antd';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuillModule } from 'ngx-quill';
import { TagsSingleComponent } from './components/tags-single/tags-single.component';
import { TagsListComponent } from './components/tags-list/tags-list.component';

@NgModule({
  declarations: [TagsListComponent, TagsSingleComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    LocalizeRouterModule,
    NgZorroAntdModule,
    SharedModule,
    QuillModule,
    NzToolTipModule,
  ],
  exports: [],
})
export class TagsModule {}
