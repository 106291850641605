<app-item-list [itemList]="vitrinesListItems"
               [filterList]="filterList"
               [pagination]="pagination"
               [isLoading]="isLoading"
               [listItemsType]="listType"
               [useFilters]="enableFilters"
               [useViewSwitcher]="useViewSwitcher"
               [useSearchBar]="true"
               [searchBarPlaceHolder]="'RECHERCHER-VITRINE' | translate"
               [useSorting]="false"
               [usePagination]="true"
               (modifyItems)="modifyVitrine($event)"
               (filterChange)="onFilterChange($event)"
               (searchChange)="onSearchChange($event)"
               (pageChange)="onPageChange($event)">

    <app-vitrine-advanced-search #advancedSearch advanced-search
                                 (filtersChange)="onAdvancedFilterChange($event)"></app-vitrine-advanced-search>

</app-item-list>