import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { LocalizeRouterService } from 'localize-router';
import { ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { ShowService } from '../../../show/services/show.service';
import {
  Filter,
  FilterValue,
  Pagination,
  Sort,
} from '../../../../shared/model/list-item.model';
import { Show } from '../../../show/model/show.model';
import { RowTypes } from '../../../../shared/enums/row-types.enum';
import { ToursService } from '../../services/tours.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TourShow } from '../../model/tour-show.model';
import { Tour, ITourKeepUpdate } from '../../model/tour.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { TourInterest } from '../../enums/tour-interet.enum';
import { ReportsService } from '../../../reports/reports.service';
import { flatMap } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/shared/model/breadcrumb.model';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.services';
import { TourShowStatus } from '../../enums/tour-show-status.enum';
import { ShowAdvancedSearchComponent } from 'src/app/concepts/show/components/show-list/show-advanced-search/show-advanced-search.component';

@Component({
  selector: 'app-submited-shows',
  templateUrl: './submited-shows.component.html',
  styleUrls: ['./submited-shows.component.scss'],
})
export class SubmitedShowsComponent implements OnInit, OnDestroy {
  constructor(
    private showService: ShowService,
    private tourService: ToursService,
    private organizationService: OrganizationService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private reportService: ReportsService,
    private breadcrumbService: BreadcrumbService,
    private readonly localizeRouterService: LocalizeRouterService,
  ) {}
  showList: Show[];

  itemList: ListItem[] = [];

  leftSideInterestFilters: Filter[] = [];
  leftSideKeepingFilters: Filter[] = [];

  sorts: Sort[] = [];

  pagination: Pagination = new Pagination();

  searchText: string;

  sortBy: any;

  tourId: number;

  contextOrgId: number;

  tour: Tour;

  headOrganization: Organization;

  pageTitle: string;
  pageSubTitle: string;

  private selectedFilters: SelectedFilter[] = [];

  lang: string;
  isLoading: boolean;
  isReady: boolean;
  advancedSelectedFilters: SelectedFilter[] = [];
  listType: RowTypes = RowTypes.SHOW;

  exportOptions = [
    { id: 0, label: 'Informations des spectacles' },
    { id: 1, label: "Rapport d'intérêts" },
  ];

  modifyTourLink: any[];
  editable: boolean;

  tourAccess = false;

  count = null;

  isAdmin = false;

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.tourId = this.route.snapshot.params['tourId'];
    this.accountService.currentOrganizationChange.subscribe(() =>
      this.tourService
        .getTourById(this.tourId)
        .subscribe(this.update.bind(this))
    );
    this.tourService.getTourById(this.tourId).subscribe(this.update.bind(this));

    this.modifyTourLink = this.localizeRouterService.translateRoute([
      `/admin/tour/${this.tourId}/edit`,
    ]) as any[];
  }

  update(tour: Tour) {
    this.contextOrgId = this.accountService.getCurrentCtxOrganizationId();
    this.tour = tour;
    this.pageTitle = this.tour.getTranslatedProperty(this.lang, 'name');

    this.addBreadcrumb();
    this.getTourNetwork();
    this.initFilter();
    this.initSort();
    this.initPagination();
    this.getshow();
  }

  addBreadcrumb() {
    const breadcrumbItem = new BreadcrumbItem();
    breadcrumbItem.set(this.pageTitle);
    this.breadcrumbService.resetBreadcrumb();
    this.breadcrumbService.addBreadcrumbCascade([breadcrumbItem]);
  }

  getTourNetwork() {
    this.organizationService
      .getOrganization(this.tour.organizationId)
      .subscribe((data: Organization) => {
        this.headOrganization = data;
        this.pageSubTitle = data.getTranslatedProperty(this.lang, 'name');
        this.isReady = true;
        this.tourAccess = data.tourAccess;
        this.isAdmin = +this.accountService.getCurrentCtxOrganizationId() === +data.id
      });
  }

  getToursCount() {
    this.tourService
      .getToursCount(
        this.tour.organizationId,
        this.tourId,
        [...this.selectedFilters, ...this.advancedSelectedFilters],
        this.searchText
      )
      .subscribe((data: any) => {
        this.count = data;
        if (this.contextOrgId === this.tour.organizationId && this.count) {
          this.addKeepingFilter(this.count);
        }
      });
  }

  getshow(noLoad?: boolean) {
    sessionStorage.setItem('soumissions_pagination', JSON.stringify(this.pagination));
    if (!noLoad) this.isLoading = true;
    // rest call with filters & pagination
    const tourShowFilters = [];

    if (this.tourId) {
      tourShowFilters.push(
        {
          field: 'tourId',
          value: this.tourId,
        },
        {
          field: 'organizationId',
          value: this.contextOrgId,
        }
      );

      if (this.tour.isVoteOpen && (this.tour.organizationId !== this.contextOrgId)) {
        tourShowFilters.push(
          {
            field: 'statusId',
            value: TourShowStatus.KEPT,
          }
        );
      }
    }

    this.tourService
      .getSubmittedTourShows(
        [
          ...tourShowFilters,
          ...this.selectedFilters,
          ...this.advancedSelectedFilters
        ],
        this.pagination,
        this.sortBy,
        this.searchText
      )
      .subscribe((tourShows: TourShow[]) => {
        this.showList = tourShows.map((tourShow) => new Show(tourShow.show));
        this.itemList = tourShows.map((tourShow: TourShow) => {
          const show = new Show(tourShow.show);
          const item = show.toListItem(this.lang);
          item.itemId = tourShow.id;
          item.itemSubtitle = this.showService.extractArtistList(tourShow.show);
          item.itemUrl = `/tour/${tourShow.tourId}/shows/${tourShow.id}`;
          item.itemRatings = tourShow.ratings;
          item.itemIsToKeep = tourShow.statusId === 1 ? false : true;
          return item;
        });
        if (!noLoad) this.isLoading = false;
      });
  }

  initFilter() {
    this.selectedFilters = [];
    if (this.contextOrgId === this.tour.organizationId) {
      this.getToursCount();
    } else {
      this.leftSideInterestFilters = [];
      this.addRideauFilter();
    }
  }

  addRideauFilter() {
    // Filtres sur le status pour l'utilisateur RIDEAU

    const interestFilter = new Filter();
    interestFilter.filterField = 'interest';
    interestFilter.filterName = this.translate.instant(
      'TOUR-SHOW-INTEREST-TITLE'
    );
    interestFilter.filterValues = [];
    interestFilter.filterValues.push(
      {
        filterValueName: this.translate.instant('STATUS.INTERET'),
        filterValue: TourInterest.LIKE,
      },
      {
        filterValueName: this.translate.instant('STATUS.DESINTERET'),
        filterValue: TourInterest.DISLIKE,
      },
      {
        filterValueName: this.translate.instant('STATUS.NOEVAL'),
        filterValue: TourInterest.NOEVAL,
      }
    );
    interestFilter.filterValues.unshift({
      filterValueName: this.translate.instant('TOUTES'),
      filterValue: '',
    } as FilterValue);

    this.leftSideInterestFilters.push(interestFilter);
  }

  addKeepingFilter(count: any) {
    const keepingFilters = new Filter();
    keepingFilters.filterField = 'statusId';
    keepingFilters.filterValues = [];
    keepingFilters.filterValues.push(
      {
        filterValueName: this.translate.instant('KEEPED'),
        filterValue: 2,
        filterTotal: count.keeped,
      },
      {
        filterValueName: this.translate.instant('NOT_KEEPED'),
        filterValue: 1,
        filterTotal: count.rejected,
      }
    );
    keepingFilters.filterValues.unshift({
      filterValueName: this.translate.instant('TOUTES'),
      filterValue: '',
      filterTotal: count.total,
    } as FilterValue);
    if (this.leftSideInterestFilters.length === 0) {
      this.leftSideInterestFilters.push(keepingFilters);
    } else {
      this.leftSideInterestFilters.splice(0, 1, keepingFilters);
    }
  }

  initSort() {
    const sort1 = new Sort(),
      sort2 = new Sort(),
      sort3 = new Sort();

    sort1.sortName = this.translate.instant('SORT.BYDATE');
    sort1.sortValue = 0;
    sort2.sortName = this.translate.instant('SORT.BYINTEREST');
    sort2.sortValue = 1;
    sort3.sortName = this.translate.instant('SORT.BYDESINTEREST');
    sort3.sortValue = 2;

    this.sorts = [sort1, sort2, sort3];
  }
  initPagination() {
    this.pagination = new Pagination(0, 20, 0);
    const session =  JSON.parse(sessionStorage.getItem('soumissions_pagination'));
    if (session) {
      Object.assign(this.pagination, session);
    }
  }

  onFilterChange(event: SelectedFilter) {
    this.selectedFilters = [event];
    this.getshow();
    //this.initFilter();
  }

  onAdvancedFilterChange(event: Parameters<ShowAdvancedSearchComponent['filtersChange']['emit']>[0]) {
    // On remet à la page 1 (si recherche manuelle)
    if (!event.rechercheSauvegardee) {
      this.pagination.offset = 0;
    }
    this.advancedSelectedFilters = event.filters;
    this.getshow();
    this.initFilter();
  }

  onSearchChange(event: string) {
    this.searchText = event;
    this.getshow();
    this.initFilter();
  }

  onSortChange(event: any) {
    this.sortBy = event;
    this.getshow();
  }

  onRateItem(event: any) {
    const rate = event;
    rate.organizationId = this.accountService.getCurrentCtxOrganizationId();
    this.tourService
      .createTourShowInterest(event.tourShowId, rate)
      .subscribe((res) => this.getshow(true));
  }

  onUpdateKeepedShow(event: any) {
    const body: ITourKeepUpdate = {
      id: event.id,
      statusId: event.statusId,
      organizationId: event.organizationId,
    };

    this.tourService.updateTourShow(body).subscribe((data) => {
      if (data.isUpdated) {
        this.itemList[
          this.itemList.findIndex((item) => item.itemId === body.id)
        ].itemIsToKeep = body.statusId === 1 ? false : true;
        this.getToursCount();
      }
    });
  }

  onPageChange(event: Pagination) {
    this.pagination.offset = event.offset;
    this.getshow();
  }

  exportShows() {
    this.reportService.generateSubmittedShowsReport(
      this.tour,
      this.headOrganization
    );
  }

  exportFavoritesCallback() {
    this.reportService.generateSubmittedShowsInterestsReport(
      this.tour,
      this.headOrganization
    );
  }

  onExportCallback($event) {
    switch ($event) {
      case 0:
        this.exportShows();
        break;
      case 1:
        this.exportFavoritesCallback();
        break;

      default:
        break;
    }
  }

  closeForDeposit() {
    return this.tourService
      .closeForDeposit(this.tour)
      .pipe(flatMap(() => this.tourService.getTourById(this.tourId)))
      .subscribe(this.update.bind(this));
  }

  openForDeposit() {
    return this.tourService
      .openForDeposit(this.tour)
      .pipe(flatMap(() => this.tourService.getTourById(this.tourId)))
      .subscribe(this.update.bind(this));
  }

  closeForVote() {
    return this.tourService
      .closeForVotes(this.tour)
      .pipe(flatMap(() => this.tourService.getTourById(this.tourId)))
      .subscribe(this.update.bind(this));
  }

  openForVote() {
    return this.tourService
      .openForVotes(this.tour)
      .pipe(flatMap(() => this.tourService.getTourById(this.tourId)))
      .subscribe(this.update.bind(this));
  }

  ngOnDestroy(): void {
    this.breadcrumbService.resetBreadcrumb();
  }
}
