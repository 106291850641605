import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProgramService } from './services/program.services';
import { Observable, of } from 'rxjs';
import { Program } from './model/program.model';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProgramsResolver implements Resolve<Program[]> {
  constructor(
    private readonly programService: ProgramService,
    private readonly userOrganizationIdResolver: UserOrganizationIdResolver
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Program[]> {
    return this.userOrganizationIdResolver.resolve().pipe(
      switchMap(contextOrganizationId => {

        if(route.params && route.params.tourId){
          return of([])
        }
        return this.programService.getOrganizationPrograms({
        ...route.queryParams,
        organizationCalendars: route.queryParams.organizationCalendars || '1',
        nonTourDates: route.queryParams.nonTourDates || 1,
        showId: route.params['tourShowId'] || 0
      }, contextOrganizationId)}

      )
    );
  }
}
