<div class="InputMetre">
    <input type="text" id="metre"
            [attr.pattern]="pattern"
           [(ngModel)]="value"
           [disabled]="disabled"
           (blur)="onTouched()"
           (input)="onChange($event.target.value)"
           decimalsOnly />
    <label for="metre">{{ "FORM.METRES" | translate }}</label>
</div>
