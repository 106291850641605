import { Injectable } from '@angular/core';
import { SelectedFilter, Pagination } from 'src/app/shared/model/list-item.model';
import { Observable } from 'rxjs';
import { IUpdatedResponse, Publication } from '../model/publication.model';
import { Globals } from 'src/app/_configs/globals';
import { MainService } from 'src/app/shared/services/main.services';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../../account/services/account.service';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';

@Injectable({
    providedIn: 'root'
})
export class PublicationService extends MainService {
    constructor(private accountService: AccountService, protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    getPublications(filters: SelectedFilter[], pagination?: Pagination): Observable<Publication[]> {
        let endpoint = this.uri + this.globals.endpoints.publication.all;

        if (filters || pagination) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get<Publication[]>(endpoint).map((res) => {
            if (pagination) pagination.total = res['total'];
            return res['publications'].map((pub) => new Publication(pub));
        });
    }

    getPublicationById(pubId: number): Observable<Publication> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.publication.getSingleEndpoint(pubId, orgId);

        return this.httpClient.get(endpoint).map((res) => new Publication(res));
    }

    createPublication(publication: Publication): Observable<Publication> {
        const body = new Publication(publication);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.publication.getMainEndpoint(orgId);
        return this.httpClient.post(endpoint, body).map((res) => new Publication(res['publication']));
    }

    updatePublication(publication: Publication): Observable<IUpdatedResponse> {
        const body = new Publication(publication);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.publication.getMainEndpoint(orgId);
        return this.httpClient.put<IUpdatedResponse>(endpoint, body);
    }

    deletePublication(publicationId: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.publication.getSingleEndpoint(publicationId, orgId);
        return this.httpClient.delete(endpoint);
    }

    modifyPublicationApproval(statusId: ObjectStatus, publicationId: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.publication.getApprovalEndpoint(orgId);
        return this.httpClient.put(endpoint, {
            publicationId: publicationId,
            statusId: statusId
        });
    }
}
