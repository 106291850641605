import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface ShowTab {
    id: string;
    errorCount: number;
}
export enum SHOW_TABS_IDS {
    IDENTIFICATION = 'IDENTIFICATION',
    CLASSIFICATION = 'CLASSIFICATION',
    CACHET = 'CACHET',
    CONTACTS = 'CONTACTS',
    MATERIEL = 'MATERIEL',
    MARKETING = 'MARKETING',
}

export const SHOW_TABS_NAMES = {
    IDENTIFICATION: 'identification',
    CLASSIFICATION: 'classification',
    CACHET: 'cachet',
    CONTACTS: 'contacts',
    MATERIEL: 'materiel',
    MARKETING: 'mise-en-marche',
}
@Injectable({
    providedIn: 'root'
})
export class ShowTabsService {
    protected readonly SHOW_TABS_IDS = [ 'IDENTIFICATION', 'CLASSIFICATION',
        'CACHET',
        'CONTACTS',
        'MATERIEL',
        'MARKETING' ];
    private SHOW_TABS: ShowTab[] = this.SHOW_TABS_IDS.map(id => ({
        id,
        errorCount: 0
    }));
    public list$: Observable<ShowTab[]>;
    public currentTabId$: Observable<string>;
    private listSubject: BehaviorSubject<ShowTab[]> = new BehaviorSubject(this.SHOW_TABS);
    private currentTabIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.SHOW_TABS_IDS[ 0 ]);
    constructor() {
        this.list$ = this.listSubject.asObservable();
        this.currentTabId$ = this.currentTabIdSubject.asObservable();
    }

    public setCurrentTabId = (id?: string) => {
        this.currentTabIdSubject.next(id || this.SHOW_TABS_IDS[ 0 ]);
    }

    public getCurrentTabId = (currentTabId?: string): string => {
        if (!currentTabId) {
            return this.SHOW_TABS_IDS[ 0 ];
        }
        // currentTabId is lowercase since it's the page name
        const tabs = this.listSubject.value || [];
        if (!tabs.length) {
            return;
        }
        const item = Object.keys(SHOW_TABS_NAMES).find(k => SHOW_TABS_NAMES[ k ] === currentTabId);
        const index = tabs.findIndex(x => x.id === item);
        if (item && index > -1) {
            return tabs[ index ].id;
        }
    }

    public getNextTabId = (): string => {
        const { index } = this.getTab(this.currentTabIdSubject.value);
        if (index === this.listSubject.value.length - 1) {
            return;
        }
        return this.listSubject.value[ index + 1 ].id;
    }

    public getTab = (key: string): { tab: ShowTab, index: number } => {
        const index = this.listSubject.value.findIndex(item => item.id === key);
        if (index === -1) {
            return { tab: null, index };
        }
        return { tab: this.listSubject.value[ index ], index };
    }

    public setTabErrorCount = (key: string, newCount: number): void => {
        const { tab, index } = this.getTab(key);
        if (!tab) {
            return;
        }
        const updatedList = [ ...this.listSubject.value ];
        updatedList[ index ] = {
            ...tab,
            errorCount: newCount
        };
        this.listSubject.next([ ...updatedList ]);
    }

    public getTabErrorCount = (key: string): number => {
        const { tab } = this.getTab(key);
        if (!tab) {
            return;
        }
        return tab.errorCount;
    }
}
