export enum SiteConcepts {
  DASHBOARD,
  ACCOUNT,
  MEETING,
  COTISATION,
  SHOW,
  ETIQUETTE,
  BOTTIN,
  REPORT,
  PRODUIT,
  SHOPCART,
  SINGLE,
  VENUE,
  MYSHOWS,
  EDIT,
  VITRINE,
  PUBLICATION,
  PROMOTION,
  USER,
  NOTIFICATIONS,
  TOURNEE,
  LEGAL,
  PRIVACY,
  PROGRAM,
  TOUR,
  ARCHIVED,
  HELP
}

interface SiteConceptsSpecs {
  title: string;
  url: string;
  isSingle?: boolean;
}

export function GetSiteConceptsSpec(concept: SiteConcepts): SiteConceptsSpecs {
  switch (concept) {
    case SiteConcepts.DASHBOARD:
      return { title: 'TABLEAU-BORD', url: '' };

    case SiteConcepts.ACCOUNT:
      return { title: 'MON-COMPTE', url: '/profile' };

    case SiteConcepts.MEETING:
      return { title: 'ESPACES-PRO', url: '/pro-meeting' };

    case SiteConcepts.SHOW:
      return { title: 'OFFRES-SPECTACLES', url: '/shows-offers' };

    case SiteConcepts.BOTTIN:
      return { title: 'REPERTOIRE', url: '/bottin' };

    case SiteConcepts.REPORT:
      return { title: 'RAPPORTS', url: '/reports' };

    case SiteConcepts.PRODUIT:
      return { title: 'PRODUITS', url: '/admin/product' };

    case SiteConcepts.SHOPCART:
      return { title: 'PANIER-DACHAT', url: '/shopping-cart' };

    case SiteConcepts.SINGLE:
      return { title: '', url: '', isSingle: true };

    case SiteConcepts.VENUE:
      return { title: 'SALLES', url: '/bottin' };

    case SiteConcepts.MYSHOWS:
      return { title: 'MES-SPECTACLES', url: '/account/my-shows' };

    case SiteConcepts.COTISATION:
      return {
        title: 'COTISATION-RIDEAU',
        url: '/admin/membership/facturation',
      };

    case SiteConcepts.EDIT:
      return { title: 'MODIFIER', url: '' };

    case SiteConcepts.VITRINE:
      return { title: 'VITRINE', url: '' };

    case SiteConcepts.TOURNEE:
      return { title: 'TOURNEE', url: '' };

    case SiteConcepts.ETIQUETTE:
      return { title: 'ETIQUETTES', url: '/admin/etiquettes' };

    case SiteConcepts.PUBLICATION:
      return { title: 'PUBLICATIONS', url: '/admin/publication' };

    case SiteConcepts.PROMOTION:
      return { title: 'PROMOTIONS', url: '/admin/promotion' };

    case SiteConcepts.USER:
      return { title: 'USERS', url: '/admin/users' };

    case SiteConcepts.NOTIFICATIONS:
      return { title: 'NOTIFICATIONS.NOTIFICATIONS', url: '/notifications' };

    case SiteConcepts.NOTIFICATIONS:
      return { title: 'LEGAL-TITLE', url: '/legal' };

    case SiteConcepts.NOTIFICATIONS:
      return { title: 'PRIVACY-TITLE', url: '/privacy' };

    case SiteConcepts.PROGRAM:
      return { title: 'PROGRAMMATION', url: '/programmations' };

    case SiteConcepts.TOUR:
      return { title: 'DEPOT-RESEAUX', url: '/admin/tour' };

    case SiteConcepts.ARCHIVED:
      return { title: 'ARCHIVED-F', url: '/tour/archived' };

    case SiteConcepts.HELP:
      return { title: 'AIDE', url: '/help' };

  }
}
