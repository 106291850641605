import { Product } from '../../product/model/product.model';

export class Transaction {
  id: number;
  organizationId: number;
  memberId: number;
  subtotal: string;
  taxTps: string;
  taxTvq: string;
  total: string;
  statusId: number;
  gbStatusCode: string;
  gbMessage: string;
  gbOrderId: string;
  gbAuthCode: string;
  gbTransactionId: string;
  originalTransactionId: number;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  transactionProducts: Product[];

  constructor(data: any) {
    this.id = data['id'];
    this.organizationId = data['organizationId'];
    this.memberId = data['memberId'];
    this.subtotal = data['subtotal'];
    this.taxTps = data['taxTps'];
    this.taxTvq = data['taxTvq'];
    this.total = data['total'];
    this.statusId = data['statusId'];
    this.gbStatusCode = data['gbStatusCode'];
    this.gbMessage = data['gbMessage'];
    this.gbOrderId = data['gbOrderId'];
    this.gbAuthCode = data['gbAuthCode'];
    this.gbTransactionId = data['gbTransactionId'];
    this.userId = data['userId'];
    this.originalTransactionId = data['originalTransactionId'];
    this.createdAt = new Date(data['createdAt']);
    this.updatedAt = new Date(data['updatedAt']);
    if (data['transaction_product']) {
      this.transactionProducts = data['transaction_product'].map(
        (prod) => new Product(prod)
      );
    }
  }
}
