import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepotVitrineComponent } from './components/depot-vitrine/depot-vitrine.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VitrineListComponent } from './components/vitrine-list/vitrine-list.component';
import { VitrineApprovalComponent } from './components/vitrine-approval/vitrine-approval.component';
import { ShowModule } from '../show/show.module';
import { VitrineRideauFormComponent } from './components/vitrine-rideau-form/vitrine-rideau-form.component';
import { VitrineAdvancedSearchComponent } from './components/vitrine-advanced-search/vitrine-advanced-search.component';

@NgModule({
  declarations: [
    DepotVitrineComponent,
    VitrineListComponent,
    VitrineApprovalComponent,
    VitrineRideauFormComponent,
    VitrineAdvancedSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    TranslateModule,
    SharedModule,
    ShowModule,
  ],
  exports: [DepotVitrineComponent, VitrineListComponent],
})
export class VitrineModule {}
