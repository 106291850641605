import {
  Component,
  Input,
  OnChanges,
  HostBinding,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
  selector: 'app-radio',
  template: `
    <nz-form-item>
      <label [attr.for]="id">
        <ng-content></ng-content>
      </label>
      <nz-form-control>
        <nz-radio-group
          class="d-flex"
          [attr.id]="id"
          [(ngModel)]="selected"
          (ngModelChange)="ngModelChange()"
        >
          <label nz-radio-button *ngFor="let key of keys" [nzValue]="key" [nzDisabled]="disabled">{{
            translateKeyPrefix + Options[key] | translate
          }}</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  `,
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent<T> implements OnChanges, ControlValueAccessor {
  private onChange: (value: T) => void;
  private onTouched: () => void;
  keys: number[];
  @Input() Options: T;
  @Input() disabled: boolean;
  @Input() validateStatus = '';
  @Input() labelProperty: string;
  @Input() valueProperty = 'id';
  @Input() translateKeyPrefix = '';
  @Input() filterOutKeys: number[] = [];
  @HostBinding('class.warning') get classWarning(): boolean {
    return this.validateStatus === 'warning';
  }
  @HostBinding('class.error') get classError(): boolean {
    return this.validateStatus === 'error';
  }
  @HostBinding('class.disabled') get classDisabled(): boolean {
    return this.disabled;
  }
  id = `RadioComponent-${unique++}`;
  selected: T;
  constructor(
    readonly translateService: TranslateService,
    private readonly ngControl: NgControl
  ) {
    this.ngControl.valueAccessor = this;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Options) {
      this.keys = Object.keys(this.Options)
        .map((key) => parseInt(key, 10))
        .filter((key) => !isNaN(key))
        .filter((key) => !this.filterOutKeys.includes(key))
        .sort((a, b) => this.Options[a] - this.Options[b]);
    }
  }
  ngModelChange(): void {
    this.onChange(this.selected);
    this.onTouched();
  }
  writeValue(propertyValue: T): void {
    this.selected = propertyValue;
  }
  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
