import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Component, OnInit } from '@angular/core';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';

@Component({
  selector: 'app-bills-to-pay',
  templateUrl: './bills-to-pay.component.html',
  styleUrls: ['./bills-to-pay.component.scss'],
})
export class BillsToPayComponent implements OnInit {
  organizationId: number;
  isLoading = false;
  billsToPay = [];

  constructor(
    private accountService: AccountService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.organizationId = this.accountService.getCurrentCtxOrganizationId();
    this.getBills();
    this.accountService.currentOrganizationChange.subscribe(() => {
      this.organizationId = this.accountService.getCurrentCtxOrganizationId();
      this.getBills();
    });
  }

  getBills() {
    this.organizationService
      .getOrganisationBills(this.organizationId)
      .subscribe((res) => {
        this.billsToPay = res.filter((bill) => bill.isPaid === 0);
        this.isLoading = false;
      });
  }
}
