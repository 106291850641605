import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-connection-container',
  templateUrl: './connection-container.component.html',
  styleUrls: ['./connection-container.component.scss']
})
export class ConnectionContainerComponent implements OnInit {

  @Input() title: string = ''

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

}
