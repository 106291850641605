<div nz-row>
  <div nz-col
       nzMd="16">
    <h2>{{ 'SHOW-STEP.CONTACTS' | translate}}</h2>
  </div>
  <div class="d-flex justify-content-end"
       nz-col
       nzMd="8"
       *ngIf="!orgaContactForm.disabled">
    <button type="button"
            class="btn btn--large"
            (click)="orgaNewContactForm()"
            [disabled]="collapseNewContactForm"
            style="padding:0 16px">
      <i class="icon-plus icon-for-btn"></i>
      {{'AJOUTER-CONTACT' | translate}}
    </button>
  </div>
</div>

<div *ngIf="isLoading"
     class="loader">
  <i nz-icon
     nzType="loading"
     nzTheme="outline"
     aria-hidden="true"></i>
</div>

<div class="d-flex align-items-center"
     nz-row
     nzType='flex'
     nzJustify='space-between'>

  <!-- CREATE MEMBER FORM -->
  <div class="form-filter"
       *ngIf="collapseNewContactForm">
    <app-contact-form [contactForm]="orgaContactForm"
                      (addContact)="addNewContact()"
                      (cancel)="collapseNewContactForm = !collapseNewContactForm"></app-contact-form>
  </div>
</div>

<nz-divider></nz-divider>

<form id="organisation-step-4"
      class="mb-4">
  <ng-container *ngFor="let orgaContact of orgaContacts">
    <app-item-row-contact [contact]="orgaContact"
                          [showForm]="orgaHideModifyForm(orgaContact.id)"
                          [isDisabled]="orgaContactForm.disabled"
                          (hideOtherModifyForms)="onHideOtherModifyForms($event)"
                          (modifyContact)="onModifyorgaContact($event)"
                          (removeContact)="onRemoveorgaContact($event)"></app-item-row-contact>
  </ng-container>

</form>
