import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ToursService } from './services/tours.service';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Program } from 'src/app/concepts/program/model/program.model';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';
import { TourAdminResolver } from './tour-admin.resolver';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from './store';

@Injectable({ providedIn: 'root' })
export class PaidTourProgramsResolver extends Store<Program[]> implements Resolve<Program[]> {
    constructor(
        private readonly toursService: ToursService,
        private readonly userOrganizationIdResolver: UserOrganizationIdResolver,
        private readonly tourAdminResolver: TourAdminResolver,
        private readonly httpClient: HttpClient
    ) {
        super();
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Program[]> {
        return this.initialize(
            combineLatest([this.userOrganizationIdResolver.resolve(), this.toursService.getTourById(+route.paramMap.get('tourId')), this.tourAdminResolver.resolve(route)]).pipe(
                switchMap(([contextOrganizationId, tour, tourAdmin]) => {
                    let params: any = { tourId: tour.id.toString(), organizationId: contextOrganizationId.toString() };

                    if (route.queryParams && route.queryParams.organizationCalendars) {
                        params = { ...params, organizationCalendars: route.queryParams.organizationCalendars };
                    }

                    if (route.queryParams && route.queryParams.nonTourDates) {
                        params = { ...params, nonTourDates: route.queryParams.nonTourDates };
                    }

                    if (route.queryParams && route.queryParams.showId) {
                        params = { ...params, showId: route.queryParams.showId };
                    }

                    if (route.queryParams && route.queryParams.show) {
                        params = { ...params, show: route.queryParams.show };
                    }

                    if (route.queryParams && route.queryParams.artist) {
                        params = { ...params, artist: route.queryParams.artist };
                    }

                    if (route.queryParams && route.queryParams.venue) {
                        params = { ...params, venue: route.queryParams.venue };
                    }

                    if (route.queryParams && route.queryParams.producer) {
                        params = { ...params, producer: route.queryParams.producer };
                    }

                    if (route.queryParams && route.queryParams.diffusers) {
                        params = { ...params, diffusers: route.queryParams.diffusers };
                    }

                    if (route.queryParams && route.queryParams.diffuser) {
                        params = { ...params, diffuser: route.queryParams.diffuser };
                    }

                    return this.httpClient
                        .get<{ programs: any[] }>(`${environment.BACKEND}programs`, {
                            params
                        })
                        .pipe(map((data) => data.programs.map((v) => new Program(v))));
                })
            )
        );
    }

    addProgram(program: Program) {
        this.state = [...this.state, program];
    }

    removeProgram(program: Program) {
        const index = this.state.findIndex((pprogram) => pprogram.id === program.id);
        if (index > -1) {
            this.state.splice(index, 1);
            this.state = this.state;
        }
    }
}
