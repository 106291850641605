<div class="wrapper">

  <div *ngFor="let photo of photoAlbum; let i=index">
    <div class="materiel-promo">

      <div class="img-container">
        <img [src]="photo.thumb"
             alt="{{photo.caption}}">
        <div class="overlay">
          <a (click)="open(i)"
             class="icon"
             title="{{photo.caption}}">
            <i nz-icon
               nzType="zoom-in"
               nzTheme="outline"
               aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <a href="{{photo.src}}"
         class="download-link"
         target="_blank"
         download="toto">
        <span class="mr-2"> {{photo.caption}} </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
          <defs />
          <path fill="#1C1C1B" fill-rule="nonzero"
            d="M4.70710678 9.29289322L10 14.5857864l5.2928932-5.29289318c.3905243-.39052429 1.0236893-.39052429 1.4142136 0 .3905243.39052429.3905243 1.02368928 0 1.41421358l-6 6c-.3905243.3905243-1.02368929.3905243-1.41421358 0l-6-6c-.39052429-.3905243-.39052429-1.02368929 0-1.41421358.39052429-.39052429 1.02368927-.39052429 1.41421356 0zM10 2c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1-.55228475 0-1-.4477153-1-1V3c0-.55228475.44771525-1 1-1z" />
        </svg>
      </a>

    </div>
  </div>


</div>
