<div class="item-row">
  <div class="item-row__col item-row__col--avatar item-row__avatar">
    <span class="item-row__avatar-container">
      <img class="item-row__avatar-src"
           src="{{ member.user.avatar }}"
           alt=""
           >
    </span>
  </div>
  <div class="item-row__col">
    <div class="item-row__text item-row__text--title d-inline-block">
      {{ member.user.firstName }}
    </div>
    <p class="item-row__text item-row__text--subtitle">{{ member.user.lastName }}</p>
  </div>
  <div class="item-row__col item-row__col--roles">
    <div class="d-flex" >
        <nz-select class="select select--small"
                 [nzDisabled]="!editable"
                 [(ngModel)]="member.roleId"
                 (ngModelChange)="changeOrganizationMemberRole()"
                 [ngModelOptions]="{standalone: true}">
               <nz-option *ngFor="let organizationsMemberRole of itemLoopOptions"
                   [nzValue]="organizationsMemberRole.id"
                   [nzLabel]="organizationsMemberRole.name">
              </nz-option>
      </nz-select>

      <span
          nz-tooltip
          [nzTitle]="member.canLeaveOrg ?  null : translate.instant('LEAVE-ORPHAN-ORGANIZATION')"
          style="padding-top:2px">
        <button
                type="button"
                *appPermission="5"
                [disabled]="!member.canLeaveOrg"
                class="btn-square"
                style="padding-top:2px; width:44px; height:45px "
                (click)="ConfirmRemoveOrganizationMember(member.id)"><i class="icon-trash" aria-hidden="true"></i></button>
      </span>
    </div>
  </div>

</div>
