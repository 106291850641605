import { Routes } from '@angular/router';
import { MembershipComponent } from './components/membership.component';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';

export const MembershipRoutes: Routes = [
  {
    path: ':page',
    component: MembershipComponent,
    data: {
      appPermission: 1,
      organizationId: 1,
    },
  },
];
