export enum Role {
	SUPER_ADMIN = 0,
	ADMINISTRATOR = 1,
	EDITOR = 2,
	CONTRIBUTOR = 3,
	USER = 4
}

export enum OrgRoles {
	ADMINISTRATOR = 1,
	EDITOR = 2,
	CONTRIBUTOR = 3
}

export enum Permission {
	IS_ADMIN = 0,
	CAN_EDIT = 1,
	CAN_DELETE = 2,
	CAN_SUBMIT = 3,
	CAN_APPROVE = 4,
	CAN_MANAGE = 5,
	CAN_PAY = 6
}
