import { Routes } from '@angular/router';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { TagsSingleComponent } from './components/tags-single/tags-single.component';
import { PermissionGuard } from '../account/services/permission-guard.service';

export const TagsRoutes: Routes = [
  {
    path: '',
    component: TagsListComponent,
    data: {
      breadcrumb: [SiteConcepts.ETIQUETTE],
    },
  },
  {
    path: ':tagId',
    children: [
      {
        path: '',
        component: TagsSingleComponent,
        data: {
          breadcrumb: [SiteConcepts.ETIQUETTE, SiteConcepts.SINGLE],
          appPermission: undefined,
          shouldBeApproved: false,
        },
      },
    ],
    canActivate: [PermissionGuard],
  },
];
