import { Tour } from 'src/app/concepts/tours/model/tour.model';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { Program } from 'src/app/concepts/program/model/program.model';
import { Calendar } from 'src/app/concepts/program/model/calendar.model';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';

import { TourResolver } from 'src/app/concepts/tours/tour.resolver';
import { PaidToursResolver } from 'src/app/concepts/tours/paid-tours.resolver';
import { ArchivedPaidToursResolver } from 'src/app/concepts/tours/archived-paid-tours.resolver';
import { UserResolver } from 'src/app/concepts/account/user.resolver';
import { ProgramsResolver } from 'src/app/concepts/program/programs.resolver';
import { CalendarsResolver } from 'src/app/concepts/program/calendars.resolver';
import { UserOrganizationResolver } from 'src/app/concepts/account/user-organization.resolver';
import { PaidTourShowsResolver } from 'src/app/concepts/tours/paid-tour-shows.resolver';
import { PaidTourShowResolver } from 'src/app/concepts/tours/paid-tour-show.resolver';
import { TourAdminResolver } from 'src/app/concepts/tours/tour-admin.resolver';
import { PaidTourProgramsResolver } from 'src/app/concepts/tours/paid-tour-programs.resolver';

export interface ProgramRouteData {
    calendars: Calendar[];
    programs: Program[];
    paidTours: Tour[];
    archivedPaidTours: Tour[];
    organization: Organization;
    user?: PxUser;
    tour?: Tour;
    tourShows?: TourShow[];
    tourShow?: TourShow;
    tourAdmin?: boolean;
    tourPrograms?: Program[];
    date?: string;
}

export const ProgramRouteResolvers = {
    paidTours: PaidToursResolver,
    archivedPaidTours: ArchivedPaidToursResolver,
    programs: ProgramsResolver,
    calendars: CalendarsResolver,
    organization: UserOrganizationResolver
};

export const TourProgramRouteResolvers = {
    user: UserResolver,
    tour: TourResolver,
    paidTours: PaidToursResolver,
    archivedPaidTours: ArchivedPaidToursResolver,
    calendars: CalendarsResolver,
    organization: UserOrganizationResolver,
    tourShows: PaidTourShowsResolver,
    tourAdmin: TourAdminResolver,
    tourPrograms: PaidTourProgramsResolver
};

export const TourShowProgramRouteResolvers = {
    user: UserResolver,
    tour: TourResolver,
    paidTours: PaidToursResolver,
    archivedPaidTours: ArchivedPaidToursResolver,
    programs: ProgramsResolver,
    calendars: CalendarsResolver,
    organization: UserOrganizationResolver,
    tourShows: PaidTourShowsResolver,
    tourShow: PaidTourShowResolver,
    tourAdmin: TourAdminResolver,
    tourPrograms: PaidTourProgramsResolver
};
