import { SiteConcepts } from '../enums/site-concepts.enum';

export class BreadcrumbItem {
  title: string;
  url: string;
  isEditing: boolean;
  mainLevel: SiteConcepts;

  constructor(data?: Record<string, any>) {
    if (data) {
      this.title = data['title'];
      this.url = data['url'];
      this.isEditing = data['isEditing'];
    }
  }

  public set(title: string, url?: string, isEditing?: boolean): void {
    // Set item
    this.url = url;
    this.title = title;
    this.isEditing = !!isEditing;
  }
}
