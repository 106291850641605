<app-connection-container
  [title]=' "RESET_YOUR_PASSWORD" | translate'
>

  <div class="FogotPassword  mt-4 ">

    <div class="row justify-content-center">

      <div class="ForgotPassword__col col-lg-4 col-sm-12 col-md-8" >
        <p class="ForgotPassword__instruction clr-white">
          {{ "ENTER_EMAIL" | translate }}.
        </p>
      </div>

    </div>

    <div class="row justify-content-center">
      <form
        [formGroup]="forgotPasswordForm"
        nz-form
        class="ForgotPassword__col col-lg-4 col-sm-12 col-md-8"
        (ngSubmit)='sendPasswordResetRequest()'
        >

        <nz-form-item >
          <nz-form-control>
            <label style="color: white!important;" for="email"> {{ "EMAIL_ADRESS" | translate }} </label>
            <input formControlName='email' type="email" name="email">
            <nz-form-explain class="has-error" *ngIf="forgotPasswordForm.invalid && forgotPasswordForm.get('email').errors &&
            forgotPasswordForm.get('email').touched">
            <span
            *ngIf="forgotPasswordForm.get('email').errors['required']; else incorrectField">{{ "CHAMP-REQUIS-SIMPLE" | translate }}</span>
            <ng-template #incorrectField>{{'ENTRER-VALEUR'|translate}}</ng-template>
          </nz-form-explain>            
          </nz-form-control>
       </nz-form-item>


       <div class="ForgotPassword__action">
          <app-button-connection
            class="w-100"
            buttonType='Primary'
            [text]=' "SEND" | translate '
            type='submit'
            [isLoading]='isLoading'
            [disable]='!forgotPasswordForm.valid'
          ></app-button-connection>
       </div>


      </form>
    </div>
    <div class="ForgotPassword__connection-link row justify-content-center">
       <a class="cta_link ForgotPassword__col col-lg-4 col-sm-12 col-md-8" [routerLink]='["../login"]'> {{ "BACK_TO_CONNECTION" | translate }}</a>
    </div>


  </div>

</app-connection-container>
