import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from '../../model/list-item.model';
import { RowTypes } from '../../enums/row-types.enum';

let unique = 0;

interface RowSelectable {
    getTranslatedProperty(currentLang: string, property: string): string;
    toListItem(currentLang: string): ListItem;
}

@Component({
    selector: 'app-row-select',
    template: `
        <nz-form-item>
            <label [attr.for]="id">
                <ng-content></ng-content>
            </label>

            <app-item-row
                [itemRowType]="rowType"
                [item]="selectedItemRow()"
                [enableRemoving]="!disabled"
                hideStatus="true"
                (removeItem)="onRemoved()"
                *ngIf="selected"
            >
            </app-item-row>

            <nz-form-control>
                <nz-select
                    [attr.id]="id"
                    name="selected"
                    nzShowSearch
                    nzAllowClear
                    [(ngModel)]="selected"
                    (ngModelChange)="onSelected($event)"
                    *ngIf="!selected"
                    [nzDisabled]="disabled"
                >
                    <nz-option
                        *ngFor="let option of options"
                        [nzLabel]="option.getTranslatedProperty(translateService.currentLang, labelProperty)"
                        [nzValue]="option[valueProperty]"
                    >
                    </nz-option>
                </nz-select>

                <ng-content select="[after]"></ng-content>
            </nz-form-control>
        </nz-form-item>
    `,
    styles: [
        `
            nz-select {
                width: 100%;
            }
        `
    ]
})
export class RowSelectComponent<T, U> implements ControlValueAccessor {
    private onChange: (value: U) => void;
    private onTouched: () => void;
    @Input() rowType: RowTypes;
    @Input() options: Array<T & RowSelectable>;
    @Input() labelProperty: string;
    @Input() valueProperty = 'id';
    @Input() disabled: boolean;
    id = `RowSelectComponent-${unique++}`;
    selected: U;
    constructor(readonly translateService: TranslateService, private readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    onSelected(): void {
        this.onChange(this.selected);
        this.onTouched();
    }
    onRemoved(): void {
        this.onChange((this.selected = null));
        this.onTouched();
    }

    selectedItemRow(): ListItem {
        if (!this.options) {
            return;
        }
        const item = this.options.find((option) => option[this.valueProperty] === this.selected);
        if (!item) return;
        const listItem = item.toListItem(this.translateService.currentLang);
        if ('itemUrl' in item) {
            listItem.itemUrl = item['itemUrl'] as string;
        }
        if ('itemUrlExt' in item) {
            listItem.itemUrlExt = item['itemUrlExt'] as boolean;
        }
        return listItem;
    }

    writeValue(propertyValue: U): void {
        this.selected = propertyValue;
    }
    registerOnChange(fn: (value: U) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
