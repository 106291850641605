<header *ngIf="isNetworkMember">
    <ng-container
        *ngTemplateOutlet="isNetworkAdmin && !isSceneProAdmin ? adminAccessTpl : memberAccessTpl"></ng-container>
</header>
<nz-card [nzBordered]="false" [nzActions]="[actionsTemplate]">
    <nz-card-meta [nzTitle]="organizationName" [nzAvatar]="avatarTemplate"></nz-card-meta>
</nz-card>
<ng-template #avatarTemplate>
    <nz-avatar [nzSrc]="organization.logo"></nz-avatar>
</ng-template>
<ng-template #actionsTemplate>
    <ng-container *ngFor="let tour of item.tours">
        <app-depot-button [tour]="tour" [isNetworkMember]="isNetworkMember"
            [isSceneProAdmin]="isSceneProAdmin"></app-depot-button>
    </ng-container>
</ng-template>

<ng-template #adminAccessTpl>
    <span class="title title--admin">{{'ADMIN_ACCESS' | translate}}</span>
</ng-template>
<ng-template #memberAccessTpl>
    <span class="title title--member">{{'MEMBER_ACCESS' | translate}}</span>
</ng-template>