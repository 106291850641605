import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageKey } from './auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private unprotectedResources = ['assets/*', 'login', 'register', 'refresh-token', 'confirm-email'];

  constructor(
    public translate: TranslateService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let isUnprotectedRessource = false;
    if (this.unprotectedResources) {
      isUnprotectedRessource = this.unprotectedResources.some((res) =>
        this.glob(res, req.url)
      );
      if(isUnprotectedRessource){
        return next.handle(req);
      }
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem(StorageKey.RIDEAU_TOKEN)}`,
      'Accept-Language': `${this.translate.currentLang}`,
    });

    return next.handle(req.clone({
      headers
    }));

  }


  private glob(pattern, input) {
    const re = new RegExp(
      pattern.replace(/([.?+^$[\]\\(){}|\/-])/g, '\\$1').replace(/\*/g, '.*')
    );
    return re.test(input);
  }

}
