// import { VenueSingleComponent } from './components/venue-single/venue-single.component';
import { Routes } from '@angular/router';
import { Permission } from 'src/app/shared/enums/roles.enum';
import { PermissionGuard } from '../account/services/permission-guard.service';
import { OrganizationTypes } from '../organization/enums/organization-types.enum';
import { VenueModifyComponent } from './components/venue-modify/venue-modify.component';
import { VenueSingleComponent } from './components/venue-single/venue-single.component';
// import { VenueListComponent } from './components/venue-list/venue-list.component';

export const VenueRoutes: Routes = [
  {
    path: 'create',
    component: VenueModifyComponent,
  },
  {
    path: ':organizationId/:venueId',
    children: [
      {
        path: '',
        component: VenueSingleComponent,
        data: {
          appPermission: undefined,
          shouldBeApproved: false,
        },
      },
      {
        path: 'modify/:page',
        component: VenueModifyComponent,
        data: {
          appPermission: Permission.CAN_EDIT,
          organizationType: OrganizationTypes.IS_SALLE,
        },
      },
      {
        path: 'modify',
        component: VenueModifyComponent,
        data: {
          appPermission: Permission.CAN_EDIT,
          organizationType: OrganizationTypes.IS_SALLE,
        },
      },
    ],
    canActivate: [PermissionGuard],
  },
];
