import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-indication',
  templateUrl: './loading-indication.component.html',
  styleUrls: ['./loading-indication.component.scss']
})
export class LoadingIndicationComponent implements OnInit {


  constructor() { }
  ngOnInit(): void {
  }

}
