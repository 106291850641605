import { ListItem } from '../../../model/list-item.model';
import { Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';
import { RowTypes } from '../../../enums/row-types.enum';
import { NzModalService } from 'ng-zorro-antd';
import { Globals } from '../../../../_configs/globals';
import { TranslateService } from '@ngx-translate/core';
import { TourInterest } from 'src/app/concepts/tours/enums/tour-interet.enum';
import { TourShowInterest } from 'src/app/concepts/tours/model/tour-show-interest.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { WINDOW } from '@app/shared/services/window.provider';
@Component({
    selector: 'app-item-row',
    templateUrl: './item-row.component.html',
    styleUrls: [ './item-row.component.scss' ]
})
export class ItemRowComponent implements OnInit {
    RowTypes = RowTypes;
    @Input() item: ListItem;
    @Input() itemRowType: RowTypes;
    @Input() itemToRate = false;
    @Input() enableEditing: boolean;
    @Input() enableRemoving: boolean;
    @Input() hideThumbnails: boolean;
    @Input() hideStatus: boolean;
    @Input() isOrgAdmin = true;
    @Input() isAdmin = false;
    @Input() confirmPrompt = true;
    @Input() loading = false;
    @Input() removeIcon = 'icon-x';
    @Input() classList?: string[] = [];

    @Output() modifyItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeItem: EventEmitter<any> = new EventEmitter<any>();

    // Rating
    @Output() rateItem: EventEmitter<any> = new EventEmitter<any>();

    @Output() updateKeepedShow: EventEmitter<any> = new EventEmitter<any>();

    activityRow: RowTypes = RowTypes.ACTIVITY;
    productRow: RowTypes = RowTypes.PRODUCT;
    showRow: RowTypes = RowTypes.SHOW;

    itemDate = {};
    dateParser = {};
    day: string;
    month: string;

    rateValue: number;
    update: boolean;
    likes = 0;
    dislikes = 0;
    isDisabled: boolean;
    isKeep = false;

    constructor(
        private globals: Globals,
        private translate: TranslateService,
        private modalService: NzModalService,
        private accountService: AccountService,
        @Inject(WINDOW) private window: Window
    ) { }

    @HostBinding('class') get classListHandler() {
        this.classList.push('d-sm-flex');
        if (this.item.itemCategory || this.item.itemInfo5) {
            this.classList.push('item-row__category');
        }
        return [ ...this.classList ];
    }
    ngOnInit(): void {
        if (!this.item.itemImgSrc || !this.item.itemImgSrc.trim()) {
            //image prop fallback
            this.item.itemImgSrc = this.globals.imgFallback;
        }
        this.isKeep = this.item.itemIsToKeep;

        if (this.itemRowType == this.activityRow) {
            if (this.item.itemDate) {
                this.dateParser = this.item.itemDate.split(/[^0-9]/);
                this.day = this.dateParser[ 2 ].replace(/^0+/, '');
                this.month = 'MOIS.' + (this.dateParser[ 1 ] - 1).toString();
            } else {
                this.itemDate[ 'date' ] = '-';
                this.itemDate[ 'month' ] = '-';
            }
        }
        if (this.itemToRate && this.item.itemRatings) {
            this.likes = this.item.itemRatings.likes;
            this.dislikes = this.item.itemRatings.dislikes;
            this.rateValue = this.item.itemRatings.interest;
        }
        this.isDisabled = this.item.itemStatus === 5 ? true : null;
    }

    isliked(): void {
        this.update = this.rateValue == TourInterest.LIKE;
        this.rateValue = this.update ? TourInterest.NOEVAL : TourInterest.LIKE;
        this.onRateItem();
    }

    isdisliked(): void {
        this.update = this.rateValue == TourInterest.DISLIKE;
        this.rateValue = this.update ? TourInterest.NOEVAL : TourInterest.DISLIKE;
        this.onRateItem();
    }

    onRateItem(): void {
        const rating: TourShowInterest = new TourShowInterest({
            tourShowId: this.item.itemId,
            interest: this.rateValue,
            reason: []
        });

        this.rateItem.emit(rating);
    }

    modifyItemForm(): void {
        this.modifyItem.emit(this.item.itemId);
    }

    removeItemForm(): void {
        if (this.confirmPrompt) {
            const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
            const acceptDelete = this.translate.instant('OUI');
            const rejectDelete = this.translate.instant('NON');
            this.modalService.confirm({
                nzContent: deleteMessages,
                nzOkText: acceptDelete,
                nzCancelText: rejectDelete,
                nzClosable: true,
                nzMaskClosable: true,
                nzOnOk: () => {
                    this.removeItem.emit(this.item.itemId);
                }
            });
        } else {
            this.removeItem.emit(this.item.itemId);
        }
    }

    isShowKeeped(): void {
        const currentOrganizationId = this.accountService.getCurrentCtxOrganizationId();
        const showKeeped: number = this.isKeep ? 2 : 1;
        this.updateKeepedShow.emit({
            id: this.item.itemId,
            statusId: showKeeped,
            organizationId: currentOrganizationId
        });
    }

    openUrl = (url: string): void => {
        const protocol = this.window.location.protocol;
        const host = this.window.location.host;
        const newUrl = `${protocol}//${host}/${url}`;
        this.window.open(newUrl, '_blank');
    };
}
