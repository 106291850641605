import { Globals } from './../../../../../_configs/globals';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Component, OnInit } from '@angular/core';
import { AbstractShowForm } from '../show-abstract-form/show-abstract-form.component';
import { ShowService } from '../../../services/show.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IContactsResponse, IShowContact, Show, ShowContact } from '../../../../../concepts/show/model/show.model';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-show-contacts-form',
    templateUrl: './show-contacts-form.component.html',
    styleUrls: [ './show-contacts-form.component.scss' ],
})
export class ShowContactsFormComponent
    extends AbstractShowForm
    implements OnInit {
    public collapseNewContactForm = false;
    public currentContactModification: number;
    public showContactForm: FormGroup;
    public showContacts: any = {};

    private updateShow = (updatedContacts: IShowContact[]) => {
        this.show = new Show({
            ...this.show,
            contacts: updatedContacts,
        });
        // do not switch to next tab
        super.doSubmit('simple');
    }

    constructor(
        private fb: FormBuilder,
        private showService: ShowService,
        private notification: RideauNotificationService,
        private translate: TranslateService,
        protected globals: Globals,
        protected accountService: AccountService
    ) {
        super(accountService, globals);
        this.formGroup = new FormGroup({});
    }

    public addNewContact() {
        this.showService
            .createShowContact(this.show.id, this.showContactForm.value)
            .subscribe(async (data) => {
                this.showContactForm.reset();
                this.notification.success(this.translate.instant('FORM.CONTACT-AJOUTE'));
                this.showContacts.push(new ShowContact(data[ 'contact' ]));
                this.updateShow(<IShowContact[]>this.showContacts);
            });
    }

    public async getShowContacts(): Promise<IShowContact[]> {
        this.showContacts = []; //reset list
        return this.showService.getShowContacts(this.show.id)
            .pipe(
                map(({ contacts }: IContactsResponse) => contacts),
                tap((contacts: IShowContact[]) => (this.showContacts = contacts)))
            .toPromise();
    }

    public ngOnInit(): void {
        this.showContactForm = new FormGroup({
            firstName: new FormControl('', [ Validators.required ]),
            lastName: new FormControl('', [ Validators.required ]),
            title: new FormControl('', [ Validators.required ]),
            phone: new FormControl('', [ Validators.required ]),
            phonePostNumber: new FormControl(''),
            email: new FormControl('', [ Validators.pattern(this.globals.emailRegex), Validators.required ])
        });

        this.showContacts = this.show.contacts;
    }

    public onHideOtherModifyForms(event) {
        this.collapseNewContactForm = false; //hide New Contact Form
        this.currentContactModification = event;
    }

    public async onModifyShowContact(event: ShowContact) {
        this.showService
            .modifyShowContact(this.show.id, event)
            .subscribe(
                async () => {
                    this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
                    const updatedContacts = await this.getShowContacts();
                    this.updateShow(updatedContacts);
                    this.currentContactModification = null;
                }
            );
    }

    public async onRemoveShowContact(event: any) {
        this.showService
            .removeShowContact(this.show.id, event)
            .subscribe(
                async () => {
                    this.notification.success(this.translate.instant('FORM.CONTACT-SUPPRIME'));
                    const updatedContacts = await this.getShowContacts();
                    this.updateShow(updatedContacts);
                });
    }

    public showHideModifyForm(id) {
        return this.currentContactModification == id;
    }

    public showNewContactForm() {
        this.collapseNewContactForm = true;
        this.currentContactModification = null;
    }

    protected initForm(): void {
        return;
    }
}
