<form id="profile-form" class="mb-4" nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control>
                    <label class="d-block" for="description">{{ 'FORM.DESCRIPTION' | translate }} *</label>
                    <div class="quill-editor-edit" [class.ql-disabled]="formGroup.disabled" tabindex="formGroupe.disabled ? null : 0">
                        <quill-editor
                            id="description"
                            class="w-100"
                            [(ngModel)]="descriptionModel"
                            [disabled]="formGroup.disabled"
                            [sanitize]="true"
                            [modules]="descriptionModules"
                            (onContentChanged)="limitDescriptionLength($event)"
                            [placeholder]="'FORM.DESCRIPTION-PLACEHOLDER' | translate"
                            [ngModelOptions]="{ standalone: true }"
                        ></quill-editor>
                    </div>
                </nz-form-control>
                <nz-form-explain class="has-error" *ngIf="displayErrors && isBlankDescValidator(descriptionModel)">
                    {{ 'CHAMP-REQUIS' | translate }}
                </nz-form-explain>
            </nz-form-item>
        </div>
        <div class="col-md-6">
            <nz-form-control>
                <label>{{ 'FORM.PHOTO' | translate }} *</label>
            </nz-form-control>

            <app-img-upload
                [currentImage]="venue.photo"
                [uploadAction]="uploadAction"
                [isDisabled]="formGroup.disabled"
                [maxFileWeigth]="1000"
                uploadActionName="venuemain"
                (onUploadFile)="onUploadFileCallback($event)"
            ></app-img-upload>

            <nz-form-explain class="has-error" *ngIf="displayErrors && formGroup.get('photo').errors">
                {{ 'CHAMP-REQUIS' | translate }}
            </nz-form-explain>
        </div>
    </div>
    <ng-container *ngIf="venue.statusId === 1 || venue.statusId === 4">
        <button *appPermission="3; objectOrganizationId: venue.organizationId" nz-button [nzLoading]="isLoading" class="btn btn--default">
            {{ 'ENREGISTER-ET-SOUMETTRE' | translate }}
        </button>
        <p *appPermission="1; objectOrganizationId: venue.organizationId" class="d-inline-block ml-2">
            {{ 'OU' | translate }}
            <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
            <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
        </p>
    </ng-container>
    <ng-container *ngIf="(venue.statusId === 3 || venue.statusId === 2) && !formGroup.disabled">
        <button *appPermission="1; objectOrganizationId: venue.organizationId" nz-button [disabled]="formGroup.invalid || !descriptionModel || !formGroup.get('photo').value" [nzLoading]="isLoading" class="btn btn--default">
            {{ 'ENREGISTER' | translate }}
        </button>
    </ng-container>
</form>
