<app-page-title title="{{title}}"
                buttonText="{{'IMPRIMER' | translate }}"
                (buttonClicked)="print()">
</app-page-title>
<div class="container">
  <article>
    <div class="scene-pro-header">
      <div>
        <p>RIDEAU </p>
        <p>1550, boul. Saint-Joseph Est </p>
        <p>Montréal, Québec, H2J 1M7 </p>
        <p>Téléphone : 514 598-8024</p>
      </div>
      <img src="assets/images/logo-sc-ne-pro.svg"
           alt="Logo Scène Pro">
    </div>

    <div class="orga-infos"
         *ngIf="organization">
      <p>{{organization.getTranslatedProperty(lang,'name')}}</p>
      <p>{{organization.address.address1}}</p>
      <p>{{organization.address.address2}}</p>
      <p>{{organization.address.city}}</p>
      <p>{{organization.address.state}}</p>
      <p>{{organization.address.zipCode}}</p>
      <p>{{organization.address.country}}</p>
      <p>{{organization.email}}</p>
    </div>
    <div class="orga-infos"
         *ngIf="user">
      <p>{{user.firstName}}</p>
      <p>{{user.lastName}}</p>
      <p>{{user.email}}</p>
    </div>


    <ng-container *ngIf="billInfos">

      <div class="bill-header">
        <div>Date: {{billInfos.date | date  : 'mediumDate' : 'GMT'}}</div>
        <div *ngIf="!billInfos.originalNumero">{{'FACTURE'|translate}}</div>
        <div *ngIf="billInfos.originalNumero">{{'NOTE-DE-CREDIT'|translate}} </div>
        <div></div>
      </div>

      <table role="none">
        <tr class="table-head">
          <td>
            {{'FORM.DESCRIPTION'|translate}}
          </td>
          <td>
            {{'FORM.QUANTITE'|translate}}
          </td>
          <td>
            {{'FORM.PRIX-UNITAIRE'|translate}}
          </td>
          <td class="th last-col">
            {{'FORM.MONTANT'|translate}}
          </td>
          <td class="th refund-col"
              *ngIf="isRefund">
            {{'REMBOURSEMENT' | translate}}
          </td>
        </tr>
        <ng-container *ngIf="products">
          <tr *ngFor="let product of products; index as i">
            <td>
              {{ product.name + (product.productTypeId == '3' ? " - " + product.participantName : "")}}
            </td>
            <td>
              {{ product.quantity }}
            </td>
            <td>
              {{ formatter.format(product.price) }} $
            </td>
            <td class="last-col">
              {{ formatter.format(product.quantity * product.price) }} $
            </td>
            <td class="refund-col"
                *ngIf="isRefund">
              <input type="number"
                     [(ngModel)]="productRefund[i].amount"
                     (ngModelChange)="calculateTotal()">
            </td>
          </tr>
        </ng-container>
      </table>
      <div class="bill-footer">
        <div class="bill-totals">
          <div class="labels">
            <span>{{'PANIER.SOUS-TOTAL'|translate}}</span>
            <span>{{'PANIER.TPS'|translate}}</span>
            <span>{{'PANIER.TVQ'|translate}}</span>
            <span>{{'PANIER.TOTAL'|translate}}</span>
            <span>{{'MONTANT-RECU'|translate}}</span>
            <span>{{'SOLDE-A-PAYER'|translate}}</span>
          </div>
          <div class="values">
            <span>{{ formatter.format(billInfos.subtotal) }} $</span>
            <span>{{ formatter.format(billInfos.taxTps) }} $</span>
            <span>{{ formatter.format(billInfos.taxTvq) }} $</span>
            <span>{{ formatter.format(billInfos.total) }} $</span>
            <span>{{ formatter.format(billInfos.receivedAmount) }} $</span>
            <span>{{ formatter.format(billInfos.amountToPay) }} $</span>
          </div>
          <div *ngIf="isRefund"
               class="values refund-totals">
            <span>{{ refund.subtotal }} $</span>
            <span>{{ refund.taxTps }} $</span>
            <span>{{ refund.taxTvq }} $</span>
            <span>{{ refund.total }} $</span>

            <button nz-button
                    [nzLoading]="isLoading"
                    class="btn btn--default mt-1"
                    (click)="doRefund()">
              {{ "REMBOURSER" | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="billInfos.originalNumero"
             class="bill-number">{{'REMBOURSEMENT-POUR-FACTURE'|translate}} {{billInfos.originalNumero}}</div>
        <div class="bill-number">{{'FACTURE'|translate}} {{billInfos.numero}}</div>

        <ng-container *ngIf="user && user.hasAcceptedLegal">
          <p class="page-template page-template-text page-template-text--smaller text-center py-4">La présente facture est assujettie aux conditions d’utilisations de Scène Pro.</p>
        </ng-container>
      </div>
    </ng-container>
  </article>
</div>


