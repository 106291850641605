import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortenText',
})
export class ShortenTextPipe implements PipeTransform {
    transform(value: string, limit = 35): string {
        if (value && value.length > limit) {
            return value.substring(0, limit) + ' ...';
        }
        return value;
    }
}
