import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Tour } from 'src/app/concepts/tours/model/tour.model';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { Subscription, Observable } from 'rxjs';
import { ProgramRouteData } from 'src/app/concepts/program/program-route-resolvers';

@Component({
    selector: 'app-tour-manager',
    templateUrl: './tour-manager.component.html',
    styleUrls: ['./tour-manager.component.scss']
})
export class TourManagerComponent implements OnInit, OnDestroy {
    groupedTours: Record<string, Tour[]>;
    tourId: number;
    subscription = new Subscription();
    routeData: ProgramRouteData;

    constructor(
        readonly translateService: TranslateService,
        readonly activatedRoute: ActivatedRoute,
        private readonly localizeRouterService: LocalizeRouterService,
        private readonly router: Router
    ) {}

    ngOnInit() {
        this.subscription.add(
            (this.activatedRoute.data as Observable<ProgramRouteData>).subscribe((routeData) => {
                this.routeData = routeData;
                this.groupedTours = routeData.paidTours.reduce<Record<string, Tour[]>>((acc, tour) => {
                    const group = tour.organization.getTranslatedProperty(this.translateService.currentLang, 'name');
                    return { ...acc, [group]: [...(acc[group] || []), tour] };
                }, {});
                this.tourId = routeData.tour && routeData.tour.id;
            })
        );
    }

    ngModelChange() {
        const queryParams = { ...this.activatedRoute.snapshot.queryParams, nonTourDates: 1 };
        this.router.navigate([this.localizeRouterService.translateRoute(`/programmations/tour/${this.tourId}`)], { queryParams });
    }

    clearSelection() {
        this.router.navigate([this.localizeRouterService.translateRoute(`/programmations`)]);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
