export class MarketingState {

  currentState: MarketingStateType;

  marketingStateType: MarketingStateType[] = []

  constructor(marketingStateType: MarketingStateType[]){
    this.currentState = marketingStateType[0];
    this.marketingStateType = marketingStateType;
  }

  changeState(stateName: string){
    this.currentState = this.marketingStateType.find(state => state.name === stateName)
  }


}


export type MarketingStateType = {
  name: string;
  prop : MarketingStateProp;
}

export type MarketingStateProp = {
  class: string;
  icon: string;
  iconStyle: string;
  sentence: string;
  buttonSentence: string;
  callback: () => any
}
