import { Pipe, PipeTransform } from '@angular/core';
import { Show } from 'src/app/concepts/show/model/show.model';
import { ShowService } from 'src/app/concepts/show/services/show.service';

@Pipe({
  name: 'extractArtistListPipeable'
})
export class ExtractArtistListPipeablePipe implements PipeTransform {

  constructor(private showService: ShowService){}

  transform(show: Show): any {
    return this.showService.extractArtistList(show)
  }
}
