import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportsService } from '../../reports.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  formGroup: FormGroup;
  reportRange: Date[] = [new Date(), new Date()];
  isLoading = false;
  FACTURES = 'factures';
  PRODUITS = 'produits';
  MEMBRES = 'membres';
  USERS = 'users';

  constructor(private fb: FormBuilder, private reportService: ReportsService) {}

  doSubmit(param: string) {
    // this.isLoading = true;
    const obs: Observable<any> = null;
    switch (param) {
      case this.FACTURES:
        this.reportService.generateBillingReport(
          this.reportRange[0],
          this.reportRange[1]
        );
        break;
      case this.PRODUITS:
        this.reportService.generateProductsReport(
          this.reportRange[0],
          this.reportRange[1]
        );
        break;
      case this.MEMBRES:
        this.reportService.generateMembersReport();
        break;
      case this.USERS:
        this.reportService.generateUsersReport();
        break;
    }
  }
}
