<div class="page-template-head">
  <div class="container">
    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      class="d-md-flex align-items-center"
    >
      <div nz-col nzSpan="16" class="page-title--title">
        <h2>{{ (id ? 'DEPOT-RESEAUX' : 'NEW-DEPOT') | translate }}</h2>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <i nz-icon
    nzType="loading"
    nzTheme="outline"
    *ngIf="updating"></i>

  <form #form="ngForm" (ngSubmit)="onSubmit(form)" novalidate *ngIf="!updating">
    <nz-form-item>
      <nz-form-control>
        <label for="title">
          {{ "FORM.NOM-DEPOT" | translate }} *
          <input nz-input id="title" [(ngModel)]="name" name="name" required />
        </label>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="organizationId">{{ 'FORM.ORGANISATION-PROPRIETAIRE' | translate }} *</nz-form-label>
      <nz-form-control>
        <nz-select
          nzShowSearch nzAllowClear
          [(ngModel)]="organizationId"
          name="organizationId"
          required>
          <nz-option *ngFor="let network of networks"
            [nzLabel]="network.getTranslatedProperty(lang,'name')"
            [nzValue]="network.id"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- <nz-form-item>
      <nz-form-label nzFor="intake">{{ 'FORM.RECEPTION-DEPOTS' | translate }}</nz-form-label>
      <nz-form-control>
        <nz-range-picker
          nzAllowClear="true"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"
          nzFormat="yyyy/MM/dd HH:mm"
          [(ngModel)]="intake"
          name="intake">
        </nz-range-picker>
      </nz-form-control>
    </nz-form-item> -->

    <!-- <nz-form-item>
      <nz-form-label nzFor="processing">{{ 'FORM.ANALYSE-DEPOTS' | translate }}</nz-form-label>
      <nz-form-control>
        <nz-range-picker
          nzAllowClear="true"
          [nzShowTime]="{ nzFormat: 'HH:mm' }"
          nzFormat="yyyy/MM/dd HH:mm"
          [(ngModel)]="processing"
          name="processing">
        </nz-range-picker>
      </nz-form-control>
    </nz-form-item> -->

    <nz-form-item>
      <nz-form-control>
        <label
          [(ngModel)]="isAvailable"
          name="isAvailable"
          nz-checkbox>{{ "FORM.PUBLISH-DEPOSIT" | translate }}</label>
      </nz-form-control>
    </nz-form-item>

    <button
      type="submit"
      nz-button
      class="btn btn--default"
      [disabled]="!form.valid || submitting"
      [class.disabled]="!form.valid || submitting">
      {{ "ENREGISTER" | translate }}
    </button>

  </form>
</div>
