import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { PermissionGuard } from './../account/services/permission-guard.service';
import { ShowSingleComponent } from './components/show-single/show-single.component';
import { Routes } from '@angular/router';
import { ShowModifyComponent } from './components/show-modify/show-modify.component';
import { ShowListComponent } from './components/show-list/show-list.component';
import { Permission } from 'src/app/shared/enums/roles.enum';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';
import { PromotionFormComponent } from '../promotion/components/promotion-form/promotion-form.component';

export const ShowRoutes: Routes = [
  {
    path: '',
    component: ShowListComponent,
    data: {
      breadcrumb: [SiteConcepts.SHOW],
    },
  },
  {
    path: ':organizationId/:showId',
    children: [
      {
        path: '',
        component: ShowSingleComponent,
        data: {
          breadcrumb: [SiteConcepts.SHOW, SiteConcepts.SINGLE],
          appPermission: undefined,
          shouldBeApproved: false,
        },
      },
      {
        path: 'modify/:page',
        component: ShowModifyComponent,
        data: {
          breadcrumb: [SiteConcepts.SHOW, SiteConcepts.SINGLE],
          appPermission: Permission.CAN_EDIT,
          organizationType: OrganizationTypes.IS_PRODUCTEUR,
          shouldBeApproved: true,
        },
      },
      {
        path: 'modify',
        component: ShowModifyComponent,
        data: {
          breadcrumb: [SiteConcepts.SHOW, SiteConcepts.SINGLE],
          appPermission: Permission.CAN_EDIT,
          organizationType: OrganizationTypes.IS_PRODUCTEUR,
          shouldBeApproved: true,
        },
      },
      {
        path: 'new-promotion',
        component: PromotionFormComponent,
        data: {
          breadcrumb: [SiteConcepts.SHOW, SiteConcepts.SINGLE],
          appPermission: Permission.CAN_EDIT,
          organizationType: OrganizationTypes.IS_PRODUCTEUR,
          shouldBeApproved: true,
        },
      },
    ],
    canActivate: [PermissionGuard],
  },
];
