import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class FieldAreEqualsValidator extends Validators {
  static verify(firstFieldName:string,  secondFieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      const firstField = control.get(firstFieldName)
      const secondField = control.get(secondFieldName)

      if((firstField && secondField) && (firstField.value !== '' && secondField.value !== '') ){

        if(firstField.value !== secondField.value){
          return {fieldAreNotEqual: true}
        }
      }


      return null;
    };
  }
}
