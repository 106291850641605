import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
} from 'localize-router';
import { LocalizeRouterHttpLoader } from 'localize-router-http-loader';
import { AccountRoutes } from './concepts/account/account.routes';
// Concepts Routes
import { ProfileRoutes } from './concepts/account/profile.routes';
import { PermissionGuard } from './concepts/account/services/permission-guard.service';
import { ActivityRoutes } from './concepts/activities/activity.routes';
import { MeetingRoutes } from './concepts/meeting/meeting.routes';
import { MembershipRoutes } from './concepts/membership/membership.routes';
// Components
import { OrganizationListComponent } from './concepts/organization/components/organization-list/organization-list.component';
import { OrganiszationRoutes } from './concepts/organization/organization.routes';
import { ProductRoutes } from './concepts/product/product.routes';
import { AdminPublicationRoutes } from './concepts/publication/publication.routes';
import { ReportsComponent } from './concepts/reports/components/reports/reports.component';
import { ShowRoutes } from './concepts/show/show.routes';
import { VenueRoutes } from './concepts/venue/venue.routes';
import { BottinComponent } from './pages/bottin/bottin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HelpComponent } from './pages/help/help.component';
import { LoginComponent } from './pages/login/login.component';
import { ProMeetingComponent } from './pages/pro-meeting/pro-meeting.component';
import { RegisterComponent } from './pages/register/register.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { SiteConcepts } from './shared/enums/site-concepts.enum';
import { Permission } from './shared/enums/roles.enum';
import { AdminPromotionRoutes } from './concepts/promotion/promotion.routes';
import { AdminUserRoutes } from './concepts/user/user.routes';
import { ToursRoutes, AdminTourRoutes } from './concepts/tours/tours.routes';
import { TagsRoutes } from './concepts/tags/tags.routes';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { LegalComponent } from './pages/legal/legal.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ProgrammationRoutes } from './concepts/program/program.routes';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthGuard } from './concepts/account/services/auth/auth-guard.service';
import { EmailConfirmationComponent } from './pages/email-confirmation/email-confirmation.component';
import { CanLoginGuard } from './shared/guards/can-login.guard';
import { EmailConfirmationResolver } from './pages/email-confirmation/email-confirmation-resolver.resolver';

export function HttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient
) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

const SCENE_PRO_ID = 1;

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [CanLoginGuard],
    data: { data: 'login', breadcrumb: [] },
  },
  {
    path: 'legal',
    component: LegalComponent,
    data: {
      breadcrumb: [SiteConcepts.LEGAL],
    },
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      breadcrumb: [SiteConcepts.PRIVACY],
    },
  },
  {
    path: 'inscription',
    component: RegisterComponent,
    data: {
      breadcrumb: []
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [],
    data: {
      breadcrumb: []
    }
  },
  {
    path: 'reset-password/:userId',
    component: ResetPasswordComponent,
    canActivate: [],
    data: {
      breadcrumb: []
    }
  },
  {
    path: 'confirm-email/:userId',
    component: EmailConfirmationComponent,
    canActivate: [],
    resolve: [EmailConfirmationResolver],
    data: {
      breadcrumb: []
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      breadcrumb: [SiteConcepts.DASHBOARD],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'pro-meeting',
    component: ProMeetingComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    component: HelpComponent,
    data: {
      breadcrumb: [SiteConcepts.HELP],
    },
  },
  {
    path: 'repertory',
    component: BottinComponent,
    data: {
      breadcrumb: [SiteConcepts.BOTTIN],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    data: {
      breadcrumb: [SiteConcepts.NOTIFICATIONS],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications/:archive',
    component: NotificationsComponent,
    data: {
      breadcrumb: [SiteConcepts.NOTIFICATIONS],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'tour',
    children: [...ToursRoutes],
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    data: {
      breadcrumb: [SiteConcepts.REPORT],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'shopping-cart',
    component: ShoppingCartComponent,
    data: {
      breadcrumb: [SiteConcepts.SHOPCART],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'activity',
    children: [...ActivityRoutes],
    canActivate:[AuthGuard]
  },
  {
    path: 'pro-meeting',
    children: [...MeetingRoutes],
    canActivate: [AuthGuard]
  },
  {
    path: 'orgs',
    component: OrganizationListComponent,
    data: {
      breadcrumb: [SiteConcepts.BOTTIN],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'shows-offers',
    children: [...ShowRoutes],
    data: {
      breadcrumb: [SiteConcepts.SHOW],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'venue',
    children: [...VenueRoutes],
    data: {
      breadcrumb: [SiteConcepts.BOTTIN],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/membership',
    children: [...MembershipRoutes],
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      breadcrumb: [SiteConcepts.COTISATION],
    },
  },
  {
    path: 'admin/etiquettes',
    children: [...TagsRoutes],
    canActivate: [AuthGuard,PermissionGuard],
  },
  {
    path: 'admin/product',
    children: [...ProductRoutes],
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'admin/reports',
    component: ReportsComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      breadcrumb: [SiteConcepts.REPORT],
      appPermission: Permission.CAN_EDIT,
      organizationId: SCENE_PRO_ID,
    },
  },
  {
    path: 'admin/publication',
    children: [...AdminPublicationRoutes],
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'admin/users',
    children: [...AdminUserRoutes],
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      breadcrumb: [SiteConcepts.USER],
      appPermission: Permission.CAN_EDIT,
      organizationId: SCENE_PRO_ID,
    },
  },
  {
    path: 'admin/promotion',
    children: [...AdminPromotionRoutes],
    canActivate: [AuthGuard,PermissionGuard],
  },
  {
    path: 'admin/tour',
    children: [...AdminTourRoutes],
    canActivate: [AuthGuard,PermissionGuard],
    data: {
      breadcrumb: [SiteConcepts.TOUR],
      appPermission: Permission.CAN_EDIT,
    },
  },
  {
    path: 'account', // account-routing-module has been deleted, content has been moved bellow
    children: [...AccountRoutes],
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    children: [...ProfileRoutes],
    data: {
      breadcrumb: [SiteConcepts.ACCOUNT],
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'organization',
    children: [...OrganiszationRoutes],
    canActivate: [AuthGuard]
  },
  {
    path: 'programmations',
    children: [...ProgrammationRoutes],
    data: {
      breadcrumb: [SiteConcepts.PROGRAM],
    },
    canActivate: [AuthGuard]
  },

  { path: '**', redirectTo: '/dashboard' }, // Wildcard, instead of a 404, any bad url request will be redirect to the dashboard
];

@NgModule({
  imports: [
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },
    }),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule, LocalizeRouterModule],
})
export class AppRoutingModule {}
