import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { Observable } from 'rxjs/Observable';
import { MainService } from 'src/app/shared/services/main.services';
import { Globals } from '../../../_configs/globals';
import { AccountService } from '../../account/services/account.service';
import { IAddress } from '../../location/models/location.model';
import { LocationService } from '../../location/services/location.service';
import { ICreatedVenue, IVenue, Venue, VenueConfiguration } from '../model/venue.model';

@Injectable({
    providedIn: 'root'
})
export class VenueService extends MainService {
    constructor(
        protected httpClient: HttpClient,
        protected globals: Globals,
        private accountService: AccountService,
        public locationService: LocationService,
        public localizeRouter: LocalizeRouterService,
        private translate: TranslateService,
        private router: Router
    ) {
        super(httpClient, globals);
    }

    getVenue(id: number): Observable<Venue> {
        //[GET] - Gets all information about a venue
        const endpoint = this.uri + this.globals.endpoints.venue.main + '/' + id;
        return this.httpClient.get<Venue>(endpoint).map((data) => new Venue(data));
    }

    getVenueConfigurations(id: number): Observable<any> {
        //[GET] - Gets venue configurations
        const endpoint = this.uri + this.globals.endpoints.venue.getVenueConfigurationsEndpoint(id);
        return this.httpClient.get(endpoint);
    }

    getAllVenues(): Observable<Venue[]> {
        //[GET] - Gets all  venues
        return this.httpClient.get(this.uri + this.globals.endpoints.venue.all).map((data) => data['venues'].map((venue) => new Venue(venue)));
    }
    getVenues(organizationId: number): Observable<Venue[]> {
        //[GET] - Gets all  venues from specific Organization
        return this.httpClient
            .get(this.uri + this.globals.endpoints.venue.getVenuesFromOrganizationEndpoint(organizationId))
            .map((data) => data['venues'].map((venue) => new Venue(venue)));
    }

    createVenue(venue: IVenue): Observable<ICreatedVenue> {
        const endpoint: string = this.globals.endpoints.venue.main;
        return this.httpClient.post<ICreatedVenue>(this.uri + endpoint, venue);
    }

    addVenueConfigurations(venueId: number, venueConfigurations: VenueConfiguration[]): Observable<any> {
        //[POST] - Add Venue Configurations
        const endpoint = this.uri + this.globals.endpoints.venue.configuration;
        const body = {
            venueId: venueId,
            venueConfigurations: venueConfigurations
        };
        return this.httpClient.post(endpoint, body);
    }

    patchVenueConfigurations(organizationId: number, venueId: number, configurations: VenueConfiguration[]): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.venue.configurations;
        const body = {
            organizationId: organizationId,
            venueId: venueId,
            configurations: configurations
        };
        return this.httpClient.post(endpoint, body);
    }

    modifyVenue(venue: IVenue): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.venue.main;
        return this.httpClient.put<IVenue>(endpoint, venue);
    }

    modifyVenueConfiguration(id: number): Observable<any> {
        //[PUT] - Modifies a venue configuration
        const endpoint = this.uri + this.globals.endpoints.venue.getVenueConfigurationEndpoint(id);
        return this.httpClient.put(endpoint, id);
    }

    removeVenue(venueId: number, organizationId: number): Observable<any> {
        //[DELETE] - Removes a venue
        const endpoint = this.uri + this.globals.endpoints.venue.getVenueEndpoint(venueId, organizationId);
        return this.httpClient.delete(endpoint);
    }

    removeVenueConfiguration(venueId: number, confId: number): Observable<any> {
        //[DELETE] - Removes a venue configuration
        const endpoint = this.uri + this.globals.endpoints.venue.getDeleteVenueConfigurationEndpoint(venueId, confId);
        return this.httpClient.delete(endpoint);
    }

    modifyVenueApproval(approvalState): Observable<any> {
        //[PUT] Approve or refuse an venue
        this.accountService.clearUserOrgaCache();
        const endpoint = this.uri + this.globals.endpoints.venue.approval;
        return this.httpClient.put(endpoint, approvalState);
    }

    async createBlankVenueAndRedirectToForm(organizationId) {
        const venue = new Venue({});
        venue.setTranslatedProperty(this.translate.currentLang, 'name', this.translate.instant('NOUVELLE-SALLE'));

        venue.organizationId = organizationId;
        const dummyAdress = {
            address1: '',
            address2: '',
            zipCode: '',
            city: '',
            countryId: 124,
            otherState: 'Québec'
        } as IAddress;

        const address = await this.locationService.createAddress(dummyAdress);
        if (!address.isCreated) {
            console.error("Erreur lors de la création de l'adresse"); /* TODO: gestion erreur creation adresse*/
        }
        venue.addressId = address.address.id;
        console.log(venue);
        this.createVenue(venue).subscribe((createdVenue) => {
            if (!createdVenue.isCreated) {
                console.error("Erreur lors de la création de l'adresse"); /* TODO: gestion erreur creation venue*/
            }
            const venueId = createdVenue.venue.id;
            const url = this.localizeRouter.translateRoute(`/venue/${organizationId}/${venueId}/modify`);
            this.router.navigate([url]);
        });
    }
}
