export enum EProgramStatus {
  INTERNAL = 1,
  EXPLORATORY = 2,
  PROVISORY = 3,
  CONFIRMED = 4,
  CANCELED = 5,
}

export const getEProgramStatusValues = () => {
  return Object.keys(EProgramStatus)
    .filter((elt) => {
      return isNaN(Number(elt));
    })
    .map((key) => {
      return {
        name: key,
        value: EProgramStatus[key],
      };
    });
};
