import { Routes } from '@angular/router';
import { PublicationListComponent } from './components/publication-list/publication-list.component';
import { SiteConcepts } from 'src/app/shared/enums/site-concepts.enum';
import { PublicationFormComponent } from './components/publication-form/publication-form.component';

export const AdminPublicationRoutes: Routes = [
  {
    path: '',
    component: PublicationListComponent,
    data: {
      breadcrumb: [SiteConcepts.PUBLICATION],
      appPermission: 1,
      organizationId: 1,
    },
  },
  {
    path: ':publicationId/modify',
    component: PublicationFormComponent,
    data: {
      breadcrumb: [SiteConcepts.PUBLICATION, SiteConcepts.SINGLE],
    },
  },
];
