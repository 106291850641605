import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-password-hint-validator',
    templateUrl: 'password-hint-validator.component.html',
    styleUrls: ['password-hint-validator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PasswordHintValidatorComponent {
    @Input() isVisible: false;
    @Input() isValid: true;
    @Input() sentence = '';
    @Input() textColor = '';
}
