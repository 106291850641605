import { Translatable } from 'src/app/shared/model/translatable.model';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { Organization } from '../../organization/model/organization.model';
import { TourDiffuseur } from './tour-diffuseur.model';
import { PxUser } from '../../account/model/project-x-user';
import { Translation } from 'src/app/shared/model/translatable.model';

export interface ITourKeepUpdate {
  id: number;
  statusId: number;
  organizationId: number;
}

export class Tour extends Translatable {
  id: number;
  organizationId: number;
  startDate?: Date;
  endDate?: Date;
  analysisStartDate?: Date;
  analysisEndDate?: Date;
  isAvailable?: boolean;
  isRegular?: number;
  trans: Translation[];
  organization?: Organization;
  isVoteOpen: boolean;
  isDepositOpen: boolean;
  isPaid: boolean;
  isDepositDone: boolean;
  isVoteDone: boolean;
  sourceTourId: number;
  maxDateSelected: number;
  diffusers: TourDiffuseur[];
  admins: Array<{ id: number, user: PxUser }>;
  calendarId?: number;

  constructor(datas: Record<string, any>) {
    super();
    this.id = datas['id'];
    this.organizationId = datas['organizationId'];
    this.trans = datas['trans'];
    this.analysisStartDate = datas['analysisStartDate'];
    this.analysisEndDate = datas['analysisEndDate'];
    this.isAvailable = datas['isAvailable'];
    this.isRegular = datas['isRegular'];
    this.organization = datas['organization'] ? new Organization(datas['organization']) : null;

    this.startDate =
      datas['startDate'] != null ? new Date(datas['startDate']) : null;
    this.endDate = datas['endDate'] != null ? new Date(datas['endDate']) : null;

    this.isVoteOpen =
      datas['isVoteOpen'] != null ? !!datas['isVoteOpen'] : null;
    this.isDepositOpen =
      datas['isDepositOpen'] != null ? !!datas['isDepositOpen'] : null;
    this.isPaid = datas['isPaid'] != null ? !!datas['isPaid'] : null;
    this.isDepositDone =
      datas['isDepositDone'] != null ? !!datas['isDepositDone'] : null;
    this.isVoteDone =
      datas['isVoteDone'] != null ? !!datas['isVoteDone'] : null;

    this.sourceTourId = datas['sourceTourId'];
    this.maxDateSelected = datas['maxDateSelected'];
    this.isAvailable = datas['isAvailable'] != null ? !!datas['isAvailable'] : null;

    this.diffusers = datas['diffusers'] != null ? datas['diffusers'].map(data => new TourDiffuseur(data)) : null;
    this.admins = datas['admins'];
    this.calendarId = datas['calendarId'];
  }

  toJSON() {
    return {
      ...this,
      isVoteOpen: this.isVoteOpen != null ? +this.isVoteOpen : null,
      isDepositOpen: this.isDepositOpen != null ? +this.isDepositOpen : null,
      isPaid: this.isPaid != null ? +this.isPaid : null,
      isDepositDone: this.isDepositDone != null ? +this.isDepositDone : null,
      isVoteDone: this.isVoteDone != null ? +this.isVoteDone : null,
      isAvailable: this.isAvailable != null ? +this.isAvailable : null
    };
  }

  toListItem(lang: string): ListItem {
    let itemInfo1 = '';
    let itemInfo2 = '';
    let itemInfo3 = '';
    let itemInfo4 = '';

    const dateFrom = new Date(this.startDate);
    const dateTo = new Date(this.endDate);

    const dateAnalysisStartDate = new Date(this.analysisStartDate);
    const dateAnalysisEndDate = new Date(this.analysisEndDate);

    if (this.startDate == null && this.endDate == null) {
      itemInfo1 = '';
      itemInfo2 = '';
    } else {
      itemInfo1 =
        dateFrom.getDate() +
        '/' +
        (dateFrom.getMonth() + 1) +
        '/' +
        dateFrom.getFullYear();
      itemInfo2 =
        dateTo.getDate() +
        '/' +
        (dateTo.getMonth() + 1) +
        '/' +
        dateTo.getFullYear();
    }

    if (this.analysisStartDate === null && this.analysisEndDate === null) {
      itemInfo3 = '';
      itemInfo4 = '';
    } else {
      itemInfo3 =
        dateAnalysisStartDate.getDate() +
        '/' +
        (dateAnalysisStartDate.getMonth() + 1) +
        '/' +
        dateAnalysisStartDate.getFullYear();
      itemInfo4 =
        dateAnalysisEndDate.getDate() +
        '/' +
        (dateAnalysisEndDate.getMonth() + 1) +
        '/' +
        dateAnalysisEndDate.getFullYear();
    }

    return {
      itemId: this.id,
      itemTitle: this.getTranslatedProperty(lang, 'name'),
      itemSubtitle: this.organization
        ? new Organization(this.organization).getTranslatedProperty(
            lang,
            'name'
          )
        : '',
      itemUrl: '',
      itemInfo1: itemInfo1,
      itemInfo2: itemInfo2,
      itemInfo3: itemInfo3,
      itemInfo4: itemInfo4,
    } as ListItem;
  }
}
