
<div class="DynamicSearchBar" appClickOutsideElement  (onClickOutside)='showResult = false' >
  <nz-input-group   [nzPrefix]="suffixIconSearch" class="DynamicSearchBar_input" (click)='showResult = true' >
    <input type="text" nz-input [placeholder]="placeholder | translate" (input)='onChange($event.target.value)' />
  </nz-input-group>
  <div class="DynamicSearchBar_result"  *ngIf="showResult">
    <ng-content select="[result]"  ></ng-content>
  </div>

</div>





<ng-template #suffixIconSearch>
  <i class="icon-search"></i>
</ng-template>
