import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainService } from 'src/app/shared/services/main.services';
import { AuthService } from '../../account/services/auth/auth.service';
import { Globals } from 'src/app/_configs/globals';
import { CartProduct } from './../../payment/models/cart-product.model';
import { ProductTypes } from './../../product/enums/product-types-enum';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService extends MainService {
    public shoppingCartChanged = new EventEmitter();

    constructor(private accountService: AccountService, private authService: AuthService, protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    getCart(): Observable<any> {
        const organizationId = this.accountService.getCurrentCtxOrganizationId();
        let endpoint: string = '';
        if (organizationId !== -1) {
            endpoint = this.globals.endpoints.cart.getCartEndpoint(organizationId);
        } else {
            endpoint = this.globals.endpoints.cart.getUserCartEndpoint(this.authService.User.id);
        }
        return this.httpClient.get<any>(this.uri + endpoint);
    }

    removeProductFromCart(productId): Observable<any> {
        const endpoint: string = this.globals.endpoints.cart.getCartProductEndpoint(productId);
        return this.httpClient.delete<any>(this.uri + endpoint).do(() => this.shoppingCartChanged.emit('removed'));
    }

    addToCart(cart): Observable<any> {
        const endpoint: string = this.globals.endpoints.cart.cartProduct;
        return this.httpClient.post(this.uri + endpoint, cart).do(() => this.shoppingCartChanged.emit('added'));
    }

    isItemInCart(itemId: number, itemType: ProductTypes): Observable<boolean> {
        return this.getCart().map((cart) => {
            const cartProducts: CartProduct[] = cart['products'].map((cartProduct) => new CartProduct(cartProduct));
            const res = cartProducts.find((cartProd) => cartProd.itemId === itemId && cartProd.productTypeId === itemType);
            return !!res;
        });
    }

    checkout(paiementInfos: any): Observable<Object> {
        const endpoint: string = this.globals.endpoints.transaction.checkout;
        return this.httpClient.post(this.uri + endpoint, paiementInfos).do(() => this.shoppingCartChanged.emit('added'));
    }

    refund(refundInfo: any): Observable<Object> {
        const endpoint = this.uri + this.globals.endpoints.transaction.refund;
        return this.httpClient.post(endpoint, refundInfo);
    }

    updateCard(type: string): void {
        this.shoppingCartChanged.emit(type);
    }
}
