import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { Permission } from '../../../../shared/enums/roles.enum';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { Globals } from '../../../../_configs/globals';
import { AccountService } from '../../../account/services/account.service';
import { ChooseActivitiesComponent } from '../../../activities/components/choose-activities/choose-activities.component';
import { OrganizationTypes } from '../../../organization/enums/organization-types.enum';
import { Organization } from '../../../organization/model/organization.model';
import { OrganizationService } from '../../../organization/services/organization.service';
import { ProductTypes } from '../../../product/enums/product-types-enum';
import { Product } from '../../../product/model/product.model';
import { ProductService } from '../../../product/services/product.service';
import { Meeting } from '../../model/meeting.model';
import { ParticipantMeeting } from '../../model/participant.model';
import { MeetingService } from '../../services/meeting.service';
import { ChooseStandModalComponent } from './../../../stand/components/choose-stand-modal/choose-stand-modal.component';
import { IStandResponse, Stand } from './../../../stand/model/stand.model';
import { StandService } from './../../../stand/services/stand.service';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { VitrineService } from '@app/concepts/vitrine/services/vitrine.service';
import { ListItem } from '@app/shared/model/list-item.model';
import { DateRangeChecker } from '@app/concepts/program/date-utilities';

@Component({
    selector: 'app-meeting-single',
    templateUrl: './meeting-single.component.html',
    styleUrls: ['./meeting-single.component.scss']
})
export class MeetingSingleComponent implements OnInit, OnDestroy {
    public readonly isCurrentOrganizationApproved$: Observable<boolean> = this.accountService.isCurrentOrgaApprouved();

    public CAN_EDIT = Permission.CAN_EDIT;
    public PRODUCTEUR = OrganizationTypes.IS_PRODUCTEUR;
    public activeTab = '';
    public activityListSize: number;
    public canChooseStand = false;
    public currentLang = this.translate.currentLang;
    public currentOrgparticipants: ParticipantMeeting[] = [];
    public currentUser: PxUser;
    public currentUserOrg: Organization;
    public currentUserOrgId: number;
    public defaultImg: string;
    public editMeetingLink = '';
    public isJuryMember = false;
    public isOpenDateActivityChoice = false;
    public isOpenDateDepotVitrine = false;
    public isOpenDateSubscription = true;
    public isReady = false;
    public meeting: Meeting;
    public meetingId: number;
    public meetingReady = false;
    public onlineUserId: number;
    public organization: Organization;
    public organizationLink = '';
    selectVitrinesByOrganizationId$: Observable<ListItem[]>;
    public selectedStand: Stand;
    public standList: Stand[];
    public standListSize: number;
    public vitrineListSize: number;
    public vitrineProducts = [];

    private subscription: Subscription;
    private destroyed: Subject<void> = new Subject();
    isActivityOptional: boolean = false;
    constructor(
        private globals: Globals,
        private route: ActivatedRoute,
        private authService: AuthService,
        private standService: StandService,
        private translate: TranslateService,
        private meetingService: MeetingService,
        private accountService: AccountService,
        private productService: ProductService,
        private breadcrumbService: BreadcrumbService,
        private organizationService: OrganizationService,
        private modalService: NzModalService,
        private notification: RideauNotificationService,
        private vitrineService: VitrineService
    ) {}

    public get hasMeetingProducts(): boolean {
        return Boolean(this.meeting.hasProducts);
    }

    public chooseActivities(): void {
        this.modalService.create({
            nzTitle: this.translate.instant('CHOISIR-ACTIVITES'),
            nzContent: ChooseActivitiesComponent,
            nzWidth: '60vw',
            nzComponentParams: {
                participants: this.currentOrgparticipants,
                meetingId: this.meetingId,
                meetingTitle: this.meeting.title,
                organization: this.currentUserOrg,
                isActivityChoiceEnabled: this.meeting.isActivityChoiceOpened()
            },
            // Le callBack OnOk renvoie l'instance du composant
            nzOnOk: (res: ChooseActivitiesComponent) => {
                res.onValidate().subscribe((data) => {
                    if (data.failures && data.failures.length > 0) {
                        this.chooseActivities();
                        this.notification.error(this.translate.instant(this.translate.instant('ERRORS.ACTIVITY-AVAILABILITY-CHANGED')));
                    } else {
                        this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
                    }
                });
            }
        });
    }

    public chooseStand(): void {
        this.modalService.create({
            nzTitle: this.translate.instant('CHOISIR-STAND'),
            nzContent: ChooseStandModalComponent,
            nzWidth: '600px',
            nzOkText: this.translate.instant('CHOISIR-CE-STAND'),
            nzComponentParams: {
                meeting: this.meeting,
                organization: this.currentUserOrg,
                standList: this.standList,
                selectedStand: this.selectedStand
            },
            // Le callBack OnOk renvoie l'instance du composant
            nzOnOk: (res: ChooseStandModalComponent) =>
                new Promise(async (resolve, reject) => {
                    await res
                        .onValidate()
                        .then((isUpdated) => {
                            this.getStandInfos(true).subscribe();
                            resolve(isUpdated);
                        })
                        .catch(() => {
                            this.selectedStand = res.selectedStandCopy;
                            reject(false);
                        });
                }),
            nzOnCancel: (res: ChooseStandModalComponent) => {
                this.selectedStand = res.savedForm ? res.selectedStand : res.selectedStandCopy;
            }
        });
    }

    public getCurrentOrgParticipants(meetingId: number): Observable<any> {
        const IS_PAID = true;
        const WITH_ACTIVITIES = true;

        return this.meetingService.getAllMeetingParticipantsForCurrentOrg(meetingId, IS_PAID, WITH_ACTIVITIES).do((data: ParticipantMeeting[]) => {
            this.currentOrgparticipants = data;
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroyed.next();
        this.destroyed.complete();
    }

    public ngOnInit(): void {
        this.activeTab = 'TAB_ACTIVITIES';
        this.onlineUserId = parseInt(this.authService.User.id);
        this.meetingId = this.route.snapshot.params['rencontreId'];
        this.editMeetingLink = `/pro-meeting/${this.meetingId}/modify`;
        this.defaultImg = this.globals.imgFallback;

        forkJoin([this.getMeetingInfos(), this.getVitrineProductsForMeeting(), this.getStandInfos(), this.getCurrentOrgParticipants(this.meetingId)]).subscribe(() => {
            this.setCurrentOrganization();
            this.setCanChooseStand();
            this.currentUser = this.authService.User;
        });

        this.subscription = this.accountService.currentOrganizationChange.subscribe(() => {
            this.setCurrentOrganization();
            this.getCurrentOrgParticipants(this.meetingId).subscribe();
        });
        this.selectVitrinesByOrganizationId$ = this.vitrineService.selectVitrinesByOrganizationId(
            this.accountService.getCurrentCtxOrganizationId(),
            this.currentLang,
            this.meetingId
        );
    }

    public productListToString(products: Product[]): string {
        return products.map((product) => product.getTranslatedProperty(this.currentLang, 'name')).join(', ');
    }

    public seeActivities(): void {
        this.modalService.create({
            nzTitle: this.translate.instant('VOIR-ACTIVITES'),
            nzFooter: null,
            nzContent: ChooseActivitiesComponent,
            nzWidth: '60vw',
            nzComponentParams: {
                participants: this.currentOrgparticipants,
                meetingId: this.meetingId,
                organization: this.currentUserOrg,
                isActivityChoiceEnabled: this.meeting.isActivityChoiceOpened()
            }
        });
    }

    public toggleMeetingContent(tab: string): void {
        this.activeTab = tab;
    }

    private getMeetingInfos(): Observable<any> {
        return this.meetingService.getMeetingById(this.meetingId).do((data) => {
            this.meeting = new Meeting(data);

            // Détermine si le user connecté est membre du jury
            this.isJuryMember = this.meeting.juries.map((x) => x.userId).indexOf(this.onlineUserId) != -1;

            // Set BreadcrumbItem
            const breadcrumbItem = new BreadcrumbItem();
            breadcrumbItem.set(this.meeting.getTranslatedProperty(this.currentLang, 'name'));
            this.breadcrumbService.addBreadcrumbCascade([breadcrumbItem], true);

            this.meeting.toSingleMeeting(this.currentLang);
            if (this.meeting.organizationId) {
                this.getMeetingOrganizer(this.meeting.organizationId);
            } else {
                this.organization = null;
            }
            this.isActivityOptional = this.meeting.isActivityOptional === 1;
            this.isOpenDateSubscription = DateRangeChecker.checkInRangeDate(this.meeting.subscriptionDateFrom, this.meeting.subscriptionDateTo);
            this.isOpenDateActivityChoice = DateRangeChecker.checkInRangeDate(this.meeting.activityDateFrom, this.meeting.activityDateTo);
            this.isOpenDateDepotVitrine = !this.meeting.proposalVitrineDateTo
                ? false
                : DateRangeChecker.checkInRangeDate(this.meeting.proposalVitrineDateFrom, this.meeting.proposalVitrineDateTo, this.isOpenDateDepotVitrine);

            this.setCurrentOrganization();
            this.getCurrentOrgParticipants(this.meetingId);

            this.meetingReady = true;
        });
    }

    private getMeetingOrganizer(organizationId: number): void {
        this.organizationService.getOrganization(organizationId).subscribe((data: Organization) => {
            this.organization = data;
            this.organizationLink = `/organization/${organizationId}`;
            this.isReady = true;
        });
    }

    private getStandInfos(setup?: boolean): Observable<IStandResponse> {
        return this.standService.getStands(this.meetingId).do(({ stands }: IStandResponse) => {
            const updatedStandList = stands.map((stand) => new Stand(stand));
            this.standList = setup ? this.setupStandList(updatedStandList) : updatedStandList;
            this.selectedStand = this.standList.find((stand) => stand.organizationId === this.currentUserOrgId) || null;
        });
    }

    private setupStandList(list: Stand[], organization?: Organization) {
        return list.map((stand: Stand) => {
            const isOccupied = !!stand.organizationId;
            const _organization = this.organization || organization;
            const isAllowedForOrgType =
                stand.organizationTypeId === OrganizationTypes.VACANT || stand.organizationTypeId === null || _organization.types.includes(stand.organizationTypeId);
            if (isOccupied || !isAllowedForOrgType) {
                stand.isDisabled = true;
            }
            return stand;
        });
    }

    private getVitrineProductsForMeeting(): Observable<any> {
        const filters = [
            {
                field: 'meetingId',
                value: this.meetingId
            },
            {
                field: 'productTypeId',
                value: ProductTypes.DEPOT_VITRINE
            }
        ];
        return this.productService.getProducts(filters).do((products: Product[]) => (this.vitrineProducts = products));
    }

    /**
     * This function sets the flag to allow (or not) to pick a stand.
     * the current date should be between dates set on the meeting, and current organisation
     * must have a least 1 participant with a product that allows to pick a stand.
     */
    private setCanChooseStand(): void {
        // if user with no organisation -> no stands
        if (this.currentUserOrgId === this.globals.NO_ORGA) {
            this.canChooseStand = false;
            return;
        }

        // if meeting specify to not allow stand allocations
        if (!this.meeting.isStandOptional) {
            this.canChooseStand = false;
            return;
        }

        this.canChooseStand = true;
        const today = new Date();
        const dateFrom = new Date(this.meeting.standDateFrom);
        const dateTo = new Date(this.meeting.standDateTo);
        dateTo.setHours(23, 59, 59, 999); // Mettre la date à la dernière milliseconde du jour

        // Si dateFrom et DateTo sont définis, on vérifie que today est entre les deux.
        if (this.meeting.standDateFrom && this.meeting.standDateTo) {
            this.canChooseStand = today >= dateFrom && today <= dateTo;
        }
        // Si seul dateFrom est défini, on vérifie que today est après.
        else if (this.meeting.standDateFrom && !this.meeting.standDateTo) {
            this.canChooseStand = today >= dateFrom;
        }
        // Si seul dateTo est défini, on vérifie que today est avant.
        else if (!this.meeting.standDateFrom && this.meeting.standDateTo) {
            this.canChooseStand = today <= dateTo;
        }

        // si on est dans les dates, on vérifie la présence d'un produit autorisant le choix de stand
        if (this.canChooseStand) {
            const allParticipantsProducts: Product[] = this.currentOrgparticipants.reduce((acc, current) => {
                return acc.concat(current.products);
            }, []);

            const found = allParticipantsProducts.find((product) => !!product.isStandOptional);
            this.canChooseStand = !!found;
        }
    }

    private setCurrentOrganization(): void {
        this.currentUserOrgId = this.accountService.getCurrentCtxOrganizationId();
        this.organizationService.getOrganization(this.currentUserOrgId).subscribe((data: Organization) => {
            this.currentUserOrg = data;
            if (this.standList) {
                this.standList = this.setupStandList(
                    this.standList.map((stand) => new Stand(stand)),
                    this.currentUserOrg
                );
                this.selectedStand = this.standList.find((stand) => stand.organizationId === this.currentUserOrgId);
            }
        });
    }
}
