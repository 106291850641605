import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RowTypes } from '../../../../../shared/enums/row-types.enum';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { VitrineStatus } from '../../../../vitrine/enums/vitrine-status.enum';
import { Vitrine } from '../../../../vitrine/model/vitrine.model';
import { VitrineService } from '../../../../vitrine/services/vitrine.service';
import { Activity } from '../../../model/activity.model';
import { ActivityService } from '../../../services/activity.service';
import { ActivityVitrine } from './../../../../vitrine/model/vitrine.model';

@Component({
  selector: 'app-activity-vitrines',
  templateUrl: './activity-vitrines.component.html',
  styleUrls: ['./activity-vitrines.component.scss'],
})
export class ActivityVitrinesComponent implements OnInit {
  @Input()
  activity: Activity;

  editFormStates: any = [];
  meetingVitrineList: Vitrine[] = [];
  activityVitrineList: any = [];
  collapseCreateSection = false;

  itemType = RowTypes.ACTIVITYVITRINE;

  lang = this.translate.currentLang;

  constructor(
    public translate: TranslateService,
    private activityService: ActivityService,
    private vitrineService: VitrineService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    this.getMeetingVitrines();
    this.getActivityVitrines();
  }

  getMeetingVitrines(): void {
    const filters = [
      { field: 'isPaid', value: 1 },
      { field: 'statusId', value: VitrineStatus.APPROUVE },
    ];

    this.vitrineService
      .getVitrineForMeeting(this.activity.meetingId, filters)
      .subscribe((data) => {
        // console.log(data)
        this.meetingVitrineList = data;
      });
  }

  getActivityVitrines(): void {
    this.activityService
      .getActivityVitrines(this.activity.id)
      .subscribe((data) => {
        // console.log(data);
        this.activityVitrineList = data['activity_vitrines'];
        this.activityVitrineList = this.activityVitrineList.map(
          (activityVitrine) => {
            const vitrine = new ActivityVitrine(activityVitrine);
            return {
              vitrine: vitrine,
              listItem: vitrine.toListItem(this.lang),
            };
          }
        );
        this.toggleHandler();
      });
  }

  toggleHandler(): void {
    this.editFormStates = this.activityVitrineList.map((activityVitrine) => {
      return { key: activityVitrine.vitrine.id, state: false };
    });
  }

  saveNewVitrine(vitrine): void {
    // Show Edit Form
    vitrine['activityId'] = this.activity.id;
    // console.log(vitrine);
    this.activityService.addVitrineToActivity(vitrine).subscribe((data) => {
      this.notification.success(this.translate.instant('VITRINE-AJOUTEE'));
      this.getActivityVitrines();
      this.collapseCreateSection = false;
    });
  }

  updateActivityVitrine(vitrine): void {
    // Update item
    // console.log(vitrine);
    const obj = {
      vitrineTypeId: vitrine.vitrineTypeId,
      duration: vitrine.duration,
    };
    this.activityService
      .updateActivityVitrine(vitrine.id, obj)
      .subscribe((data) => {
        this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
        this.getActivityVitrines();
        this.collapseCreateSection = false;
      });
  }

  showForm(vitrineId): unknown {
    // Show Edit Form
    const item = this.editFormStates.find((item) => item.key == vitrineId);
    return item.state;
  }

  addVitrine(): void {
    this.toggleVitrineForm();
    this.collapseCreateSection = true;
  }

  toggleVitrineForm(vitrineId?: number): void {
    // Toggle state
    this.toggleHandler();
    this.collapseCreateSection = false;
    if (vitrineId) {
      // console.log(vitrineId);
      const index = this.editFormStates.findIndex(
        (item) => item.key == vitrineId
      );
      if (index >= 0)
        this.editFormStates[index].state = !this.editFormStates[index].state;
    }
  }

  removeItem(vitrineId): void {
    // Remove item
    this.activityService
      .removeVitrineFromActivity(vitrineId)
      .subscribe((data) => {
        this.notification.success(this.translate.instant('VITRINE-SUPPRIMEE'));
        this.getActivityVitrines();
      });
  }
}
