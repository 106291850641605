<section>
    <div nz-row nzGutter="24">
        <div nz-col>
            <ng-container [ngTemplateOutlet]="isLoading ? loaderTpl : listTpl"></ng-container>
        </div>
    </div>
    <div nz-row nzGutter="24" class="d-flex justify-content-center">
        <div nz-col>
            <!-- PAGINATION -->
            <ng-container *ngIf="usePagination && pagination">
                <nz-pagination
                    [nzPageIndex]="pagination.getPage()"
                    [nzTotal]="pagination.getTotal()"
                    [nzPageSize]="pagination.limit"
                    [nzItemRender]="renderItemTemplate"
                    (nzPageIndexChange)="onPageChange($event)"
                ></nz-pagination>
                <ng-template #renderItemTemplate let-type let-page="page">
                    <a class="controls {{ pagination.getPage() == 1 ? 'disabled' : '' }}" *ngIf="type === 'pre'">
                        <i style="padding-right: 5px" nz-icon aria-hidden="true" nzType="left" nzTheme="outline"></i>
                        {{ 'PRECEDENT' | translate }}
                    </a>
                    <a class="controls" *ngIf="type === 'next'">
                        {{ 'SUIVANT' | translate }}
                        <i style="padding-left: 5px" nz-icon aria-hidden="true" nzType="right" nzTheme="outline"></i>
                    </a>
                    <a *ngIf="type === 'page'">{{ page }}</a>
                </ng-template>
            </ng-container>
        </div>
    </div>
</section>
<ng-template #listTpl>
    <ng-content select="[list]"></ng-content>
</ng-template>
<ng-template #loaderTpl>
    <div class="loader">
        <i nz-icon aria-hidden="true" nzType="loading" nzTheme="outline"></i>
    </div>
</ng-template>
