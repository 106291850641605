
    <nz-form-item>
      <label [attr.for]="id">
        <ng-content></ng-content>
      </label>
      <nz-form-control>
        <nz-radio-group
          class="d-flex"
          [attr.id]="id"
          [(ngModel)]="selected"
          (ngModelChange)="ngModelChange()"
        >
          <label nz-radio-button *ngFor="let key of keys" [nzValue]="key" [nzDisabled]="disabled">{{
            translateKeyPrefix + Options[key] | translate
          }}</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  