import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import {
  Filter,
  ListItem,
  Pagination,
  SelectedFilter,
  Sort,
} from './../../model/list-item.model';
import { Globals } from '../../../_configs/globals';
import { RowTypes } from '../../enums/row-types.enum';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent implements OnInit {
  /**
   * filtres
   */
  @Input()
  filterList: Filter[];
  /**
   * Tris
   */
  @Input()
  sortList: Sort[];
  /**
   * Config de la pagination
   */
  @Input()
  pagination: Pagination;
  /**
   * List d'elements à afficher
   */
  @Input()
  itemList: ListItem[] = [];
  /**
   * Flag si en cours de chargement
   */
  @Input()
  isLoading: boolean;
  /**
   * Flag si on cache le header
   */
  @Input()
  hideHeader: boolean;
  /**
   * Flag pour le type d'élément affichés
   */
  @Input()
  listItemsType: RowTypes;
  /**
   * Flag pour l'utilisation des filtres
   */
  @Input()
  useFilters: boolean;
  /**
   * Flag pour l'utilisation du switch
   */
  @Input()
  useViewSwitcher: boolean;
  /**
   * Flag pour l'utilisation de la recherche
   */
  @Input()
  useSearchBar: boolean;
  @Input()
  searchBarPlaceHolder: string;

  /**
   * Flag pour l'utilisation du tri
   */
  @Input()
  useSorting: boolean;
  /**
   * Flag pour l'utilisation de la pagination
   */
  @Input()
  usePagination: boolean;
  /**
   * Flag pour l'utilisation du bouton Modifier
   */
  @Input()
  enableEditing: boolean;

  /**
   * Flag pour l'utilisation du bouton Modifier
   */
  @Input()
  enableRemoving: boolean;

  @Input()
  isProductList: boolean; //If it's Product List, different display only showing row display

  @Input()
  elementsLabel?: string;

  @Input()
  isToRate?: boolean;

  // Flag to download favorites OdS
  @Input()
  isDownloadFavorites?: boolean;

  @Input() isAdmin?: boolean;

  @Input()
  updateItemRate = new EventEmitter<SelectedFilter>();
  @Input() searchSessionStorageSuffix?:string;

  @Output()
  filterChange = new EventEmitter<SelectedFilter>();

  @Output()
  modifyItems = new EventEmitter<any>();

  @Output()
  removeItems = new EventEmitter<any>();

  @Output()
  rateItem = new EventEmitter<any>();

  @Output()
  updateKeepedShow = new EventEmitter<any>();

  @Output()
  sortChange = new EventEmitter<any>();

  @Output()
  searchChange = new EventEmitter<string>();

  @Output()
  pageChange = new EventEmitter<Pagination>();

  @Output()
  exportFavorites? = new EventEmitter<any>();

  // Flag List / Cards
  isListViewRow = false;
  checkedCollapse = false;
  searchFormControl = new FormControl();

  // just need for ngModel & compiling purposes
  sortModel: any = null;
  selectedFilter: string;

  // List Types
  activityList = RowTypes.ACTIVITY;
  vitrineList = RowTypes.VITRINE;
  productList = RowTypes.PRODUCT;
  showsList = RowTypes.SHOW;
  bottinList = RowTypes.BOTTIN;
  participantList = RowTypes.CONTACT;

  /**
   *  Réference sur le composant de recherche avancée, si il existe
   *  Pour rajouter un composant de recherche avancée, le mettre à
   * l'interieur des balise avec la réference #advancedSearch. Ex :
   *  <app-item-list>
   *    <app-rech-avancee #advancedSearch advanced-search></app-rech-avancee>
   *  </app-item-list>
   * */
  @ContentChild('advancedSearch', { static: true })
  advancedSearchComponent: any;

  constructor(
    private translate: TranslateService,
    protected globals: Globals
  ) {}

  ngOnInit(): void {
    this.searchFormControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((searchVal) => {
        // On remet la pagination à zero
        this.pagination.offset = 0;
        this.searchChange.emit(searchVal);
        if (this.searchSessionStorageSuffix) {
          sessionStorage.setItem(`recherche_liste_${ this.searchSessionStorageSuffix }`, JSON.stringify(searchVal));
        }
      });

    this.elementsLabel =
      this.elementsLabel || this.translate.instant('vitrines');

    if (this.useViewSwitcher) {
      this.listItemsType == RowTypes.PRODUCT
        ? (this.isListViewRow = true)
        : (this.isListViewRow = false); //Show Row view if it's Product List
    } else {
      this.isListViewRow = true;
    }

    if (this.searchSessionStorageSuffix && JSON.parse(sessionStorage.getItem(`recherche_liste_${ this.searchSessionStorageSuffix }`))) {
      this.searchFormControl.setValue(JSON.parse(sessionStorage.getItem(`recherche_liste_${ this.searchSessionStorageSuffix }`)));
    }

    this.checkedCollapse = !!(this.searchSessionStorageSuffix && JSON.parse(sessionStorage.getItem(`recherche_avancee_collapse_${ this.searchSessionStorageSuffix }`)));
  
    if (this.searchSessionStorageSuffix && (JSON.parse(sessionStorage.getItem(`recherche_liste_sort_${ this.searchSessionStorageSuffix }`)) != null)) {
      this.sortModel = JSON.parse(sessionStorage.getItem(`recherche_liste_sort_${ this.searchSessionStorageSuffix }`));
      this.sortChange.emit(this.sortModel);
    }
    if (this.searchSessionStorageSuffix && JSON.parse(sessionStorage.getItem(`recherche_liste_view_${ this.searchSessionStorageSuffix }`))) {
      this.isListViewRow = JSON.parse(sessionStorage.getItem(`recherche_liste_view_${ this.searchSessionStorageSuffix }`));
    }
  }

  changeListView() {
    this.isListViewRow = !this.isListViewRow;
    sessionStorage.setItem(`recherche_liste_view_${ this.searchSessionStorageSuffix }`, JSON.stringify(this.isListViewRow));
  }

  onPageChange(page: number) {
    const paginate = new Pagination();
    paginate.limit = this.pagination.limit;
    paginate.offset = paginate.limit * (page - 1);
    this.pageChange.emit(paginate);
  }

  onSelectFilter(
    filterField: string,
    filterValue: any,
    filterValueName?: string
  ) {
    // hack pourri pour que seul le filtre selectionné soit en bleu.
    for (const filter of this.filterList) {
      if (filter.filterField != filterField) {
        for (const filterVal of filter.filterValues) {
          filterVal.selected = false;
        }
      }
    }

    this.selectedFilter = filterValueName ? filterValueName : '';

    // On remet aussi la pagination à zero
    this.pagination.offset = 0;
    this.filterChange.emit({ field: filterField, value: filterValue });
  }

  modifyItemCallback($event) {
    this.modifyItems.emit($event);
  }

  removeItemCallback($event) {
    this.removeItems.emit($event);
  }

  onRateItemCallback($event) {
    this.rateItem.emit($event);
  }

  onUpdateKeepedShowCallback($event) {
    this.updateKeepedShow.emit($event);
  }

  rechercheAvancee() {
    this.checkedCollapse = !this.checkedCollapse
    if (this.searchSessionStorageSuffix) {
      sessionStorage.setItem(`recherche_avancee_collapse_${ this.searchSessionStorageSuffix }`, JSON.stringify(this.checkedCollapse));
    }
  }

  onSortChange($event: unknown) {
    this.sortChange.emit($event);
    if (this.searchSessionStorageSuffix) {
      sessionStorage.setItem(`recherche_liste_sort_${ this.searchSessionStorageSuffix }`, JSON.stringify($event));
    }
  }
}
