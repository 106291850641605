import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { Globals } from '../../../../../_configs/globals';
import { AccountService } from '../../../../account/services/account.service';
import { Organization } from '../../../../organization/model/organization.model';
import { OrganizationService } from '../../../../organization/services/organization.service';
import { OrganisationStatus } from '../../../../organization/enums/organization-status.enum';
import { Meeting } from '../../../model/meeting.model';
import { MeetingService } from '../../../services/meeting.service';
import { ImgUploadService, ImgUploadState, ImgUploadStatus } from '@app/shared/components/img-upload/img-upload.service';
import { ObjectUtils } from '@app/shared/utils/object.utils';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-meeting-general-form',
    templateUrl: './meeting-general-form.component.html',
    styleUrls: [ './meeting-general-form.component.scss' ]
})
export class MeetingGeneralFormComponent implements OnInit {
    @Input() meeting: Meeting;

    isReady = false;
    currentLang = this.translate.currentLang;

    meetingId: number;
    formGroup: FormGroup;

    //logo
    logoUrl: string;
    uploadAction: string;
    loading = false;

    isActivityOptional: boolean;
    isStandOptional: boolean;
    organizationList: Organization[] = [];

    public uri: string = environment.BACKEND;

    descriptionModules;
    descriptionModel = '';
    public readonly imageConfig = this.global.images;
    constructor(
        private global: Globals,
        private fb: FormBuilder,
        private translate: TranslateService,
        private organizationService: OrganizationService,
        private notification: RideauNotificationService,
        private meetingService: MeetingService,
        private route: ActivatedRoute,
        public accountService: AccountService,
        private imgUploadService: ImgUploadService
    ) { }

    ngOnInit(): void {
        this.uploadAction = this.uri + 'upload/meeting/main';
        this.meetingId = this.route.snapshot.params[ 'rencontreId' ];
        this.getOrganizations();
        this.initForm();
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            name: [ this.meeting.getTranslatedProperty(this.currentLang, 'name') ],
            image: [ this.meeting.image ],
            place: [ this.meeting.place ],
            website: [ this.meeting.website ],
            isPublished: [ !!this.meeting.isPublished ],
            isDatePeriod: [ !!this.meeting.isDatePeriod ],
            dateFrom: [ this.meeting.dateFrom ],
            dateTo: [ this.meeting.dateTo ],
            subscriptionDateFrom: [ this.meeting.subscriptionDateFrom ],
            subscriptionDateTo: [ this.meeting.subscriptionDateTo ],
            proposalVitrineDateFrom: [ this.meeting.proposalVitrineDateFrom ],
            proposalVitrineDateTo: [ this.meeting.proposalVitrineDateTo ],
            organizationId: [ this.meeting.organizationId ],
            isActivityOptional: [ !!this.meeting.isActivityOptional ],
            activityDateFrom: [ this.meeting.activityDateFrom ],
            activityDateTo: [ this.meeting.activityDateTo ],
            isStandOptional: [ !!this.meeting.isStandOptional ],
            standDateFrom: [ this.meeting.standDateFrom ],
            standDateTo: [ this.meeting.standDateTo ]
        });
        this.descriptionModules = this.global.InputRichTextModules;
        this.descriptionModel = this.meeting.getTranslatedProperty(this.currentLang, 'description');
        this.isReady = true;
        this.imgCurrentStatusHandler();
    }

    getOrganizations(): void {
        this.organizationService.getOrganizations([ { field: 'statusId', value: OrganisationStatus.APPROUVE } ], null, null).subscribe((data) => {
            this.organizationList = data;
        });
    }

    /*Meeting Image*/
    onUploadFileCallback(url: string): void {
        this.formGroup.patchValue({
            image: url
        });
    }

    // Update meeting object
    extractFormData(): void {
        if (this.formGroup.value.image) {
            this.meeting.image = this.formGroup.value.image;
        }
        if (this.formGroup.value.website) {
            this.meeting.website = this.formGroup.value.website;
        }
        if (this.formGroup.value.dateFrom) {
            this.meeting.dateFrom = this.formGroup.value.dateFrom;
        }
        if (this.formGroup.value.place) {
            this.meeting.place = this.formGroup.value.place;
        }
        if (this.formGroup.value.organizationId) {
            this.meeting.organizationId = this.formGroup.value.organizationId;
        }
        if (this.formGroup.value.isDatePeriod) {
            this.meeting.dateTo = this.formGroup.value.dateTo;
        }

        this.meeting.standDateFrom = this.formGroup.value.standDateFrom;
        this.meeting.standDateTo = this.formGroup.value.standDateTo;

        this.meeting.activityDateFrom = this.formGroup.value.activityDateFrom;
        this.meeting.activityDateTo = this.formGroup.value.activityDateTo;

        this.meeting.subscriptionDateFrom = this.formGroup.value.subscriptionDateFrom;
        this.meeting.subscriptionDateTo = this.formGroup.value.subscriptionDateTo;

        this.meeting.proposalVitrineDateFrom = moment.tz(moment(this.formGroup.value.proposalVitrineDateFrom).format('YYYY-MM-DD'), 'America/Toronto').set({ hour: 0, minute: 1, second: 0, millisecond: 0 }).toDate();
        this.meeting.proposalVitrineDateTo = moment.tz(moment(this.formGroup.value.proposalVitrineDateTo).format('YYYY-MM-DD'), 'America/Toronto').set({ hour: 0, minute: 1, second: 0, millisecond: 0 }).toDate();

        this.meeting.isPublished = this.formGroup.value.isPublished ? 1 : 0;
        this.meeting.isDatePeriod = this.formGroup.value.isDatePeriod ? 1 : 0;
        this.meeting.isStandOptional = this.formGroup.value.isStandOptional ? 1 : 0;
        this.meeting.isActivityOptional = this.formGroup.value.isActivityOptional ? 1 : 0;
        this.meeting.setTranslatedProperty(this.currentLang, 'name', this.formGroup.value.name);
        this.meeting.setTranslatedProperty(this.currentLang, 'description', this.descriptionModel);
    }

    doSubmit(): void {
        this.extractFormData();
        ObjectUtils.cleanUpNullValues(this.meeting);
        this.meetingService.modifyMeeting(this.meeting).subscribe((data) => {
            this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
        });
    }

    limitDescriptionLength($event) {
        if ($event.editor.getLength() > this.global.MAXDESCRIPTIONLENGHT) {
            $event.editor.deleteText(this.global.MAXDESCRIPTIONLENGHT, $event.editor.getLength());
        }
    }

    private imgCurrentStatusHandler = () => {
        this.imgUploadService.currentStatus$.pipe(filter((x) => x.status !== ImgUploadStatus.INIT)).subscribe((state: ImgUploadState) => {
            switch (state.status) {
                case ImgUploadStatus.UPLOADING:
                    this.loading = true;
                    this.formGroup.get('image').setErrors({ required: true });
                    break;
                case ImgUploadStatus.DELETED:
                    this.loading = false;
                    this.formGroup.patchValue({ image: null });
                    break;
                case ImgUploadStatus.SUCCESS:
                case ImgUploadStatus.ERROR:
                    this.loading = false;
                    this.formGroup.get('image').setErrors(null);
                    break;
            }
            this.formGroup.get('image').updateValueAndValidity();
        });
    };
}
