export enum TourEquipage {
  DIRECTEUR_TOURNEE = 1,
  SONORISATEUR_ARTISTE = 2,
  ECLAIRAGISTE_ARTISTE = 3,
  EQUIPEMENT_SONORE = 4,
  EQUIPEMENT_ECLAIRAGE = 5,
  PREMIERE_PARTIE = 6,
}
export function GetEquipageById(id: number): string {
  switch (id) {
    case TourEquipage.DIRECTEUR_TOURNEE:
      return 'TOURS.DIRECTEUR-TOURNEE';

    case TourEquipage.SONORISATEUR_ARTISTE:
      return 'TOURS.SONORISATEUR-ARTISTE';

    case TourEquipage.ECLAIRAGISTE_ARTISTE:
      return 'TOURS.ECLAIRAGISTE-ARTISTE';

    case TourEquipage.EQUIPEMENT_SONORE:
      return 'TOURS.EQUIPEMENT-SONORE';

    case TourEquipage.EQUIPEMENT_ECLAIRAGE:
      return 'TOURS.EQUIPEMENT-ECLAIRAGE';

    case TourEquipage.PREMIERE_PARTIE:
      return 'TOURS.PREMIERE-PARTIE';
  }
}
