import { CountryState } from '@app/concepts/organization/model/state.model';
import { Translation } from 'src/app/shared/model/translatable.model';

export interface Country {
    id?: number;
    alpha2?: string;
    alpha3?: string;
    trans: Translation[];
}

export interface SimpleCountry {
    id?: number;
    name: string;
}

export interface CountriesResponse {
    countries: Array<Country>;
}

export interface IAddress {
    id?: number;
    address1: string;
    address2: string;
    zipCode: string;
    city: string;
    stateId?: number;
    otherState: string;
    state?: CountryState;
    country?: any;
    countryId: number;
}

export interface ICreatedAddress {
    isCreated: boolean;
    address: IAddress;
}

export class Address implements IAddress {
    id?: number;
    address1: string;
    address2: string;
    zipCode: string;
    city: string;
    stateId?: number;
    otherState: string;
    state?: CountryState;
    country?: any;
    countryId: number;

    constructor(datas: Record<string, any>) {
        this.id = datas[ 'id' ];
        this.address1 = datas[ 'address1' ];
        this.address2 = datas[ 'address2' ];
        this.zipCode = datas[ 'zipCode' ];
        this.city = datas[ 'city' ];
        this.stateId = datas[ 'stateId' ];
        this.otherState = datas[ 'otherState' ];
        this.state = datas[ 'state' ];
        this.country = datas[ 'country' ];
        this.countryId = datas[ 'countryId' ];
    }

    public getTranslatedState(currentLang: string): string {
        if (this.state != undefined) {
            const transObj = this.state.trans.find((elt) => elt.langId === currentLang);
            return transObj ? (transObj[ 'name' ] ? transObj[ 'name' ] : '') : '';
        } else {
            return '';
        }
    }

    public getTranslatedCountry(currentLang: string): string {
        if (this.country != undefined) {
            const transObj = this.country.trans.find((elt) => elt.langId === currentLang);
            return transObj ? (transObj[ 'name' ] ? transObj[ 'name' ] : '') : '';
        } else {
            return '';
        }
    }
}
