import { CreateArtistComponent } from './../organization/components/organization-create/create-artist/create-artist.component';
import { MyShowsComponent } from './../show/components/my-shows/my-shows.component';
import { Routes } from '@angular/router';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';

export const AccountRoutes: Routes = [
  {
    path: 'my-shows',
    component: MyShowsComponent,
    data: {
      breadcrumb: [SiteConcepts.MYSHOWS],
      data: 'myshows',
    },
  },
  {
    path: 'create-artist',
    component: CreateArtistComponent,
  },
  {
    path: 'my-shows',
    component: MyShowsComponent,
    data: {
      breadcrumb: [SiteConcepts.MYSHOWS],
      data: 'myshows',
    },
  },
];
