import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NetworkService } from '../../../organization/services/network.service';
import {
  Network,
  Organization,
} from '../../../organization/model/organization.model';
import { TranslateService } from '@ngx-translate/core';
import { ToursService } from '../../services/tours.service';
import { Tour } from '../../model/tour.model';
import { LocalizeRouterService } from 'localize-router';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';

@Component({
  selector: 'edit-tours-add-tour-form',
  templateUrl: './edit-tour-form.component.html',
  styleUrls: ['./edit-tour-form.component.scss'],
})
export class EditTourFormComponent implements OnInit {
  id: number;
  name: string;
  organizationId: number;
  intake: [Date, Date] | [] = [];
  processing: [Date, Date] | [] = [];
  organizations: Organization[];
  networks: Network[];
  isAvailable = false;

  submitting = false;
  get updating(): boolean {
    return this.id && !this.name;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly networkService: NetworkService,
    private readonly translateService: TranslateService,
    private readonly toursService: ToursService,
    private readonly router: Router,
    private readonly localizeRouter: LocalizeRouterService,
    private readonly rideauNotificationService: RideauNotificationService,
    private readonly breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.params.tourId || null;

    if (this.id) {
      this.update();
    }

    this.networkService.getAllNetworksWithMembers().subscribe((data) => {
      this.networks = data;
    });
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.submitting = true;

      const [startDate, endDate] = this.intake.length
        ? this.intake
        : [new Date(), new Date()];
      const [analysisStartDate, analysisEndDate] = this.processing.length
        ? this.processing
        : [new Date(), new Date()];

      const body: Tour = new Tour({
        id: this.id,
        startDate,
        endDate,
        analysisStartDate,
        analysisEndDate,
        isAvailable: +this.isAvailable,
        organizationId: this.organizationId,
        trans: [
          {
            langId: this.translateService.currentLang,
            name: this.name,
          },
        ],
      });

      (this.id
        ? this.toursService.updateTour(body)
        : this.toursService.createTour(body)
      ).subscribe((data) => {
        this.submitting = false;
        this.rideauNotificationService.success(
          this.translateService.instant('FORM.SAUVEGARDE')
        );
        this.router.navigate(
          this.localizeRouter.translateRoute(['/admin/tour']) as any[]
        );
      });
    }
  }

  private update(): void {
    this.toursService.getTourById(this.id).subscribe((tour) => {
      this.name = tour.getTranslatedProperty(
        this.translateService.currentLang,
        'name'
      );
      this.intake = [tour.startDate, tour.endDate];
      this.processing = [tour.analysisStartDate, tour.analysisEndDate];
      this.organizationId = tour.organizationId;
      this.isAvailable = !!tour.isAvailable;

      const breadcrumb = new BreadcrumbItem();
      breadcrumb.set(this.name, null, true);
      this.breadcrumbService.addBreadcrumbCascade([breadcrumb], true);
    });
  }
}
