import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Globals } from './../../_configs/globals';

@Directive({
  selector: '[appIfAdminOrOwnerOf]',
})
export class IfAdminOrOwnerOfDirective {
  private hasView = false;
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly accountService: AccountService,
    private readonly globals: Globals
  ) {}
  @Input() set appIfAdminOrOwnerOf(item: { organizationId: number }) {
    const condition =
      (item && item.organizationId && item.organizationId === this.accountService.getCurrentCtxOrganizationId())
      || (this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID);
    if (condition && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
