import { TranslateService } from '@ngx-translate/core';
import { BillingItem } from './../../../model/billing-item.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NetworkService } from 'src/app/concepts/organization/services/network.service';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';

@Component({
  selector: 'app-membership-billing-item',
  templateUrl: './membership-billing-item.component.html',
  styleUrls: ['./membership-billing-item.component.scss'],
})
export class MembershipBillingItemComponent implements OnInit {
  @Input()
  billing: BillingItem;

  @Output()
  billingUpdated = new EventEmitter<any>();

  lang = this.translate.currentLang;

  isManualyFixed: boolean;

  constructor(
    private translate: TranslateService,
    private networkService: NetworkService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    this.isManualyFixed = this.billing.customFee !== null;
  }

  cbxChanged(): void {
    if (!this.isManualyFixed) {
      this.billing.customFee = null;
      this.billingChanged();
    }
  }

  billingChanged(): void {
    console.log('Billing changed', this.billing);
    this.networkService
      .updateNetworkBill(
        this.billing.organizationHeadId,
        this.billing.organizationId,
        this.billing.customFee
      )
      .subscribe(() => {
        this.notification.success(
          this.translate.instant('MODIFICATION-ENREGISTREE')
        );
        this.billingUpdated.emit();
      });
  }
}
