<!--Team & admin Tab-->
<div nz-row>
    <div nz-col
         nzMd="16">
      <h2>{{ 'EQUIPE-ADMIN' | translate}}</h2>
    </div>
    <div class="text-md-right"
         nz-col
         nzMd="8">
      <button type="button"
              *appPermission="5"
              class="btn"
              [disabled]="checkedCollapse"
              (click)="checkedCollapse = !checkedCollapse"
              style="padding: 4px 21px 4px 16px">
        <i class="icon-plus icon-for-btn"></i>
        {{'AJOUTER-MEMBRE' | translate}}
      </button>
    </div>
  </div>

  <div class="d-flex align-items-center"
       nz-row
       nzType='flex'
       nzJustify='space-between'>

    <!-- CREATE MEMBER FORM -->
    <ng-container *ngIf="checkedCollapse">
      <form class="w-100"
            nz-form
            [formGroup]="inviteForm"
            (ngSubmit)="addNewMember()">
        <div class="form-filter"
             *ngIf="!invitationSent">
          <div nz-row
               nzGutter="24"
               class="form-filter__container p-3">
            <div nz-col
                 nzMd="12">
              <nz-form-item>
                <nz-form-control>
                  <label for="email"
                         class="repertory-filter__label d-block">{{'FORM.COURRIEL' | translate}}</label>
                  <input nz-input
                         id="email"
                         formControlName="email"
                         type="email">
                  <nz-form-explain class="has-error" *ngIf="inviteForm.invalid && inviteForm.get('email').errors && inviteForm.get('email').touched">
                    <span
                    *ngIf="inviteForm.get('email').errors['required']; else incorrectField">{{ "CHAMP-REQUIS-SIMPLE" | translate }}</span>
                    <ng-template #incorrectField>{{'ENTRER-VALEUR'|translate}}</ng-template>
                  </nz-form-explain>                         
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col
                 nzMd="12">
              <nz-form-item>
                <nz-form-control>
                  <label class="repertory-filter__label d-block">{{'ROLE-LABEL' | translate}}</label>
                  <nz-select class="select select--small mr-2"
                             formControlName="roleId">
                    <nz-option *ngFor="let organizationsMemberRole of organizationsMemberRoles"
                               [nzValue]="organizationsMemberRole.id"
                               [nzLabel]="organizationsMemberRole.name"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="form-filter__footer text-md-right p-3"
                style="display:flex; justify-content: flex-end;">
            <button class="btn-rect px-3 d-inline-block mr-md-3"
                    style="padding-top:7px"
                    (click)="cancel()">{{ 'ANNULER' | translate }}</button>
            <button class="btn btn--small d-inline-block"
                    nz-button
                    nzSize="large"
                    [disabled]="!inviteForm.valid || isSending"
                    nzType="default">{{ 'AJOUTER' | translate }}</button>
          </div>
        </div>
        <div class="form-filter"
             *ngIf="invitationSent">
          <div nz-row
               nzGutter="24"
               class="form-filter__container p-3">
            <div nz-col
                 *ngIf="invitationSuccessfull"
                 class="invitation-sent-message">
              <p>{{'INVITATION-ENVOYEE' | translate}}</p>
            </div>
            <div nz-col
                 *ngIf="!invitationSuccessfull"
                 class="invitation-error-message">
              <p>{{ errorMessage | translate}}</p>
            </div>
          </div>
        </div>
      </form>

    </ng-container>
  </div>

  <div class="role-infobox px-2 py-3 mb-5 d-flex">
    <div class="col-sm-12">
      <div class="row role-infobox__row">
        <div class="col-sm-2">
          <label class="role-infobox__label">{{ 'MEMBER-ROLE_1' | translate}}</label>
        </div>
        <div class="col-sm-10">
          <span class="role-infobox__desc">{{ 'MEMBER-ROLE_1-DESC' | translate }}</span>
        </div>
      </div>
      <div class="row role-infobox__row">
        <div class="col-sm-2">
          <label class="role-infobox__label">{{ 'MEMBER-ROLE_2' | translate}}</label>
        </div>
        <div class="col-sm-10">
          <span class="role-infobox__desc">{{ 'MEMBER-ROLE_2-DESC' | translate }}</span>
        </div>
      </div>
      <div class="row role-infobox__row">
        <div class="col-sm-2">
          <label class="role-infobox__label">{{ 'MEMBER-ROLE_3' | translate}}</label>
        </div>
        <div class="col-sm-10">
          <span class="role-infobox__desc">{{ 'MEMBER-ROLE_3-DESC' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <nz-divider></nz-divider>

  <!-- MEMBER LIST  -->
  <ng-container *ngIf="organizationMembers.length > 0">
    <h3>{{ 'MEMBRES' | translate}}</h3>
    <div class="table">
      <ng-container *ngFor="let organizationMember of organizationMembers">
        <app-item-row-member [member]="organizationMember"
                             (memberDeleted)="getOrganizationMembers()"
                             (memberChangedRole)="getOrganizationMembers()"
                             [itemLoopOptions]="organizationsMemberRoles">
        </app-item-row-member>
      </ng-container>
    </div>
    <nz-divider></nz-divider>
  </ng-container>

  <!-- INVITATIONS LIST -->
  <ng-container *ngIf="invitations.length > 0">
    <h3>{{ 'INVITATIONS' | translate}}</h3>
    <div class="table invitations">
      <div *ngFor="let invit of invitations">
        <p>{{ invit.email }}</p>
        <p>{{ getRoleForId(invit.roleId) }}</p>
        <button type="button"
                *appPermission="5"
                class="btn-square"
                (click)="deleteInvitation(invit.id)"><i class="icon-trash"></i></button>

      </div>
    </div>
  </ng-container>
