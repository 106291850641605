import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { UploadFile } from 'ng-zorro-antd';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';

@Component({
  selector: 'app-create-artist',
  templateUrl: './create-artist.component.html',
  styleUrls: ['./create-artist.component.scss'],
})
export class CreateArtistComponent implements OnInit {
  validateForm: FormGroup;

  /*FORM SUBMITS AND VALIDATIONS*/
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }

  //logo upload
  loading = false;
  artistLogoUrl: string;

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

  /*FORM STEPS*/
  current = 0;

  index = '';

  pre(): void {
    this.current -= 1;
  }

  next(): void {
    this.current += 1;
  }

  done(): void {
    console.log('done');
  }

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      artist: [null, [Validators.required]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      zipcode: [null, [Validators.required]],
      province: [null, [Validators.required]],
      country: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      phone_post: [null],
      email: [null, [Validators.email, Validators.required]],
      website: [null],
      description: [null],
      numero_entreprise_qc: [null],
      reseaux_sociaux1: [null],
      reseaux_sociaux2: [null],
      reseaux_sociaux3: [null],
    });

    this.loadScript();
  }

  public loadScript(): void {
    const node = document.createElement('script');
    node.src = '/assets/js/main.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('body')[0].prepend(node);
  }

  //Upload Logo
  beforeUpload = (file: File) => {
    const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJPGorPNG) {
      this.notification.error(
        this.translate.instant('FORM.FORMAT-NON-SUPPORTE')
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.notification.error(
        this.translate.instant('FORM.FICHIER-TROP-VOLUMINEUX')
      );
    }
    return isJPGorPNG && isLt2M;
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: UploadFile }): void {
    if (info.file.status === 'uploading') {
      this.loading = true;
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, (img: string) => {
        this.loading = false;
        this.artistLogoUrl = img;
      });
    }
  }
}
