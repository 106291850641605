import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from '../../../model/calendar.model';
import { ProgramStatus } from '../../../model/program-status.model';
import { ISearchProgramCalendar } from '../../../model/program.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { getEProgramStatusValues } from 'src/app/concepts/program/enums/program-status-enum';
import { getTimestampFromDate } from '@app/shared/utils/time-utils';

interface RouteData {
    calendars: Calendar[];
    tourAdmin: boolean;
}

@Component({
    selector: 'app-program-calendar-header',
    templateUrl: './program-calendar-header.component.html',
    styleUrls: ['./program-calendar-header.component.scss']
})
export class ProgramCalendarHeaderComponent implements OnInit {
    @Input() mode: 'month' | 'year' = 'month';
    @Output() modeChange: EventEmitter<'month' | 'year'> = new EventEmitter();

    @Input() fullscreen = true;
    @Input() activeDate: Date = new Date();
    @Input() tour = null;

    @Output() statusFilterChange = new EventEmitter<string[]>();
    @Output() calendarsFilterChange = new EventEmitter<string[]>();
    @Output() onSearchChange = new EventEmitter<ISearchProgramCalendar>();

    @Output() yearChange = new EventEmitter<Date>();
    @Output() monthChange = new EventEmitter<Date>();
    @Output() viewChange: EventEmitter<boolean> = new EventEmitter();

    exportOptions = [
        { id: 0, label: this.translate.instant('EXPORT-ICS') },
        { id: 1, label: this.translate.instant('EXPORT-EXCEL') }
    ];

    @Output() exportCalendarIcs: EventEmitter<void> = new EventEmitter();
    @Output() exportCalendarExcel: EventEmitter<void> = new EventEmitter();

    yearOffset = 10;
    yearTotal = 20;
    years: Array<{ label: string; value: number }>;
    months: Array<{ label: string; value: number }>;
    ProgramStatus = ProgramStatus;

    checkedCollapse = false;
    inTourFilterModel = true;

    // Flag List / Calendar
    isListViewRow = false;
    showExport = true;

    statusArray = getEProgramStatusValues();
    statusFilterModel: boolean[];
    calendarsFilterModel: boolean[];
    showListButton = true;
    routeData: RouteData;
    private subscription = new Subscription();

    constructor(public translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.subscription.add(
            combineLatest([this.activatedRoute.queryParamMap, this.activatedRoute.data as Observable<RouteData>]).subscribe(([queryParamMap, routeData]) => {
                this.routeData = routeData;
                const statusIdValues = (queryParamMap.get('statusIds') || '')
                    .split(',')
                    .filter((v) => v)
                    .map((v) => +v);
                this.statusFilterModel = this.statusArray.map((v) => statusIdValues.includes(v.value));

                const calendarIdValues = (queryParamMap.get('organizationCalendars') || '')
                    .split(',')
                    .filter((v) => v)
                    .map((v) => +v);

                this.calendarsFilterModel = this.routeData.calendars.map((v) => {
                    return calendarIdValues.includes(v.id) || (v.isMain == '1' && calendarIdValues == ('' as any));
                });

                if (this.activatedRoute.snapshot.params['tourId']) {
                    this.exportOptions[1].label = this.translate.instant('EXPORT-TOURNEE-EXCEL');
                    if (!this.routeData.tourAdmin) {
                        this.showExport = false;
                        this.showListButton = false;
                    }
                }
            })
        );

        this.setUpYears();
        this.setUpMonths();
    }

    get activeYear(): number {
        return this.activeDate.getFullYear();
    }

    get activeMonth(): number {
        return this.activeDate.getMonth();
    }

    updateYear(year: number): void {
        this.activeDate.setFullYear(year);
        this.setUpYears(year);
        this.yearChange.emit(new Date(this.activeDate));
    }

    updateMonth(month: number): void {
        this.activeDate.setMonth(month);
        this.monthChange.emit(new Date(this.activeDate));
    }

    private setUpYears(year?: number): void {
        const start = (year || this.activeYear) - this.yearOffset;
        const end = start + this.yearTotal;

        this.years = [];
        for (let i = start; i < end; i++) {
            this.years.push({ label: `${i}`, value: i });
        }
    }

    nextMonth(): void {
        this.updateMonth(this.activeMonth + 1);
    }

    previousMonth(): void {
        this.updateMonth(this.activeMonth - 1);
    }

    private setUpMonths(): void {
        this.months = [...Array(12).keys()].map((v) => ({
            label: this.translate.instant('MOIS.' + v),
            value: v
        }));
    }

    changeListView(bool) {
        this.isListViewRow = bool;
        this.viewChange.emit(this.isListViewRow);
    }

    onExportCallback($event) {
        switch ($event) {
            case 0:
                this.exportCalendarIcs.emit();
                break;
            case 1:
                this.exportCalendarExcel.emit();
                break;

            default:
                break;
        }
    }

    onSearchData(queryParams: ISearchProgramCalendar) {
        this.navigateWithQueryParams({
            ...queryParams,
            date: getTimestampFromDate(this.activeDate)
        });
    }

    onStatusModelChange(event: string[]) {
        this.navigateWithQueryParams({
            statusIds:
                this.statusFilterModel
                    .map((value, index) => value && this.statusArray[index].value + '')
                    .filter((v) => v)
                    .join(',') || null
        });
    }

    onCalendarsModelChange(event: string[]) {
        this.navigateWithQueryParams({
            organizationCalendars:
                this.calendarsFilterModel
                    .map((value, index) => value && this.routeData.calendars[index].id + '')
                    .filter((v) => v)
                    .join(',') || null
        });
    }

    onInTourModelChange(event) {
        this.navigateWithQueryParams({
            nonTourDates: event ? 1 : 0
        });
    }

    private navigateWithQueryParams = (queryParams: any, route: string[] = []): void => {
        this.router.navigate(route, {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge'
        });
    };
}
