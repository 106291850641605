<div class="container">
  <div nz-row nzGutter="16">
    <app-edit-tour-nav></app-edit-tour-nav>

    <div nz-col nzMd="12" *ngIf="form">
      <h2>{{ 'PARAMETRES-TOURNEE' | translate }}</h2>

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-text-input formControlName="name">
          {{ 'NOM-TOURNEE' | translate }} *
        </app-text-input>

        <div nz-row nzGutter="16">
          <div nz-col nzMd="12">
            <app-date-picker formControlName="startDate">
              {{ 'DEBUT-TOURNEE' | translate }} *
            </app-date-picker>
          </div>
          <div nz-col nzMd="12">
            <app-date-picker formControlName="endDate">
              {{ 'FIN-TOURNEE' | translate }} *
            </app-date-picker>
          </div>
        </div>

        <p class="error" *ngIf="form.errors && form.errors.dateRange">
          {{ 'DATE_RANGE_ERROR' | translate }}
        </p>

        <app-select
          formControlName="sourceTourId"
          labelProperty="name"
          [options]="sourceTours">
          {{ 'FORM.SOURCE-OFFRES-SPECTACLES' | translate }}
        </app-select>

        <app-select
          formControlName="maxDateSelected"
          valueProperty="value"
          [options]="maxDateSelectedOptions"
          [withSearch]="'false'"
          [withClear]="'false'"
          class="with-prefix">
          <ng-container prefix>{{ 'FORM.NOMBRE-SUGGESTION-DATES.PREFIX' | translate }}</ng-container>
          {{ 'FORM.NOMBRE-SUGGESTION-DATES.TITRE' | translate }} *
          <ng-container suffix>{{ 'FORM.NOMBRE-SUGGESTION-DATES.SUFFIX' | translate }}</ng-container>
        </app-select>

        <app-single-checkbox formControlName="isAvailable" *ngIf="form.get('isAvailable')">
          {{ 'FORM.ARCHIVER-TOURNEE.LABEL' | translate }}
          <ng-container description>{{ 'FORM.ARCHIVER-TOURNEE.DESCRIPTION' | translate }}</ng-container>
        </app-single-checkbox>

      </form>
    </div>
  </div>
</div>

<div class="footer">
  <div class="container">
    <div nz-row nzGutter="16">
      <div nz-col nzOffset="6" nzMd="18" *ngIf="form">
        <button
          appButton
          class="blue"
          [disabled]="!form.valid"
          [click]="onSubmit.bind(this)"
        >
          {{ 'ENREGISTER' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
