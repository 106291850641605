import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { ListItem } from '../../../shared/model/list-item.model';
import { Translatable } from '../../../shared/model/translatable.model';
import * as Utils from '../../../shared/utils/time-utils';
import { ParticipantMeeting } from '../../meeting/model/participant.model';
import { Organization } from './../../organization/model/organization.model';

export class Activity extends Translatable {
  id: number;
  meetingId?: number;
  organizationId: number;
  categoryId?: number;
  date?: Date;
  hourFrom?: Date;
  hourTo?: Date;
  place?: string;
  room?: string;
  trans: any[];
  isAvailable: number;

  // tableau des participantId des inscrits à l'activité
  participants: number[];

  // restriction d'accès capacité
  capacity?: number;
  remainingCapacity?: number;

  // restrictions d'accès par type d'orga
  hasNoAccessRestrictions: number;
  allowDiffusers: number;
  allowProducers: number;
  allowVenuesOwners: number;
  allowGovernmental: number;
  allowProviders: number;
  allowMedia: number;
  allowOthers: number;
  allowIndividuals: number;
  // restriction à un network
  organizationHeadId: number;

  // restrictions d'accès par produits
  hasNoProductsRestrictions: number;
  products: any[];

  private generalFormGroup: FormGroup;
  private disponibilityFormGroup: FormGroup;

  constructor(datas: Record<string, any>) {
    super();

    // General Form
    this.id = datas['id'];
    this.meetingId = datas['meetingId'];
    this.organizationId = datas['organizationId'];
    this.categoryId = datas['categoryId'];
    this.date = datas['date'] ? new Date(datas['date']) : null;
    this.hourFrom = datas['hourFrom'];
    this.hourTo = datas['hourTo'];
    this.place = datas['place'];
    this.room = datas['room'];
    this.trans = datas['trans'];
    this.participants = datas['participants'];
    this.isAvailable = datas['isAvailable'];
    // Disponibility form
    this.capacity = datas['capacity'];
    this.remainingCapacity = datas['remainingCapacity'];
    this.hasNoAccessRestrictions = datas['hasNoAccessRestrictions'];
    this.allowDiffusers = datas['allowDiffusers'];
    this.allowProducers = datas['allowProducers'];
    this.allowVenuesOwners = datas['allowVenuesOwners'];
    this.allowGovernmental = datas['allowGovernmental'];
    this.allowProviders = datas['allowProviders'];
    this.allowMedia = datas['allowMedia'];
    this.allowOthers = datas['allowOthers'];
    this.allowIndividuals = datas['allowIndividuals'];
    this.organizationHeadId = datas['organizationHeadId'];
    this.hasNoProductsRestrictions = datas['hasNoProductsRestrictions'];
    this.products = datas['products'];
  }

  toListItem(lang: string, activityRoute?: string): ListItem {
    const item = new ListItem();
    item.itemId = this.id;
    item.itemTitle = this.getTranslatedProperty(lang, 'title');
    if (activityRoute) {
      item.itemUrl = `/${activityRoute}/${this.meetingId}/${this.id}`;
    }
    if (this.categoryId) {
      item.itemCategory = 'ACTIVITY-CATEGORIES.' + this.categoryId;
    }
    if (this.date) {
      const eventDate =
        new Date(this.date).toLocaleString('fr-CA', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          timeZone: 'America/Montreal',
        }) + ' 05:00:00.0000000 +00:00';
      item.itemDate = `${eventDate}`;
    }

    item.itemSubtitle = this.getHourInfos();
    item.itemInfo1 = `${this.place ? this.place : ''}`;
    item.itemInfo2 = `${this.room ? this.room : ''}`;
    item.itemInfo5 = `${
      (this.capacity || this.capacity == 0) &&
      this.capacity - this.participants.length <= 0
        ? 'COMPLE'
        : ''
    }`;
    return item;
  }

  getHourInfos(): string {
    let hourInfo = '';
    if (this.hourFrom) {
      hourInfo = Utils.getTimeFromTimestamp(this.hourFrom);
    }
    if (!!this.hourTo) {
      hourInfo += ` - ${Utils.getTimeFromTimestamp(this.hourTo)}`;
    }
    return hourInfo;
  }

  getGeneralFormGroup(fb: FormBuilder, lang: string): FormGroup {
    if (!this.generalFormGroup) {
      this.generalFormGroup = fb.group({
        title: [this.getTranslatedProperty(lang, 'title')],
        meetingId: [this.meetingId],
        categoryId: [this.categoryId],
        date: [this.date],
        hourFrom: [this.setTimeEventDate(this.date, this.hourFrom)],
        hourTo: [this.setTimeEventDate(this.date, this.hourTo)],
        place: [this.place],
        room: [this.room],
        isAvailable: [this.isAvailable === 1],
      });
    }

    return this.generalFormGroup;
  }

  setTimeEventDate(date: Date, hour: Date) {
    let result = null;

    if (date) {
      result = new Date(
        new Date(hour).setFullYear(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        )
      );
    }

    return result;
  }

  getDisponibilityFormGroup(fb: FormBuilder): FormGroup {
    if (!this.disponibilityFormGroup) {
      this.disponibilityFormGroup = fb.group({
        capacity: [this.capacity],
        hasOrgaTypeRestrictions: [!this.hasNoAccessRestrictions],
        orgaTypeRestrictions: fb.group({
          allowDiffusers: [!!this.allowDiffusers],
          allowProducers: [!!this.allowProducers],
          allowVenuesOwners: [!!this.allowVenuesOwners],
          allowGovernmental: [!!this.allowGovernmental],
          allowProviders: [!!this.allowProviders],
          allowMedia: [!!this.allowMedia],
          allowOthers: [!!this.allowOthers],
          allowIndividuals: [!!this.allowIndividuals],
          hasOrgaId: [!!this.organizationHeadId],
          orgaId: [this.organizationHeadId],
        }),
        hasNoProductsRestrictions: [!!this.hasNoProductsRestrictions],
        productsRestrictions: fb.array(
          this.products.map((product) => product.id)
        ),
      });
    }

    return this.disponibilityFormGroup;
  }

  /**
   * Determine si L'activité peut-etre choisi pour le participant passé en parametre
   * (et son organisation)
   */
  isActivityChoiceEnabled(
    participant: ParticipantMeeting,
    organization: Organization
  ): boolean {
    let state = true;
    // Si l'activité est complet et le participant n'est pas encore inscrit
    if (
      (this.capacity || this.capacity == 0) &&
      this.participants &&
      this.capacity - this.participants.length <= 0 &&
      !this.participants.includes(participant.id)
    ) {
      return false;
    }
    // Si l'utilisateur fait parti d'une organisation
    if (organization) {
      // Si l'activité a des restrictions par network, et qu'on ne trouve pas
      // ce network dans ceux de l'orga courante --> disabled
      if (
        this.organizationHeadId &&
        !organization.networks.includes(this.organizationHeadId)
      ) {
        return false;
      }
      // On vérifie les réstrictions sur le type d'organisation
      if (
        this.allowProducers ||
        this.allowDiffusers ||
        this.allowVenuesOwners ||
        this.allowIndividuals ||
        this.allowGovernmental ||
        this.allowProviders ||
        this.allowMedia ||
        this.allowOthers
      ) {
        state = false;
      }
      if (this.allowProducers) {
        state =
          state || organization.types.includes(OrganizationTypes.IS_PRODUCTEUR);
      }
      if (this.allowDiffusers) {
        state =
          state || organization.types.includes(OrganizationTypes.IS_DIFFUSEUR);
      }
      if (this.allowVenuesOwners) {
        state =
          state || organization.types.includes(OrganizationTypes.IS_SALLE);
      }
      if (this.allowGovernmental) {
        state =
          state ||
          organization.types.includes(OrganizationTypes.IS_GOVERNMENTAL);
      }
      if (this.allowProviders) {
        state =
          state || organization.types.includes(OrganizationTypes.IS_PROVIDER);
      }
      if (this.allowMedia) {
        state =
          state || organization.types.includes(OrganizationTypes.IS_MEDIA);
      }
      if (this.allowOthers) {
        state =
          state || organization.types.includes(OrganizationTypes.IS_OTHER);
      }
    } else {
      // si il s'agit d'un utilisateur sans organisation, on autorise si il n'y a pas de restriction
      // on si l'Activité autorise les individus seuls
      state = !!this.hasNoAccessRestrictions || !!this.allowIndividuals;
    }

    if (!this.hasNoProductsRestrictions) {
      // Verifies that the participant owns at least one product in the activity's products list
      const isParticpantOwnerofRestrictedProduct =
        participant.products.filter(
          (participantProduct) =>
            !!this.products.find(
              (product) => participantProduct.id === product.id
            )
        ).length > 0;
      state = state && isParticpantOwnerofRestrictedProduct;
    }

    return state && this.isParticipantScheduleFree(participant);
  }

  isParticipantScheduleFree(participant: ParticipantMeeting): boolean {
    let res = true;
    // Loop sur les activities du participant pour vérifier les chevauchements.
    participant.activities
      .filter((act) => act.id !== this.id)
      .forEach((partActivity) => {
        // si les 2 activités sont à la même date
        this.date.setHours(0, 0, 0, 0);
        partActivity.date.setHours(0, 0, 0, 0);
        const isSameDay =
          this.date.getFullYear() === partActivity.date.getFullYear() &&
          this.date.getMonth() === partActivity.date.getMonth() &&
          this.date.getDate() === partActivity.date.getDate();
        if (isSameDay) {
          // Si les heures ne se chevauchent pas => OK; Sinon => FALSE
          const d1DateFrom = Utils.ToSameDay(new Date(partActivity.hourFrom));
          const d1DateTo = Utils.ToSameDay(new Date(partActivity.hourTo));
          const d2DateFrom = Utils.ToSameDay(new Date(this.hourFrom));
          const d2DateTo = Utils.ToSameDay(new Date(this.hourTo));
          // On considère que pour une date, hourTo est forcément superieur à hourFrom.
          const isOk = d1DateTo <= d2DateFrom || d1DateFrom >= d2DateTo;
          res = res && isOk;
        }
      });
    return res;
  }
}
