import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../../account/services/account.service';
import { Meeting } from '../../model/meeting.model';
import { MeetingService } from '../../services/meeting.service';
import { Globals } from '../../../../_configs/globals';

@Component({
  selector: 'app-my-meetings-little',
  templateUrl: './my-meetings-little.component.html',
  styleUrls: ['./my-meetings-little.component.scss'],
})
export class MyMeetingsLittleComponent implements OnInit {
  lang: string;
  imgFallback: string;
  activeRoute: string;

  meetingList: Meeting[] = [];

  isLoading: boolean;

  isDisabled = false;

  constructor(
    private translate: TranslateService,
    private globals: Globals,
    private meetingService: MeetingService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.imgFallback = this.globals.imgFallback;
    this.getMyMeetings();
    this.accountService.currentOrganizationChange.subscribe(() =>
      this.getMyMeetings()
    );
  }

  getMyMeetings(): void {
    this.isLoading = true;
    this.meetingService
      .getAllMyParticipatedMeetings()
      .subscribe((meetings: Meeting[]) => {
        this.meetingList = meetings;
        this.isLoading = false;
      });
  }
}
