import { Routes } from '@angular/router';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';
import { AddShowOfferFormComponent } from './components/add-tour-show/add-show-offer-form.component';
import { SubmitedShowsComponent } from './components/submited-shows/submited-shows.component';
import { TourShowSingleComponent } from './components/tour-show-single/tour-show-single.component';
import { EditTourFormComponent } from './components/edit-tour/edit-tour-form.component';
import { ListedTourComponent } from './components/listed-tour/listed-tour.component';
import { EditTourOwnerComponent } from './components/edit-tour/edit-tour-owner.component';
import { EditTourComponent } from './components/edit-tour/edit-tour.component';
import { EditTourSettingsComponent } from './components/edit-tour/edit-tour-settings.component';
import { EditTourAdminsComponent } from './components/edit-tour/edit-tour-admins.component';
import { EditTourDiffuseursComponent } from './components/edit-tour/edit-tour-diffuseurs.component';
import { ArchivedToursComponent } from 'src/app/concepts/tours/components/archived/archived-tours.component';
import { ArchivedPaidToursResolver } from 'src/app/concepts/tours/archived-paid-tours.resolver';
import { TourEditRouteResolvers } from './tour-edit-route-resolvers';
import { TourShowResolver } from './tour-show.resolver';

export const ToursRoutes: Routes = [
  {
    path: ':tourId/new-show-offer',
    component: AddShowOfferFormComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.TOURNEE],
    }
  },

  {
    path: 'shows',
    component: SubmitedShowsComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.TOURNEE],
    },
  },

  {
    path: 'archived',
    component: ArchivedToursComponent,
    data: {
      breadcrumb: [SiteConcepts.PROGRAM, SiteConcepts.ARCHIVED],
    },
    resolve: {
      archivedPaidTours: ArchivedPaidToursResolver
    }
  },

  {
    path: ':tourId/shows',
    component: SubmitedShowsComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.TOURNEE],
    },
  },

  {
    path: ':tourId/shows/:tourShowId',
    component: TourShowSingleComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
      appPermission: undefined,
      shouldBeApproved: false,
    },
  },

  {
    path: ':tourId/shows/:tourShowId/edit',
    component: AddShowOfferFormComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.TOURNEE],
    },
    resolve: {
      tourShow: TourShowResolver
    }
  },

  {
    path: ':tourId/edit/owner',
    component: EditTourOwnerComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
    },
  },
  {
    path: ':tourId/edit',
    component: EditTourComponent,
    resolve: TourEditRouteResolvers,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
    },
    children: [
      {
        path: 'settings',
        component: EditTourSettingsComponent,
      },
      {
        path: 'admins',
        component: EditTourAdminsComponent,
      },
      {
        path: 'diffuseurs',
        component: EditTourDiffuseursComponent,
      }
    ],
  },
  {
    path: 'new',
    component: EditTourComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
    },
    children: [
      {
        path: 'settings',
        component: EditTourSettingsComponent,
      }
    ]
  },
  {
    path: ':sourceTourId/new',
    component: EditTourComponent,
    data: {
      breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
    },
    children: [
      {
        path: 'settings',
        component: EditTourSettingsComponent,
      }
    ]
  }
];

export const AdminTourRoutes: Routes = [
  {
    path: ':tourId/edit',
    component: EditTourFormComponent,
    data: {
      breadcrumb: [SiteConcepts.TOUR, SiteConcepts.SINGLE],
    },
  },
  {
    path: '',
    component: ListedTourComponent,
    data: {
      breadcrumb: [SiteConcepts.TOUR],
    },
  },
  {
    path: 'add',
    component: EditTourFormComponent,
    data: {
      breadcrumb: [SiteConcepts.TOUR],
    },
  },
];
