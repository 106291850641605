import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {OrganizationToursShows, ToursService} from 'src/app/concepts/tours/services/tours.service';

@Component({
  selector: 'app-organisation-depot',
  templateUrl: './organisation-depot.component.html',
  styleUrls: ['./organisation-depot.component.scss']
})
export class OrganisationDepotComponent implements OnInit {


  organisationTours: OrganizationToursShows;
  visibleTours = []

  @Input() organisationId: number;

  constructor(
    private tourService: ToursService
  ) { }

  ngOnInit() {
    this.tourService.getVisibleTourByOrganization(this.organisationId).subscribe(result => {
      this.visibleTours = result.visibleTourShows
    })
    this.tourService.getOrganizationTourShows(this.organisationId).toPromise().then(orgTourShow => {
      this.organisationTours = orgTourShow;
      this.organisationTours.tourShows = orgTourShow.tourShows.filter(tourShow => !this.visibleTours.find(tour => tour.tourId === tourShow.tour.id ));
    });
  }

  onTourShowDelete(tourId: number) {
    const tourIndex = this.organisationTours.tourShows.findIndex(tourShow => tourShow.tour.id === tourId);

    this.organisationTours.tourShows.splice(tourIndex, 1);

  }

}
