import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  template: `
    <div class="message-icon">
      <div class="icon"><ng-content select="i"></ng-content></div>
      <div class="message"><ng-content></ng-content></div>
    </div>
    <div class="button"><ng-content select="button"></ng-content></div>
  `,
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  constructor(readonly translateService: TranslateService) {}
}
