import { HttpClient, HttpHandler, HttpHeaders, HttpParams, HttpXhrBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Organization } from './concepts/organization/model/organization.model';
import { Tag } from './concepts/tags/model/tags.model';

const backendUrl = environment.BACKEND + 'datalayer/';
declare global {
    interface Window {
        dataLayer: {
            push(data: Record<string, unknown>): void;
        };
    }
}

export interface ShowOfferSearchData {
    terme?: string;
    discipline?: string[];
    producteur?: string;
    public?: string;
    etiquettes?: string[];
}

const defaultShowOfferSearchData: ShowOfferSearchData = {
    terme: undefined,
    discipline: undefined,
    producteur: undefined,
    public: undefined,
    etiquettes: undefined
};

export interface BottinSearchData {
    terme?: string;
    membre?: string;
    province?: string;
    ville?: string;
    residencyType?: number[];
    disponibilityPeriod?: number[];
    totalStageWidth?: number;
    totalSceneDepth?: number;
    clearHeightLamps?: number;
    hasAttachmentPoints?: boolean;
    hasDanceFloor?: boolean;
    reseau?: string;
    capacite?: {
        min?: number;
        max?: number;
    };
}

const defaultBottinSearchData: BottinSearchData = {
    terme: undefined,
    membre: undefined,
    province: undefined,
    ville: undefined,
    reseau: undefined,
    capacite: undefined
};

export interface UserOrganisationData {
    admin?: boolean;
    organisation?: string;
    types?: string[];
}

export interface ShowOfferViewData {
    showId?: number;
    offre?: string;
    disciplines?: Array<string[]>;
    publics?: string[];
    representant?: string;
    isDiffuser?: number;
}

export interface OrganisationViewData {
    organisation?: string;
    types?: string[];
}

export function tagPageView(path: string, httpCLient: HttpClient): void {
    window.dataLayer.push({
        event: 'page_view',
        data: path
    });
    sendLastLayer(httpCLient, 'page-view', (lastLayer) => {
        return {
            uniqueEventId: lastLayer['gtm.uniqueEventId'],
            path: path
        };
    });
}

export function tagShowOfferSearch(data: ShowOfferSearchData, httpClient: HttpClient): void {
    window.dataLayer.push({
        data: {
            ...defaultShowOfferSearchData,
            ...data
        },
        event: 'recherche_offres_de_spectacle'
    });

    sendLastLayer(httpClient, 'show-offer-search', (lastLayer) => {
        return {
            uniqueEventId: lastLayer['gtm.uniqueEventId'],
            term: data.terme,
            discipline: data.discipline,
            producer: data.producteur,
            public: data.public,
            tags: data.etiquettes
        };
    });
}

export function tagBottinSearch(data: BottinSearchData, httpClient: HttpClient): void {
    window.dataLayer.push({
        data: {
            ...defaultBottinSearchData,
            ...data
        },
        event: 'recherche_bottin'
    });
    sendLastLayer(httpClient, 'bottin-search', (lastLayer) => {
        return {
            uniqueEventId: lastLayer['gtm.uniqueEventId'],
            term: data.terme,
            member: data.membre,
            province: data.province,
            city: data.ville,
            network: data.reseau,
            capacityMin: data.capacite ? data.capacite.min : undefined,
            capacityMax: data.capacite ? data.capacite.max : undefined
        };
    });
}

export function tagUserOrganisation(data: UserOrganisationData, httpClient: HttpClient): void {
    window.dataLayer.push({
        data,
        event: 'user_organisation'
    });
    sendLastLayer(httpClient, 'user-organization', (lastLayer) => {
        return {
            uniqueEventId: lastLayer['gtm.uniqueEventId'],
            admin: data.admin ? 1 : 0,
            organization: data.organisation,
            types: JSON.stringify(data.types)
        };
    });
}

export function tagShowOfferView(data: ShowOfferViewData, httpClient: HttpClient): void {
    window.dataLayer.push({
        data,
        event: 'vue_offre_de_spectacle'
    });
    sendLastLayer(httpClient, 'show-view', (lastLayer) => {
        return {
            uniqueEventId: lastLayer['gtm.uniqueEventId'] ? lastLayer['gtm.uniqueEventId'] : undefined,
            showId: data.showId ? data.showId : undefined,
            isDiffuser: data.isDiffuser ? data.isDiffuser : undefined,
            title: data.offre ? data.offre : undefined,
            disciplines: data.disciplines ? JSON.stringify(data.disciplines) : undefined,
            audiences: data.publics ? JSON.stringify(data.publics) : undefined,
            representative: data.representant ? data.representant : undefined
        };
    });
}

export function tagOrganisationView(data: OrganisationViewData, httpClient: HttpClient): void {
    window.dataLayer.push({
        data,
        event: 'vue_organisation'
    });
    sendLastLayer(httpClient, 'organization-view', (lastLayer) => {
        return {
            uniqueEventId: lastLayer['gtm.uniqueEventId'],
            organization: data.organisation,
            types: JSON.stringify(data.types)
        };
    });
}

export type CascadeChild = { label: string; value: string; children: CascadeChild[] };

export function data_layer_cascade_r(value: string, child: CascadeChild, tokens: CascadeChild[]): CascadeChild[] {
    if (child.value === value) {
        return tokens;
    }
    if (child.children) {
        for (const cchild of child.children) {
            const result = data_layer_cascade_r(value, cchild, [...tokens, cchild]);
            if (result) {
                return result;
            }
        }
    }
}

function getLastDataLayer() {
    return (window.dataLayer as []).length > 0 ? window.dataLayer[(window.dataLayer as []).length - 1] : {};
}

function sendLastLayer(httpClient: HttpClient, endpoint: string, callback: (lastLayer: any) => any) {
    httpClient
        .post(backendUrl + endpoint, callback(getLastDataLayer()), {
            headers: new HttpHeaders().append('Authorization', 'Bearer' + localStorage.getItem(''))
        })
        .toPromise()
        .catch((e) => {});
}
