import { ListItem } from '../../../model/list-item.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RowTypes } from '../../../enums/row-types.enum';
import { NzModalService } from 'ng-zorro-antd';
import { Globals } from '../../../../_configs/globals';
import { TranslateService } from '@ngx-translate/core';

export interface ModifiedParticipantRowEvent {
  itemId: number;
  participantType?: string;
  productsDeleted?: number[];
}

@Component({
  selector: 'app-item-row-participant',
  templateUrl: './item-row-participant.component.html',
  styleUrls: ['./item-row-participant.component.scss'],
})
export class ItemRowParticipantComponent implements OnInit {
  @Input() item: ListItem;
  @Input() itemRowType: RowTypes;
  @Input() enableEditing: boolean;
  @Input() enableRemoving: boolean;
  @Input() hideThumbnails: boolean;

  @Output() modifyItem = new EventEmitter<ModifiedParticipantRowEvent>();
  @Output() removeItem = new EventEmitter<number>();

  isEditInscriptions = false;
  products: ListItem[] = [];
  productsToDelete: number[] = [];

  constructor(
    private globals: Globals,
    private translate: TranslateService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    if (!this.item.itemImgSrc || !this.item.itemImgSrc.trim()) {
      //image prop fallback
      this.item.itemImgSrc = this.globals.imgFallback;
    }
  }

  removeItemForm(): void {
    const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
    const acceptDelete = this.translate.instant('OUI');
    const rejectDelete = this.translate.instant('NON');
    this.modalService.confirm({
      nzContent: deleteMessages,
      nzOkText: acceptDelete,
      nzCancelText: rejectDelete,
      nzClosable: true,
      nzMaskClosable: true,
      nzOnOk: () => {
        this.removeItem.emit(this.item.itemId);
      },
    });
  }

  modifyInscriptions(): void {
    if (this.isEditInscriptions) return;
    this.isEditInscriptions = true;
    this.products = this.item.itemList;
  }
  updateParticipantType(): void {
    this.modifyItem.emit({
      itemId: this.item.itemId,
      participantType: this.item.itemInfo3,
    });
  }
  deleteProduct(productId: number): void {
    this.productsToDelete.push(productId);
    this.products = this.products.filter((item) => item.itemId !== productId);
  }

  submitDeletedProducts(): void {
    if (this.productsToDelete.length > 0) {
      this.modifyItem.emit({
        itemId: this.item.itemId,
        productsDeleted: this.productsToDelete,
      });
      this.productsToDelete = [];
      this.item.itemList = this.products;
    }
    this.isEditInscriptions = false;
  }

  cancelEditProducts(): void {
    this.isEditInscriptions = false;
    this.products = this.item.itemList;
    this.productsToDelete = [];
  }
}
