<app-page-title title="{{ 'PROMOTIONS' | translate }}">
</app-page-title>


<div class="container">
	<app-item-list [itemList]="promItemArray" [filterList]="leftSideFilters" [pagination]="pagination"
		[isLoading]="isLoading" [elementsLabel]="('PROMOTIONS' | translate)  | lowercase" [useFilters]="true"
		[useSearchBar]="false" [useSorting]="false" [usePagination]="true" [enableEditing]="true"
		[enableRemoving]="true" (filterChange)="onFilterChange($event)" (pageChange)="onPageChange($event)"
		(modifyItems)="modifyPromotion($event)" (removeItems)="deletePromotion($event)">
	</app-item-list>
</div>