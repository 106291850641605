<div nz-row
     class="mb-3"
     nzGutter="24">
  <div nz-col
       nzMd="12"
       *ngIf="classificationsListCascade.length>0">
    <label for="disciplines">{{'FORM.DISCIPLINE' | translate}}</label>
    <br>
    <nz-cascader id="disciplines"
                 nzChangeOnSelect
                 nzPlaceHolder="{{'FORM.DISCIPLINE' | translate}}"
                 [nzExpandTrigger]="'hover'"
                 [(ngModel)]="discipline"
                 (ngModelChange)="handleChange()"
                 [nzOptions]="classificationsListCascade"></nz-cascader>
  </div>
  <div nz-col
       nzMd="12">
    <label for="producer">{{'ORGANIZATION-TYPE_1' | translate}}</label>
    <nz-select [(ngModel)]="producer"
               name="organizationId"
               nzShowSearch
               nzAllowClear
               style="width: 100%"
               name="producer"
               id="producer"
               (ngModelChange)="this.producerSubject.next($event)">
      <nz-option *ngFor="let organization of organizations"
                 [nzLabel]="organization.getTranslatedProperty(lang,'name')"
                 [nzValue]="organization.id"></nz-option>
    </nz-select>
  </div>


</div>

<div nz-row
     class="mb-3"
     nzGutter="24">
     <div nz-col
     nzMd="12">
     <label for="public">{{'FORM.PUBLIC-CIBLE' | translate}}</label>
     <br>
     <nz-select
       id="public"
       [(ngModel)]="public"
       (ngModelChange)="handleChange()"
       nzMode='multiple' >

       <nz-option
         [nzValue]="0"
           nzLabel="{{ 'TOUS' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.GRAND_PUBLIC"
         nzLabel="{{ 'FORM.GRAND_PUBLIC' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.BEBES"
         nzLabel="{{ 'FORM.BEBES' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.TOUT_PETITS"
         nzLabel="{{ 'FORM.TOUT_PETITS' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.PETIT_ENFANTS"
         nzLabel="{{ 'FORM.PETIT_ENFANTS' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.ENFANTS"
         nzLabel="{{ 'FORM.ENFANTS' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.JEUNES"
         nzLabel="{{ 'FORM.JEUNES' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.ADULTES"
         nzLabel="{{ 'FORM.ADULTES' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.AINES"
         nzLabel="{{ 'FORM.AINES' | translate }}">
       </nz-option>
       <nz-option
         [nzValue]="showAudiences.FAMILLES"
         nzLabel="{{ 'FORM.FAMILLES' | translate }}">
       </nz-option>

     </nz-select>

     </div>
  <div nz-col
       nzMd="12">
    <label for="tags">{{'ETIQUETTES' | translate}}</label>
    <nz-select [(ngModel)]="selectedTags"
               nzMode="multiple"
               style="width: 100%"
               id="tags"
               (ngModelChange)="this.tagSubject.next($event)">
      <nz-option *ngFor="let tag of tags"
                 [nzLabel]="tag.getTranslatedProperty(lang,'keyword')"
                 [nzValue]="tag.id"></nz-option>
    </nz-select>
  </div>

</div>

<!--  -->
