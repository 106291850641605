import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteToHour'
})
export class MinuteToHourPipe implements PipeTransform {

  transform(minutes: number): string {
    return ((minutes - (minutes%60))/60) + ' h ' + (minutes%60)
  }

}
