import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Translatable } from "../model/translatable.model";

@Pipe({
  name: "translatable",
})
export class TranslatablePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Translatable, attributName: string): any {
    return value.getTranslatedProperty(
      this.translateService.currentLang,
      attributName
    );
  }
}
