import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Globals } from 'src/app/_configs/globals';
import { VenueConfigurationType } from '../../../enums/venue-configuration-type.enum';
import { AbstractVenueFormComponent } from '../venue-abstract-form/venue-abstract-form.component';

@Component({
    selector: 'app-venue-technical-information-form',
    templateUrl: './venue-technical-information-form.component.html',
    styleUrls: ['./venue-technical-information-form.component.scss']
})
export class VenueTechnicalInformationFormComponent extends AbstractVenueFormComponent implements OnInit {
    public controlVenueConfigurationsArray: FormArray;
    public uploadAction: string;
    public loading = false;

    venueConfiguationTypes: any[];

    public formGroup: FormGroup;

    constructor(private fb: FormBuilder, private translate: TranslateService, protected globals: Globals, protected accountService: AccountService) {
        super(accountService, globals);
    }

    ngOnInit(): void {
        this.uploadAction = this.globals.api.ENDPOINT + 'upload/venue/tech';
        this.initForm();
        this.venueConfiguationTypes = this.getVenueConfigurationTypes();
    }

    private getVenueConfigurationTypes() {
        return Object.keys(VenueConfigurationType)
            .filter((key) => !Number(key))
            .map((key, index) => {
                return {
                    label: this.translate.instant('FORM.' + key),
                    value: index + 1
                };
            });
    }

    protected initForm() {
        this.formGroup = this.venue.getTechnicalInfoForm(this.fb, this.translate.currentLang);
        //Temp push socialsArray value into social Array
        this.controlVenueConfigurationsArray = <FormArray>this.formGroup.get('configurations');
        this.venue.configurations.forEach((x) => {
            this.controlVenueConfigurationsArray.push(this.patchValues(x['configurationTypeId'], x['capacity']));
        });

        this.formGroup.patchValue({
            urlTechSpecification: this.venue.urlTechSpecification
        });
    }
    patchValues(configurationTypeId, capacity) {
        return this.fb.group({
            configurationTypeId: [configurationTypeId, Validators.required],
            capacity: [Math.abs(capacity), Validators.required]
        });
    }
    addField(e?: MouseEvent): void {
        if (e) {
            e.preventDefault();
        }
        this.controlVenueConfigurationsArray.push(this.patchValues('', ''));
    }
    removeField(index, e: MouseEvent): void {
        e.preventDefault();
        this.controlVenueConfigurationsArray.controls.splice(index, 1); //remove targetted FormControl from FormArray
    }

    doSubmit(param?: any) {
        //Re-affecter les socialMedias
        this.venueValue.urlTechSpecification = this.formGroup.value.urlTechSpecification;
        // Remove configurations that lack a type or capacity
        this.venueValue.configurations = this.controlVenueConfigurationsArray.value.filter(
            (configuration) => configuration.configurationTypeId && configuration.capacity
        );
        super.doSubmit(param);
    }
}
