import { Component, OnInit } from '@angular/core';
import { Show } from 'src/app/concepts/show/model/show.model';
import { ActivatedRoute } from '@angular/router';
import { TourShow } from '../../model/tour-show.model';
import { ToursService } from '../../services/tours.service';

@Component({
  selector: 'app-tour-show-single',
  templateUrl: './tour-show-single.component.html',
})
export class TourShowSingleComponent implements OnInit {
  lang: string;

  show: Show;

  tourShow: any;

  tourId: number;

  tourShowId: number;

  isReady: boolean;

  isLoading: boolean;

  constructor(
    private tourService: ToursService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.tourId = this.route.snapshot.params['tourId'];
    this.tourShowId = this.route.snapshot.params['tourShowId'];

    this.getTourShow();
  }

  getTourShow() {
    this.isLoading = true;
    // rest call with filters & pagination
    const tourShowFilters = [
      {
        field: 'tourId',
        value: this.tourId,
      },
    ];

    this.tourService
      .getTourShowById(this.tourShowId, tourShowFilters)
      .subscribe((tourShow: TourShow) => {
        this.tourShow = tourShow;

        this.isLoading = false;
        this.isReady = true;
      });
  }
}
