import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NzModalService} from 'ng-zorro-antd';
import {TourShows, ToursService} from 'src/app/concepts/tours/services/tours.service';

@Component({
  selector: 'app-organisation-tour-accordion',
  templateUrl: './organisation-tour-accordion.component.html',
  styleUrls: ['./organisation-tour-accordion.component.scss']
})
export class OrganisationTourAccordionComponent implements OnInit {

  @ViewChild('modalText', {static: true}) modalTemplateRef;

  @Input() organizationId: number;
  @Input() tourShows: TourShows;
  @Input() isFirst: boolean = false;
  @Input() isLast: boolean = false;

  @Output() deleteEvent: EventEmitter<number> = new EventEmitter();

  @HostBinding('class.first') get IsFirst(){
    return this.isFirst;
  }

  @HostBinding('class.last') get IsLast(){
    return this.isLast;
  }

  tourName: string;

  isOpen = false;


  constructor(
    private translateService: TranslateService,
    private modaleService: NzModalService,
    private tourService: ToursService
  ) { }

  ngOnInit() {
    this.tourName = this.tourShows.tour.getTranslatedProperty(this.translateService.currentLang, 'name');
  }

  onClick() {

    this.isOpen = !this.isOpen;

  }

  delete(event) {
    event.stopPropagation()

    this.modaleService.create({
      nzContent: this.modalTemplateRef,
      nzOkText: this.translateService.instant('SUPPRIMER'),
      nzCancelText: this.translateService.instant('ANNULER'),
      nzOnOk: () => {
        this.tourService.HideTourForOrganization(this.organizationId, this.tourShows.tour.id).subscribe(result => {
        this.deleteEvent.emit(this.tourShows.tour.id);
        })
      }
    })
  }

}
