import { LocalizeRouterService } from 'localize-router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs/Subscription';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';
import { CartProduct } from 'src/app/concepts/payment/models/cart-product.model';
import { CreditCardService } from './../../concepts/payment/services/credit-card.service';
import { RideauNotificationService } from '../../shared/services/rideau-notification.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
    public cartItems: Array<CartProduct>;
    public formatter;
    public isReady = false;
    public billDetails: Array<any>;
    public lang = this.translate.currentLang;
    public creditCards: any[] = [];
    public showCreditCards;
    public selectedCard;
    public cvvNumber;
    public isLoading = false;
    public currentOrgId: number;

    private subscription: Subscription;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private modalService: NzModalService,
        private accountService: AccountService,
        private localizeRouter: LocalizeRouterService,
        private creditCardService: CreditCardService,
        private notification: RideauNotificationService,
        private shoppingCartService: ShoppingCartService
    ) {}

    ngOnInit(): void {
        this.formatter = new Intl.NumberFormat('fr-CA', {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'symbol',
            minimumFractionDigits: 2
        });
        this.isLoading = true;
        this.getCartItems();
        this.getCards();
        this.subscription = this.accountService.currentOrganizationChange.subscribe(() => {
            this.getCartItems();
            this.getCards();
        });
    }

    getCartItems() {
        this.shoppingCartService.getCart().subscribe((data) => {
            this.cartItems = data['products'].map((cartProd) => {
                const rslt = new CartProduct(cartProd);
                rslt.template.setFinalPrice();
                rslt.totalPrice = rslt.template.singlePrice && rslt.quantity ? rslt.template.singlePrice * rslt.quantity : 0;
                return rslt;
            });
            this.billDetails = data['bill_details'];

            this.isReady = true;
            this.isLoading = false;
        });
    }

    getCards() {
        this.currentOrgId = this.accountService.getCurrentCtxOrganizationId();
        this.creditCardService.getCards(this.currentOrgId).subscribe((data) => {
            this.creditCards = data || [];
            if (this.creditCards.length === 1) {
                this.selectedCard = this.creditCards[0].id;
            }
        });
    }

    removeFromCart(itemId) {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: this.translate.instant(acceptDelete),
            nzCancelText: this.translate.instant(rejectDelete),
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.isLoading = true;
                this.shoppingCartService.removeProductFromCart(itemId).subscribe(() => this.getCartItems());
            }
        });
    }

    payCart() {
        this.showCreditCards = !this.showCreditCards;
    }

    checkout(paymentInformations) {
        this.cvvNumber = paymentInformations.cvvNumber;
        this.selectedCard = paymentInformations.selectedCard;

        const paiementInfos = {
            // "memberId":null,
            organizationId: this.accountService.getCurrentCtxOrganizationId(),
            paymentMethodId: Number(this.selectedCard),
            cardCvv: this.cvvNumber,
            frontAmount: Number(this.billDetails['total'])
        };

        this.isLoading = true;

        this.shoppingCartService.checkout(paiementInfos).subscribe(
            (res) => {
                this.showCreditCards = false;
                this.notification.success(this.translate.instant('PAIEMENT-ACCEPTE'));
                const route: any[] = this.localizeRouter.translateRoute([
                    '/organization',
                    this.accountService.getCurrentCtxOrganizationId(),
                    'bill',
                    res['transactionId']
                ]) as any[];
                this.router.navigate(route);
                // this.getCartItems();
            },
            (err) => {
                console.log(err);
                this.isLoading = false;
                if (err.status === 410) {
                    this.notification.error(this.translate.instant('ERRORS.ResourceNotAvailable'));
                } else if (err.status === 409) {
                    this.notification.error(this.translate.instant('ERRORS.PAIEMENT-REFRESH'));
                } else if (err.status === 402) {
                    this.notification.error(this.translate.instant('ERRORS.CREDIT-CARD-DECLINED'));
                } else {
                    this.notification.error(this.translate.instant('ERRORS.PAIEMENT-ERROR'));
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
