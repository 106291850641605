<div
  class="statusNotification"
  [ngClass]="notification.status"
>
  <span class="statusNotification__message">
    <svg [ngClass]="notification.status" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" *ngIf="notification.status === 'warning'">
      <path fill-rule="nonzero" d="M10 19a1 1 0 0 1 0-2c.593 0 1.175-.073 1.737-.217a1 1 0 0 1 .495 1.938A9.015 9.015 0 0 1 10 19zm7.02-3.369a1 1 0 0 1-1.559-1.252 7 7 0 0 0 .917-1.49 1 1 0 0 1 1.822.826 9 9 0 0 1-1.18 1.916zm1.776-7.546a1 1 0 1 1-1.955.424 6.942 6.942 0 0 0-.583-1.648 1 1 0 1 1 1.787-.899c.338.672.59 1.384.75 2.123zm-4.84-6.171a1 1 0 1 1-.88 1.796 6.942 6.942 0 0 0-1.655-.566 1 1 0 0 1 .404-1.959c.741.153 1.456.398 2.13.729zm-7.721-.09a1 1 0 0 1 .837 1.816 7.003 7.003 0 0 0-1.485.926 1 1 0 0 1-1.261-1.552 9.003 9.003 0 0 1 1.909-1.19zm-5 6.123a1 1 0 0 1 1.948.454A7.024 7.024 0 0 0 3 10v.034a1 1 0 0 1-2 .01V10c0-.697.08-1.385.236-2.053zm1.621 7.528a1 1 0 0 1 1.587-1.218c.356.465.77.884 1.23 1.246a1 1 0 1 1-1.237 1.572 9.048 9.048 0 0 1-1.58-1.6z"/>
    </svg>
    <svg [ngClass]="notification.status" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" *ngIf="notification.status === 'info'">
      <path fill-rule="nonzero" d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321.13-.537.67-.868 1.207-.739s.868.669.739 1.206C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909-.126-.538.208-1.076.745-1.202.538-.127 1.076.207 1.202.744zM10 9c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1v-5c0-.552.448-1 1-1zm0-9c4.778 0 8.67 3.168 9.796 7.854.129.537-.202 1.077-.74 1.206-.536.13-1.076-.202-1.205-.739C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367c-.126.537-.664.87-1.202.745C.4 8.985.066 8.447.191 7.909 1.3 3.194 5.202 0 10.001 0zm0 4.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5S8.5 6.828 8.5 6s.672-1.5 1.5-1.5z"/>
    </svg>
    <svg [ngClass]="notification.status" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" *ngIf="notification.status === 'success'">
      <path fill-rule="nonzero" d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321a1 1 0 0 1 1.946.467C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909a1 1 0 0 1 1.947-.458zm11.426-6.097a1 1 0 0 1 .326 1.282l-.064.108-4.776 7a1 1 0 0 1-1.523.154l-.083-.092-2.224-2.77a1 1 0 0 1 1.475-1.344l.085.093 1.378 1.716 4.016-5.885a1 1 0 0 1 1.39-.262zM10 0c4.778 0 8.67 3.168 9.796 7.854a1 1 0 0 1-1.945.467C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367a1 1 0 1 1-1.947-.458C1.3 3.194 5.202 0 10.001 0z"/>
    </svg>
    <svg [ngClass]="notification.status" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" *ngIf="notification.status === 'danger'">
      <path fill-rule="nonzero" d="M10.878 1.021l9 16.5A1 1 0 0 1 19 19H5a1 1 0 0 1 0-2h12.315L10 3.588l-8.122 14.89a1 1 0 1 1-1.756-.957l9-16.5a1 1 0 0 1 1.756 0zM10 14a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-7a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1z"/>
    </svg>
    <svg [ngClass]="notification.status" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" *ngIf="notification.status === 'deleted'">
        <path fill-rule="nonzero" d="M15 7a1 1 0 0 1 1.01.991l.09 10a1 1 0 0 1-1 1.009H5.9a1 1 0 0 1-1-1.009l.09-10a1 1 0 0 1 2 .018L6.91 17h7.18l-.08-8.991A1 1 0 0 1 15 7zM9 7a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm3 0a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm1-6a1 1 0 0 1 1 1v2h3a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2h3V2a1 1 0 0 1 1-1h5zm-1 2H9v1h3V3z"/>
    </svg>
    <div [innerHTML]="notification.message | translate: notification.params"></div>
    <!-- <div [translate]="notification.message" [translateParams]="{org: param}"></div> -->
  </span>
</div>
