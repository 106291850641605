import { Globals } from './../../../../../_configs/globals';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { tap, map, filter, distinctUntilChanged } from 'rxjs/operators';
import { LocationService } from 'src/app/concepts/location/services/location.service';
import { AbstractOrganizationForm } from '../organization-abstract-form/organization-abstract-form.component';
import { AccountService } from './../../../../account/services/account.service';
import { CountryState } from './../../../model/state.model';
import { Country, SimpleCountry } from '@app/concepts/location/models/location.model';

@Component({
    selector: 'app-organization-coordinate-form',
    templateUrl: './organization-coordinate-form.component.html',
    styleUrls: [ './organization-coordinate-form.component.scss' ]
})
export class OrganizationCoordinateFormComponent extends AbstractOrganizationForm implements OnInit {
    public canadaSelected = false;
    public countries: SimpleCountry[];
    public lang = this.translate.currentLang;
    public selectedCountry: number;
    public shouldDisplayFormControlError = (fomControlName: string, error: string): boolean => {
        const formControl = this.formGroup.get(fomControlName) as FormControl;
        // not required
        if (!formControl.value) {
            return false;
        }
        if (!formControl.touched || !formControl.dirty) {
            return false;
        }
        if (!formControl.errors || !formControl.errors[ error ]) {
            return false;
        }
        return true;
    };
    public states$: Observable<Array<CountryState>>;
    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        public locationService: LocationService,
        protected globals: Globals,
        public accountService: AccountService
    ) {
        super(accountService, globals);
    }

    public get isSceneProAdmin(): boolean {
        return this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID;
    }

    /**
     * Submits the form.
     * @param {any} [param] - Optional parameter to pass to the submit method.
     */
    public doSubmit(param?: any): void {
        if (this.formGroup.invalid) {
            this.displayErrors = true;
            return;
        }
        const { address1, city, zipcode, province, country, phone, phonePostNumber, email, website, artsdataId, wikidataId } = this.formGroup.value;
        this.organizationValue.setTranslatedProperty(this.translate.currentLang, 'name', this.formGroup.get('name').value); // this.coordForm.value.name seems undefined when the value of the control is set ??
        Object.assign(this.organizationValue.address, { address1, city, zipCode: zipcode, otherState: province, countryId: country });
        Object.assign(this.organizationValue, { phone, phonePostNumber, email, website, artsdataId, wikidataId });

        super.doSubmit(param);
    }

    /**
     * Gets a list of countries from the location service.
     * @returns {Observable<any>} An observable that emits a list of countries.
     */
    public getCountries(): Observable<any> {
        return this.locationService.getCountries().pipe(
            tap((data: Country[]) => {
                // Sort countries by translated name in alphabetical order
                this.countries = data
                    .map(
                        (country: Country): SimpleCountry => ({
                            id: country.id,
                            name: this.translate.instant('COUNTRIES.' + country.id)
                        })
                    )
                    .sort((country1: SimpleCountry, country2: SimpleCountry) => country1.name.localeCompare(country2.name, this.lang));
            })
        );
    }

    /**
     * Gets an observable of states from the location service and filters Canadian states.
     * @returns {Observable<CountryState[]>} An observable that emits a list of Canadian states.
     */
    public getStates(): void {
        this.states$ = this.locationService.getStates().pipe(
            map((data: CountryState[]): CountryState[] => data.filter((e) => this.locationService.isSelectedCountryCanada(e.countryId))));
    }

    public ngOnInit(): void {
        this.initForm();
        this.getCountries().subscribe();
        this.countriesValueChangesHandler();
    }

    /**
     * Initializes the form.
     */
    protected initForm(): void {
        // Check if country is Canada (ID: 124)
        this.canadaSelected = this.organization.address.countryId === 124;
        if (this.canadaSelected) {
            this.getStates(); // Get list of states
        }

        // Initialize form
        this.formGroup = this.organization.getCoordFormGroup(this.lang, this.isSceneProAdmin, this.globals);
        this.organization.coordFormValueChangedListener();
        this.checkDisable();
    }

    private countriesValueChangesHandler(): void {
        this.formGroup.get('country').valueChanges.pipe(distinctUntilChanged(), filter(x => !!x)).subscribe((val) => {
            if (val !== this.selectedCountry) {
                // pas dans le premier passage. On met la province � null qund le pays change
                if (this.selectedCountry !== undefined) {
                    this.formGroup.get('province').reset();
                    this.formGroup.get('province').setValue('');
                }

                this.selectedCountry = val;
                if (this.locationService.isSelectedCountryCanada(this.selectedCountry)) {
                    //if Canada
                    this.canadaSelected = true;
                    if (!this.formGroup.get('province').value) {
                        this.formGroup.get('province').setValue('Qu�bec');
                    }
                    this.getStates();
                } else {
                    this.canadaSelected = false;
                }
                this.getStates();
            }
        });
    }
}
