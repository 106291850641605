import { Organization } from '../../organization/model/organization.model';

export class TourDiffuseur {
  id: number;
  hasSubmitedPrograms: boolean;
  isInvited: boolean;
  organization: Organization;
  constructor(datas: Record<string, any>) {
    this.hasSubmitedPrograms = datas['hasSubmitedPrograms'] ? datas['hasSubmitedPrograms'] : false;
    this.id = datas['id'];
    this.isInvited = datas['isInvited'] != null ? !!datas['isInvited'] : null;
    this.organization = datas['organization'] ? new Organization(datas['organization']) : null;
  }
}
