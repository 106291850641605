import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-picker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
  template: `
    <nz-form-control [nzValidateStatus]="validateStatus">
      <nz-time-picker
        class="w-100"
        [(ngModel)]="selected"
        (ngModelChange)="ngModelChange()"
        nzFormat="HH:mm"
        [nzDisabled]="disabled"
      >
      </nz-time-picker>
    </nz-form-control>
  `,
  styles: [
    `
      :host.warning /deep/ .ant-time-picker-input {
        border: 2px solid #faad14 !important;
        border-radius: 4px !important;
      }

      :host.warning /deep/ .ant-time-picker-input:hover {
        border: 2px solid #faad14 !important;
      }

      :host.error /deep/ .ant-time-picker-input {
        border: 2px solid #f5222d !important;
        border-radius: 4px !important;
      }

      :host.error /deep/ .ant-time-picker-input:hover {
        border: 2px solid #f5222d !important;
      }

      :host.disabled /deep/ .ant-time-picker-clear {
        display: none !important;
      }
    `,
  ],
})
export class TimePickerComponent implements ControlValueAccessor {
  private onChange: (value: Date) => void;
  private onTouched: () => void;
  @Input() disabled: boolean;
  @Input() validateStatus = '';
  @HostBinding('class.warning') get classWarning(): boolean {
    return this.validateStatus === 'warning';
  }
  @HostBinding('class.error') get classError(): boolean {
    return this.validateStatus === 'error';
  }
  @HostBinding('class.disabled') get classDisabled(): boolean {
    return this.disabled;
  }
  selected: Date;
  constructor(readonly translateService: TranslateService) {}
  ngModelChange(): void {
    this.onChange(this.selected);
    this.onTouched();
  }
  writeValue(value: Date): void {
    this.selected = value;
  }
  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
