import { Component, OnInit, Input } from '@angular/core';
import { Tour } from '../../../concepts/tours/model/tour.model';
import { TranslateService } from '@ngx-translate/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-depot-button',
    templateUrl: './depot-button.component.html',
    styleUrls: ['./depot-button.component.scss']
})
export class DepotButtonComponent implements OnInit {
    @Input() tour: Tour;
    @Input() isNetworkMember: number;
    @Input() isSceneProAdmin: boolean;
    public currentLang = this.translate.currentLang;
    currentTour: Tour;
    public route$: Observable<string>;
    public get currentTourName(): string {
        return this.currentTour.getTranslatedProperty(this.currentLang, 'name');
    }

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.currentTour = new Tour(this.tour);
        this.route$ = this.getRoute$();
    }

    private getRoute$(): Observable<string> {
        return this.translate.stream('ROUTES.tour').pipe(
            withLatestFrom(this.translate.stream(`ROUTES.${this.isNetworkMember || this.isSceneProAdmin ? `shows` : `new-show-offer`}`)),
            map(([tourRoute, translatedRoute]: [string, string]) => `/${this.currentLang}/${tourRoute}/${this.tour.id}/${translatedRoute}`)
        );
    }
}
