import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { NzModalService } from 'ng-zorro-antd';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { Globals } from 'src/app/_configs/globals';
import { environment } from '../../../../environments/environment';
import { RideauNotificationService } from '../../../shared/services/rideau-notification.service';
import { Organization, IMember } from '../../organization/model/organization.model';
import { OrganizationService } from '../../organization/services/organization.service';
import { AccountService } from '../../account/services/account.service';
import { FieldAreEqualsValidator } from 'src/app/shared/validators/fieldsAreEquals.validator';
import { ErrorsHandler } from 'src/app/shared/error-handling/errors-handler';

const SCENE_PRO_ID = 1;

@Component({
    selector: 'app-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss'],
    providers: [ErrorsHandler]
})
export class UserAccountComponent implements OnInit {
    public openedTab: string;
    public uri: string = environment.BACKEND;
    public uploadAction: string;
    public resetPasswordUrl = this.global.endpoints.reset_password;
    public imageChangedEvent: any = '';
    public croppedImage: any = '';

    currentEmailToShow = '';
    validateForm: FormGroup;
    newEmailRequested = false;
    user: PxUser;
    userId = 0;
    organizations: Organization[] = [];
    organizationsNumber: number;
    currentLang = this.translate.currentLang;
    public emailsOption: boolean;
    passwordForm: FormGroup;
    showPasswordForm: boolean = false;
    public readonly imageDimensions = this.global.images;
    // avatar
    avatarUrl: string;
    loading = false;

    canLeaveOrg: {
        id: number;
        value: boolean;
    }[] = [];

    constructor(
        private fb: FormBuilder,
        protected global: Globals,
        private route: ActivatedRoute,
        private modalService: NzModalService,
        public translate: TranslateService,
        public accountService: AccountService,
        public localizeRouter: LocalizeRouterService,
        private notification: RideauNotificationService,
        private organizationService: OrganizationService,
        private errorHandler: ErrorsHandler
    ) {}

    ngOnInit(): void {
        this.userId = this.route.snapshot.params['userId'];
        this.accountService.getUser(this.userId).subscribe((user: PxUser) => {
            this.user = user;
            this.emailsOption = this.user.emailsOption === 1;
            this.newEmailRequested = this.user.pendingEmailChangeRequest === 1;
            this.initForm();
            this.initPasswordForm();
        });
    }

    initForm() {
        this.openedTab = this.route.snapshot.data['openedTab'];
        this.uploadAction = this.uri + 'upload/profileImage';
        this.currentEmailToShow = this.newEmailRequested ? this.user.newEmail : this.user.email;

        this.validateForm = this.fb.group({
            id: this.user.id,
            firstName: new FormControl(this.user.firstName, [Validators.required]),
            lastName: new FormControl(this.user.lastName, [Validators.required]),
            email: new FormControl(this.currentEmailToShow, [Validators.required, Validators.pattern(this.global.emailRegex)]),
            emailsOption: new FormControl(this.user.emailsOption),
            phone: new FormControl(this.user.phone),
            phonePostNumber: new FormControl(this.user.phonePostNumber),
            avatar: new FormControl(this.user.avatar)
        });

        this.getUserOrganizations();
    }

    getUserOrganizations() {
        this.organizations = [];
        this.accountService.getUserOrganizations(this.user.id).subscribe((data: Organization[]) => {
            this.organizations = data;
            this.organizationsNumber = this.organizations.length;

            // Disables "leave" button if current user is the only admin of this same organization
            this.organizations.forEach((organization: Organization) => {
                this.organizationService.getOrganizationTeam(organization.id).subscribe((orgTeam) => {
                    this.canLeaveOrg.push({
                        id: organization.id,
                        value: !!orgTeam.members.find((member: IMember) => member.userId !== Number(this.user.id) && member.roleId === 1)
                    });
                });
            });
        });
    }

    changeLang(value) {
        this.translate.use(value);
    }

    /* CALL TO CREATE ORGANIZATION SERVICE */
    async updateAccount() {
        this.validateForm.value.isReachable = !this.validateForm.value.isReachable ? 0 : 1;
        this.validateForm.value.emailsOption = !this.validateForm.value.emailsOption ? 0 : 1;
        const isEmailChanged = this.isEmailChanged();
        if (this.showPasswordForm && this.passwordForm.valid) {
            if (this.validateForm.dirty) {
                await this.accountService
                    .updateAccount({ ...this.validateForm.value, isEmailChanged })
                    .toPromise()
                    .then((_) => {
                        if (isEmailChanged) {
                            this.newEmailRequested = true;
                        }
                    });
            }

            this.accountService
                .updatePassword(+this.user.id, {
                    newPassword: this.passwordForm.get('newPassword').value
                })
                .subscribe(
                    (result) => {
                        this.showPasswordForm = false;
                        this.initPasswordForm();
                        this.notification.success(this.translate.instant('SUCCESS_RESET_PASSWORD_ADMIN'));
                    },
                    (error) => {
                        if (error.error.message && error.error.message == 'You have entered an invalid old password value') {
                            this.notification.error(this.translate.instant('ERRORS.WRONG-CURRENT-PASSWORD'));
                        } else if (error.error.message && error.error.message == 'The following parameters is missing : oldPassword') {
                            this.notification.error(this.translate.instant('ADMIN-ERROR-OWN-PASSWORD'));
                        } else {
                            this.errorHandler.handleError(error);
                        }
                    }
                );
        } else if (!this.showPasswordForm) {
            this.accountService.updateAccount({ ...this.validateForm.value, isEmailChanged }).subscribe((result) => {
                this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));

                if (isEmailChanged) {
                    this.newEmailRequested = true;
                }
            });
        }
    }

    /*FORM SUBMITS AND VALIDATIONS*/
    submitForm(): void {
        for (const i in this.validateForm.controls) {
            if (i.hasOwnProperty('controls')) {
                this.validateForm.controls[i].markAsDirty();
                this.validateForm.controls[i].updateValueAndValidity();
            }
        }
        if (this.validateForm.valid) {
            this.updateAccount();
        } else {
            this.notification.error('Formulaire non valid');
        }
    }

    onUploadFileCallback($event) {
        // console.log($event);
        this.validateForm.patchValue({
            avatar: $event
        });
    }

    private initPasswordForm() {
        this.passwordForm = this.fb.group(
            {
                newPassword: new FormControl('', [Validators.required, Validators.pattern(this.global.passwordRegex)]),
                confirmPassword: new FormControl('', [Validators.required])
            },
            {
                validators: [FieldAreEqualsValidator.verify('newPassword', 'confirmPassword')]
            }
        );
    }

    showPassword() {
        this.showPasswordForm = !this.showPasswordForm;

        if (!this.showPasswordForm) {
            this.initPasswordForm();
        }
    }

    private isEmailChanged() {
        return this.validateForm.get('email').dirty && this.currentEmailToShow != this.validateForm.get('email').value ? 1 : 0;
    }
}
