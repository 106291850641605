import {Component} from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {

  helpMenuList = [
    {value: "AIDE-MENU.GENERAL", id:"AIDE-MENU-ID.GENERAL", selected: true},
    {value: "AIDE-MENU.BOTTIN", id:"AIDE-MENU-ID.BOTTIN",selected: false},
    {value: "AIDE-MENU.OFFRES-SPECTACLES", id:"AIDE-MENU-ID.OFFRES-SPECTACLES", selected: false},
    {value: "AIDE-MENU.RENCONTRES-PRO", id:"AIDE-MENU-ID.RENCONTRES-PRO", selected: false},
    {value: "AIDE-MENU.TOURNER-QUEBEC", id:"AIDE-MENU-ID.TOURNER-QUEBEC", selected: false}
  ]

  onSelectMenu(menu): void {
    menu.selected=true
  }

}
