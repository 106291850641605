import { Component, Input, HostBinding } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
  selector: 'app-single-checkbox',
  template: `
    <nz-form-item>
      <nz-form-control>
        <label [attr.for]="id" nz-checkbox [(ngModel)]="value" (ngModelChange)="ngModelChange()">
          <ng-content></ng-content>
        </label>
        <div class="description">
          <ng-content select="[description]"></ng-content>
        </div>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .description {
        line-height: 1.5;
        margin-left: 32px;
      }
    `
  ],
})
export class SingleCheckboxComponent implements ControlValueAccessor {
  private onChange: (value: boolean) => void;
  private onTouched: () => void;
  @Input() disabled: boolean;
  id = `SingleCheckboxComponent-${unique++}`;
  value: boolean;
  constructor(
    readonly translateService: TranslateService,
    readonly ngControl: NgControl
  ) {
    this.ngControl.valueAccessor = this;
  }
  ngModelChange(): void {
    this.onChange(this.value);
    this.onTouched();
  }
  writeValue(value: boolean): void {
    this.value = value;
  }
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
