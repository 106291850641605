import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Promotion } from '../../model/promotion.model';
import { PromotionService } from '../../services/promotion.service';
import { getRemainingDays } from '../../../../shared/utils/time-utils';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';

@Component({
  selector: 'app-my-promotions',
  templateUrl: './my-promotions.component.html',
  styleUrls: ['./my-promotions.component.scss'],
})
export class MyPromotionsComponent implements OnInit {
  promItemArray: ListItem[] = [];

  private promotions: Promotion[];
  private orgId: number;
  private lang: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private localizeRouter: LocalizeRouterService,
    private promotionService: PromotionService,
    private notification: RideauNotificationService,
    private shoppingCartService: ShoppingCartService
  ) {}

  ngOnInit(): void {
    this.orgId = this.route.snapshot.params['organizationId'];
    this.lang = this.translate.currentLang;
    this.getPromotions();
  }

  getPromotions() {
    const filters: SelectedFilter[] = [
      {
        field: 'organizationId',
        value: this.orgId,
      } as SelectedFilter,
    ];
    this.promotionService.getPromotions(filters).subscribe((res) => {
      this.promotions = res;
      this.promItemArray = this.promotions.map((prom) => {
        const item = prom.toListItem(this.lang);
        const itemDayStart = new Date(prom.dateFrom);
        const itemDayEnd = new Date(itemDayStart);
        itemDayEnd.setMonth(itemDayEnd.getMonth() + Number(prom.monthsNumber));
        item.itemDate = getRemainingDays(
          itemDayStart,
          itemDayEnd,
          this.translate
        );
        return item;
      });
    });
  }

  goToOffers() {
    this.router.navigate(
      this.localizeRouter.translateRoute([
        '/shows-offers',
        this.orgId,
        '0',
        'new-promotion',
      ]) as any[]
    );
  }

  renouveler(item: ListItem) {}

  modifier(item: ListItem) {
    this.router.navigate(
      this.localizeRouter.translateRoute([
        '/admin/promotion',
        item.itemId,
        'modify',
      ]) as any[]
    );
  }

  supprimer(item: ListItem) {
    this.promotionService.deletePromotion(item.itemId).subscribe(() => {
      this.notification.success(this.translate.instant('PROMOTION-SUPPRIMEE'));
      this.shoppingCartService.updateCard('removed');
      this.getPromotions();
    });
  }
}
