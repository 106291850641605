import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from 'localize-router';
import { NgZorroAntdModule, NzCalendarModule } from 'ng-zorro-antd';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { BadgeComponent } from './components/badge/badge.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { InputMeterComponent } from './components/input-meter-label/input-meter.component';
import { DownloadPhotoLightboxComponent } from './components/download-photo-lightbox/download-photo-lightbox.component';
import { ItemCardComponent } from './components/item-card/item-card.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { ImageCropperModule } from 'ngx-image-cropper-upgraded';
// tslint:disable-next-line: max-line-length
import { ItemRowBillingSubscriptionComponent } from './components/item-rows/item-row-billing-subscription/item-row-billing-subscription.component';
import { ItemRowBillingComponent } from './components/item-rows/item-row-billing/item-row-billing.component';
import { ItemRowContactComponent } from './components/item-rows/item-row-contact/item-row-contact.component';
import { ItemRowFileComponent } from './components/item-rows/item-row-file/item-row-file.component';
import { ItemRowMemberComponent } from './components/item-rows/item-row-member/item-row-member.component';
import { ItemRowComponent } from './components/item-rows/item-row/item-row.component';
import { ItemUploadRepeaterComponent } from './components/item-upload-repeater/item-upload-repeater.component';
import { BreadcrumbComponent } from './components/layout/breadcrumb/breadcrumb.component';
import { MainNavigationComponent } from './components/layout/main-navigation/main-navigation.component';
import { PageTitleComponent } from './components/layout/page-title/page-title.component';
import { SectionTitleComponent } from './components/layout/section-title/section-title.component';
import { NewCreditCardComponent } from './components/new-credit-card/new-credit-card.component';
import { AudioPlayerComponent } from './components/players/audio-player/audio-player.component';
import { VideoPlayerComponent } from './components/players/video-player/video-player.component';
import { BillComponent } from './components/prints/bill/bill.component';
import { SelectCreditCardComponent } from './components/select-credit-card/select-credit-card.component';
import { SocialLinkComponent } from './components/social-link/social-link.component';
import { UserInfosComponent } from './components/user-infos/user-infos.component';
import { PermissionDirective } from './directives/permission.directive';
import { ExternalLink } from './pipes/external-link.pipe';
import { HourMinPipe } from './pipes/hour-min.pipe';
import { TimeFromDatePipe } from './pipes/time-from-date.pipe';
import { ShortenTextPipe } from './pipes/shorten-text.pipe';
import { PaidBillComponent } from './components/prints/paid-bill/paid-bill.component';
import { UnpaidBillComponent } from './components/prints/unpaid-bill/unpaid-bill.component';
import { ItemRowPromoComponent } from './components/item-rows/item-row-promo/item-row-promo.component';
import { ItemRowParticipantComponent } from './components/item-rows/item-row-participant/item-row-participant.component';
import { EtiquettesMiniComponent } from './components/etiquettes/etiquettes-mini/etiquettes-mini.component';
import { EtiquettesLargeComponent } from './components/etiquettes/etiquettes-large/etiquettes-large.component';
import { NetworkCardComponent } from './components/network-card/network-card.component';
import { DepotButtonComponent } from './components/depot-button/depot-button.component';
import { ImgUploadComponent } from './components/img-upload/img-upload.component';
import { TimeagoModule } from 'ngx-timeago';
import { ExportDropdownComponent } from './components/export-dropdown/export-dropdown.component';
import { RichTextComponent } from './components/rich-text/rich-text.component';
import { QuillModule } from 'ngx-quill';
import { TextInputComponent } from './components/text-input/text-input.component';
import { RowSelectComponent } from './components/row-select/row-select.component';
import { RowMultipleSelectComponent } from './components/row-select/row-multiple-select.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { SelectComponent } from './components/select/select.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ButtonDirective } from './components/button/button.directive';
import { IfOwnerOfDirective } from './directives/if-owner-of.directive';
import { IfAdminDirective } from './directives/if-admin.directive';
import { IfAdminOrOwnerOfDirective } from './directives/if-admin-or-owner-of.directive';
import { RadioComponent } from './components/radio/radio.component';
import { SingleCheckboxComponent } from './components/checkbox/single-checkbox.component';
import { ButtonConnectionComponent } from './components/button-connection/button-connection.component';
import { ConnectionContainerComponent } from './components/connection-container/connection-container.component';
import { ModalComponent } from './components/modal/modal.component';
import { CasePipe } from './pipes/case.pipe';
import { ExtractArtistListPipeablePipe } from './pipes/extract-artist-list-pipeable.pipe';
import { InputPhoneExtensionComponent } from './components/input-phone-extension/input-phone-extension.component';
import { ItemCardBannerComponent } from './components/item-card/item-card-banner/item-card-banner.component';
import { PasswordHintValidatorComponent } from './components/password-hint-validator/password-hint-validator.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { StringShortenerPipe } from './pipes/string-shortener.pipe';
import { TranslatablePipe } from './pipes/translatable.pipe';
import { DynamicSearchBarComponent } from './components/dynamic-search-bar/dynamic-search-bar.component';
import { RowItemActionComponent } from './components/row-item-action/row-item-action.component';
import { ClickOutsideElementDirective } from './directives/click-outside-element.directive';
import { AlternativeAssetImageDirective } from './directives/alternative-asset-image.directive';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { LoadingIndicationComponent } from './components/loading-indication/loading-indication.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { ShowIdentificationComponent } from './components/show-identification/show-identification.component';
import { ShowModalitiesComponent } from './components/show-modalities/show-modalities.component';
import { AddClassOnClickDirective } from './directives/add-class-on-click.directive';
import { OnlyDecimalDirective } from './directives/only-decimal.directive';
import { AlertComponent } from './components/alert/alert.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { FloatNumberPipe } from './pipes/float-number.pipe';
import { ConvertTypePipe } from './pipes/convert-type.pipe';
import { FormErrorComponent } from './components/form-error/form-error.component';

@NgModule({
    declarations: [
        ItemCardComponent,
        ItemRowComponent,
        ItemRowMemberComponent,
        ItemRowContactComponent,
        ItemRowFileComponent,
        ItemRowBillingComponent,
        ItemRowBillingSubscriptionComponent,
        ItemRowPromoComponent,
        ItemUploadRepeaterComponent,
        MainNavigationComponent,
        PageTitleComponent,
        SectionTitleComponent,
        SocialLinkComponent,
        BreadcrumbComponent,
        BadgeComponent,
        ItemListComponent,
        TimeFromDatePipe,
        EnumToArrayPipe,
        CasePipe,
        HourMinPipe,
        ExternalLink,
        PermissionDirective,
        DownloadPhotoLightboxComponent,
        NewCreditCardComponent,
        SelectCreditCardComponent,
        BillComponent,
        ContactFormComponent,
        UserInfosComponent,
        VideoPlayerComponent,
        AudioPlayerComponent,
        ShortenTextPipe,
        PaidBillComponent,
        UnpaidBillComponent,
        ItemRowParticipantComponent,
        NetworkCardComponent,
        DepotButtonComponent,
        EtiquettesMiniComponent,
        EtiquettesLargeComponent,
        ImgUploadComponent,
        ExportDropdownComponent,
        RichTextComponent,
        TextInputComponent,
        ExportDropdownComponent,
        RowSelectComponent,
        RowMultipleSelectComponent,
        TimePickerComponent,
        DatePickerComponent,
        SelectComponent,
        CalendarComponent,
        ButtonDirective,
        IfOwnerOfDirective,
        IfAdminDirective,
        IfAdminOrOwnerOfDirective,
        RadioComponent,
        SingleCheckboxComponent,
        ButtonConnectionComponent,
        ConnectionContainerComponent,
        InputPhoneExtensionComponent,
        ModalComponent,
        ExtractArtistListPipeablePipe,
        InputPhoneExtensionComponent,
        ItemCardBannerComponent,
        PasswordHintValidatorComponent,
        PasswordInputComponent,
        StringShortenerPipe,
        TranslatablePipe,
        DynamicSearchBarComponent,
        RowItemActionComponent,
        ClickOutsideElementDirective,
        AlternativeAssetImageDirective,
        ActionButtonComponent,
        LoadingIndicationComponent,
        OverlayComponent,
        ItemCardBannerComponent,
        AddClassOnClickDirective,
        ShowIdentificationComponent,
        ShowModalitiesComponent,
        TranslatablePipe,
        AlertComponent,
        InputMeterComponent,
        OnlyDecimalDirective,
        FloatNumberPipe,
        ConvertTypePipe,
        FormErrorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        NzCalendarModule,
        ImageCropperModule,
        TranslateModule,
        RouterModule,
        LocalizeRouterModule,
        QuillModule
    ],
    exports: [
        ShortenTextPipe,
        ConvertTypePipe,
        MainNavigationComponent,
        PageTitleComponent,
        SectionTitleComponent,
        ItemCardComponent,
        ItemRowComponent,
        ItemRowMemberComponent,
        ItemRowContactComponent,
        ItemRowFileComponent,
        ItemRowBillingComponent,
        ItemRowBillingSubscriptionComponent,
        ItemRowPromoComponent,
        ItemRowParticipantComponent,
        ItemUploadRepeaterComponent,
        SocialLinkComponent,
        BreadcrumbComponent,
        BadgeComponent,
        ItemListComponent,
        TimeFromDatePipe,
        EnumToArrayPipe,
        HourMinPipe,
        ExternalLink,
        PermissionDirective,
        DownloadPhotoLightboxComponent,
        NewCreditCardComponent,
        SelectCreditCardComponent,
        BillComponent,
        UserInfosComponent,
        ContactFormComponent,
        VideoPlayerComponent,
        AudioPlayerComponent,
        NetworkCardComponent,
        DepotButtonComponent,
        EtiquettesMiniComponent,
        EtiquettesLargeComponent,
        ImgUploadComponent,
        TimeagoModule,
        NzDropDownModule,
        ExportDropdownComponent,
        RichTextComponent,
        TextInputComponent,
        ExportDropdownComponent,
        RowSelectComponent,
        RowMultipleSelectComponent,
        TimePickerComponent,
        DatePickerComponent,
        SelectComponent,
        CalendarComponent,
        ButtonDirective,
        IfOwnerOfDirective,
        IfAdminDirective,
        IfAdminOrOwnerOfDirective,
        RadioComponent,
        SingleCheckboxComponent,
        ButtonConnectionComponent,
        ConnectionContainerComponent,
        InputPhoneExtensionComponent,
        ModalComponent,
        ExtractArtistListPipeablePipe,
        InputPhoneExtensionComponent,
        PasswordHintValidatorComponent,
        PasswordInputComponent,
        StringShortenerPipe,
        TranslatablePipe,
        DynamicSearchBarComponent,
        RowItemActionComponent,
        ClickOutsideElementDirective,
        AlternativeAssetImageDirective,
        ActionButtonComponent,
        LoadingIndicationComponent,
        OverlayComponent,
        AddClassOnClickDirective,
        ShowIdentificationComponent,
        ShowModalitiesComponent,
        TranslatablePipe,
        AlertComponent,
        InputMeterComponent,
        OnlyDecimalDirective,
        FloatNumberPipe,
        FormErrorComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
