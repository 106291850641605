import { Globals } from './../../../../../_configs/globals';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { LocationService } from 'src/app/concepts/location/services/location.service';
import { CountryState } from 'src/app/concepts/organization/model/state.model';
import { AbstractVenueFormComponent } from '../venue-abstract-form/venue-abstract-form.component';
import { Country, SimpleCountry } from 'src/app/concepts/location/models/location.model';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-venue-coordinate-form',
    templateUrl: './venue-coordinate-form.component.html'
})
export class VenueCoordinateFormComponent extends AbstractVenueFormComponent implements OnInit {
    // TODO: Use Abstract like venue-coordinate-form

    /**
     * An array of countries.
     * @type {SimpleCountry[]}
     * @public
     */
    public countries: SimpleCountry[];

    /**
     * An array of states.
     * @type {Array<CountryState>}
     * @public
     */
    public states$: Observable<CountryState[]>;

    /**
     * A boolean flag indicating whether Canada has been selected.
     * @type {boolean}
     * @public
     */
    public canadaSelected = false;

    /**
     * The index of the currently selected country.
     * @type {number}
     * @public
     */
    public selectedCountry: number;

    /**
     * The language currently being used, obtained from the translate service.
     * @type {string}
     * @public
     */
    public lang = this.translate.currentLang;

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        public locationService: LocationService,
        protected globals: Globals,
        protected accountService: AccountService
    ) {
        super(accountService, globals);
    }

    ngOnInit(): void {
        this.initForm();
        this.getCountries().subscribe();
        this.onChanges();
    }

    /**
     * Initializes the form, retrieves a list of states if the country is Canada, and shows the State (Province) select.
     * If the country is not Canada, the State (Province) select is hidden.
     * The form is then initialized with the coordinates form group based on the selected language.
     * @protected
     * @returns {void}
     */
    protected initForm(): void {
        if (this.venue.address.countryId === 124) {
            // if Country is Canada
            this.getStates(); // get List of States
            this.canadaSelected = true; // show State (Province) select
        } else {
            this.canadaSelected = false;
        }
        // init du formulaire
        this.formGroup = this.venue.getCoordFormGroup(this.fb, this.lang, this.isSceneProAdmin, this.globals);
    }

    /**
     * Retrieves a list of countries from the location service and sorts them alphabetically by their translated names.
     * @returns {Observable<any>} An observable that emits the list of sorted countries.
     */
    getCountries(): Observable<Country[]> {
        return this.locationService.getCountries().do((data: Country[]) => {
            // Tri des pays par ordre alphabetique traduit
            this.countries = data
                .map((country: Country) => ({
                    id: country.id,
                    name: this.translate.instant('COUNTRIES.' + country.id) as string
                }))
                .sort((country1, country2) => country1.name.localeCompare(country2.name, this.lang)) as SimpleCountry[];
        });
    }

    /**
     * Retrieves a list of states from the location service and filters the list to include only the Canadian provinces or territories.
     * @returns {void}
     */
    getStates(): void {
        this.states$ = this.locationService.getStates().pipe(
            map((data: CountryState[]): CountryState[] => data.filter((e) => this.locationService.isSelectedCountryCanada(e.countryId))));
    }

    /**
     * Subscribes to changes in the country form control and updates the selected country.
     * If the selected country is Canada, the State (Province) select is shown and the list of Canadian provinces or territories is retrieved.
     * If the selected country is not Canada, the State (Province) select is hidden.
     * @returns {void}
     */
    onChanges(): void {
        this.formGroup.get('country').valueChanges.subscribe((val) => {
            if (val !== this.selectedCountry) {
                this.selectedCountry = val;
                if (this.selectedCountry === 124) {
                    // if canada
                    this.canadaSelected = true;
                    this.getStates();
                } else {
                    this.canadaSelected = false;
                }
            }
        });
    }

    /**
     * Sets the properties of the venue value based on the form group values and submits the form.
     * @param {string} [param] Optional parameter to pass to the submit function.
     * @returns {void}
     */
    doSubmit(param?: string): void {
        this.venueValue.setTranslatedProperty(this.translate.currentLang, 'name', this.formGroup.value.name);
        this.venueValue.address.address1 = this.formGroup.value.address1;
        this.venueValue.address.city = this.formGroup.value.city;
        this.venueValue.address.zipCode = this.formGroup.value.zipcode;
        this.venueValue.address.otherState = this.formGroup.value.province;
        this.venueValue.address.countryId = this.formGroup.value.country;
        this.venueValue.phone = this.formGroup.value.phone;
        this.venueValue.phonePostNumber = this.formGroup.value.phonePostNumber;
        this.venueValue.email = this.formGroup.value.email;
        this.venueValue.website = this.formGroup.value.website;
        this.venueValue.artsdataId = this.formGroup.value.artsdataId;
        this.venueValue.wikidataId = this.formGroup.value.wikidataId;

        super.doSubmit(param);
    }

    public get isSceneProAdmin(): boolean {
        return this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID;
    }

    public shouldDisplayFormControlError = (fomControlName: string, error: string): boolean => {
        const formControl = this.formGroup.get(fomControlName) as FormControl;
        // not required
        if (!formControl.value) {
            return false;
        }
        if (!formControl.touched || !formControl.dirty) {
            return false;
        }
        if (!formControl.errors || !formControl.errors[ error ]) {
            return false;
        }
        return true;
    };
}
