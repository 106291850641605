import { Globals } from './../../../../../_configs/globals';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { Organization } from '../../../model/organization.model';
import { NetworkService } from '../../../services/network.service';
import { AccountService } from './../../../../account/services/account.service';
import { NetworkInfos, OrganizationLegalStatus } from './../../../model/finance.model';
import { takeUntil } from 'rxjs/operators';
import { NumberUtils } from '@app/shared/utils/number.utils';

@Component({
    selector: 'app-organization-network-infos',
    templateUrl: './organization-network-infos.component.html',
    styleUrls: [ './organization-network-infos.component.scss' ],
})
export class OrganizationNetworkInfosComponent implements OnInit, OnDestroy {
    public currentOrgaId: number;
    public isLoading = false;
    public legalStatusOptions: { label: string; value: number }[] = Object.keys(OrganizationLegalStatus)
        .filter((key) => !Number(key))
        .map((key, index) => {
            return {
                label: this.translate.instant('FORM.' + key),
                value: index + 1,
            };
        });
    public networkInfos: NetworkInfos;
    public networkInfosForm: FormGroup;
    @Input()
    public organization: Organization;
    private destroyed: Subject<void>;

    constructor(
        private networkService: NetworkService,
        private translate: TranslateService,
        private accountService: AccountService,
        private notification: RideauNotificationService,
        private globals: Globals
    ) { }

    public getNetworkInfos(): void {
        this.networkService
            .getNetworkInfosForOgr(this.organization.id, this.globals.RIDEAU_ORGID)
            .subscribe((res) => {
                this.networkInfos = res;
                this.networkInfosForm = res.getNetworkInfosForm();
                if (this.currentOrgaId !== this.globals.SCENE_PRO_ORGID) {
                    this.networkInfosForm.get('responsibleMember').disable();
                }
            });
    }

    public ngOnDestroy(): void {
        if (this.destroyed) {
            this.destroyed.next();
            this.destroyed.complete();
        }
    }

    public ngOnInit(): void {
        this.currentOrgaId = this.accountService.getCurrentCtxOrganizationId();
        if (this.organization.id !== this.globals.SCENE_PRO_ORGID) {
            this.getNetworkInfos();
        }

        this.accountService.currentOrganizationChange
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                this.currentOrgaId = this.accountService.getCurrentCtxOrganizationId();
                if (this.currentOrgaId !== this.globals.SCENE_PRO_ORGID) {
                    this.networkInfosForm.get('responsibleMember').disable();
                } else {
                    this.networkInfosForm.get('responsibleMember').enable();
                }
            }
            );
    }

    public saveFinance(): void {
        this.networkInfos.responsibleMember = this.networkInfosForm.value.responsibleMember;
        this.networkInfos.legalStatusId = this.networkInfosForm.value.legalStatusId;
        this.networkInfos.hasCALQSupport = this.networkInfosForm.value
            .hasCALQSupport
            ? 1
            : 0;
        this.networkInfos.annualAmountCALQ = NumberUtils.formattedToNumber(
            this.networkInfosForm.value.annualAmountCALQ
        );
        this.networkInfos.hasFederalSupport = this.networkInfosForm.value
            .hasFederalSupport
            ? 1
            : 0;
        this.networkInfos.hasMunicipalSupport = this.networkInfosForm.value
            .hasMunicipalSupport
            ? 1
            : 0;
        this.networkInfos.broadcastEmployeesNumber = this.networkInfosForm.value.broadcastEmployeesNumber;
        this.networkInfos.financialYearEnd = this.networkInfosForm.value.financialYearEnd;
        this.networkInfos.ticketsRevenue = NumberUtils.formattedToNumber(
            this.networkInfosForm.value.ticketsRevenue
        );
        this.networkInfos.artistsFees = NumberUtils.formattedToNumber(
            this.networkInfosForm.value.artistsFees
        );
        this.networkInfos.soldTicketsNumber = NumberUtils.formattedToNumber(
            this.networkInfosForm.value.soldTicketsNumber
        );

        this.isLoading = true;

        const body = new NetworkInfos(this.networkInfos);
        this.networkService.updateNetworkInfosOrg(body).subscribe(() => {
            this.notification.success(this.translate.instant('FORM.SAUVEGARDEE'));
            this.isLoading = false;
        });
    }
}
