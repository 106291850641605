import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router,  } from '@angular/router';
import { Globals } from '@app/_configs/globals';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { ErrorsHandler } from 'src/app/shared/error-handling/errors-handler';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
  providers: [ErrorsHandler]
})
export class EmailConfirmationComponent implements OnInit, AfterViewInit {

  showTemplateName: 'confirming'| 'confirming-error' | 'confirm'  =  'confirming'

  isLoading = false;

  failureMessage = 'FAILED_CONFIRM_EMAIL';

  connectionForm: FormGroup;

  private userId: string;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: Router,
    private errorHandlerService: ErrorsHandler,
    private rideauNotification: RideauNotificationService,
    private globals: Globals
  ) { }


  ngOnInit() {

    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');

    this.initConnectionForm()

  }

  ngAfterViewInit(): void {
    const isForResetEmail = this.activatedRoute.snapshot.queryParamMap.get('confirmResetEmail');
    if(isForResetEmail){
      this.sendResetConfirmation()
    } else {
      this.sendConfirmation()
    }

  }

  async login(event){

    if(this.connectionForm.valid){
      const {email, password} = this.connectionForm.value;

      this.isLoading = true;

      await this.authService.loginV2({email, password}).toPromise().catch(err => {
        this.errorHandlerService.handleError(err)
      });

      this.isLoading = false;

      this.route.navigate(['...','dashboard'])
    } else {
      this.connectionForm.markAllAsTouched();

    }
  }

  sendResetConfirmation(){
    this.authService.confirmEmailChangeRequest(this.userId).toPromise()
    .then( _ => {
      this.rideauNotification.success(this.translate.instant('NEW-EMAIL-REQUEST-CONFIRM'))
      this.showTemplateName = 'confirm'
    })
    .catch(err => {
      this.failureMessage = 'FORM.NEW-EMAIL-REQUEST-FAILURE';
      this.showTemplateName = 'confirming-error'
    })
  }

  private sendConfirmation(){
    this.authService.sendEmailConfirmation(this.userId)
    .toPromise()
    .then( _ => {
     this.showTemplateName = 'confirm';
    }).catch( error => {
      this.failureMessage = 'FAILED_CONFIRM_EMAIL'
      this.showTemplateName = 'confirming-error'
    } )
  }

  private initConnectionForm(){
    this.connectionForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.globals.emailRegex), Validators.minLength(3)]),
      password: new FormControl('', [Validators.required, Validators.minLength(3)]),
    })
  }
}
