import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

  @Input() showModal: boolean;
  @Input() validateModalFooterText: string;
  @Input() cancelModalFooterText: string;
  @Input() modalTitle: string;
  @Input() modalContent: string;
  @Input() type: string;
  @Output() handleValidateModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleCancelModal: EventEmitter<any> = new EventEmitter<any>();

  onCancelModal(){
    this.handleCancelModal.emit();
  }

  onValidateModal(){
    this.handleValidateModal.emit();
  }
}
