import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BillingItem } from 'src/app/concepts/membership/model/billing-item.model';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Globals } from 'src/app/_configs/globals';
import { BillInfos } from '../model/bill-infos.model';

@Component({
  selector: 'app-unpaid-bill',
  templateUrl: './unpaid-bill.component.html',
  styleUrls: ['./unpaid-bill.component.scss'],
})
export class UnpaidBillComponent implements OnInit {
  organization: Organization;
  products: any[];
  billInfos: BillInfos;
  billItem: BillingItem;

  constructor(
    private organizationService: OrganizationService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private global: Globals
  ) {}

  ngOnInit(): void {
    const organizationId = Number(this.route.snapshot.params['organizationId']);
    const billId = Number(this.route.snapshot.params['billId']);

    this.getOrga(organizationId);
    this.getBills(billId, organizationId);
  }

  getOrga(organizationId: number): void {
    this.organizationService
      .getOrganization(organizationId)
      .subscribe((res) => (this.organization = res));
  }

  getBills(billId: number, organizationId: number): void {
    this.organizationService
      .getOrganisationBills(organizationId)
      .subscribe((res) => {
        this.billItem = res.find((bill) => bill.id === billId);
        this.setBillInfos();
      });
  }

  setBillInfos(): void {
    this.billInfos = new BillInfos();
    this.billInfos.subtotal = this.billItem.customFee ? this.billItem.customFee : this.billItem.fee;

    const taxTps =
      parseInt(
        Math.round(this.billInfos.subtotal * this.global.taxes.TPS * 100).toString()
      ) / 100;
    const taxTvq =
      parseInt(
        Math.round(this.billInfos.subtotal * this.global.taxes.TVQ * 100).toString()
      ) / 100;

    this.billInfos.taxTps = taxTps.toFixed(2);
    this.billInfos.taxTvq = taxTvq.toFixed(2);
    this.billInfos.total = this.billItem.totalFee;
    this.billInfos.receivedAmount = 0;
    this.billInfos.amountToPay = this.billItem.totalFee;
    this.billInfos.date = this.billItem.sentAt;
    this.billInfos.numero = '';

    this.products = [
      {
        name: this.translate.instant('COTISATION-ANNUELLE'),
        price: this.billInfos.subtotal,
        quantity: 1
      },
    ];
  }
}
