import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[alternative]',
  host: {
    '[src]':'src'
  }
})
export class AlternativeAssetImageDirective {
  @Input() src: string;
  @Input() alternative: string = '';

  constructor() { }

  @HostListener('error')
  changeSrc(){
    this.src = this.alternative
  }
}
