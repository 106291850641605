<app-page-title title="{{ 'OFFRES-SPECTACLES' | translate }}" [isMainPage]="true">
</app-page-title>


<div class="container">
  <app-item-list [itemList]="itemList"
                 [filterList]="leftSideFilters"
                 [sortList]="sorts"
                 [pagination]="pagination"
                 [listItemsType]="listType"
                 [isLoading]="isLoading"
                 [useFilters]="true"
                 [useViewSwitcher]="true"
                 [useSearchBar]="true"
                 [searchBarPlaceHolder]="'RECHERCHER-SHOWS' | translate"
                 [useSorting]="false"
                 [usePagination]="true"
                 elementsLabel="{{'OFFRES-SPECTACLES' | translate | lowercase}}"
                 (filterChange)="onFilterChange($event)"
                 (searchChange)="onSearchChange($event)"
                 (sortChange)="onSortChange($event)"
                 (pageChange)="onPageChange($event)"
                 [isDownloadFavorites]="isFavorites"
                 (exportFavorites)="exportFavoriteShows()"
                 searchSessionStorageSuffix="show-list">

    <app-show-advanced-search #advancedSearch advanced-search searchSessionStorageSuffix="show-list"
                              (filtersChange)="onAdvancedFilterChange($event)">

    </app-show-advanced-search>
  </app-item-list>
</div>
