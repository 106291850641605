<form id="profile-form" nz-form [formGroup]="rideauForm">
    <ng-container *ngIf="!reloading">
        <div nz-row class="my-3">
            <div nz-col nzSpan="24">
                <app-item-upload-repeater title="FORM.MATERIEL-APPUI-SUPP" subtitle="FORM.MATERIEL-APPUI-EXPLICATIONS"
                    btnText="FORM.MATERIEL-AJOUTER" [isVitrine]="true" [name]="'vitrineFiles'"
                    [action]="presentationFileEndpoint" [shouldDisplayImage]="false"
                    [acceptedFileTypes]="acceptedFileTypes" [(items)]="materielSupList"
                    [maxFileSize]="maxFileSize"></app-item-upload-repeater>
            </div>
        </div>
    </ng-container>

    <div nz-row>
        <div nz-col nzSpan="19">
            <strong>{{ 'FORM.LIENS-DEMO-TITLE' | translate}}</strong>
            <p>{{ 'FORM.LIENS-DEMO-DESCRIPTION' | translate}}</p>
            <!--  Main Link -->
            <div nz-row nzGutter="16">
                <div nz-col nzSpan="16">
                    <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="demoLink">{{ 'FORM.LIEN-DEMO-1' | translate}}</label>
                            <input type="url" nz-input id="demoLink" formControlName="mediaUrl">

                            <nz-form-explain class="has-error"
                                *ngIf="rideauForm.get('mediaUrl').touched && rideauForm.get('mediaUrl').errors">
                                {{'CHAMP-REQUIS-SIMPLE'|translate}}
                            </nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col nzSpan="8">
                    <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="mediaPassword">{{ 'FORM.MOT-DE-PASSE-IF-PRIVATE' |
                                translate}}</label>
                            <input type="url" nz-input id="mediaPassword" formControlName="mediaPassword">
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <!--  additional Link -->
            <div nz-row nzGutter="16">
                <div nz-col nzSpan="16">
                    <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="otherDemoLink">{{ 'FORM.LIEN-DEMO-2' | translate}}</label>
                            <input nz-input id="otherDemoLink" formControlName="additionalUrl">
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div nz-col nzSpan="8">
                    <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="additionalMediaPassword">{{ 'FORM.MOT-DE-PASSE-IF-PRIVATE' |
                                translate}}</label>
                            <input nz-input id="additionalMediaPassword" formControlName="additionalMediaPassword">
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

        </div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="24">
            <nz-form-control>
                <label nz-checkbox formControlName="isYoungAudience" class="pt-2 pb-3">
                    {{'VITRINE-JURY-JEUNE-PUBLIC' | translate}}
                </label>
            </nz-form-control>
            <nz-form-control>
                <label nz-checkbox formControlName="isExpress">
                    {{ 'VITRINE_TYPE.PRESENTATION_EXPRESS' | translate }}
                </label>
            </nz-form-control>
        </div>
    </div>
</form>