import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './concepts/account/services/auth/auth.service';
import { LocalizeRouterService } from 'localize-router';
import { PxUser } from './concepts/account/model/project-x-user';
import { MyMonitoringService } from './monitoring/monitoring.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { tagPageView } from 'src/app/data-layer';
import { HttpClient } from '@angular/common/http';
import { ResolverListnerService } from './shared/services/resolver-listner.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Scène Pro';
    lang: string;
    isReady = false;
    idToken: string | null;
    userId: number;
    user: PxUser | null;

    currentLang = this.translate.currentLang;

    hideModal: boolean;
    public hasAcceptedLegal: number;

    public legalUrl: string;
    public privacyUrl: string;

    sub: Subscription;

    isResolving = false;

    constructor(
        public translate: TranslateService,
        private authService: AuthService,
        private localizeRouter: LocalizeRouterService,
        private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute,
        private monitoringService: MyMonitoringService,
        private httpClient: HttpClient,
        private resolverListnerService: ResolverListnerService
    ) {
        translate.setDefaultLang('fr');
        translate.use('fr');
        localizeRouter.init();
        monitoringService.logEvent('hello from frontend monitoring service');
    }

    ngOnInit(): void {
        this.resolverListnerService.initListner();

        this.resolverListnerService.isResolvingObservable$.subscribe((isResolving) => {
            this.isResolving = isResolving;
        });

        this.legalUrl = '/' + this.currentLang + '/' + this.translate.instant('ROUTES.legal');
        this.privacyUrl = '/' + this.currentLang + '/' + this.translate.instant('ROUTES.privacy');

        this.routeNavigationListener();

        this.authService.userObservable$.subscribe((user) => {
            this.user = user;
            if (user) {
                this.hasAcceptedLegal = this.user.hasAcceptedLegal;
            }
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    acceptLegalTerms(): void {
        this.accountService.acceptLegalTerms(+this.user.id).subscribe((data) => {
            this.hasAcceptedLegal = 1;
        });
    }

    routeNavigationListener(): void {
        this.sub = this.router.events
            .pipe(
                // Get the final step of the navigation
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe((event: NavigationEnd) => {
                const unWantedPage = ['login', 'inscription', 'forgot-password', 'reset-password', 'confirm-email'];
                const includeUnwantedPage = event.urlAfterRedirects.split('/').find((urlSegment) => unWantedPage.includes(urlSegment));

                if (includeUnwantedPage === undefined) {
                    tagPageView(event.urlAfterRedirects, this.httpClient);
                }

                if (this.hasAcceptedLegal != undefined) {
                    this.hideModal = event.url == this.legalUrl || event.url == this.privacyUrl;
                }
            });
    }
}
