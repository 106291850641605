import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[AddClassOnClick]'
})
export class AddClassOnClickDirective implements OnInit {

  @Input() classToAdd : string;
  @Input() targetClass: string;

  private targetElement: Element;
  private isActive = false;


  constructor(private element: ElementRef) {
  }

  ngOnInit(){
    this.initTarget();
  }

  @HostListener('click') onClick(){
    this.isActive = !this.isActive

    if(this.isActive){
      this.targetElement.classList.add(this.classToAdd);
    } else {
      this.targetElement.classList.remove(this.classToAdd);
    }

  }

  private initTarget(){
    this.targetElement = this.element.nativeElement.querySelector(this.targetClass);
  }

}
