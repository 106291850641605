import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-phone-extension',
  templateUrl: './input-phone-extension.component.html',
  styleUrls: ['./input-phone-extension.component.scss'],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputPhoneExtensionComponent
    }
  ]
})
export class InputPhoneExtensionComponent implements ControlValueAccessor  {

  extensionValue: string = '';
  value = ''
  touched = false;

  disabled = false;

  constructor() { }


  onChange = (value: string) => {}

  onTouched = () => {}


  writeValue(value: string): void {
    this.value = value;
    this.extensionValue = value;
    this.onChange(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched): void {
   this.onTouched = onTouched
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


}
