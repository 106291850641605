import { Routes } from '@angular/router';
import { PaidBillComponent } from 'src/app/shared/components/prints/paid-bill/paid-bill.component';
import { AccountComponent } from './components/account.component';

export const ProfileRoutes: Routes = [
  {
    path: '',
    component: AccountComponent,
    data: { openedTab: 'profile' },
  },
  {
    path: 'info',
    component: AccountComponent,
    data: { openedTab: 'profile' },
  },
  {
    path: 'password',
    component: AccountComponent,
    data: { openedTab: 'password' },
  },
  {
    path: 'organizations',
    component: AccountComponent,
    data: { openedTab: 'organizations' },
  },
  {
    path: 'artists-profiles',
    component: AccountComponent,
    data: { openedTab: 'artists-profiles' },
  },
  {
    path: 'account',
    component: AccountComponent,
    data: { openedTab: 'account' },
  },
  {
    path: 'billing',
    component: AccountComponent,
    data: { openedTab: 'billing' },
  },
  {
    path: 'bill/:userId/:transactionId',
    component: PaidBillComponent,
  },
];
