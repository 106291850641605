<nav class="main-navigation" *ngIf="user" [ngClass]="{'dashboardTheme': isDashboardTheme}">
    <ul class="main-navigation__list ">
        <li class="main-navigation__list-item" style="margin-top: 15px;">
            <a [routerLink]="'/dashboard' | localize" routerLinkActive="active">
                <img [src]="isDashboardTheme ? 'assets/images/scene_logo_dashboard.svg' : 'assets/images/scene_logo.svg'"
                    alt="" />
            </a>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/dashboard' | localize" routerLinkActive="active">{{
                'TABLEAU-BORD' | translate }}</a>
            <span *ngIf="notificationCount > 0" class="notificationCount">{{notificationCount}}</span>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/pro-meeting' | localize" routerLinkActive="active">{{
                'ESPACES-PRO' | translate }}</a>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/shows-offers' | localize" routerLinkActive="active">{{
                'OFFRES-SPECTACLES' | translate }}</a>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/repertory' | localize" routerLinkActive="active">{{
                'REPERTOIRE'
                | translate }}</a>
        </li>

        <li class="main-navigation__list-item" *ngIf="isSceneProAdmin$ | async">
            <nz-dropdown [nzPlacement]="'bottomRight'" [nzTrigger]="'click'">
                <a class="main-navigation__link" nz-dropdown>
                    <span class="mr-2">{{ 'ADMIN' | translate }}</span>
                    <i class="icon-chevron-down align-middle"></i>
                </a>
                <ul nz-menu class="p-3">
                    <li nz-menu-item>
                        <a [routerLink]="'/admin/etiquettes' | localize" routerLinkActive="active">
                            {{ 'ETIQUETTES' | translate }}
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="'/admin/product' | localize" routerLinkActive="active">
                            {{ 'PRODUITS' | translate }}
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="'/admin/publication' | localize" routerLinkActive="active">
                            {{ 'PUBLICATIONS' | translate }}
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="'/admin/promotion' | localize" routerLinkActive="active">
                            {{ 'PROMOTIONS' | translate }}
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="'/admin/membership/billing' | localize" routerLinkActive="active">
                            {{ 'COTISATIONS' | translate }}
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="['/admin','reports'] | localize" routerLinkActive="active">
                            {{ 'RAPPORTS' | translate }}
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="'/admin/users' | localize" routerLinkActive="active">
                            {{ 'USERS' | translate }}
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a [routerLink]="'/admin/tour' | localize" routerLinkActive="active">
                            {{ 'DEPOT-RESEAUX' | translate }}
                        </a>
                    </li>
                </ul>
            </nz-dropdown>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/help' | localize" routerLinkActive="active">
                <div [ngClass]="['help-icon', isDashboardTheme ? 'help-icon--white': '']"></div>
            </a>
        </li>
    </ul>

    <ul class="main-navigation__list ">
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="['/shopping-cart'] | localize" routerLinkActive="active">
                <nz-badge [nzCount]="cartCount" class="cartCounter">
                    <i nz-icon aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path [attr.fill]="isDashboardTheme ? '#FFFFFF' : '#1C1C1B'" fill-rule="nonzero"
                                d="M6.792 11L5.336 4.813A3 3 0 0 0 2.416 2.5H1.5a1 1 0 0 0 0 2h.916a1 1 0 0 1 .973.771l1.637 6.958A1 1 0 0 0 6 13h11a1 1 0 0 0 .977-.79l1.5-7A1 1 0 0 0 18.5 4H8a1 1 0 0 0 0 2h9.263l-1.072 5h-9.4zm8.708 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-8 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                        </svg>
                    </i>
                </nz-badge>
            </a>
        </li>
        <li class="main-navigation__list-item" *appPermission="1; organizationType: 1">
            <a class="main-navigation__link" [routerLink]="['/account/my-shows'] | localize"
                routerLinkActive="active">{{
                'MES-SPECTACLES' | translate }}</a>
        </li>
        <li class="main-navigation__list-item" *ngIf="hasValidOrganizations$ | async">
            <a class="main-navigation__link" [routerLink]="'/programmations' | localize" routerLinkActive="active">{{
                'MES-CALENDRIERS' | translate }}</a>
        </li>

        <div class="profile-account">
            <!-- Photo de profil -->

            <a nz-dropdown class="profile-account-btn" [nzDropdownMenu]="accountDropDown" [nzTrigger]="'click'"
                [nzPlacement]="'bottomRight'">
                <img [src]="user.avatar || './assets/images/placeholder-membre.svg'" alt="avatar" />
                <div class="userInfos">
                    <span class="ml-2" [ngClass]="{'clr-white': isDashboardTheme}">{{
                        user.firstName
                        }}</span>
                    <span class="ml-2 userOrganization" [ngClass]="{'clr-white70': isDashboardTheme}">{{
                        selectedUserOrganizationName$ | async }}</span>
                </div>
                <i class="icon-chevron-down align-middle" [ngClass]="{'clr-white': isDashboardTheme}"
                    aria-hidden="true"></i>
            </a>
            <nz-dropdown-menu #accountDropDown="nzDropdownMenu">
                <ul nz-menu class="p-3">
                    <li class="ml-2">
                        <span class="main-navigation__subtitle">{{
                            'CONNECTE-EN-TANT-QUE' | translate
                            }}</span>
                    </li>
                    <li nz-menu-item *ngFor="let orga of userOrganizations$ | async">
                        <a (click)="changectxOrga(orga.id)">
                            <i class="fa fa-check" style="position: absolute;
                        left: 10px;
                        padding-top: 4px;" *ngIf="(selectedUserOrganizationId$ | async) === orga.id"></i>
                            {{ orga.getTranslatedProperty(lang, 'name') }}
                        </a>
                    </li>
                    <li nz-menu-divider></li>
                    <li nz-menu-item>
                        <a [routerLink]="['/profile/info'] | localize"><i nz-icon aria-hidden="true" nzType="user"
                                nzTheme="outline" class="mr-1"></i>
                            {{ 'MON-COMPTE' | translate }}</a>
                    </li>
                    <li nz-menu-item *ngIf="(selectedUserOrganizationId$ | async) != -1"
                        (click)="navigateToOrganizationEdition()">
                        <a><i nz-icon aria-hidden="true" nzType="setting" nzTheme="outline" class="mr-1"></i>
                            {{ 'REGLAGES' | translate }}&nbsp;{{ selectedUserOrganizationName$ | async }}</a>
                    </li>
                    <li nz-menu-item (click)="onLogout()">
                        <a><i nz-icon aria-hidden="true" nzType="logout" nzTheme="outline" class="mr-1"></i>
                            {{ 'DECONNEXION' | translate }}</a>
                    </li>
                </ul>
            </nz-dropdown-menu>
        </div>
    </ul>
</nav>
