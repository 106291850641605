import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { Pagination, SelectedFilter } from '../../../shared/model/list-item.model';
import { MainService } from '../../../shared/services/main.services';
import { Globals } from '../../../_configs/globals';
import { AccountService } from '../../account/services/account.service';
import { ActivityVitrine } from '../../vitrine/model/vitrine.model';
import { Activity } from '../model/activity.model';

const ORG_ID_QS_PREFIX = '?organizationId=';
export interface ActivityCapacity {
    capacity: number;
    remainingCapacity: number;
}
@Injectable({
    providedIn: 'root'
})
export class ActivityService extends MainService implements OnDestroy {
    public remainingCapacity$: Observable<ActivityCapacity>;
    public hasRemainingCapacity$: Observable<boolean>;
    private remainingCapacity: BehaviorSubject<ActivityCapacity> = new BehaviorSubject<ActivityCapacity>(null);
    private destroyed: Subject<void> = new Subject<void>();
    constructor(
        private localizeRouter: LocalizeRouterService,
        private accountService: AccountService,
        private translate: TranslateService,
        private authService: AuthService,
        protected httpClient: HttpClient,
        protected globals: Globals,
        private router: Router
    ) {
        super(httpClient, globals);
        this.remainingCapacity$ = this.remainingCapacity.asObservable().pipe(takeUntil(this.destroyed));
        this.hasRemainingCapacity$ = this.remainingCapacity$.pipe(filter(x => x !== null), map(({ capacity, remainingCapacity }: ActivityCapacity) => remainingCapacity === null || remainingCapacity > 0));
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
        this.remainingCapacity.next(undefined);
        this.remainingCapacity.complete();
    }

    public updateRemainingCapacity = ({ capacity, remainingCapacity }: ActivityCapacity) => this.remainingCapacity.next({
        capacity,
        remainingCapacity
    });

    getActivitiesForMeeting(meetingId: number, filters?: SelectedFilter[], pagination?: Pagination, searchText?: string): Observable<Activity[]> {
        let endpoint = this.uri + this.globals.endpoints.meeting.getActivitiesEndpoint(meetingId);

        if (searchText || filters || pagination) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += searchText || pagination ? '&' : '';
        }

        if (searchText) {
            endpoint += `search=${encodeURIComponent(searchText)}`;
            endpoint += pagination ? '&' : '';
        }

        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) {
                    pagination.total = data[ 'total' ];
                }
                const result = data[ 'activities' ].map((activity) => new Activity(activity));
                return result;
            })
        );
    }

    getActivityVitrines(activityId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.activity.getVitrinesEndpoint(activityId);
        return this.httpClient.get(endpoint);
    }

    getActivityById(activityId: number): Observable<Activity> {
        const endpoint = this.uri + this.globals.endpoints.activity.getActivityEndpoint(activityId);
        return this.httpClient.get(endpoint).map((res) => new Activity(res));
    }

    modifyActivity(activity: Activity): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.activity.main;
        return this.httpClient.put(endpoint, this.cleanUpNullValues(activity));
    }

    removeActivity(activityId, orgaId): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.activity.getDeleteEndpoint(activityId, orgaId);
        return this.httpClient.delete(endpoint);
    }

    createActivityAndRedirectToForm(meetingId: number, lang: string) {
        const endpoint = this.uri + this.globals.endpoints.activity.main;
        const activity = new Activity({
            meetingId: meetingId,
            isAvailable: 1,
            organizationId: this.accountService.getCurrentCtxOrganizationId(),
            date: new Date(),
            hourFrom: new Date(),
            hourTo: new Date(),
            trans: [
                {
                    langId: lang,
                    title: this.translate.instant('NOUVELLE-ACTIVITE')
                }
            ]
        });
        return this.httpClient.post(endpoint, activity).subscribe((res) => {
            const url = this.localizeRouter.translateRoute(`/activity/${activity.meetingId}/${res[ 'activity' ][ 'id' ]}/modify`);
            this.router.navigate([ url ]);
        });
    }

    saveParticipantsActivity(meetingId: number, activityMatrix: any): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.activity.saveParticipantsActivityEndpoint(meetingId);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        if (orgId !== this.globals.NO_ORGA) {
            endpoint += ORG_ID_QS_PREFIX + orgId;
        }
        return this.httpClient.put(endpoint, activityMatrix);
    }

    getParticipantsForActivity(meetingId: number, activityId: number, adminView: boolean, pagination?: Pagination, searchText?: string): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.activity.getParticipantsForActivityEndpoint(meetingId, activityId);

        const orgId = this.accountService.getCurrentCtxOrganizationId();
        if (!adminView && orgId !== this.globals.NO_ORGA) {
            endpoint += ORG_ID_QS_PREFIX + orgId;
            endpoint += pagination || searchText ? '&' : '';
        } else if (orgId === this.globals.NO_ORGA) {
            endpoint += '?userId=' + this.authService.User.id;
            endpoint += pagination || searchText ? '&' : '';
        } else if (pagination || searchText) {
            endpoint += '?';
        }

        if (searchText) {
            endpoint += `search=${encodeURIComponent(searchText)}`;
            endpoint += pagination ? '&' : '';
        }

        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) pagination.total = data[ 'total' ];
                return data[ 'participants' ];
            })
        );
    }

    addParticipantToActivity(meetingId: number, participantId: number, activityId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.activity.getMeetingPArticipantActivityEndpoint(meetingId, participantId, activityId);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        if (orgId !== this.globals.NO_ORGA) {
            endpoint += ORG_ID_QS_PREFIX + orgId;
        }
        return this.httpClient.post(endpoint, null);
    }
    removeParticipantFromActivity(meetingId: number, participantId: number, activityId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.activity.getMeetingPArticipantActivityEndpoint(meetingId, participantId, activityId);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        if (orgId !== this.globals.NO_ORGA) {
            endpoint += ORG_ID_QS_PREFIX + orgId;
        }
        return this.httpClient.delete(endpoint);
    }

    addVitrineToActivity(vitrine: ActivityVitrine): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.activity.vitrine;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();
        // console.log(endpoint);
        return this.httpClient.post(endpoint, vitrine);
    }

    updateActivityVitrine(vitrineId: number, activityVitrine: any): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.activity.vitrine + '/' + vitrineId;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();
        return this.httpClient.put(endpoint, activityVitrine);
    }

    removeVitrineFromActivity(vitrineId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.activity.vitrine + '/' + vitrineId;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();
        return this.httpClient.delete(endpoint);
    }
}
