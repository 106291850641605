<div class="row form-filter__container p-3">
  <div class="col-md-12">
    <h3>
      {{ 'EFFECTUER-LE-PAIEMENT' | translate}}
    </h3>
  </div>
</div>
<div *ngIf="isReady"
     class="row px-3 pb-3">
  <!-- Si pas de carte -->
  <ng-container *ngIf="creditCards.length === 0; else hasCard">
    <div class="col-md-12">
      <span>{{'PAS-DE-CARTE-BANCAIRE' | translate}}</span>
      <a style="text-decoration: underline;"
         *ngIf="!isUserWithNoOrg"
         [routerLink]="'/organization/'+ currentOrgId +'/modify/billing' | localize">{{'AJOUTER-UNE-CARTE' | translate}}</a>
      <a style="text-decoration: underline;"
         *ngIf="isUserWithNoOrg"
         [routerLink]="'/profil/facturation' | localize">{{'AJOUTER-UNE-CARTE' | translate}}</a>
    </div>
  </ng-container>

  <ng-template #hasCard>
    <!-- Si plusieurs cartes -->
    <div class="col-md-5"
         *ngIf="creditCards.length > 1;else oneCard">
      <label for="selectedCard">
        {{ 'CARTES-ENREGISTREES' | translate}}
      </label>
      <select name="selectedCard"
              [(ngModel)]="selectedCard"
              id="selectedCard">
        <option *ngFor="let creditCard of creditCards"
                [value]="creditCard.id">
          ************{{creditCard.ccNumber}}
        </option>
      </select>
    </div>
    <!-- Si une seule carte -->
    <ng-template #oneCard>
      <div class="col-md-5">
        <label>
          {{ 'CARTES-ENREGISTREE' | translate}}
        </label>
        <br>
        <div class="mt-3">************{{creditCards[0].ccNumber}}</div>
      </div>
    </ng-template>

    <div class="col-md-3">
      <label for="cvvNumber">
        {{ 'CVV-NUMBER' | translate}}
      </label>
      <input type="text"
             id="cvvNumber"
             [(ngModel)]="cvvNumber"
             maxlength="4"
             placeholder="CVV">
    </div>
    <div class="col-md-4 text-right mt-4">
      <button class="btn"
              nz-button
              [disabled]="!cvvNumber || !selectedCard || processing"
              type="submit"
              (click)="sendPaymentInformations()">
        {{ 'EFFECTUER-LE-PAIEMENT' | translate}}
      </button>
    </div>
  </ng-template>

</div>