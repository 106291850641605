import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { MyMonitoringService } from './../../monitoring/monitoring.service';
import { HttpErrorResponse } from '@angular/common/http';
// errors-handler.ts
import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    constructor(
        private rideauNotificationService: RideauNotificationService,
        private translateService: TranslateService,
        private monitoringService: MyMonitoringService
    ) {}

    handleError(error: Error | HttpErrorResponse): void {
        if (!this.rideauNotificationService) {
            return;
        }
        this.monitoringService.logError(error);

        // Server error happened
        if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
                // No Internet connection
                this.rideauNotificationService.error(this.translateService.instant('ERRORS.OFFLINE'), '');
            }
            // ERREUR HTTP (400, 500, etc...)
            // Extraire le message de L'erreur, le traduire, et l'afficher
            try {
                const { name, statusCode } = error.error;
                // const serverError: RideauError = new RideauError(name, statusCode, message, stack);
                if (statusCode === 409 && name) {
                    // Warning
                    this.rideauNotificationService.warning(this.translateService.instant('WARNING.' + name), '');
                    console.warn('Warning : ', error);
                } else if (name) {
                    this.rideauNotificationService.error(this.translateService.instant('ERRORS.' + name), '');
                    console.error('Error : ', error);
                } else {
                    throw error;
                }
            } catch (e) {
                this.rideauNotificationService.error(this.translateService.instant('ERRORS.SERVER-ERROR'), '');
                console.error('Error : ', error);
            }
        } else if (error) {
            // Client Error (erreur dans le code), message "Ooops" !
            this.rideauNotificationService.error(this.translateService.instant('ERRORS.CLIENT-ERROR'), '');
            console.error('Error : ', error);
        }
    }
}
