<div class="sidenav-container"
     *appPermission="permission;objectOrganizationId:organizationId">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="61" height="55" viewBox="0 0 61 55">
        <g fill="none" fill-rule="evenodd">
          <path fill="#CADAED" d="M9 8.5H24V23.5H9z" />
          <path fill="#DCD9D3"
            d="M9 33.5H24V48.5H9zM27.5 8.5H42.5V23.5H27.5zM27.5 33.5H42.5V48.5H27.5zM46 8.5H61V23.5H46zM46 33.5H61V48.5H46z" />
          <path fill="#FFF"
            d="M9 23.5H24V30H9zM9 48.5H24V55H9zM27.5 23.5H42.5V30H27.5zM27.5 48.5H42.5V55H27.5zM46 23.5H61V30H46zM46 48.5H61V55H46z" />
          <path fill="#1702F8" fill-rule="nonzero"
            d="M4 9.5c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L4 11.5H1c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L1 9.5h3zM2.732 4.146c-.39-.39-.39-1.023 0-1.414.363-.362.935-.388 1.327-.077l.087.077 2.122 2.122c.39.39.39 1.023 0 1.414-.363.362-.935.388-1.327.077l-.087-.077-2.122-2.122zM9 1c0-.552.448-1 1-1 .513 0 .936.386.993.883L11 1v3c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L9 4V1z" />
        </g>
      </svg>
    </div>
    <p> {{'PROMOUVOIR-SHOW' | translate }}</p>

      <div>
        <button nz-button
                [routerLink]="buttonLink | localize"
                nzType="primary">
          {{'PROMOUVOIR' | translate }}
        </button>
      </div>
</div>

