
<div nz-row nzType="flex" nzType="flex" nzJustify="space-around" nzAlign="top" class="ReadOnlyAction">
  <div nz-col [nzSpan]='showDetailsColumn ? 8 : 18' class="ReadOnlyAction_column ReadOnlyAction_title">
    {{ title }}
  </div>
  <div *ngIf="details && showDetailsColumn; else emptyDetails"  nz-col nzSpan='12' class="ReadOnlyAction_column ReadOnlyAction_details">
    <span *ngIf="!isUrl" [innerHTML]='details' ></span>
    <a *ngIf="isUrl" [href]="details" target="_blank" rel="noopener noreferrer">{{details}}</a>
  </div>
  <div nz-col [nzSpan]='showDetailsColumn ? 2 : 6' class="ReadOnlyAction_column ReadOnlyAction_action">
    <i nz-icon aria-hidden="true" class="pr-12 ReadOnlyAction_edit" (click)='onEditClick()'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="#1702f8" fill-rule="nonzero"
              d="M16.142 3.707a3 3 0 0 1 0 4.243l-7.07 7.07a1 1 0 0 1-.314.213l-4.95 2.121c-.83.356-1.669-.483-1.313-1.313l2.121-4.95a1 1 0 0 1 .212-.313l7.071-7.07a3 3 0 0 1 4.243 0zM11.26 7.174L6.38 12.056l-1.06 2.475 2.474-1.06 4.881-4.883-1.414-1.414zm2.054-2.053l-.64.64 1.414 1.413.64-.638a1 1 0 1 0-1.414-1.415z" />
      </svg>
    </i>
  </div>
</div>

<ng-template #emptyDetails>
  <div *ngIf="showDetailsColumn" nz-col nzSpan='12' class="ReadOnlyAction_column ReadOnlyAction_emptyDetailsContainer height-50">
    <div class="ReadOnlyAction_emptyDetails"></div>
  </div>

</ng-template>
