import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Program } from '../../model/program.model';
import { TranslateService } from '@ngx-translate/core';
import { ProgramStatus } from '../../../program/model/program-status.model';

@Component({
  selector: 'app-program-calendar',
  templateUrl: './program-calendar.component.html',
  styleUrls: ['./program-calendar.component.scss'],
})
export class ProgramCalendarComponent implements OnChanges {
  ProgramStatus = ProgramStatus;

  @Input() date: Date;

  displayCal = true;

  constructor(
    public translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.displayCal = false;
      setTimeout(() => {
        this.displayCal = true;
      });
    }
  }
}
