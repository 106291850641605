<div class="container pb-5 pt-3">
    <h3 class="residency-title mt-4 mb-3">{{ 'RESIDENCY-INFO' | translate }}</h3>
    <div class="residency-grid">
        <!-- TYPES -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-INFO-LIST.RESIDENCY-TYPE' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isTypeCreation ? value : null; context: {$implicit: 'RESIDENCY-INFO-LIST.RESIDENCY-TYPE-CREATION' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isTypeTechnical ? value : null; context: {$implicit: 'RESIDENCY-INFO-LIST.RESIDENCY-TYPE-TECHNICAL' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isTypeDried ? value : null; context: {$implicit: 'RESIDENCY-INFO-LIST.RESIDENCY-TYPE-DRY' | translate}"></ng-container>
        </div>
        <!-- SEASONS -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-INFO-LIST.DISPONIBILITY' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isDisponibilityWinter ? value : null; context: {$implicit: 'RESIDENCY-FORM.DISPONIBILITY-FIELD-4' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isDisponibilitySpring ? value : null; context: {$implicit: 'RESIDENCY-FORM.DISPONIBILITY-FIELD-3' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isDisponibilityFall ? value : null; context: {$implicit: 'RESIDENCY-FORM.DISPONIBILITY-FIELD-1' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isDisponibilitySummer ? value : null; context: {$implicit: 'RESIDENCY-FORM.DISPONIBILITY-FIELD-2' | translate}"></ng-container>
        </div>
        <!-- DURATION -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-INFO-LIST.RESIDENCY-DURATION' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isDurationDay ? value : null; context: {$implicit: 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-1' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isDurationWeek ? value : null; context: {$implicit: 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-2' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.isDurationMonth ? value : null; context: {$implicit: 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-3' | translate}"></ng-container>
        </div>
        <!-- PERSONNEL -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-INFO-LIST.TECHNICAL-PERSON-DISPONIBILITY' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasReceptionStaff ? value : null; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-1' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasAssemblyStaff ? value : null; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-3' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasLightingDesigner ? value : null; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-2' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasSoundEngineer ? value : null; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-7' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasStageTechnician ? value : null; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-4' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasTechnicalDirector ? value : null; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-5' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasRigger ? value : null; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-6' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="!(venue.hasReceptionStaff | convertType: 'boolean') && !(venue.hasAssemblyStaff | convertType: 'boolean') && !(venue.hasLightingDesigner | convertType: 'boolean') && !(venue.hasSoundEngineer | convertType: 'boolean') && !(venue.hasStageTechnician | convertType: 'boolean') && !(venue.hasTechnicalDirector | convertType: 'boolean') && !(venue.hasRigger | convertType: 'boolean') ? noneInformation : null"></ng-container>
        </div>
        <!-- DIMENSIONS -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.STAGE-SIZE-TITLE' | translate}"></ng-container>
            <!-- Mandatory value-->
            <p class="residency-grid__card-content mb-0">
                {{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-1' | translate }}<ng-container *ngTemplateOutlet="additionalInformationSeparator"></ng-container>{{venue.totalStageWidth | floatNumber: ['comma']}}<ng-container *ngTemplateOutlet="metricUnit"></ng-container>
            </p>
            <!-- Mandatory value-->
            <p class="residency-grid__card-content mb-0">
                {{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-2' | translate }}<ng-container *ngTemplateOutlet="additionalInformationSeparator"></ng-container>{{venue.totalSceneDepth | floatNumber: ['comma']}}<ng-container *ngTemplateOutlet="metricUnit"></ng-container>
            </p>
            <p class="residency-grid__card-content mb-0" *ngIf="venue.clearHeightLamps">
                {{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-3' | translate }}<ng-container *ngTemplateOutlet="additionalInformationSeparator"></ng-container><ng-container *ngTemplateOutlet="value; context: {$implicit: venue.clearHeightLamps | floatNumber: ['comma'], suffix: 'm'}"></ng-container>
            </p>
            <p class="residency-grid__card-content mb-0" *ngIf="venue.widthPlayground">
                {{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-4' | translate }}<ng-container *ngTemplateOutlet="additionalInformationSeparator"></ng-container><ng-container *ngTemplateOutlet="value; context: {$implicit: venue.widthPlayground | floatNumber: ['comma'], suffix: 'm'}"></ng-container>
            </p>
            <p class="residency-grid__card-content mb-0" *ngIf="venue.playgroundDepth">
                {{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-5' | translate }}<ng-container *ngTemplateOutlet="additionalInformationSeparator"></ng-container><ng-container *ngTemplateOutlet="value; context: {$implicit: venue.playgroundDepth | floatNumber: ['comma'], suffix: 'm'}"></ng-container>
            </p>
        </div>
        <!-- EQUIPMENT -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-1-BIS' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="information; context: {$implicit: venue.hasAttachmentPoints}"></ng-container>
        </div>
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-3' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="information; context: {$implicit: venue.hasDanceFloor}"></ng-container>
        </div>
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-5' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="information; context: {$implicit: venue.hasVideoProjector}"></ng-container>
        </div>
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-7' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="information; context: {$implicit: venue.hasSoundEquipment}"></ng-container>
        </div>
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-2' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="information; context: {$implicit: venue.hasLightEquipment}"></ng-container>
        </div>

        <!-- TELEPRESENCE -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-4' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="information; context: {$implicit: venue.hasInternet}"></ng-container>
        </div>
        <!-- TELEPRESENCE -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-6' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="information; context: {$implicit: venue.hasTelepresenceEquipment}"></ng-container>
        </div>
        <!-- ACCUEIL -->
        <div class="residency-grid__card">
            <ng-container *ngTemplateOutlet="subtitle; context: {$implicit: 'RESIDENCY-INFO-LIST.HOME' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasLodge ? value : null; context: {$implicit: 'RESIDENCY-FORM.HOME-FIELD-1' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasStorageSpace ? value : null; context: {$implicit: 'RESIDENCY-FORM.HOME-FIELD-3' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasLandingStage ? value : null; context: {$implicit: 'RESIDENCY-FORM.HOME-FIELD-6' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasKitchen ? value : null; context: {$implicit: 'RESIDENCY-FORM.HOME-FIELD-5' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasHostingPartnership ? value : null; context: {$implicit: 'RESIDENCY-FORM.HOME-FIELD-4' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="venue.hasLaundryRoom ? value : null; context: {$implicit: 'RESIDENCY-FORM.HOME-FIELD-2' | translate}"></ng-container>
            <ng-container *ngTemplateOutlet="!(venue.hasLodge | convertType: 'boolean') && !(venue.hasStorageSpace | convertType: 'boolean') && !(venue.hasLandingStage | convertType: 'boolean') && !(venue.hasKitchen | convertType: 'boolean') && !(venue.hasHostingPartnership | convertType: 'boolean') && !(venue.hasLaundryRoom | convertType: 'boolean') ? noneInformation : null"></ng-container>
        </div>
    </div>
</div>

<ng-template #subtitle let-value>
    <p class="page-template-labels c-title c-title--section mb-1">{{ value }}</p>
</ng-template>

<ng-template #value let-value let-suffix="suffix">
    <p class="residency-grid__card-content mb-0">{{value}}<ng-container *ngIf="suffix">{{suffix}}</ng-container>
    </p>
</ng-template>

<ng-template #information let-value>
    <p class="residency-grid__card-content mb-0">{{value ? ('RESIDENCY-INFO-LIST.AVAILABLE-EQUIPMENT' | translate) : ('RESIDENCY-INFO-LIST.NO' | translate) }}</p>
</ng-template>

<ng-template #noInformation>
    <p class="residency-grid__card-content mb-0">{{'RESIDENCY-INFO-LIST.NO' | translate }}</p>
</ng-template>

<ng-template #noneInformation>
    <p class="residency-grid__card-content mb-0">{{'RESIDENCY-INFO-LIST.NONE' | translate }}</p>
</ng-template>

<ng-template #additionalInformationSeparator>&nbsp;&ndash;&nbsp;</ng-template>
<ng-template #metricUnit>m</ng-template>
