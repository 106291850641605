export class BillInfos {
  subtotal: any;
  taxTps: any;
  taxTvq: any;
  total: any;
  receivedAmount: any;
  amountToPay: any;
  date: any;
  numero: any;
  originalNumero?: any;
}
