export enum VenueConfigurationType {
  ITALIENNE = 1,
  CABARET = 2,
  THEATRE = 3,
  DEBOUT = 4,
  MULTIFONCT = 5,
  CONFERENCE = 6,
  REPETITION = 7,
  SURSCENE = 8,
  AUTRE = 9,
}
