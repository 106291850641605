export class Gratuitie {
  id: number;
  users: any[];
  price: number;
  toDelete?: number;

  constructor(data: any) {
    this.id = data['id'];
    this.users = data['users'];
    this.price = data['price'];
    this.toDelete = null;
  }
}
