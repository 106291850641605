<app-connection-container
  [title]=' "PRODUCT-TYPE.INSCRIPTION" | translate'
>

<div class="Register row justify-content-center mt-4">
  <form
  nz-form
  [formGroup]="registrationForm"
  class="Register__col col-lg-4 col-sm-12 col-md-8"
  (ngSubmit)='sendRegistration()'
  >

  <nz-form-item>
    <nz-form-control>
      <label for="email"> {{ "EMAIL_ADRESS" | translate }} </label>
      <input formControlName='email' type="email" name="email">
      <nz-form-explain class="has-error" *ngIf="registrationForm.invalid && registrationForm.get('email').errors &&
      registrationForm.get('email').touched">
      <span
      *ngIf="registrationForm.get('email').errors['required']; else incorrectField">{{ "CHAMP-REQUIS-SIMPLE" | translate }}</span>
      <ng-template #incorrectField>{{'ENTRER-VALEUR'|translate}}</ng-template>
    </nz-form-explain>        
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control>
      <label for="firstName"> {{ "PRENOM" | translate }} </label>
      <input formControlName='firstName' type='text' name="firstName" >
    </nz-form-control>
   </nz-form-item>

   <nz-form-item>
    <nz-form-control>
      <label for="lastName"> {{ "NOM" | translate }} </label>
      <input formControlName='lastName' type='text' name="lastName" >
    </nz-form-control>
   </nz-form-item>


  <nz-form-item>
    <app-password-input formControlName='password' ></app-password-input>
  </nz-form-item>
  <app-password-hint-validator [isValid]='registrationForm.controls.password.valid'  sentence='{{ "PASSWORD-REGEX" | translate }}'  ></app-password-hint-validator>
  <nz-form-item>
    <app-password-input label="FORM.MOT-DE-PASSE-CONFIRM" formControlName='confirmPassword' ></app-password-input>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control >
      <label  formControlName='receiverNewsletter' nz-checkbox >
        <span style="color: white;">{{ "SUBSCRIBE_TO_NEWS_LETTERS" | translate}}</span>
      </label>
    </nz-form-control>
  </nz-form-item>


  <div class="Login__action">
    <app-button-connection
    class="w-100"
    buttonType='Primary'
    [text]=' "REGISTER" | translate '
    [isLoading]='isLoading'
    type='submit'
    [disable]='!registrationForm.valid'
    >

    </app-button-connection>
  </div>


  <div class="Register__have-account-cta d-flex mb-4">
    <p>{{ "HAVE_ACCOUNT" | translate }}?</p>
    <a class="cta_link ml-2" [routerLink]='["../login"]'> {{ "CONNECT" | translate }}</a>
  </div>

  </form>
</div>


</app-connection-container>

