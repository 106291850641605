import { Lightbox, IAlbum } from 'ngx-lightbox';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-photo-lightbox',
  templateUrl: './download-photo-lightbox.component.html',
  styleUrls: ['./download-photo-lightbox.component.scss'],
})
export class DownloadPhotoLightboxComponent {
  @Input()
  photoAlbum: IAlbum[];

  constructor(private lightbox: Lightbox) {}

  open(index: number) {
    this.lightbox.open(this.photoAlbum, index);
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }
}
