<div nz-row
     class="gratuitie-row">
    <div class="number">
        <input type="number"
               [(ngModel)]=" +gratuitie.price">
    </div>
    <div class="text">
        $ {{'POUR' | translate}}
    </div>
    <div class="selector">

        <nz-select nzMode="multiple"
                   id="select-users"
                   [nzTokenSeparators]="[',']"
                   style="width: 100%;"
                   [(ngModel)]="gratuitie.users"
                   nzPlaceHolder="{{'FORM.USERS' | translate}}"
                   nzServerSearch
                   (nzOnSearch)="this.searchUserSubject.next($event)">
            <nz-option *ngFor="let user of users"
                       [nzLabel]="user.email"
                       [nzValue]="user"></nz-option>
        </nz-select>

    </div>
    <div class="delete-button">
        <button type="button"
                class="btn-square"
                (click)="doDelete()">
            <i class="icon-trash"></i>
        </button>
    </div>
</div>
<nz-divider></nz-divider>
