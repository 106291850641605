import { Globals } from 'src/app/_configs/globals';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ItemRowFileComponent } from 'src/app/shared/components/item-rows/item-row-file/item-row-file.component';
import { AbstractShowForm } from '../show-abstract-form/show-abstract-form.component';

@Component({
    selector: 'app-show-materiel-form',
    templateUrl: './show-materiel-form.component.html',
    styleUrls: ['./show-materiel-form.component.scss']
})
export class ShowMaterielFormComponent extends AbstractShowForm implements OnInit {
    readonly fileTypes: string[] = ['image/jpeg', 'image/png'];
    constructor(protected accountService: AccountService, protected globals: Globals) {
        super(accountService, globals);
    }

    @ViewChildren(ItemRowFileComponent)
    childrenComponents: QueryList<ItemRowFileComponent>;

    ngOnInit(): void {
        this.initForm();
    }

    protected initForm(): void {
        this.formGroup = this.show.getAdditionalMaterialFormGroup(this.globals.api.ENDPOINT);
    }

    doSubmit(param?: any) {
        const { promoPhoto1, promoPhoto2, pressPhoto1, pressPhoto2, urlVideo, urlAudio } = this.formGroup.value;
        this.showValue.urlPressPhoto1 = pressPhoto1.file ? pressPhoto1.file : '';
        this.showValue.lblPressPhoto1 = pressPhoto1.desc ? pressPhoto1.desc : '';
        this.showValue.urlPressPhoto2 = pressPhoto2.file ? pressPhoto2.file : '';
        this.showValue.lblPressPhoto2 = pressPhoto2.desc ? pressPhoto2.desc : '';
        this.showValue.urlPromoPhoto1 = promoPhoto1.file ? promoPhoto1.file : '';
        this.showValue.lblPromoPhoto1 = promoPhoto1.desc ? promoPhoto1.desc : '';
        this.showValue.urlPromoPhoto2 = promoPhoto2.file ? promoPhoto2.file : '';
        this.showValue.lblPromoPhoto2 = promoPhoto2.desc ? promoPhoto2.desc : '';
        this.showValue.urlVideo = urlVideo;
        this.showValue.urlAudio = urlAudio;

        super.doSubmit(param);
    }
}
