<app-page-title title="{{ 'ESPACES-PRO' | translate }}" buttonText="{{ 'NOUVELLE-RENCONTRE-PRO' | translate}}"
    (buttonClicked)="createNewMeeting()" [permission]="CAN_EDIT" [organizationId]="SCENE_PRO_ID" [isMainPage]="true">
</app-page-title>


<div class="container">
    <section>

        <div nz-row class="pb-5" nzGutter="24">
            <div nz-col nzSpan="4">
                <h3>{{ 'RENCONTRES-PRO' | translate}}</h3>
            </div>

            <div nz-col nzSpan="20">
                <div *ngIf="isLoading" class="loader">
                    <i nz-icon aria-hidden="true" nzType="loading" nzTheme="outline"></i>
                </div>

                <div class="item-card-grid">
                    <ng-container *ngFor="let item of itemList">
                        <div class="item-card-wrapper">
                            <app-item-card [item]="item"></app-item-card>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <nz-divider></nz-divider>

        <div nz-row nzGutter="24" class="pb-5" *ngIf="networks$">
            <div nz-col nzSpan="4">
                <h3>{{ 'TOURNER-AU-QUEBEC' | translate }}</h3>
            </div>

            <div nz-col nzSpan="20">
                <div *ngIf="isLoading" class="loader">
                    <i nz-icon aria-hidden="true" nzType="loading" nzTheme="outline"></i>
                </div>
            </div>

            <div style="padding-left: 191px;">
                <div class="item-card-wrapper">
                    <div nz-row [nzGutter]="20">
                        <div nz-col [nzSpan]="8" class="gutter-row mb-20" *ngFor="let item of networks$ | async">
                            <app-network-card [item]="item"
                                [isNetworkMember]="checkIfNetworkMember(item.organization.id)"
                                [isSceneProAdmin]="isSceneProAdmin$ | async"></app-network-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>