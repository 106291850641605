export enum NotificationStatus {
    INFO = 0,
    NOT_SUBMITTED = 1,
    SUBMITTED = 2,
    APPROVED = 3,
    REFUSED = 4,
    OUTDATED = 5,
    CONFIRMED = 6
}

export enum NotificationType {
    WELCOME = 0,
    ORGANIZATION = 1,
    VENUE = 2,
    SHOW = 3,
    PROMOTION = 4,
    PROGRAM = 5,
    TOUR = 6,
    DATE = 7
}
