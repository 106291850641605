import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
    selector: 'app-text-input',
    template: `
        <nz-form-item>
            <label [attr.for]="id">
                <ng-content></ng-content>
            </label>
            <nz-form-control>
                <input
                    nz-input
                    [attr.id]="id"
                    [disabled]="disabled"
                    [type]="type"
                    [(ngModel)]="value"
                    (ngModelChange)="ngModelChange()"
                    [class.error]="ngControl.touched && ngControl.errors"
                />
                <p *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.required" class="error">
                    {{ requiredTranslateKey | translate }}
                </p>
                <p *ngIf="ngControl.touched && ngControl.errors && ( ngControl.errors.pattern || ngControl.errors.email )" class="error">
                    {{ emailTranslateKey | translate }}
                </p>
                <p *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.maxlength" class="error">
                    {{ maxLengthTranslateKey | translate: ngControl.errors.maxlength }}
                </p>

                <ng-content select="[after]"></ng-content>
            </nz-form-control>
        </nz-form-item>
    `,
    styles: [
        `
            :host {
                display: block;
            }
            label {
                margin-bottom: 4px;
                display: block;
            }
            p.error {
                color: rgb(226, 0, 108) !important;
                margin-top: 5px;
            }
            input.error {
                border: 2px solid rgb(226, 0, 108) !important;
            }
        `
    ]
})
export class TextInputComponent implements ControlValueAccessor {
    private onChange: (value: string) => void;
    private onTouched: () => void;
    @Input() disabled: boolean;
    @Input() maxLengthTranslateKey = 'MAX_LENGTH_ERROR';
    @Input() requiredTranslateKey = 'REQUIRED_ERROR';
    @Input() emailTranslateKey = 'EMAIL_ERROR';
    @Input() countMaxLength: number;
    @Input() type = 'text';
    id = `TextInputComponent-${unique++}`;
    value: string;
    constructor(readonly translateService: TranslateService, readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }
    ngModelChange(): void {
        this.onChange(this.value);
        this.onTouched();
    }
    writeValue(value: string): void {
        this.value = value;
    }
    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
