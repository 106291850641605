import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RowTypes } from 'src/app/shared/enums/row-types.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToursService } from 'src/app/concepts/tours/services/tours.service';
import { Subscription, Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { TourDiffuseur } from 'src/app/concepts/tours/model/tour-diffuseur.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Globals } from 'src/app/_configs/globals';
import { TourEditRouteData } from 'src/app/concepts/tours/tour-edit-route-resolvers';
import { Tour } from '../../model/tour.model';

@Component({
  selector: 'app-edit-tour-diffuseurs',
  templateUrl: './edit-tour-diffuseurs.component.html',
  styleUrls: ['./edit-tour-diffuseurs.component.scss'],
})
export class EditTourDiffuseursComponent implements OnInit, OnDestroy {
  RowTypes = RowTypes;
  organizationIds: number[];
  context: { name: string, count: number };
  routeData: TourEditRouteData;
  subscription = new Subscription();

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly globals: Globals,
    private readonly translateService: TranslateService,
    private readonly toursService: ToursService,
    private readonly accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    this.subscription.add((this.activatedRoute.data as Observable<TourEditRouteData>).subscribe(routeData => {
      this.routeData = routeData;
      this.organizationIds = this.routeData.tour.diffusers.map(tourDiffuseur => tourDiffuseur.organization.id);
      this.context = {
        name: this.routeData.tour.organization.getTranslatedProperty(this.translateService.currentLang, 'name'),
        count: this.routeData.network.length
      };
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addAll = () => {
    return this.toursService.addAllDiffuseurs(this.routeData.tour.id).pipe(
      tap(result => {
        result.tourDiffusers.forEach(tourDiffuser => {
          this.organizationIds = [...this.organizationIds, tourDiffuser.organizationId];
          this.routeData.tour.diffusers.push(new TourDiffuseur({
            id: tourDiffuser.id,
            organization: this.routeData.network.find(organization => organization.id === tourDiffuser.organizationId)
          }))
        });
        this.setTourStorage();
      }),
      take(1)
    ).subscribe();
  }

  add = (organizationId: number) => {
    return this.toursService.addDiffuseur(this.routeData.tour.id, organizationId).pipe(
      tap(result => {
        this.routeData.tour.diffusers.push(new TourDiffuseur({
        id: result.tourDiffuser.id,
        organization: this.routeData.network.find(organization => organization.id === result.tourDiffuser.organizationId)
      }));
      this.setTourStorage();
     })
    );
  }

  remove = (organizationId: number) => {
    return this.toursService.removeDiffuseur(
      this.routeData.tour.id,
      this.routeData.tour.diffusers.find(tourDiffuseur => tourDiffuseur.organization.id === organizationId).id
    ).pipe(
      tap(() => {
        this.routeData.tour.diffusers.splice(this.routeData.tour.diffusers.findIndex(tourDiffuseur => tourDiffuseur.organization.id === organizationId) ,1);
        this.setTourStorage();
      })
    );
  }

  openTour = () => {
    return this.toursService.accesDiffuseurs(
      this.routeData.tour.id,
      this.accountService.getCurrentCtxOrganizationId(),
      true
    ).pipe(
      tap(() => {
        this.routeData.tour.isDepositOpen = true;
        this.setTourStorage();
      }),
      take(1)
    ).subscribe();
  }

  closeTour = () => {
    return this.toursService.accesDiffuseurs(
      this.routeData.tour.id,
      this.accountService.getCurrentCtxOrganizationId(),
      false
    ).pipe(
      tap(() => {
        this.routeData.tour.isDepositOpen = false;
        this.setTourStorage();
      }),
      take(1)
    ).subscribe();
  }

  private setTourStorage() {
    const tourList:Tour[] = [];
    const storedTours = sessionStorage.getItem('rideau.notifications.tours');
    if(storedTours) {
    const parsedData = JSON.parse(storedTours) as Tour[];
    parsedData.forEach((tourItem) => {
      if(tourItem.id === this.routeData.tour.id) {
        tourItem.diffusers = this.routeData.tour.diffusers;
        tourItem.isDepositOpen = this.routeData.tour.isDepositOpen;
      }
      tourList.push(tourItem);
    })
    } else {
      tourList.push(this.routeData.tour);
    }
    sessionStorage.setItem('rideau.notifications.tours', JSON.stringify([ ...tourList ]));
  }
}
