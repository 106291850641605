import { Translatable } from 'src/app/shared/model/translatable.model';

export class BillingItem extends Translatable {
  id: number;
  organizationHeadId: number;
  organizationId: number;
  fee: number;
  customFee: number;
  totalFee: number;
  hasFinanceInfo: number;
  isReadyForPay: number;
  isPaid: number;
  isExpired: number;
  trans: any;
  sentAt?: Date;
  paidAt?: Date;

  constructor(datas: Record<string, any>) {
    super();
    this.id = datas['id'];
    this.organizationHeadId = datas['organizationHeadId'];
    this.organizationId = datas['organizationId'];
    this.fee = datas['fee'];
    this.customFee = datas['customFee'];
    this.totalFee = datas['totalFee'];
    this.hasFinanceInfo = datas['hasFinanceInfo'];
    this.isReadyForPay = datas['isReadyForPay'];
    this.isPaid = datas['isPaid'];
    this.isExpired = datas['isExpired'];
    this.trans = datas['trans'];
    this.sentAt = datas['sentAt'] ? new Date(datas['sentAt']) : null;
    this.paidAt = datas['paidAt'] ? new Date(datas['paidAt']) : null;
  }
}
