<ng-container *ngIf="isReady">
    <app-page-title title="{{ pageTitle }}"
                    buttonText="{{ 'ENREGISTER' | translate }}"
                    (buttonClicked)="updateTag()">
    </app-page-title>

    <div class="container">
        <div class="tagRow row justify-content-center">
            <!-- IMAGE UPLOAD -->
            <div class="col col-sm-4 pt-4">

                <app-img-upload
                  [currentImage]="tag.image"
                  [uploadAction]="uploadAction"
                  [maxCropperWidth]="imageConfig.maxWidth"
                  [maxCropperHeight]="imageConfig.maxHeight"
                  [maxFileWeigth]="1000"
                  uploadActionName="showmain"
                  (onUploadFile)="onUploadFileCallback($event)"
                ></app-img-upload>

            </div>

            <!-- MAIN -->
            <div class="col col-sm-8" >
                <!-- TITLE -->
                <div class="title mb-5">
                    <label for="showId"
                        class="repertory-filter__label mb-2 d-block">{{'TITRE' | translate}} *</label>
                        <input  nz-input
                                placeholder="Mot clé"
                                [(ngModel)]="pageTitle" />
                </div>

                <div>
                  <nz-form-item>
                    <label for="validityPeriod" class="d-block mb-2">{{ 'FORM.PERIODE-DE-VALIDITE' | translate }}</label>
                    <nz-radio-group class="input-radioBtn" [(ngModel)]="isAlwaysValid" [ngModelOptions]="{ standalone: true }">
                      <label nz-radio [nzValue]="true" class="input-radioBtn__radio d-block mb-2">{{ 'FORM.EN-TOUT-TEMPS' | translate}}</label>
                      <label nz-radio [nzValue]="false" class="input-radioBtn__radio d-block mb-2">{{ 'FORM.LIMITE-DANS-LE-TEMPS' | translate}}</label>
                    </nz-radio-group>
                    <nz-range-picker nzAllowClear="true"
                      [nzShowTime]="{ nzFormat: 'HH:mm' }"
                      nzFormat="yyyy/MM/dd HH:mm"
                      [(ngModel)]="validityInterval"
                      *ngIf="!isAlwaysValid">
                    </nz-range-picker>
                  </nz-form-item>
                </div>

                <!-- SEARCH -->
                <div class="searchbar mb-5 ">
                    <label for="showId"
                        class="repertory-filter__label mb-2 d-block">{{'RECHERCHER' | translate}}</label>
                    <nz-select name="showId"
                            id="showId"
                            nzShowSearch
                            nzAllowClear
                            style="width: 100%"
                            [nzSuffixIcon]="nzSuffixIconSearch"
                            [(ngModel)]="searchText"
                            (nzOnSearch)="onSearchChange($event)"
                            (ngModelChange)="onSearchSelect($event)">
                    <nz-option></nz-option>
                    <ng-template #nzSuffixIconSearch>
                        <i nz-icon nzType="search" aria-hidden="true"></i>
                      </ng-template>
                    <nz-option *ngFor="let show of showsSearch"
                                [nzLabel]="show.getTranslatedProperty(currentLang,'title')"
                                [nzValue]="show.id"></nz-option>
                    </nz-select>

                </div>

                <!-- EXISTING SHOWS -->
                <div  class="mb-3">

                    <app-item-list  [itemList]="tagShows"
                                    [useFilters]="false"
                                    [useViewSwitcher]="false"
                                    [useSearchBar]="false"
                                    [useSorting]="false"
                                    [hideHeader]="true"
                                    [enableRemoving]="true"
                                    (removeItems)="deleteTagShow($event)"
                                    [usePagination]="true"
                                    [pagination]="pagination"
                                    (pageChange)="onPageChange($event)"></app-item-list>
                </div>
            </div>
        </div>
    </div>
</ng-container>
