<form nz-form
      [nzLayout]="'vertical'"
      [formGroup]="formGroup"
      (ngSubmit)="doSubmit()">
  <div nz-row>
    <div nz-col
         nzSpan="24"
         class="mb-4">
      <h2>
        {{'FORM.SELECTIONNEZ-SAPPLIQUE-VOTRE-ORGANISATION' | translate}}
      </h2>
      <nz-divider></nz-divider>
      <nz-form-item nz-row>
        <nz-form-control [nzSpan]="14">
          <label nz-checkbox
                 [nzDisabled]="formGroup.disabled"
                 formControlName="isProducteur"
                 class="d-flex">
            <div class="d-inline-block float-right">
              <span class="d-block">{{'ORGANIZATION-TYPE_PRODUCTEUR' | translate}}</span>
              <span class="fw-normal d-block">{{'ORGANIZATION-TYPE_PRODUCTEUR_INFOS' | translate}}</span>
            </div>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-control [nzSpan]="14">
          <label nz-checkbox
                 [nzDisabled]="formGroup.disabled"
                 formControlName="isDiffuseur"
                 class="d-flex">
            <div class="d-inline-block float-right">
              <span class="d-block">{{'ORGANIZATION-TYPE_DIFFUSEUR' | translate}}</span>
              <span class="fw-normal d-block">{{'ORGANIZATION-TYPE_DIFFUSEUR_INFOS' | translate}}</span></div>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-control [nzSpan]="14">
          <label nz-checkbox
                 [nzDisabled]="formGroup.disabled"
                 formControlName="isSalle"
                 class="d-flex">
            <div class="d-inline-block float-right">
              <span class="d-block">{{'ORGANIZATION-TYPE_GESTION' | translate}}</span>
              <span class="fw-normal d-block">{{'ORGANIZATION-TYPE_GESTION_INFOS' | translate}}</span></div>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-control [nzSpan]="14">
          <label nz-checkbox
                 [nzDisabled]="formGroup.disabled"
                 formControlName="isGovernmental"
                 class="d-flex">
            <div class="d-inline-block float-right">
              <span class="d-block">{{'ORGANIZATION-TYPE_GOVERNMENTAL' | translate}}</span>
              <span class="fw-normal d-block">{{'ORGANIZATION-TYPE_GOVERNMENTAL_INFOS' | translate}}</span></div>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-control [nzSpan]="14">
          <label nz-checkbox
                 [nzDisabled]="formGroup.disabled"
                 formControlName="isProvider"
                 class="d-flex">
            <div class="d-inline-block float-right">
              <span class="d-block">{{'ORGANIZATION-TYPE_PROVIDER' | translate}}</span>
              <span class="fw-normal d-block">{{'ORGANIZATION-TYPE_PROVIDER_INFOS' | translate}}</span></div>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-control [nzSpan]="14">
          <label nz-checkbox
                 [nzDisabled]="formGroup.disabled"
                 formControlName="isMedia"
                 class="d-flex">
            <div class="d-inline-block float-right">
              <span class="d-block">{{'ORGANIZATION-TYPE_MEDIA' | translate}}</span>
              <span class="fw-normal d-block">{{'ORGANIZATION-TYPE_MEDIA_INFOS' | translate}}</span></div>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row>
        <nz-form-control [nzSpan]="14">
          <label nz-checkbox
                 [nzDisabled]="formGroup.disabled"
                 formControlName="isOther"
                 class="d-flex">
            <div class="d-inline-block float-right">
              <span class="d-block">{{'ORGANIZATION-TYPE_OTHER' | translate}}</span></div>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-explain class="has-error"
                       *ngIf="displayErrors && formGroup.status === 'INVALID'">
        {{'CHOISIR-UN-TYPE'|translate}}
      </nz-form-explain>

      <div *ngIf="getSceneProAdmin() === SCENE_PRO_ID">
        <nz-divider></nz-divider>
        <h2>
          {{'ACCESS_FEATURES' | translate}}
        </h2>
        <nz-form-item nz-row>
          <nz-form-control [nzSpan]="14">
            <label nz-checkbox
                   [nzDisabled]="formGroup.disabled"
                   formControlName="tourAccess"
                   class="d-flex">
              <div class="d-inline-block float-right">
                <span class="d-block">{{'TOUR_ADMIN_TITLE' | translate}}</span>
                <span class="fw-normal d-block">{{'CREATE_UPDATE_TOUR_ADMIN' | translate}}</span></div>

            </label>
          </nz-form-control>
        </nz-form-item>
      </div>

    </div>
    <!-- Boutons si orga non soumise ou rejetée  -->
    <ng-container *ngIf="organization.statusId === 1 || organization.statusId === 4">
      <button *appPermission="1;objectOrganizationId :organization.id;shouldBeApproved:false"
              nz-button
              [nzLoading]="isLoading"
              class="btn btn--default">
        {{'ENREGISTER-CONTINUER' | translate}}
      </button>
      <p *appPermission="1;objectOrganizationId :organization.id;shouldBeApproved:false"
         class="d-inline-block ml-2">{{ 'OU' | translate }}
        <a *ngIf="!isLoading"
           (click)="doSubmit('simple')">{{'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
        <span *ngIf="isLoading">{{'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
      </p>
    </ng-container>
    <!-- Bouton simple si orga approuvée -->
    <ng-container *ngIf="(organization.statusId === 3 || organization.statusId === 2) &&!formGroup.disabled">
      <button *appPermission="1;objectOrganizationId :organization.id;shouldBeApproved:false"
              nz-button
              [nzLoading]="isLoading"
              class="btn btn--default">
        {{'ENREGISTER' | translate}}
      </button>
    </ng-container>
  </div>
</form>
