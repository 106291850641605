<div class="sidenav-container">
    <app-section-title title="{{ 'BABILLARD' | translate }}"
                       buttonText="{{ 'NOUVELLE-PUBLICATION' | translate }}"
                       (buttonClicked)="newPublication()"
                       [permission]="1"
                       [buttonIsLoading]="isDisabled">
    </app-section-title>

    <section class="sidenav-container__content">
        <div nz-row
             class="sidenav-container__list">
            <nz-list [nzBordered]>
                <!-- LOADING SPINNER -->
                <div *ngIf="isLoading"
                     class="loader">
                    <i nz-icon
                    aria-hidden="true"
                       nzType="loading"
                       nzTheme="outline"></i>
                </div>

                <!-- LIST -->
                <nz-list-item *ngFor="let item of publications">
                    <div nz-col
                         nzSpan="6">
                        <div>
                            <div class="sidebar-image-container">
                                <img class="w-100"
                                     style="object-fit: cover;
                                      width: 69px;
                                      height: 69px;"
                                     src="{{ item.itemImgSrc }}"
                                     alt="" />
                            </div>

                        </div>
                    </div>

                    <div class="myShow-info">
                        <div nz-col
                             nzSpan="14">
                            <div style="display:flex;flex-direction: column">
                                <div style="display: contents;">
                                    <span class="sidenav-container__content__links">
                                        {{item.itemTitle}}
                                    </span>
                                    <sub class="sidenav-container__content__sub">
                                        {{ getRemainingTime(item) }}
                                    </sub>
                                </div>
                            </div>
                        </div>

                        <div nz-col
                             nzSpan="4"></div>

                        <!-- STATUS -->
                        <span *ngIf="item.itemStatus === objectStatus.SOUMIS"
                              class="status-bulb status-bulb--small status-bulb--pending">
                            <i nz-icon
                            aria-hidden="true"
                               nzType="clock-circle"
                               nzTheme="outline"></i>
                        </span>
                        <span *ngIf="item.itemStatus === objectStatus.APPROUVE"
                              class="status-bulb status-bulb--small status-bulb--success">
                            <i nz-icon
                            aria-hidden="true"
                               nzType="check"
                               nzTheme="outline"></i>
                        </span>
                        <span *ngIf="item.itemStatus === objectStatus.REJETE"
                              class="status-bulb status-bulb--small status-bulb--warning">
                            <i nz-icon
                            aria-hidden="true"
                               nzType="exclamation-circle"
                               nzTheme="outline"></i>
                        </span>

                        <span *ngIf="item.itemStatus === objectStatus.OUTDATED"
                              class="status-bulb status-bulb--small status-bulb--errors">
                            <i nz-icon
                            aria-hidden="true"
                               nzType="close"
                               nzTheme="outline"></i>
                        </span>
                    </div>
                </nz-list-item>
            </nz-list>
        </div>

        <div nz-col
             nzSpan="24"
             class="pt-2 footer-link">
            <span>
                <a class="sidenav-container__content__links"
                   [routerLink]="['/organization/',organizationId,'modify','promotion'] | localize"
                   style="font-size: 13px !important">{{ 'TOUTES-MES-PUBLICATIONS' | translate }} ></a>
            </span>
        </div>
    </section>
</div>
