import { Component, Input } from '@angular/core';
import { Venue } from '@app/concepts/venue/model/venue.model';

@Component({
    selector: 'app-venue-single-residency',
    templateUrl: 'venue-single-residency.component.html',
    styleUrls: ['./venue-single-residency.component.scss']
})
export class VenueSingleResidencyComponent {
    /**
     * An instance of the Venue class representing the venue currently being displayed.
     * @type {Venue}
     */
    @Input() venue: Venue;
}
