import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-blank.validator';
import { Organization } from '../../organization/model/organization.model';
import { getClosestDay } from '@app/shared/utils/time-utils';

export class Publication extends Translatable {
  id?: number;
  statusId: number;
  image: string;
  website: string;
  organizationId: number;
  dateFrom: string;
  weeksNumber: number;
  isPaid: number;
  createdAt: string;
  isPublication: number;
  organization: Organization;

  private publicationForm: FormGroup;

  constructor(data: any) {
    super();
    this.id = data['id'];
    this.statusId = data['statusId'];
    this.image = data['image'];
    this.website = data['website'];
    this.organizationId = data['organizationId'];
    this.dateFrom = data['dateFrom'] ? getClosestDay(data['dateFrom']).toString() : "";
    this.weeksNumber = data['weeksNumber'];
    this.isPaid = data['isPaid'];
    this.createdAt = data['createdAt'];
    this.trans = data['trans'];
    this.isPublication = data['isPublication'];
    this.organization =
      data['organization'] && new Organization(data['organization']);
  }

  toListItem(lang: string): ListItem {
    const item = new ListItem();

    item.itemId = this.id;
    item.itemDate = this.dateFrom;
    item.itemTitle = this.getTranslatedProperty(lang, 'title');
    item.itemSubtitle = this.getTranslatedProperty(lang, 'description');
    item.itemImgSrc = this.image;
    item.itemStatus = this.statusId;
    item.itemInfo1 = this.weeksNumber + '';
    item.isPublication = this.isPublication;
    item.organizationName =
      this.organization &&
      this.organization.getTranslatedProperty(lang, 'name');

    return item;
  }

  getPublicationFormGroup(fb: FormBuilder, lang: string): FormGroup {
    if (!this.publicationForm) {
      this.publicationForm = fb.group({
        title: [
          this.getTranslatedProperty(lang, 'title'),
          [Validators.required, noWhitespaceValidator],
        ],
        website: [this.website, [Validators.required]],
        description: [
          this.getTranslatedProperty(lang, 'description'),
          [Validators.required, noWhitespaceValidator],
        ],
        dateFrom: [this.dateFrom, [Validators.required]],
        weeksNumber: [
          this.weeksNumber ? this.weeksNumber.toString() : null,
          [Validators.required],
        ],
        image: [this.image, [Validators.required]],
        organizationName: [
          this.organization &&
          this.organization.getTranslatedProperty(lang, 'name'),
        ],
      });
    }
    return this.publicationForm;
  }
}

export interface IUpdatedResponse {
    isUpdated: boolean;
    isAddedToCart: boolean
}
