<app-page-title title="{{'AIDE' | translate}}" [isMainPage]="true">
</app-page-title>

<div class="container help">
    <div class="row">
        <div class="col-sm-3">
            <!-- Menu  -->
            <div class="help__menu">
                <ul nz-menu>

                    <li nz-menu-item class="help__menu__item" *ngFor="let menu of helpMenuList"
                        [nzSelected]="menu.selected" (click)="onSelectMenu(menu);">
                        <a class="mr-3" [routerLink]='"."' [fragment]="menu.id | translate">
                            {{ menu.value | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-sm-9 help__content">

            <div id="{{ 'AIDE-MENU-ID.GENERAL' | translate }}">

                <h2>Général</h2>

                <h3>J’ai effectué un paiement dans la plateforme. Comment puis-je accéder à la facture ?</h3>
                <p>Pour visualiser et imprimer vos factures, rendez-vous dans les réglages de votre
                    organisation, dans l’onglet <strong>Facturation</strong>.</p>

            </div>

            <div id="{{ 'AIDE-MENU-ID.BOTTIN' | translate }}">
                <h2>Bottin</h2>

                <h3>Mon organisation ne s’affiche pas dans le bottin. Que faire ?</h3>
                <p>Avez-vous bien soumis votre organisation pour approbation? Il est possible que cette
                    dernière soit toujours au stade de brouillon. Pour soumettre cette dernière, rendez-vous
                    dans <strong> Mon compte </strong> → <a class="page-template page-template-link"
                        [routerLink]="'/profile/organizations' | localize">
                        <strong>Organisations</strong></a>, cliquez sur modifier à côté de l’organisation en
                    question. Enfin, cliquez sur le bouton <strong>Soumettre pour approbation</strong>.</p>

                <p>Les fiches d’organisation sont révisées et validées par notre équipe de contrôle qualité avant d’être
                    publiées dans le bottin dans un délai de 48h suivant sa soumission pour approbation. Si
                    votre organisation a été créée il y a plus de 48h mais n’apparait toujours pas dans le bottin,
                    merci de contacter le service utilisateur au 514 598-8024 au poste 221.</p>

                <h3>Je suis un artiste qui s’auto-représente. Dois-je quand même créer une organisation ?</h3>
                <p>Si vous êtes un artiste auto-produit, il est impératif de vous créer une organisation de type
                    « Production » afin de pouvoir inscrire votre offre de spectacle dans le répertoire et la
                    proposer en vitrine à des rencontres professionnelles.</p>
                <h3>Qu'est-ce qu'un « identifiant Wikidata » et à quoi sert-il ?</h3>
                <p>L'identifiant Wikidata est un identifiant unique utilisé dans Wikidata, la base de connaissances
                    ouverte associée à Wikipédia. En plus de favoriser la découvrabilité des entités du secteur du
                    spectacle, il est appelé à devenir un identifiant universel qui facilite l'échange d'informations
                    entre différentes plateformes et bases de données.
                <h3>Qu'est-ce qu'un « identifiant Artsdata » et à quoi sert-il ?</h3>
                <p>L'identifiant Artsdata est un identifiant unique utilisé dans Artsdata, le graphe de connaissances
                    ouvert pour les arts. Il permet d'automatiser des processus de collecte, de mise-à-jour et de
                    partage d'informations en provenance ou à destination de pages web, de plateformes et de bases de
                    données.</p>
            </div>

            <div id="{{ 'AIDE-MENU-ID.OFFRES-SPECTACLES' | translate }}">

                <h2>Offres de spectacle</h2>

                <h3>J’ai rempli le formulaire d’une offre de spectacle et payé pour sa publication mais cette
                    dernière ne s’affiche pas dans le répertoire. Pourquoi ?</h3>
                <p>Les fiches d’offres de spectacle sont révisées et validées par notre équipe de contrôle
                    qualité avant d’être publiées dans le répertoire dans un délai de 72h suivant sa soumission
                    pour approbation. En période de forte affluence, il est possible que ce délai ne puisse pas
                    être respecté. Si votre offre de spectacle a été soumise il y a plus de 72h mais n’apparait
                    toujours pas dans le répertoire, merci de contacter le service utilisateur au 514 598-8024 au
                    poste 221. Si vous avez reçu un courriel de confirmation vous notifiant l’approbation de
                    votre offre de spectacle, vérifiez que la case « Ce spectacle sera visible dans la rubrique
                    "Offres de spectacle" » dans la page <strong>Identification du spectacle</strong> est bien cochée.
                </p>

                <h3>À quoi sert la validation des offres de spectacles ?</h3>
                <p>Certains champs remplis dans votre formulaire d’offre de spectacle serviront d’informations
                    de référence pour les diffuseurs et les évènements professionnels. Pour assurer une qualité
                    optimale de cette information, Scène Pro vérifie chaque fiche d’offre de spectacle avant sa
                    publication.
                    Par exemple, afin de présenter sa programmation, un diffuseur ayant programmé votre
                    spectacle pourra aller chercher votre description et votre photo directement dans la
                    plateforme sans avoir à faire de vérifications supplémentaires.</p>

                <h3>Pourrai-je modifier mon offre de spectacle après sa validation ?</h3>
                <p>Oui ! À l’exception de certains champs (titre, artiste(s), description courte, image vedette et
                    représentant) votre offre de spectacle peut être mise à jour en tout temps. Si vous devez
                    tout de même modifier un champ bloqué vous pouvez contacter le support utilisateur de
                    Scène Pro avec les modifications à apporter à
                    <a class="page-template page-template-link"
                        href="mailto:support@scenepro.ca">support@scenepro.ca</a>.
                </p>

                <h3>Mon artiste n’apparait pas dans la liste préexistante. Que faire ?</h3>
                <p>Ajouter un artiste est très simple ! Si ce dernier n’existe pas, il vous suffit de taper au clavier
                    le nom de l’artiste et d’appuyer sur la touche « entrée » de votre clavier.</p>

                <h3>Mon offre de spectacle a été rejetée. Pourquoi ?</h3>
                <p>Pas de panique ! Si votre offre de spectacle est rejetée par notre contrôle qualité, c’est
                    qu’une information doit être corrigée ou ajoutée. Notre équipe vous contactera très
                    rapidement afin de vous informer des changements à effectuer.</p>

                <h3>Je suis le nouvel agent d’une offre de spectacle déjà enregistrée sur Scène Pro. Comment
                    obtenir les accès administrateurs de cette offre?</h3>
                <p>L’organisation actuellement propriétaire de l’offre doit envoyer un courriel à
                    <a class="page-template page-template-link"
                        href="mailto:support@scenepro.ca">support@scenepro.ca</a> avec le
                    titre du spectacle, l’artiste et la nouvelle organisation admin
                    (Attention, cette dernière doit être créée et approuvée sur Scène Pro avant que le transfert
                    ne puisse être fait). Le transfert sera effectué dans les prochaines 48h ouvrables.
                </p>


            </div>

            <div id="{{ 'AIDE-MENU-ID.RENCONTRES-PRO' | translate }}">
                <h2>Rencontres Pro</h2>

                <h3>Je tente d’acheter un produit gratuit mais on me demande un numéro de carte de crédit. Est-ce
                    normal?</h3>
                <p>Scène Pro fonctionne sur un mode transactionnel, il n’est donc pas possible de compléter
                    une inscription sans ajouter de carte de crédit. Bien entendu si le total de votre transaction
                    est de 0$, votre carte de crédit ne sera pas débitée.</p>

                <h3>Lors d’une inscription à une rencontre, je ne trouve pas l’individu que je souhaiterais inscrire
                    dans la liste « Ajouter une autre personne ». Comment procéder ?</h3>
                <p>Si la personne que vous voulez inscrire n’apparait pas dans la liste déroulante, cela signifie
                    qu’elle n’a pas encore de compte utilisateur dans Scène Pro. Pour l’ajouter, rendez-vous
                    dans la section « Équipe et administration » dans les réglages de votre organisation.
                    Inscrivez son adresse courriel et invitez-la à créer un compte utilisateur ainsi qu’à ajouter
                    une photo. Cette dernière est indispensable pour finaliser l’inscription car elle sera utilisée
                    pour son accréditation.</p>

            </div>

            <div id="{{ 'AIDE-MENU-ID.TOURNER-QUEBEC' | translate }}">
                <h2>Tourner au Québec</h2>

                <h3>Producteur : Comment savoir si mon offre a bien été transmise?</h3>
                <p>Vous devriez avoir reçu un courriel de notification vous confirmant le succès de votre
                    dépôt. Si vous ne trouvez pas ce courriel, contactez
                    <a class="page-template page-template-link"
                        href="mailto:support@scenepro.ca">support@scenepro.ca</a>
                    Vous pouvez consulter la liste des spectacles que vous avez déposés ainsi que le détail des
                    formulaires remplis depuis les réglages de votre organisation dans Scène Pro.
                </p>

                <h3>Producteur : J’ai déposé une offre de spectacle pour tourner dans un réseau. Puis-je modifier
                    les éléments de mon dépôt?</h3>
                <p>Il n’est pas possible pour vous de modifier les informations du formulaire une fois ce
                    dernier transmis au réseau. Par ailleurs, vous pouvez contacter la personne responsable du
                    dépôt et l’informer de la correction à apporter. Suivez ses instructions afin de vous assurez
                    que la modification bien été reçue de leur côté.</p>

                <h3>Producteur : Quelles sont les données accessibles uniquement aux diffuseurs du réseau?</h3>
                <p>Toutes les données entrées dans le formulaire destiné au réseau sont privées et seulement
                    visibles par les diffuseurs membres de ce réseau.</p>

                <h3>Diffuseur membre : Comment accéder aux offres déposées dans le dépôt d’un réseau?</h3>
                <p>Rendez-vous dans Espace Pro et cliquez sur le nom du dépôt qui vous intéresse dans la
                    section Tourner au Québec. Assurez vous qu’un bandeau gris indiquant « Accès membre »
                    apparait au-dessus du nom du réseau propriétaire du dépôt. Si cette mention n’apparait
                    pas, essayez de rafraichir votre page web.</p>

                <h3>Diffuseur membre : Comment repérer les offres auxquelles j’ai inscrit un intérêt?</h3>
                <p>C’est facile! Il vous suffit de cliquer sur « Intérêt » dans les filtres disponibles à gauche de la
                    liste des spectacles. De la même façon vous pouvez afficher les offres de spectacles que
                    vous n’avez pas encore évalué en sélectionnant « Non-évalué ».</p>

                <h3>Diffuseur membre : Quelle est la différence entre intérêts et favoris. Qui peux voir ces
                    données?</h3>
                <p>Les favoris fonctionnent comme les favoris de votre navigateur. Depuis le répertoire des
                    offres de spectacles de Scène Pro, vous pouvez identifier les offres comme favoris. Trois
                    options s’offrent à vous en termes de visibilité :</p>
                <ul>
                    <li>Moi : les favoris ne sont visibles que par vous.</li>
                    <li>« Nom de votre organisation » : Tous les utilisateurs membres de cette
                        organisation peuvent voir les spectacles que vous avez mis en favoris.
                    </li>
                    <li>« Nom de votre/vos réseau(x) » : Tous les utilisateurs membres de ce réseau
                        peuvent voir les spectacles que vous avez mis en favoris.
                    </li>
                </ul>
                <p>Attention : Les propriétaires des spectacles ne voient pas les offres que vous avez enregistrées
                    comme favoris.</p>
                <p>Les intérêts s’appliquent uniquement au sein du dépôt d’un réseau. Ils expriment votre
                    désir de programmer un spectacle dans le cadre d’une tournée. Ils ne sont visibles que par
                    les autres diffuseurs membres du réseau et par le réseau en question.</p>
            </div>
        </div>
    </div>
</div>