<h2 class="mb-4">{{ 'CONFIGURATION' | translate}}</h2>
<form id="profile-form"
      class="mb-4"
      *ngIf="membershipConfigForm"
      nz-form
      [formGroup]="membershipConfigForm"
      (ngSubmit)="saveFinance()">

  <div class="row">
    <div class="col-md-4">
      <label for="value1">{{ 'FORM.CONFIG.VALUE1' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value1"
                 name="value1"
                 formControlName="value1"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <label for="value2">{{ 'FORM.CONFIG.VALUE2' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value2"
                 name="value2"
                 formControlName="value2"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value3">{{ 'FORM.CONFIG.VALUE3' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value3"
                 name="value3"
                 formControlName="value3"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value4">{{ 'FORM.CONFIG.VALUE4' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value4"
                 name="value4"
                 formControlName="value4"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value5">{{ 'FORM.CONFIG.VALUE5' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value5"
                 name="value5"
                 formControlName="value5"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value6">{{ 'FORM.CONFIG.VALUE6' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value6"
                 name="value6"
                 formControlName="value6"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value7">{{ 'FORM.CONFIG.VALUE7' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value7"
                 name="value7"
                 formControlName="value7"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value8">{{ 'FORM.CONFIG.VALUE8' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value8"
                 name="value8"
                 formControlName="value8"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value9">{{ 'FORM.CONFIG.VALUE9' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value9"
                 name="value9"
                 formControlName="value9"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label for="value1">{{ 'FORM.CONFIG.VALUE10' | translate }}</label>
    </div>
    <div class="col-md-3">
      <nz-form-item>
        <nz-form-control>
          <input id="value10"
                 name="value10"
                 formControlName="value10"
                 type="number">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <!-- TODO  : modifier pou ROSEQ -->
  <button *appPermission="1;objectOrganizationId :1;shouldBeApproved:true"
          [disabled]="membershipConfigForm.disabled"
          nz-button
          class="btn btn--default">
    {{'ENREGISTER' | translate}}
  </button>
</form>