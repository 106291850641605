
        <nz-form-item>
            <label [attr.for]="id">
                <ng-content></ng-content>
            </label>

            <app-item-row
                [itemRowType]="rowType"
                [item]="selectedItemRow()"
                [enableRemoving]="!disabled"
                hideStatus="true"
                (removeItem)="onRemoved()"
                *ngIf="selected"
            >
            </app-item-row>

            <nz-form-control>
                <nz-select
                    [attr.id]="id"
                    name="selected"
                    nzShowSearch
                    nzAllowClear
                    [(ngModel)]="selected"
                    (ngModelChange)="onSelected($event)"
                    *ngIf="!selected"
                    [nzDisabled]="disabled"
                >
                    <nz-option
                        *ngFor="let option of options"
                        [nzLabel]="option.getTranslatedProperty(translateService.currentLang, labelProperty)"
                        [nzValue]="option[valueProperty]"
                    >
                    </nz-option>
                </nz-select>

                <ng-content select="[after]"></ng-content>
            </nz-form-control>
        </nz-form-item>
    