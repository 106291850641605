import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Invitation, IMember } from '../../model/organization.model';
import { OrganizationService } from '../../services/organization.service';
import { OrgRoles } from '../../../../shared/enums/roles.enum';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Globals } from '@app/_configs/globals';

const SCENE_PRO_ID = 1;

@Component({
  selector: 'app-organization-team',
  templateUrl: './organization-team.component.html',
  styleUrls: ['./organization-team.component.scss'],
})
export class OrganizationTeamComponent implements OnInit {
  @Input()
  organizationId: number;

  invitationSent = false;
  invitationSuccessfull = false;
  organizationMembers: any = [];
  organizationsMemberRoles: any;
  invitations: Invitation[] = [];
  inviteForm: FormGroup;
  errorMessage: string;
  checkedCollapse = false;
  isSending = false;

  constructor(
    private organziationService: OrganizationService,
    private translate: TranslateService,
    private accountService: AccountService,
    private fb: FormBuilder,
    private globals: Globals
  ) {}

  ngOnInit(): void {
    this.inviteForm = this.fb.group({
      email: ['', [Validators.pattern(this.globals.emailRegex), Validators.required]],
      roleId: [null, [Validators.required]],
    });
    this.getOrganizationMembers();
    this.getInvitations();
    this.initOrgaRoles();
    // check if user has admin role on orga
  }

  getOrganizationMembers(): void {
    this.organziationService.getOrganizationTeam(this.organizationId).subscribe(
      (data: any) => {
        this.organizationMembers = data.members;

        this.updateMembersDeletionPermissions();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  private updateMembersDeletionPermissions(): void {
    this.organizationMembers.forEach((member) => {
      if (this.accountService.getCurrentCtxOrganizationId() === SCENE_PRO_ID) {
        member.canLeaveOrg = true;
      } else {
        if (member.roleId === 1) {
          member.canLeaveOrg = !!this.organizationMembers.find(
            (memberComp) =>
              memberComp.id !== member.id && memberComp.roleId === 1
          );
        } else {
          member.canLeaveOrg = true;
        }
      }
    });
  }

  private getInvitations(): void {
    this.organziationService
      .getOrganizationInvitations(this.organizationId)
      .subscribe(
        (invits: Invitation[]) => (this.invitations = invits),
        (err) => {
          console.error(err);
        }
      );
  }

  private initOrgaRoles(): void {
    this.organizationsMemberRoles = Object.keys(OrgRoles)
      .filter((val) => isNaN(Number(val)))
      .map((key) => {
        return {
          id: OrgRoles[key],
          name: this.translate.instant('ROLE.' + key),
        };
      });
  }

  getRoleForId(roleId: number): string {
    const res = this.organizationsMemberRoles.find(
      (role) => role.id === roleId
    );
    return res ? res.name : '';
  }

  addNewMember(): void {
    if (
      this.organizationMembers.filter(
        (x) => x.user.email === this.inviteForm.value.email
      ).length
    ) {
      this.isSending = false;
      this.invitationSuccessfull = false;
      this.errorMessage = 'INVITATION-NON-ENVOYEE';
      this.invitationSent = true;
      setTimeout(() => (this.invitationSent = false), 3000);
    } else if (
      this.invitations.filter((x) => x.email === this.inviteForm.value.email)
        .length
    ) {
      this.isSending = false;
      this.invitationSuccessfull = false;
      this.errorMessage = 'INVITATION-DEJA-ENVOYEE';
      this.invitationSent = true;
      setTimeout(() => (this.invitationSent = false), 3000);
    } else {
      this.isSending = true;
      this.organziationService
        .inviteMember(
          this.organizationId,
          this.inviteForm.value.email,
          this.inviteForm.value.roleId
        )
        .subscribe((data) => {
          if ('invitation' in data) {
            this.invitations.push(new Invitation(data['invitation']));
          }
          if ('member' in data) {
            this.getOrganizationMembers();
          }
          this.inviteForm.reset();
          this.invitationSuccessfull = true;
          this.invitationSent = true;
          this.isSending = false;
          setTimeout(() => (this.invitationSent = false), 3000);
        });
    }
  }

  cancel(): void {
    this.checkedCollapse = !this.checkedCollapse;
    this.inviteForm.reset();
  }

  deleteInvitation(invitId: number) {
    this.organziationService.deleteInvitation(invitId).subscribe(() => {
      this.getInvitations();
    });
  }
}
