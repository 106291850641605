import { AbstractControl, ValidationErrors } from '@angular/forms';

export function richTextMaxLength(requiredLength: number) {
  return function validate(control: AbstractControl): ValidationErrors | null {
    const div = document.createElement('div');
    div.innerHTML = control.value;
    const actualLength = div.textContent.trim().length;
    if (actualLength > requiredLength) {
      return { maxlength: { actualLength, requiredLength } };
    }
    return null;
  };
}
