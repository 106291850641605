import { FormGroup } from '@angular/forms';

export function formatPhoneNumber(tel: string): string {
    if (!tel) {
        return '';
    }

    const value = tel
        .toString()
        .trim()
        .replace(/^\+|-|\s|\./g, '');

    if (value.match(/[^0-9]/)) {
        return tel;
    }

    let country, city, number;

    switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
            country = 1;
            city = value.slice(0, 3);
            number = value.slice(3);
            break;

        case 11: // +CPPP####### -> CCC (PP) ###-####
            country = value[ 0 ];
            city = value.slice(1, 4);
            number = value.slice(4);
            break;

        case 12: // +CCCPP####### -> CCC (PP) ###-####
            country = value.slice(0, 3);
            city = value.slice(3, 5);
            number = value.slice(5);
            break;

        default:
            return tel;
    }

    if (country == 1) {
        country = '';
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    // this.user.phone = (country + " (" + city + ") " + number).trim();

    return (country + ' (' + city + ') ' + number).trim();
}

export function countInvalidControls(fg: FormGroup): number {
    const invalid = [];
    const controls = fg.controls;
    for (const name in controls) {
        if (controls[ name ].invalid) {
            controls[ name ].setErrors({});
            invalid.push(name);
        }
    }
    return invalid.length;
}

export function getDomainName(url: string): string {
    if (url && url.includes('.')) {
        const urlPattern = /^(?:https?:\/\/)?(?:w{3}\.)?([\w\d\.-]+)\.(?:[\w\.]{2,10})(?:[/\w\.-]*)*/;
        const domainPattern = url.match(urlPattern);
        return domainPattern ? domainPattern[ 1 ] : url;
    } else {
        //fallback if wrong url format
        return url;
    }
}
