import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of, from, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class UserOrganizationIdResolver implements Resolve<number> {
  constructor(
    private readonly authService: AuthService
  ) {
  }

  resolve(): Observable<number> {
    return combineLatest([
      of(JSON.parse(localStorage.getItem('currentCtxOrganizationId'))),
      from(this.authService.populateUser())
    ]).pipe(
      switchMap(([localStorage, user]) => {
        if (localStorage && localStorage['userID'] === user.id) {
          return of(+localStorage['orgaId']);
        }
        return of(null);
      })
    );
  }
}