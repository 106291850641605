import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-input-meter',
    templateUrl: './input-meter.component.html',
    styleUrls: ['./input-meter.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputMeterComponent
        }
    ]
})
export class InputMeterComponent implements ControlValueAccessor {
    @Input() pattern: string | RegExp;
    extensionValue = '';
    value = '';
    touched = false;
    disabled = false;

    onChange = (value: string) => {};

    onTouched = () => {};

    writeValue(value: string): void {
        this.value = value;
        this.extensionValue = value;
        this.onChange(value);
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
