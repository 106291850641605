import { Venue } from './../../venue/model/venue.model';
import { Organization } from './../../organization/model/organization.model';
import { Show } from 'src/app/concepts/show/model/show.model';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { Promotion } from '../../promotion/model/promotion.model';
import { Program } from '../../program/model/program.model';
import { Tour } from '../../tours/model/tour.model';
import { NotificationStatus, NotificationType } from '../enums/notifications-status.enum';
import { Globals } from '@app/_configs/globals';

export interface NotificationContent {
    type?: string;
    name?: string;
    image?: string;
    org?: string;
}

export interface NotificationContentStatus {
    message: {
        trans: string;
        date?: Date | string
    };
    className: string;
}

export enum NotificationAction {
    CREATE = 0,
    RENEW = 1,
    VALIDATE = 2,
    ACCESS = 3
}
export class Notification extends Translatable {
    id?: number;
    // Type: 0: organisation; 1: venue; 2: show; ...
    type: NotificationType;
    // Status: 0: Info; 1: approuvé; 2: refusé;
    statusId: number;
    organization?: Organization;
    showId?: number;
    tourId?: number;
    show?: Show;
    tour?: Tour;
    tourAdmin?: Organization;
    venue?: Venue;
    promotion?: Promotion;
    promotionId?: Promotion;
    programId?: number;
    program?: Program;
    createdAt: Date;
    deadline?: Date;
    isActive: number;
    isArchived: number;
    programDate?: Date;
    // Action: 0: Créer organisation; 1: renouveler; 2: valider
    actionId?: NotificationAction;

    constructor(datas: Record<string, any>) {
        super();

        this.id = datas[ 'id' ];
        this.type = datas[ 'type' ];
        this.statusId = datas[ 'statusId' ];
        this.showId = datas[ 'showId' ];
        this.tourAdmin = datas[ 'tourAdmin' ];
        this.tourId = datas[ 'tourId' ];
        this.promotionId = datas[ 'promotionId' ];
        this.programId = datas[ 'programId' ];
        this.program = datas[ 'program' ] ? new Program(datas[ 'program' ]) : null;
        this.tour = datas[ 'tour' ] ? new Tour(datas[ 'tour' ]) : null;
        this.organization = datas[ 'organization' ] ? new Organization(datas[ 'organization' ]) : null;
        this.show = datas[ 'show' ] ? new Show(datas[ 'show' ]) : null;
        this.venue = datas[ 'venue' ] ? new Venue(datas[ 'venue' ]) : null;
        this.promotion = datas[ 'promotion' ] ? new Promotion(datas[ 'promotion' ]) : null;
        this.createdAt = new Date(datas[ 'createdAt' ]);
        this.deadline = new Date(datas[ 'deadline' ]);
        this.isActive = datas[ 'isActive' ];
        this.isArchived = datas[ 'isArchived' ];
        this.programDate = datas[ 'programDate' ];
        this.actionId = datas[ 'actionId' ];
    }

    public getStringFromStatusId = (statusId?: number): string | string[] => {
        switch (statusId || this.statusId) {
            case NotificationStatus.NOT_SUBMITTED:
            case NotificationStatus.SUBMITTED:
                return [ 'NOTIFICATIONS.EST', 'STATUS.SOUMIS-COURT' ];
            case NotificationStatus.APPROVED:
                return 'NOTIFICATIONS.APPROUVE';
            case NotificationStatus.REFUSED:
                return 'NOTIFICATIONS.REJETE';
            case NotificationStatus.OUTDATED:
                return 'NOTIFICATIONS.OUTDATED';
            default:
                return 'NOTIFICATIONS.APPROUVE';

        }
    }

    public getActionLabel(): string {
        if (this.type === NotificationType.TOUR) {
            return 'NOTIFICATIONS.ACCEDER';
        }
        switch (this.actionId) {
            case NotificationAction.CREATE:
                return 'NOTIFICATIONS.ADD-ORGANIZATION';
            case NotificationAction.RENEW:
                return 'NOTIFICATIONS.RENOUVELER';
            case NotificationAction.VALIDATE:
            case NotificationAction.ACCESS:
                return 'NOTIFICATIONS.ACCEDER';
        }
    }

    public getStatusData(): NotificationContentStatus {
        if (!this.statusId) {
            return { message: { trans: '', date: '' }, className: 'is-info' };
        }
        switch (this.statusId) {
            case NotificationStatus.INFO: {
                return {
                    message: {
                        trans: 'NOTIFICATIONS.ECHEANCE',
                        date: this.deadline ? Globals.getLocaleString(this.deadline) : null
                    },
                    className: 'is-info'
                };
            }
            case NotificationStatus.APPROVED: {
                return {
                    message: { trans: 'NOTIFICATIONS.APPROUVE' },
                    className: 'is-approved'
                };
            }
            case NotificationStatus.CONFIRMED: {
                return {
                    message: { trans: 'NOTIFICATIONS.CONFIRMED' },
                    className: 'is-approved'
                };
            }
            case NotificationStatus.REFUSED: {
                return {
                    message: { trans: 'NOTIFICATIONS.REJETE' },
                    className: 'is-rejected'
                };
            }
            case NotificationStatus.OUTDATED: {
                return {
                    className: 'is-info',
                    message: {
                        trans: 'NOTIFICATIONS.OUTDATED',
                        date: this.deadline ? Globals.getLocaleString(this.deadline) : null
                    }
                };
            }
            default:
                return { message: { trans: '' }, className: 'is-info' };
        }
    }

    setActionId(id: NotificationAction) {
        this.actionId = id;
        return this;
    }

    getExpirationDate = (model: 'year' | 'month' | 'day' = 'year'): Date => {
        const startDate = this.type === NotificationType.PROMOTION ? this.promotion.dateFrom : this.deadline;
        const expirationValue = this.type === NotificationType.PROMOTION ? this.promotion.monthsNumber : 1;
        let date = new Date();
        if (!startDate || isNaN(expirationValue)) {
            return date;
        }
        date = new Date(startDate);
        switch (model) {
            case 'year':
                date.setFullYear(date.getFullYear() + expirationValue);
                break;
            case 'month':
                date.setMonth(date.getMonth() + expirationValue);
                break;
            case 'day':
                date.setDate(date.getDate() + expirationValue);
                break;
        }
        return date;
    };
}


export class NotificationContentBuilder {
    private content: NotificationContent;

    constructor(payload?: NotificationContent) {
        this.content = {} as NotificationContent;
        if (payload) {
            Object.keys(payload).forEach((key: string) => {
                this.content[ key ] = payload[ key ] || '';
            });
        }
    }

    get type(): string | undefined {
        return this.content ? this.content.type : '';
    }

    get name(): string | undefined {
        return this.content ? this.content.name : '';
    }

    get org(): string | undefined {
        return this.content ? this.content.org : '';
    }

    get image(): string | undefined {
        return this.content ? this.content.image : '';
    }

    setType(type: string): this {
        this.content.type = type;
        return this;
    }

    setName(name: string): this {
        this.content.name = name;
        return this;
    }

    setImage(image: string): this {
        this.content.image = image;
        return this;
    }

    setOrg(org: string): this {
        this.content.org = org;
        return this;
    }

    build(): NotificationContent {
        return this.content;
    }
}
