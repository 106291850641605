import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { MyCalendarsComponent } from './components/my-calendars/my-calendars.component';
import { ProgramCalendarComponent } from './components/program-calendar/program-calendar.component';
import { ProgramSingleComponent } from './components/program-single/program-single.component';
import { ProgramCalendarHeaderComponent } from './components/program-calendar/program-calendar-header/program-calendar-header.component';
import { NotificationsComponent } from './components/program-single/notifications/notifications.component';
import { DateProgramsComponent } from './components/date-programs/date-programs.component';
import { TourShowProgramComponent } from './components/date-programs/tour-show-program/tour-show-program.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ProgramAdminComponent } from './components/program-admin/program-admin.component';
import { ProgramAdvancedSearchModule } from './components/program-calendar/program-advanced-search/program-advanced-search.module';

@NgModule({
    declarations: [
        MyCalendarsComponent,
        ProgramCalendarComponent,
        ProgramSingleComponent,
        ProgramCalendarHeaderComponent,
        NotificationsComponent,
        DateProgramsComponent,
        TourShowProgramComponent,
        ProgramAdminComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        TranslateModule,
        SharedModule,
        OverlayModule,
        PortalModule,
        ProgramAdvancedSearchModule
    ],
    exports: [ProgramCalendarComponent, ProgramCalendarHeaderComponent, NotificationsComponent]
})
export class ProgramModule {}
