


<app-page-title
[withNoMargin]='true'
[title]='title'
[subtitle]='subtitle'
>
  <button
    class="btn d-flex align-items-center justify-content-around"
    nzType="default"
    (click)='navigateToShowOffer()'
  >
    <i class='mr-2' nz-icon nzType="eye" nzTheme="outline"></i>
    {{ 'SEE-SHOW-OFFER' | translate}}
  </button>
</app-page-title>
<ng-container  *ngIf="tour && tourShow">
  <app-organization-banner [tour]='tour' [tourShow]='tourShow' ></app-organization-banner>
  <app-show-identification [backGroundColor]='"#ecebe7"' [TourShow]='tourShow' ></app-show-identification>
  <app-show-modalities [tourShow]='tourShow'></app-show-modalities>
</ng-container>

<div class="container mb-2">
  <button
    class="btn d-flex align-items-center justify-content-around"
    nzType="default"
    (click)='navigateToDepot()'
  >
    <i class='arrowIcon mr-2 icon-arrow-right' ></i>
    {{ 'RETURN-TO-DEPOT' | translate}}
  </button>
</div>


