<div class="item-row">
    <div class="item-row__col item-row__col--avatar item-row__avatar left-stuff">
        <span class="item-row__avatar-container">
            <img class="img-responsive item-row__avatar-src"
                 src="{{item.itemImgSrc}}"
                 alt="">
        </span>
    </div>


    <div class="item-row__infos right-stuff">
        <div class="item-row__col__row-title">

            <p class="item-row__text item-row__text--title">
                {{item.itemTitle| shortenText}}
            </p>

            <p class="item-row__text item-row__text--subtitle">
                {{item.itemSubtitle | shortenText:100 }}
            </p>
        </div>

        <div class="item-row__col right-stuff">
            <div class="status pending"
                 *ngIf="item.itemStatus === objectStatus.NON_SOUMIS">
                {{'STATE.EN-ATTENTE-PAIEMENT' | translate}}
            </div>
            <div class="status pending"
                 *ngIf="item.itemStatus === objectStatus.SOUMIS">
                {{'STATE.EN-ATTENTE-APPROBATION' | translate}}
            </div>
            <div class="status warning"
                 *ngIf="item.itemStatus === objectStatus.REJETE">
                {{'STATE.REJETE' | translate}}
            </div>
            <div class="status errors"
                 *ngIf="item.itemStatus === objectStatus.OUTDATED">
                {{'STATE.TERMINE' | translate}}
            </div>

            <div class="status success"
                 *ngIf="item.itemStatus === objectStatus.APPROUVE">
              {{item.itemDate}}
            </div>

            <div class="buttons">
                <button type="button" class="btn-square" *ngIf="item.itemStatus === objectStatus.OUTDATED && showRenewButton" (click)="renewItem.emit()" [title]="'RENOUVELER' | translate">
                    <i nz-icon aria-hidden="true" nzType="sync" nzTheme="outline"></i>
                </button>
    
                <button type="button" class="btn-square" (click)="delete()" *ngIf="[objectStatus.REJETE, objectStatus.NON_SOUMIS].includes(item.itemStatus)" [title]="'SUPPRIMER' | translate">
                    <i class="icon-trash"></i>
                </button>

                <button type="button" class="btn-square" (click)="modifyItem.emit()" *ngIf="[objectStatus.REJETE, objectStatus.NON_SOUMIS].includes(item.itemStatus)" [title]="'MODIFIER' | translate">
                    <i class="icon-pencil"></i>
                </button>
            </div>
        </div>
    </div>
</div>
