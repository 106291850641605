import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectedFilter } from 'src/app/shared/model/list-item.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationTypes } from '../../../../organization/enums/organization-types.enum';

@Component({
  selector: 'app-meeting-participant-advanced-search',
  templateUrl: './meeting-participant-advanced-search.component.html',
  styleUrls: ['./meeting-participant-advanced-search.component.scss'],
})
export class MeetingParticipantAdvancedSearchComponent implements OnInit {
  @Output()
  filtersChange = new EventEmitter<SelectedFilter[]>();

  public organizationTypes;
  selectedOrganizationTypes: OrganizationTypes[];
  organizationTypeSubject = new Subject<any>();
  organizationTypeFilter: SelectedFilter = {
    field: 'organizationTypeId',
    value: null,
  };

  lang;

  constructor(
    private organizationService: OrganizationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.organizationTypes = OrganizationTypes;
    this.selectedOrganizationTypes = null;

    this.organizationTypeSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.organizationTypeFilter.value =
          value && value.length > 0 ? value : null;
        this.handleChange();
      });
  }

  private pipeDebounce(subject: Subject<any>): Observable<any> {
    return subject.pipe(debounceTime(300), distinctUntilChanged());
  }

  handleChange(): void {
    const filters: SelectedFilter[] = [];
    if (this.organizationTypeFilter.value) {
      filters.push(this.organizationTypeFilter);
    }
    this.filtersChange.emit(filters);
  }
}
