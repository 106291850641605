import { Permission, Role } from './../../../shared/enums/roles.enum';
/**
 * Représente le role qu'a un utilisateur pour une organisation
 */
export class UserMembership {
  id: number;
  email: string;
  userId: number;
  organizationId: number;
  roleId: Role;

  constructor(data: Record<string, any>) {
    this.id = data['id'];
    this.email = data['email'];
    this.userId = data['userId'];
    this.organizationId = data['organizationId'];
    this.roleId = data['roleId'];
  }
}

/**
 * Représente les premissions associées à un role
 */
export class PermissionPerRole {
  roleId: Role;
  permissions: Permission[];

  constructor(data: Record<string, any>) {
    this.roleId = data['roleId'];
    this.permissions = data['permissions'];
  }
}
