import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainService } from 'src/app/shared/services/main.services';
import { Globals } from '../../../_configs/globals';
import { ICreditCard } from '../models/credit-card.model';
import { Transaction } from '../models/transaction.model';

@Injectable({
    providedIn: 'root'
})
export class CreditCardService extends MainService {
    constructor(private authService: AuthService, httpClient: HttpClient, global: Globals) {
        super(httpClient, global);
    }

    saveCard(creditCard: ICreditCard): Observable<any> {
        const endpoint: string = this.globals.endpoints.transaction.save;
        return this.httpClient.post(this.uri + endpoint, creditCard);
    }
    getCards(organizationId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.transaction.getCardsEndpoint(organizationId);
        return this.httpClient.get(endpoint);
    }

    getUserCards(userId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.transaction.getUserCardsEndpoint(userId);
        return this.httpClient.get(endpoint);
    }

    removeCard(cardId, organizationId): Observable<any> {
        const endpoint: string = this.globals.endpoints.transaction.getDeleteCardEndpoint(cardId, organizationId);
        return this.httpClient.delete<any>(this.uri + endpoint).pipe();
    }

    getHistory(organizationId: number): Observable<Transaction[]> {
        let endpoint = '';
        if (organizationId !== -1) {
            endpoint = this.uri + this.globals.endpoints.transaction.getHistoryEndpoint(organizationId);
        } else {
            endpoint = this.uri + this.globals.endpoints.transaction.getUserHistoryEndpoint(this.authService.User.id);
        }

        return this.httpClient.get(endpoint).map((transactions: any[]) => transactions.map((transaction) => new Transaction(transaction)));
    }

    getUserTransactionsProductTypeHistory(userId: number, productTypeId: number): Observable<Transaction[]> {
        const endpoint = this.uri + this.globals.endpoints.transaction.getUserHistoryProductTypeEndpoint(userId, productTypeId);
        return this.httpClient.get(endpoint).map((transactions: any[]) => transactions.map((transaction) => new Transaction(transaction)));
    }

    getTransactionsProductTypeHistory(orgId: number, productTypeId: number): Observable<Transaction[]> {
        const endpoint = this.uri + this.globals.endpoints.transaction.getHistoryProductTypeEndpoint(orgId, productTypeId);
        return this.httpClient.get(endpoint).map((transactions: any[]) => transactions.map((transaction) => new Transaction(transaction)));
    }

    getUserHistory(userId: number): Observable<Transaction[]> {
        const endpoint = this.uri + this.globals.endpoints.transaction.getUserHistoryEndpoint(userId);
        return this.httpClient.get(endpoint).map((transactions: any[]) => transactions.map((transaction) => new Transaction(transaction)));
    }

    getProductsForTransaction(transactionId: number, organizationId?: number): Observable<any[]> {
        let endpoint = this.uri + this.globals.endpoints.transaction.getTransactionProductsEndpoint(transactionId);
        if (organizationId) {
            endpoint += `?organizationId=${organizationId}`;
        } else {
            endpoint += `?userId=${this.authService.User.id}`;
        }
        return this.httpClient.get<any[]>(endpoint);
    }
}
