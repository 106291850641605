import { LocalizeRouterService } from 'localize-router';
import { Subscription } from 'rxjs/Subscription';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { BillingItem } from '../../../../concepts/membership/model/billing-item.model';
import { NetworkService } from 'src/app/concepts/organization/services/network.service';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from '../../../services/rideau-notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-row-billing-subscription',
  templateUrl: './item-row-billing-subscription.component.html',
  styleUrls: ['./item-row-billing-subscription.component.scss'],
})
export class ItemRowBillingSubscriptionComponent implements OnInit, OnDestroy {
  @Output()
  public paid: EventEmitter<any> = new EventEmitter();

  @Input() subscription: BillingItem;

  public showCreditCards = false;

  public currentCtxId = this.accountService.getCurrentCtxOrganizationId();

  private subscription$: Subscription;

  public isLoading = false;

  constructor(
    private organizationService: OrganizationService,
    private notification: RideauNotificationService,
    private localizRouter: LocalizeRouterService,
    private accountService: AccountService,
    private networkService: NetworkService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscription$ = this.accountService.currentOrganizationChange.subscribe(
      () =>
        (this.currentCtxId = this.accountService.getCurrentCtxOrganizationId())
    );
  }

  showPayment(): void {
    this.showCreditCards = !this.showCreditCards;
  }

  markAsPayed() {
    console.log('mark as Payed');
    this.networkService.setNetworkBillPayed(this.subscription).subscribe(() => {
      this.showCreditCards = false;
      this.notification.success(this.translate.instant('PAIEMENT-ENREGISTRE'));
      this.paid.emit('payed');
    });
  }

  checkout(event) {
    this.isLoading = true;

    const paiement = {
      organizationId: this.subscription.organizationId,
      membershipId: this.subscription.id,
      paymentMethodId: event.selectedCard,
      cardCvv: event.cvvNumber,
      frontAmount: Number(this.subscription.totalFee),
    };
    this.organizationService.payNetworkMemberShip(paiement).subscribe(
      (res) => {
        this.showCreditCards = false;
        this.notification.success(
          this.translate.instant('PAIEMENT-ENREGISTRE')
        );
        this.isLoading = false;
        const route: any[] = this.localizRouter.translateRoute([
          '/organization',
          this.subscription.organizationId,
          'bill',
          res['transactionId'],
        ]) as any[];
        this.router.navigate(route);
      },
      (err) => {
        this.notification.error(
          this.translate.instant('ERRORS.PAIEMENT-ERROR')
        );
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
