import { Pipe, PipeTransform } from '@angular/core';
type CaseTypes = 'capitalize' | 'camelCase' | 'pascalCase';
@Pipe({ name: 'case' })
export class CasePipe implements PipeTransform {
    transform(value: string, caseType: CaseTypes): string {
        switch (caseType) {
            case 'pascalCase':
                return this.pascalCase(value);
            case 'camelCase':
                return this.camelCase(value);
            case 'capitalize':
                return this.capitalize(value);
            default:
                return value;
        }
    }

    private capitalize = (value: string): string => value.substring(0, 1).toUpperCase() + value.substring(1, value.length).toLowerCase();

    private camelCase = (value: string): string => {
        const match = /\s/gm.exec(value);
        if (match) {
            const firstString = value.substring(0, match.index).toLowerCase();
            const rest = this.pascalCase(value.substring(match.index, value.length));
            return firstString.concat('', rest);
        }
        return value;
    };
    private pascalCase = (value: string): string =>
        value
            .split(' ')
            .map((v: string) => this.capitalize(v))
            .join('');
}
