import { ItemFileType } from 'src/app/shared/model/item-row-file.model';
import { FormItemSelect } from 'src/app/shared/services/form.row.select.service';
export enum ContentType {
    SHOW = 1,
    VITRINE = 2
}

export enum VitrineAdditionalMaterialTypeBase {
    CALENDRIER_TOURNEE = 1,
    BIOGRAPHIE = 2,
    DOSSIER_PRESSE = 3,
    LETTRE_PRESENTATION = 4
}

export enum VitrineAdditionalMaterialType {
    CALENDRIER_TOURNEE = 1,
    BIOGRAPHIE = 2,
    DOSSIER_PRESSE = 3,
    LETTRE_PRESENTATION = 4,
    PHOTO = 5
}
export function getAdditionalMaterialTypeById(index: number): string {
    return VitrineAdditionalMaterialType[index];
}

export function getVitrineAdditionalMaterialTypeList(): ItemFileType[] {
    return enumToArray(VitrineAdditionalMaterialType);
}

export function getVitrineAdditionalMaterialTypeBaseList(): ItemFileType[] {
    return enumToArray(VitrineAdditionalMaterialTypeBase);
}

export function getVitrineAdditionalMaterialTypeBaseFormSelectItemList(): FormItemSelect[] {
    return getVitrineAdditionalMaterialTypeBaseList().map((item): FormItemSelect => ({ id: item.typeId }));
}

const enumToArray = <T>(value: T): ItemFileType[] => {
    const enumLength = Object.keys(value).length;
    const enumKeys = Object.values(value);
    enumKeys.splice(0, enumLength / 2);
    return enumKeys.reduce(
        (acc: ItemFileType[], curr: number) => [
            ...acc,
            {
                label: value[curr],
                typeId: curr
            }
        ],
        []
    );
};
