<div nz-row>
   <div nz-col
        nzSpan="24">
      <!-- SECTION TITLE BAR -->
      <div class="section--title" style="background:#FAF9F7; padding:15px 17px 11px 17px">
         <div class="section--title--title" >
            <h4 style="font-size:15px !important; padding-top:8px">{{title}} </h4>
         </div>
         <ng-container
                       *appPermission="permission;objectOrganizationId:organizationId;organizationType:orgaType;shouldBeApproved:shouldBeApproved">
            <div *ngIf="buttonText"
                 class="section--title--button">
               <a class="btn-rect"
                       *ngIf="buttonLink; else otherButton"
                       [routerLink]="buttonLink | localize">{{buttonText}}</a>
               <ng-template #otherButton>
                  <button class="btn-rect"
                           style="padding: 1px 18px 0px 13px"
                          (click)="buttonClicked.emit()"
                          [disabled]="buttonIsLoading">
                          <i id="iconPlus"
                          nz-icon aria-hidden="true" nzType="plus" nzTheme="outline"></i>
                          {{buttonText}}</button>
               </ng-template>
            </div>
         </ng-container>
      </div>
   </div>
</div>
