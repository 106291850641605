import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToursService } from './services/tours.service';
import { Observable, combineLatest, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';

@Injectable({ providedIn: 'root' })
export class TourAdminResolver implements Resolve<boolean> {
  constructor(
    private readonly toursService: ToursService,
    private readonly authService: AuthService,
    private readonly userOrganizationIdResolver: UserOrganizationIdResolver
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.userOrganizationIdResolver.resolve(),
      from(this.authService.populateUser()),
      this.toursService.getTourById(+route.paramMap.get('tourId'))
    ]).pipe(
      map(([contextOrganizationId, user, tour]) => {
        return (
          tour.admins.some((admin) => admin.user.id === user.id) &&
          contextOrganizationId === tour.organizationId
        );
      })
    );
  }
}
