<form id="organisation-step-2" class="mb-4" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row>
        <div nz-col nzMd="18" formArrayName="disciplines">
            <label for="">
                <span>{{ 'FORM.DISCIPLINES' | translate }} *</span>
                <div class="mt-3">
                    <nz-form-item *ngFor="let discipline of controlDisciplinesArray.controls; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-10">
                                <nz-cascader
                                    id="{{ 'disciplines-' + i }}"
                                    nzPlaceHolder="{{ 'FORM.DISCIPLINES-SELECT' | translate }}"
                                    nzChangeOnSelect
                                    [nzExpandTrigger]="'hover'"
                                    [formControlName]="'cascade'"
                                    [nzOptions]="classificationsListCascade"
                                    [nzAllowClear]="false"
                                ></nz-cascader>
                            </div>
                            <div class="col-2">
                                <button
                                    class="btn btn--small d-inline-block"
                                    nzSize="large"
                                    nz-button
                                    (click)="removeDiscipline(i, $event)"
                                    nzType=""
                                    style="padding: 5px 12px 1px 11px; margin-top: 5px"
                                >
                                    <i class="icon-trash" style="font-size: 17px !important"></i>
                                </button>
                            </div>
                        </div>
                    </nz-form-item>
                </div>
            </label>
        </div>

        <div nz-col nzMd="18">
            <div class="row mb-3">
                <div class="col-10">
                    <nz-form-item *ngIf="classificationsListCascade.length > 0">
                        <nz-cascader
                            id="addDisciplines"
                            nzPlaceHolder="{{ 'FORM.DISCIPLINES-SELECT' | translate }}"
                            nzChangeOnSelect
                            [nzExpandTrigger]="'hover'"
                            [formControlName]="'discipline'"
                            [nzOptions]="classificationsListCascade"
                            (nzSelectionChange)="onAddChanges($event)"
                            (nzVisibleChange)="addNewClassification($event)"
                            [ngClass]="{ 'error-border': displayErrors && controlDisciplinesArray.controls.length === 0 }"
                        ></nz-cascader>
                        <nz-form-explain class="has-error" *ngIf="displayErrors && controlDisciplinesArray.controls.length === 0">
                            {{ 'CHOISIR-UNE-DISCIPLINE' | translate }}
                        </nz-form-explain>
                    </nz-form-item>
                </div>
            </div>
        </div>
        <div nz-col nzMd="18">
            <nz-form-item formGroupName="audiences">
                <nz-form-control>
                    <label>{{ 'FORM.PUBLIC-CIBLE' | translate }} *</label>
                    <p class="has-error" *ngIf="formGroup.get('audiences').errors && formGroup.get('audiences').errors.hasOneOrManyRequiredError">
                        {{ 'CHOISIR-UNE-AUDIENCE' | translate }}
                    </p>
                    <label class="d-block ml-0 mb-2" formControlName="isAllPublic" nz-checkbox>{{ 'FORM.GRAND_PUBLIC' | translate }}</label>
                    <label class="d-block ml-0 mb-2" formControlName="isBabies" nz-checkbox>{{ 'FORM.BEBES' | translate }}</label>
                    <label class="d-block ml-0 mb-2" formControlName="isInfants" nz-checkbox>{{ 'FORM.TOUT_PETITS' | translate }}</label>
                    <label class="d-block ml-0 mb-2" formControlName="isSmallKids" nz-checkbox>{{ 'FORM.PETIT_ENFANTS' | translate }}</label>
                    <label class="d-block ml-0 mb-2" formControlName="isKids" nz-checkbox>{{ 'FORM.ENFANTS' | translate }}</label>
                    <label class="d-block ml-0 mb-2" formControlName="isYoung" nz-checkbox>{{ 'FORM.JEUNES' | translate }}</label>
                    <label class="d-block ml-0 mb-2" formControlName="isAdults" nz-checkbox>{{ 'FORM.ADULTES' | translate }}</label>
                    <label class="d-block ml-0" formControlName="isElders" nz-checkbox>{{ 'FORM.AINES' | translate }}</label>
                    <label class="d-block ml-0" formControlName="isFamily" nz-checkbox>{{ 'FORM.FAMILLES' | translate }}</label>
                </nz-form-control>
            </nz-form-item>
        </div>

        <div nz-col nzMd="18">
            <nz-form-item>
                <nz-form-control>
                    <label class="d-block">{{ 'FORM.CONTENU' | translate }}</label>
                    <label nz-checkbox formControlName="hasViolence">{{ 'FORM.VIOLENCE' | translate }}</label>
                    <label nz-checkbox formControlName="hasNudity">{{ 'FORM.NUDITE' | translate }}</label>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <ng-container *ngIf="displaySaveForLater()">
        <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid"
        class="btn btn--primary">
          {{ "ENREGISTER-CONTINUER" | translate }}
        </button>
        <p *appPermission="1; objectOrganizationId: show.organizationId" class="d-inline-block ml-2">
          {{ "OU" | translate }}
          <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{
            "ENREGISTER-TERMINER-PLUS-TARD" | translate
            }}</a>
          <span *ngIf="isLoading">{{
            "ENREGISTER-TERMINER-PLUS-TARD" | translate
            }}</span>
        </p>
      </ng-container>
      <ng-container *ngIf="displaySubmit()">
        <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid"
         class="btn btn--primary">
          {{ "ENREGISTER" | translate }}
        </button>
      </ng-container>
</form>
