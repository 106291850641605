<div nz-row
     class="mb-3"
     nzGutter="24">

  <div nz-col
       nzMd="16">
    <label for="organizationTypes">{{'FORM.TYPE-ORGANISATION' | translate}}</label>
    <br>
    <nz-select id="organizationTypes"
               [(ngModel)]="selectedOrganizationTypes"
               nzMode="multiple"
               style="width: 100%"
               (ngModelChange)="this.organizationTypeSubject.next($event)">
      <nz-option [nzValue]="organizationTypes.IS_PRODUCTEUR"
                 nzLabel="{{ 'ORGANIZATION-TYPE_1' | translate }}"></nz-option>
      <nz-option [nzValue]="organizationTypes.IS_DIFFUSEUR"
                 nzLabel="{{ 'ORGANIZATION-TYPE_2' | translate }}"></nz-option>
      <nz-option [nzValue]="organizationTypes.IS_SALLE"
                 nzLabel="{{ 'ORGANIZATION-TYPE_3' | translate }}"></nz-option>
      <nz-option [nzValue]="organizationTypes.IS_GOVERNMENTAL"
                 nzLabel="{{ 'ORGANIZATION-TYPE_4' | translate }}"></nz-option>
      <nz-option [nzValue]="organizationTypes.IS_PROVIDER"
                 nzLabel="{{ 'ORGANIZATION-TYPE_5' | translate }}"></nz-option>
      <nz-option [nzValue]="organizationTypes.IS_MEDIA"
                 nzLabel="{{ 'ORGANIZATION-TYPE_6' | translate }}"></nz-option>
      <nz-option [nzValue]="organizationTypes.IS_OTHER"
                 nzLabel="{{ 'ORGANIZATION-TYPE_7' | translate }}"></nz-option>
    </nz-select>
  </div>
</div>
