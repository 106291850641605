<ng-container *ngIf="isReady">

  <ng-container *ngIf="audioType !== undefined">
    <!-- SOUNDCLOUD PLAYER -->
    <ng-container *ngIf="audioType == 'soundcloud'">
      <iframe [src]="soundCloudAudio" width="100%" height="166"
              scrolling="no" frameborder="no" allow="autoplay" title="soundCloudAudio"></iframe>
    </ng-container>

    <!-- OTHERS AUDIOS -->
    <ng-container *ngIf="audioType !== 'soundcloud'">
      <div class="noPlayerBox">
        <a [href]="src | externalLink" target="_blank">
          <i nz-icon aria-hidden="true" nzType="customer-service" nzTheme="outline"></i>
          <span class="ml-3"> {{'OTHERS-AUDIO-LINK' | translate}} </span>
        </a>
      </div>
    </ng-container>

  </ng-container>

  <!-- NO AUDIO -->
  <ng-template #noContentAudio>
    <p>{{'AUCUN-LIEN-AUDIO' | translate }}</p>
  </ng-template>


</ng-container>
