
    <nz-form-item>
      <label [attr.for]="id">
        <ng-content></ng-content>
      </label>
      <nz-form-control
        [nzValidateStatus]="
          ngControl.touched && ngControl.errors ? 'error' : ''
        "
      >
        <nz-date-picker
          placeholder="yyyy/mm/dd"
          nzAllowClear="true"
          nzFormat="yyyy/MM/dd"
          [(ngModel)]="selected"
          (ngModelChange)="ngModelChange()"
          [nzDisabled]="disabled"
        >
        </nz-date-picker>
        <nz-form-explain
          class="has-error"
          *ngIf="
            ngControl.touched && ngControl.errors && ngControl.errors.required
          "
        >
          {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
        </nz-form-explain>
      </nz-form-control>
    </nz-form-item>
  