import { Component, OnInit } from '@angular/core';
import { VenueService } from '../../services/venue.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from 'src/app/concepts/location/services/location.service';
import { Venue } from '../../model/venue.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { Address } from 'src/app/concepts/location/models/location.model';
import { VenueConfigurationType } from '../../enums/venue-configuration-type.enum';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { WikiArtsDataValidation } from '@app/shared/services/wikiArtsData.validation';

@Component({
    selector: 'app-venue-single',
    templateUrl: './venue-single.component.html',
    styleUrls: ['./venue-single.component.scss']
})
export class VenueSingleComponent implements OnInit {
    /**
     * An instance of the Venue class representing the venue currently being displayed.
     * @type {Venue}
     */
    public venue: Venue;

    /**
     * The ID of the venue currently being displayed.
     * @type {number}
     */
    public venueId = 0;

    /**
     * The language code currently being used.
     * @type {string}
     */
    public lang: string;

    /**
     * The link to the page for editing the current venue.
     * @type {string}
     */
    public editVenueLink: string;

    /**
     * An instance of the Organization class representing the organization that owns the current venue.
     * @type {Organization}
     */
    public organization: Organization;

    /**
     * The link to the page for displaying the current organization.
     * @type {string}
     */
    public organizationLink: string;

    /**
     * A flag indicating whether the class instance has finished initializing and is ready to display data.
     * @type {boolean}
     */
    public isReady = false;

    /**
     * The minimum capacity among all the venue configurations for the current venue.
     * @type {number}
     */
    public minConfigCapacity: number;

    /**
     * The maximum capacity among all the venue configurations for the current venue.
     * @type {number}
     */
    public maxConfigCapacity: number;

    /**
     * The translated name of the state or province where the current venue is located.
     * @type {string}
     */
    public venueState: string;

    /**
     * A string representing the description of the current venue, in Markdown format.
     * @type {string}
     */
    public descriptionModel: string;
    /**
     * The base URL for Wikidata.
     * @type {string}
     */
    public readonly wikidataUrlBase: string = WikiArtsDataValidation.wikidataUrlBase;

    constructor(
        private venueService: VenueService,
        private organizationService: OrganizationService,
        private translate: TranslateService,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        public location: LocationService
    ) {}

    ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.venueId = this.route.snapshot.params['venueId'];
        this.getSingleVenue();
    }

    /**
     * * Retrieves the label of a venue configuration type based on the provided numerical value.
     * @param value - The numerical value of the venue configuration type.
     * @returns {string} - The label of the venue configuration type with the given numerical value.
     * If there is no venue configuration type with the given numerical value,
     * then the function returns undefined.
     */
    public getConfigurationLabel = (value: number): string => {
        try {
            return this.getVenueConfigurationTypes()[value - 1].label;
        } catch (error) {
            return error;
        }
    };

    /**
     * * Retrieves a single venue with the given venue ID and sets the necessary properties
     * of the class instance to the retrieved venue data.
     * @returns {void} - This function does not return anything,
     * but it sets several properties
     * of the class instance, including the retrieved venue data, the readiness state,
     * the minimum and maximum capacity of the venue configurations, and the venue state.
     * It also sets a breadcrumb item and the edit venue link based on the retrieved venue data.
     */
    private getSingleVenue(): void {
        this.venueService.getVenue(this.venueId).subscribe((data: Venue) => {
            this.venue = new Venue(data);
            this.isReady = true;
            this.editVenueLink = `/venue/${this.venue.organizationId}/${this.venue.id}/modify`;
            this.getSingleOrganization();

            // Set Breadcrumb
            const venueName = this.venue.getTranslatedProperty(this.lang, 'name');

            const breadcrumbItem = new BreadcrumbItem();
            breadcrumbItem.set(venueName);
            this.breadcrumbService.addBreadcrumbCascade([breadcrumbItem]);

            const configurationsCapacity = this.venue.configurations.map((x) => x.capacity);
            this.minConfigCapacity = Math.min(...configurationsCapacity);
            this.maxConfigCapacity = Math.max(...configurationsCapacity);

            if (this.venue.addressId !== null) {
                this.location.getAddress(this.venue.addressId).subscribe((locationData: any) => {
                    const adr = new Address(locationData[0]);
                    this.venueState = adr.getTranslatedState(this.lang) || this.venue.address.otherState;
                });
            }
        });
    }

    /**
     * * Retrieves a single organization with the ID corresponding to the venue currently being displayed,
     * and sets the necessary properties of the class instance to the retrieved organization data.
     * @returns {void} - This function does not return anything, but it sets the organization property
     * of the class instance to the retrieved organization data, and sets the `organizationLink`
     * property based on the retrieved organization data.
     */
    private getSingleOrganization(): void {
        this.organizationService.getOrganization(this.venue.organizationId).subscribe((data: Organization) => {
            this.organization = data;
            const organizationRoute = this.translate.instant('ROUTES.organization');
            this.organizationLink = `/${this.lang}/${organizationRoute}/${this.venue.organizationId}`;
        });
    }

    /**
     * * Retrieves an array of venue configuration types with their corresponding labels and numerical values.
     * @returns {Array} - An array of objects containing the label and numerical value of each venue configuration type.
     */
    private getVenueConfigurationTypes = (): { label: string; value: number }[] =>
        Object.keys(VenueConfigurationType)
            .filter((key) => !Number(key))
            .map((key, index) => ({
                label: this.translate.instant('FORM.' + key),
                value: index + 1
            }));
}
