import { Globals } from './../../../../../_configs/globals';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { ShowService } from '../../../services/show.service';
import { AbstractShowForm } from '../show-abstract-form/show-abstract-form.component';
import { ShowAudiences } from './../../../enums/show-audiences.enum';
import { buildTreesClassifications } from '../../../util/discipline-cascader.util';
import { Classification } from '../../../model/classification.model';
import { ShowStatus } from '@app/concepts/show/enums/show-status.enum';

@Component({
    selector: 'app-show-classification-form',
    templateUrl: './show-classification-form.component.html',
    styleUrls: [ './show-classification-form.component.scss' ]
})
export class ShowClassificationFormComponent extends AbstractShowForm implements OnInit {
    controlDisciplinesArray: FormArray;
    isDisabled = false;
    classificationsList: Classification[] = [];
    classificationsListCascade: any = [];
    currentDiscipline: any = [];

    constructor(private translate: TranslateService, private showService: ShowService, protected globals: Globals, protected accountService: AccountService) {
        super(accountService, globals);
    }

    ngOnInit(): void {
        this.initForm();

        //Temp push socialsArray value into social Array
        this.controlDisciplinesArray = <FormArray>this.formGroup.get('disciplines');

        this.getDisciplineList();
    }

    protected initForm() {
        this.formGroup = this.show.getClassificationFormGroup();
    }

    patchValues(classif) {
        return new FormGroup({
            cascade: new FormControl(classif)
        });
    }

    getDisciplineList() {
        // Création de la cascade à partir de la liste de toutes les disciplines
        this.showService.getClassificationsList().subscribe((classifications) => {
            if (classifications) {
                // Construire la liste des classifications pour le cascader
                this.classificationsList = classifications;

                buildTreesClassifications(classifications, this.classificationsListCascade, this.translate);
            }
            // Obtenir les cascades d'un show
            this.getShowClassification();
        });
    }

    private getShowClassification() {
        // current classification
        this.show.classifications.forEach((x) => {
            this.controlDisciplinesArray.push(this.patchValues(this.formatShowClassification(x)));
        });
    }

    displaySubmit() {
        return this.formGroup && !this.formGroup.disabled && this.show && (this.show.statusId === ShowStatus.SOUMIS || this.show.statusId === ShowStatus.APPROUVE);
    }

    displaySaveForLater() {
        return this.show && (this.show.statusId === ShowStatus.NON_SOUMIS || this.show.statusId === ShowStatus.REJETE || this.show.statusId === ShowStatus.EXPIRE);
    }

    formatShowClassification(showClassification) {
        let classificationPath = [];
        const result: any[] = [];
        if (showClassification) {
            classificationPath = this.createShowCascade(result, showClassification);
        }
        return classificationPath;
    }

    createShowCascade(result, id) {
        if (this.classificationsList) {
            const main = this.classificationsList.filter((x) => x.id == id).pop();
            result.unshift(main.id.toString());

            // Récursive si le noeud actuel a un parent
            if (main.parentId) {
                this.createShowCascade(result, main.parentId);
            }
            return result;
        }
        return [];
    }

    onAddChanges(event) {
        this.currentDiscipline = event;
    }

    addNewClassification(event) {
        if (event === false && this.currentDiscipline.length) {
            this.formGroup.controls[ 'discipline' ].setValue([]);
            this.controlDisciplinesArray.push(this.patchValues(this.currentDiscipline.map((x) => x.value)));
            this.currentDiscipline = [];
        }
    }

    removeDiscipline(index, event) {
        event.preventDefault();
        this.controlDisciplinesArray.controls.splice(index, 1);
    }

    doSubmit(param?: any) {
        this.showValue.audiences = [];
        this.showValue.classifications = [];
        if (this.formGroup.value.audiences.isAllPublic) {
            this.showValue.audiences.push(ShowAudiences.GRAND_PUBLIC);
        }
        if (this.formGroup.value.audiences.isBabies) {
            this.showValue.audiences.push(ShowAudiences.BEBES);
        }
        if (this.formGroup.value.audiences.isInfants) {
            this.showValue.audiences.push(ShowAudiences.TOUT_PETITS);
        }
        if (this.formGroup.value.audiences.isSmallKids) {
            this.showValue.audiences.push(ShowAudiences.PETIT_ENFANTS);
        }
        if (this.formGroup.value.audiences.isKids) {
            this.showValue.audiences.push(ShowAudiences.ENFANTS);
        }
        if (this.formGroup.value.audiences.isYoung) {
            this.showValue.audiences.push(ShowAudiences.JEUNES);
        }
        if (this.formGroup.value.audiences.isAdults) {
            this.showValue.audiences.push(ShowAudiences.ADULTES);
        }
        if (this.formGroup.value.audiences.isElders) {
            this.showValue.audiences.push(ShowAudiences.AINES);
        }
        if (this.formGroup.value.audiences.isFamily) {
            this.showValue.audiences.push(ShowAudiences.FAMILLES);
        }

        if (this.controlDisciplinesArray.controls) {
            const classif = this.controlDisciplinesArray.controls.map((x) => {
                const value = x.value[ 'cascade' ].pop();
                if (value) {
                    return parseInt(value);
                }
                return null;
            });
            this.showValue.classifications = classif.filter((x) => x != null);
        }
        this.showValue.hasViolence = this.formGroup.value.hasViolence ? 1 : 0;
        this.showValue.hasNudity = this.formGroup.value.hasNudity ? 1 : 0;

        super.doSubmit(param);
    }
}
