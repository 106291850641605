import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from 'localize-router';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../shared/shared.module';
import { ActivityModule } from '../activities/activity.module';
import { StandModule } from '../stand/stand.module';
import { VitrineModule } from '../vitrine/vitrine.module';
import { InscriptionRowComponent } from './components/meeting-inscription/inscription-row/inscription-row.component';
import { MeetingInscriptionComponent } from './components/meeting-inscription/meeting-inscription.component';
import { MeetingGeneralFormComponent } from './components/meeting-modify/meeting-general-form/meeting-general-form.component';
import { MeetingModifyComponent } from './components/meeting-modify/meeting-modify.component';
import { MeetingParticipantComponent } from './components/meeting-modify/meeting-participant/meeting-participant.component';
import { MeetingProductListComponent } from './components/meeting-product-list/meeting-product-list.component';
import { MeetingSingleComponent } from './components/meeting-single/meeting-single.component';
import { MeetingJuryComponent } from './components/meeting-jury/meeting-jury.component';
import { MyMeetingsLittleComponent } from './components/my-meetings-little/my-meetings-little.component';
import { MeetingParticipantAdvancedSearchComponent } from './components/meeting-modify/meeting-participant-advance-search/meeting-participant-advanced-search.component';

@NgModule({
    declarations: [
        MeetingModifyComponent,
        MeetingGeneralFormComponent,
        MeetingSingleComponent,
        MeetingProductListComponent,
        MeetingInscriptionComponent,
        InscriptionRowComponent,
        MeetingJuryComponent,
        MeetingParticipantComponent,
        MyMeetingsLittleComponent,
        MeetingParticipantAdvancedSearchComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        LocalizeRouterModule,
        NgZorroAntdModule,
        SharedModule,
        ActivityModule,
        VitrineModule,
        StandModule,
        QuillModule,
    ],
    exports: [ MyMeetingsLittleComponent ],
})
export class MeetingModule { }
