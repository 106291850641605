
        <nz-form-item>
            <label [attr.for]="id">
                <ng-content></ng-content>
            </label>
            <nz-form-control>
                <input
                    nz-input
                    [attr.id]="id"
                    [disabled]="disabled"
                    [type]="type"
                    [(ngModel)]="value"
                    (ngModelChange)="ngModelChange()"
                    [class.error]="ngControl.touched && ngControl.errors"
                />
                <p *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.required" class="error">
                    {{ requiredTranslateKey | translate }}
                </p>
                <p *ngIf="ngControl.touched && ngControl.errors && ( ngControl.errors.pattern || ngControl.errors.email )" class="error">
                    {{ emailTranslateKey | translate }}
                </p>
                <p *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.maxlength" class="error">
                    {{ maxLengthTranslateKey | translate: ngControl.errors.maxlength }}
                </p>

                <ng-content select="[after]"></ng-content>
            </nz-form-control>
        </nz-form-item>
    