<app-alert class="danger alert-calender" *ngIf="tourAdmin && routeData.tour && routeData.tour.diffusers?.length && !routeData.tour.isDepositOpen">
  <i nz-icon nzType="lock" nzTheme="fill"></i>
  <span [innerHTML]="'DIFFUSEURS-TOURNEE.ACCES-FERME' | translate"></span>
  <button appButton [click]="openTour">
    {{ 'ACCES-DEPOT-OUVRIR' | translate }}
  </button>
</app-alert>
<app-alert prefix class="success alert-calender" *ngIf="tourAdmin && routeData.tour && routeData.tour.diffusers?.length && routeData.tour.isDepositOpen">
  <i nz-icon nzType="unlock" nzTheme="fill"></i>
  <span [innerHTML]="'DIFFUSEURS-TOURNEE.ACCES-OUVERT' | translate"></span>
  <button appButton [click]="closeTour">
    {{ 'ACCES-DEPOT-FERMER' | translate }}
  </button>
</app-alert>
<app-alert prefix class="danger alert-calender" *ngIf="tourAdmin && routeData.tour && (!routeData.tour.diffusers || !routeData.tour.diffusers.length)">
  <i nz-icon nzType="warning" nzTheme="fill"></i>
  <span [innerHTML]="'DIFFUSEURS-TOURNEE.SANS-DIFFUSEURS' | translate"></span>
  <button appButton [click]="inviteDiffusers">
    {{ 'ACCES-DEPOT-INVITER' | translate }}
  </button>
</app-alert>

