import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from 'localize-router';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { SharedModule } from '../../shared/shared.module';
import { CreateArtistComponent } from '../organization/components/organization-create/create-artist/create-artist.component';
import { MeetingModule } from './../meeting/meeting.module';
import { AccountBillingComponent } from './components/account-billing/account-billing.component';
import { AccountComponent } from './components/account.component';

@NgModule({
  declarations: [
    AccountComponent,
    CreateArtistComponent,
    AccountBillingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    LocalizeRouterModule,
    MeetingModule,
    SharedModule,
  ],
  exports: [AccountComponent, CreateArtistComponent, AccountBillingComponent],
})
export class AccountModule {}
