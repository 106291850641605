<div class="notifications-single">
    <div class="notifications-single__infos" [ngClass]="{ isWelcome: notificationType === notificationTypeEnum.WELCOME }">
        <div class="notifications-single__infos-logo">
            <ng-container *ngIf="notificationType !== notificationTypeEnum.WELCOME; else notifWelcomeImage">
                    <img class="notifications-single__infos-logo-img" alt="" [src]="getNotificationContentByTypeProperty('image')" />
            </ng-container>
            <ng-template #notifWelcomeImage>
                <img src="../../../../../../assets/images/scene-pro-orange.png" class="notifications-single__infos-logo-img" alt="" />
            </ng-template>
            <span [ngClass]="['notifications-single__infos-logo-status', statusClassName]">
                <ng-container
                    [ngTemplateOutlet]="
                        isNotificationStatusInfo
                            ? infoTpl
                            : isNotificationStatusApproved || isNotificationStatusConfirmed
                            ? approvedTpl
                            : isNotificationStatusRefused || isNotificationStatusOutdated
                            ? outdatedRefusedTpl
                            : null
                    "
                ></ng-container>
            </span>
        </div>
        <div class="notifications-single__infos-text">
            <ng-container [ngSwitch]="notificationType">
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.WELCOME">
                    <span class="notifications-single__infos-text-message-title">{{ notificationContent.name }}</span>
                </p>
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.PROGRAM">
                    <span class="notifications-single__infos-text-message-title">
                        <strong>{{ notificationContent.org }}</strong>
                    </span>
                    {{ notificationContent.type }}
                </p>
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.TOUR" [innerHTML]="notificationContent.type"></p>
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.DATE" [innerHTML]="notificationContent.type"></p>
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.VENUE" [innerHTML]="notificationContent.name"></p>
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.ORGANIZATION" [innerHTML]="notificationContent.name"></p>
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.PROMOTION" [innerHTML]="notificationContent.name"></p>
                <p class="notifications-single__infos-text-message" *ngSwitchCase="notificationTypeEnum.SHOW" [innerHTML]="notificationContent.name"></p>
                <p class="notifications-single__infos-text-message" *ngSwitchDefault>
                    {{ 'NOTIFICATIONS.VOTRE' | translate }}
                    <strong>{{ notificationContent.type }}</strong>
                    <span class="notifications-single__infos-text-message-title">{{ notificationContent.name }}</span>
                    {{ statusMessage }}
                </p>
            </ng-container>
            <p class="notifications-single__infos-text-time">{{ notification.createdAt | timeago: live }}</p>
        </div>
    </div>
    <div class="notifications-single__actions">
        <button *ngIf="isActionId" class="notifications-single__actionsBtn action" (click)="notificationActionHandler()">
            {{ actionLabel | translate }}
        </button>
        <button *ngIf="!hideArchiveBtn" class="notifications-single__actionsBtn delete" (click)="archiveNotification()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                    fill-rule="nonzero"
                    d="M6.284 4.87l3.743 3.743 3.745-3.744a1 1 0 0 1 1.415 1.414l-3.745 3.745 3.745 3.745a1 1 0 1 1-1.414 1.414l-3.745-3.745-3.745 3.745a1 1 0 0 1-1.414-1.414l3.744-3.746L4.87 6.284a1 1 0 1 1 1.415-1.415z"
                />
            </svg>
        </button>
    </div>
</div>
<ng-template #outdatedRefusedTpl>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path
            fill="#FFFFFF"
            fill-rule="nonzero"
            d="M6.284 4.87l3.743 3.743 3.745-3.744a1 1 0 0 1 1.415 1.414l-3.745 3.745 3.745 3.745a1 1 0 1 1-1.414 1.414l-3.745-3.745-3.745 3.745a1 1 0 0 1-1.414-1.414l3.744-3.746L4.87 6.284a1 1 0 1 1 1.415-1.415z"
        />
    </svg>
</ng-template>
<ng-template #approvedTpl>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="#FFFFFF" fill-rule="nonzero" d="M8.111 13.321l6.063-8.885a1 1 0 0 1 1.652 1.128l-6.824 10a1 1 0 0 1-1.605.062L4.22 11.67a1 1 0 0 1 1.56-1.252L8.11 13.32z" />
    </svg>
</ng-template>
<ng-template #infoTpl>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path
            fill="#FFFFFF"
            d="M10 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zM10 3c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1s-1-.448-1-1V4c0-.552.448-1 1-1z"
        />
    </svg>
</ng-template>
