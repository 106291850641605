<div class="item-row"
     [ngClass]="{'disabled':billing.isReadyForPay}">
  <div class="item-row__col">
    <span class="ml-3 org-title">
      {{billing.getTranslatedProperty(lang,'name')}}
    </span>
  </div>
  <div class="item-row__col">
    <span *ngIf="billing.isPaid">
      <i nz-icon
      aria-hidden="true"
         [nzType]="'check-circle'"
         style="color: green;"></i>
      {{'PAYE' | translate}}
    </span>
    <span *ngIf="!billing.hasFinanceInfo">
      <i nz-icon
      aria-hidden="true"
         [nzType]="'close-circle'"
         style="color: red;"></i>
      {{'DONNEES-MANQUANTES' | translate}}
    </span>
  </div>

  <div class="item-row__col">

    <ng-container *ngIf="isManualyFixed; else calculated">
      <input type="number"
             class="customFee"
             [disabled]="billing.isReadyForPay"
             [(ngModel)]="billing.customFee"
             (change)="billingChanged()">
    </ng-container>
    <ng-template #calculated>
      <span>{{billing.fee}} $</span>
    </ng-template>

  </div>
  <div class="item-row__col">
    <label nz-checkbox
           [(ngModel)]="isManualyFixed"
           [disabled]="billing.isReadyForPay"
           (ngModelChange)="cbxChanged()">{{ 'MANUALY-FIXED' | translate }}</label>

  </div>
</div>
<nz-divider></nz-divider>
