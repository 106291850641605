<div class="notifications-list">
  <ng-container *ngIf="headerEnabled">
    <div class="notifications-list__header">
      <span class="notifications-list__headerCount">{{ headerTotal ? headerTotal : 0 }} {{ 'NOTIFICATIONS.NOTIFICATIONS' | translate }} </span>
      <button *ngIf="!hideArchiveBtn" class="notifications-list__headerAction" (click)="onArchiveAllCallback()">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
           <path fill-rule="nonzero" d="M6.284 4.87l3.743 3.743 3.745-3.744a1 1 0 0 1 1.415 1.414l-3.745 3.745 3.745 3.745a1 1 0 1 1-1.414 1.414l-3.745-3.745-3.745 3.745a1 1 0 0 1-1.414-1.414l3.744-3.746L4.87 6.284a1 1 0 1 1 1.415-1.415z"/>
       </svg>
       <span> {{ 'ARCHIVE-ALL' | translate }} </span>
     </button>
    </div>
  </ng-container>

  <div class="notifications-list__Dashboard">
    <div class="mb-20">
      <ng-container *ngIf="notifications.length; else noNotification">
        <ng-container *ngFor="let notification of notifications">
            <app-notifications-single
            [hideArchiveBtn]="hideArchiveBtn"
            [notification]="notification"
            (onArchiveNotification)="onArchiveItemCallback($event)"
            ></app-notifications-single>
        </ng-container>
      </ng-container>

      <ng-template #noNotification>
        <div class="noNotification">
          {{"NOTIFICATIONS.NO-NOTIFICATIONS" | translate}}
          <a  *ngIf="!headerEnabled" [routerLink]="'/notifications/archives' | localize"> {{"NOTIFICATIONS.GOTO-ARCHIVED" | translate}}</a>
        </div>
      </ng-template>

    </div>
  </div>
</div>

