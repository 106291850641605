<div class="container">
  <div nz-row nzGutter="16">
    <app-edit-tour-nav></app-edit-tour-nav>

    <div nz-col nzMd="12">
      <h2>{{ 'DIFFUSEURS' | translate }}</h2>

      <p>
        {{ 'DIFFUSEURS-TOURNEE.AJOUTER-TOUS.PREFIX' | translate: context }}
        <button appButton class="link" [click]="addAll">
          {{ 'DIFFUSEURS-TOURNEE.AJOUTER-TOUS.BOUTON' | translate: context }}
        </button>
      </p>

      <app-row-multiple-select
        [options]="routeData.network"
        [selectedOptions]="routeData.tour.diffusers"
        labelProperty="name"
        [(ngModel)]="organizationIds"
        [placeholder]="'DIFFUSEURS-TOURNEE.AJOUTER.PLACEHOLDER' | translate"
        [onAdd]="add"
        [onRemove]="remove"
        >

        <ng-template let-option let-loading="loading" let-remove="remove">
          <div class="selected">
            <img [src]="option.logo || globals.imgFallback">
            <div class="infos">
              <div class="name">{{ option.getTranslatedProperty(translateService.currentLang, 'name') }}</div>
              <div class="city">{{ option.address.city }}</div>
            </div>
            <button type="button"
              class="btn-square"
              [disabled]="loading || option.hasSubmitedPrograms"
              (click)="remove()">
              <i class="icon-trash" aria-hidden="true"></i>
            </button>
            <div class="overlay" *ngIf="loading">
              <i nz-icon
                 nzType="loading"
                 nzTheme="outline"
                 aria-hidden="true"></i>
            </div>
          </div>
        </ng-template>

      </app-row-multiple-select>

    </div>

    <div nz-col nzMd="6" *ngIf="routeData.tour.diffusers.length" class="access">
      <app-alert class="mb-3 danger column" *ngIf="!routeData.tour.isDepositOpen">
        <i nz-icon nzType="lock" nzTheme="fill"></i>
        <span [innerHTML]="'DIFFUSEURS-TOURNEE.ACCES-FERME' | translate"></span>
        <button appButton [click]="openTour">
          {{ 'ACCES-DEPOT-OUVRIR' | translate }}
        </button>
      </app-alert>

      <app-alert class="mb-3 success column" *ngIf="routeData.tour.isDepositOpen">
        <i nz-icon nzType="lock" nzTheme="fill"></i>
        <span [innerHTML]="'DIFFUSEURS-TOURNEE.ACCES-OUVERT' | translate"></span>
        <button appButton [click]="closeTour">
          {{ 'ACCES-DEPOT-FERMER' | translate }}
        </button>
      </app-alert>
    </div>

  </div>
</div>
