<ng-container *ngIf="isReady">
  <app-page-title title="{{ 'COTISATIONS' | translate }}">
  </app-page-title>

  <div class="container pb-5">
    <div nz-row
         nzGutter="24">
      <div nz-col
           nzMd="6">
        <div class="list-filters">
          <div class="list-filters__box">
            <ul nz-menu>
              <li nz-menu-item
                  class="errorCount list-filters__item"
                  *ngFor="let tab of tabs"
                  [nzSelected]="current == tab"
                  [routerLink]="('../' + tab) | localize"
                  routerLinkActive="active">
                {{'MEMBERSHIP-TAB.' + tab | translate}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div nz-col
           nzMd="18">
        <!-- FACTURATION -->
        <ng-container *ngIf="current == 'FACTURATION'">
          <app-membership-billing-list></app-membership-billing-list>
        </ng-container>
        <!-- CONFIGURATION -->
        <ng-container *ngIf="current == 'CONFIGURATION'">
          <app-membership-configuration> </app-membership-configuration>
        </ng-container>
        <ng-container *ngIf="current == 'COMPTABILITE' && product">
          <app-product-compatibility-form [(product)]="product"
                                          (submitCallback)="saveCompatibility($event)"
                                          [simpleButton]="true"></app-product-compatibility-form>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
