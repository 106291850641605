import { Component, Input } from '@angular/core';

export interface Notification {
  status: string;
  message: string;
  params?: any;
}

@Component({
  selector: 'app-notifications-component',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  @Input() notification: Notification;
}
