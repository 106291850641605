<app-page-title title="{{pageTitle}}" [isMainPage]="true">
</app-page-title>


<div class="container">
  <app-item-list [itemList]="itemList"
                 [filterList]="leftSideFilters"
                 [sortList]="sorts"
                 [pagination]="pagination"
                 [listItemsType]="listType"
                 [isLoading]="isLoading"
                 [useFilters]="true"
                 [useViewSwitcher]="true"
                 [useSearchBar]="true"
                 [searchBarPlaceHolder]="'RECHERCHER-PAR-NOM' | translate"
                 [useSorting]="false"
                 [usePagination]="true"
                 elementsLabel="{{'ELEMENTS' | translate | lowercase}}"
                 (filterChange)="onFilterChange($event)"
                 (searchChange)="onSearchChange($event)"
                 (pageChange)="onPageChange($event)">
    <app-bottin-advanced-search #advancedSearch advanced-search
                                [leftSideSelectedFilter]="leftSideSelectedFilter"
                                (filtersChange)="onAdvancedFilterChange($event)">
    </app-bottin-advanced-search>
  </app-item-list>
</div>
