import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/*Components*/
import { NotificationsSingleComponent } from './components/notifications-single/notifications-single.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from 'localize-router';
import { NgZorroAntdModule, NzToolTipModule } from 'ng-zorro-antd';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [NotificationsSingleComponent, NotificationsListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    LocalizeRouterModule,
    NgZorroAntdModule,
    SharedModule,
    QuillModule,
    NzToolTipModule,
  ],
  exports: [NotificationsSingleComponent, NotificationsListComponent],
})
export class NotificationsModule {}
