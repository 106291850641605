<div nz-row
     class="mb-3"
     nzGutter="24">
    <div nz-col
         nzMd="8">
        <label for="producer">{{'ORGANIZATION-TYPE_1' | translate}}</label>
        <nz-select [(ngModel)]="producer"
                   name="organizationId"
                   nzShowSearch
                   nzAllowClear
                   style="width: 100%"
                   name="producer"
                   id="producer"
                   (ngModelChange)="this.producerSubject.next($event)">
            <nz-option *ngFor="let organization of organizations"
                       [nzLabel]="organization.getTranslatedProperty(lang,'name')"
                       [nzValue]="organization.id"></nz-option>
        </nz-select>
    </div>
</div>