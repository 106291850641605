import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectedFilter } from 'src/app/shared/model/list-item.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vitrine-advanced-search',
  templateUrl: './vitrine-advanced-search.component.html',
  styleUrls: ['./vitrine-advanced-search.component.scss'],
})
export class VitrineAdvancedSearchComponent implements OnInit {
  @Output()
  filtersChange = new EventEmitter<SelectedFilter[]>();

  producer: number[];
  producerSubject: Subject<any> = new Subject();
  producerFilter: SelectedFilter = {
    field: 'organizationId',
    value: null,
  };

  lang;

  public organizations;

  constructor(
    private organizationService: OrganizationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;

    this.getOrganizationList();

    this.producerSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.producerFilter.value = value;
        this.filtersChange.emit([this.producerFilter]);
      });
  }

  getOrganizationList() {
    this.organizationService
      .getProducers()
      .subscribe((data: Organization[]) => {
        this.organizations = data;
      });
  }
}
