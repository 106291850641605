import { Component, Input } from '@angular/core';
import { Permission } from '../../../../../shared/enums/roles.enum';

@Component({
  selector: 'app-show-promote',
  templateUrl: './show-promote.component.html',
  styleUrls: ['./show-promote.component.scss'],
})
export class ShowPromoteComponent {
  @Input() permission?: Permission;
  @Input() organizationId: number;
  @Input() buttonLink: string;
}
