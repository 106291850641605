import { Injectable } from '@angular/core';
import { SelectedFilter, Pagination } from 'src/app/shared/model/list-item.model';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/_configs/globals';
import { MainService } from 'src/app/shared/services/main.services';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../../account/services/account.service';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';
import { Promotion } from '../model/promotion.model';

@Injectable({
    providedIn: 'root'
})
export class PromotionService extends MainService {
    constructor(private accountService: AccountService, protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    getPromotions(filters: SelectedFilter[], pagination?: Pagination): Observable<Promotion[]> {
        let endpoint = this.uri + this.globals.endpoints.promotion.all;

        if (filters || pagination) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get<Promotion[]>(endpoint).map((res) => {
            if (pagination) pagination.total = res['total'];
            return res['promotions'].map((prom) => new Promotion(prom));
        });
    }

    getPromotionById(pubId: number): Observable<Promotion> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.promotion.getSingleEndpoint(pubId, orgId);

        return this.httpClient.get(endpoint).map((res) => new Promotion(res));
    }

    createPromotion(promotion: Promotion): Observable<Promotion> {
        const body = new Promotion(promotion);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.promotion.getMainEndpoint(orgId);
        return this.httpClient.post(endpoint, body).map((res) => new Promotion(res['promotion']));
    }

    modifiyPromotion(promotion: Promotion): Observable<any> {
        const body = new Promotion(promotion);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.promotion.getMainEndpoint(orgId);
        return this.httpClient.put(endpoint, body);
    }

    deletePromotion(promotionId: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.promotion.getSingleEndpoint(promotionId, orgId);
        return this.httpClient.delete(endpoint);
    }

    modifyPromotionApproval(statusId: ObjectStatus, promotionId: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.promotion.getApprovalEndpoint(orgId);
        return this.httpClient.put(endpoint, {
            promotionId: promotionId,
            statusId: statusId
        });
    }
}
