export enum ShowAudiences {
  GRAND_PUBLIC = 1,
  FAMILLES = 2,
  ENFANTS = 3,
  JEUNES = 4,
  ADULTES = 5,
  BEBES = 6,
  TOUT_PETITS = 7,
  PETIT_ENFANTS = 8,
  AINES = 9,
}
 export enum SortedShowAudiences {
  GRAND_PUBLIC = 1,
  BEBES = 6,
  TOUT_PETITS = 7,
  PETIT_ENFANTS = 8,
  ENFANTS = 3,
  JEUNES = 4,
  ADULTES = 5,
  AINES = 9,
  FAMILLES = 2,
 }