import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TarifTypes, VolumeTypes } from './../../enums/tarifs-enum';
import { EventEmitter } from '@angular/core';
import { Tarif } from '../../model/product.model';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-tarif-row',
    templateUrl: './tarif-row.component.html',
    styleUrls: ['./tarif-row.component.scss']
})
export class TarifRowComponent implements OnInit {
    @Input()
    tarif: Tarif;

    @Input()
    orgaName: string;

    @Output()
    deleteTarif = new EventEmitter<any>();
    /**
     * An array of objects that represents the available tariff types.
     * The objects contain an id and a label that is translated using the ngx-translate module.
     * @type {Array<{ id: number; label: string }>}
     */
    public tarifTypes$: Observable<{ id: number; label: string }[]>;

    volumeTypes = [
        { id: VolumeTypes.ALL, label: 'VOLUME_TYPES.ALL' },
        { id: VolumeTypes.TWO, label: 'VOLUME_TYPES.TWO' },
        { id: VolumeTypes.THREE, label: 'VOLUME_TYPES.THREE' },
        { id: VolumeTypes.FOUR, label: 'VOLUME_TYPES.FOUR' }
    ];

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.tarifTypes$ = this.getTarifTypes();
    }

    public doDelete(id: any): void {
        this.tarif.toDelete = 1;
        this.deleteTarif.emit(id);
    }

    /**
     * Returns an Observable of an array of objects containing the id and label of each tariff type.
     * Tariff types are retrieved from the TarifTypes enum and translated using the Angular translate service.
     * The label is concatenated with the organization name for the ORGA_MEMBER type.
     * @returns Observable of an array of objects containing the id and label of each tariff type.
     */
    private getTarifTypes = (): Observable<{ id: number; label: string }[]> => {
        const types = Object.values(TarifTypes)
            .splice(0, Object.keys(TarifTypes).length / 2)
            .map((x: string) => {
                let label = this.translate.instant(`TARIF_TYPES.${x}`);
                label += TarifTypes[x] === TarifTypes.ORGA_MEMBER ? ` ${this.orgaName}` : '';
                return {
                    id: TarifTypes[x],
                    label
                };
            });
        return of(types);
    };
}
