import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ImgUploadStatus {
    INIT = 'INIT',
    UPLOADING = 'UPLOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    DELETED = 'DELETED'
}

export interface ImgUploadState {
    status: ImgUploadStatus;
    url?: string;
}

@Injectable({ providedIn: 'root' })
export class ImgUploadService {
    private readonly initialState: ImgUploadState = {
        status: ImgUploadStatus.INIT,
        url: ''
    };
    private currentStatusBehaviorSubject: BehaviorSubject<ImgUploadState> = new BehaviorSubject<ImgUploadState>(this.initialState);
    public readonly currentStatus$: Observable<ImgUploadState> = this.currentStatusBehaviorSubject.asObservable();

    public setCurrentStatus = ({ status, url }: ImgUploadState): void => {
        this.currentStatusBehaviorSubject.next({ status, url });
    };

    public resetCurrentStatus = (): void => {
        this.currentStatusBehaviorSubject.next(undefined);
    };
}
