import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { ErrorsHandler } from 'src/app/shared/error-handling/errors-handler';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { FieldAreEqualsValidator } from 'src/app/shared/validators/fieldsAreEquals.validator';
import { Globals } from 'src/app/_configs/globals';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [ErrorsHandler]
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;

  isLoading = false;

  private userId: string;

  constructor(
    private authService: AuthService,
    private rideauNotificationService: RideauNotificationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private erroHandlerService: ErrorsHandler,
    private router: Router,
    private location: Location,
    private globals: Globals
    ) { }

  ngOnInit() {

    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');

    this.initForm();
  }

  async sendNewPassword(event){
    event.stopPropagation();
    if(this.resetForm.valid){
      const newPassword = this.resetForm.get('password').value;

      this.isLoading = true;

      await this.authService.sendNewPassword({newPassword , userId: this.userId})
      .toPromise()
      .then( _ => {
        this.rideauNotificationService.success(this.translate.instant('SUCCESS_RESET_PASSWORD'))
        this.router.navigate([this.translate.currentLang, 'login']);
        //this.location.replaceState(this.translate.currentLang + '/login')
      })
      .catch(err => {
        this.erroHandlerService.handleError(err);
      });

      this.isLoading = false;

    }


  }

  private initForm(){
    this.resetForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.pattern(this.globals.passwordRegex)]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validators: [FieldAreEqualsValidator.verify('password','confirmPassword')]
    });
  }
}
