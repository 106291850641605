import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getDomainName } from '../../../utils/other-utils';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit {
  @Input() src: string;

  isReady = false;
  audioType: string;

  soundCloudAudio: SafeResourceUrl;
  bandCampAudio: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.audioType = this.src ? getDomainName(this.src) : undefined;

    // yer/?url=https%3A//api.soundcloud.com/tracks/568198284&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true

    if (this.audioType === 'soundcloud') {
      const soundId = this.src.split('.com/').pop();

      const url =
        'https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/' +
        soundId +
        '&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true';
      this.soundCloudAudio = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    this.isReady = true;
  }
}
