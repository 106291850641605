export enum VitrineType {
    INTEGRAL = 1,
    EXTRAIT = 2,
    PRESENTATION_EXPRESS = 3
}

export function GetVitrineById(id) {
    switch (id) {
        case 1:
            return 'INTEGRAL';

        case 2:
            return 'EXTRAIT';

        case 3:
            return 'PRESENTATION_EXPRESS';
    }
}
// in ActivityVitrinesFormComponent, the typeId: 3 label is different from the other components
// since the label is displayed in a select box
export function getVitrineTypesList(): { label: string; typeId: number }[] {
    return [
        { label: 'INTEGRAL', typeId: 1 },
        { label: 'EXTRAIT', typeId: 2 },
        { label: 'PRESENTATION_EXPRESS_SHORT', typeId: 3 }
    ];
}
