import { ActivitySingleComponent } from './components/activity-single/activity-single.component';
import { ActivityModifyComponent } from './components/activity-modify/activity-modify.component';
import { Routes } from '@angular/router';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';

export const ActivityRoutes: Routes = [
  {
    path: ':meetingId/:activityId',
    children: [
      {
        path: '',
        component: ActivitySingleComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
        },
      },
      {
        path: 'modify/:page',
        component: ActivityModifyComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
        },
      },
      {
        path: 'modify',
        component: ActivityModifyComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
        },
      },
    ],
  },
];
