export type IterableEnum<T> = { name: string; value: T };

export class EnumUtils {
    static convertToIterable<T>(enumValue: T): IterableEnum<T>[] {
        return Object.keys(enumValue)
            .filter((x) => isNaN(Number(x)))
            .map((key) => ({ name: key, value: enumValue[ key ] }));
    }

    static filterBy<T>(iterable: IterableEnum<T>[], filterFn: (item: IterableEnum<T>) => boolean): IterableEnum<T>[] {
        return iterable.filter(filterFn);
    }
}
