import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';

@Directive({
  selector: '[appIfOwnerOf]',
})
export class IfOwnerOfDirective {
  private hasView = false;
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly accountService: AccountService
  ) {}
  @Input() set appIfOwnerOf(item: { organizationId: number }) {
    const condition =
      item.organizationId &&
      item.organizationId === this.accountService.getCurrentCtxOrganizationId();
    if (condition && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
