<form nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row nzGutter="16" class="residency-section" *ngIf="venue.isResidency">
        <app-alert prefix class="mb-3 success">
            <i nz-icon nzType="check" nzTheme="outline"></i>
            <span [innerHTML]="'RESIDENCY-FORM-ACTIVE' | translate"></span>
            <button appButton (click)="switchResidencyStatus(false)">
                {{ 'RESIDENCY-INACTIVE' | translate }}
            </button>
        </app-alert>
    </div>

    <div nz-row nzGutter="16" class="residency-section" *ngIf="!venue.isResidency">
        <app-alert prefix class="mb-3 danger">
            <i nz-icon nzType="close" nzTheme="outline"></i>
            <span [innerHTML]="'RESIDENCY-FORM-INACTIVE' | translate"></span>
            <button appButton (click)="switchResidencyStatus(true)">
                {{ 'RESIDENCY-ACTIVE' | translate }}
            </button>
        </app-alert>
    </div>

    <div *ngIf="venue.isResidency">
        <!-- TYPE -->
        <section formGroupName="type">
            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <h4>{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-TITLE' | translate }} <span class="error">*</span></h4>
                </div>
            </div>

            <nz-form-explain class="has-error" *ngIf="isFormGroupInvalid('type')">
                {{ 'CHAMP-REQUIS' | translate }}
            </nz-form-explain>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <nz-divider></nz-divider>
                    <nz-form-item nz-row>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isTypeCreation" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-1' | translate }}</span>
                                    <span class="checkbox-sub-title d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-1-SUB' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isTypeTechnical" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-2' | translate }}</span>
                                    <span class="checkbox-sub-title d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-2-SUB' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isTypeDried" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-3' | translate }}</span>
                                    <span class="checkbox-sub-title d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-3-SUB' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-divider></nz-divider>
                </div>
            </div>
        </section>
        <!-- END TYPE-->
        <section formGroupName="availability">
            <div class="mb-2" nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <h4>{{ 'RESIDENCY-FORM.DISPONIBILITY-TITLE' | translate }} <span class="error">*</span></h4>
                </div>
            </div>

            <nz-form-explain class="has-error" *ngIf="isFormGroupInvalid('availability')">
                {{ 'CHAMP-REQUIS' | translate }}
            </nz-form-explain>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isDisponibilityFall" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-1' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="12">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isDisponibilitySpring" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-3' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isDisponibilityWinter" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-4' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="12">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isDisponibilitySummer" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-2' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </section>
        <section formGroupName="duration">
            <div class="mb-2" nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <h4>{{ 'RESIDENCY-FORM.RESIDENCY-DURATION-TITLE' | translate }} <span class="error">*</span></h4>
                </div>
            </div>

            <nz-form-explain class="has-error" *ngIf="isFormGroupInvalid('duration')">
                {{ 'CHAMP-REQUIS' | translate }}
            </nz-form-explain>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isDurationDay" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-1' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isDurationWeek" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-2' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <nz-form-item>
                        <nz-form-control>
                            <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="isDurationMonth" class="d-flex">
                                <div class="d-inline-block float-right">
                                    <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-3' | translate }}</span>
                                </div>
                            </label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </section>
        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-TITLE' | translate }}
                </h4>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasReceptionStaff" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-1' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasAssemblyStaff" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-3' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasTechnicalDirector" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-5' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasSoundEngineer" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-7' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasLightingDesigner" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-2' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasStageTechnician" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-4' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasRigger" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-6' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.STAGE-SIZE-TITLE' | translate }}
                </h4>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-1' | translate }} <span class="error">*</span></span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" formControlName="totalStageWidth"></app-input-meter>
                            <form-error *ngIf="formGroup.get('totalStageWidth').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                            <form-error *ngIf="formGroup.get('totalStageWidth').errors?.required" [value]="'CHAMP-REQUIS' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-2' | translate }} <span class="error">*</span></span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$"  formControlName="totalSceneDepth"></app-input-meter>
                            <form-error *ngIf="formGroup.get('totalSceneDepth').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                            <form-error *ngIf="formGroup.get('totalSceneDepth').errors?.required" [value]="'CHAMP-REQUIS' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control >
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-3' | translate }}</span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$"  formControlName="clearHeightLamps"></app-input-meter>
                            <form-error *ngIf="formGroup.get('clearHeightLamps').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-4' | translate }}</span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$"   formControlName="widthPlayground"></app-input-meter>
                            <form-error *ngIf="formGroup.get('widthPlayground').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-5' | translate }}</span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" formControlName="playgroundDepth"></app-input-meter>
                            <form-error *ngIf="formGroup.get('playgroundDepth').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <nz-divider class="residency-divider"></nz-divider>
            </div>
        </div>

        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-TITLE' | translate }}
                </h4>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasAttachmentPoints" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-1' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasDanceFloor" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-3' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasVideoProjector" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-5' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasSoundEquipment" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-7' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasLightEquipment" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-2' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasInternet" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-4' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasTelepresenceEquipment" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-6' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.HOME-TITLE' | translate }}
                </h4>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasLodge" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-1' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasStorageSpace" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-3' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasKitchen" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-5' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasLaundryRoom" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-2' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasHostingPartnership" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-4' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label nz-checkbox [nzDisabled]="formGroup.disabled" formControlName="hasLandingStage" class="d-flex">
                            <div class="d-inline-block float-right">
                                <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-6' | translate }}</span>
                            </div>
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </div>
    <div nz-row nzGutter="16" class="residency-section mt-4">
        <ng-container *ngIf="venue.isNotSubmitted || venue.isRejected">
            <button *appPermission="1; objectOrganizationId: venue.organizationId;shouldBeApproved:false" nz-button [nzLoading]="isLoading" class="btn btn--default">
                {{ 'ENREGISTER-CONTINUER' | translate }}
            </button>
            <p *appPermission="1; objectOrganizationId: venue.organizationId;shouldBeApproved:false" class="d-inline-block ml-2">
                {{ 'OU' | translate }}
                <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
                <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
            </p>
        </ng-container>
        <ng-container *ngIf="(venue.isSubmitted || venue.isApproved) && !formGroup.disabled">
            <button [disabled]="formGroup.invalid" *appPermission="1; objectOrganizationId: venue.organizationId;shouldBeApproved:false" nz-button [nzLoading]="isLoading" class="btn btn--default">
                {{ 'ENREGISTER' | translate }}
            </button>
        </ng-container>
    </div>
</form>
