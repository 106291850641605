import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Notification } from '../../model/notifications.model';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';

@Component({
    selector: 'app-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: [ './notifications-list.component.scss' ],
})
export class NotificationsListComponent {
    @Input() headerEnabled: boolean;
    @Input() headerTotal: string;
    @Input() notifications: Notification[] = [];
    @Input() hideArchiveBtn: boolean;
    @Output() archiveItem = new EventEmitter<number>();
    @Output() archiveAll = new EventEmitter();
    currentLang = this.translate.currentLang;

    constructor(
        private modalService: NzModalService,
        private translate: TranslateService
    ) { }

    onArchiveItemCallback($event: number): void {
        this.archiveItem.emit($event);
    }

    onArchiveAllCallback(): void {
        const deleteMessages = this.translate.instant(
            'NOTIFICATIONS.ARCHIVE-CONFIRMATION'
        );
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.archiveAll.emit();
            },
        });
    }
}
