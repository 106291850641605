import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LocationService } from 'src/app/concepts/location/services/location.service';
import { CreditCard } from 'src/app/concepts/payment/models/credit-card.model';
import { RideauNotificationService } from '../../services/rideau-notification.service';
import { Globals } from '@app/_configs/globals';

@Component({
  selector: 'app-new-credit-card',
  templateUrl: './new-credit-card.component.html',
  styleUrls: ['./new-credit-card.component.scss'],
})
export class NewCreditCardComponent implements OnInit {
  @Input() isSavingCard: boolean;
  @Input() creditCardFormType: string;

  @Output()
  onHideForm = new EventEmitter();

  @Output()
  onConfirmCreditCard = new EventEmitter();

  public countries: Array<any>;
  public lang = this.translate.currentLang;
  public formGroup: FormGroup;
  public creditCard = new CreditCard({});

  //displaying errors
  public displayErrors = false;
  public invalidExpDate = false;
  public errorCount = 0;

  constructor(
    public locationService: LocationService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private notification: RideauNotificationService,
    private globals: Globals
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getCountries().subscribe();
  }

  protected initForm(): void {
    // init du formulaire
    this.formGroup = this.creditCard.getOrgaCreditCardFormGroup(this.fb, this.globals);
  }

  getCountries(): Observable<any> {
    return this.locationService.getCountries().do((data: any) => {
      // Tri des pays par ordre alphabetique traduit
      this.countries = data
        .map((country) => {
          return {
            alpha2: country.alpha2,
            name: this.translate.instant('COUNTRIES.' + country.id),
          };
        })
        .sort((country1, country2) => {
          return country1.name.localeCompare(country2.name, this.lang);
        });

      // console.log( this.countries )
      if (!this.formGroup.value.country && !this.formGroup.value.province) {
        this.formGroup.patchValue({
          country: 'CA',
          province: 'Québec',
        });
      }
    });
  }

  displayEmailErrors(): string {
    if (this.formGroup.get('email').errors['required']) {
      return 'CHAMP-REQUIS-SIMPLE';
    } else return 'ERRORS.EMAIL-FORMAT';
  }

  checkCardValidity(): void {
    if (this.formGroup.errors && this.formGroup.errors['expDateIsInvalid']) {
      this.invalidExpDate = this.formGroup.errors['expDateIsInvalid'];
    } else {
      this.invalidExpDate = false;
    }
  }

  addCard(): void {
    if (this.formGroup.invalid) {
      this.notification.error(
        this.translate.instant('MESSAGES.ERREUR-SOUMISSION')
      );
      this.displayErrors = true;
    } else {
      const controls: string[] = Object.keys(this.formGroup.controls);
      controls.forEach((key) => {
        const value = this.formGroup.get(key).value;
        if (typeof value === 'string' || value instanceof String) {
          this.formGroup
            .get(key)
            .setValue(value.trim().replace(String.fromCharCode(8232), ' '));
        }
      });
      this.onConfirmCreditCard.emit(this.formGroup.value);
    }
  }

  hideForm(): void {
    this.onHideForm.emit();
  }
}
