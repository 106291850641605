import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../services/product.service';
import { Product } from '../model/product.model';
import {
  Filter,
  FilterValue,
  ListItem,
  Pagination,
  SelectedFilter,
  Sort,
} from 'src/app/shared/model/list-item.model';
import { ProductTypes } from '../enums/product-types-enum';
import { LocalizeRouterService } from 'localize-router';
import { Globals } from '../../../_configs/globals';
import { RowTypes } from '../../../shared/enums/row-types.enum';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  public buttonLink = ' ';
  public products;
  public formatter;
  public isLoading: boolean;
  public lang: string;

  itemList: ListItem[] = [];
  productList: Product[];
  leftSideFilters: Filter[] = [];
  sorts: Sort[] = [];
  pagination: Pagination = new Pagination();
  listType: RowTypes = RowTypes.PRODUCT;

  private selectedFilters: SelectedFilter[] = [];

  constructor(
    public translate: TranslateService,
    protected globals: Globals,
    private productService: ProductService,
    private localize: LocalizeRouterService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.formatter = new Intl.NumberFormat('fr-CA', {
      style: 'currency',
      currency: 'CAD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
    });

    this.initFilter();
    this.initPagination();
    this.getProducts();
  }

  async newProduct(): Promise<void> {
    this.productService.createBlankProductAndRedirectToForm();
  }

  getProducts(): void {
    this.isLoading = true;
    // rest call with filters & pagination
    this.productService
      .getProducts([...this.selectedFilters], this.pagination)
      .subscribe((products: Product[]) => {
        this.productList = products;
        this.itemList = this.productList.map((product: Product) => {
          const item = product.toListItem(this.lang, this.translate);
          item.itemUrl = `/admin/product/${product.id}/modify/RENSEIGNEMENTS-GENERAUX`;
          return item;
        });
        this.isLoading = false;
      });
  }

  initFilter(): void {
    this.selectedFilters = [];
    this.leftSideFilters = [];
    this.addRideauFilter();
  }

  addRideauFilter(): void {
    const statusFilter = new Filter();
    statusFilter.filterField = 'productTypeId';
    statusFilter.filterName = this.translate.instant('TYPE');
    statusFilter.filterValues = Object.keys(ProductTypes)
      .filter((val) => isNaN(Number(val)) && val !== 'COTISATION')
      .map((key) => {
        return {
          filterValueName: this.translate.instant('PRODUCT-TYPE.' + key),
          filterValue: ProductTypes[key],
        } as FilterValue;
      });
    statusFilter.filterValues.unshift({
      filterValueName: this.translate.instant('TOUS'),
      filterValue: '',
    } as FilterValue);
    console.log(statusFilter);
    this.leftSideFilters.push(statusFilter);
  }

  /*Filters*/
  initPagination(): void {
    this.pagination.limit = 12;
    this.pagination.offset = 0;
    this.pagination.total = 0;
  }

  onFilterChange(event: SelectedFilter) {
    console.log('Filter Changed', event);
    this.selectedFilters = [event];
    this.getProducts();
  }

  onSearchChange(event: string) {
    //Currently Not Used in Backend...
    console.log('Search Changed', event);
    this.getProducts();
  }

  onSortChange(event: any) {
    console.log('Sort Changed', event);
    this.getProducts();
  }

  onPageChange(event: Pagination) {
    console.log('Pagination Changed', event);
    this.pagination.offset = event.offset;
    this.getProducts();
  }
}
