import { ProductTypes } from './../../product/enums/product-types-enum';
import { SelectedFilter } from 'src/app/shared/model/list-item.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { finalize } from 'rxjs/operators';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Product } from 'src/app/concepts/product/model/product.model';
import { ProductService } from './../../product/services/product.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
})
export class MembershipComponent implements OnInit {
  public isReady = false;
  public lang: string;

  /*Pages*/
  current = '';
  public tabs = ['FACTURATION', 'CONFIGURATION', 'COMPTABILITE'];

  public product: Product;
  public isLoading = false;
  private productId = 1; // TODO : savoir comment le trouver.

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private productService: ProductService,
    private accountService: AccountService,
    private localizeRouter: LocalizeRouterService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    this.isReady = true;
    // listen to route changes
    this.handleRouteEvents();
    this.getSingleProduct();
  }

  private handleRouteEvents(): void {
    this.route.params.subscribe((params) => {
      // par defaut l'onglet courrant est le premier;
      this.current = 'FACTURATION';
      if (params.page) {
        // traduire tous les onglets jusqu'à trouver celui passé en URL
        for (const tab of this.tabs) {
          const locTab = this.localizeRouter.translateRoute(tab);
          if (params.page === locTab) {
            this.current = tab;
            break;
          }
        }
      } else {
        //redirect to identification si pas de parametre.
        const transTab = this.localizeRouter.translateRoute(this.current);
        this.router.navigate([`./${transTab}`], { relativeTo: this.route });
      }
    });
  }

  getSingleProduct(): void {
    const filters = [
      {
        field: 'productTypeId',
        value: ProductTypes.COTISATION,
      },
    ] as SelectedFilter[];
    this.productService.getProducts(filters).subscribe((data: Product[]) => {
      this.product = data.length > 0 ? data[0] : null;
    });
  }

  saveCompatibility(): void {
    this.isLoading = true;

    this.productService
      .modifyProduct(this.product as Product)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.notification.success(this.translate.instant('FORM.SAUVEGARDEE'));
      });
  }
}
