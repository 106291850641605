export enum RowTypes {
  ACTIVITY = 1,
  CONTACT = 2,
  SHOW = 3,
  ORGANISATION = 4,
  MEETING = 5,
  BOTTIN = 6,
  PRODUCT = 7,
  VITRINE = 8,
  ACTIVITYVITRINE = 9,
  EMPTY = 10,
  DIFFUSEUR = 11,
}
