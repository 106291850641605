<app-page-title title="{{ 'MES-SPECTACLES' | translate }}"
                buttonText="{{ 'NOUVELLE-OFFRE-SPECTACLE' | translate}}"
                (buttonClicked)="newShow()"
                [permission]="1"
                [orgaType]="1"
                [buttonIsLoading]="isDisabled">
</app-page-title>


<div class="container">
  <app-item-list [itemList]="itemList"
                 [filterList]="filters"
                 [sortList]="sorts"
                 [pagination]="pagination"
                 [listItemsType]="listType"
                 [isLoading]="isLoading"
                 [useFilters]="true"
                 [useViewSwitcher]="true"
                 [useSearchBar]="true"
                 [searchBarPlaceHolder]="'RECHERCHER-SHOWS' | translate"
                 [useSorting]="false"
                 [usePagination]="true"
                 elementsLabel="{{'OFFRES-SPECTACLES' | translate | lowercase}}"
                 (filterChange)="onFilterChange($event)"
                 (searchChange)="onSearchChange($event)"
                 (sortChange)="onSortChange($event)"
                 (pageChange)="onPageChange($event)">
  </app-item-list>
</div>
