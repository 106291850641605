import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from 'src/app/_configs/globals';
import { MainService } from './../../../shared/services/main.services';
import { BillingItem } from './../../membership/model/billing-item.model';
import { NetworkInfos } from './../model/finance.model';
import { Network, Organization } from '../model/organization.model';
import { NetworkItem } from 'src/app/concepts/tours/model/network-item.model';

@Injectable({
    providedIn: 'root'
})
export class NetworkService extends MainService {
    // Cache pour la liste des réseaux
    private networkCache: Network[] = [];

    constructor(protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    getAllNetworks(): Observable<Network[]> {
        if (this.networkCache.length > 0) {
            return of(this.networkCache);
        } else {
            const endpoint = this.uri + this.globals.endpoints.network.all;
            return this.httpClient.get(endpoint).map((res: any[]) => {
                this.networkCache = res.map((ntwk) => new Network(ntwk));
                return this.networkCache;
            });
        }
    }

    getAllNetworksWithMembers(): Observable<Network[]> {
        if (this.networkCache.length > 0) {
            return of(this.networkCache);
        } else {
            const endpoint = this.uri + this.globals.endpoints.network.withMembers;
            return this.httpClient.get(endpoint).map((res: any[]) => {
                this.networkCache = res.map((ntwk) => new Network(ntwk));
                return this.networkCache;
            });
        }
    }

    clearNetworkCache(): void {
        this.networkCache = [];
    }

    addOrganizationToNetwork(networkId: number, orgId: number): Observable<any> {
        const body = {
            organizationId: orgId,
            organizationHeadId: networkId
        };
        const endpoint = this.uri + this.globals.endpoints.network.add;
        return this.httpClient.post(endpoint, body);
        // .flatMap(() => this.createNetworkInfosForOrg(networkId, orgId))
        // .flatMap(() => this.createBillingInfos(networkId, orgId));
    }

    removeOrganizationFromNetwork(networkId: number, orgId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.network.getDeleteOrgaFromNetworkEndpoint(networkId, orgId);
        return this.httpClient.delete(endpoint);
    }

    getNetworkDetail(organizationId: number): Observable<Organization[]> {
        return this.httpClient
            .get(this.uri + this.globals.endpoints.network.getOrgaDetailEndpoint(organizationId))
            .pipe(map((data: { organizations: unknown[] }) => data.organizations.map((v) => new Organization(v))));
    }

    getNetworkInfosForOgr(orgaId: number, orgHeadId: number): Observable<NetworkInfos> {
        let endpoint = this.uri + this.globals.endpoints.organization.getNetworkInfosEndpoint(orgaId);
        endpoint += `?organizationHeadId=${orgHeadId}`;
        return this.httpClient.get(endpoint).map((res) => new NetworkInfos(res));
    }

    createBillingInfos(networkId: number, orgId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.network.getBillingEndpoint(networkId);
        return this.httpClient.post(endpoint, { organizationId: orgId });
    }

    createNetworkInfosForOrg(networkId: number, orgId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.finance;
        const body = new NetworkInfos({
            organizationId: orgId,
            organizationHeadId: networkId
        });
        return this.httpClient.post(endpoint, body);
    }

    updateNetworkInfosOrg(network: NetworkInfos): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.finance;
        return this.httpClient.put(endpoint, this.cleanUpNullValues(network));
    }

    getNetworkBills(networkId: number): Observable<BillingItem[]> {
        const endpoint = this.uri + this.globals.endpoints.network.getNetworkBills(networkId);
        return this.httpClient.get(endpoint).map((res: any[]) => res.map((bill) => new BillingItem(bill)));
    }

    updateNetworkBill(networkId: number, orgId: number, customFee: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.network.getBillingEndpoint(networkId);
        const body = {
            organizationId: orgId,
            customFee: customFee
        };
        return this.httpClient.put(endpoint, body);
    }

    setNetworkBillPayed(bill: BillingItem) {
        const endpoint = this.uri + this.globals.endpoints.network.getManualPayBillEndpoint(bill.organizationHeadId, bill.id, bill.organizationId);
        const body = {};
        return this.httpClient.put(endpoint, body);
    }

    sendTheBills(networkId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.network.getNetworkSendBillsEndpoints(networkId);
        return this.httpClient.put(endpoint, {});
    }

    startNewBillingPeriod(networkId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.network.getNetworkExpireBillsEndpoints(networkId);
        return this.httpClient.put(endpoint, {});
    }

    getNetworksTours(orgId: number): Observable<NetworkItem[]> {
        const endpoint = this.uri + this.globals.endpoints.network.getNetworksToursEndpoint(orgId);
        return this.httpClient.get(endpoint).pipe(
            map<{ networks: unknown[] }, NetworkItem[]>((data) => data.networks.map((v) => new NetworkItem(v)))
        );
    }

    getNetworksByOrganization(orgId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.network.getNetworksByOrganizationEndpoint(orgId);
        return this.httpClient.get(endpoint, {});
    }
}
