import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Globals } from '../../../_configs/globals';
import { MainService } from 'src/app/shared/services/main.services';
import { IAddress, Address, ICreatedAddress, Country } from 'src/app/concepts/location/models/location.model';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CountryState } from '../../organization/model/state.model';
import { SessionStorageService } from '@app/shared/services/storage.service';
export interface ILocationData {
    countryId: number;
    states: CountryState[]
}
@Injectable({
    providedIn: 'root'
})
export class LocationService extends MainService {
    private countriesCache: any[] = [];

    constructor(httpClient: HttpClient, global: Globals, private sessionStorage: SessionStorageService) {
        super(httpClient, global);
    }

    isSelectedCountryCanada = (countryId: number): boolean => countryId === 124;
    //[GET] countries
    getCountries(): Observable<Country[]> {
        const endpoint: string = this.globals.endpoints.locations.countries;
        if (this.countriesCache.length < 1) {
            return this.httpClient.get<Country[]>(this.uri + endpoint).map((res) => {
                this.countriesCache = res[ 'countries' ];
                return this.countriesCache;
            });
        } else {
            return of(this.countriesCache);
        }
    }

    //[GET] states
    getStates(): Observable<CountryState[]> {
        const endpoint: string = this.globals.endpoints.locations.states;
        const storedLocationData: ILocationData = this.sessionStorage.getItem('sp.locations.states');
        if (!storedLocationData) {
            return this.httpClient.get(this.uri + endpoint).pipe(
                debounceTime(100),
                distinctUntilChanged(),
                map(({ states }: { states: CountryState[] }) => {
                    const updatedStates = states.map((x) => new CountryState(x));
                    this.sessionStorage.setItem('sp.locations.states', {
                        countryId: 124,
                        states: updatedStates
                    });
                    return updatedStates;
                }));
        }
        const { states, countryId } = storedLocationData;
        if (this.isSelectedCountryCanada(countryId)) {
            return of(states.map(x => new CountryState(x)));
        }
        return of([]);
    }

    getAddress(id: number): Observable<Array<Address>> {
        const endpoint: string = this.globals.endpoints.locations.address + '/' + id;

        return this.httpClient.get(this.uri + endpoint).pipe(map((data) => [ data ].map((address) => new Address(address))));
    }

    //[POST] address
    async createAddress(address: IAddress): Promise<ICreatedAddress> {
        const endpoint: string = this.globals.endpoints.locations.address;

        return this.httpClient.post<ICreatedAddress>(this.uri + endpoint, this.cleanUpNullValues(address)).toPromise();
    }

    async modifyAdress(address: IAddress): Promise<any> {
        const endpoint: string = this.globals.endpoints.locations.address;
        return this.httpClient.put(this.uri + endpoint, this.cleanUpNullValues(address)).toPromise();
    }
}
