<ng-container *ngIf="isReady">
  <app-page-title [title]="meeting.title"
                  [subtitle]="meeting.subtitle"
                  [image]="meeting.image">
  </app-page-title>

  <div class="container pb-5">
    <div nz-row
         class="main-wrapper">
      <div nz-col
           nzSpan="20">
        <div *ngIf="isLoading"
             class="loader">
          <i nz-icon
             nzType="loading"
             nzTheme="outline"
             aria-hidden="true"></i>
        </div>
        <h2>{{'INSCRIPTION' | translate}}</h2>
        <nz-divider></nz-divider>

        <!-- LISTE DES INSCRIPTIONS -->
        <div>
          <app-inscription-row *ngFor="let participant of participants"
                               [meeting]="meeting"
                               [participant]="participant"
                               (removeProduct)="removeProduct($event,participant)"
                               (addProduct)="addProduct($event,participant)"
                               (removeParticipant)="removeParticipant($event)">
          </app-inscription-row>
        </div>
        <nz-divider></nz-divider>

        <!-- Ajout de membre et Totaux -->
        <div class="members-and-price">
          <div>
            <nz-dropdown [nzTrigger]="'click'"
                         *ngIf="hasOrganisation">
              <button class="button"
                      nz-button
                      nz-dropdown>
                <span>{{'AJOUTER-UNE-PERSONNE' | translate}}</span>
                <i nz-icon
                   type="down"
                   aria-hidden="true"></i>
              </button>

              <ul nz-menu>
                <li nz-menu-item
                    *ngFor="let member of getFilteredTeam()"
                    (click)="addMemberToMeeting(member.id)">
                  {{member.user.firstName +' '+ member.user.lastName}}
                </li>
              </ul>
            </nz-dropdown>
          </div>
          <div class="bill-totals">
            <div class="labels">
              <span>{{'PANIER.SOUS-TOTAL'|translate}}</span>
              <span>{{'PANIER.TPS'|translate}}</span>
              <span>{{'PANIER.TVQ'|translate}}</span>
              <span>{{'PANIER.TOTAL'|translate}}</span>
            </div>
            <div class="values">
              <span>{{formatter.format(facture.subtotal)}}</span>
              <span>{{formatter.format(facture.taxTps)}}</span>
              <span>{{formatter.format(facture.taxTvq)}}</span>
              <span>{{formatter.format(facture.total)}}</span>
            </div>
          </div>
        </div>

        <!-- Footer boutons de paiement -->
        <div class="footer-buttons">
          <a (click)="cancel()">{{ 'ANNULER' | translate }}</a>
          <button nz-button
                  *appPermission="6"
                  nzType="primary"
                  (click)="payNow()">
            {{ 'PAYER-MAINTENANT' | translate }}
          </button>
          <button nz-button
                  *ngIf="!hasOrganisation"
                  nzType="primary"
                  (click)="payNow()">
            {{ 'PAYER-MAINTENANT' | translate }}
          </button>
        </div>
        <!-- Choix carte -->
        <div class="form-filter form-filter--right mb-3"
             *ngIf="showCreditCards">
          <app-select-credit-card [processing]="isLoading"
                                  (onPay)="checkout($event)"></app-select-credit-card>
        </div>
      </div>
    </div>
  </div>

</ng-container>
