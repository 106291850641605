import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from 'src/app/concepts/organization/services/network.service';
import { Activity } from '../../../model/activity.model';
import { AccountService } from './../../../../account/services/account.service';
import { Network } from './../../../../organization/model/organization.model';
import { ProductService } from 'src/app/concepts/product/services/product.service';
import {
  Product,
  IProduct,
} from 'src/app/concepts/product/model/product.model';
import { ProductTypes } from 'src/app/concepts/product/enums/product-types-enum';

const ORG_ID_RESTRICTION = 'orgaTypeRestrictions.orgaId';

@Component({
  selector: 'app-activity-disponibility-form',
  templateUrl: './activity-disponibility-form.component.html',
  styleUrls: ['./activity-disponibility-form.component.scss'],
})
export class ActivityDisponibilityFormComponent implements OnInit {
  @Input() activity: Activity;

  @Output() readonly saveActivity: EventEmitter<any> = new EventEmitter<any>();

  public formGroup: FormGroup;

  public isLoading = false;

  public areProductsLoading = true;
  public currentLang = this.translate.currentLang;

  public networkList: Network[];

  public meetingProducts: Product[];
  public meetingProductsRestrictions: number[];

  constructor(
    public fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly accountService: AccountService,
    private readonly networkService: NetworkService,
    private readonly productService: ProductService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.activity.getDisponibilityFormGroup(this.fb);
    this.meetingProductsRestrictions = this.formGroup.get(
      'productsRestrictions'
    ).value;

    this.getOrganizations();
    this.getMeetingProducts();

    if (!this.formGroup.get('hasOrgaTypeRestrictions').value) {
      this.disableAndClearSubControls(
        this.formGroup.get('orgaTypeRestrictions') as FormGroup
      );
    }
  }

  getOrganizations(): void {
    this.networkService
      .getAllNetworks()
      .subscribe((res) => (this.networkList = res));
  }

  private disableAndClearSubControls(formGroup: FormGroup): void {
    for (const control in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(control))
        formGroup.get(control).patchValue(undefined);
    }
    formGroup.disable();
  }

  checkDisableOrganizationTypeRestriction(newValue: boolean): void {
    const subFormGroup = this.formGroup.get(
      'orgaTypeRestrictions'
    ) as FormGroup;
    newValue
      ? subFormGroup.enable()
      : this.disableAndClearSubControls(subFormGroup);
  }

  checkDisableOrganizationNetworkRestriction(newValue: boolean): void {
    if (!newValue) {
      this.formGroup.get(ORG_ID_RESTRICTION).patchValue(undefined);
      this.formGroup.get(ORG_ID_RESTRICTION).disable();
    }
  }

  checkDisableProductsRestrictions(newValue: boolean): void {
    if (newValue) this.meetingProductsRestrictions = [];
  }

  addNewProductRestriction(newCheckBoxValue: boolean, productId: number): void {
    if (newCheckBoxValue) {
      this.meetingProductsRestrictions.push(productId);
    } else {
      this.meetingProductsRestrictions = this.meetingProductsRestrictions.filter(
        (restrictedProductId) => restrictedProductId !== productId
      );
    }
  }

  getMeetingProducts(): void {
    this.areProductsLoading = true;

    const meetingFilter = [
      {
        field: 'meetingId',
        value: this.activity.meetingId,
      },
      {
        field: 'productTypeId',
        value: ProductTypes.INSCRIPTION,
      },
    ];

    this.productService
      .getProducts(meetingFilter)
      .subscribe((products: Product[]) => {
        this.meetingProducts = products;
        this.areProductsLoading = false;
      });
  }

  doSubmit(): void {
    this.activity.capacity = this.formGroup.controls.capacity.value;
    // restrictions sur type d'orga
    this.activity.hasNoAccessRestrictions = this.formGroup.controls
      .hasOrgaTypeRestrictions.value
      ? 0
      : 1;
    if (this.activity.hasNoAccessRestrictions) {
      this.activity.allowDiffusers = 0;
      this.activity.allowProducers = 0;
      this.activity.allowVenuesOwners = 0;
      this.activity.allowGovernmental = 0;
      this.activity.allowProviders = 0;
      this.activity.allowMedia = 0;
      this.activity.allowOthers = 0;
      this.activity.allowIndividuals = 0;
      this.activity.organizationHeadId = null;
    } else {
      this.activity.allowDiffusers = this.formGroup.get(
        'orgaTypeRestrictions.allowDiffusers'
      ).value
        ? 1
        : 0;
      this.activity.allowProducers = this.formGroup.get(
        'orgaTypeRestrictions.allowProducers'
      ).value
        ? 1
        : 0;
      this.activity.allowVenuesOwners = this.formGroup.get(
        'orgaTypeRestrictions.allowVenuesOwners'
      ).value
        ? 1
        : 0;
      this.activity.allowGovernmental = this.formGroup.get(
        'orgaTypeRestrictions.allowGovernmental'
      ).value
        ? 1
        : 0;
      this.activity.allowProviders = this.formGroup.get(
        'orgaTypeRestrictions.allowProviders'
      ).value
        ? 1
        : 0;
      this.activity.allowMedia = this.formGroup.get(
        'orgaTypeRestrictions.allowMedia'
      ).value
        ? 1
        : 0;
      this.activity.allowOthers = this.formGroup.get(
        'orgaTypeRestrictions.allowOthers'
      ).value
        ? 1
        : 0;
      this.activity.allowIndividuals = this.formGroup.get(
        'orgaTypeRestrictions.allowIndividuals'
      ).value
        ? 1
        : 0;
      if (this.formGroup.get('orgaTypeRestrictions.hasOrgaId').value) {
        this.activity.organizationHeadId = this.formGroup.get(
          ORG_ID_RESTRICTION
        ).value;
      } else {
        this.activity.organizationHeadId = null;
      }
    }
    this.activity.hasNoProductsRestrictions = this.formGroup.get(
      'hasNoProductsRestrictions'
    ).value
      ? 1
      : 0;
    if (this.activity.hasNoProductsRestrictions) {
      this.activity.products = [];
    } else {
      this.activity.products = this.meetingProductsRestrictions.map(
        (productId) => {
          return { id: productId } as IProduct;
        }
      );
    }
    this.activity.organizationId = this.accountService.getCurrentCtxOrganizationId();
    this.saveActivity.emit();
  }
}
