
import { Meeting } from './meeting.model';

export class Jury {
  id?: number;
  meetingId: number;
  email: string;
  userId?: number;
  sentAt?: string;

  user?: any;
  meeting?: Meeting;

  constructor(data: any) {
    this.id = data['id'];
    this.userId = data['userId'];
    this.meetingId = data['meetingId'];
    this.email = data['email'];
    this.sentAt = data['sentAt'];

    this.user = data['user'];
    this.meeting = data['meeting'] ? new Meeting(data['meeting']) : undefined;
  }

  toIJuryObj(): IJury {
    return {
      userId: this.userId,
      meetingId: this.meetingId,
      email: this.email,
    } as IJury;
  }
}

export interface IJury {
  userId: number;
  meetingId: number;
  email: string;
}
