import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { Meeting } from '../../../meeting/model/meeting.model';
import { MeetingService } from '../../../meeting/services/meeting.service';
import { Activity } from './../../model/activity.model';
import { ActivityService } from './../../services/activity.service';
import { IProduct } from 'src/app/concepts/product/model/product.model';

@Component({
    selector: 'app-activity-modify',
    templateUrl: './activity-modify.component.html',
    styleUrls: [ './activity-modify.component.scss' ],
})
export class ActivityModifyComponent implements OnInit {
    public openedTab: string;

    public activity: Activity;

    public isReady = false;

    public lang = this.translate.currentLang;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private meetingService: MeetingService,
        private activityService: ActivityService,
        private breadcrumbService: BreadcrumbService,
        private localiseService: LocalizeRouterService,
        private notification: RideauNotificationService
    ) { }

    ngOnInit(): void {
        this.handleRouteEvents();
        this.getActivity();
    }

    getActivity(): void {
        this.activityService
            .getActivityById(this.route.snapshot.params.activityId)
            .subscribe((activity) => {
                this.activity = activity;
                this.isReady = true;
                this.setActivityBreadcrumb();
            });
    }

    saveActivity(): void {
        // Format response for backend
        const newActivity = new Activity(this.activity);
        newActivity.products = newActivity.products.map(
            (product: IProduct) => product.id
        );
        this.activityService.modifyActivity(newActivity).subscribe((res) => {
            this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
            this.getActivity();
        });
    }

    async setActivityBreadcrumb(): Promise<void> {
        // Set Custom Breadcrumb item list
        const breadcrumbItems: BreadcrumbItem[] = [];
        const meeting: Meeting = await this.meetingService
            .getMeetingById(this.route.snapshot.params.meetingId)
            .toPromise();
        const meetingName = meeting.getTranslatedProperty(this.lang, 'name');
        const meetingUrl = '/pro-meeting/' + this.route.snapshot.params.meetingId;
        const activityName = this.activity.getTranslatedProperty(
            this.lang,
            'title'
        );
        const activityUrl =
            '/activity/' +
            this.route.snapshot.params.meetingId +
            '/' +
            this.activity.id;

        breadcrumbItems.push(
            new BreadcrumbItem({
                title: meetingName,
                url: meetingUrl,
                isEditing: false,
            })
        );
        breadcrumbItems.push(
            new BreadcrumbItem({
                title: activityName,
                url: activityUrl,
                isEditing: true,
            })
        );

        this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
    }

    /**
     * L'onglet courrant est determiné par le parametre :page de l'URL. Comme celui-ci
     * est traduit, il y a un travail de reverse-traduction à faire pour trouver l'onglet
     * correspondant.
     */
    private handleRouteEvents(): void {
        const tabs = [ 'general', 'disponibility', 'participants', 'vitrines' ];

        this.route.params.subscribe((params) => {
            //default value
            this.openedTab = tabs[ 0 ];
            if (params.page) {
                // traduire tous les onglets jusqu'à trouver celui passé en URL
                for (const tab of tabs) {
                    const locTab = this.localiseService.translateRoute(tab);
                    if (params.page === locTab) {
                        this.openedTab = tab;
                        break;
                    }
                }
            } else {
                //redirect to coordonees si pas de parametre.
                const transTab = this.localiseService.translateRoute(this.openedTab);
                this.router.navigate([ `./${transTab}` ], { relativeTo: this.route });
            }
        });
    }
}
