import { Activity } from './../../activities/model/activity.model';
import {
  IMember,
  Organization,
} from './../../organization/model/organization.model';
import { PxUser } from '../../account/model/project-x-user';
import { Product } from '../../product/model/product.model';
import { ListItem } from '../../../shared/model/list-item.model';
import { formatPhoneNumber } from '../../../shared/utils/other-utils';

export class ParticipantMeeting {
  id: number;
  meetingId: number;
  memberId: number;
  member?: IMember;
  userId: number;
  user?: PxUser;
  products?: Product[];
  activities?: Activity[];
  extraType?: string;

  constructor(data: any) {
    this.id = data['id'];
    this.meetingId = data['meetingId'];
    this.memberId = data['memberId'];
    this.member = data['member'];
    this.userId = data['userId'];
    this.user = data['user'];
    this.extraType = data['extraType'];
    if (data['products']) {
      this.products = [];
      data['products'].map((product: Product) => {
        this.products.push(new Product(product));
      });
    }
    if (data['activities']) {
      this.activities = [];
      data['activities'].map((activity) => {
        this.activities.push(new Activity(activity));
      });
    }
  }

  toListItem(lang: string): ListItem {
    const item = new ListItem();

    item.itemId = this.id;
    item.itemTitle = `${this.user.firstName} ${this.user.lastName}`;
    item.itemSubtitle =
      this.member && this.member.organization
        ? new Organization(this.member.organization).getTranslatedProperty(
            lang,
            'name'
          )
        : '';
    item.itemImgSrc =
      this.user.avatar || './assets/images/placeholder-membre.svg';
    item.itemUrl = '';
    item.itemInfo1 = this.user.email || 'NO-EMAIL';
    item.itemInfo2 = formatPhoneNumber(this.user.phone) || 'NO-PHONE';
    item.itemInfo3 = this.extraType;

    return item;
  }
}
