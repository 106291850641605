import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadFile } from 'ng-zorro-antd';
import { ItemRowFile } from 'src/app/shared/model/item-row-file.model';
import { Globals } from 'src/app/_configs/globals';

@Component({
    selector: 'app-vitrine-rideau-form',
    templateUrl: './vitrine-rideau-form.component.html'
})
export class VitrineRideauFormComponent implements OnInit {
    @Input()
    rideauForm: FormGroup;

    @Input()
    materielSupList: ItemRowFile[] = [];

    @Input()
    reloading = false;
    demoFile: UploadFile = null;
    fileLoading = false;
    presentationFileEndpoint: string;
    materielRepeaterEndpoint: string;
    public readonly acceptedFileTypes: string[] = ['application/pdf'];
    public readonly maxFileSize: number = this.globals.maxImageSize;
    constructor(private notification: RideauNotificationService, private translate: TranslateService, private globals: Globals) {}

    ngOnInit(): void {
        this.presentationFileEndpoint = this.globals.api.ENDPOINT + 'upload/vitrine/files';
        this.materielRepeaterEndpoint = this.globals.api.ENDPOINT + 'upload/vitrine/images';
        this.rideauForm.get('demoFile').valueChanges.subscribe(() => {
            this.demoFile = this.rideauForm.get('demoFile').value;
        });
    }
}
