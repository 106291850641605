import { Subject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators'; 

export abstract class Store<T> {
  private _state: T;
  private readonly _state$ = new Subject<T>();

  protected get state(): T {
    return this._state;
  }

  protected set state(state: T) {
    this._state = state;
    this._state$.next(state);
  }

  get state$(): Observable<T> {
    return this._state$.asObservable();
  }

  protected initialize(resolved: Observable<T>): Observable<T> {
    return resolved.pipe(
      tap(state => this._state = state)
    );
  }
}
